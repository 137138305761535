import { Box, ButtonBase, Popover } from '@material-ui/core';
import PgButton from 'Components/PgButton';
import PgTypo from 'Components/PgTypo';
import { useWalletAddresses } from 'Features/AppHeader/WalletList';
import UserModel, { TCryptoWallet } from 'Models/User';
import { WalletType } from 'Models/Web3/@types';
import { APP_ROUTES } from 'Routes';
import { WalletAddress } from 'Screens/UserSettings/Sections/ConnectWallet';
import React, { FC, MouseEvent, useEffect, useMemo, useState } from 'react';

export interface AddressSelectorProps {
  onWalletChange?: (option: TCryptoWallet) => void;
  isSuiEvent?: boolean;
}

const AddressSelector: FC<AddressSelectorProps> = (props) => {

  const { onWalletChange, isSuiEvent = false } = props;

  const _wallets = useWalletAddresses();

  const wallets = useMemo(() => {
    if (isSuiEvent) {
      const hasSuiWallet = _wallets.find(w => w.type === WalletType.SUI)
      if (hasSuiWallet) return [hasSuiWallet];
      else return [];
    }
    return _wallets.filter(w => w.type !== WalletType.COCRT && w.type !== WalletType.SUI);
  }, [_wallets, isSuiEvent]);

  const [wallet, setWallet] = useState<TCryptoWallet>(wallets?.[0]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const popOverId = Boolean(anchorEl) ? 'wallet-address-selector-popover' : undefined;

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleOptionChange = (option: TCryptoWallet) => () => {
    setWallet(option);
    onWalletChange?.(option);
    handleClose();
  }

  useEffect(() => {
    if (wallets?.[0]) handleOptionChange(wallets[0])();
  }, [])

  const handleSuiWalletCreate = async () => {
    const { loginURL } = await UserModel.getGoogleZKLoginLink();
    window.open(loginURL, '_self');
  }

  return (
    <Box display='flex' flexDirection='column' gridGap={16}>
      <PgTypo c1>Mint your NFT</PgTypo>
      <PgTypo b4>Select which wallet to use</PgTypo>
      {wallets.length ? (
        <Box display='flex' alignItems='center' gridGap={20}>
          <WalletAddress  publicAddr={wallet.address} recentWalletUsed={wallet.type} />
          {wallets.length > 1 && (
            <>
              <PgButton quaternary underlined onClick={handleClick}>Change wallet</PgButton>
              <Popover
                id={popOverId}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                style={{ zIndex: 1350 }}
              >
                {wallets.map(w => (
                  <ButtonBase onClick={handleOptionChange(w)} style={{ display: 'block' , padding: '8px 16px' }}>
                    <WalletAddress publicAddr={w.address} recentWalletUsed={w.type} showCopyBtn={false} />
                  </ButtonBase>
                ))}
              </Popover>
            </>
          )}
        </Box>
      ) : isSuiEvent ? (
        <PgButton secondary onClick={handleSuiWalletCreate}>Connect Sui Wallet </PgButton>
      ) : null}
      <PgTypo b6>
        {wallet?.type === WalletType.PG && (
          'With PG wallet, the tickets will be non-transferrable. For so, you’ll will need to use a traditional wallet such as metamask. '
        )}
        Your NFT will be minted after paying and it will take up a few minutes. If any longer, let us know.{' '}
        <PgButton quaternary underlined href={APP_ROUTES.CONTACT.path}>Contact us</PgButton>
      </PgTypo>
    </Box>
  )
}

export default AddressSelector;
