/* eslint-disable react/require-default-props */
/* eslint-disable global-require */
import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';

export interface CustomIconProps {
  icon: string; // file name
  className?: string;
  size?: number;
  variant?: 'grey' | 'light' | 'dark';
  onClick?: () => void;
}

const resolvePath = (path: string) => {
  try {
    // eslint-disable-next-line import/no-dynamic-require
    return require(`../Assets/icons/${path}`);
  } catch (error) {
    return '';
  }
};

const CustomIcon = React.forwardRef<any, CustomIconProps>((props, ref) => {
  const { icon, className, variant = 'grey', size = 24, onClick } = props;
  const classes = useStyles({ size });
  const variantKey = `variant${variant}`;
  return <ReactSVG onClick={onClick} ref={ref} src={resolvePath(icon)} className={clsx(classes.root, classes[variantKey], className || '')} />;
});

const useStyles = makeStyles<Theme, { size: number }>((theme) => {
  const {
    palette: { grey, primary },
  } = theme;
  return createStyles({
    root: {
      height: (props) => props.size,
      width: (props) => props.size,
      lineHeight: 1.2,
      borderRadius: '50%',
      color: grey['400'],
      // backgroundColor: grey['400'],
      // '&:hover': {
      //     backgroundColor: primary['main'],
      // },
      '& svg': {
        height: (props) => props.size,
        width: (props) => props.size,
      },
      transition: '200ms linear',
    },
    variant: {},
    variantdark: {
      color: theme.palette.primary.main,
      //  backgroundColor: theme.palette.primary['contrastText'],
      '& svg': {
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
        // backgroundColor: theme.palette.primary['contrastText'],
      },
    },
    variantlight: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '& svg': {
        // backgroundColor: theme.palette.primary['main'],
        color: theme.palette.primary.contrastText,
        fill: theme.palette.primary.contrastText,
      },
    },
    variantgrey: {
      // backgroundColor: theme.palette.primary['contrastText'],
      color: '#949494',
      '& svg': {
        fill: '#949494',
      },
    },
  });
});

export default CustomIcon;
