import React, { FC } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

const Hash: FC<SVGCIconProps> = (props) => {
  const { color = 'black', height = 14, width = 14, style } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 22 22" style={style} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 15.4444H19.8889M8.77778 1L4.33333 21L8.77778 1ZM17.6667 1L13.2222 21L17.6667 1ZM2.11111 6.55556H21H2.11111Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default Hash;
