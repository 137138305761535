import { JSONType } from 'Typings/Global';
import { TResource } from './@types';
import { defaultFeatureToggle } from 'Models/Event/eventParsers';
import { EGateType } from 'Models';

export const parseResource = (obj: JSONType): TResource => {
  const { tagIds, featureToggle, ...res } = obj;
  return {
    durationMinutes: res.duration > 0 ? res.duration / 60 : undefined,
    primaryEventCategoryId: tagIds[0],
    secondaryEventCategoryId: tagIds[1],
    featureToggle: featureToggle ?? defaultFeatureToggle,
    ...res,
  } as TResource;
};
export const parseResourceFormData = (resource: Partial<TResource>): JSONType => {
  const { previewLink, durationMinutes, primaryEventCategoryId, secondaryEventCategoryId, gateType, nftGates, password, ...rest } = resource;
  let formData: any;
  if (primaryEventCategoryId || secondaryEventCategoryId) {
    formData = {
      ...rest,
      tagIds: [primaryEventCategoryId, secondaryEventCategoryId].filter((id) => !!id),
    };
  } else {
    formData = { ...rest };
  }
  if (durationMinutes !== undefined) formData.duration = (durationMinutes ?? 0) * 60;
  return {
    ...formData,
    password: !gateType ? undefined : gateType === EGateType.PASSWORD_PROTECTED ? password : '',
    nftGates,
    gateType: !nftGates?.length && gateType === EGateType.NFT_GATED ? EGateType.OPEN : gateType,
  };
};
