import React, { createContext, useContext, useEffect, useState } from 'react';
// import { Web3AuthNoModal } from '@web3auth/no-modal';
import { OpenloginAdapter } from '@web3auth/openlogin-adapter';
import { openLoginAdapterConfig, VERIFIER_ID, web3AuthConfig } from './config';
import { ADAPTER_EVENTS, WALLET_ADAPTERS } from '@web3auth/base';
import EthereumRpc from '../utils/evm.ethers';
import Web3Auth from '@web3auth/single-factor-auth';
import helpers from '../../Utils/helpers';
import { useOnUserLoggedIn, useUpsertUserPgWallet } from './hooks';
import UserModel from '../../Models/User';
import utils from '../../Utils';
import { useStoreState } from '../../Stores';

const isProduction = helpers.isProduction();

export const PgWalletProvider: React.FC = ({ children }) => {
  const web3AuthRef = React.useRef<Web3Auth | null>(null);
  const [isWeb3AuthDone, setIsWeb3AuthDone] = useState(false);
  const [ethereumRpc, setEthereumRpc] = useState<EthereumRpc | null>(null);

  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));

  /* Handle automatic patching of the user's pg wallet */
  useUpsertUserPgWallet(ethereumRpc);

  const isUserLoggedIn = useOnUserLoggedIn();

  const adapterEventsCallback = {
    [ADAPTER_EVENTS.CONNECTED]: function () {
      setIsWeb3AuthDone(true);
    },
  };

  const eventListeners = {
    attach: (adapter: OpenloginAdapter) => {
      Object.keys(adapterEventsCallback).forEach((event) => {
        adapter.on(event, adapterEventsCallback[event as keyof typeof adapterEventsCallback]);
      });
    },
    detach: (adapter: OpenloginAdapter) => {
      Object.keys(adapterEventsCallback).forEach((event) => {
        adapter.off(event, adapterEventsCallback[event as keyof typeof adapterEventsCallback]);
      });
    },
  };

  const getOpenloginAdapter = () => {
    const openLoginAdapter = new OpenloginAdapter({ ...openLoginAdapterConfig });
    return {
      attachEvents: () => eventListeners.attach(openLoginAdapter),
      detachEvents: () => eventListeners.detach(openLoginAdapter),
      openLoginAdapter,
    };
  };

  // const initializeWeb3Auth = async (openLoginAdapter: OpenloginAdapter) => {
  //   web3AuthRef.current = new Web3Auth(web3AuthConfig);
  //   web3AuthRef.current.configureAdapter(openLoginAdapter); // Disabling this in favor of single factor authentication
  //   await web3AuthRef.current.init();
  //   if (web3AuthRef.current?.provider) {
  //     const ethRpc = new EthereumRpc(web3AuthRef.current.provider);
  //     setEthereumRpc(ethRpc);
  //   }
  // }; // Disabling this in favor of single factor authentication

  const initializeWeb3Auth = async () => {
    web3AuthRef.current = new Web3Auth(web3AuthConfig);
    if (!isProduction) {
      (window as any).web3Auth = web3AuthRef.current;
    }
    await web3AuthRef.current.init();
    // if (web3AuthRef.current?.provider) {
    //   const ethRpc = new EthereumRpc(web3AuthRef.current.provider);
    //   setEthereumRpc(ethRpc);
    //   if (!isProduction) {
    //     (window as any).ethRpc = ethRpc;
    //   }
    // } // Disabling this in favor of single factor authentication
  };

  // useEffect(() => {
  //   const { openLoginAdapter, attachEvents, detachEvents } = getOpenloginAdapter(); // Disabling this in favor of single factor authentication
  //   attachEvents();
  //   initializeWeb3Auth(openLoginAdapter);
  //   return () => {
  //     detachEvents();
  //   };
  // }, []); // Disabling this in favor of single factor authentication

  useEffect(() => {
    initializeWeb3Auth();
  }, []);

  const login = async (email: string, idToken: string) => {
    if (!web3AuthRef.current) return;
    // await web3AuthRef.current.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
    //   loginProvider: 'jwt',
    //   extraLoginOptions: {
    //     id_token: idToken,
    //     verifierIdField: 'sub',
    //   },
    // }); // Disabling this in favor of single factor authentication
    const provider = await web3AuthRef.current.connect({
      idToken,
      verifierId: email,
      verifier: VERIFIER_ID,
    });

    if (provider) {
      const ethRpc = new EthereumRpc(provider);
      setEthereumRpc(ethRpc);
      if (!isProduction) {
        (window as any).ethRpc = ethRpc;
      }
    }
    setIsWeb3AuthDone(true);
  };

  const doWeb3Auth = async (email: string, accessToken: string) => {
    if (isWeb3AuthDone) return;
    const { token } = await UserModel.getWeb3AuthToken(accessToken);
    login(email, token);
  };

  useEffect(() => {
    if (!isUserLoggedIn) return;
    const accessToken = utils.getAccessToken();
    if (!accessToken) {
      console.error(`UserLoggedIn but no access token found`);
      return;
    }
    const userEmail = appUser?.email;
    if (!userEmail) {
      console.error(`UserLoggedIn but no user email found`);
      return;
    }
    doWeb3Auth(userEmail, accessToken);
  }, [isUserLoggedIn]);

  const logout = async () => {
    if (!web3AuthRef.current) return;
    // await web3AuthRef.current.logout();
  };

  const values = {
    login,
    logout,
    web3Auth: web3AuthRef.current,
    isWeb3AuthDone,
    ethereumRpc,
  };

  return <PgWalletProviderContext.Provider value={values}>{children}</PgWalletProviderContext.Provider>;
};

interface IPgWalletProvider {
  login: (email: string, idToken: string) => Promise<void>;
  logout: () => Promise<void>;
  web3Auth: Web3Auth | null;
  isWeb3AuthDone: boolean;
  ethereumRpc: EthereumRpc | null;
}

export const PgWalletProviderContext = createContext<IPgWalletProvider>({
  login: async () => {},
  logout: async () => {},
  web3Auth: null,
  isWeb3AuthDone: false,
  ethereumRpc: null,
});
export const usePgWalletProvider = () => useContext(PgWalletProviderContext);
