import { action, Action, thunk, Thunk } from 'easy-peasy';
import { InviteLink } from 'Models/Community/@types';
import usersModel, { Referral, TUserInvite } from 'Models/User';
import { TRootStore } from 'Stores';

export interface TInviteState {
  invites: TUserInvite[];
  setInvites: Action<TInviteState, TUserInvite[]>;
  fetchInvites: Thunk<TInviteState, void, null, TRootStore>;

  loadingInvites: boolean;
  setLoadingInvites: Action<TInviteState, boolean>;

  inviteLink?: InviteLink;
  setInviteLink: Action<TInviteState, InviteLink | undefined>;
  getInviteLink: Thunk<TInviteState, { communityId: string; redirectUrl: string }, null, TRootStore, Promise<InviteLink>>;

  communityReferral?: Referral;
  getCommunityReferral: Thunk<TInviteState, { redirectUrl: string; referredEmailId?: string }, null, TRootStore, Promise<Referral>>;
  setCommunityReferral: Action<TInviteState, Referral>;
}

const InviteStore: TInviteState = {
  invites: [],
  loadingInvites: false,
  inviteLink: undefined,
  communityReferral: undefined,

  setInvites: action((state, payload) => {
    state.invites = payload;
  }),
  setLoadingInvites: action((state, payload) => {
    state.loadingInvites = payload;
  }),
  fetchInvites: thunk(async (actions, _, { getStoreState }) => {
    const {
      AuthStore: { appUser },
    } = getStoreState();
    if (!appUser) return;
    actions.setLoadingInvites(true);
    const data = await usersModel.getUsersIdGetInvites(appUser.id, {
      include: ['referredUser'],
    });
    actions.setInvites(data);
    actions.setLoadingInvites(false);
  }),

  setInviteLink: action((state, payload) => {
    state.inviteLink = payload;
  }),

  getInviteLink: thunk(async (actions, params) => {
    const inviteLink = await usersModel.getInviteLink(params);
    actions.setInviteLink(inviteLink);
    return inviteLink;
  }),
  getCommunityReferral: thunk(async (actions, { redirectUrl, referredEmailId }) => {
    const referral = await usersModel.getCommunityReferral({ redirectUrl, referredEmailId });
    actions.setCommunityReferral(referral);
    return referral;
  }),
  setCommunityReferral: action((state, payload) => {
    state.communityReferral = payload;
  }),
};

export default InviteStore;
