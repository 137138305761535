import React, { FC } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

const Info: FC<SVGCIconProps> = (props) => {

    const { color = 'black', height = 24, width = 24 } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ?? "24"} height={height ?? "24"} viewBox="0 0 24 24" fill="none">
            <path d="M12.5417 10.1205C12.5417 9.82133 12.2992 9.57882 12 9.57882C11.7008 9.57882 11.4583 9.82133 11.4583 10.1205V17.3494C11.4583 17.6486 11.7008 17.8911 12 17.8911C12.2992 17.8911 12.5417 17.6486 12.5417 17.3494V10.1205Z" fill={color ?? "black"} />
            <path d="M11.2184 7.25783C11.2184 6.82409 11.5653 6.4771 11.9991 6.4771C12.4328 6.4771 12.7798 6.82409 12.7798 7.25783C12.7798 7.69156 12.4328 8.03855 11.9991 8.03855C11.5653 8.03855 11.2184 7.69156 11.2184 7.25783Z" fill={color ?? "black"} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C5.37831 0 0 5.37831 0 12C0 18.6217 5.37831 24 12 24C18.6217 24 24 18.6217 24 12C24 5.37831 18.6217 0 12 0ZM1.08333 12C1.08333 5.97662 5.97662 1.08333 12 1.08333C18.0234 1.08333 22.9167 5.97662 22.9167 12C22.9167 18.0234 18.0234 22.9167 12 22.9167C5.97662 22.9167 1.08333 18.0234 1.08333 12Z" fill="black" />
            <circle cx="12.5" cy="11.5" r="7.5" fill="white" />
            <path d="M9.46094 15.973V15.1239H11.6662V10.4853H9.46094V9.63614H12.6801V15.1239H14.8346V15.973H9.46094ZM11.324 7.83647C11.324 7.60834 11.4 7.41401 11.5521 7.25347C11.7042 7.08449 11.8985 7 12.1351 7C12.3801 7 12.5829 7.08449 12.7434 7.25347C12.9124 7.41401 12.9969 7.60834 12.9969 7.83647C12.9969 8.05614 12.9124 8.24203 12.7434 8.39411C12.5829 8.5462 12.3801 8.62224 12.1351 8.62224C11.8985 8.62224 11.7042 8.5462 11.5521 8.39411C11.4 8.24203 11.324 8.05614 11.324 7.83647Z" fill="black" />
        </svg>
    );
};

export default Info;
