import TimeInput from 'Components/FormFields/TimeInput';
import get from 'lodash/get';
import { FormHelperText } from '@material-ui/core';
import React, { FC, memo, useState, useEffect } from 'react';
import { IFieldProps, getFieldError } from 'react-forms';
import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mm';

export interface ITimeFieldProps extends IFieldProps {
  fieldProps?: {
    label?: string;
    placeholder?: string;
    timezone?: string;
  };
}

const TimeField: FC<ITimeFieldProps> = (props) => {
  const { fieldConfig, formikProps, fieldProps = {} } = props;
  const { label = 'Time', timezone = 'GMT' } = fieldProps;
  const valueKey = fieldConfig?.valueKey || '';
  const value = get(formikProps?.values, valueKey);
  const date = value ? moment.utc(value).tz(timezone) : undefined;
  const [time, setTime] = useState<string | undefined>(
    date ? date.format(TIME_FORMAT) : undefined
  );

  const error = getFieldError(valueKey, formikProps ?? {});

  useEffect(() => {
    if (!time) return;
    const baseDate = date ?? moment();
    const updatedDate = moment
      .tz(
        `${baseDate.format(DATE_FORMAT)} ${time}`,
        `${DATE_FORMAT} ${TIME_FORMAT}`,
        timezone
      )
      .utc()
      .format();
    formikProps?.setFieldValue(valueKey, updatedDate);
  }, [time, timezone]);

  return (
    <>
      <TimeInput
        label={label}
        onChange={(v) => setTime(v as string)}
        value={time}
        placeholder={fieldProps.placeholder}
        format={TIME_FORMAT}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
};

export default memo(TimeField);
