import { Theme, createStyles, makeStyles } from '@material-ui/core';
import React, { FC, useMemo } from 'react';

export interface MasonryLayoutProps {
  columns?: number;
  gap?: number
}

const MasonryLayout: FC<MasonryLayoutProps> = (props) => {

  const { columns = 3, gap = 16, children } = props;

  const classes = useStyles({ columns, gap });;

  return (
    <div className={classes.container}>
      {Array.isArray(children) && children.map((child, index) => (
        <div key={index} className={classes.item}>{child}</div>
      ))}
    </div>
  )
}

export default MasonryLayout;

const useStyles = makeStyles<Theme, MasonryLayoutProps>((theme) => createStyles({
  container: props => ({ columns: props.columns, columnGap: props.gap }),
  item: props => ({ marginBottom: props.gap, breakInside: 'avoid' }),
}));
