/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-expressions */
import React, { FC, memo } from 'react';
import { Box, BoxProps, Button, ButtonProps, FormControl, FormControlProps, FormHelperText, FormHelperTextProps } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IFieldProps, getMenuOptions, MenuOptions, MenuOptionObject, getFieldError } from 'react-forms';
import get from 'lodash/get';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import PgButton, { PgButtonProps } from 'Components/PgButton';
import clsx from 'clsx';

export interface IToggleButtonProps extends IFieldProps {
  fieldProps?: {
    options?: MenuOptions<MenuOptionObject>;
    buttonsContainerProps?: BoxProps;
    defaultButtonProps?: ButtonProps;
    selectedButtonProps?: PgButtonProps;
    formControlProps?: FormControlProps;
    formHelperTextProps?: FormHelperTextProps;
    helperText?: string;
    onChange?: (menu: MenuOptionObject) => void;
    restrictToggleValue?: any;
    onRectrictToggle?: () => void;
  };
}

const defaultBtnProps: ButtonProps = {
  variant: 'outlined',
  color: 'secondary',
};
const selectedBtnProps: ButtonProps = {
  variant: 'contained',
  color: 'secondary',
};
const ToggleButtons: FC<IToggleButtonProps> = (props) => {
  const { formikProps, fieldConfig, fieldProps = {} } = props;
  const {
    options = [],
    defaultButtonProps,
    selectedButtonProps,
    formControlProps,
    formHelperTextProps,
    helperText,
    onChange,
    restrictToggleValue,
    onRectrictToggle,
    buttonsContainerProps,
  } = fieldProps;
  const { isDeviceSm } = useMediaQuery();
  const classes = useStyles();
  const menuOptions = getMenuOptions(options);
  const value = get(formikProps?.values, fieldConfig?.valueKey || ''); // formikProps?.values[(fieldConfig?.valueKey || '')];
  const fieldError = formikProps ? getFieldError(fieldConfig?.valueKey || '', formikProps) : '';

  const handleSelection = (menu: MenuOptionObject) => () => {
    if (menu?.value === restrictToggleValue) {
      onRectrictToggle?.();
      return;
    }
    formikProps?.setFieldValue?.(fieldConfig?.valueKey || '', menu.value);
    onChange?.(menu);
  };

  return (
    <FormControl fullWidth error={!!fieldError} {...formControlProps}>
      <Box {...(buttonsContainerProps ?? {})} className={clsx(classes.buttonsContainer, buttonsContainerProps?.className)}>
        {menuOptions.map((menu: any) => {
          const btnProps =
            menu.value === value ? { ...defaultBtnProps, ...defaultButtonProps, ...selectedBtnProps, ...selectedButtonProps } : { ...defaultBtnProps, ...defaultButtonProps };
          return (
            <PgButton key={menu.value} onClick={handleSelection(menu)} className={classes.button} fullWidth={isDeviceSm} {...btnProps}>
              {menu.name}
            </PgButton>
          );
        })}
      </Box>

      <FormHelperText {...formHelperTextProps} error={!!fieldError}>
        {fieldError || helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default memo(ToggleButtons);

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    buttonsContainer: {
      flex: 1,
      flexDirection: 'row',
    },
    button: {
      marginRight: 20,
      '&:last-child': {
        marginRight: 0,
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        marginTop: 10,
        '&:first-child': {
          marginTop: 0,
        },
      },
    },
  }),
);
