/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Axios from 'axios';
import { TRefundIssue, TRefundRequest } from './@types';

class RefundRequestModel {
  static baseUrl = '/refundRequests';

  static getRefundType(refundRequest: TRefundRequest) {
    if (refundRequest?.quantity === refundRequest?.originalBill?.quantity) return 'full';
    return 'partial';
  }

  static newRefundRequest(purchasedTicketId: string, quantity: number, reason: string) {
    return Axios.request<TRefundRequest>({
      url: `${this.baseUrl}/new`,
      method: 'post',
      data: {
        purchasedTicketId,
        quantity,
        reason,
      },
    });
  }

  static cancelRefundRequest(id: string, cancelNote: string) {
    return Axios.request<TRefundRequest>({
      url: `${this.baseUrl}/cancel`,
      method: 'delete',
      data: {
        id,
        cancelNote,
      },
    });
  }

  static getRefundRequest(id: string, filter?: any) {
    return Axios.request<TRefundIssue>({
      url: `${this.baseUrl}/${id}`,
      method: 'GET',
      params: {
        filter,
      },
    });
  }
}

export default RefundRequestModel;
