import React, { FC, useEffect } from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, PopoverOrigin, ThemeProvider } from '@material-ui/core'
import { makeStyles, Theme, createStyles , alpha } from '@material-ui/core/styles';
import clsx from 'clsx';
import PgButton from 'Components/PgButton';
import PgTypo from 'Components/PgTypo';
import { COLOR } from 'Theme/themeConstants';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import helpers from 'Utils/helpers';
import PgIcon from 'Components/PgIcon';
import LightTheme from 'Theme';

export interface OnboardingPopupProps {
  onClose?: () => void;
  onClick: () => void;
  content: { title: string; body: string; actionText: string };
  disableSkip?:boolean
  showCloseIconBtn?: boolean;
}

const OnboardingPopup: FC<OnboardingPopupProps> = ({ content, onClick, onClose, disableSkip = false, showCloseIconBtn = false }) => {
  const classes = useStyles();
  const handleClose = () => {
    onClose?.();
  };
  useEffect(() => {
     helpers.toggleBodyScroll(false);
     return () => {
       helpers.toggleBodyScroll(true);
     }
  },[])
  const {isDeviceSm} = useMediaQuery();
  return (
    <div style={{display : 'flex'}}>
     {isDeviceSm ? <Box width="24px" /> : null}
      <Box position={'relative'} className={classes.root}>
        {showCloseIconBtn && (
          <IconButton onClick={handleClose} className={classes.closeIconBtn}>
            <PgIcon icon='icon-close' size='small' />
          </IconButton>
        )}
        <DialogTitle className={classes.zeroPadding} disableTypography>
          <PgTypo align="center" h6>
            {content.title}
          </PgTypo>
        </DialogTitle>
        <DialogContent className={clsx(classes.zeroPadding, classes.content)}>
          {typeof content.body === 'string' ? (
            <PgTypo gutterBottom align="center" b4>
              {content.body}
            </PgTypo>
          ) : (
            content.body
          )}
        </DialogContent>
        <DialogActions className={clsx(classes.zeroPadding, classes.actions)}>
          <PgButton onClick={onClick} fullWidth size="large" secondary variant="outlined">
            {content.actionText}
          </PgButton>
          {disableSkip ? null : (
            <PgButton style={{ marginTop: 8 }} onClick={handleClose} underlined tertiary variant="text">
              <PgTypo c5 underline>
                Skip all onboarding
              </PgTypo>
            </PgButton>
          )}
        </DialogActions>
      </Box>
    </div>
  );
};

const LightThemeOnboardingPopup: FC<OnboardingPopupProps> = (props) => {
  return (
    <ThemeProvider theme={LightTheme}>
      <OnboardingPopup {...props} />
    </ThemeProvider>
  )
}


const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({

    root: {
      width: 400,
      padding: theme.spacing(4, 2.5),
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.down('sm')]: {
        width: '100vw',
        // transform : 'translateX(10px)'
      },
    },
    content: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    zeroPadding: {
      padding: 0,
    },
    subtitle: {
      color: COLOR.tertiary.activism,
    },
    backdrop: {
      backgroundColor: alpha('#000', 0.6),
    },
    actions:{
      flexDirection:'column'
    },
    closeIconBtn: { position: 'absolute', top: theme.spacing(1), right: theme.spacing(1) },
  });
});

export default LightThemeOnboardingPopup
