import { TResource } from 'Models/Resource/@types';
import { parseResource, parseResourceFormData } from 'Models/Resource/resourceParsers';
import AxiosUtils, { request } from 'Resources/AxiosUtils';
import { JSONType } from 'Typings/Global';

class VideoModel {
  static getAllVideos = async (params?: Record<string, any>): Promise<TResource[]> => {
    const res = await request<JSONType[]>({
      url: '/videos',
      method: 'GET',
      params,
    });
    return res.map(parseResource);
  };

  static getVideo = async (id: string, params?: Record<string, any>): Promise<TResource> => {
    const res = await request<JSONType>({
      url: `/resources/${id}/find/Video`,
      method: 'GET',
      params,
    });
    return parseResource(res);
  };

  static updateVideo = async (data: Partial<TResource> & { id: string }): Promise<TResource> => {
    const res = await request({
      url: `/videos/${data.id}`,
      method: 'patch',
      data: { ...parseResourceFormData(data) },
    }).catch(AxiosUtils.throwError);
    return { ...data, ...parseResource(res as JSONType) } as TResource;
  };

  static deleteVideos = async (ids: string[]): Promise<JSONType> => {
    const res = await request<JSONType>({
      url: '/videos/trash',
      method: 'DELETE',
      data: { ids },
    });
    return res;
  };

  static togglePublishVideo = (id: string, publish: boolean): Promise<JSONType> =>
    request<JSONType>({
      url: `/videos/${id}/publish`,
      method: 'post',
      params: { publish },
    });
}

export default VideoModel;
