import React, { FC } from 'react';
import {
  Box,
  Button,
  ButtonProps,
  FormControlProps,
  FormHelperTextProps,
} from '@material-ui/core';
import SvgIcon from 'Components/SvgIcon';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IFieldProps, MenuOptions, MenuOptionObject } from 'react-forms';

export interface IToggleButtonProps extends IFieldProps {
  fieldProps?: {
    options?: MenuOptions<MenuOptionObject>;
    defaultButtonProps?: ButtonProps;
    formControlProps?: FormControlProps;
    formHelperTextProps?: FormHelperTextProps;
    helperText?: string;
  };
}

const PayoutAccordion: FC<IToggleButtonProps> = (props) => {
  const { formikProps, fieldConfig, fieldProps = {} } = props;
  console.log(formikProps);
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.payoutContainer}>
        <SvgIcon size={64} icon="stripe.svg" />
        <Box>
          <Box className={classes.CheckBoxContent} style={{ display: 'flex' }}>
            <SvgIcon size={16} icon="Checkbox.svg" />
            <span className={classes.spanColor}>
              Charge credit & debit cards{' '}
            </span>
          </Box>
          <Box className={classes.CheckBoxContent} style={{ display: 'flex' }}>
            <SvgIcon size={16} icon="Checkbox.svg" />
            <span className={classes.spanColor}>
              Funds deposited to bank account{' '}
            </span>
          </Box>
        </Box>
        <Button className={classes.continueButton}>Continue</Button>
      </Box>
    </div>
  );
};

export default PayoutAccordion;

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    buttonsContainer: {
      flex: 1,
      flexDirection: 'row',
    },
    checkBoxContent: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: '20px',
      // flex: 1,
    },
    spanColor: {
      color: '#5C5C5C',
      paddingLeft: '10px',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '14.4px',
    },
    continueButton: {
      width: '108px',
      height: '32px',
      padding: '12px 30px 12px 30px',
      border: '1px solid #675DF7',
      color: '#675DF7',
    },
    stripeIcon: {
      width: '61.54px',
      height: '25.61px',
    },
    payoutContainer: {
      display: 'flex',
      flex: 1,
      width: '732px',
      height: '80px',
      left: '144px',
      top: '596px',
      border: '1px solid rgba(103, 93, 247, 0.5)',
      boxSizing: 'border-box',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    heading: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 1000,
    },
    root: {
      width: '100%',
    },
    selectBold: {
      fontWeight: 700,
      fontSize: theme.typography.body2.fontSize,
    },
    button: {
      marginRight: 20,
    },
  })
);
