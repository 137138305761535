import { Box, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import PgButton from 'Components/PgButton';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import Typo from 'Components/Typo';
import { getDiscussionFormConfig } from 'Features/Discussion/CreateDiscussionDialog';
import PinnedMessageDialog from 'Features/Discussion/PinnedMessageDialog';
import { Formik } from 'formik';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { TDiscussion } from 'Models/Discussion/@types';
import moment from 'moment';
import React, { FC, useState } from 'react';
import { MLFormContent } from 'react-forms';
import * as Yup from 'yup';
import { DiscussionDialogBaseProps } from '../index';

export interface AboutProps extends DiscussionDialogBaseProps {
  onSave: (data: Partial<TDiscussion>) => void;
  onDeleteClick: () => void;
  onLeaveClick: () => void;
}

const About: FC<AboutProps> = (props) => {
  const { discussion, isAdmin, isAuthenticated, config, onSave, onDeleteClick, onLeaveClick } = props;
  const { isDeviceSm } = useMediaQuery();

  const [showPinnedMsgDialog, setShowPinnedMsgDialog] = useState(false);
  const toggleShowPinnedMsgDialog = () => setShowPinnedMsgDialog((e) => !e);

  const classes = useStyles();

  return (
    <>
      <Formik<Partial<TDiscussion>>
        validationSchema={Yup.object({
          title: Yup.string().trim().required('This is required').max(80, 'Maximum 80 characters are allowed'),
          description: Yup.string().trim().max(250, 'Maximum 250 characters are allowed'),
          password: Yup.string().when('isPasswordProtected', { is: true, then: Yup.string().required('Password required') }),
        })}
        onSubmit={onSave}
        initialValues={{
          ...discussion
        }}
      >
        {(formikProps) => {
          const formConfig = getDiscussionFormConfig(formikProps);
          return (
            <form onSubmit={formikProps.handleSubmit}>
              <Box mt={4}>
                {isAdmin ? (
                  <MLFormContent formId="conversations" schema={formConfig} formikProps={formikProps} />
                ) : (
                  <Box>
                    {discussion.description?.length ? (
                      <>
                        <PgTypo b4Bold>Description</PgTypo>
                        <PgTypo b4>{discussion.description}</PgTypo>
                      </>
                    ) : null}
                  </Box>
                )}
              </Box>
              <Box mt={2}>
                <PgTypo b4Bold>Created</PgTypo>
                <PgTypo b4>{moment(discussion.created).format('MMMM DD, YYYY')}</PgTypo>
              </Box>
              {isDeviceSm && discussion.pinnedMessage && isAuthenticated ? (
                <Box mt={2}>
                  <PgButton onClick={toggleShowPinnedMsgDialog} variant="text" size="small">
                    <PgTypo c3 style={{ textTransform: 'capitalize' }}>
                      Pinned
                    </PgTypo>
                    <PgIcon icon="icon-map-pin" color="primary" />
                  </PgButton>
                </Box>
              ) : null}
              {isAdmin && (
                <Box mt={3}>
                  <PgButton quaternary onClick={onDeleteClick} underlined className={classes.deleteConverstion}>
                    Delete conversation
                  </PgButton>
                </Box>
              )}
              {isAdmin ? (
                <Box mt={2} display="flex" justifyContent="flex-end">
                  <Button fullWidth={isDeviceSm} type="submit" variant="contained" color="secondary">
                    SAVE
                  </Button>
                </Box>
              ) : (
                <Box mt={2}>
                  <Button onClick={onLeaveClick} fullWidth={false}>
                    <Typo underline color="error" weight="bold" variant="caption">
                      Leave conversation
                    </Typo>
                  </Button>
                </Box>
              )}
            </form>
          );
        }}
      </Formik>

      {showPinnedMsgDialog && discussion && discussion.pinnedMessage ? (
        <PinnedMessageDialog
          isAdmin={isAdmin}
          config={config}
          discussion={discussion}
          onClose={toggleShowPinnedMsgDialog}
          open={showPinnedMsgDialog}
        />
      ) : null}
    </>
  );
};

export default About;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteConverstion: {
      color: theme.palette.error.main,
    },
  }),
);
