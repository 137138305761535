import { TCreatorFilter } from "Models/CreatorRequests/@types";
import qs from 'qs';


export interface CreatorRequestQuery {
    page: number
    sort: {
        user: 'newest' | 'oldest' | 'asc' | 'desc'
        community: 'asc' | 'desc'
        date: 'newest' | 'oldest'
    }
    status: 'all' | 'pending' | 'suspended' | 'ignored'
    q: string
}

export const COMMUNITIES_PER_PAGE = 10;

const communityRequestQueryUtil = {
    getSearchQuery: (query: CreatorRequestQuery): TCreatorFilter => {
        let sort: TCreatorFilter['sort'] = [];

        // if (query.sort.user === 'newest') {
        //     sort.push({
        //         "user.created": {
        //             "order": "desc" // recent
        //         }
        //     });
        // } else if (query.sort.user === 'oldest') {
        //     sort.push({
        //         "user.created": {
        //             "order": "asc" // oldest
        //         }
        //     });
        // } else if (query.sort.user === 'asc') {
        //     sort.push({
        //         "user.firstName.keyword": {
        //             "order": "asc" // asc
        //         }
        //     });
        // } else if (query.sort.user === 'desc') {
        //     sort.push({
        //         "user.firstName.keyword": {
        //             "order": "desc" // desc
        //         }
        //     });
        // }

        // if (query.sort.community === 'asc') {
        //     sort.push({
        //         "community.name.keyword": {
        //             "order": "asc" // asc
        //         }
        //     });
        // } else if (query.sort.community === 'desc') {
        //     sort.push({
        //         "community.name.keyword": {
        //             "order": "desc" // desc
        //         }
        //     });
        // }

        if (query.sort.date === 'oldest') {
            sort.push({
                "created": {
                    "order": "asc" // asc
                }
            });
        } else if (query.sort.date === 'newest') {
            sort.push({
                "created": {
                    "order": "desc" // desc
                }
            });
        }

        let status = ["PENDING", "IGNORED"];
        if (query.status === 'pending') {
            status = ["PENDING"];
        } else if (query.status === 'suspended') {
            status = ["SUSPENDED"];
        } else if (query.status === 'ignored') {
            status = ["IGNORED"];
        }

        return {
            where: {
                status
            },
            sort,
            limit: COMMUNITIES_PER_PAGE,
            skip: (query.page - 1) * COMMUNITIES_PER_PAGE
        }
    },

    getQueryFromSearch: (search: string): CreatorRequestQuery => {
        const q = qs.parse(search, {
            ignoreQueryPrefix: true,
        }) as Record<string, string>;

        return {
            page: q.page ? Number(q.page) : 1,
            sort: {
                // user: q.userSort ? q.userSort : 'newest',
                // community: q.communitySort ? q.communitySort : 'desc',
                date: q.dateSort ? q.dateSort : 'oldest',

            } as CreatorRequestQuery['sort'],
            status: (q.status ? q.status : 'pending') as CreatorRequestQuery['status'],
            q: '',
        }
    },



    getSearchQueryString: (query: CreatorRequestQuery) => {
        return qs.stringify({
            page: query.page,
            // userSort: query.sort.user,
            // communitySort: query.sort.community,
            dateSort: query.sort.date,
            status: query.status,
            // q: query.q,
        }, {
            addQueryPrefix: true,
            encode: false,
        });
    },
}

export default communityRequestQueryUtil