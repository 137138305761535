import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Dialog, DialogProps } from '@material-ui/core';
import { useMediaQuery } from '../Hooks/useMediaQuery';

export interface DialogWrapperProps extends DialogProps {
  variant?: 'light' | 'dark';
}

const DialogWrapper: FC<DialogWrapperProps> = (props) => {
  const { children, variant = 'dark', onClose, ...dialogProps } = props;
  const classes = useStyles({ variant });
  const { isDeviceSm } = useMediaQuery();
  const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason) return;
    onClose?.(event, reason);
  }
  return (
    <Dialog
      fullScreen={isDeviceSm}
      onClose={handleClose}
      {...dialogProps}
      PaperProps={{ className: classes.paper }}
      BackdropProps={{ className: classes.backdrop }}
      scroll="body"
    >
      {children}
    </Dialog>
  );
};

const useStyles = makeStyles<Theme, { variant: DialogWrapperProps['variant'] }>((theme) => ({
  paper: {
    background: (props) => (props.variant === 'light' ? undefined : theme.palette.primary.main),
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      // height: `calc(100vh - ${HEADER_HEIGHT_SMALL}px)`
    },
  },
  backdrop: {
    backdropFilter: 'blur(3px)',
    WebkitBackdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
}));

export default DialogWrapper;
