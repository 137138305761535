import { Box, BoxProps, ThemeProvider } from '@material-ui/core';
import React, { FC } from 'react';
import { darkTheme } from 'Theme';

const Dark: FC<BoxProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <ThemeProvider theme={darkTheme}>
      <Box {...rest} bgcolor={darkTheme.palette.background.default}>{children}</Box>
    </ThemeProvider>
  );
};

export default Dark;
