/* eslint-disable no-underscore-dangle */
import qs from 'qs';

export type TPaginationQuery = {
  page?: number;
};

export const LISTING_LIMIT = 20;
//     skip: LISTING_LIMIT * (pageNumber - 1),
class PaginationQuery {
  private _paginationQuery: TPaginationQuery;

  constructor(query: TPaginationQuery) {
    this._paginationQuery = query;
  }

  get paginationQuery() {
    return this._paginationQuery;
  }

  get paginationFilter() {
    return {
      limit: LISTING_LIMIT,
      skip: LISTING_LIMIT * ((this._paginationQuery.page || 1) - 1),
    };
  }

  get paginationQueryString() {
    return qs.stringify(this._paginationQuery);
  }

  static fromQuery(query: string) {
    const data = qs.parse(query, { ignoreQueryPrefix: true }) as Record<string, string>;
    return new PaginationQuery({ page: Number(data.page) });
  }
}

export default PaginationQuery;
