import Axios, { CancelTokenSource } from 'axios';
import { action, Action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';
import ResourceQuery from 'Features/Query/ResourceQuery';
import AlbumModel from 'Models/Album';
import { TFilter } from 'Models/AppModels';
import ResourceModel from 'Models/Resource';
import { TResource } from 'Models/Resource/@types';
import { parseResource } from 'Models/Resource/resourceParsers';
import { TRootStore } from 'Stores';


export interface AlbumState {
  albumDetail?: TResource;
  setAlbumDetail: Action<AlbumState, TResource | undefined>;
  fetchAlbumDetail: Thunk<AlbumState, { id: string, filters?: TFilter }, null, TRootStore>;
  loadingDetails: boolean;
  setLoadingDetails: Action<AlbumState, boolean>;

  albumList: TResource[];
  fetchAlbumList: Thunk<AlbumState, ResourceQuery, null, TRootStore>;
  setAlbumList: Action<AlbumState, { albumList: TResource[] }>;

  loading: boolean;
  setLoading: Action<AlbumState, boolean>;

  total: number;
  setTotal: Action<AlbumState, number>;

  query: ResourceQuery;
  setQuery: Action<AlbumState, ResourceQuery>;
  onQueryChange: ThunkOn<AlbumState, Record<string, unknown>, TRootStore>;
}

let cancelToken: CancelTokenSource | undefined;

const AlbumStore: AlbumState = {
  loadingDetails: false,
  albumList: [],
  total: 0,
  loading: false,
  query: new ResourceQuery({}, { page: 1 }),

  setAlbumDetail: action((state, payload) => {
    state.albumDetail = payload;
  }),
  setLoadingDetails: action((state, payload) => {
    state.loadingDetails = payload;
  }),

  setAlbumList: action((state, { albumList }) => {
    state.albumList = albumList;
  }),
  setTotal: action((state, payload) => {
    state.total = payload;
  }),
  setQuery: action((state, payload) => {
    state.query = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  fetchAlbumList: thunk(async (actions, query, { getStoreState }) => {
    actions.setLoading(true);
    const { community } = getStoreState().CommunityStore;
    if (!community) return;

    query.communityId = community?.id ?? '';
    query.docType = 'Collect';

    if (cancelToken) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
    cancelToken = Axios.CancelToken.source();

    const res = await ResourceModel.search(
      community?.id ?? '',
      '',
      {
        album: {
          include: 'tags',
        },
        ...query.searchFilter,
      },
      { cancelToken: cancelToken.token }
    );

    if (res) {
      const albumList: TResource[] = [...res.results.map((res) => parseResource(res.hit))];

      actions.setTotal(res.total);
      actions.setAlbumList({ albumList });
    }
    actions.setLoading(false);
  }),
  fetchAlbumDetail: thunk(async (actions, { id, filters = {} }) => {
    try {
      actions.setLoadingDetails(true);
      const res = await AlbumModel.getAlbum(id, filters);
      actions.setAlbumDetail(res);
    } catch (error) {
      console.error(error)
    } finally {
      actions.setLoadingDetails(false);
    }
  }),
  onQueryChange: thunkOn(
    (actions) => actions.setQuery,
    async (actions, { payload }) => {
      actions.fetchAlbumList(payload);
    }
  ),
};

export default AlbumStore;

