import React, { createContext } from 'react';
// eslint-disable-next-line import/no-cycle
import useAddContentDialog, {
  AddContentDialogState,
} from './AddContent/useAddContentDialog';

export type DialogContextProviderValue = AddContentDialogState;

export const DialogContext = createContext<DialogContextProviderValue>({
  open: false,
  addContent: () => null,
  closeAddContentDialog: () => null,
});

export const DialogProvider: React.FC = (props) => {
  const { Component: AddContentDialogComponent, ...addContentDialogState } =
    useAddContentDialog();
  const { children } = props;

  return (
    <DialogContext.Provider
      value={{
        ...addContentDialogState,
      }}
    >
      {children}
      {AddContentDialogComponent}
    </DialogContext.Provider>
  );
};
