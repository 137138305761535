import { Player } from '@livepeer/react';
import { Box } from '@material-ui/core';
import { TDiscussion } from 'Models/Discussion/@types'
import React, { FC } from 'react'

const LiveStream: FC<TDiscussion> = (props) => {

  const { stream } = props;
  const { playbackId } = stream ?? {};


  return (
    <Box height='100%' display='flex' alignItems='center' justifyContent='center'>
      <Player playbackId={playbackId} priority onError={console.error} autoPlay />
    </Box>
  )
}

export default LiveStream
