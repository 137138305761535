import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { TCommunity } from 'Models/Community/@types';
import UserAvatar from 'Components/UserAvatar';
import helpers from 'Utils/helpers';

export interface CollaboratorsGroupProps {
  collaborators: Array<TCommunity>;
}

const CollaboratorsGroup: FC<CollaboratorsGroupProps> = ({ collaborators }) => {
  const classes = useStyles();
  let collaboratorsImages = (collaborators || []).map((i) => i._profileImages?.[0]?.url || '');
  collaboratorsImages = collaboratorsImages.filter((i) => !!i);
  const showAllCollaborators = (collaboratorsImages?.length || 0) <= 3;
  const showCount = (collaboratorsImages?.length || 0) > 3 ? (collaboratorsImages?.length || 0) - 2 : 0;
  return (
    <Box display="flex" alignItems="center">
      {collaborators?.map((item, index) => {
        const showMe = index < 2 || showAllCollaborators;
        if (!showMe) return null;
        return (
          <UserAvatar
            name={item.name ?? ''}
            size={60} url={helpers.getPictureUrl(item._profileImages?.[0])}
            className={clsx(classes.avatar, { [classes.avatarOverlap]: (collaboratorsImages?.length || 0) > 1 })}
          />
        )
      })}
      {showCount ? (
        <UserAvatar
          name={`+${showCount}`}
          size={60}
          className={clsx(classes.avatar, classes.avatarText, { [classes.avatarOverlap]: (collaboratorsImages?.length || 0) > 1 })}
        />
      ) : null}
    </Box>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    avatar: {
      height: 60,
      width: 60,
      border: `1px solid ${theme.palette.common.white}`,
    },
    avatarOverlap: {
      marginRight: -20,
      '&:last-child': { marginRight: 0 },
    },
    avatarText: {
      height: 60,
      width: 60,
      // position: 'absolute',
      // top: -theme.spacing(7),
      // right: theme.spacing(1),
      fontSize: 16,
      // fontWeight: theme.typography.fontWeightRegular,
      fontWeight: theme.typography.fontWeightBold,
      // backgroundColor: '#B6BFBF',
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  });
});

export default CollaboratorsGroup;
