import React, { FC } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

const Key: FC<SVGCIconProps> = (props) => {
  const { color = 'black', height = 16, width = 15 } = props;
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0766 0.666504C9.34053 0.666335 8.61462 0.838597 7.95707 1.16949C7.29952 1.50037 6.72862 1.98069 6.29012 2.57194C5.85163 3.16319 5.55772 3.84893 5.43196 4.57422C5.3062 5.2995 5.35208 6.04416 5.56593 6.74853L0.125 12.1895L0.125 15.3321H3.26762L8.70855 9.89115C9.35693 10.088 10.0401 10.1427 10.7115 10.0516C11.383 9.96046 12.0269 9.72568 12.5994 9.36321C13.1719 9.00075 13.6595 8.51912 14.029 7.95116C14.3985 7.3832 14.6413 6.74224 14.7407 6.07199C14.8401 5.40173 14.7938 4.71792 14.605 4.06716C14.4163 3.4164 14.0894 2.81398 13.6468 2.30096C13.2041 1.78794 12.6561 1.37638 12.04 1.09433C11.4239 0.812269 10.7542 0.666347 10.0766 0.666504ZM10.0766 9.04683C9.71603 9.04663 9.35742 8.99334 9.01234 8.88865L8.41157 8.70638L6.30183 10.8161L5.57955 10.0944L4.83893 10.835L5.56121 11.5573L4.73051 12.388L4.00823 11.6657L3.26762 12.4063L3.9899 13.1286L2.83394 14.2845H1.17254L1.17254 12.6231L6.7507 7.0455L6.56842 6.44474C6.34455 5.70674 6.3591 4.91694 6.60999 4.18769C6.86087 3.45844 7.33533 2.82686 7.96586 2.38281C8.5964 1.93876 9.3509 1.70484 10.122 1.71432C10.8932 1.72381 11.6417 1.97622 12.2611 2.43565C12.8805 2.89508 13.3393 3.53814 13.5722 4.27335C13.8051 5.00855 13.8002 5.79847 13.5582 6.53073C13.3163 7.263 12.8496 7.90032 12.2245 8.35205C11.5994 8.80377 10.8478 9.0469 10.0766 9.04683Z"
        fill={color}
      />
      <path
        d="M10.6004 5.90421C11.1789 5.90421 11.6479 5.43521 11.6479 4.85667C11.6479 4.27813 11.1789 3.80913 10.6004 3.80913C10.0219 3.80913 9.55287 4.27813 9.55287 4.85667C9.55287 5.43521 10.0219 5.90421 10.6004 5.90421Z"
        fill={color}
      />
    </svg>
  )
}

export default Key;
