import { AxiosRequestConfig } from 'axios';
import { TContentType, TDrop } from 'Models/Drop/@types';
import { TEvent } from 'Models/Event/@types';
import moment, { Moment } from 'moment';
import DateTimeUtils from 'Resources/DateTimeUtils';
import { SearchResponse } from 'Typings/Global';
import AxiosUtils, { request } from 'Utils';
import { ResourceResponse, TPreviewLink, TResource } from './@types';
import { parseResource, parseResourceFormData } from './resourceParsers';
import { capitalize } from 'lodash';
import config from 'config';
import { PlaybackPolicy } from '@livepeer/react';

class ResourceModel {
    static getUserResourcesAndEvents = (userId?: string, params?: Record<string, any>): Promise<ResourceResponse> => {
        return request<ResourceResponse>({
            url: `/Resources/search`,
            params: { ...(params ?? {}), userId },
        });
    };

    static globalFeature = async (subjectType: string, subjectId: string, feature: boolean) => {
        return request({
            url: `/Resources/${subjectType}/${subjectId}/global-feature`,
            method: 'PATCH',
            data: {
                feature,
            },
        });
    };

    static createNew = async (type: string, data: Partial<TResource>): Promise<TResource> => {
        const res = await request({
            url: `/resources/${type}/create-new`,
            method: 'POST',
            data: { ...parseResourceFormData(data) },
        })
            .then()
            .catch(AxiosUtils.throwError);

        return { ...data, ...parseResource(res) } as TResource;
    };

    static search = (
        communityId: string,
        term?: string,
        filter?: Record<string, any>,
        axiosConfig?: AxiosRequestConfig,
    ): Promise<SearchResponse<TResource>> | undefined => {
        if (!communityId) return;

        // eslint-disable-next-line consistent-return
        return request<SearchResponse<TResource>>({
            ...axiosConfig,
            url: '/resources/search',
            params: {
                term,
                filter,
                communityId,
            },
        });
    };

    static previewLink = async (targetLink: string): Promise<TPreviewLink> => {
        const res = await request({
            url: '/resources/preview-link',
            method: 'GET',
            params: {
                targetLink,
            },
        })
            .then()
            .catch(AxiosUtils.throwError);

        return res as TPreviewLink;
    };

    static searchResources = (
        communityId: string | undefined,
        term: string,
        filter?: Record<string, any>,
        axiosConfig?: AxiosRequestConfig,
    ): Promise<SearchResponse<TResource | TEvent>> => {
        return request<SearchResponse<TResource | TEvent>>({
            ...axiosConfig,
            url: '/resources/search',
            params: { term, filter, communityId },
        });
    };

    static searchDrops = (
        communityId: string,
        term: string,
        filter?: Record<string, any>,
        axiosConfig?: AxiosRequestConfig,
    ): Promise<SearchResponse<TDrop>> => {
        return request<SearchResponse<TDrop>>({
            ...axiosConfig,
            url: '/resources/search',
            params: { term, filter, communityId },
        });
    };

    static getSingleResource = (resourceType: TContentType, slug: string, filter: Record<string, any>): Promise<TResource> => {
        return request<TResource>({
            url: `${resourceType}/${slug}`,
            params: filter,
        });
    };

    /**
     * Convert duration in seconds to minutes
     * @param duration A positive number
     * @returns
     */
    static getDuration = (duration: number): number => {
        if (duration < 1) return 0;
        return duration / 60;
    };

    static getReleaseDateTime = (date?: string, timeZone?: string): Moment | undefined => {
        if (!date) return undefined;
        const releaseDate = DateTimeUtils.getDate(date);
        return releaseDate;
    };

    static isPastRelease = (date?: string, time?: string): boolean => {
        const updatedDate = ResourceModel.getReleaseDateTime(date);
        return moment().isAfter(updatedDate);
    };

    static getReleaseDate = (resource: TResource, isLocal = false): Moment | undefined => {
        if (!resource || !resource.releaseDate) return undefined;
        return DateTimeUtils.copyTimeInDate(resource.releaseDate, resource.releaseDate, isLocal ? undefined : resource.timezone?.name);
    };

    static resourceIncludeFilter = {
        include: [
            {
                relation: 'community',
                scope: {
                    fields: ['firstName', 'lastName', '_profileImages'],
                },
            },
            {
                relation: 'tags',
                scope: {
                    fields: ['name', 'background', 'color'],
                },
            },
        ],
    };

    static unlockGatedDrop = (id: string, dropType: TContentType) => {
        return request<{ isAuthorized: boolean }>({
            url: `/Resources/${id}/unlock-nft-drop/${capitalize(dropType)}`,
            method: 'POST',
        });
    };

    static updateLivepeerAssets = async (assetId: string, playbackPolicy?: PlaybackPolicy<object>) => {
        return request({
            url: `https://livepeer.studio/api/asset/${assetId}`,
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${config.get('LIVEPEER_API_KEY')}`,
                'Content-Type': 'application/json',
            },
            data: {
                playbackPolicy,
            },
        });
    };
}

export default ResourceModel;

export const resourceIncludeFilter = {
    include: [
        {
            relation: 'community',
            scope: {
                fields: ['name', '_profileImages'],
            },
        },
        {
            relation: 'tags',
            scope: {
                fields: ['name', 'background', 'color'],
            },
        },
        'collaborators',
    ],
};
