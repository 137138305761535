import React, { FC } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

const TikTok: FC<SVGCIconProps> = (props) => {
  const { color, height, width } = props;
  return (
    <svg width={width ?? '24'} height={height ?? '24'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4744 5.84203C13.3969 5.60933 13.1621 5.46719 12.92 5.50644C12.6779 5.54569 12.5 5.75474 12.5 6V15C12.5 15.4945 12.3534 15.9778 12.0787 16.3889C11.804 16.8 11.4135 17.1205 10.9567 17.3097C10.4999 17.4989 9.99723 17.5484 9.51228 17.452C9.02732 17.3555 8.58187 17.1174 8.23223 16.7678C7.8826 16.4181 7.6445 15.9727 7.54804 15.4877C7.45158 15.0028 7.50108 14.5001 7.6903 14.0433C7.87952 13.5865 8.19995 13.196 8.61108 12.9213C9.0222 12.6466 9.50555 12.5 10 12.5C10.2761 12.5 10.5 12.2761 10.5 12C10.5 11.7239 10.2761 11.5 10 11.5C9.30777 11.5 8.63108 11.7053 8.05551 12.0899C7.47993 12.4744 7.03133 13.0211 6.76642 13.6606C6.50152 14.3001 6.4322 15.0039 6.56725 15.6828C6.7023 16.3617 7.03564 16.9854 7.52513 17.4749C8.01461 17.9644 8.63825 18.2977 9.31719 18.4327C9.99612 18.5678 10.6999 18.4985 11.3394 18.2336C11.9789 17.9687 12.5256 17.5201 12.9101 16.9445C13.2947 16.3689 13.5 15.6922 13.5 15V7.83579C13.6169 7.97283 13.7437 8.10766 13.8806 8.23763C14.6129 8.93281 15.6479 9.5 17 9.5C17.2761 9.5 17.5 9.27614 17.5 9C17.5 8.72386 17.2761 8.5 17 8.5C15.9521 8.5 15.1536 8.06719 14.5691 7.51237C13.9762 6.94949 13.6177 6.27228 13.4744 5.84203Z"
        fill={color ?? '#4E45FF'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.5C6.54131 2.5 5.14236 3.07946 4.11091 4.11091C3.07946 5.14236 2.5 6.54131 2.5 8V16C2.5 17.4587 3.07946 18.8576 4.11091 19.8891C5.14236 20.9205 6.54131 21.5 8 21.5H16C17.4587 21.5 18.8576 20.9205 19.8891 19.8891C20.9205 18.8576 21.5 17.4587 21.5 16V8C21.5 6.54131 20.9205 5.14236 19.8891 4.11091C18.8576 3.07946 17.4587 2.5 16 2.5H8ZM4.81802 4.81802C5.66193 3.97411 6.80653 3.5 8 3.5H16C17.1935 3.5 18.3381 3.97411 19.182 4.81802C20.0259 5.66193 20.5 6.80653 20.5 8V16C20.5 17.1935 20.0259 18.3381 19.182 19.182C18.3381 20.0259 17.1935 20.5 16 20.5H8C6.80653 20.5 5.66193 20.0259 4.81802 19.182C3.97411 18.3381 3.5 17.1935 3.5 16V8C3.5 6.80653 3.97411 5.66193 4.81802 4.81802Z"
        fill={color ?? '#4E45FF'}
      />
    </svg>
  );
};

export default TikTok;
