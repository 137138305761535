import helpers from "Utils/helpers";

/* eslint-disable import/prefer-default-export */
export const INVITE_READ_MORE_LINK = 'https://playgroundapp.notion.site/WTF-08a3be8829bb41299fbf1a40ba0390cd';
export const RAINBOW_PAPER_LINK = 'https://docsend.com/view/m4n33gq2wa5kvv6v';
export const CAREERS_LINK = 'https://playgroundapp.notion.site/playgroundapp/Welcome-to-Playground-1f2501812b614711917d459b7dc56e3f';
export const LEGAL_LINK = 'https://playgroundapp.notion.site/Privacy-and-Terms-7d25499ff8fd428887e8959a6e488b2d';
export const COMMUNITY_GUIDELINES = 'https://playgroundapp.notion.site/Community-Guidelines-1ef55df11a90470484675cef0ad10137';
export const REFUND_POLICY_LINK = 'https://playgroundapp.notion.site/Refund-Policy-506e1c979d234640a38dca71727e3712';
export const TAXES_LINK = 'https://playgroundapp.notion.site/Taxes-Fees-bb1d2256a44d452e84d035776040177a';
export const PLAYGROUND_STUDIOS_LINK = 'https://www.playgroundstudios.co/';
export const PLAYGROUND_ACC_LINK = helpers.isProduction() ? '/playground' : 'https://playgroundapp.com/playground';
export const CULTURE_FUND = 'https://clarinet-sprout-2zcm.squarespace.com/';

export const HELLO_PG_EMAIL = 'hello@playgroundapp.com';
export const CONTACT_GMAIL_LINK = helpers.getMailToGmailLink(HELLO_PG_EMAIL);


// Social media links
export const INSTAGRAM_LINK = 'https://www.instagram.com/playground/';
export const TWITTER_LINK = 'https://twitter.com/meet_playground';
export const LINKEDIN_LINK = 'https://www.linkedin.com/company/playgroundinc/';
export const DISCORD_LINK = 'https://discord.gg/7KuXtua9jR';
