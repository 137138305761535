import { createStyles, makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import React, { FC } from 'react';

export interface NavTabsProps {
  currentTab: number;
  onTabChange: (val: number) => void;
}

const TABS = [
  { id: 0, label: 'About' },
  { id: 1, label: 'Privacy' },
  { id: 2, label: 'Permissions' },
];

const NavTabs: FC<NavTabsProps> = (props) => {
  const { currentTab, onTabChange } = props;

  const classes = useStyles();

  return (
    <Tabs value={currentTab} indicatorColor="primary" onChange={(e, val) => onTabChange(val)}>
      {TABS.map((t) => (
        <Tab className={classes.tab} value={t.id} label={t.label} key={t.id} />
      ))}
    </Tabs>
  );
};

export default NavTabs;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      textTransform: 'none',
      opacity: 1,
      fontWeight: theme.typography.fontWeightRegular,
      '&.Mui-selected': { fontWeight: theme.typography.fontWeightBold },
    },
  }),
);
