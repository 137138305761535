import React, { FC, useCallback, useState } from 'react';
import { FormConfig, IFieldProps } from 'react-forms';
import PgButton from 'Components/PgButton';
import { FormikProps } from 'formik';
import get from 'lodash/get';
import { NFTDetails } from 'Models/Community/@types';
import TokenDialog, { TokenDialogOtherProps } from './TokenDialog';
import useConfirmationDialog from 'Hooks/useConfirmationDialog';
import Spacer from 'Components/Spacer';
import Listing from './Listing';
import { Box } from '@material-ui/core';

interface TokenListProps extends IFieldProps {
  fieldProps?: {
    limit?: number;
    name?: string;
    valueKey?: string;
    onTokenListChange: (nftGates: NFTDetails[]) => void;
    dialogProps?: TokenDialogOtherProps;
  };
}

const TokenList: FC<TokenListProps> = ({
  fieldConfig = {} as FormConfig,
  fieldProps = {} as TokenListProps['fieldProps'],
  formikProps = {} as FormikProps<unknown>,
}) => {
  const { name = '', valueKey = '', onTokenListChange, dialogProps = {}, limit = 100 } = fieldProps || {};
  const tokensDetailList: NFTDetails[] = get(formikProps, `values.${name}`) ?? [];
  const [toUpdateOrAdd, setToUpdateOrAdd] = useState<{ type: 'update' | 'add'; updateIndex?: number } | undefined>();
  const [editingToken, setEditingToken] = useState<NFTDetails | undefined | Record<string, never>>(undefined);
  const removeToken = useCallback(
    (token: string) => {
      const newValue = tokensDetailList.filter((tokenItem) => tokenItem.tokenAddress !== token);
      formikProps.setFieldValue(name, newValue);
      onTokenListChange?.(newValue);
    },
    [tokensDetailList],
  );
  const formSubmit = (values: Partial<NFTDetails>) => {
    const newValue =
      toUpdateOrAdd?.type === 'update' && toUpdateOrAdd.updateIndex !== undefined
        ? tokensDetailList.map((token, index) => (index === toUpdateOrAdd.updateIndex ? values : token))
        : [...tokensDetailList, values];
    formikProps?.setFieldValue(name, newValue);
    setEditingToken(undefined);
    onTokenListChange?.(newValue as NFTDetails[]);
  };
  const withConfirmationDialog = useConfirmationDialog();

  const handleEditClick = (tokenItem: NFTDetails, index: number) => {
    if (!formikProps.isSubmitting) {
      setEditingToken(tokenItem);
      setToUpdateOrAdd({ type: 'update', updateIndex: index });
    }
  };

  const handleRemoveClick = (tokenAddr: string) => {
    if (!formikProps.isSubmitting)
      withConfirmationDialog(() => removeToken(tokenAddr), {
        message: 'Are you sure you want to drop your NFT gate?',
        body: "Your members won't need to be verified before accessing Conversations and you'll lose all your gate info. ",
      });
  };

  return (
    <>
      <Box display='flex' flexDirection='column' gridGap={20}>
        <Listing tokens={tokensDetailList} onEdit={handleEditClick} onRemove={handleRemoveClick} isActionDisabled={formikProps.isSubmitting} />
        {tokensDetailList.length < limit ? (
          <PgButton
            disabled={formikProps.isSubmitting}
            secondary
            onClick={() => {
              setEditingToken({});
              setToUpdateOrAdd({ type: 'add' });
            }}
            style={{ width: 'max-content' }}
          >
            ADD NFT GATE
          </PgButton>
        ) : null}
      </Box>
      <TokenDialog editingToken={editingToken} onClose={() => setEditingToken(undefined)} onSubmit={formSubmit} {...dialogProps} />
    </>
  );
};

export default TokenList;
