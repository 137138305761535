import React, { FC } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IFieldProps } from 'react-forms';
import { useHistory } from 'react-router';

export type addPhoneNumberButtonType = 'addPhoneNumber' | 'verifyPhoneNumber';
export interface IToggleButtonProps extends IFieldProps {
  fieldProps?: {
    sendToUrl?: string;
    buttonText?: string;
  };
}

const defaultBtnProps: ButtonProps = {
  variant: 'outlined',
  color: 'secondary',
};
const selectedBtnProps: ButtonProps = {
  variant: 'contained',
  color: 'secondary',
};
const LinkButton: FC<IToggleButtonProps> = (props) => {
  const history = useHistory();
  const { formikProps, fieldConfig, fieldProps = {} } = props;
  const { sendToUrl, buttonText } = fieldProps;

  return (
    <Button {...defaultBtnProps} onClick={() => history.push(sendToUrl ?? '')}>
      {buttonText}
    </Button>
  );
};

// export default memo(ToggleButtons);
export default LinkButton;

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    buttonsContainer: {
      flex: 1,
      flexDirection: 'row',
    },
    button: {
      marginRight: 20,
      maxWidth: '235px',
    },
    verifiedPhoneText: {
      // cursor: 'pointer',
      color: theme.palette.secondary.main,
    },
  })
);
