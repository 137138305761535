import utils, { request } from 'Utils';
import { InterestsBreakdown, MutualSubscribers, TDemographics, ChartData } from 'Models/Insights/@types';

export const DEFAULT_INSIGHT_FILTER_ITEMS:(keyof ChartData)[] = ['follow' ,'favorite', 'rsvp' ,  'ticket'];
class InsightsModel {
  static fetchInterestBreakDown = async (communityId: string, startDate?: any, endDate?: any): Promise<InterestsBreakdown[]> => {
    const res = await request({
      url: '/users/interest-breakdown',
      method: 'get',
      params: {
        communityId,
        startDate,
        endDate,
      },
    });
    return res as InterestsBreakdown[];
  };

  static fetchMutualSubscribers = async (communityId: string, userFilter?: any, limit?: number): Promise<MutualSubscribers[]> => {
    const res = await request({
      url: '/users/mutual-subscribers',
      method: 'get',
      params: {
        communityId,
        userFilter,
        limit,
      },
    });
    return res as MutualSubscribers[];
  };

  static fetchDemographics = async (
    id: string,
    trackingSubjectType: string,
    startDate?: any,
    endDate?: any,
    subjectId?: string
    // subjectType?: string
  ): Promise<TDemographics> => {
    const res = await request({
      url: `/users/${id}/demographics`,
      method: 'get',
      params: {
        trackingSubjectType,
        startDate,
        endDate,
        subjectId,
        // subjectType,
      },
    });
    return res as TDemographics;
  };

  static fetchChartInsights = async (id: string, startDate: any, endDate: any, granularity: string , insights : string[] = DEFAULT_INSIGHT_FILTER_ITEMS): Promise<ChartData> => 
     request<ChartData>({
      url: `/users/community-insights`,
      method: 'get',
      params: {
        communityId: id,
        startDate,
        endDate,
        granularity,
        insights
      },
    });
    
  

  static fetchUserAnalytics(userId: string, startDate: string, endDate: string): void {
    window.open(
      `${
        process.env.REACT_APP_API_URL
      }/communities/${userId}/analytics?access_token=${utils.getAccessToken()}&startDate=${startDate}&endDate=${endDate}&granularity=month`
    );
    // return request({
    //   url: `users/${userId}/analytics`,
    //   params: {
    //     startDate,
    //     endDate,
    //     granularity: 'month'
    //   }
    // })
  }
}

export default InsightsModel;
