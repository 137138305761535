import React, { FC, useState, useEffect } from 'react';
import { Box, Button, ButtonProps, FormControlProps, FormHelperTextProps, IconButton } from '@material-ui/core';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import shadows from '@material-ui/core/styles/shadows';
import { IFieldProps, MenuOptions, MenuOptionObject, MLFormContent, FormConfig } from 'react-forms';
import { admissionType, ticketCost } from 'Screens/Dashboard/EventForm/form-sections/ticketingForms/AdmissionTypeAndCost';
import { availableTickets, ticketsQuantity } from 'Screens/Dashboard/EventForm/form-sections/ticketingForms/AvailableTickets';
import { ticketingDescription, ticketingHeader } from 'Screens/Dashboard/EventForm/form-sections/ticketingForms/TicketingInstructions';
import ConfigurableAccordion, { ConfigurableAccordionRow } from 'Components/ConfigurableAccordion';
import { FormikProps } from 'formik';
import { TEvent } from 'Models/Event/@types';
import get from 'lodash/get';
import { TicketTier } from 'Models/Event/ticketing/@types';
import { DIVIDER_BORDER, THEME_PALETTE } from 'Theme/themeConstants';
import PgIcon from 'Components/PgIcon';
import Spacer from 'Components/Spacer';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import Typo from 'Components/Typo';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import PgTypo from 'Components/PgTypo';
import PgButton from 'Components/PgButton';

export interface IToggleButtonProps extends IFieldProps {
  fieldProps?: {
    options?: MenuOptions<MenuOptionObject>;
    defaultButtonProps?: ButtonProps;
    formControlProps?: FormControlProps;
    formHelperTextProps?: FormHelperTextProps;
    helperText?: string;
  };
}

export interface TicketRowForm extends TicketTier {
  rowTitle: string;
}

const schema = (index: number, value: TicketRowForm, captionCharactersLeft: number, isDeviceSm?: boolean) => {
  const formFields = isDeviceSm
    ? [admissionType(`enabledTier._ticketTiers[${index}].name`), ticketCost(`enabledTier._ticketTiers[${index}].price`)]
    : [[admissionType(`enabledTier._ticketTiers[${index}].name`), ticketCost(`enabledTier._ticketTiers[${index}].price`)]];
  return [
    {
      type: 'header',
      valueKey: 'firstHeading',
      fieldProps: {
        title: value.rowTitle,
      },
    },
    ...formFields,
    [
      availableTickets(`enabledTier._ticketTiers[${index}].ticketsAvailable`),
      ticketsQuantity(`enabledTier._ticketTiers[${index}].maxTicketsPerOrder`),
    ],
    ticketingHeader,
    ticketingDescription(`enabledTier._ticketTiers[${index}].ticketInstructions`, captionCharactersLeft),
  ];
};

const getDefaultRowProps = (classes: Record<string, string>, index: number) => {
  return {
    title: `Tier ${index + 1} ticket`,
    accordionProps: {
      className: classes.accordion,
      classes: { expanded: classes.accordion },
    },
    summaryProps: {
      className: classes.summary,
      classes: { expanded: classes.expandedSummary },
      IconButtonProps: { disableRipple: true },
    },
    detailsProps: { className: classes.accordionDetail },
  };
};

const RightIcons: FC<{
  onEdit: () => void;
  onDelete: () => void;
}> = ({ onEdit, onDelete }) => {
  return (
    <Box
      display="flex"
      borderLeft={`1px solid ${THEME_PALETTE.grey[200]}`}
      justifyContent="space-evenly"
      position="relative"
      height="70px"
      width="96px"
      alignItems="center"
    >
      <Box paddingLeft="1em">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
        >
          <PgIcon icon="icon-edit" color="secondary" />
        </IconButton>
      </Box>
      <Box>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <PgIcon icon="icon-trash" color="secondary" />
        </IconButton>
      </Box>
    </Box>
  );
};

const TierForm: FC<{
  index: number;
  formikProps: FormikProps<Partial<TEvent>>;
  fieldConfig?: FormConfig;
  tier: TicketRowForm;
  addRow?: () => void;
  isDeviceSm?: boolean;
}> = ({ index, formikProps, addRow, tier, isDeviceSm }) => {
  const captionCharactersLeft = 200 - (get(formikProps.values, `enabledTier._ticketTiers[${index}].ticketInstructions`)?.length ?? 0);
  return (
    <Box width="100%">
      <Box width="100%" paddingY={2}>
        <MLFormContent
          schema={schema(index, tier, captionCharactersLeft, isDeviceSm)}
          formId={`multiTier-ticket-${index}`}
          formikProps={formikProps}
          settings={{ verticalSpacing: 30, columnHorizontalPadding: isDeviceSm ? 0 : 20 }}
        />
      </Box>

      {addRow && (
        <Box bgcolor={THEME_PALETTE.grey.A200} paddingTop={1.4} paddingBottom={1.4} paddingLeft={1.7} position="relative">
          <PgButton
            secondary
            color="secondary"
            onClick={(e) => {
              e.stopPropagation();
              addRow();
            }}
          >
            <PgIcon color="secondary" icon="icon-add" />
            <Spacer width={20} />
            ADD ANOTHER TIER
          </PgButton>
        </Box>
      )}
    </Box>
  );
};

const AccordionSummaryComponent: FC<{
  heading: string;
  ticketsAvailable?: number | string;
  ticketCost?: number | string;
  isDeviceSm?: boolean;
}> = ({ heading, ticketsAvailable, ticketCost, isDeviceSm }) => {
  return (
    <Box display="flex" width="100%" justifyContent="space-between" paddingRight={isDeviceSm ? 1 : 2.5}>
      <PgTypo variant="subtitle2" typoWeight="fontWeightBold">
        {heading}
      </PgTypo>
      <Box display={isDeviceSm ? 'none' : 'flex'} justifyContent="flex-end" flex="0.4" minWidth="150px">
        {ticketCost && ticketsAvailable ? (
          <Box display="flex" justifyContent="flex-end" alignItems="center" flex="1">
            <PgTypo style={{ paddingRight: '15px' }}>{ticketsAvailable} available</PgTypo>$ {ticketCost}.00
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

const TicketAccordion: FC<IToggleButtonProps> = (props) => {
  const [currentStep, setCurrentStep] = useState(0);

  const { formikProps, fieldConfig, fieldProps = {} } = props;

  const { isDeviceSm } = useMediaQuery();
  const classes = useStyles();
  const values: TicketRowForm[] = get(formikProps?.values, fieldConfig?.valueKey || '');

  const handleAccordionChange = (event: React.ChangeEvent<Record<string, unknown>>, index: number) => {
    const newStep = index + 1;
    setCurrentStep(newStep === currentStep ? 0 : newStep);
  };

  const addRow = () => {
    const values = get(formikProps?.values, fieldConfig?.valueKey || '');
    formikProps?.setFieldValue(fieldConfig?.valueKey ?? '', [
      ...values,
      ...[
        {
          rowTitle: `Tier ${values.length + 1} ticket`,
        },
      ],
    ]);
    setCurrentStep(values.length + 1);
  };

  const onRowEdit = (rowItem: TicketRowForm, index: number) => {
    setCurrentStep(index + 1);
  };
  const onRowDelete = (rowItem: TicketRowForm, index: number) => {
    values.splice(index, 1);
    formikProps?.setFieldValue(fieldConfig?.valueKey ?? '', [...values]);
    setCurrentStep(values.length);
  };

  useEffect(() => {
    if (values && values.length) setCurrentStep(values.length);
  }, []);

  useEffect(() => {
    if (!values.length) addRow();
  }, [values]);

  const rows: ConfigurableAccordionRow[] = values.length
    ? values.map((value: TicketRowForm, index: number) => {
        const defaultProps = getDefaultRowProps(classes, index);
        return {
          ...defaultProps,
          icon: <RightIcons onEdit={() => onRowEdit(value, index)} onDelete={() => onRowDelete(value, index)} />,
          content: (
            <TierForm
              index={index}
              formikProps={formikProps ?? ({} as FormikProps<Partial<TEvent>>)}
              fieldConfig={fieldConfig}
              addRow={index === values.length - 1 ? addRow : undefined}
              tier={value}
              isDeviceSm={isDeviceSm}
            />
          ),
          id: `ticketTierForm-${value.id ?? index}`,
          title: (
            <AccordionSummaryComponent
              heading={value.name ?? value.rowTitle}
              ticketsAvailable={value.ticketsAvailable ?? ''}
              ticketCost={value.price ?? ''}
              isDeviceSm={isDeviceSm}
            />
          ),
        };
      })
    : [];

  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { source, destination } = result;
    if (!destination) {
      // onReorder(stateList)
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;
    const ticketTiersArray = [...values];
    const itemToinsert = ticketTiersArray[source.index];
    ticketTiersArray.splice(source.index, 1);
    ticketTiersArray.splice(destination.index, 0, itemToinsert);
    formikProps?.setFieldValue(
      // eslint-disable-next-line no-underscore-dangle
      fieldConfig?.valueKey ?? '',
      ticketTiersArray
    );
  };

  return (
    <Box>
      <ConfigurableAccordion
        id="multiTier-ticket-form"
        rows={rows}
        draggable
        selectedStep={currentStep}
        onDragEnd={handleDragEnd}
        onChange={handleAccordionChange}
        titleProps={{ className: classes.title }}
      />
    </Box>
  );
};

export default TicketAccordion;

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    buttonsContainer: {
      flex: 1,
      flexDirection: 'row',
    },
    bx: {
      backgroundColor: 'blue',
    },
    heading: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 1000,
    },
    root: {
      width: '100%',
    },
    selectBold: {
      fontWeight: 700,
      fontSize: theme.typography.body2.fontSize,
    },
    button: {
      marginRight: 20,
    },
    summary: {
      borderBottom: DIVIDER_BORDER,
      height: 70,
      paddingLeft: 30,
      paddingRight: 30,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    accordionDetail: {
      padding: 0,
    },
    expandedSummary: {
      boxShadow: shadows[2],
      '& p': {
        boxShadow: 'none',
      },
      '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: 'unset',
        boxShadow: 'none',
      },
      '& .MuiAccordionSummary-content': {
        boxShadow: 'none',
      },
    },
    accordion: {
      marginTop: 10,
    },
    title: {
      fontSize: 18,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1.2,
    },
    subtitle: {
      fontSize: 12,
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);
