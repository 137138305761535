import { alpha, Box, ButtonBase, createStyles, makeStyles, Portal, Theme } from '@material-ui/core';
import Autolinker from 'autolinker';
import CustomCarousel from 'Components/CustomCarousel';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import { autoLinkerReplaceFn } from 'Constants/initialGenerator';
import BasicActionDialog from 'Dialogs/BasicActionDialog';
import { Picture } from 'Models/Picture/@types';
import React, { FC, useState } from 'react';
import helpers from 'Utils/helpers';

const AdditionalInfo: FC<{ val: string; imgs?: Picture[] }> = ({ val, imgs }) => {
  const [open, setOpen] = useState<{ title: string; startIndex: number } | undefined>(undefined);
  const classes = useStyles();
  return (
    <Box>
      <PgTypo b2 className={classes.addText} dangerouslySetInnerHTML={{ __html: Autolinker.link(val, { newWindow: true, replaceFn: autoLinkerReplaceFn }) }} />
      {!!imgs?.length && (
        <Box mt={2} display="flex" alignItems="flex-start" flexWrap="wrap" gridGap={12}>
          {imgs?.map((i, index) => {
            return (
              <Box component={ButtonBase} onClick={() => setOpen({ title: i.source ?? '', startIndex: index })} className={classes?.addIngWrap}>
                <img src={helpers.getPictureUrl(i)} alt={i.source} className={classes?.addImgs} />
                <Box className={classes?.addImgsBg}>
                  <PgIcon icon="icon-search" color="contrast" />
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
      {!open ? null : (
        <Portal>
          <BasicActionDialog
            open={!!open}
            onClose={() => setOpen(undefined)}
            additionalComponent={<Carousel imgs={imgs ?? []} startIdex={open.startIndex} />}
            dialogMaxWidth={820}
          />
        </Portal>
      )}
    </Box>
  );
};

export default AdditionalInfo;

const Carousel: FC<{ imgs: Picture[]; startIdex?: number }> = (props) => {
  const { imgs, startIdex } = props;
  const renderItem = (data: Picture) => {
    return (
      <>
        <PgTypo variant="h5" align="center" typoWeight="fontWeightBold" style={{ marginBottom: 20 }}>
          {data.source ?? ''}
        </PgTypo>
        <img src={helpers.getPictureUrl(data)} alt={data.source} width={'100%'} />
      </>
    );
  };
  return (
    <CustomCarousel<Picture>
      items={imgs}
      renderItem={renderItem}
      startIndex={startIdex}
      showArrows
      carouselSettings={{ infinite: false, slidesToShow: 1, slidesToScroll: 1 }}
    />
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addImgs: { width: 140 },
    addIngWrap: { position: 'relative', '&:hover > div': { display: 'grid' } },
    addImgsBg: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: alpha(theme.palette.common.black, 0.7),
      display: 'none',
      placeItems: 'center',
      aspectRatio: '1.5 / 1',
    },
    addText: {
      '& a': {
        color: theme.palette.secondary.main,
        textDecoration: 'underline',
        '&:focus': {
          backgroundColor: 'unset !important',
        },
      },
    },
  }),
);
