import { request } from 'Resources/AxiosUtils';
import { JSONType } from 'Typings/Global';
import { TEventSetting } from './@types';
import { parseEventSetting } from './SettingParsers';

class SettingModel {
  static getEventSetting = async (): Promise<TEventSetting> => {
    const res = await request<JSONType>({
      url: '/settings/event-settings',
    });
    return parseEventSetting(res);
  };
}

export default SettingModel;
