import { createStream } from '@livepeer/react';
import { Box, IconButton, TextField } from '@material-ui/core';
import PgButton from 'Components/PgButton';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import useAsyncTask from 'Hooks/useAsyncTask';
import useToastMessage from 'Hooks/useToastMessage';
import DiscussionModel from 'Models/Discussion';
import { TDiscussion } from 'Models/Discussion/@types';
import { THEME_PALETTE } from 'Theme/themeConstants';
import helpers from 'Utils/helpers';
import React, { FC, useEffect } from 'react';
import { IFieldProps } from 'react-forms';

const BORDER = `1px solid ${THEME_PALETTE.grey.A300}`

const GenerateStream: FC<IFieldProps<Partial<TDiscussion>>> = (props) => {
  const { values, setFieldValue } = props.formikProps ?? {};
  const { discussionType, stream, title } = values ?? {};

  const generateStreamData = useAsyncTask(async (regenerate = false) => {
    if (stream && !regenerate) return;
    if (stream && regenerate) await DiscussionModel.deleteLiveStream(stream.id).catch(console.error)
    const streamData = await createStream({ name: title ?? 'Live Stream Broadcasting' });
    setFieldValue?.('stream', streamData);
  });

  const withToast = useToastMessage();

  const handleCopy = (text: string, key: string) => () =>
    withToast(() => helpers.copyToClipboard(text), {
      successToastMessage: `${key} copied to clipboard!`,
    });

  useEffect(() => {
    if (discussionType === 'live-stream') generateStreamData.run(false)
  }, [discussionType])

  if (discussionType !== 'live-stream' || !stream) return null;

  // if (!stream)
  //   return (
  //     <PgButton secondary onClick={() => generateStreamData.run(false)} disabled={generateStreamData.status === 'PROCESSING'}>
  //       generate key
  //     </PgButton>
  //   );

  const data = [
    { label: 'Link for OBS/StreamLabs', value: stream.rtmpIngestUrl.replace(`/${stream.streamKey}`, ''), key: 'Link' },
    { label: 'Private Stream Key', value: stream.streamKey, key: 'Stream key' },
  ];

  return (
    <div>
      <Box borderTop={BORDER} py={2.5}>
      {data.map((s) => (
        <TextField
          {...s}
          fullWidth
          variant="outlined"
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleCopy(s.value, s.key)}>
                <PgIcon icon="icon-duplicate" size="small" />
              </IconButton>
            ),
            readOnly: true,
          }}
          style={{ margin: '20px 0' }}
        />
      ))}
      <PgButton secondary onClick={() => generateStreamData.run(true)} disabled={generateStreamData.status === 'PROCESSING'}>
        re-generate key
      </PgButton>
      </Box>
      <Box borderTop={BORDER} borderBottom={BORDER} py={2.5}>
        <PgTypo b4>In order to create the stream, it is necessary to paste the link and the key generated in OBS or StreamLabs integration section.</PgTypo>
      </Box>
    </div>
  );
};

export default GenerateStream;
