import { action, Action, thunk, Thunk } from 'easy-peasy';
import DropQuery from 'Features/Query/DropsQuery';
import DropModel from 'Models/Drop';
import { TDrop } from 'Models/Drop/@types';
import { TEvent } from 'Models/Event/@types';
import { resourceIncludeFilter } from 'Models/Resource';
import helpers from 'Utils/helpers';

export const DROP_LIST_LIMIT = 20;

const defaultSort = { "sort": { "created": { "order" : "desc" } } };

export interface TDropsState {
  drops: TDrop[];
  setDropList: Action<TDropsState, { drop: TDrop[] }>;
  total: number;
  hasMore?: boolean;
  aggregations?: Record<string, unknown>;
  fetchDrops: Thunk<TDropsState, { query: DropQuery; appendResult?: boolean }>;
  updateDropList: Action<TDropsState, { drop: TDrop; action: 'ADD' | 'UPDATE' | 'PUT' | 'DELETE' }>;
}

const DROPS_SEARCH_PAGE_LIMIT = 20;

const DropStore: TDropsState = {
  total: 0,
  drops: [],
  setDropList: action((state, payload) => {
    state.drops = [...payload.drop];
  }),
  hasMore: true,
  aggregations: {},
  updateDropList: action((state, payload) => {
    state.drops = helpers.updateItemList(state.drops, payload.drop, payload.action);
  }),
  fetchDrops: thunk(async (actions, { appendResult = true, query }, { getState }) => {
    const state = getState();
    if (appendResult === false) state.drops = [];
    const searchResponse = await DropModel.search(undefined, {
      term: query.query.searchTerm,
      filter: {
        limit: DROPS_SEARCH_PAGE_LIMIT,
        // eslint-disable-next-line no-nested-ternary
        skip: appendResult ? state.drops.length : query.query.page ? (query.query.page - 1) * DROPS_SEARCH_PAGE_LIMIT : 0,
        ...query.searchFilter,
        ...defaultSort,
        Event: {
          fields: [
            'name',
            'id',
            'description',
            'tierType',
            '_tiers',
            'slug',
            'eventDates',
            '_primaryImage',
            'tagIds',
            'tags',
            'userId',
            'communityId',
            'frequency',
            'repeatUntil',
            'subtitle',
            'type',
            'platformName',
            'platformUrl',
            'address',
            'rsvpUrl',
            'featured',
            'platform',
            'isFavourited',
            'isReminded',
            'reminderId',
            'prices',
            'category',
            'when',
            'canceled',
            'isUserFeatured',
            'isPublished',
            'favouriteCount',
            'reminderCount',
            'dateChanged',
            'timeZone',
            'ticketingInfo',
            'collaboratingCommunityIds',
            'collaborators',
            'taxInfo',
            'location',
            'isPasswordProtected',
            'subType',
          ] as (keyof TEvent)[],
          include: [
            'tags',
            'ticketingInfo',
            {
              relation: 'community',
              scope: {
                fields: ['_profileImages', 'id', 'name', 'slug'],
              },
            },
            'includePurchasedTickets',
            // 'includePurchasersMeta',
            'collaborators',
          ],
        },
        Video: resourceIncludeFilter,
        Collect: resourceIncludeFilter,
        Podcast: resourceIncludeFilter,
        Article: resourceIncludeFilter,
        Album: resourceIncludeFilter,
        Soundtrack: resourceIncludeFilter,
      },
    });
    state.drops = appendResult ? [...state.drops, ...searchResponse.results] : searchResponse.results;
    if (appendResult === false) {
      state.total = searchResponse.total;
      state.aggregations = searchResponse.aggregations;
      state.hasMore = true;
    } else {
      state.hasMore = state.total > state.drops.length || Boolean(searchResponse.results.length);
      state.total = searchResponse.total;
    }
  }),
};

export default DropStore;
