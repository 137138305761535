/* eslint-disable import/no-cycle */
import { action, Action, thunk, Thunk } from 'easy-peasy';
import { TPurchasedTicket } from 'Models/PurchasedTicket/@types';
import UserModel from 'Models/User';
import { TRootStore } from 'Stores';
import { InfiniteScrollSetAction } from 'Stores/UserFavouriteEvents';

export type ordersWhenFilter = 'past' | 'ongoing' | 'future' | 'tbd';

export interface TTicketsState {
  upcomingEventTickets: TPurchasedTicket[];
  setUpcomingEventTickets: Action<TTicketsState, { list: TPurchasedTicket[]; action: InfiniteScrollSetAction }>;

  fetchPurchasedTickets: Thunk<
    TTicketsState,
    { when: ordersWhenFilter; action: InfiniteScrollSetAction; skip?: number; limit?: number },
    null,
    TRootStore
  >;

  pastEventTickets: TPurchasedTicket[];
  setPastEventTickets: Action<TTicketsState, { list: TPurchasedTicket[]; action: InfiniteScrollSetAction }>;

  ongoingEventTickets: TPurchasedTicket[];
  setOngoingEventTickets: Action<TTicketsState, { list: TPurchasedTicket[]; action: InfiniteScrollSetAction }>;

  tbdEventTickets: TPurchasedTicket[];
  setTbdEventTickets: Action<TTicketsState, { list: TPurchasedTicket[]; action: InfiniteScrollSetAction }>;

  loading: boolean;
  setLoading: Action<TTicketsState, boolean>;

  hasMoreUpcomingOrders: boolean;
  setHasMoreUpcomingOrders: Action<TTicketsState, boolean>;

  hasMorePastOrders: boolean;
  setHasMorePastOrders: Action<TTicketsState, boolean>;

  hasMoreOngoingOrders: boolean;
  setHasMoreOngoingOrders: Action<TTicketsState, boolean>;

  hasMoreTbdOrders: boolean;
  setHasMoreTbdOrders: Action<TTicketsState, boolean>;
}

const TicketsStore: TTicketsState = {
  loading: true,

  pastEventTickets: [],
  upcomingEventTickets: [],
  ongoingEventTickets: [],
  tbdEventTickets: [],

  hasMorePastOrders: true,
  hasMoreUpcomingOrders: true,
  hasMoreOngoingOrders: true,
  hasMoreTbdOrders: true,

  setLoading: action((state, payload) => {
    state.loading = payload;
  }),

  setUpcomingEventTickets: action((state, payload) => {
    state.upcomingEventTickets = payload.action === 'APPEND' ? [...state.upcomingEventTickets, ...payload.list] : payload.list;
  }),
  setHasMoreUpcomingOrders: action((state, payload) => {
    state.hasMoreUpcomingOrders = payload;
  }),
  setPastEventTickets: action((state, payload) => {
    state.pastEventTickets = payload.action === 'APPEND' ? [...state.pastEventTickets, ...payload.list] : payload.list;
  }),
  setTbdEventTickets: action((state, payload) => {
    state.tbdEventTickets = payload.action === 'APPEND' ? [...state.tbdEventTickets, ...payload.list] : payload.list;
  }),

  setHasMorePastOrders: action((state, payload) => {
    state.hasMorePastOrders = payload;
  }),
  setOngoingEventTickets: action((state, payload) => {
    state.ongoingEventTickets = payload.action === 'APPEND' ? [...state.ongoingEventTickets, ...payload.list] : payload.list;
  }),
  setHasMoreOngoingOrders: action((state, payload) => {
    state.hasMoreOngoingOrders = payload;
  }),
  setHasMoreTbdOrders: action((state, payload) => {
    state.hasMoreTbdOrders = payload;
  }),

  fetchPurchasedTickets: thunk(async (actions, { when, action, skip = 0, limit = 10 }, { getStoreState }) => {

    const {
      AuthStore: { appUser },
    } = getStoreState();
    if (!appUser) return;

    const data = await UserModel.getPurchasedTickets(
      appUser.id,
      {
        skip,
        limit,
        eventFilter: {
          fields: ['canceled', 'address', 'platform', 'platformName', 'platformUrl', 'type'],
        },
      },
      when
    );

    switch (when) {
      case 'future':
        if (data.length < limit) actions.setHasMoreUpcomingOrders(false);
        actions.setUpcomingEventTickets({ list: data, action });
        break;
      case 'ongoing':
        if (data.length < limit) actions.setHasMoreOngoingOrders(false);
        actions.setOngoingEventTickets({ list: data, action });
        break;
      case 'past':
        if (data.length < limit) actions.setHasMorePastOrders(false);
        actions.setPastEventTickets({ list: data, action });
        break;
      case 'tbd':
        if (data.length < limit) actions.setHasMoreTbdOrders(false);
        actions.setTbdEventTickets({ list: data, action });
        break;
      default:
        break;
    }
    actions.setLoading(false);
  }),
};

export default TicketsStore;
