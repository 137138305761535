// import { action, Action, thunk, Thunk } from 'easy-peasy';
// import { TRootStore } from 'Stores';

// export interface UserDropsState { }

// const UserDropsStore: UserDropsState = {};

// export default UserDropsStore;

import { action, Action, ActionOn, actionOn, thunk, Thunk } from 'easy-peasy';
import DropQuery, { FEATURED_SHOULD_FILTER, userShouldFilter } from 'Features/Query/DropsQuery';
import { omit } from 'lodash';
import { TFilter } from 'Models/App/@types';
import DropModel from 'Models/Drop';
import { TDrop, TDropSearchResponse } from 'Models/Drop/@types';
import { TEvent } from 'Models/Event';
import { EVENT_FIELDS } from 'Models/Event/utils';
import { TRootStore } from 'Stores';
import { resourceFilter } from 'Stores/CommunityDrops';

export const DROP_LIST_LIMIT = 20;

export interface UserDropsState {
  total: number;
  aggregations?: Record<string, unknown>;

  drops: TDrop[];
  setDropList: Action<UserDropsState, { drops: TDrop[] }>;

  hasMore: boolean;
  setHasMore: Action<UserDropsState, boolean>;

  isFeaturedPresent: boolean;
  setIsFeaturedPresent: Action<UserDropsState, boolean>;

  featuredDrops: TDrop[];

  fetchDrops: Thunk<UserDropsState, { id: string; query: DropQuery; page?: number }>;

  onContentFavorite: ActionOn<UserDropsState, TRootStore>;
  onContentUnFavorite: ActionOn<UserDropsState, TRootStore>;
  onEventRemind: ActionOn<UserDropsState, TRootStore>;
  onEventUnRemind: ActionOn<UserDropsState, TRootStore>;
}

const DROPS_SEARCH_PAGE_LIMIT = 20;

const UserDropsStore: UserDropsState = {
  total: 0,
  aggregations: {},

  drops: [],
  setDropList: action((state, payload) => {
    state.drops = [...state.drops, ...payload.drops];
  }),

  hasMore: true,
  setHasMore: action((state, payload) => {
    state.hasMore = payload;
  }),

  isFeaturedPresent: true,
  setIsFeaturedPresent: action((state, payload) => {
    state.isFeaturedPresent = payload;
  }),

  featuredDrops: [],

  fetchDrops: thunk(async (actions, { id, query, page = 0 }, { getState }) => {
    if (page === 0) {
      getState().isFeaturedPresent = true;
      getState().featuredDrops = [];
      getState().drops = [];
    }
    const state = getState();
    if (getState().isFeaturedPresent) {
      const getFeaturedDrops = await getDrops(undefined, {
        filter: {
          limit: DROPS_SEARCH_PAGE_LIMIT,
          skip: page * DROPS_SEARCH_PAGE_LIMIT,
          ...query.featuredSearchFilter,
          ...TDropFilters(id, true),
        },
      });
      state.total = getFeaturedDrops.total;
      state.aggregations = getFeaturedDrops.aggregations;
      state.drops = [...state.drops, ...getFeaturedDrops.results];
      if (getFeaturedDrops.results.length < DROPS_SEARCH_PAGE_LIMIT) {
        state.featuredDrops = [...state.drops];
        state.isFeaturedPresent = false;
        const getUnfeaturedDrops = await getDrops(undefined, {
          filter: {
            limit: DROPS_SEARCH_PAGE_LIMIT,
            skip: 0,
            ...query.searchFilter,
            ...TDropFilters(id),
          },
        });
        state.total = getUnfeaturedDrops.total;
        state.aggregations = getUnfeaturedDrops.aggregations;
        state.drops = [...state.drops, ...getUnfeaturedDrops.results];
        actions.setHasMore(!(getUnfeaturedDrops.results.length < DROPS_SEARCH_PAGE_LIMIT));
      }
    } else {
      const getUnfeaturedDrops = await getDrops(undefined, {
        filter: {
          limit: DROPS_SEARCH_PAGE_LIMIT,
          skip: state.drops.length - state.featuredDrops.length ?? 0,
          ...query.searchFilter,
          ...TDropFilters(id),
        },
      });
      state.total = getUnfeaturedDrops.total;
      state.aggregations = getUnfeaturedDrops.aggregations;
      state.drops = [...state.drops, ...getUnfeaturedDrops.results];
      actions.setHasMore(!(getUnfeaturedDrops.results.length < DROPS_SEARCH_PAGE_LIMIT));
    }
  }),

  onContentFavorite: actionOn(
    (actions, storeActions) => storeActions.AuthStore.contentFavorited,
    (state, { payload: { event, favouriteId } }) => {
      state.drops = state.drops.map((e) => (e.id === event.id ? { ...e, ...event, isFavourited: true, favouriteId } : e)) as TDrop[];
    },
  ),
  onContentUnFavorite: actionOn(
    (actions, storeActions) => storeActions.AuthStore.contentUnFavorited,
    (state, { payload }) => {
      state.drops = state.drops.map((e) => (e.id === payload ? omit(e, ['isFavourited', 'favouriteId']) : e)) as TDrop[];
    },
  ),
  onEventRemind: actionOn(
    (actions, storeActions) => storeActions.AuthStore.eventReminded,
    (state, { payload: { event, reminderId } }) => {
      state.drops = state.drops.map((e) => (e.id === event.id ? { ...e, ...event, isReminded: true, reminderId } : e));
    },
  ),
  onEventUnRemind: actionOn(
    (actions, storeActions) => storeActions.AuthStore.eventUnReminded,
    (state, { payload }) => {
      state.drops = state.drops.map((e) => (e.reminderId === payload ? omit(e, ['isReminded', 'reminderId']) : e)) as TDrop[];
    },
  ),
};

const getDrops = async (id: string | undefined, filters: TFilter): Promise<TDropSearchResponse> => {
  const featuredDrops = await DropModel.search(id, filters);
  return featuredDrops;
};

const TDropFilters = (id: string, isFeatured?: boolean) => ({
  Event: {
    fields: EVENT_FIELDS,
    include: [
      'tags',
      'ticketingInfo',
      {
        relation: 'community',
        scope: {
          fields: ['_profileImages', 'id', 'name', 'slug'],
        },
      },
      'includePurchasedTickets',
      // 'includePurchasersMeta',
      'collaborators',
    ],
  },
  Video: resourceFilter(id, isFeatured),
  Collect: resourceFilter(id, isFeatured),
  Podcast: resourceFilter(id, isFeatured),
  Article: resourceFilter(id, isFeatured),
});

export default UserDropsStore;
