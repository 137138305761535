/* eslint-disable default-case */
import { action, Action, actionOn, ActionOn, thunk, Thunk } from 'easy-peasy';
import { TResultItem } from 'Features/Search/useSearch';
import { omit } from 'lodash';
import AppModel from 'Models/App';
import { TEvent } from 'Models/Event';
import ResourceModel from 'Models/Resource';
import { ResourceResponse, TResourceResponseResult } from 'Models/Resource/@types';
import { TRootStore } from 'Stores';
import { RESOURCE_SEARCH_QUERY_CONSTANT } from 'Stores/SearchPage';
import { InfiniteScrollSetAction } from 'Stores/UserFavouriteEvents';

type Res = { results: TResultItem[]; total: number };

export enum SearchMoreEntityType {
  COMMUNITY = 'community',
  USER = 'user',
  DROP = 'drop',
}

export interface TSearchMoreState {
  searchTerm: string;
  setSearchTerm: Action<TSearchMoreState, string>;

  type: SearchMoreEntityType;
  setType: Action<TSearchMoreState, SearchMoreEntityType>;

  page: number;
  setPage: Action<TSearchMoreState, number>;

  total: number;
  setTotal: Action<TSearchMoreState, number>;

  loading: boolean;
  setLoading: Action<TSearchMoreState, boolean>;

  drops: ResourceResponse['results'];
  setDrops: Action<TSearchMoreState, { data: ResourceResponse['results']; action: InfiniteScrollSetAction }>;

  communities: Res['results'];
  setCommunities: Action<TSearchMoreState, { data: Res['results']; action: InfiniteScrollSetAction }>;

  users: Res['results'];
  setUsers: Action<TSearchMoreState, { data: Res['results']; action: InfiniteScrollSetAction }>;

  search: Thunk<TSearchMoreState, { type: SearchMoreEntityType; searchTerm: string; action: InfiniteScrollSetAction; skip?: number; limit?: number }>;

  onContentFavorite: ActionOn<TSearchMoreState, TRootStore>;
  onContentUnFavorite: ActionOn<TSearchMoreState, TRootStore>;
  onEventRemind: ActionOn<TSearchMoreState, TRootStore>;
  onEventUnRemind: ActionOn<TSearchMoreState, TRootStore>;
}

function getPaginationFilter(skip = 0, limit = 24) {
  return {
    limit,
    // skip: (page - 1) * limit,
    skip,
  };
}

const SearchMoreStore: TSearchMoreState = {
  loading: false,
  page: 1,
  drops: [],
  communities: [],
  searchTerm: '',
  total: 0,
  type: SearchMoreEntityType.DROP,
  users: [],
  setType: action((state, payload) => {
    state.type = payload;
  }),
  setSearchTerm: action((state, payload) => {
    state.searchTerm = payload;
  }),
  setPage: action((state, payload) => {
    state.page = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setDrops: action((state, payload) => {
    state.drops = payload.action === 'APPEND' ? [...state.drops, ...payload.data] : payload.data;
  }),
  setCommunities: action((state, payload) => {
    state.communities = payload.action === 'APPEND' ? [...state.communities, ...payload.data] : payload.data;
  }),
  setUsers: action((state, payload) => {
    state.users = payload.action === 'APPEND' ? [...state.users, ...payload.data] : payload.data;
  }),
  setTotal: action((state, payload) => {
    state.total = payload;
  }),

  search: thunk(async (actions, { type, searchTerm, action, skip, limit }, { getState }) => {
    actions.setLoading(true);
    const paginationFilter = getPaginationFilter(skip, limit);

    switch (type) {
      case SearchMoreEntityType.DROP: {
        const resourceResponse = await ResourceModel.getUserResourcesAndEvents(undefined, {
          term: searchTerm,
          filter: { ...paginationFilter, ...RESOURCE_SEARCH_QUERY_CONSTANT, where: { isPublished: true } },
        });
        actions.setDrops({ data: resourceResponse.results, action });
        actions.setTotal(resourceResponse.total);
        break;
      }
      case SearchMoreEntityType.COMMUNITY: {
        const data = await AppModel.searchEntities(searchTerm, { ...paginationFilter, doc_type: 'Community' });
        actions.setCommunities({ data: data.results.map((r) => r.hit), action });
        actions.setTotal(data.total);
        break;
      }
      case SearchMoreEntityType.USER: {
        const data = await AppModel.searchEntities(searchTerm, { ...paginationFilter, doc_type: 'User' });
        actions.setUsers({ data: data.results.map((r) => r.hit), action });
        actions.setTotal(data.total);
        break;
      }
      default: {
        // TODO
      }
    }
    actions.setLoading(false);
  }),

  onContentFavorite: actionOn(
    (actions, storeActions) => storeActions.AuthStore.contentFavorited,
    (state, { payload: { event, favouriteId } }) => {
      state.drops =
        state.drops?.map((e) =>
          e.hit.id === event.id ? ({ ...e, hit: { ...e.hit, ...event, isFavourited: true, favouriteId } } as TResourceResponseResult) : e
        ) ?? [];
    }
  ),
  onContentUnFavorite: actionOn(
    (actions, storeActions) => storeActions.AuthStore.contentUnFavorited,
    (state, { payload }) => {
      state.drops =
        state.drops?.map((e) => (e.hit.id === payload ? ({ ...omit(e, ['hit.isFavourited', 'hit.favouriteId']) } as TResourceResponseResult) : e)) ??
        [];
    }
  ),
  onEventRemind: actionOn(
    (actions, storeActions) => storeActions.AuthStore.eventReminded,
    (state, { payload: { event, reminderId } }) => {
      state.drops = state.drops?.map((e) => (e.hit.id === event.id ? { ...e, hit: { ...e.hit, ...event, isReminded: true, reminderId } } : e)) ?? [];
    }
  ),
  onEventUnRemind: actionOn(
    (actions, storeActions) => storeActions.AuthStore.eventUnReminded,
    (state, { payload }) => {
      state.drops =
        state.drops?.map((e) =>
          (e.hit as TEvent).reminderId === payload ? ({ ...omit(e, ['hit.isReminded', 'hit.reminderId']) } as TResourceResponseResult) : e
        ) ?? [];
    }
  ),
};

export default SearchMoreStore;
