/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { TResource } from 'Models/Resource/@types';
import { TContentType } from 'Models/Drop/@types';
import { TEvent } from 'Models/Event/@types';
import { User } from 'Models/User/@types';
import clsx from 'clsx';
import { TResultItem } from './useSearch';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import EventSearchResultItem from './EventSearchResultItem';
import PeopleSearchResultItem from './PeopleSearchResultItem';

export interface SearchResultProps {
  searchResult: TResultItem[];
  clickAway?: () => void;
  loading?: boolean;
  onResultClick?: () => void;
}

const SearchResult: FC<SearchResultProps> = (props) => {
  const classes = useStyles();
  const { isDeviceSm } = useMediaQuery();
  const { loading = false, clickAway, searchResult, onResultClick } = props;
  const history = useHistory();

  // eslint-disable-next-line camelcase
  const handleItemClick = (doc_type: string) => {
    if (clickAway) clickAway();

    // history.push('...')
  };

  const renderItem = (item: TResultItem) => {
    switch (item.doc_type) {
      case 'Event':
      case 'Podcast':
      case 'Collect':
      case 'Video':
      case 'Article':
      case 'Album':
      case 'Soundtrack':
        return (
          <EventSearchResultItem
            onClick={(e) => onResultClick?.()}
            className={clsx(classes.searchResultItem, classes.main)}
            key={item.id}
            eventType={item.doc_type as TContentType}
            event={item as TEvent | TResource}
          />
        );
      case 'Community':
        return (
          <PeopleSearchResultItem
            onClick={(e) => onResultClick?.()}
            className={clsx(classes.searchResultItem, classes.main)}
            key={item.id}
            user={item as unknown as User}
          />
        );
      // case 'Collect':
      //     return null
      case 'user':
        return (
          <PeopleSearchResultItem
            type="user"
            onClick={(e) => onResultClick?.()}
            className={clsx(classes.searchResultItem, classes.main)}
            key={item.id}
            user={item as unknown as User}
          />
        );
      default:
        return null;
    }
  };

  return <Box className={classes.root}>{searchResult.map(renderItem)}</Box>;
};

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    // width: 600,
    // padding: 20,
    display: 'flex',
    flexDirection: 'column',
    transition: theme.transitions.create('height'),
    paddingBottom: theme.spacing(2),
  },
  searchResultItem: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderBottom: `1px solid ${theme.palette.common.black}`,
    '&:first-child': {
      paddingTop: 0,
    },
    // '&:last-child': {
    //     borderBottom: 'none',
    //     marginBottom: theme.spacing(2)
    // }
  },
  main: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
}));

export default SearchResult;
