import React, { FC } from 'react';
import { Box, Link, makeStyles } from '@material-ui/core';
import PgTypo from 'Components/PgTypo';
import { NFTDetails } from 'Models/Community/@types';
import helpers from 'Utils/helpers';
import Slider, { Settings } from 'react-slick';
import Spacer from 'Components/Spacer';
import { COLOR } from 'Theme/themeConstants';
import CustomCarousel from 'Components/CustomCarousel';

interface NFTDetailsSliderProps {
  nftGates: NFTDetails[];
}

const NFTDetailsSlider: FC<NFTDetailsSliderProps> = ({ nftGates }) => {
  const classes = useStyles();

  return (
    <CustomCarousel
      arrowProps={{ additionalClassName: classes.arrow }}
      showArrows={nftGates.length > 1}
      items={nftGates}
      renderItem={({ image, marketplaceUrl, description, tokenAddress: token }) => (
        <Box>
          <Box key={token} display="flex" flexDirection="column" alignItems="center">
            <PgTypo b4 component="p" align="center" className={classes.description}>
              {description}
            </PgTypo>
            <Link href={marketplaceUrl} display="block" align="center" className={classes.link}>
              {marketplaceUrl}
            </Link>
            <Spacer height={12} />
            <img className={classes.img} src={helpers.getPictureUrl(image)} />
          </Box>
        </Box>
      )}
    />
  );
};

export default NFTDetailsSlider;

const useStyles = makeStyles((theme) => ({
  link: {
    color: COLOR.tertiary.startup,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
  },
  img: {
    width: 200,
  },
  arrow: {
    '&.slick-arrow > svg': {
      color: 'black',
    },
  },
  description: { wordBreak: 'break-word' },
}));
