/* eslint-disable react/require-default-props */
/* eslint-disable no-underscore-dangle */
import React, { FC, useMemo } from 'react';
import { alpha, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, BoxProps, ButtonBase } from '@material-ui/core';
import { TResource } from 'Models/Resource/@types';
import { TContentType } from 'Models/Drop/@types';
import { useHistory } from 'react-router-dom';
import { TEvent } from 'Models/Event/@types';
import { generatePath } from 'react-router';
import { APP_ROUTES, ResourcePathMap } from 'Routes';
import PgTypo from 'Components/PgTypo';
import helpers from '../../Utils/helpers';
import { THEME_PALETTE } from '../../Theme/themeConstants';

export interface EventSearchResultItemProps extends Omit<BoxProps, 'onClick'> {
  event: TEvent | TResource;
  eventType?: TContentType;
  onClick?: (event: TEvent) => void;
}

const EventSearchResultItem: FC<EventSearchResultItemProps> = (props) => {
  const { event, eventType, onClick, children, ...boxProps } = props;
  const classes = useStyles();

  const history = useHistory();

  const handleClick = () => {
    if (onClick) {
      onClick(event as TEvent);
    }
    if (eventType === 'Event') {
      const _event = event as TEvent;
      // history.push(`/events/${_event.slug || event.id}`);
      // window.location.href = generatePath(APP_ROUTES.EVENT_PAGE.path, { id: _event.slug ?? _event.id });
      const path = generatePath(APP_ROUTES.EVENT_PAGE.path, { id: _event.slug ?? _event.id });
      history.push(path);
    } else {
      const _resource = event as TResource;
      // window.open(_resource.link);
      // window.location.href = generatePath(ResourcePathMap[(eventType?.toLowerCase() as TContentType) || 'video'], {
      //   id: _resource.slug ?? _resource.id,
      // });
      const _path = generatePath(ResourcePathMap[(eventType?.toLowerCase() as TContentType) || 'video'], {
        id: _resource.slug ?? _resource.id,
      });
      history.push(_path);
    }
  };

  const data = useMemo<{
    imageUrl: string;
    name: string;
    desc?: string;
  }>(() => {
    if (eventType === 'Event') {
      const _event: TEvent = event as TEvent;
      return {
        imageUrl: helpers.getPictureUrl(_event._primaryImage ?? _event.primaryImage) || helpers.getDummyImageUrl(200, 200),
        name: _event.name,
        desc: _event.subtitle,
      };
    }
    const _resource: TResource = event as TResource;
    return {
      imageUrl: helpers.getPictureUrl(_resource._resourceImages?.[0]) || helpers.getDummyImageUrl(200, 200),
      name: _resource.title,
      desc: _resource.description,
    };
  }, [event, eventType]);

  return (
    <Box {...boxProps} onClick={handleClick} component={ButtonBase}>
      {/* <div onClick={handleClick} className={clsx(className, classes.root)}> */}
      <Box position="relative">
        <img src={data.imageUrl} className={classes.eventImage} alt={data.name} />
        {event.doc_type && (
          <PgTypo b5 className={classes.tag}>
            {event.doc_type.toLowerCase() === 'collect' ? 'Merch' : event.doc_type}
          </PgTypo>
        )}
      </Box>
      <Box pl={1} alignItems="flex-start" display="flex" flexDirection="column">
        <PgTypo c2 align="left" limitLines={2}>
          {data.name}
        </PgTypo>
        <PgTypo b4 limitLines={1} align="left">
          {data.desc}
        </PgTypo>
      </Box>
      {/* </div> */}
    </Box>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  eventImage: {
    width: 80,
    height: 80,
    objectFit: 'cover',
  },
  dot: {
    width: 3,
    height: 3,
    borderRadius: 1.5,
    backgroundColor: THEME_PALETTE.others.purple,
    verticalAlign: 'middle',
    display: 'inline-block',
    margin: theme.spacing(0, 0.5),
  },
  tag: {
    position: 'absolute',
    top: 3,
    left: 3,
    padding: '0px 5px',
    backgroundColor: alpha(theme.palette.common.black, 0.5),
    textTransform: 'capitalize',
    color: 'white',
  },
}));

export default EventSearchResultItem;
