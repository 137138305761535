import React from 'react';
// eslint-disable-next-line import/no-cycle
import { DialogProvider } from 'Dialogs/DialogContext';
import AppDialogProvider from './AppDialogContext';
import AppToastProvider from './AppToastContext';
import EnqueToastProvider from './AppToastContext/EnqueToast';
import StripeContextProvider from './StripeContext';
import PointsDialogProvider from './PointsDialogContext';
import { ChatProvider } from './ChatContext';

const RootContextProviders: React.FC = (props) => {
  const { children } = props;
  return (
    <EnqueToastProvider>
      <AppToastProvider>
        <AppDialogProvider>
          <PointsDialogProvider>
            <DialogProvider>
              <StripeContextProvider>
                <ChatProvider>{children}</ChatProvider>
              </StripeContextProvider>
            </DialogProvider>
          </PointsDialogProvider>
        </AppDialogProvider>
      </AppToastProvider>
    </EnqueToastProvider>
  );
};

export default RootContextProviders;
