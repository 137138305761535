import React, { FC, useMemo, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, ButtonBase } from '@material-ui/core';
import { Formik, FormikConfig, useFormikContext } from 'formik';
import { MLFormContent, RowSchema, attachField } from 'react-forms';
import { StripeCardElement } from '@stripe/stripe-js';

import { ChevronLeft } from '@material-ui/icons';
import clsx from 'clsx';

import SubmitButton from 'Components/SubmitButton';
import { TBill } from 'Models/Transactions/@types';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { TEvent } from 'Models/Event';
import * as Yup from 'yup';
import Typo from '../../Components/Typo';
import { BORDER_BLACK, THEME_PALETTE, TICKETING_FORM_COLOR } from '../../Theme/themeConstants';
import { ClaimRewardInfo } from 'Screens/EventPage/ClaimReward';
import CollapsableContainer from 'Components/CollapsableContainer';

export interface StripeFormProps {
    initialValues?: Partial<StripeFormData>;
    onBack?: () => void;
    loading?: boolean;
    NativePayButton: JSX.Element | null;
    onSubmit: FormikConfig<Partial<StripeFormData>>['onSubmit'];
    bill: TBill | null;
    variant?: 'paid' | 'free';
    event: TEvent;
    totalTickets: number;
}

export type StripeFormData = {
    name: string;
    stripeCard: StripeCardElement | null;
    zipCode: string;
};

// attachField('stripe-card', <StripeCardDetail />)
export const StripeFormValidationSchema = Yup.object<Partial<StripeFormData>>({
    name: Yup.string().required('Name required'),
    // zipCode: Yup.number().typeError('Zip code must be a number').required('Zip code required'),
    zipCode: Yup.string().required('Zip code required'),
});
const StripeForm: FC<StripeFormProps> = (props) => {
    const { initialValues = {}, onSubmit } = props;

    return (
        <Formik<Partial<StripeFormData>> initialValues={initialValues} onSubmit={onSubmit} validationSchema={StripeFormValidationSchema}>
            <FormConsumer {...props} />
        </Formik>
    );
};

const FormConsumer: FC<StripeFormProps> = (props) => {
    const formikProps = useFormikContext<Partial<StripeFormData>>();
    const [paymentType, setPaymentType] = useState('card');
    const { variant, event, bill, totalTickets } = props;
    const { isDeviceSm } = useMediaQuery();
    const classes = useStyles({});

    const paymentOptions = [
        { type: 'card' },
        // { type: 'paypal' },
    ];

    const nameField = {
        type: 'text',
        valueKey: 'name',
        fieldProps: { fullWidth: true, label: 'Name on Card', placeholder: 'Name', className: classes.textField },
    };
    const zipCodeField = {
        type: 'text',
        valueKey: 'zipCode',
        fieldProps: { fullWidth: true, label: 'Zip Code', placeholder: 'Zip Code', className: clsx(classes.textField, classes.zipCodeField) },
    };
    const formFields = isDeviceSm ? [nameField, zipCodeField] : [[nameField, zipCodeField]];

    const schema: RowSchema[] = useMemo(
        () => [
            [{ type: 'stripe-card', valueKey: 'stripeCard', fieldProps: { label: 'Credit or Debit Card' } }],
            // [
            //   {
            //     type: 'section-header',
            //     valueKey: 'section-header-5',
            //     fieldProps: {
            //       title: 'Name on Card',
            //       style: {
            //         letterSpacing: 0,
            //         fontWeight: 600,
            //         position: 'relative',
            //         top: '7px',
            //         marginTop: '-20px',
            //       },
            //       variant: 'caption',
            //     },
            //   },
            //   {
            //     type: 'section-header',
            //     valueKey: 'section-header-5',
            //     fieldProps: {
            //       title: 'Zip Code',
            //       style: {
            //         letterSpacing: 0,
            //         fontWeight: 600,
            //         position: 'relative',
            //         top: '7px',
            //         marginTop: '-20px',
            //       },
            //       variant: 'caption',
            //     },
            //   },
            // ],
            ...formFields,
            // [
            //     {
            //         type: 'checkbox',
            //         valueKey: 'x',
            //         fieldProps: {
            //             label: (
            //                 <Typo style={{ paddingTop: '4px' }} variant="caption" weight="medium">
            //                     SAVE THIS CARD TO MY ACCOUNT.
            //                 </Typo>
            //             ),
            //             color: 'secondary',
            //             style: { color: 'black' },
            //             labelStyle: { color: 'black' },
            //         },
            //     },
            // ],
        ],
        [formFields],
    );

    const validateStripeCardElements = () => {
        const emptyClass = 'StripeElement--empty';
        const invalidClass = 'StripeElement--invalid';
        const completeClass = 'StripeElement--complete';

        const configs = [
            { id: 'card-number-element', label: 'Card number' },
            { id: 'card-expiry-element', label: 'Card expiry' },
            { id: 'card-cvc-element', label: 'Card CVC' },
        ];

        configs.forEach(({ id, label }) => {
            const stripeEl = document.getElementById(id);
            if (stripeEl) {
                if (stripeEl.classList.contains(emptyClass)) {
                    const errorEl = document.getElementById(`${id}-error`);
                    if (errorEl) errorEl.innerHTML = `${label} required`;
                } else if (stripeEl.classList.contains(invalidClass)) {
                    const errorEl = document.getElementById(`${id}-error`);
                    if (errorEl) errorEl.innerHTML = `${label} invalid`;
                } else if (stripeEl.classList.contains(completeClass)) {
                    const errorEl = document.getElementById(`${id}-error`);
                    if (errorEl) errorEl.innerHTML = '';
                }
            }
        });
    };

    const handleSubmit: React.DOMAttributes<HTMLFormElement>['onSubmit'] = (e) => {
        validateStripeCardElements();
        formikProps.handleSubmit(e);
    };

    return (
        <form className={classes.form} onSubmit={handleSubmit}>
            <Box p={isDeviceSm ? '38px 0 8px 20.7px' : '35.2px 0 26px 20.7px'}>
                <Typo variant="h6" weight="bold">
                    Payment Information
                </Typo>
            </Box>
            {/*  {isDeviceSm ? (
        <Box className={classes.orderSummaryBox}>
          <MobileTicketCard variant={variant} event={event} bill={bill} totalTickets={totalTickets} badgeClass={classes.badge} />
        </Box>
      ) : null} */}
            <Box p={isDeviceSm ? 2 : 5} pt={0} pb={0} display="flex" flexDirection="column">
                {props.NativePayButton && (
                    <Box my={2} display="flex" alignItems="center">
                        {paymentOptions.map((p) => (
                            <Box
                                onClick={() => setPaymentType(p.type)}
                                component={ButtonBase}
                                className={clsx(classes.paymentOptionItem, { [classes.activePaymentOption]: p.type === paymentType })}
                                key={p.type}
                            >
                                {p.type}
                            </Box>
                        ))}
                        {props.NativePayButton ? <Box className={clsx(classes.nativePay)}>{props.NativePayButton}</Box> : null}
                    </Box>
                )}
                <Box my={2}>
                    {/* <Typo weight="bold">Add a new card</Typo>
          <Spacer height={20} /> */}
                    <MLFormContent settings={{ horizontalSpacing: 40 }} schema={schema} formikProps={formikProps} formId="ticket-card-info" />
                </Box>
            </Box>
            {event.rewardSettings ? (
                <CollapsableContainer title='REWARD' containerProps={{ paddingX: isDeviceSm ? 2 : 5 }}>
                    <ClaimRewardInfo
                        dropType='event'
                        onClick={async () => { }}
                        rewardSettings={event.rewardSettings}
                    />
                </CollapsableContainer>

            ) : null}
            {/* <Box flex={1} /> */}
            <Box className={classes.footer}>
                {/* <Button className={classes.backButton} onClick={props.onBack}>
          <ChevronLeft />
          &nbsp;BACK
        </Button> */}
                <SubmitButton loading={props.loading || false} variant="contained" color="primary" className={classes.submitButton} type="submit">
                    Pay ${props.bill?.total || 0}
                </SubmitButton>
            </Box>
        </form>
    );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
    form: {
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        '& label': {
            // color: 'white',
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: '100vh',
        },
    },
    footer: {
        height: 83,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: theme.spacing('14px', 5),
        borderTop: `1px solid ${THEME_PALETTE.grey.A200}`,
        '& img': {
            position: 'relative',
            top: 4,
            margin: '0px 20px 0px 4px',
        },
    },
    paymentOptionItem: {
        width: 90,
        height: 40,
        marginRight: 10,
        border: `1px solid ${theme.palette.common.white}`,
    },
    activePaymentOption: {
        backgroundColor: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.secondary.main}`,
    },
    backButton: {
        color: theme.palette.common.white,
        '& svg': {
            color: theme.palette.common.white,
        },
    },
    submitButton: {
        fontWeight: 600,
        fontSize: '16px',
    },
    nativePay: {
        width: 90,
        border: `1px solid ${theme.palette.common.white}`,
    },
    orderSummaryBox: {
        backgroundColor: theme.palette.primary.contrastText,
    },
    badge: {
        top: 120,
    },
    textField: {
        // backgroundColor: `${theme.palette.grey.A400}!important`,
        // '& > div.MuiInput-underline:before': {
        //   borderBottom: '0px !important',
        // },
        // '& > div': {
        //   width: '100%',
        //   borderBottom: '0px !important',
        //   // position: 'absolute',
        //   // top: '24px',
        // },
        // '& > div > input': {
        //   padding: '5px 4px 5px 8px',
        //   color: TICKETING_FORM_COLOR,
        // },
        // '& > div:hover': {
        //   border: 'none !important',
        //   backgroundColor: `${theme.palette.grey.A400}!important`,
        // },
        // height: '32px',
        // '& label + .MuiInput-formControl': {
        //   marginTop: '0px !important',
        // },
    },
    zipCodeField: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(4),
        },
    },
}));

export default StripeForm;
