import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, TextField, TextFieldProps } from '@material-ui/core';
import Typo from 'Components/Typo';
import { CheckCircleOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { TICKETING_FORM_COLOR } from 'Theme/themeConstants';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import PgTypo from 'Components/PgTypo';

export interface PromoCodeInputProps extends Pick<TextFieldProps, 'label' | 'value' | 'onChange' | 'helperText' | 'error'> {
  isApplied?: boolean;
  onApply: (promoCode: string) => void;
  onClear: () => void;
  btnClass?: string;
  inputClass?: string;
}

const PromoCodeInput: FC<PromoCodeInputProps> = (props) => {
  const { onApply, onClear, isApplied = false, label = 'Promo code', value = '', onChange, helperText, error, btnClass, inputClass } = props;
  const { isDeviceSm } = useMediaQuery();
  const classes = useStyles({ isDeviceSm });
  return (
    <Box display="flex" flexDirection="column" className={inputClass}>
      <PgTypo b4 gutterBottom>
        {label}
      </PgTypo>
      <Box display="flex">
        <TextField
          value={value}
          onChange={onChange}
          error={error}
          variant="outlined"
          helperText={helperText}
          InputProps={{
            // classes: {
            //   root: classes.root,
            //   // underline: classes.underline,
            //   input: classes.input,
            // },
            endAdornment: isApplied ? <CheckCircleOutlined /> : null,
          }}
          // InputLabelProps={{
          //   classes: {
          //     focused: classes.input,
          //   },
          // }}
          // className={classes.promoFieldClass}
          // variant="filled"
          placeholder="Enter your promo code"
          style={{ height: '32px' }}
          // label={label}
        />
        {isApplied ? (
          <Button className={clsx(classes.button, classes.alignClearButton)} onClick={onClear} variant="outlined">
            CLEAR
          </Button>
        ) : (
          <Button className={classes.button} disabled={value === ''} onClick={() => onApply(value as string)} variant="contained">
            {/* <Typo color="textSecondary" weight="extended-bold" variant="body1"> */}
            APPLY
            {/* </Typo> */}
          </Button>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme, { isDeviceSm?: boolean }>((theme) => ({
  root: {
    // borderBottom: '1px solid #fff',
    color: TICKETING_FORM_COLOR,
    marginRight: 8,
    paddingLeft: '6px',
    // backgroundColor: theme.palette.grey.A400,
  },
  underline: {
    color: TICKETING_FORM_COLOR,
    // border: '0px !important',
    borderBottom: `1px solid ${TICKETING_FORM_COLOR}`,
  },
  input: {
    color: TICKETING_FORM_COLOR,
    '& input:-internal-autofill-selected': {
      backgroundColor: theme.palette.grey.A400,
    },
  },
  button: {
    marginLeft: 8,
    // borderColor: '#fff',
    // width: (props) => (props.isDeviceSm ? '158px' : ''),
    color: '#fff',
    backgroundColor: theme.palette.common.black,
    '&:hover': {
      backgroundColor: theme.palette.common.black,
    },
  },
  alignClearButton: {
    marginBottom: '20px !important',
  },
  promoFieldClass: {
    // width: '177px',
    // height: '32px !important',
    marginTOp: '3px',
    '& > div': {
      border: '0px !important',
      borderRadius: '0px !important',
    },
    '& > div > input': {
      // height: '32px !important',
      padding: '0px !important',
    },
  },
}));

export default PromoCodeInput;
