import React, { useState } from 'react';
import { InputAdornment, Icon, TextFieldProps } from '@material-ui/core';
import { FormConfig } from 'react-forms';
import PgIcon, { TPgIcon } from './PgIcon';

const ICON_SEE = 'icon-view-on';
const ICON_HIDE = 'icon-view-off';

export default (valueKey: string, label: string, darkVariant = true, textFieldProps?: TextFieldProps) => {
  const [passType, setPassType] = useState<'text' | 'password'>('password');
  const toggleType = () => {
    setPassType((type) => (type === 'password' ? 'text' : 'password'));
  };

  const icon = passType === 'password' ? ICON_HIDE : ICON_SEE;
  if (darkVariant)
    return {
      type: passType === 'text' ? 'pg-text' : 'pg-password',
      valueKey,
      fieldProps: {
        label,
        fullWidth: true,
        InputProps: {
          endAdornment: (
            <InputAdornment style={{ cursor: 'pointer' }} position="end" onClick={toggleType}>
              <Icon>{icon}</Icon>
            </InputAdornment>
          ),
        },
      },
    } as FormConfig;

  return {
    type: 'text',
    valueKey,
    fieldProps: {
      ...textFieldProps,
      label,
      fullWidth: true,
      type: passType,
      InputProps: {
        endAdornment: (
          <InputAdornment style={{ cursor: 'pointer' }} position="end" onClick={toggleType}>
            <Icon>
              <PgIcon icon={icon as TPgIcon} />
            </Icon>
          </InputAdornment>
        ),
      },
    } as TextFieldProps,
  } as FormConfig;
};
