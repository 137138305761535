import { action, Action, thunk, Thunk } from 'easy-peasy';
import { TFilter } from 'Models/App/@types';
import CommunityModel from 'Models/Community';
import { TCommunityRefundRequestListData } from 'Models/Community/@types';
import EventModel from 'Models/Event';
import { TPurchasedTicket } from 'Models/PurchasedTicket/@types';
import { TRefundIssue } from 'Models/RefundRequest/@types';
// eslint-disable-next-line import/no-cycle
import { TRootStore } from 'Stores';

export interface EventOrderState {
  order?: TPurchasedTicket;
  fetchOrder: Thunk<EventOrderState, { id: string }, null, TRootStore, Promise<void>>;
  setOrder: Action<EventOrderState, TPurchasedTicket>;

  refundRequest?: TRefundIssue;
  fetchRefundRequest: Thunk<EventOrderState, { id: string }, null, TRootStore, Promise<void>>;
  setRefundRequest: Action<EventOrderState, TRefundIssue>;

  refundRequestListData: TCommunityRefundRequestListData;
  setRefundRequests: Action<EventOrderState, TCommunityRefundRequestListData>;
  fetchRefundRequests: Thunk<EventOrderState, { filter?: TFilter }, null, TRootStore, Promise<void>>;

  loading?: boolean;
  setLoading: Action<EventOrderState, boolean>;
}

const EventOrderStore: EventOrderState = {
  order: undefined,
  loading: false,
  fetchOrder: thunk(async (actions, { id }) => {
    actions.setLoading(true);
    const order = await EventModel.getPurchasedTicketDetail(id, { filter: { include: 'user' } });
    actions.setOrder(order);
    actions.setLoading(false);
  }),
  setOrder: action((state, payload) => {
    state.order = payload;
  }),
  fetchRefundRequest: thunk(async (actions, { id }) => {
    actions.setLoading(true);
    const refundRequest = await EventModel.getRefundRequest(id, { include: ['user', 'transaction', 'event','purchasedTicket'] });
    actions.setRefundRequest(refundRequest);
    actions.setLoading(false);
  }),
  setRefundRequest: action((state, payload) => {
    state.refundRequest = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),

  refundRequestListData: { total: 0, results: [] },
  fetchRefundRequests: thunk(async (actions, { filter }, { getStoreState }) => {
    const communityId = getStoreState().CommunityStore.community?.id;
    if (communityId) {
      const resp = await CommunityModel.getRefundRequestForCommunities(communityId, filter);
      actions.setRefundRequests(resp);
    }
  }),
  setRefundRequests: action((state, payload) => {
    state.refundRequestListData = payload;
  }),
};

export default EventOrderStore;
