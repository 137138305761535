import {
  Box,
  createStyles,
  FormHelperText,
  FormHelperTextProps,
  IconButton,
  InputLabel,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import SvgIcon from 'Components/SvgIcon';
import { FormikProps } from 'formik';
import useAsyncTask from 'Hooks/useAsyncTask';
import { get } from 'lodash';
import { Picture } from 'Models/Picture/@types';
import React, { FC, useEffect, useState } from 'react';
import { getFieldError, IFieldProps, processFilesWithCallback, TFile } from 'react-forms';
import helpers from 'Utils/helpers';
import ICONS from 'Constants/icons.json';
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import { COLOR } from 'Theme/themeConstants';
import PgIcon from 'Components/PgIcon';
import { arrayMoveImmutable } from 'array-move';

export interface MultiImagePickerFieldProps {
  label: string;
  numImages?: number;
  name?: string;
  subtitle?: string | JSX.Element;
  imgHW?: number; // This has to be a number because this is the width that will be applied for transforming the url using imageKit's api.
  // imagePlaceholderHeight: number | string;
  customParser?: (img: any) => Picture | string;
  helperText?: string;
  classes?: MultiImagePickerClasses;
  helperTextProps?: FormHelperTextProps;
  disableDnD?: boolean;
}

interface MultiImagePickerClasses {
  label?: string;
  subtitle?: string;
  helperText?: string;
  root?: string;
}

export interface MultiImagePickerProps extends IFieldProps {
  fieldProps?: MultiImagePickerFieldProps;
}
const MultiImagePicker: FC<MultiImagePickerProps> = (props) => {
  const { fieldProps = {} as MultiImagePickerFieldProps, formikProps = {} as FormikProps<any> } = props;
  const {
    name = '',
    numImages = 1,
    label = 'Add Images',
    helperText,
    customParser,
    imgHW = 90,
    /* imagePlaceholderHeight = 50, */ subtitle,
    classes: propClasses,
    helperTextProps,
    disableDnD,
  } = fieldProps;
  const classes = useStyles();
  const value: Picture[] | null | undefined = get(formikProps, `values.${name}`);
  const [uploadingImgNum, setUploadingImgNum] = useState<number | undefined>();
  const numAddedImages = typeof value?.length === 'number' ? value?.length : 0;

  const pictureUpload = async (prop: { imgs: TFile[]; _rem: any[] }) => {
    // const { imgs } = prop;
    // if (!imgs[0].base64) return;
    // const image = await PictureModel.upload({ base64: imgs[0].base64, name: imgs[0].name });
    // if (image) {
    //   if (!customParser) formikProps?.setFieldValue(`${name}[${numAddedImages}]`, parsePicture(image));
    //   else formikProps?.setFieldValue(`${name}[${numAddedImages}]`, customParser(image));
    // }
  };
  const pictureUploadTask = useAsyncTask(pictureUpload);

  useEffect(() => {
    if (pictureUploadTask.status !== 'PROCESSING') {
      setUploadingImgNum(undefined);
    }
  }, [pictureUploadTask.status]);

  const fieldError = getFieldError(name, formikProps as any);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, imgNum: number) => {
    const { files } = event.target;
    files &&
      processFilesWithCallback(files, (prop: { imgs: TFile[]; _rem: any[] }) => {
        pictureUploadTask.run(prop);
        setUploadingImgNum(imgNum);
      });
  };

  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { source, destination } = result;
    if (!destination) {
      // onReorder(stateList)
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      // onReorder(stateList)
      return;
    }
    if ((value?.length || 0) > 0) {
      const newValues = arrayMoveImmutable(value as Picture[], source.index, destination.index);
      formikProps.setFieldValue(name, newValues);
    }
  };

  const removeItem = (event: React.MouseEvent<HTMLElement>, index: number) => {
    if (value?.[index]) {
      event.stopPropagation();
      event.preventDefault();
      const newValues = [...(value || [])];
      newValues.splice(index, 1);
      formikProps.setFieldValue(name, newValues);
    }
  };
  return (
    <Box>
      <Box>
        <InputLabel className={propClasses?.label} shrink={false}>
          {label}
        </InputLabel>
        {
          // eslint-disable-next-line no-nested-ternary
          subtitle ? typeof subtitle === 'string' ? <Typography className={propClasses?.subtitle}>{subtitle}</Typography> : subtitle : null
        }
      </Box>
      <Box display="flex">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="test-id" direction="horizontal">
            {(provided, snapshot) => (
              <div
                className={classes.droppableArea}
                ref={provided.innerRef}
                style={{
                  backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'transparent',
                }}
                {...provided.droppableProps}
              >
                {[...Array(numImages).keys()].map((number) => {
                  const disabled = number > numAddedImages;
                  const isLoading = pictureUploadTask.status === 'PROCESSING' && uploadingImgNum === number;
                  const dragId = `draggable-${number}`;
                  return (
                    <Draggable isDragDisabled={disableDnD || disabled} key={number} draggableId={dragId} index={number}>
                      {(dragProvided, _snapshot) => (
                        <div
                          ref={dragProvided.innerRef}
                          className={clsx(classes.root, propClasses?.root, {
                            [classes.disabled]: disabled,
                            [classes.hasImage]: !!value?.[number],
                            [classes.draggingElement]: _snapshot.isDragging,
                          })}
                          key={`${name}${number}`}
                          {...dragProvided.draggableProps}
                        >
                          <Box
                            className={clsx(classes.rearrangeIcon, {
                              [classes.invisible]: disableDnD || !value?.[number],
                            })}
                            {...dragProvided.dragHandleProps}
                          >
                            <OpenWithIcon />
                          </Box>
                          {isLoading ? (
                            <LinearProgress color="secondary" className={classes.loader} />
                          ) : (
                            <>
                              {!value?.[number] ? (
                                <SvgIcon
                                  size={24}
                                  icon={ICONS.addCircleOutline}
                                  className={clsx(classes.svg, {
                                    [classes.disabledSVG]: disabled,
                                  })}
                                />
                              ) : null}
                              {value?.[number] ? (
                                <Box>
                                  {' '}
                                  <img className={classes.img} src={helpers.getPictureUrl(value[number], undefined, imgHW)} alt="" />{' '}
                                </Box>
                              ) : null}
                              <input
                                accept="image/*"
                                disabled={disabled}
                                title=""
                                type="file"
                                name={`${name}[${numAddedImages}]`}
                                onChange={(event) => handleChange(event, number)}
                                className={classes.input}
                              />
                            </>
                          )}
                          <div id="imageRemoveOverlay" className={classes.imageRemoveOverlay}>
                            <IconButton onClick={(event) => removeItem(event, number)}>
                              <PgIcon icon="icon-add" color="contrast" styleClass={classes.rotate45} />
                            </IconButton>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {/* {<ReorderableList itemKey={(i: number) => i.toString()} list={[...Array(numImages).keys()]} renderItem={renderItem} handleUpdateListOrder={handleUpdateListOrder} />} */}
      </Box>
      {helperText || fieldError || (pictureUploadTask.status === 'ERROR' && pictureUploadTask.message === 'request entity too large') ? (
        <FormHelperText {...helperTextProps} className={propClasses?.helperText} error={!!fieldError}>
          {fieldError || helperText}
        </FormHelperText>
      ) : null}
    </Box>
  );
};

export default MultiImagePicker;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: `${theme.palette.secondary.main}`,
      border: `1px dashed ${theme.palette.secondary.main}`,
      borderRadius: 3,
      position: 'relative',
      zIndex: 1,
      verticalAlign: 'middle',
      textAlign: 'center',
      width: 90,
      height: 90,
      '&:not(:last-child)': {
        marginRight: 20,
      },
    },
    disabled: {
      backgroundColor: theme.palette.grey.A200,
      border: 'none',
    },
    imgPlaceholder: {
      width: 200,
      height: 200,
      borderRadius: 3,
      border: `1px solid ${theme.palette.primary.main}1A`,
    },
    input: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      opacity: 0,
      width: 'inherit',
      height: 'inherit',
      zIndex: 2,
      '&:not([disabled])': {
        cursor: 'pointer',
      },
    },
    hasImage: {
      border: 'none',
      backgroundColor: 'transparent',
      width: 'auto',
      height: 'auto',
      '&:hover #imageRemoveOverlay': {
        display: 'flex',
      },
    },
    draggingElement: {
      '&:hover #imageRemoveOverlay': {
        display: 'none !important',
      },
    },
    svg: {
      marginRight: '50%',
      marginLeft: '50%',
      transform: 'translate(-50%, -50%)',
      marginTop: '50%',
      marginBottom: '50%',
      color: theme.palette.secondary.main,
    },
    disabledSVG: {
      opacity: 0,
    },
    img: {
      display: 'inline-block',
      zIndex: 2,
      position: 'relative',
      float: 'left',
      borderRadius: 3,
    },
    loader: {
      transform: 'translateY(-50%)',
      marginTop: '50%',
      marginBottom: '50%',
    },
    imageRemoveOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'none',
      cursor: 'pointer',
      width: 'inherit',
      height: 'inherit',
      zIndex: 9,
      justifyContent: 'center',
      alignItems: 'center',
      '&::before': {
        backgroundColor: COLOR.secondary.blue,
        content: "' '",
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        opacity: 0.5,
        borderRadius: 3,
      },
    },
    rearrangeIcon: {
      color: theme.palette.common.white,
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 25,
    },
    invisible: {
      display: 'none',
    },
    droppableArea: {
      display: 'flex',
    },
    rotate45: {
      transform: 'rotate(45deg)',
    },
  })
);
