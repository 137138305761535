import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, Theme, alpha } from '@material-ui/core/styles';
import { Addons } from 'Models/Event/@types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Picture } from 'Models/Picture/@types';
import helpers from 'Utils/helpers';
import PgTypo from 'Components/PgTypo';
import PgIcon from 'Components/PgIcon';
import CustomCarousel from 'Components/CustomCarousel';
import FavoriteButton from 'Features/Drops/FavoriteButton';
import { TContentType, TDrop } from 'Models/Drop/@types';
import { THEME_PALETTE } from 'Theme/themeConstants';
import Spacer from 'Components/Spacer';

type CarouselProps = {
  primaryImage: Picture;
  drop: TDrop;
  setDropDetail?: (drop: TDrop) => void;
  // eslint-disable-next-line react/require-default-props
  Addons?: Addons;
  disableAction?: boolean
};

const Carousel = ({ primaryImage, drop, setDropDetail, Addons, disableAction = false }: CarouselProps): JSX.Element => {
  const images: Picture[] = Addons?.additionalImages ? [primaryImage, ...(Addons.additionalImages || ([] as Picture))] : [primaryImage];

  const width = document.getElementById('ref')?.clientWidth || 0;
  const containerWidth = document.getElementById('containerRef')?.clientWidth || 0;
  const classes = useStyles({ width, containerWidth });
  const handleDone = (id: string, dropType: TContentType, favouriteId?: string) => {
    if (favouriteId) setDropDetail?.({ ...drop, isFavourited: true, favouriteId });
    else setDropDetail?.({ ...drop, favouriteId: undefined, isFavourited: false });
  };

  const renderItem = (data: Picture) => {
    return (
      <>
        <div id="ref" className={classes.imgContainer}>
          <img src={helpers.getPictureUrl(data)} alt={data?.name} className={classes.img} />
        </div>
      </>
    );
  };

  const renderLabel = (data: Picture) => {
    return (
      <>
        {images.length > 1 || data?.source ? (
          <div id="captionRef" className={classes.label}>
            <PgTypo b4 className={classes.caption} contrast>
              {data.source ? (
                <>
                  <PgIcon icon="icon-camera" color="contrast" styleClass={classes.cameraIcon} />
                  &nbsp;&nbsp;&nbsp;&nbsp;{data.source}
                </>
              ) : null}
            </PgTypo>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div id="containerRef" className={classes.root}>
      <Box className={classes.main}>
        <CustomCarousel<Picture>
          showArrows
          items={images}
          renderLabel={renderLabel}
          renderItem={renderItem}
          carouselSettings={{
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          }}
        />
        <Box position="absolute" top={20} right={20} zIndex={1} display="flex" alignItems="center">
          {(drop.isFeatured || drop.isUserFeatured || drop.featured) && <PgIcon icon="icon-star-fill" styleClass={classes.featured} />}
          <Spacer width={10} />
          <FavoriteButton moment={drop} onDone={handleDone} isDisable={disableAction} />
        </Box>
      </Box>
    </div>
  );
};

const useStyles = makeStyles<Theme, { width: number; containerWidth: number }>((theme: Theme) => ({
  root: {
    width: '100%',
    // height: (props) => props.containerWidth,
    // height: '100vw',
    aspectRatio: '1/1',
    // [theme.breakpoints.up('sm')]: {
    //   position: 'sticky',
    //   top: APP_HEADER_HEIGHT,
    // },
  },
  main: {
    position: 'relative',
    // width: '100%',
    // height: (props) => props.containerWidth,
    height: '100%',
    // height: 375,
    '& .slick-prev': {
      top: `calc(100% - 18px)`,
      left: `calc(100% - 68px) !important`,
      // backgroundColor: `${THEME_PALETTE.common.white}`,
      // '&:hover': {
      //     backgroundColor: `${THEME_PALETTE.common.white}`,
      // },
      // '&:focus': {
      //     backgroundColor: `${THEME_PALETTE.common.white}`,
      // },
    },
    '& .slick-next': {
      top: `calc(100% - 18px)`,
      right: `0px !important`,
      // backgroundColor: `${THEME_PALETTE.common.white}`,
      // '&:hover': {
      //     backgroundColor: `${THEME_PALETTE.common.white}`,
      // },
      // '&:focus': {
      //     backgroundColor: `${THEME_PALETTE.common.white}`,
      // },
    },
    '& .slick-slider': {
      [theme.breakpoints.down('sm')]: {
        width: '100vw',
      },
    },
    // '& .slick-slide': {
    //     width: props => `${props.width} !important`,
    // },
  },
  imgContainer: {
    width: '100%',
    // height: props => props.width,
  },
  img: {
    width: '100%',
    // height: 'auto',
    // height: props => props.width,
    // height: '100%',
    objectFit: 'cover',
    aspectRatio: '1/1',
  },
  index: {
    width: 35,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: 25,
    right: 32,
    padding: '6px 0px 4px 5px',
  },
  label: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    // backgroundImage: `linear-gradient(0deg, ${theme.palette.common.black} -90%, ${alpha(theme.palette.grey.A200, 0)} 90%)`,
    backgroundImage: `linear-gradient(0deg, ${theme.palette.common.black} -17.68%, ${alpha(theme.palette.grey.A400, 0)} 66.16%)`,
    padding: theme.spacing(22, 1, 0.5),
  },
  caption: {
    display: 'inline-flex !important',
    alignItems: 'center',
  },
  cameraIcon: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
  },
  featured: {
    fontSize: 28,
    color: THEME_PALETTE.others.orange,
    WebkitTextStrokeColor: THEME_PALETTE.grey.A700,
    WebkitTextStrokeWidth: '1px',
  },
}));

export default Carousel;
