import React from 'react';
import PgIcon from 'Components/PgIcon';
import { FormConfig } from 'react-forms';

export const admissionType = (valueKey: string) => ({
  type: 'text',
  valueKey,
  fieldProps: {
    label: 'Name',
    // placeholder: 'general admission,early-bird,etc.',
    classes: {
      // root: classes?.selectBold,
    },
  },
  styles: {
    marginTop: 21,
  },
});

export const ticketCost = (valueKey: string, config?: Partial<FormConfig>) => {
  const ticketCostConfig = {
    ...{
      type: 'text',
      valueKey,
      fieldProps: {
        label: 'Cost',
        InputProps: {
          endAdornment: <PgIcon color="primary" icon="icon-money" />,
        },
        styles: {
          color: 'red',
        },
        classes: {
          // root: classes?.selectBold,
        },
        required: true,
      },
      styles: {
        fontWeight: 'bold',
        marginTop: 21,
      },
    },
    ...config,
  };
  return ticketCostConfig;
};
