import ViewModel from 'Core/@types';
import useAsyncTask from 'Hooks/useAsyncTask';
import { TContentType } from 'Models/Drop/@types';
import ResourceModel from 'Models/Resource';
import { TResource } from 'Models/Resource/@types';
import {User} from 'Models/User/@types';
import { useState, createContext, useEffect, useMemo } from 'react';
import { useParams, useRouteMatch } from 'react-router';
import { useStoreState } from 'Stores';

const RESOURCE_PARAMS_FILTER = {
  filter: {
    include: ['tags', { relation: 'community' }, 'includeRemindersMeta', 'collaborationRequestInfo', 'collaborators'],
  },
};
export const getResourceType = (path: string): TContentType => {
  const resourceTypeText: Record<string, string> = {
    videos: 'videos',
    podcast: 'podcasts',
    article: 'articles',
    merch: 'collects',
    album: 'albums',
    soundtrack: 'soundtracks',
  };

  return resourceTypeText[path.slice(1, -4)] as TContentType;
};
const useResource = (): ResourceViewModelState => {
  const [resourceDetail, setResourceDetail] = useState<TResource>();
  const { path } = useRouteMatch();
  const resourceType = getResourceType(path);
  const { id } = useParams<{ id: string }>();

  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));

  const getResourceDetail = useAsyncTask<void>(async () => {
    const resourceDetail = await ResourceModel.getSingleResource(resourceType, id, RESOURCE_PARAMS_FILTER);
    setResourceDetail(resourceDetail);
  });

  const isMyDrop = useMemo(() => appUser?.id === resourceDetail?.userId, [appUser?.id, resourceDetail?.id]);

  useEffect(() => {
    if(id)
    refreshStealth();
  }, [appUser?.id,id]);

  const refreshStealth = () => {
    getResourceDetail.run();
  };

  return { resourceDetail, setResourceDetail, refreshStealth, isMyDrop, isLoading: getResourceDetail.status === 'PROCESSING' };
};

export const ResourceContext = createContext<ResourceViewModelState>({
  resourceDetail: {} as TResource,
  setResourceDetail: () => {},
  refreshStealth: () => {},
  isMyDrop: false,
  isLoading: true,
});

export interface ResourceViewModelState {
  resourceDetail?: TResource;
  setResourceDetail: (resourceDetail: TResource) => void;
  refreshStealth: () => void;
  isMyDrop: boolean;
  isLoading: boolean;
}

class ResourceViewModel implements ViewModel<ResourceViewModelState> {
  Hook = useResource;

  CtxProvider = ResourceContext.Provider;
}

export default ResourceViewModel;
