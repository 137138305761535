import React, { FC } from 'react'
import { SVGCIconProps } from '../SVGCIcon'

const share: FC<SVGCIconProps> = (props) => {
    const { color = 'black', height = 24, width = 24 } = props;
    return (

        <svg
            version="1.1"
            id="svg2940"
            width={width ?? "682.66669"}
            height={height ?? "682.66669"}
            viewBox="0 0 682.66669 682.66669"
            xmlns="http://www.w3.org/2000/svg">
            <defs id="defs2944">
                <clipPath
                    clipPathUnits="userSpaceOnUse"
                    id="clipPath2954">
                    <path
                        d="M 0,512 H 512 V 0 H 0 Z"
                        id="path2952" />
                </clipPath>
            </defs>
            <g id="g2946" transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)">
                <g id="g2948">
                    <g id="g2950" clip-path="url(#clipPath2954)">
                        <g id="g2956" transform="translate(286,255.9995)">
                            <path
                                d="M 0,0 C -110.316,0 -212.164,-63.327 -261.499,-161.996 L -271,-183.899 v 32.9 C -271,-1.882 -149.117,120 0,120 V 224.5 L 202,60 0,-105 Z"
                                style={{
                                    fill: 'none',
                                    stroke: color ?? '#000000',
                                    strokeWidth: 30,
                                    strokeLinecap: 'butt',
                                    strokeLinejoin: 'miter',
                                    strokeMiterlimit: 10,
                                    strokeDasharray: 'none',
                                    strokeOpacity: 1
                                }}
                                id="path2958" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    )
}

export default share
