import { isString, map, filter, indexOf, isUndefined, round, get } from 'lodash';
import Config from 'config';
import * as Yup from 'yup';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import { Picture } from 'Models/Picture/@types';
import PlaceholderProfile from 'Assets/images/PlaceholderProfile.png';
import bg1 from 'Assets/Backgrounds/bg1.svg';
import bg2 from 'Assets/Backgrounds/bg2.svg';
import bg3 from 'Assets/Backgrounds/bg3.svg';
import { FormikProps } from 'formik';
import moment from 'moment';
import { TEvent } from 'Models/Event';

const emailSchema = Yup.string().email();
const IMG_MAX_SIZE = 10000;

const BGS = [bg1, bg2, bg3];

const helpers = {
    isProduction: () => {
        const env = process.env.REACT_APP_NODE_ENV?.trim();
        return env === 'production';
    },
    scrollTo: (id: string) => {
        const el = document.getElementById(id);
        if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
        }
    },
    isDecimal: (num: number): boolean => {
        return num !== parseInt(`${num}`, 10);
    },
    appendApostrophe: (text?: string): string | undefined => {
        if (!text) return undefined;
        if (text[text.length - 1] === 's') return `${text}'`;
        return `${text}'s`;
    },

    sortByList: <T = any>(list: T[], featuredList: Array<string>, compareKey = 'id') => {
        if (!list.length || !featuredList.length) return [];

        const items: T[] = [];

        featuredList.forEach((i) => {
            const item = list.find((_item: any) => _item[compareKey] === i);
            if (item) items.push(item);
        });
        return [...items];
    },
    *idGenerator() {
        let id = 1;
        while (true) {
            yield id;
            id++;
        }
    },
    toggleItemFromList: <T>(list: T[] = [], item: T, key = 'id', comaprisonFunction?: (currentItem: T, item: T) => boolean) => {
        const updatedList: T[] = [...list];
        const index = list.findIndex((i: any) => {
            if (comaprisonFunction) return comaprisonFunction(i, item);
            if (typeof i === 'string' && typeof item === 'string') {
                return i === item;
            }
            return i[key] === (item as any)[key];
        });
        index === -1 ? updatedList.push(item) : updatedList.splice(index, 1);
        return updatedList;
    },
    copyToClipboard: (value: string): void => {
        if (window.navigator && window.navigator.clipboard && window.navigator.clipboard.writeText)
            // eslint-disable-next-line no-unused-expressions
            window.navigator?.clipboard?.writeText(value);
        else {
            const el = document.createElement('textarea');
            el.value = value;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        }
    },
    getPlaceholderImageUrl: (w = 400, h = 400, text = `${w}x${h}`) => `https://via.placeholder.com/${w}x${h}?text=${text}`,
    getDummyImageUrl: (w = 800, h = 800, text = 'gradient') => `https://source.unsplash.com/random/${w}x${h}?${text}`,
    getPlaceholderProfileImage: () => PlaceholderProfile,
    bytesToSize: (bytes: number) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        // eslint-disable-next-line no-restricted-properties
        return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
    },

    toggleBodyScroll: (allowScroll: boolean) => {
        document.body.style.overflow = allowScroll ? '' : 'hidden';
    },

    mockRequest: (returnData?: any, fail?: boolean, time?: number) =>
        new Promise<typeof returnData>((resolve, reject) => {
            setTimeout(() => {
                if (fail) reject(returnData);
                else resolve(returnData);
            }, time || 2000);
        }),
    getPictureUrl: (picture?: Picture, width = 0, height = 0, quality = 0) => {
        if (!picture) return '';
        const path = picture.url || picture.imagePath;
        // const path = picture.filePath || picture.imagePath;
        if (!path) return '';
        const { cropData } = picture;
        if (!path.includes('imagekit')) {
            return path;
        }
        if (path) {
            const transformationAttr = [];
            if (!isEmpty(cropData)) {
                if ((cropData?.width && cropData?.width > IMG_MAX_SIZE) || (cropData?.height && cropData?.height > IMG_MAX_SIZE)) return path;
                if (cropData?.height) transformationAttr.push(`h-${Math.floor(cropData.height)}`);
                if (cropData?.width) transformationAttr.push(`w-${Math.floor(cropData.width)}`);
                transformationAttr.push('cm-extract');
                if (cropData?.x !== undefined) transformationAttr.push(`x-${Math.floor(cropData.x)}`);
                if (cropData?.y !== undefined) transformationAttr.push(`y-${Math.floor(cropData.y)}`);
            } else {
                if (width > 0) transformationAttr.push(`w-${Math.floor(width)}`);
                if (height > 0) transformationAttr.push(`h-${Math.floor(height)}`);
            }

            if (!transformationAttr.length || isEmpty(cropData)) return path;

            const transformation = `${transformationAttr.join(',')}`;
            const imageUrl = `${path}?tr=${transformation}`;
            // const imageUrl = `${BASE_URL}${transformation}${path}`;
            // urlPrefix += `${path}?tr:${transformationAttr.join(',')}`
            return imageUrl;
        }
        return '';
    },
    isListSimilar: (list1: Array<any>, list2: Array<any>) => {
        if (list1.length !== list2.length) return false;
        // eslint-disable-next-line consistent-return
        list1.forEach((elem) => {
            const index = list2.findIndex((i) => i.id === elem.id);
            if (index === -1) return false;
        });
        return true;
    },
    sequentialPromises: <T = any>(items: T[], asyncFunc: (item: T) => any, onEachItem: any) => {
        if (!Array.isArray(items) || typeof asyncFunc !== 'function') return Promise.reject(new Error('No item or method provided'));
        return items.reduce(
            (previous, current) =>
                previous.then((accumulator: any) =>
                    asyncFunc(current).then((result: any) => {
                        if (typeof onEachItem === 'function') onEachItem(current, result);
                        return accumulator.concat(result);
                    }),
                ),
            Promise.resolve([]),
        );
    },
    bringIntoView: (elementId: string, args?: ScrollIntoViewOptions) => {
        document.getElementById(elementId)?.scrollIntoView(args);
    },
    updateItemList: (list: Array<any> = [], item: any, action: 'ADD' | 'DELETE' | 'UPDATE' | 'PUT', key: any = 'id'): typeof list => {
        Object.keys(item).forEach((k) => {
            if (isUndefined(item[k])) delete item[k];
        });
        const newList = list.slice();
        let itemIndex;
        if (action === 'UPDATE') {
            return list.map((_item) => (_item[key] === item[key] ? { ..._item, ...item } : _item));
            // itemIndex = newList.findIndex(listItem => item[key] === listItem[key]);
            // if (itemIndex !== -1)
            //     newList.splice(itemIndex, 1, item);
            // return newList;
        }
        if (action === 'ADD') {
            newList.unshift(item);
            return newList;
        }
        if (action === 'DELETE') {
            return newList.filter((listItem) => item[key] !== listItem[key]);
        }
        if (action === 'PUT') {
            itemIndex = newList.findIndex((listItem) => item[key] === listItem[key]);
            if (itemIndex !== -1) newList.splice(itemIndex, 1, item);
            else {
                newList.push(item);
            }
            return newList;
        }
        return newList;
    },
    findValues: (superSet: Array<any>, subSet: string | Array<string>, findKey = 'value', mapKey = 'name', isReturnItem = false) => {
        // findKey = findKey || 'value';
        // mapKey = mapKey || 'name';
        // eslint-disable-next-line no-param-reassign
        if (isString(subSet)) subSet = [subSet];
        const filteredValues = filter(superSet, (item: any) => indexOf(subSet, item[findKey]) !== -1);
        if (isReturnItem) return filteredValues;
        return map(filteredValues, mapKey);
    },
    hexToRgb: (hex: string, opacity = '0.1') => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if (result) return `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)} ,${parseInt(result[3], 16)},${opacity})`;
        // r: parseInt(result[1], 16),
        // g: parseInt(result[2], 16),
        // b: parseInt(result[3], 16)
        return '';
    },
    cityDetails: (placeId: string, fields?: Array<string>): Promise<google.maps.places.PlaceResult> => {
        return new Promise((resolve, reject) => {
            if (google) {
                const request = {
                    placeId,
                    // fields: GoogleUtils.geoAddressFields
                };
                const service = new google.maps.places.PlacesService(document.createElement('div'));
                service.getDetails(request, (result) => {
                    resolve(result);
                });
            }
        });
    },
    timezoneFromLocation: async (
        lat: number,
        long: number,
    ): Promise<{
        timeZoneId: string;
        dstOffset: string;
        timeZoneName: string;
        rawOffset: number;
    }> => {
        const res = (
            await fetch(
                `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${long}&timestamp=${Math.floor(
                    Date.now() / 1000,
                ).toString()}&key=${Config.get('GOOGLE_MAPS_API_KEY')}`,
            )
        ).json();
        return res;
    },
    validateEmail: (email: string) => {
        return emailSchema.isValidSync(email.toLowerCase());
    },
    locationString: (searchString: string) => {
        if (!searchString) return '';
        return searchString.replace(/^\?/, '');
    },
    locationParams: <T>(queryString: string): T => {
        const str = helpers.locationString(queryString);
        if (!str) return {} as T;
        const params = qs.parse(str);
        return params as unknown as T;
    },
    templateReplace: (template: string, data: Record<string, string>): string => {
        return template.replace(/{([^{}]+)}/g, (keyExpr, key) => {
            return data[key] || '';
        });
    },
    isSafari: (): boolean => /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor),
    isFireFox: () => navigator.userAgent.indexOf('Firefox') > 0,
    formatAmount: (value: number, offset = 2): number => round(value, offset),
    scrollToCreds: (top: number = 0, left: number = 0, behavior: 'smooth' | 'auto' = 'smooth') => window.scrollTo({ top, left, behavior }),
    randomNumberGenerator: (min: number = 0, max: number = 100) => Math.floor(Math.random() * (max - min + 1) + min),
    downloadFileFromUrl: (url: string, target: string = '_blank') => {
        window.open(url, target);
    },
    truncateText: (text = '', length = 50) => (text.length <= length ? text : `${text.slice(0, length - 3)}...`),
    capitalize: (word: string) => word?.[0]?.toUpperCase() + word?.substring(1)?.toLowerCase(),
    getPlaceholderBanner: BGS[Math.floor(Math.random() * 100) % 3],
    getFieldValue: (formikProps: FormikProps<any>, name: string) => {
        let value = get(formikProps, `values.${name}`);
        if (value === null || value === undefined || value === false) return '';
        return value;
    },

    checkImageForFileSize: (files: FileList, MAX_FILE_SIZE: number = IMG_MAX_SIZE) => {
        const invalidFiles: File[] = [];
        const validFiles: File[] = [];
        for (let i = 0; i < (files?.length ?? 0); i += 1) {
            const file = files[i];
            const fileSizeKiloBytes = file.size / 1024;
            if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                invalidFiles.push(file);
            } else {
                validFiles.push(file);
            }
        }
        return {
            hasInvalidFile: Boolean(invalidFiles.length),
            invalidFiles: invalidFiles,
            validFiles: validFiles,
        };
    },
    isEventLive: (event: TEvent) => moment().isAfter(event?.eventDates?.[0]?.startDate) && moment().isBefore(event?.eventDates?.[0]?.endDate),
    isValidUrl: (text: string) => Yup.string().url().isValidSync(text),
    getErrorData: <T = any>(error: any): T => get(error, 'response.data.error'),
    getMailToGmailLink: (email: string) => `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`,
    formatWeb3Address: (address: string) => `${address.slice(0, 5)}...${address.slice(-4)}`,

    b64Encode: (data: string) => btoa(data),
    b64Decode: (data: string) => atob(data),
};
export default helpers;
