import PgDialog from 'Components/PgDialog';
import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import METAMASK from 'Assets/Web3_wallets/metamask.svg';
import COINBASE from 'Assets/Web3_wallets/coinbase.svg';
import WALLET_CONNECT from 'Assets/Web3_wallets/walletconnect.svg';
import EMAIL_CONNECT from 'Assets/Web3_wallets/emailconnect.svg';
import PHANTOM from 'Assets/Web3_wallets/phantom.svg';
import PgButton from 'Components/PgButton';
import { Step, TAuthDialogContentProps } from '../@types';
import { Box, createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { TWalletConnectHookProps, WalletType } from '../useWalletConnect';
import { useStoreState } from 'Stores';
import PgTypo from 'Components/PgTypo';
import { FONT_FAMILY } from 'Theme/themeConstants';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { useHistory, useLocation } from 'react-router';
import { ACCOUNTS_ROUTES } from 'Routes/AccountNavigation';
import helpers from 'Utils/helpers';
import { WALLET_ICONS_MAP } from 'Constants/variables';
import QueryString from 'qs';
import UserModel from 'Models/User';

interface WalletButtons {
    id: string;
    text: JSX.Element;
    onClick: () => void;
}

const metaMessages = (wallet: string, img: string) => ({
    title: (
        <PgTypo h3>
            Connecting to {wallet} <img src={img} alt={wallet} style={{ marginLeft: 8, marginBottom: -6 }} />
        </PgTypo>
    ),
    subtitle: `Approve your ${wallet} connection and authorize access to continue.`,
});

const WalletSelect: FC<
    TAuthDialogContentProps & {
        handleWalletSelect: TWalletConnectHookProps['handleWalletSelect'];
        setWalletMeta: TWalletConnectHookProps['setWalletMeta'];
        walletMeta: TWalletConnectHookProps['walletMeta'];
        setWalletStep: Dispatch<SetStateAction<Step | undefined>>;
    }
> = (props) => {
    const { changeStep, handleWalletSelect, setWalletMeta, walletMeta, meta, step, setWalletStep } = props;
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
    const classes = useStyles({});

    const { pathname, search } = useLocation();
    const history = useHistory();

    const handleWalletClick = (wallet: WalletType, img: string) => {
        setWalletMeta?.({ ...metaMessages(wallet, img), wallet, hideClose: true });
        const appLink = new URL(window.location.href)
        appLink.searchParams.append('walletType', wallet);
        handleWalletSelect(wallet, appLink.toString());
    };
    useEffect(() => {
        if (step === 'wallet-select') {
            setWalletStep(undefined);
            setWalletMeta({ subtitle: undefined, title: undefined });
        }
    }, [step]);

    useEffect(() => {
        const params = helpers.locationParams<{ walletType: WalletType }>(search);
        if (!params.walletType) return;
        const walletIcon = WALLET_ICONS_MAP[params.walletType.toLowerCase() as keyof typeof WALLET_ICONS_MAP];
        handleWalletClick(params.walletType, walletIcon);
        const updatedParams = QueryString.stringify({ ...params, walletType: undefined });
        history.replace({ search: updatedParams });
    }, [search]);

    const handleSuiClick = async () => {
        const { loginURL } = await UserModel.getGoogleZKLoginLink();
        window.open(loginURL, '_self');
    }

    let btns: WalletButtons[] = [
        // walletMeta?.appUser || appUser
        //   ? undefined
        //   : {
        //       id: 'emailconnect',
        //       text: (
        //         <>
        //           <img src={EMAIL_CONNECT} alt="EmailConnect" style={{ marginRight: 8 }} />
        //           {'email'.toUpperCase()}
        //         </>
        //       ),
        //       onClick: () => changeStep(meta?.setStep ?? 'login'),
        //     },
        {
            id: 'metamask',
            text: (
                <>
                    <img src={METAMASK} alt="MetaMask" style={{ marginRight: 8 }} />
                    {'MetaMask'.toUpperCase()}
                </>
            ),
            onClick: () => handleWalletClick(WalletType.MetaMask, METAMASK),
        },
        {
            id: 'sui-wallet',
            text: (
                <>
                    <img src={WALLET_ICONS_MAP['sui']} alt="sui-wallet" style={{ marginRight: 8 }} />
                    {'SUI'.toUpperCase()}
                </>
            ),
            // onClick: () => handleWalletClick(WalletType.Sui, WALLET_ICONS_MAP['sui']),
            onClick: handleSuiClick,
        },
        {
            id: 'coinbase',
            text: (
                <>
                    <img src={COINBASE} alt="Coinbase" style={{ marginRight: 8 }} />
                    {'Coinbase'.toUpperCase()}
                </>
            ),
            onClick: () => handleWalletClick(WalletType.Coinbase, COINBASE),
        },
        {
            id: 'phantomWallet',
            text: (
                <>
                    <img src={PHANTOM} alt="WalletConnect" style={{ marginRight: 8 }} />
                    PHANTOM
                </>
            ),
            onClick: () => handleWalletClick(WalletType.Phantom, PHANTOM),
        },
        {
            id: 'walletconnect',
            text: (
                <>
                    <img src={WALLET_CONNECT} alt="WalletConnect" style={{ marginRight: 8 }} />
                    {'WalletConnect'.toUpperCase()}
                </>
            ),
            onClick: () => handleWalletClick(WalletType.WalletConnect, WALLET_CONNECT),
        },
    ].filter((f) => f) as WalletButtons[];

    const { isDeviceSm } = useMediaQuery();

    const mobileSupportedWalletIds = ['emailconnect', 'metamask', 'coinbase', 'walletconnect', 'sui-wallet'];

    const supportedWalletIds = isDeviceSm ? mobileSupportedWalletIds : [];

    if (supportedWalletIds.length) {
        btns = btns.filter((f) => supportedWalletIds.includes(f.id));
    }

    return (
        <PgDialog
            // heading={'Log in or create an account'}
            // body={
            //   'In web 3, connecting your wallet can be used to sign up or log in. Select your preferred wallet to get started, or use your email address instead.'
            // }
            headingContainerProps={{ display: 'flex', justifyContent: 'center', position: 'relative' }}
            headingProps={{ h3: !isDeviceSm, h7: isDeviceSm, align: 'center', typoWeight: 'fontWeightBold', h6: false }}
            subheadingProps={{ className: classes.subtitle, c2: false, b1: true }}
            heading={
                walletMeta?.heading ??
                meta?.heading ??
                (meta?.setStep === 'signup'
                    ? 'Get started'
                    : !appUser
                        ? 'Already on Playground? Log in.'
                        : pathname === ACCOUNTS_ROUTES.SETTINGS.path
                            ? 'Connect Wallet'
                            : '')
            }
            subheading={walletMeta?.subtitle ?? meta?.subtitle}
            body={walletMeta?.body}
            prev={meta?.setStep ? () => changeStep(meta?.setStep ?? 'login') : undefined}
            actionButtons={
                <Box pt={4}>
                    {btns.map((b) => (
                        <PgButton variant="outlined" key={b.id} fullWidth onClick={b.onClick} className={classes.btn}>
                            {b.text}
                        </PgButton>
                    ))}
                    {/* {walletMeta?.appUser || appUser ? null : <SocialAuth />}
          {!appUser && (
            <PgButton
              quaternary
              underlined
              className={classes.loginBtn}
              onClick={() => {
                if (meta?.setStep === 'signup') {
                  changeStep('login');
                } else {
                  changeStep('signup');
                }
              }}
            >
              {meta?.setStep === 'signup' ? 'Already on Playground? Log in.' : 'Need An Account? Sign Up'}
            </PgButton>
          )} */}
                </Box>
            }
        />
    );
};

export default WalletSelect;

const useStyles = makeStyles<Theme, any>((theme) =>
    createStyles({
        loginBtn: {
            width: 'fit-content',
            margin: '0 auto',
            marginBottom: theme.spacing(4),
            '& .MuiButton-label': {
                fontSize: '1rem',
                fontFamily: FONT_FAMILY.secondary,
            },
        },
        btn: {
            marginBottom: theme.spacing(2),
        },
        subtitle: {
            fontFamily: FONT_FAMILY.secondary,
        },
    }),
);
