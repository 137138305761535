import React from "react";
import { OnboardingPopupProps } from "Features/Popover/OnboardingPopup";
import { Box } from "@material-ui/core";
import PgTypo from "Components/PgTypo";
import POINTS_ICON from 'Assets/icons/points_icon.svg';
import { COLORS } from "Theme/newThemeConstants";
import PgButton from "Components/PgButton";

export default {

    createCommunity: {
        title: 'Want to start creating events?',
        body: ' Lets hyper create your community for you! Edit the details whenever you want! Have a skip this step option',
        actionText: 'start',
    },

} as Record<string, OnboardingPopupProps['content']>;

export const commOnboardStepsCls = {
    manageComm: 'manage-community',
    editComm: 'edit-community-profile',
    commChat: 'community-conversations',
    commIntegration: 'community-integrations',
};

export const communityOnboardSteps = (onEditCommClick?: () => void): (OnboardingPopupProps['content'] & { target: string })[] => [
    {
        title: 'We’ve created a community for you',
        body: (
            <Box display='flex' flexDirection='column' alignItems='center' gridGap={12}>
                <Box display='flex' alignItems='center' gridGap={8}>
                    <PgTypo c3 align='center' style={{ color: COLORS.neutral }}>You’ve earned 5 points</PgTypo>
                    <img src={POINTS_ICON} alt="points-icon" height={16} />
                </Box>
                <PgTypo b4 align='center'>Add what you like! Event? Video? Podcast?<br />Create it now for your community!</PgTypo>
            </Box>
        ) as unknown as string,
        actionText: 'gotcha',
        target: `.${commOnboardStepsCls.manageComm}`,
    },
    {
        title: (<>Shiny new profile? Coming<br />right up ⭐️</>) as unknown as string,
        body: (
            <PgTypo b4 align='center'>
                Curate the vibes on your community page so the right members join the club.{' '}
                <PgButton quaternary underlined onClick={onEditCommClick}>Edit profile</PgButton>
            </PgTypo>
        ) as unknown as string,
        actionText: 'gotcha',
        target: `.${commOnboardStepsCls.editComm}`,
    },
    {
        title: 'Get people talking 💬',
        body: 'Create hype, share updates, or discuss the latest arthouse film with your community.',
        actionText: 'ttyl',
        target: `.${commOnboardStepsCls.commChat}`,
    },
    {
        title: 'Your apps + Playground = 🚀',
        body: 'Bring your favorite tools together in a few clicks.',
        actionText: 'AWESOME 😎',
        target: `.${commOnboardStepsCls.commIntegration}`,
    },
];

type TOnboarding = 'manage-comm-sidebar' | 'default-sidebar';

export const hideOnboard = (type: TOnboarding = 'manage-comm-sidebar') => {
    localStorage.setItem(`${type}_onboard`, 'closed');
};

export const isOnboardHidden = (type: TOnboarding = 'manage-comm-sidebar') => {
    const onboardState = localStorage.getItem(`${type}_onboard`);
    return onboardState === 'closed';
};
