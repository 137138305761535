import { Theme, makeStyles } from '@material-ui/core';

export const NO_PRINT_CLASS = 'no-print';

const useAppStyles = makeStyles((theme: Theme) => ({
  richText: {
    '& > p': {
      margin: 0,
    },
  },
  textButton: {
    padding: '0 5px',
    textTransform: 'initial',
    height: 'auto',
    minHeight: 'auto',
    lineHeight: 'inherit',
  },
  buttonInitialCase: {
    textTransform: 'initial',
  },
}));

export default useAppStyles;
