// import type { AddEthereumChainParameter } from '@web3-react/types';
// import config from 'config';

// const ETH: AddEthereumChainParameter['nativeCurrency'] = {
//   name: 'Ether',
//   symbol: 'ETH',
//   decimals: 18,
// };

// const MATIC: AddEthereumChainParameter['nativeCurrency'] = {
//   name: 'Matic',
//   symbol: 'MATIC',
//   decimals: 18,
// };

// interface BasicChainInformation {
//   urls: string[];
//   name: string;
//   lookupValue: string;
// }

// interface ExtendedChainInformation extends BasicChainInformation {
//   nativeCurrency: AddEthereumChainParameter['nativeCurrency'];
//   blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls'];
// }

// function isExtendedChainInformation(
//   chainInformation: BasicChainInformation | ExtendedChainInformation
// ): chainInformation is ExtendedChainInformation {  return !!(chainInformation as ExtendedChainInformation)?.nativeCurrency;
// }

// export function getAddChainParameters(chainId: number): AddEthereumChainParameter | number {
//   const chainInformation = CHAINS[chainId];
//   if (isExtendedChainInformation(chainInformation)) {
//     const data = {
//       chainId,
//       chainName: chainInformation.name,
//       nativeCurrency: chainInformation.nativeCurrency,
//       rpcUrls: chainInformation.urls,
//       blockExplorerUrls: chainInformation.blockExplorerUrls,
//     };
//     return data;
//   }
//   return chainId;
// }

// export const CHAINS: { [chainId: number]: BasicChainInformation | ExtendedChainInformation } = {
//   1: {
//     urls: [
//       config.get('infuraKey') ? `https://mainnet.infura.io/v3/${config.get('infuraKey')}` : undefined,
//       config.get('alchemyKey') ? `https://eth-mainnet.alchemyapi.io/v2/${config.get('alchemyKey')}` : undefined,
//       'https://cloudflare-eth.com',
//     ].filter((url) => url !== undefined) as string[],
//     name: 'Mainnet',
//     lookupValue: 'eth',
//   },
//   // 3: {
//   //   urls: [config.get('infuraKey') ? `https://ropsten.infura.io/v3/${config.get('infuraKey')}` : undefined].filter(
//   //     (url) => url !== undefined
//   //   ) as string[],
//   //   name: 'Ropsten',
//   // },
//   // 4: {
//   //   urls: [config.get('infuraKey') ? `https://rinkeby.infura.io/v3/${config.get('infuraKey')}` : undefined].filter(
//   //     (url) => url !== undefined
//   //   ) as string[],
//   //   name: 'Rinkeby',
//   // },
//   5: {
//     urls: [config.get('infuraKey') ? `https://goerli.infura.io/v3/${config.get('infuraKey')}` : undefined].filter(
//       (url) => url !== undefined
//     ) as string[],
//     name: 'Görli',
//     lookupValue: 'goerli',
//   },
//   // 42: {
//   //   urls: [config.get('infuraKey') ? `https://kovan.infura.io/v3/${config.get('infuraKey')}` : undefined].filter(
//   //     (url) => url !== undefined
//   //   ) as string[],
//   //   name: 'Kovan',
//   // },
//   // Optimism
//   // 10: {
//   //   urls: [
//   //     config.get('infuraKey') ? `https://optimism-mainnet.infura.io/v3/${config.get('infuraKey')}` : undefined,
//   //     'https://mainnet.optimism.io',
//   //   ].filter((url) => url !== undefined) as string[],
//   //   name: 'Optimism',
//   //   nativeCurrency: ETH,
//   //   blockExplorerUrls: ['https://optimistic.etherscan.io'],
//   // },
//   // 69: {
//   //   urls: [
//   //     config.get('infuraKey') ? `https://optimism-kovan.infura.io/v3/${config.get('infuraKey')}` : undefined,
//   //     'https://kovan.optimism.io',
//   //   ].filter((url) => url !== undefined) as string[],
//   //   name: 'Optimism Kovan',
//   //   nativeCurrency: ETH,
//   //   blockExplorerUrls: ['https://kovan-optimistic.etherscan.io'],
//   // },
//   // Arbitrum
//   // 42161: {
//   //   urls: [
//   //     config.get('infuraKey') ? `https://arbitrum-mainnet.infura.io/v3/${config.get('infuraKey')}` : undefined,
//   //     'https://arb1.arbitrum.io/rpc',
//   //   ].filter((url) => url !== undefined) as string[],
//   //   name: 'Arbitrum One',
//   //   nativeCurrency: ETH,
//   //   blockExplorerUrls: ['https://arbiscan.io'],
//   // },
//   // 421611: {
//   //   urls: [
//   //     config.get('infuraKey') ? `https://arbitrum-rinkeby.infura.io/v3/${config.get('infuraKey')}` : undefined,
//   //     'https://rinkeby.arbitrum.io/rpc',
//   //   ].filter((url) => url !== undefined) as string[],
//   //   name: 'Arbitrum Testnet',
//   //   nativeCurrency: ETH,
//   //   blockExplorerUrls: ['https://testnet.arbiscan.io'],
//   // },
//   // Polygon
//   137: {
//     urls: [config.get('infuraKey') ? `https://polygon-mainnet.infura.io/v3/${config.get('infuraKey')}` : undefined, 'https://polygon-rpc.com'].filter(
//       (url) => url !== undefined
//     ) as string[],
//     name: 'Polygon Mainnet',
//     nativeCurrency: MATIC,
//     blockExplorerUrls: ['https://polygonscan.com'],
//     lookupValue: 'matic',
//   },
//   80001: {
//     urls: [config.get('infuraKey') ? `https://polygon-mumbai.infura.io/v3/${config.get('infuraKey')}` : undefined].filter(
//       (url) => url !== undefined
//     ) as string[],
//     name: 'Polygon Mumbai',
//     nativeCurrency: MATIC,
//     blockExplorerUrls: ['https://mumbai.polygonscan.com'],
//     lookupValue: 'mumbai',
//   },
// };

// export const URLS: { [chainId: number]: string[] } = Object.keys(CHAINS).reduce<{ [chainId: number]: string[] }>((accumulator, chainId) => {
//   const validURLs: string[] = CHAINS[Number(chainId)].urls;

//   if (validURLs.length) {
//     accumulator[Number(chainId)] = validURLs;
//   }

//   return accumulator;
// }, {});


import type { AddEthereumChainParameter } from '@web3-react/types'
import config from 'config'

const ETH: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18,
}

const MATIC: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Matic',
  symbol: 'MATIC',
  decimals: 18,
}

const CELO: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Celo',
  symbol: 'CELO',
  decimals: 18,
}

interface BasicChainInformation {
  urls: string[]
  name: string
  lookupValue: string,
  chainId: number
  currencyAddress?: string,
  isTestNet?: boolean
  chain?: string;
  currencySymbol?: string;
}

export interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter['nativeCurrency']
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls']
}

function isExtendedChainInformation(
  chainInformation: BasicChainInformation | ExtendedChainInformation
): chainInformation is ExtendedChainInformation {
  return !!(chainInformation as ExtendedChainInformation).nativeCurrency
}

export function getAddChainParameters(chainId: number, externalChainConfig?: ChainConfig): AddEthereumChainParameter | number {
  const chainInformation = (externalChainConfig ?? CHAINS)[chainId]
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    }
  } else {
    return chainId
  }
}

const getInfuraUrlFor = (network: string) =>
  config.get('infuraKey') ? `https://${network}.infura.io/v3/${config.get('infuraKey')}` : undefined
const getAlchemyUrlFor = (network: string) =>
  config.get('alchemyKey') ? `https://${network}.alchemyapi.io/v2/${config.get('alchemyKey')}` : undefined

export type ChainConfig = { [chainId: number]: BasicChainInformation | ExtendedChainInformation }

export const MAINNET_CHAINS: ChainConfig = {
  1: {
    urls: [getInfuraUrlFor('mainnet'), getAlchemyUrlFor('eth-mainnet'), 'https://cloudflare-eth.com'].filter(Boolean) as string[],
    name: 'Ethereum Mainnet',
    lookupValue: 'eth',
    chainId: 1,
  },
  // 10: {
  //   urls: [getInfuraUrlFor('optimism-mainnet'), 'https://mainnet.optimism.io'].filter(Boolean) as string[],
  //   name: 'Optimism',
  //   nativeCurrency: ETH,
  //   blockExplorerUrls: ['https://optimistic.etherscan.io'],
  // },
  // 42161: {
  //   urls: [getInfuraUrlFor('arbitrum-mainnet'), 'https://arb1.arbitrum.io/rpc'].filter(Boolean) as string[],
  //   name: 'Arbitrum One',
  //   nativeCurrency: ETH,
  //   blockExplorerUrls: ['https://arbiscan.io'],
  // },
  137: {
    urls: [getInfuraUrlFor('polygon-mainnet'), 'https://polygon-rpc.com'].filter(Boolean) as string[],
    name: 'Polygon Mainnet',
    nativeCurrency: MATIC,
    blockExplorerUrls: ['https://polygonscan.com'],
    lookupValue: 'matic',
    chainId: 137,
  },
  // 42220: {
  //   urls: ['https://forno.celo.org'],
  //   name: 'Celo',
  //   nativeCurrency: CELO,
  //   blockExplorerUrls: ['https://explorer.celo.org'],
  // },
  43114: {
    urls: ['https://api.avax.network/ext/bc/C/rpc', 'https://avalanche-c-chain.publicnode.com'],
    name: 'Avalanche C-Chain',
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
    lookupValue: 'AVAX',
    chainId: 43114,
    blockExplorerUrls: ['https://snowtrace.io'],
  },
}

export const TESTNET_CHAINS: ChainConfig = {
  5: {
    urls: [getInfuraUrlFor('goerli')].filter(Boolean) as string[],
    name: 'Görli Testnet',
    lookupValue: 'goerli',
    chainId: 5,
  },
  // 420: {
  //   urls: [getInfuraUrlFor('optimism-goerli'), 'https://goerli.optimism.io'].filter(Boolean) as string[],
  //   name: 'Optimism Goerli',
  //   nativeCurrency: ETH,
  //   blockExplorerUrls: ['https://goerli-explorer.optimism.io'],
  // },
  // 421613: {
  //   urls: [getInfuraUrlFor('arbitrum-goerli'), 'https://goerli-rollup.arbitrum.io/rpc'].filter(Boolean) as string[],
  //   name: 'Arbitrum Goerli',
  //   nativeCurrency: ETH,
  //   blockExplorerUrls: ['https://testnet.arbiscan.io'],
  // },
  80001: {
    urls: [getInfuraUrlFor('polygon-mumbai')].filter(Boolean) as string[],
    name: 'Polygon Mumbai Testnet',
    nativeCurrency: MATIC,
    blockExplorerUrls: ['https://mumbai.polygonscan.com'],
    lookupValue: 'mumbai',
    chainId: 80001,
  },
  // 44787: {
  //   urls: ['https://alfajores-forno.celo-testnet.org'],
  //   name: 'Celo Alfajores',
  //   nativeCurrency: CELO,
  //   blockExplorerUrls: ['https://alfajores-blockscout.celo-testnet.org'],
  // },
  // 10532: {
  //   urls: ['https://rpc.buildbear.io/grieving-luminara-unduli-4004ce9f'],
  //   name: 'BuildBear',
  //   nativeCurrency: {
  //     "name": "BuildBear",
  //     "symbol": "BB",
  //     "decimals": 18
  //   },
  //   chainId: 10532,
  //   // blockExplorerUrls: [],
  //   lookupValue: 'BuildBear',
  // },
  43113: {
    name: 'Avalanche Fuji Testnet',
    urls: ['https://api.avax-test.network/ext/bc/C/rpc', 'https://avalanche-fuji-c-chain.publicnode.com'],
    nativeCurrency: {
        name: 'Avalanche',
        symbol: 'AVAX',
        decimals: 18,
    },
    chainId: 43113,
    lookupValue: 'fuji',
  },
}

export const CHAINS: ChainConfig = {
  ...MAINNET_CHAINS,
  ...TESTNET_CHAINS,
}

export const URLS: { [chainId: number]: string[] } = Object.keys(CHAINS).reduce<{ [chainId: number]: string[] }>(
  (accumulator, chainId) => {
    const validURLs: string[] = CHAINS[Number(chainId)].urls

    if (validURLs.length) {
      accumulator[Number(chainId)] = validURLs
    }

    return accumulator
  },
  {}
)
