import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box, BoxProps, IconButton } from '@material-ui/core';
import { useStoreActions, useStoreState } from 'Stores';
import PgIcon from 'Components/PgIcon';
import helpers from 'Utils/helpers';
import { BORDER_BLACK } from 'Theme/themeConstants';
import useToastMessage from 'Hooks/useToastMessage';
import PgTypo from 'Components/PgTypo';
import useAuthDialog from 'Features/Auth/useAuthDialog';
import PgButton from 'Components/PgButton';
import { User } from 'Models/User';
import useConfirmationDialog from 'Hooks/useConfirmationDialog';
import { WALLET_ICONS_MAP } from 'Constants/variables';
import MESSAGES from 'Utils/Messages';
import { useWeb3React } from '@web3-react/core';
import { connectWalletEagerly } from 'Constants/initialGenerator';
import { WalletType } from 'Models/Web3/@types';

export interface ConnectWalletProps {
  rootProps?: BoxProps;
  hideDisconnectBtn?: boolean;
  hideChangeBtn?: boolean
}

export const ConnectWalletButton: FC<ConnectWalletProps> = (props) => {
  const { rootProps, hideDisconnectBtn = false, hideChangeBtn = false } = props;

  const classes = useStyles({});

  const { fetchMe, updateUser, setAppUser } = useStoreActions(({ AuthStore: { fetchMe, updateUser, setAppUser } }) => ({
    fetchMe,
    updateUser,
    setAppUser,
  }));
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));

  const withConfirmationDialog = useConfirmationDialog();

  const { openDialog } = useAuthDialog(() => fetchMe());

  const { isActive, provider, connector, accounts } = useWeb3React();

  const userConnectedToOwnAccount = accounts?.find((account) => account === appUser?.publicAddress);

  const disconnect = async () => {
    try {
      if (!appUser) return;
      withConfirmationDialog(
        async () => {
          await updateUser({ id: appUser?.id, publicAddress: null, recentWalletUsed: null } as unknown as Partial<User> & { id: string });
          setAppUser({ ...appUser, publicAddress: undefined } as unknown as User);
        },
        {
          message: 'Disconnect Wallet',
          body: 'Are you sure you want to disconnect the wallet?',
          agreeText: 'Disconnect',
          cancelText: 'Cancel',
        },
        {
          successToastMessage: 'Wallet disconnected successfully',
        },
      );
    } catch (error) {
      console.error(error);
    }
  };

  const isPhantom = (provider as any)?.provider.isPhantom;
  const showSwitchAccount = isActive && !userConnectedToOwnAccount && provider && !isPhantom;
  const isWalletConnected = (isActive && userConnectedToOwnAccount) || isPhantom;

  const handleSwitchAccount = async () => {
    await provider?.send('wallet_requestPermissions', [{ eth_accounts: {} }]);
  }

  return (
    <Box mt={3} display="flex" alignItems="center" {...rootProps}>
      {appUser?.publicAddress ? (
        <>
          <WalletAddress publicAddr={appUser.publicAddress} recentWalletUsed={appUser.recentWalletUsed} isWalletConnected={isWalletConnected} />
          {/* {!isActive && (
            <PgButton
              quaternary
              onClick={async () => connectWalletEagerly(appUser)}
              underlined
              className={classes.changeWalletBtn}
            >
              Connect wallet
            </PgButton>
          )}
          {showSwitchAccount && (
            <PgButton quaternary underlined onClick={handleSwitchAccount}>
              Switch account
            </PgButton>
          )} */}
          {hideChangeBtn ? null : (
            <PgButton quaternary onClick={() => openDialog('wallet-select', { appUser })} underlined className={classes.changeWalletBtn}>
              Change wallet
            </PgButton>
          )}
          {hideDisconnectBtn ? null : (
            <PgButton quaternary onClick={disconnect} underlined className={classes.changeWalletBtn}>
              Disconnect wallet
            </PgButton>
          )}
        </>
      ) : (
        <Box display="grid" gridRowGap="15px">
          <PgButton secondary onClick={() => openDialog('wallet-select', { appUser })}>
            {'connect wallet'.toUpperCase()}
          </PgButton>
        </Box>
      )}
    </Box>
  );
};

export const WalletAddress: FC<{ publicAddr: string, recentWalletUsed?: WalletType, isWalletConnected?: boolean, showCopyBtn?: boolean }> = (props) => {

  const { publicAddr, recentWalletUsed, isWalletConnected = true, showCopyBtn = true } = props;

  const withToast = useToastMessage();

  const handleCopyAddress = () => {
    withToast(() => helpers.copyToClipboard(publicAddr), {
      genraToastMessage: MESSAGES.PUBLIC_ADDR_COPIED,
    });
  };

  return (
    <Box display="flex" alignItems="center">
      {recentWalletUsed && (
        <img src={WALLET_ICONS_MAP[recentWalletUsed]} alt={recentWalletUsed} width={16} style={{ marginRight: 8 }} />
      )}
      <div>
        <PgTypo variant="body1" typoWeight="fontWeightBold">{`${publicAddr.slice(0, 5)}...${publicAddr.slice(-4)}`}</PgTypo>
        {!isWalletConnected && <PgTypo variant="caption">Wallet not connected</PgTypo>}
      </div>
      {showCopyBtn && (
        <IconButton onClick={handleCopyAddress}>
          <PgIcon icon="icon-duplicate" size="small" />
        </IconButton>
      )}
    </Box>
  )
}

const ConnectWallet: FC = () => (
  <Box mt={3}>
    <PgTypo variant="caption">In web 3, you can use a wallet connection to log in or enter communities that are token-gated.</PgTypo>
    <ConnectWalletButton />
  </Box>
);

export default ConnectWallet;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    connectWalletBtn: {
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      border: BORDER_BLACK,
    },
    changeWalletBtn: {
      marginLeft: theme.spacing(3),
    },
  }),
);
