import React, { useState } from 'react';
// eslint-disable-next-line import/no-cycle
import AddContentDialog from '.';
import Theme from 'Theme';
import { MuiThemeProvider } from '@material-ui/core';

export type AddContentDialogState = Omit<ReturnType<typeof useAddContentDialog>, 'Component'>;

function useAddContentDialog() {
  const [open, setOpen] = useState(false);

  const addContent = () => setOpen(true);
  const closeAddContentDialog = () => setOpen(false);

  return {
    open,
    addContent,
    closeAddContentDialog,
    Component: (
      <MuiThemeProvider theme={Theme}>
        <AddContentDialog open={open} onClose={closeAddContentDialog} />
      </MuiThemeProvider>
    ),
  };
}

export default useAddContentDialog;
