import { TContentType, TDrop } from 'Models/Drop/@types';
import React, { FC } from 'react';
import { Grid, GridProps, makeStyles, Theme } from '@material-ui/core';
import { IMediaQuery, useMediaQuery } from 'Hooks/useMediaQuery';
import DropCardGrid from './DropCardGrid';
import DropSkeletonCard from './Skeletons/DropSkeletonCard';
import clsx from 'clsx';

export interface IDropList {
  drops: TDrop[];
  docType?: TContentType;
  isLoading: boolean;
  numColumns?: number;
  gridProps?: GridProps;
  onDropHover?: (id?: string) => void;
}

export const getNumberOfColumns = (mediaQuery: IMediaQuery, numColumns?: number) => {
  if (numColumns) return numColumns;
  if (mediaQuery.isDeviceSm || mediaQuery.isDeviceMd) return 1;
  if (mediaQuery.isDeviceLg) return 4;
  if (mediaQuery.isDeviceXl) return 6;
};

const DropList: FC<IDropList> = ({ drops, docType, isLoading = false, numColumns, gridProps, onDropHover }) => {
  const classes = useStyles();
  const mediaQuery = useMediaQuery();
  const numberOfColumns = getNumberOfColumns(mediaQuery, numColumns);

  return (
    <Grid container>
      {drops.map((drop: TDrop) => (
        <Grid className={clsx(classes.gridItem, 'drop-card')} item key={drop.id} xl={2} lg={3} md={4} sm={12} xs={12} {...gridProps}>
          <DropCardGrid drop={{ ...drop, doc_type: docType ?? drop.doc_type ?? 'Event' }} onDropHover={onDropHover}/>
        </Grid>
      ))}
      {isLoading
        ? Array.from(Array(numberOfColumns).keys())?.map((i) => (
            <Grid item key={i} xl={2} lg={3} md={4} sm={12} xs={12} {...gridProps}>
              <DropSkeletonCard />
            </Grid>
          ))
        : null}
    </Grid>
  );
};

export default DropList;

const useStyles = makeStyles<Theme>((theme) => ({
  gridItem: {
    borderRight: `1px solid ${theme.palette.common.black}`,
    borderBottom: `1px solid ${theme.palette.common.black}`,
    [theme.breakpoints.only('md')]: {
      '&:nth-child(3n)': {
        borderRight: 'none',
      },
      '&:nth-child(-n+3)': {
        borderTop: `1px solid ${theme.palette.common.black}`,
      },
    },
    [theme.breakpoints.only('lg')]: {
      '&:nth-child(4n)': {
        borderRight: 'none',
      },
      '&:nth-child(-n+4)': {
        borderTop: `1px solid ${theme.palette.common.black}`,
      },
    },
    [theme.breakpoints.up('xl')]: {
      '&:nth-child(6n)': {
        borderRight: 'none',
      },
      '&:nth-child(-n+6)': {
        borderTop: `1px solid ${theme.palette.common.black}`,
      },
    },
    [theme.breakpoints.down('sm')]: {
      // borderRight: 'none',
      borderLeft: `1px solid ${theme.palette.common.black}`,
      '&:nth-child(1)': {
        borderTop: `1px solid ${theme.palette.common.black}`,
      },
    },
  },
  skeletonLayout: {
    padding: '1rem',
    borderRadius: 0,
  },
}));
