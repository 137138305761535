import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import Typo from 'Components/Typo';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useStoreState } from 'Stores';
import { TEXT_COLOR } from 'Theme/themeConstants';

export interface UserInterestFormProps {
  // onSubmit: Function;
  // onCancel?: () => void;
  initialData: string[];
}

const UserInterestForm: React.FC<UserInterestFormProps> = (props) => {
  const classes = useStyles();
  const [error, setError] = useState<string>();
  const [tagIds, setTagIds] = useState<string[]>(props.initialData || []);
  const { tagsSummaries } = useStoreState(({ App: { tagsSummaries } }) => ({
    tagsSummaries,
  }));
  const formikProps = useFormikContext<any>();

  const handleChange = (id: string) => {
    let list = [...tagIds];
    let index = list.findIndex((i) => i === id);
    if (index > -1) {
      list.splice(index, 1);
    } else {
      list.push(id);
    }
    console.log('updated list', list);
    setTagIds([...list]);
    formikProps.setFieldValue('tagIds', list);
  };
  return (
    <>
      {error && (
        <Box ml={4} my={1}>
          <Typo variant="body1" weight="medium" color="error">
            {error}
          </Typo>
        </Box>
      )}
      <Box width="100%" display="flex" flexWrap={'wrap'} maxHeight={'400px'}>
        {tagsSummaries.map((it, i) => {
          let selected = tagIds.findIndex((i) => i === it.id) > -1;
          return (
            <Box
              key={i}
              // flexBasis={'50%'}
              width={'25%'}
              style={{
                backgroundColor: selected ? it.background : 'transparent',
              }}
              className={clsx(classes.item, {
                [classes.itemTop]: i < 4,
                [classes.itemLeft]: i % 4 === 0,
              })}
              onClick={() => handleChange(it.id)}
            >
              <Typo
                weight="bold"
                className={
                  selected ? classes.selectedText : classes.disselectedText
                }
                // color={selected ? 'primary' : 'textSecondary'}
              >
                {it.name.toUpperCase()}
              </Typo>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  // const { palette: { grey } } = theme;
  return createStyles({
    item: {
      borderRight: `0.25px solid ${'#A7A9AC'}`,
      borderBottom: `0.25px solid ${'#A7A9AC'}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 16,
      cursor: 'pointer',
    },
    itemTop: {
      borderTop: `0.25px solid ${'#A7A9AC'}`,
    },
    itemLeft: {
      borderLeft: `0.25px solid ${'#A7A9AC'}`,
    },
    selectedText: {
      fontSize: 13,
    },
    disselectedText: {
      fontSize: 13,
      color: TEXT_COLOR.secondary,
    },
  });
});

export default UserInterestForm;
