import React, { FC } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

const Cube: FC<SVGCIconProps> = (props) => {
  const { color = 'black', height = 16, width = 15 } = props;
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5503 3.144L7.84172 0.0477219C7.77379 0.0162838 7.69984 0 7.62498 0C7.55013 0 7.47617 0.0162838 7.40824 0.0477219L0.699638 3.144C0.610063 3.18546 0.534255 3.25173 0.481205 3.33496C0.428154 3.4182 0.400087 3.5149 0.400331 3.6136V12.3864C0.400087 12.4851 0.428154 12.5818 0.481205 12.665C0.534255 12.7483 0.610063 12.8145 0.699638 12.856L7.40824 15.9523C7.47617 15.9837 7.55013 16 7.62498 16C7.69984 16 7.77379 15.9837 7.84172 15.9523L14.5503 12.856C14.6399 12.8145 14.7157 12.7483 14.7688 12.665C14.8218 12.5818 14.8499 12.4851 14.8496 12.3864V3.6136C14.8499 3.5149 14.8218 3.4182 14.7688 3.33496C14.7157 3.25173 14.6399 3.18546 14.5503 3.144ZM7.62498 1.08498L13.1002 3.6136L7.62498 6.14223L2.14973 3.6136L7.62498 1.08498ZM1.43242 4.41864L7.10894 7.04015V14.6776L1.43242 12.0561V4.41864ZM8.14103 14.6776V7.04015L13.8175 4.41864V12.0561L8.14103 14.6776Z"
        fill={color}
      />
    </svg>
  );
};

export default Cube;
