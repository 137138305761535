import React, { FC } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

export interface ChatIconProps extends SVGCIconProps {
  iconProps?: {
    color1?: string;
    color2?: string;
    className?: string;
  };
}

const Chat: FC<ChatIconProps> = (props) => {

  const { width = 26, height = 26 } = props;
  const { color1 = "black", color2 = "white", className } = props.iconProps ?? {};

  return (
    // <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    //   <path d="M2.03178 11.2362H0.0949402V0.759766H10.6773V11.2362H5.20099L2.03178 12.8059V11.2362Z" fill={color1} />
    //   <path d="M10.5824 0.854002V11.1425H5.1801L5.13833 11.1632L2.12673 12.6539V11.15H0.189886V0.854002H10.5824ZM10.7723 0.666016H0V11.3305H1.93684V12.9584L5.22378 11.3305H10.7723V0.666016Z" fill={color1} />
    //   <path d="M10.1323 13.6131H4.65601V3.13477H15.2384V13.6131H13.3034V15.1828L10.1323 13.6131Z" fill={color2} />
    //   <path d="M15.1435 3.229V13.5194H13.2066V15.0232L10.195 13.5382L10.1551 13.5194H4.74717V3.229H15.1435ZM15.3333 3.04102H4.55728V13.7073H10.1096L13.3965 15.3297V13.7073H15.3333V3.04102Z" fill={color1} />
    //   <path d="M10.0146 6.44922L10.533 7.98506L12.1698 8.0001L10.8539 8.96259L11.3476 10.5078L10.0146 9.56603L8.68351 10.5078L9.17532 8.96259L7.85941 8.0001L9.49623 7.98506L10.0146 6.44922Z" fill={color2} stroke={color1} stroke-width="0.5" stroke-miterlimit="10" />
    //   <path d="M9.99999 13.6667H4.66666V3H15.3333V13.6667H13.3333V15.3333L9.99999 13.6667Z" stroke={color1} stroke-width="0.7" />
    // </svg>
    <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M3.80166 18.2603V17.7603H3.30166H0.654297V1.73608H16.8507V17.7603H8.45162H8.33458L8.2297 17.8123L3.80166 20.0055V18.2603Z"
        stroke={color1}
        fill={color1}
      />
      <path
        d="M17.1964 1.38873V18.1075H8.41767L8.34978 18.1411L3.45593 20.5635V18.1197H0.308566V1.38873H17.1964ZM17.5049 1.08325H0V18.413H3.14737V21.0584L8.48864 18.413H17.5049V1.08325Z"
        fill={color2}
      />
      <path
        d="M16.4649 22.1215H7.56592V5.09424H24.7623V22.1215H21.618V24.6723L16.4649 22.1215Z"
        fill={color2}
      />
      <path
        d="M24.608 5.24688V21.9687H21.4607V24.4125L16.5668 21.9993L16.502 21.9687H7.71408V5.24688H24.608ZM24.9166 4.94141H7.40552V22.2742H16.428L21.7692 24.9105V22.2742H24.9166V4.94141Z"
        fill={color1}
      />
      <path
        d="M16.2737 10.4797L17.1161 12.9755L19.7759 12.9999L17.6376 14.564L18.4398 17.075L16.2737 15.5445L14.1107 17.075L14.9098 14.564L12.7715 12.9999L15.4313 12.9755L16.2737 10.4797Z"
        fill={color2}
        stroke={color1}
        stroke-width="0.5"
        stroke-miterlimit="10"
      />
      <path
        d="M16.2499 22.2083H7.58325V4.875H24.9166V22.2083H21.6666V24.9167L16.2499 22.2083Z"
        stroke={color1}
        stroke-width="0.7"
      />
    </svg>
  )
}

export default Chat
