import { Box, makeStyles, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Spacer from 'Components/Spacer';
import React, { FC } from 'react';

const DropSkeletonCard: FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.skeletonLayout}>
      <Skeleton variant="rect" width="100%" height={328} />
      <Spacer height={10} />
      <Skeleton variant="rect" width="23%" height={15} />
      <Spacer height={15} />
      <Skeleton variant="rect" width="70%" height={25} />
      <Spacer height={15} />
      <Skeleton variant="rect" width="95%" height={15} />
      <Spacer height={10} />
      <Skeleton variant="rect" width="85%" height={15} />
      <Spacer height={15} />
      <Skeleton variant="rect" width="100%" height={50} />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  skeletonLayout: {
    padding: theme.spacing(2, 2, 3),
    borderRadius: 0,
  },
}));

export default DropSkeletonCard;
