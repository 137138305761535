import { alpha, StyleRules } from '@material-ui/core';
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides';
import { COLOR, THEME_PALETTE } from 'Theme/themeConstants';

const OutlinedInput: Partial<StyleRules<ComponentNameToClassKey['MuiOutlinedInput']>> = {
  adornedStart: {
    paddingLeft: 8,
  },
  inputAdornedStart: {
    paddingLeft: 8,
  },
  multiline: {
    padding: '12px 14px',
  },
  root: {
    minHeight: 32,
    // minWidth: 200,
    backgroundColor: THEME_PALETTE.grey.A200,
    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(COLOR.secondary.blue, 0.5),
      display: 'block',
    },
    borderRadius: 'none',
  },
  input: {
    // '&$focused': {
    // 	'& fieldset': {
    // 		borderColor: COLOR.secondary.blue,
    // 		borderWidth: 2,
    // 		outlineColor: COLOR.secondary.blue,
    // 		outlineWidth: 2,
    // 		outlineStyle: 'solid',
    // 	}
    // }
  },
  notchedOutline: {
    borderColor: COLOR.secondary.blue,
    // display: 'none',
    borderWidth: 0,
  },
};

export default OutlinedInput;

export const OutlinedInputDark: Partial<StyleRules<ComponentNameToClassKey['MuiOutlinedInput']>> = {
  adornedStart: {
    paddingLeft: 8,
  },
  inputAdornedStart: {
    paddingLeft: 8,
  },
  multiline: {
    padding: '12px 14px',
  },
  root: {
    minHeight: 32,
    backgroundColor: THEME_PALETTE.grey.A900,
    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(COLOR.secondary.blue, 0.5),
      display: 'block',
    },
    borderRadius: 'none',
    '& input:-webkit-autofill, input:-webkit-autofill:focus': { boxShadow: 'none', transition: 'background-color 600000s 0s, color 600000s 0s' },
  },
  input: {
    // '&$focused': {
    // 	'& fieldset': {
    // 		borderColor: COLOR.secondary.blue,
    // 		borderWidth: 2,
    // 		outlineColor: COLOR.secondary.blue,
    // 		outlineWidth: 2,
    // 		outlineStyle: 'solid',
    // 	}
    // }
  },
  notchedOutline: {
    borderColor: COLOR.secondary.blue,
    // display: 'none',
    borderWidth: 0,
  },
};
