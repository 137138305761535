import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ANALYTICS_USER_EVENT } from 'Analytics/analyticActions';
import PgDialog from 'Components/PgDialog';
import SignUpForm, { SignUpFormData } from 'Forms/SignUp';
import useAsyncTask from 'Hooks/useAsyncTask';
import useToastMessage from 'Hooks/useToastMessage';
import omit from 'lodash/omit';
import { User } from 'Models/User/@types';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { APP_ROUTES } from 'Routes';
import { useStoreActions, useStoreDispatch, useStoreState } from 'Stores';
import helpers from 'Utils/helpers';
import MESSAGES, { getTemplateString } from 'Utils/Messages';
import { Step, TAuthDialogContentProps } from '../@types';
import { TWalletConnectHookProps } from '../useWalletConnect';

const SignUp: React.FC<
  TAuthDialogContentProps & { handleUserSignUp: TWalletConnectHookProps['handleUserSignUp']; walletMeta: TWalletConnectHookProps['walletMeta'] }
> = (props) => {
  const { onDone, changeStep, handleUserSignUp, walletMeta, setMeta, meta } = props;
  const classes = useStyles({});
  const { pathname, search } = useLocation();
  const history = useHistory();

  const queryParams = helpers.locationParams<{ email?: string; flow?: string }>(search);

  const dispatch = useStoreDispatch();
  const { isAuthenticated, appUser, communityReferral } = useStoreState(
    ({ AuthStore: { isAuthenticated, appUser }, InviteStore: { communityReferral } }) => ({
      isAuthenticated,
      appUser,
      communityReferral,
    }),
  );

  const { signup } = useStoreActions(({ AuthStore: { signup } }) => ({
    signup,
  }));

  const withToast = useToastMessage();
  const handleSubmit = async (_data: User & { referringCode?: string }) => {
    const data = { ..._data, referringCode: _data.referringCode ?? communityReferral?.referCode };
    try {
      if (walletMeta?.email) {
        await handleUserSignUp.run(data as SignUpFormData);
        if (data.referringCode) {
          window.history.replaceState(null, '', '?hyperCommCrt=true');
          window.location.reload(); // Reloading to get latest community details if invite was accepted
        }
        return;
      }
      await withToast(
        async () => {
          const userInfo = await signup(data);

          dispatch({
            type: ANALYTICS_USER_EVENT,
            data: {
              eventName: 'USER_SIGNUP',
              context: { user: omit(userInfo, ['otpResult', 'firstName', 'isPublished']), type: 'email' },
              source: pathname,
            },
          });

          if (data.referringCode) {
            if (queryParams.flow === 'inviteCsv') history.replace(APP_ROUTES.HOME_PAGE.path);
            window.history.replaceState(null, '', '?hyperCommCrt=true');
            window.location.reload();
          }
        },
        {
          successToastMessage: { title: MESSAGES.SIGNUP_SUCCESS_TITLE, subtitle: getTemplateString('SIGNUP_SUCCESS_MESSAGE', { email: data.email }) },
        },
      );
      changeStep('verifyEmailPrompt');
    } catch (error) {
      // error handling
    }
  };

  const handleSubmitRunner = useAsyncTask(handleSubmit);
  const loading = handleSubmitRunner.status === 'PROCESSING';

  const handleChange = (step: Step) => {
    if (step === 'signup') setMeta?.({ ...meta, setStep: 'signup' });
    changeStep(step);
  }

  useEffect(() => {
    if (isAuthenticated && !loading) {
      onDone();
    }
  }, [isAuthenticated, loading, appUser]);
  return (
    <PgDialog
      // heading="Sign Up"
      body={
        <Box mt={-3}>
          <SignUpForm
            disableEmail={!!walletMeta?.email}
            isSubmitting={loading}
            initialData={{ referringCode: communityReferral?.referCode, email: (walletMeta?.email ?? queryParams.email) as string | undefined }}
            changeStep={handleChange}
            onSubmit={handleSubmitRunner.run}
            setMeta={setMeta}
            // removeSteps={!!queryParams.email ? ['email'] : undefined}
            meta={walletMeta}
          />
        </Box>
      }
      actionButtons={<></>}
      isForm
    />
  );
};

const useStyles = makeStyles(() => createStyles({}));

export default SignUp;
