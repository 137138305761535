import { action, Action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';
import CreatorRequestsModel from 'Models/CreatorRequests';
import { CommunityRequest } from 'Models/CreatorRequests/@types';
import communityRequestQueryUtil, { CreatorRequestQuery } from './communityRequestQueryUtil';

export interface CommunityRequestsState {
  communityRequests: CommunityRequest[];
  setCommunityRequests: Action<CommunityRequestsState, CommunityRequest[]>;
  fetchCommunityRequests: Thunk<CommunityRequestsState, CreatorRequestQuery>;
  updateCommunityRequestStatus: Action<CommunityRequestsState, { id: string; status: CommunityRequest['status'] }>;

  loading: boolean;
  setLoading: Action<CommunityRequestsState, boolean>;

  totalCount: number;
  setTotalCount: Action<CommunityRequestsState, number>;

  query: CreatorRequestQuery;
  setQuery: Action<CommunityRequestsState, CreatorRequestQuery>;

  onQueryChange: ThunkOn<CommunityRequestsState, CreatorRequestQuery>;
}

const CommunityRequestsStore: CommunityRequestsState = {
  communityRequests: [],
  setCommunityRequests: action((state, payload) => {
    state.communityRequests = payload;
  }),

  loading: false,
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),

  totalCount: 0,
  setTotalCount: action((state, payload) => {
    state.totalCount = payload;
  }),

  query: {
    page: 1,
    sort: {
      community: 'desc',
      user: 'newest',
      date: 'oldest',
    },
    status: 'pending',
    q: '',
  },

  setQuery: action((state, payload) => {
    state.query = payload;
  }),

  fetchCommunityRequests: thunk(async (actions, query) => {
    actions.setLoading(true);
    const _query = communityRequestQueryUtil.getSearchQuery(query);
    const { total, results } = await CreatorRequestsModel.searchCommunityRequests(query.q, _query);
    actions.setTotalCount(total);
    actions.setCommunityRequests(results.map((r) => r.hit));
    actions.setLoading(false);
  }),

  updateCommunityRequestStatus: action((state, payload) => {
    const index = state.communityRequests.findIndex((item) => item.id === payload.id);
    if (index !== -1) {
      if (payload.status === 'APPROVED') {
        // remove from list
        state.communityRequests.splice(index, 1);
        state.totalCount--;
      } else {
        state.communityRequests[index].status = payload.status;
      }
    }
  }),

  onQueryChange: thunkOn(
    (actions) => actions.setQuery,

    async (actions, { payload }) => {
      actions.fetchCommunityRequests(payload);
    }
  ),
};

export default CommunityRequestsStore;
