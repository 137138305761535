import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import PgTypo from 'Components/PgTypo';
import UserAvatar from 'Components/UserAvatar';
import CommunityModel from 'Models/Community';
import { TCommunity } from 'Models/Community/@types';
import { TEcosystemInviteStatus } from 'Models/Ecosystem';
import { getUserName } from 'Models/User/utils';
import React, { FC, useMemo } from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from 'Routes';
import { COLOR } from 'Theme/themeConstants';
import helpers from 'Utils/helpers';

export interface ICommunityGridCard {
  community: TCommunity;
  isUser?: boolean;
  isDarkTheme?: boolean;
  isEcosystem?: boolean;
  isPending?: boolean;
}

const CommunityGridCard: FC<ICommunityGridCard> = ({ community, isUser = false, isDarkTheme = false, isEcosystem, isPending }) => {
  // const { ref, width } = useLayoutQuery();
  const width = document.getElementById('ref')?.clientWidth || 0;
  const classes = useStyles({ width });
  const data = useMemo(
    () => ({
      name: isUser ? getUserName(community) : CommunityModel.getName(community),
      subtitle: community.subtitle || '',
      avatar: CommunityModel.getAvatarImage(community),
      link: generatePath(isUser ? APP_ROUTES.USER_DETAIL.path : APP_ROUTES.COMMUNITY_DETAIL.path, {
        communityId: community?.slug ?? community?.id ?? '#',
        id: community.slug ?? community.id ?? '#',
      }),
    }),
    [community, isUser],
  );
  const name = data.name || 'PG Community';

  return (
    <Link to={data.link}>
      <div>
        <Box className={classes.root} width="100%">
          <UserAvatar name={name} url={helpers.getPictureUrl(data.avatar)} size={`calc(100% * 0.7)`}></UserAvatar>
          <Box mt={1}>
            <PgTypo h6 align="center" contrast={isDarkTheme} limitLines={2}>
              {name || 'PG Community'}
            </PgTypo>
            {isPending && isEcosystem && (
              <PgTypo b2 align="center" className={clsx(classes.ecoSubtitle, classes.pending)}>
                Pending
              </PgTypo>
            )}
            <PgTypo b2 align="center" contrast={isDarkTheme} className={clsx({ [classes.ecoSubtitle]: isEcosystem && !isPending })} limitLines={2}>
              {data.subtitle}
            </PgTypo>
          </Box>
        </Box>
      </div>
    </Link>
  );
};

const useStyles = makeStyles<Theme, { width: number }>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    whiteSpace: 'pre-wrap',
  },
  ecoSubtitle: {
    marginTop: theme.spacing(1),
  },
  pending: {
    color: COLOR.tertiary.activism,
  },
}));

export default CommunityGridCard;
