const KEY_PREFIX = 'REACT_APP_';

const config = {
  get: (key: string) => process.env[KEY_PREFIX + key],
  GOOGLE_MAP_URL:
    // 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBkw72RLcZ7A34Rt040WTuo7-DClAUoGEc&libraries=places',
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyCc6pbTkgzx5HeojyGKICeppLZlmVa8MuA&libraries=places',
};

export default config;
