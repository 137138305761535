import { makeStyles } from '@material-ui/core';
import PgButton from 'Components/PgButton';
import PgDialog from 'Components/PgDialog';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import React, { FC } from 'react';
import { useStoreState } from 'Stores';
import { FONT_FAMILY } from 'Theme/themeConstants';
import { TAuthDialogContentProps } from '../@types';
import { TWalletConnectHookProps } from '../useWalletConnect';

const WalletConnecting: FC<
  TAuthDialogContentProps & { setWalletStep: TWalletConnectHookProps['setWalletStep']; setWalletMeta: TWalletConnectHookProps['setWalletMeta'] }
> = (props) => {
  const classes = useStyles();
  const { meta, setMeta, setWalletMeta, setWalletStep } = props;
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
  const handleBackClick = () => {
    setWalletStep('wallet-select');
    setMeta?.((oldMeta = { variant: 'full' }) => ({ ...oldMeta, variant: oldMeta.variant }));
    setWalletMeta?.({});
  };

  const { isDeviceSm } = useMediaQuery();

  return (
    <PgDialog
      headingContainerProps={{ display: 'flex', justifyContent: 'center', position: 'relative' }}
      headingProps={{ h3: !isDeviceSm, h7: isDeviceSm, align: 'center', typoWeight: 'fontWeightBold', h6: false }}
      subheadingProps={{ className: classes.subtitle, c2: false, b1: true }}
      heading={meta?.title}
      subheading={meta?.subtitle}
      actionButtons={
        appUser && appUser.id ? undefined : (
          <PgButton variant="outlined" onClick={handleBackClick}>
            Back to login
          </PgButton>
        )
      }
      positive={false}
      negative={true}
      negativeText="Cancel"
    />
  );
};

export default WalletConnecting;

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontFamily: FONT_FAMILY.secondary,
    marginBottom: theme.spacing(4),
  },
}));
