/* eslint-disable import/no-cycle */
import { LinearProgress } from '@material-ui/core';
import PrivateRoute from 'Features/Auth/PrivateRoute';
import React, { FC, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { APP_ROUTES } from 'Routes';
import Discussion from 'Screens/Dashboard/Discussion';
import { useStoreState } from 'Stores';
import RoleUtils from 'Utils/RoleUtils';
import lazyImport from '../../lazy-import';
import Page404 from '../../Screens/Page404';

const Overview = lazyImport('../Screens/Dashboard/Overview');
const CommunityManagement = lazyImport('../Screens/Dashboard/CommunityManagement');
const Login = lazyImport('../Screens/Dashboard/Login');
const EventsMediaNavigation = lazyImport('../Routes/DashboardRoutes/EventsAndMediaNavigation');
const Integrations = lazyImport('Screens/Dashboard/Integrations');
const Insights = lazyImport('Screens/Dashboard/Insights');
const Payments = lazyImport('Screens/Dashboard/Payments');
// const CreateCommunity = lazyImport('../Screens/CreateCommunity');
const CommunityFormScreen = lazyImport('../Screens/CommunityFormScreen');
const CommunitySettings = lazyImport('../Screens/Dashboard/CommunitySettings');
const CreatorRequests = lazyImport('../Screens/Dashboard/CreatorRequests');
const ResolutionCenter = lazyImport('../Screens/Dashboard/ResolutionCenter');
const Events = lazyImport('../Screens/Dashboard/EventAndMedia/Events');
const Communities = lazyImport('../Screens/Dashboard/Communities');
const GlobalUsers = lazyImport('../Screens/Dashboard/GlobalUsers');

const AppNavigation: FC = () => {
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({
    appUser,
  }));
  return (
    <>
      <Suspense fallback={null}>
        <Switch>
          <Route path={APP_ROUTES.LOGIN.path} exact component={Login} />
          <Route path={'/*'} exact render={() => <>{RoleUtils.isSuperAdmin(appUser) ? <SuperadminNavigation /> : <CommunityAdminNavigation />}</>} />
        </Switch>
      </Suspense>
    </>
  );
};

const CommunityAdminNavigation: FC = () => (
  <Switch>
    <Route path={APP_ROUTES.HOME_PAGE.path} exact render={() => <Redirect to={DASHBOARD_ROUTES.OVERVIEW.path} />} />
    <PrivateRoute path={DASHBOARD_ROUTES.OVERVIEW.path} component={Overview} />
    <PrivateRoute path={DASHBOARD_ROUTES.EVENTS_LIST.path} component={EventsMediaNavigation} />
    {/* <PrivateRoute path={DASHBOARD_ROUTES.EDIT_COMMUNITY.path} exact component={CreateCommunity} /> */}
    <PrivateRoute path={DASHBOARD_ROUTES.EDIT_COMMUNITY.path} exact component={CommunityFormScreen} />
    <PrivateRoute path={DASHBOARD_ROUTES.COMMUNITY_MANAGEMENT.path} component={CommunityManagement} />

    <PrivateRoute path={DASHBOARD_ROUTES.INTEGRATIONS.path} component={Integrations} />
    <PrivateRoute path={DASHBOARD_ROUTES.PAYMENTS.path} component={Payments} />
    <PrivateRoute path={DASHBOARD_ROUTES.INSIGHTS.path} component={Insights} />
    <PrivateRoute path={DASHBOARD_ROUTES.COMMUNUNITY_SETTINGS.path} component={CommunitySettings} />
    <PrivateRoute path={DASHBOARD_ROUTES.RESOLUTION_CENTER.path} component={ResolutionCenter} />
    <PrivateRoute path={DASHBOARD_ROUTES.CONVERSATIONS.path} component={Discussion} />
    <PrivateRoute path="/*" exact component={Page404} />
  </Switch>
);

const SuperadminNavigation: FC = () => (
  <Switch>
    <Route path={SUPERADMIN_ROUTES.HOME_PAGE.path} exact render={() => <Redirect to={SUPERADMIN_ROUTES.COMMUNITIES.path} />} />
    <PrivateRoute path={SUPERADMIN_ROUTES.CREATOR_REQUESTS.path} exact component={CreatorRequests} />
    <PrivateRoute path={SUPERADMIN_ROUTES.COMMUNITIES.path} exact component={Communities} />
    <PrivateRoute path={SUPERADMIN_ROUTES.MEMBERS.path} exact component={GlobalUsers} />
    <PrivateRoute path={SUPERADMIN_ROUTES.DROPS.path} exact component={Events} />
    <PrivateRoute path={DASHBOARD_ROUTES.EVENTS_LIST.path} component={EventsMediaNavigation} />
  </Switch>
);

const BASE_PATH = APP_ROUTES.DASHBOARD_HOME.path;
export const DASHBOARD_ROUTES = {
  HOME_PAGE: {
    path: BASE_PATH,
  },
  OVERVIEW: {
    path: `${BASE_PATH}/overview`,
  },
  LOGIN: {
    path: '/login',
  },
  COMMUNITY_MANAGEMENT: {
    path: `${BASE_PATH}/community-management`,
  },
  // NEW_COMMUNITY: {
  //   path: `${BASE_PATH}/community-management/community/new`,
  // },
  EDIT_COMMUNITY: {
    path: `${BASE_PATH}/community-management/community/edit`,
  },
  EVENTS_LIST: {
    path: `${BASE_PATH}/events-and-media`,
  },
  PROFILE: {
    path: `${BASE_PATH}/profile`,
  },
  INTEGRATIONS: {
    path: `${BASE_PATH}/integrations`,
  },
  RESOLUTION_CENTER: {
    path: `${BASE_PATH}/resolution-center`,
  },
  CONVERSATIONS: {
    path: `${BASE_PATH}/conversations`,
  },
  PAYMENTS: {
    path: `${BASE_PATH}/payments`,
  },

  INSIGHTS: {
    path: `${BASE_PATH}/insights`,
  },
  INVITE_FRIENDS: {
    path: `${BASE_PATH}/invite-friends`,
  },
  COMMUNUNITY_SETTINGS: {
    path: `${BASE_PATH}/settings`,
  },
};

export const SUPERADMIN_ROUTES = {
  HOME_PAGE: {
    path: '/',
  },
  CREATOR_REQUESTS: {
    path: `${BASE_PATH}/creator-requests`,
  },
  COMMUNITIES: {
    path: `${BASE_PATH}/communities`,
  },
  MEMBERS: {
    path: `${BASE_PATH}/members`,
  },
  DROPS: {
    path: `${BASE_PATH}/drops`,
  },
};

export const MOMENTS_PATH = '/drops';
export const COMMUNITIES_PATH = '/communities';
export const WEB_INVITES_PATH = '/account/invite-friends';

export default AppNavigation;
