import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// eslint-disable-next-line import/no-cycle
import { SVGCIconProps } from '../SVGCIcon';

export interface RemoveIconProps extends SVGCIconProps {
  iconProps?: Record<string, unknown>;
}

export const RemoveIcon: FC<RemoveIconProps> = (props) => {
  const { color = '#9147FE', width = 37, height = 38 } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2218 11.2217C15.513 6.9305 22.4949 6.93839 26.7782 11.2217C31.0708 15.5143 31.0699 22.4863 26.7782 26.778C22.487 31.0692 15.5051 31.0613 11.2218 26.778C6.93853 22.4947 6.93064 15.5129 11.2218 11.2217ZM10.5147 10.5146C15.1969 5.83238 22.8119 5.84124 27.4853 10.5146C32.1685 15.1978 32.1675 22.8029 27.4853 27.4851C22.8031 32.1673 15.1881 32.1585 10.5147 27.4851C5.84138 22.8118 5.83252 15.1968 10.5147 10.5146ZM23.5962 15.1108C23.7915 14.9155 23.7915 14.5989 23.5962 14.4037C23.4009 14.2084 23.0843 14.2084 22.8891 14.4037L19 18.2928L15.1109 14.4037C14.9157 14.2084 14.5991 14.2084 14.4038 14.4037C14.2085 14.5989 14.2085 14.9155 14.4038 15.1108L18.2929 18.9999L14.4038 22.8889C14.2085 23.0842 14.2085 23.4008 14.4038 23.5961C14.5991 23.7913 14.9157 23.7913 15.1109 23.5961L19 19.707L22.8891 23.5961C23.0843 23.7913 23.4009 23.7913 23.5962 23.5961C23.7915 23.4008 23.7915 23.0842 23.5962 22.8889L19.7071 18.9999L23.5962 15.1108Z"
        fill={color}
      />
    </svg>
  );
};

export const Twitch: FC<RemoveIconProps> = (props) => {
  const classes = useStyles();
  return (
    <i className={classes.root}>
      <RemoveIcon {...props} />
    </i>
  );
};

export default Twitch;

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      position: 'relative',
      display: 'inline-block',
    },
    playIcon: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-70%)',
    },
  })
);
