import { Box, Button, CircularProgress, createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import clsx from 'clsx';
import PgButton from 'Components/PgButton';
import SocialInvite from 'Features/Invites/SocialInvite';
import useToastMessage from 'Hooks/useToastMessage';
import React, { FC } from 'react';
import helpers from 'Utils/helpers';
import { InvitationDialogProps } from '..';

export interface InviteLinkProps extends Pick<InvitationDialogProps, 'socialShareText' | 'inviteLink' | 'isInviteLinkLoading'> {}

const InviteLink: FC<InviteLinkProps> = (props) => {
  const { socialShareText, inviteLink, isInviteLinkLoading } = props;
  const classes = useStyles();
  const withToast = useToastMessage();
  const onCopyLink = () => {
    withToast(() => helpers.copyToClipboard(inviteLink), { successToastMessage: 'Link copied successfully.' });
  };
  return (
    <div>
      <Box className={classes.inputContainers}>
        <Box>
          <TextField
            disabled
            fullWidth
            value={inviteLink}
            variant="outlined"
            InputLabelProps={{
              variant: 'standard',
            }}
            InputProps={{
              className: clsx(classes.textField, classes.inviteTextField),
            }}
            inputProps={{
              className: classes.inputField,
            }}
          />
        </Box>
        <Box className={classes.actionDiv}>
          <PgButton onClick={onCopyLink} fullWidth primary disabled={isInviteLinkLoading}>
            {isInviteLinkLoading ? <CircularProgress /> : 'COPY LINK'}
          </PgButton>
        </Box>
      </Box>
      <SocialInvite url={inviteLink} body={socialShareText} />
    </div>
  );
};

export default InviteLink;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      // background: '#F8F8F8',
      // border: 'none',
      display: 'flex !important',
      flexWrap: 'wrap',
    },
    inviteTextField: {
      padding: `4px 14px`,
    },
    inputField: {
      width: 0,
      flex: '1 1 0%',
      height: 32,
      margin: theme.spacing(1, 0),
      padding: 0,
    },
    inputContainers: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      marginTop: theme.spacing(2),
    },
    actionDiv: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    rowheight: {
      height: 56,
    },
  }),
);
