import React from 'react';
import { ReactForm } from 'react-forms';
import { useFormConfig } from './config';

export interface LoginFormData {
  email: string;
  password: string;
  referringCode?: string;
}

export interface LoginFormProps {
  onSubmit: (data: LoginFormData) => void;
  initialData: Partial<LoginFormData>;
  isSubmitting: boolean;
  disabledEmail?: boolean;
}

const LoginForm: React.FC<LoginFormProps> = (props) => {
  const { initialData, onSubmit, isSubmitting, disabledEmail } = props;
  const { actionConfig, inputConfig, validationSchema } = useFormConfig(!!disabledEmail);
  return (
    <ReactForm
      initialValues={initialData}
      formId="Login"
      config={inputConfig}
      actionConfig={actionConfig}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      formSettings={{
        verticalSpacing: 16,
      }}
      isInProgress={isSubmitting}
    />
  );
};

export default LoginForm;
