import React, { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, TextField, TextFieldProps } from '@material-ui/core';
import { IFieldProps } from 'react-forms';
import clsx from 'clsx';
import Typo from 'Components/Typo';
import { DIVIDER_BORDER } from 'Theme/themeConstants';

// color picker
import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';
import PgIcon from 'Components/PgIcon';

export interface ColorFieldProps extends IFieldProps {
  fieldProps?: {
    className?: string;
    label?: string;
    type?: 'hex';
    shouldShowColorPickerOnFocus?: boolean;
  };
}

const ColorPickerField: FC<ColorFieldProps> = (props) => {
  const { fieldProps, fieldConfig, formikProps } = props;
  const classes = useStyles({});
  const { shouldShowColorPickerOnFocus } = fieldProps || {};
  const name = fieldConfig?.valueKey || '--';
  const className = fieldProps?.className || '';
  const label = fieldProps?.label || 'Hex value';
  const value = formikProps?.values?.[name] || '';
  const type = fieldProps?.type || 'hex';
  const error = formikProps?.errors?.[name] || '';
  const [showColorPicker, setShowColorPicker] = useState(false);
  const handleChange: TextFieldProps['onChange'] = (e) => {
    formikProps?.setFieldValue(name, e.target.value, true);
  };
  const [color, setColor] = useColor('hex', value);

  const toggleShowColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };

  const handleColorPickerChange = (value: any) => {
    formikProps?.setFieldValue(name, value.hex, true);
  };

  const handleColorPickerClose = () => {
    setShowColorPicker(false);
  };

  return (
    <Box className={clsx(classes.root, className)}>
      <div className={classes.color} style={{ backgroundColor: value }} />
      <Box className={classes.bx}>
        <Typo gutterBottom variant="caption" color="textSecondary">
          {label}
        </Typo>

        {showColorPicker && (
          <div className={classes.colorPickerDiv}>
            <ColorPicker
              width={200}
              height={130}
              color={color}
              onChange={handleColorPickerChange}
              hideRGB
              hideHSB
              dark
            />
            <Button
              className={classes.colorPickerBtn}
              onClick={handleColorPickerClose}
            >
              OKAY
            </Button>
          </div>
        )}
        <TextField
          variant="outlined"
          name={name}
          value={value}
          error={!!error}
          helperText={error}
          onBlur={(
            e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            if (shouldShowColorPickerOnFocus) setShowColorPicker(false);
            if (formikProps) formikProps.handleBlur(e);
          }}
          onChange={handleChange}
          onFocus={
            shouldShowColorPickerOnFocus
              ? () => setShowColorPicker(true)
              : undefined
          }
          InputProps={{
            endAdornment: (
              <div
                onClick={(_event: React.MouseEvent<HTMLElement>) => {
                  toggleShowColorPicker();
                }}
              >
                <PgIcon icon="icon-color" />
              </div>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: 66,
  },
  color: {
    border: DIVIDER_BORDER,
    borderRadius: 3,
    width: 66,
    height: 66,
    marginRight: 20,
  },
  colorPickerDiv: {
    left: '400px',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
  },
  colorPickerBtn: {
    top: '-10px',
    display: 'flex',
    justifyContent: 'space-around',
    borderRadius: '0 0 10px 10px',
    backgroundColor: '#181818',
    color: 'white',
  },
  bx: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default ColorPickerField;
