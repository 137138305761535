/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useMemo } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useStoreState } from 'Stores';
import { Link } from 'react-router-dom';
import { SEARCH_ROUTES } from 'Routes/SearchNavigation';
import withViewModel from 'Core/withViewModel';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import PgTypo from 'Components/PgTypo';
import Drops from './Sections/Drops';
import Communities from './Sections/Communities';
import Users from './Sections/Users';
import SearchViewModel, { SearchViewModelState } from './SearchViewModel';
import { THEME_PALETTE } from '../../Theme/themeConstants';
import Loader from 'Components/Loader';

export interface TSearchProps extends SearchViewModelState {}

const Search: React.FC<TSearchProps> = (props) => {
  const { loading, searchTerm, communities, drops, users } = useStoreState(
    ({ SearchPageStore: { loading, searchTerm, communities, drops, users } }) => ({ loading, searchTerm, communities, drops, users })
  );
  const { isDeviceSm } = useMediaQuery();
  const classes = useStyles();

  const seeMorelink = useMemo(
    () => ({
      drops: `${SEARCH_ROUTES.MORE.path}?type=drop&q=${searchTerm}`,
      community: `${SEARCH_ROUTES.MORE.path}?type=community&q=${searchTerm}`,
      user: `${SEARCH_ROUTES.MORE.path}?type=user&q=${searchTerm}`,
    }),
    [searchTerm]
  );

  const title = `You found <span class=${classes.drops}>${drops.total} drops</span>, <span>${communities.total} communities</span> and <span>${users.total} users</span> for "${searchTerm}"`;

  const emptyState = () => {
    return (
      <Box>
        <PgTypo h7 align="center" className={classes.title}>
          {/* variant={isDeviceSm ? 'body1' : 'h6'} */}
          No results found for “{searchTerm}”.
        </PgTypo>

        <PgTypo h7 align="center" className={classes.subtitle}>
          Try searching for something else.
        </PgTypo>
      </Box>
    );
  };

  const linkText = useCallback(
    (text: string, link?: string) =>
      link ? (
        <Link className={clsx(classes.result, classes.link)} to={link}>
          {text}
        </Link>
      ) : (
        <span className={classes.result}>{text}</span>
      ),
    []
  );

  return !loading && !drops.total && !communities.total && !users.total ? (
    emptyState()
  ) : loading ? <Loader /> : (
    <div>
      {/* <Typo align='center' weight='extended-regular' className={classes.title} variant='h6' dangerouslySetInnerHTML={{ __html: title }} /> */}
      <PgTypo h7 align="center" className={classes.title}>
        {linkText(`${drops.total} ${pluralParser('drop', drops.total)}`, drops.total ? seeMorelink.drops : undefined)},{' '}
        {linkText(`${communities.total} ${pluralParser('community', communities.total)}`, communities.total ? seeMorelink.community : undefined)}
        &nbsp;and {linkText(`${users.total} ${pluralParser('member', users.total)}`, users.total ? seeMorelink.user : undefined)}
        &nbsp;found for "{searchTerm}"
      </PgTypo>
      <Drops />
      <Communities />
      <Users />
    </div>
  );
};

export const pluralParser = (text: string, length: number) => {
  if (text.slice(-1) === 'y') {
    return length === 1 ? text : `${text.slice(0, -1)}ies`;
  }
  return length === 1 ? text : `${text}s`;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      // marginTop: theme.spacing(5),
      // marginBottom: theme.spacing(5),
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    result: {
      fontSize: 'inherit',
      fontWeight: 'inherit',
      color: 'inherit',
      cursor: 'unset',
    },
    link: {
      borderBottom: `1px solid`,
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    drops: {
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    subtitle: {
      color: THEME_PALETTE.grey.A300,
      marginTop: theme.spacing(23.25),
    },
  })
);

export default withViewModel(Search, new SearchViewModel());
