import { Avatar, Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typo from 'Components/Typo';
import React, { useEffect, useState } from 'react';
import ToRight from 'Assets/icons/sidebar/to_right.svg';
import helpers from 'Utils/helpers';
import { useStoreActions } from 'Stores';
import { SUPERADMIN_ROUTES } from 'Routes/DashboardRoutes/AppNavigation';
import { generatePath, NavLink } from 'react-router-dom';
import { THEME_PALETTE } from 'Theme/themeConstants';
import { APP_ROUTES } from 'Routes';
import { EVENT_MEDIA_ROUTES } from 'Routes/DashboardRoutes/EventsAndMediaNavigation';
import CommunityModel from 'Models/Community';
import { TSearchResult } from 'Models';
import { TCommunity } from 'Models/Community/@types';
import UserModel from 'Models/User';
import { useDropsList } from 'Features/Drops/useDropsList';

export interface AdminConsoleDefaultSidebarProps {
  open: boolean;
}

interface Total {
  communities: number;
  users: number;
  drops: number;
}

const NAVS = (total: Total) => [
  // { labelFull: 'Manage community requests', label: 'MCR', key: 'manage-community-requests', url: SUPERADMIN_ROUTES.CREATOR_REQUESTS.path },
  {
    labelFull: `Communities (${total.communities})`,
    label: 'C',
    key: 'communities',
    url: generatePath(SUPERADMIN_ROUTES.COMMUNITIES.path, { communityId: 'super' }),
  },
  {
    labelFull: `Members (${total.users})`,
    label: 'M',
    key: 'members',
    url: generatePath(SUPERADMIN_ROUTES.MEMBERS.path, { communityId: 'super' }),
  },
  { labelFull: `Drops (${total.drops})`, label: 'D', key: 'drops', url: generatePath(SUPERADMIN_ROUTES.DROPS.path, { communityId: 'super' }) },
];

const AdminConsoleDefaultSidebar = (props: AdminConsoleDefaultSidebarProps): JSX.Element => {
  const classes = useStyles();
  const { open } = props;
  const { setOpen } = useStoreActions(({ DrawerSidebarStore: { setOpen } }) => ({ setOpen }));

  const { init: initDroplist, total: totalDrops } = useDropsList({
    route: generatePath(SUPERADMIN_ROUTES.DROPS.path, { communityId: 'super' }),
  });

  const [total, setTotal] = useState<Total>({ communities: 0, users: 0, drops: 0 });

  useEffect(() => {
    CommunityModel.search<TSearchResult<TCommunity>>('', { limit: 1, skip: 0, where: { approvalStatus: ['APPROVED'] } }).then((res) =>
      setTotal((t) => ({ ...t, communities: res.total })),
    );
    UserModel.search('', {
      limit: 1,
      skip: 0,
      user: { include: ['roles', 'wallet'] },
      entityFilter: { entitySort: 'credits_remaining_desc' },
    }).then((res) => setTotal((t) => ({ ...t, users: res.total })));
    initDroplist();
  }, []);
  useEffect(() => {
    if (totalDrops) setTotal((t) => ({ ...t, drops: totalDrops }));
  }, [totalDrops]);

  return (
    <>
      <Box className={classes.root}>
        {open ? (
          <>
            <Box className={classes.topBox}>
              <Typo variant="h6" weight="bold">
                Admin Console
              </Typo>
            </Box>
            <Box className={classes.bottomBox}>
              {NAVS(total).map((item, index) => (
                <NavLink to={item.url} key={item.key} activeClassName={classes.activeLink} className={classes.navItem}>
                  {item.labelFull}
                </NavLink>
              ))}
            </Box>
          </>
        ) : (
          <>
            <Box mr={1} onClick={() => setOpen(!open)} className={classes.topContentBox}>
              <img src={ToRight} alt="" className={classes.toRight} />
            </Box>
            <Box>
              <Box className={classes.topContentBox}>
                <Avatar alt="A" src={helpers.getPlaceholderImageUrl()} />
              </Box>
              <Box className={classes.bottomBox}>
                {NAVS(total).map((item, index) => (
                  <NavLink to={item.url} activeClassName={classes.activeLink} key={item.key} className={classes.navItem}>
                    {item.label}
                  </NavLink>
                ))}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default AdminConsoleDefaultSidebar;

const useStyles = makeStyles((theme: Theme) => ({
  root: { color: theme.palette.primary.main },
  bottomBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  navItem: {
    textTransform: 'none',
    justifyContent: 'left',
    marginBottom: 8,
    textDecoration: 'none',
    color: 'inherit',
    padding: '8px 0 8px 16px',
  },
  activeLink: {
    background: THEME_PALETTE.grey['800'],
    color: '#fff',
  },
  contentBox: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0 20px 0',
  },
  topBox: {
    padding: '20px 0 20px 20px',
    borderBottom: `1px solid`,
    borderColor: theme.palette.primary.main,
  },
  topContentBox: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0 20px 0',
  },
  toRight: { filter: theme.palette.type === 'dark' ? 'invert(100%)' : undefined },
}));
