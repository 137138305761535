import { DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DialogWrapper from 'Components/DialogWrapper';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import UserSearch, { FetchUsersParams } from 'Features/User/UserSearch';
import { isString } from 'lodash';
import { User } from 'Models/User/@types';
import React, { FC } from 'react';

/**
 * This component is a dialog used for rendering user list with a search box.
 */

export interface UserListDialogProps {
  title: string | React.ReactNode;
  open: boolean;
  onClose: () => void;
  fetchData: (params: FetchUsersParams) => Promise<{
    total: number;
    users: User[];
  }>;
  onUserClick?:(user:User) => void
}

export const UserListDialog: FC<UserListDialogProps> = ({ open, onClose, title, fetchData, onUserClick }) => {
  const classes = useStyles();
  return (
    <DialogWrapper PaperProps={{ className: classes.root }} variant="light" scroll="paper" onClose={onClose} open={open}>
      <IconButton className={classes.closeIcon} onClick={onClose}>
        <PgIcon icon="icon-close" />
      </IconButton>
      <DialogTitle disableTypography>
        {isString(title) ? (
          <PgTypo align="center" h6>
            {title}
          </PgTypo>
        ) : (
          <>{title}</>
        )}
      </DialogTitle>
      <DialogContent id="scrollableDiv">
        <UserSearch fetchData={fetchData} onUserClick={onUserClick} />
      </DialogContent>
    </DialogWrapper>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    root: {
      width: 600,
      height: 600,
      [theme.breakpoints.down('sm')]:{
      height: '100%',

      },
      '& .userContainer': {
        overflowY: 'auto',
      },
    },
    closeIcon: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  });
});

export default UserListDialog;
