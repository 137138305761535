import React, { FC } from 'react';
import GoogleUtils, { TGooglePlaceSuggestCategories } from '../../Resources/google';
import AutoSuggest, { AutoSuggestProps } from './AutoSuggest';

export type GoogleLocationSuggestProps = {
  onResultClick: (result: any) => void;
  suggestionsTypes: TGooglePlaceSuggestCategories[];
  disableSearchIcon?: boolean;
  label?: string;
  autoSuggestProps?: Partial<AutoSuggestProps<any>>;
  className?: string;
  error?: string;
};

const GoogleLocationSuggest: FC<GoogleLocationSuggestProps> = (props) => {
  const { className, label, disableSearchIcon, onResultClick, autoSuggestProps, error } = props;
  return (
    <>
      <AutoSuggest<any>
        fetchSuggestions={async (input) => {
          const results = (await GoogleUtils.placeSuggest(input, props.suggestionsTypes)) as any[];
          return results ?? [];
        }}
        autoCompleteProps={{
          getOptionLabel: (option: any) => option.description,
          getOptionSelected: (option: any) => option.description,
        }}
        className={className}
        label={label}
        disableSearchIcon={disableSearchIcon}
        onResultClick={onResultClick}
        variant="outlined"
        error={!!error}
        helperText={error}
        {...autoSuggestProps}
      />
    </>
  );
};

export default GoogleLocationSuggest;
