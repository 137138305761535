/* eslint-disable react/require-default-props */
import React, { FC, useState, useCallback, useEffect } from 'react';
import { TextField, TextFieldProps, CircularProgress, InputAdornment, FormHelperText, Box } from '@material-ui/core';
import { IFieldProps, FormConfig } from 'react-forms';
import AxiosUtils from 'Resources/AxiosUtils';
import { get } from 'lodash';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import { INVALID_URL_MESSAGE, INVALID_URL_PREFIX_MESSAGE, VALID_URL_PREFIX_REGEX } from 'Constants/variables';
import PgTypo from 'Components/PgTypo';
import UrlInput from 'Components/Inputs/UrlInput';

export interface ILinkPreviewProps extends IFieldProps {
  fieldProps?: {
    required?: boolean;
    pattern?: RegExp;
    fetchLinkData?: (value: string, valueKey?: string, formikProps?: FormikProps<any>) => Promise<any>;
    textFieldProps?: TextFieldProps;
    additionalHeaderProps?: {
      title?: string;
      subtitle?: string;
      helperText?: string;
    };
  };
}
const containsEncodedComponents = (link: string) => {
  return decodeURI(link) !== decodeURIComponent(link);
};
const isValidURL = (str: string, regex?: RegExp) => {
  let testingURI = str;
  if (containsEncodedComponents(testingURI)) {
    testingURI = decodeURIComponent(str);
  }
  const pattern =
    regex ||
    new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
  return !!pattern.test(testingURI);
};

const isUrlValidate = Yup.string().matches(VALID_URL_PREFIX_REGEX, INVALID_URL_PREFIX_MESSAGE).url(`${INVALID_URL_MESSAGE}`);

const loadingIndicator = (
  <InputAdornment position="end">
    <CircularProgress color="secondary" />
  </InputAdornment>
);

// interface Pattern extends TextFieldProps {
//   pattern?: RegExp;
// }
const LinkPreview: FC<ILinkPreviewProps> = (props) => {
  const { formikProps = {} as FormikProps<unknown>, fieldConfig = {} as FormConfig, fieldProps = {} } = props;
  const { valueKey = '' } = fieldConfig;
  const { fetchLinkData, textFieldProps = {}, additionalHeaderProps = {} } = fieldProps;
  const { title, helperText, subtitle } = additionalHeaderProps;
  const [input, setInput] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>(get(formikProps, `values[${fieldConfig?.valueKey ?? ''}]`) || '');
  const [isFetchingPreview, setIsFetchingPreview] = useState<boolean>(false);
  const error = get(formikProps, `errors.${valueKey}`);

  const fetchLinkMetadata = useCallback(
    (() => {
      let timeout: NodeJS.Timeout;
      return async (term: string) => {
        if (!fetchLinkData || !term) return;
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(async () => {
          setIsFetchingPreview(true);
          try {
            await fetchLinkData(term, fieldConfig?.valueKey, formikProps).catch(AxiosUtils.throwError);
            setIsFetchingPreview(false);
            formikProps.setFieldValue(fieldConfig?.valueKey ?? '', term);
          } catch (err) {
            setIsFetchingPreview(false);
          }
        }, 300);
      };
    })(),
    [get(formikProps.values, valueKey.split('.').slice(0, -1).join('.') ?? '')]
    // Instead of watching for changes in this component, we're looking for changes in the parent too. Since the component is most likely to be used to set values on its parent
  );

  useEffect(() => {
    fetchLinkMetadata(input);
  }, [input]);

  const handleChange = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;
    formikProps.setFieldValue(valueKey, value);
    setInputValue(value);
    const isValid = await isUrlValidate.validate(value);
    // if (!isValidURL(value, pattern)) {
    if (!isValid) {
      formikProps.setFieldError(valueKey, 'Please enter a valid url');
      return;
    }
    formikProps.setFieldError(valueKey, undefined);
    setInput(value);
  };
  return (
    <>
      {title && <PgTypo b4>{title}</PgTypo>}
      {(subtitle || helperText) && (
        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={1}>
          {subtitle && <PgTypo b6>{subtitle}</PgTypo>}
          {helperText && (
            <PgTypo color="secondary" b6>
              {helperText}
            </PgTypo>
          )}
        </Box>
      )}
      <UrlInput
        value={inputValue}
        fullWidth
        variant="outlined"
        onChange={handleChange}
        {...textFieldProps}
        InputProps={{
          ...textFieldProps?.InputProps,
          ...(isFetchingPreview ? { endAdornment: loadingIndicator } : {}),
        }}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
};

export default LinkPreview;
