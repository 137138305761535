import React, { FC, useCallback, useState } from 'react';
import { IFieldProps, FormConfig, getFieldError, MLFormContent } from 'react-forms';
import {
  Box,
  IconButton,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import SVGCIcon from 'Components/SVGComponents/SVGCIcon';
import { createStyles, makeStyles, Theme, alpha } from '@material-ui/core/styles';
import { THEME_PALETTE } from 'Theme/themeConstants';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import Typo from 'Components/Typo';
import AxiosUtils from 'Resources/AxiosUtils';
import { NewsLetterList } from 'Models/NewsLetter';
import { FormikProps } from 'formik';
import PgIcon from 'Components/PgIcon';
import clsx from 'clsx';
import { NewsLetterProviderType } from 'Models/Community/@types';

export interface IProviderSelectionProps extends IFieldProps {
  fieldProps?: {
    formControlProps?: FormControlProps;
    formHelperTextProps?: FormHelperTextProps;
    helperText?: string;
    formProviderKey?: string;
    formListIdKey?: string;
    formListNameKey?: string;
    fetchSubscriptionList?: (providerType: NewsLetterProviderType, apiKey?: string) => Promise<any>;
  };
}

const loadingIndicator = (
  <InputAdornment position="end">
    <CircularProgress color="secondary" />
  </InputAdornment>
);

interface NewsLetterServiceProps {
  id: NewsLetterProviderType;
  icon: JSX.Element;
  selectedIcon?: JSX.Element;
  apiKeyValidation: RegExp;
  helperText?: JSX.Element;
}

const getListSelectConfig = (valueKey: string, nameValueKey: string, list: NewsLetterList[], formikProps?: FormikProps<any>): FormConfig => ({
  type: 'select',
  valueKey,
  fieldProps: {
    label: 'Newsletter list',
    emptyItem: 'Select',
    options: list.map((item) => ({ name: item.name, value: item.id })),
    onChange: (e: any) => {
      formikProps?.setFieldValue(valueKey, e.target.value);
      formikProps?.setFieldValue(nameValueKey, find(list, { id: e.target.value })?.name);
    },
  },
});

const NewsLetterServices: Array<NewsLetterServiceProps> = [
  {
    id: 'klaviyo',
    icon: <SVGCIcon icon="icon-klaviyo" />,
    selectedIcon: <SVGCIcon icon="icon-klaviyo" iconProps={{ color: THEME_PALETTE.common.white }} />,
    apiKeyValidation: new RegExp(/^pk_[\w-.]{33,42}$/),
    helperText: (
      <span>
        How to create a new API key from Klaviyo.{' '}
        <a href="https://google.com" target="_blank" rel="noreferrer">
          <PgIcon icon="icon-external" color="secondary" size="small" />
        </a>
      </span>
    ),
  },
  {
    id: 'sendgrid',
    icon: <Typo weight="bold">S</Typo>,
    selectedIcon: (
      <Typo weight="bold" contrast>
        S
      </Typo>
    ),
    apiKeyValidation: new RegExp(/^SG\.[\w -.]{64,73}$/),
    helperText: (
      <span>
        How to create a new API key from Twilio SendGrid.{' '}
        <a href="https://google.com" target="_blank" rel="noreferrer">
          <PgIcon icon="icon-external" color="secondary" size="small" />
        </a>
      </span>
    ),
  },
];

const prefixFormElementsKey = (formConfig: Array<FormConfig | FormConfig[]>, valueKey: string): Array<FormConfig | FormConfig[]> => {
  // TODO Fix this logick=
  const items = formConfig.map((item) => {
    if (isArray(item)) return item.map((t) => ({ ...t, valueKey: `${valueKey}.${t.valueKey}` }));
    return { ...item, valueKey: `${valueKey}.${item.valueKey}` };
  });
  return items;
};
const ProviderSelection: FC<IProviderSelectionProps> = (props) => {
  const { fieldConfig, formikProps, fieldProps = {} } = props;
  const {
    formControlProps,
    formHelperTextProps,
    helperText,
    formProviderKey = 'newsletterProvider',
    formListIdKey = 'newsletterListId',
    formListNameKey = 'newsletterListName',
    fetchSubscriptionList,
  } = fieldProps;
  const valueKey = fieldConfig?.valueKey || '';
  const value = formikProps?.values[valueKey] || {};
  const providerId = value[formProviderKey];
  const [provider, setProvider] = useState<NewsLetterServiceProps | undefined>(find(NewsLetterServices, { id: providerId }));
  const [inputValue, setInputValue] = useState<string>('');
  const [apiKeyError, setApiKeyError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newsLetterList, setNewsLetterList] = useState<NewsLetterList[]>([]);
  const selectedNewsLetterListName = value[formListNameKey];
  const classes = useStyles();

  const fetchList = useCallback(
    (() => {
      let timeout: NodeJS.Timeout;
      return async (providerType: NewsLetterProviderType, apiKey?: string) => {
        if (!fetchSubscriptionList) return;
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(async () => {
          setIsLoading(true);
          try {
            const res = await fetchSubscriptionList(providerType, apiKey).catch(AxiosUtils.throwError);
            setNewsLetterList(res ?? []);
            setIsLoading(false);
            // formikProps?.setFieldValue(fieldConfig?.valueKey ?? '', term);
          } catch (err) {
            setIsLoading(false);
          }
        }, 300);
      };
    })(),
    []
  );

  const handleSelection = (item: NewsLetterServiceProps) => () => {
    setProvider(item);
    setInputValue('');
    setNewsLetterList([]);
    formikProps?.setFieldValue(`${valueKey}`, { [formProviderKey]: item.id });
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);
    if (!provider?.apiKeyValidation.test(value)) {
      setApiKeyError(true);
      return;
    }
    setApiKeyError(false);
    fetchList(provider.id, value);
  };

  const handleEditList = () => {
    if (!provider) return;
    fetchList(provider.id);
  };

  const fieldError = formikProps ? getFieldError(`${valueKey}.provider`, formikProps) : '';

  return (
    <FormControl fullWidth error={!!fieldError} {...formControlProps}>
      <Box>
        {NewsLetterServices.map((item) => (
          <IconButton
            className={clsx(classes.button, provider?.id === item.id ? classes.buttonSelected : '')}
            key={item.id}
            onClick={handleSelection(item)}
          >
            {provider?.id === item.id ? item.selectedIcon : item.icon}
          </IconButton>
        ))}
      </Box>

      {/* <Box className={classes.formContainer}>
        <MLFormContent
          schema={newsLetterProviderListConfig}
          formikProps={formikProps}
          formId="newsletter-list-component"
        />
      </Box> */}

      {provider && (
        <Box className={classes.formContainer}>
          <TextField
            variant="outlined"
            fullWidth
            label="Api key"
            onChange={handleChange}
            value={inputValue}
            error={apiKeyError}
            helperText={apiKeyError ? 'Api Key is not valid' : provider.helperText ?? ''}
            InputProps={{
              endAdornment: isLoading ? loadingIndicator : undefined,
            }}
            FormHelperTextProps={{
              className: classes.apiKeyHelperText,
            }}
          />
        </Box>
      )}
      {provider && !!newsLetterList.length && (
        <Box className={classes.formContainer}>
          <MLFormContent
            schema={[getListSelectConfig(`${valueKey}.${formListIdKey}`, `${valueKey}.${formListNameKey}`, newsLetterList, formikProps)]}
            formikProps={formikProps}
            formId="newsletter-list-component"
          />
        </Box>
      )}
      {selectedNewsLetterListName && !newsLetterList.length && (
        <Box className={classes.formContainer}>
          <Box display="flex" justifyContent="space-between" bgcolor={THEME_PALETTE.grey.A100} py={1} px={2}>
            <Typo variant="h5">{selectedNewsLetterListName}</Typo>
            <IconButton onClick={handleEditList}>
              <PgIcon icon="icon-edit" />
            </IconButton>
          </Box>
        </Box>
      )}
      <FormHelperText {...formHelperTextProps} error={!!fieldError}>
        {fieldError || helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default ProviderSelection;

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    button: {
      borderRadius: 0,
      height: 60,
      width: 60,
      border: `1px solid ${alpha(theme.palette.secondary.main, 0.5)}`,
      marginRight: 15,
    },
    buttonSelected: {
      backgroundColor: theme.palette.secondary.main,
    },
    formContainer: {
      marginTop: 40,
    },
    apiKeyHelperText: {
      '& > span': {
        position: 'relative',
        top: -3,
        '& > a': {
          position: 'relative',
          top: 4,
        },
      },
    },
  })
);
