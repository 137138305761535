import React, { FC } from 'react';
import { Box, TextFieldProps } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { IFieldProps, MUITextField } from 'react-forms';
import PgTypo from 'Components/PgTypo';

export interface FormTextInputProps extends IFieldProps {
  fieldProps?: TextFieldProps & {
    subtitle?: string;
    containerClassName?: string;
  };
}

const FormTextInput: FC<FormTextInputProps> = (props) => {
  const classes = useStyles();
  const { fieldProps = {}, ...restProps } = props;
  const { label, subtitle, containerClassName, ...restFieldProps } = fieldProps;
  return (
    <Box my={1} className={containerClassName}>
      <PgTypo b4>{label}</PgTypo>
      {subtitle ? (
        <PgTypo b6 component="p" className={classes.subtitle}>
          {subtitle}
        </PgTypo>
      ) : null}
      <MUITextField
        fieldProps={{
          ...restFieldProps,
          className: classes.textField,
        }}
        {...restProps}
      />
    </Box>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    textField: {
      marginTop: '8px',
      '& .MuiFormLabel-root': {
        color: theme.palette.common.black,
      },
    },
    label: {
      transform: 'translate(0, 12px) scale(0.75) !important',
    },
    subtitle: {
      marginTop: 8,
    },
  });
});

export default FormTextInput;
