/* eslint-disable react/no-unused-prop-types */
/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import { Box, ClickAwayListener, Slide } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import EmailSentDialog from 'Features/Auth/SignUp/EmailSentDialog';
import Login from '../Login';
import SignUp from '../SignUp';
import ChangePassword from '../ChangePassword';
import PhoneVerification from '../PhoneVerification';
import { Step, TAuthDialogContentProps } from '../@types';
import ResetPassword from '../ResetPassword';
import EmailVerifiedDialog from '../SignUp/EmailVerifiedDialog';
import PhoneVerifiedDialog from '../PhoneVerification/PhoneVerifiedDialog';
import DontAskAgain from '../PhoneVerification/DontAskAgain';
import WalletSelect from '../web3/WalletSelect';
import WalletConnecting from '../web3/WalletConnecting';
import CheckEmailExist from '../web3/CheckEmailExist';
import WalletError from '../web3/WalletError';
import EditEmail from '../EditEmail';
import useWalletConnect from '../useWalletConnect';
import VerifyEmailFirst from '../SignUp/VerifyEmailFirst';
import clsx from 'clsx';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { APP_HEADER_HEIGHT } from 'Features/AppHeader';
import PG_LOGO from 'Assets/Logo/PLAYGROUND_WORDMARK-S_WHITE_2.svg';
import HOME_BG_LG from 'Assets/Backgrounds/Home/home_bg_lg.gif';
import HOME_BG_SM from 'Assets/Backgrounds/Home/home_bg_sm.gif';
import { useSnackbar } from 'notistack';
import UnlockGated from '../web3/UnlockGated';
import Dark from 'Features/DarkTheme';

const AuthDialogContent: React.FC<TAuthDialogContentProps> = (props) => {
    const { step, onDone, changeStep, setMeta, meta = {} } = props;
    const {
        walletStep,
        walletMeta,
        handleUserLogIn,
        handleUserSignUp,
        handleEmailExist,
        handleErrorBackClick,
        handleWalletSelect,
        setWalletMeta,
        setWalletStep,
    } = useWalletConnect(onDone);
    const classes = useStyles({});
    useEffect(() => {
        if (!walletStep) return;
        if ((['wallet-select'] as Step[]).includes(walletStep)) {
            // if new wallet step is one of the steps in the array, show the close dialog button.
            setMeta?.((meta: any) => ({ ...meta, hideClose: false }));
        }
        changeStep(walletStep);
        setMeta?.((oldMeta = {}) => ({ ...oldMeta, ...walletMeta }));
    }, [walletStep, walletMeta]);

    const { isDeviceSm } = useMediaQuery();

    console.log({ step })

    return (
        <Dark height='100%'>
            <ClickAwayListener
                onClickAway={(event) => {
                    // Do not close dialog on clicking toasts.
                    const target = event.target as HTMLElement;
                    const targetParent = target.parentElement,
                        targetGrandParent = target.parentElement?.parentElement,
                        target3rdAncestor = targetGrandParent?.parentElement,
                        target4thAncestor = target3rdAncestor?.parentElement;
                    if (
                        target.classList.contains('toast-snackbar') ||
                        targetParent?.classList.contains('toast-snackbar') ||
                        targetGrandParent?.classList.contains('toast-snackbar') ||
                        target3rdAncestor?.classList.contains('toast-snackbar') ||
                        target4thAncestor?.classList.contains('toast-snackbar')
                        // NOTE: It is possible in some situations for toast snackbar to be an ancestor higher up in the tree. If such cases arise, it should be handled here.
                    ) {
                        return;
                    }
                    onDone?.();
                }}
            >
                <div className={clsx(classes.root, { [classes.fullWidthDialog]: meta?.variant !== 'small' || !isDeviceSm })}>
                    {meta?.variant !== 'small' && !isDeviceSm ? (
                        <Box className={classes.imgContainer}>
                            <img src={isDeviceSm ? HOME_BG_SM : HOME_BG_LG} className={classes.img} />
                            <img src={PG_LOGO} alt="Playground Logo" className={classes.logoImg} />
                        </Box>
                    ) : null}
                    <Box className={clsx({
                        [classes.fullWidthDialogMainContentArea]: meta?.variant !== 'small' || !isDeviceSm,
                        [classes.contentAreaSpacing]: (step !== "unlock-gated" && step !== "wallet-select") && (meta?.variant !== 'small' || !isDeviceSm)
                    })}>
                        <Slide in={step === 'login'} direction="left" mountOnEnter unmountOnExit>
                            <div className={clsx(classes.slideContainer, { [classes.slideContainerFullScreenVariant]: meta?.variant !== 'small' })}>
                                <Login {...props} handleUserLogIn={handleUserLogIn} walletMeta={{ ...meta, ...walletMeta }} setWalletMeta={setWalletMeta} />
                            </div>
                        </Slide>
                        <Slide in={step === 'signup'} direction="left" mountOnEnter unmountOnExit>
                            <div className={clsx(classes.slideContainer, { [classes.slideContainerFullScreenVariant]: meta?.variant !== 'small' })}>
                                <SignUp {...props} handleUserSignUp={handleUserSignUp} walletMeta={{ ...meta, ...walletMeta }} />
                            </div>
                        </Slide>
                        <Slide in={step === 'reset-password'} direction="left" mountOnEnter unmountOnExit>
                            <div className={clsx(classes.slideContainer, { [classes.slideContainerFullScreenVariant]: meta?.variant !== 'small' })}>
                                <ResetPassword {...props} />
                            </div>
                        </Slide>
                        <Slide in={step === 'verifyEmailPrompt'} direction="left" mountOnEnter unmountOnExit>
                            <div className={clsx(classes.slideContainer, { [classes.slideContainerFullScreenVariant]: meta?.variant !== 'small' })}>
                                <EmailSentDialog />
                            </div>
                        </Slide>

                        <Slide in={step === 'change-password'} direction="left" mountOnEnter unmountOnExit>
                            <div>
                                <ChangePassword {...props} />
                            </div>
                        </Slide>

                        <Slide in={step === 'verifyPhone'} direction="left" mountOnEnter unmountOnExit>
                            <div className={clsx(classes.slideContainer, { [classes.slideContainerFullScreenVariant]: meta?.variant !== 'small' })}>
                                <PhoneVerification {...props} />
                            </div>
                        </Slide>

                        <Slide in={step === 'email-verified'} direction="left" mountOnEnter unmountOnExit>
                            <div className={clsx(classes.slideContainer, { [classes.slideContainerFullScreenVariant]: meta?.variant !== 'small' })}>
                                <EmailVerifiedDialog />
                            </div>
                        </Slide>

                        <Slide in={step === 'phone-verified'} direction="left" mountOnEnter unmountOnExit>
                            <div className={clsx(classes.slideContainer, { [classes.slideContainerFullScreenVariant]: meta?.variant !== 'small' })}>
                                <PhoneVerifiedDialog />
                            </div>
                        </Slide>

                        <Slide in={step === 'dont-ask-phone'} direction="left" mountOnEnter unmountOnExit>
                            <div className={clsx(classes.slideContainer, { [classes.slideContainerFullScreenVariant]: meta?.variant !== 'small' })}>
                                <DontAskAgain {...props} />
                            </div>
                        </Slide>

                        <Slide in={step === 'edit-email'} direction="left" mountOnEnter unmountOnExit>
                            <div className={clsx(classes.slideContainer, { [classes.slideContainerFullScreenVariant]: meta?.variant !== 'small' })}>
                                <EditEmail {...props} />
                            </div>
                        </Slide>

                        <Slide in={step === 'verify-email-first'} direction="left" mountOnEnter unmountOnExit>
                            <div className={clsx(classes.slideContainer, { [classes.slideContainerFullScreenVariant]: meta?.variant !== 'small' })}>
                                <VerifyEmailFirst {...props} />
                            </div>
                        </Slide>

                        <Slide in={step === 'wallet-select'} direction="left" mountOnEnter unmountOnExit>
                            <div className={clsx(classes.slideContainer, { [classes.slideContainerFullScreenVariant]: meta?.variant !== 'small' })}>
                                <WalletSelect
                                    {...props}
                                    walletMeta={{ ...meta, ...walletMeta }}
                                    setWalletMeta={setWalletMeta}
                                    handleWalletSelect={handleWalletSelect}
                                    setWalletStep={setWalletStep}
                                />
                            </div>
                        </Slide>

                        <Slide in={step === 'wallet-connecting'} direction="left" mountOnEnter unmountOnExit>
                            <div className={clsx(classes.slideContainer, { [classes.slideContainerFullScreenVariant]: meta?.variant !== 'small' })}>
                                <WalletConnecting {...props} setWalletMeta={setWalletMeta} setWalletStep={setWalletStep} />
                            </div>
                        </Slide>

                        <Slide in={step === 'web3-email-check'} direction="left" mountOnEnter unmountOnExit>
                            <div className={clsx(classes.slideContainer, { [classes.slideContainerFullScreenVariant]: meta?.variant !== 'small' })}>
                                <CheckEmailExist {...props} walletMeta={walletMeta} handleEmailExist={handleEmailExist} />
                            </div>
                        </Slide>

                        <Slide in={step === 'wallet-error'} direction="left" mountOnEnter unmountOnExit>
                            <div className={clsx(classes.slideContainer, { [classes.slideContainerFullScreenVariant]: meta?.variant !== 'small' })}>
                                <WalletError {...props} walletMeta={walletMeta} handleErrorBackClick={handleErrorBackClick} />
                            </div>
                        </Slide>

                        <Slide in={step === 'unlock-gated'} direction="left" mountOnEnter unmountOnExit>
                            <div className={clsx(classes.slideContainer, { [classes.slideContainerFullScreenVariant]: meta?.variant !== 'small' })}>
                                <UnlockGated {...props} walletMeta={walletMeta} setWalletMeta={setWalletMeta} setWalletStep={setWalletStep} />
                            </div>
                        </Slide>
                    </Box>
                </div>
            </ClickAwayListener>
        </Dark>
    );
};

export default AuthDialogContent;

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            overflowX: 'hidden',
            borderRadius: 0,
        },
        slideContainer: {
            textAlign: 'center',

            '& >button': {
                fontWeight: theme.typography.fontWeightBold,
            },
        },
        fullWidthDialog: {
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
        imgContainer: {
            width: '40%',
            display: 'inline-block',
            height: `calc(100vh - ${APP_HEADER_HEIGHT}px)`,
            position: 'relative',
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                display: 'block',
                height: 160,
            },
        },
        img: {
            height: '100%',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: 'auto',
            },
        },
        slideContainerFullScreenVariant: {
            maxWidth: 660,
            margin: '0 auto',
        },
        contentAreaSpacing: {
            width: '59%',
            display: 'inline-block',
            padding: theme.spacing(12.5, 12.5, 5, 11),
        },
        fullWidthDialogMainContentArea: {
            position: 'relative',
            margin: '0 auto',
            maxHeight: `calc(100vh - ${APP_HEADER_HEIGHT}px)`,
            overflow: 'auto',
            [theme.breakpoints.down('sm')]: {
                // height: `calc(100vh - ${APP_HEADER_HEIGHT + 160}px)`,
                position: 'static',
                width: '100%',
                display: 'block',
                padding: theme.spacing(4, 2),
            },
        },
        logoImg: {
            width: '50%',
            display: 'block',
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            left: '50%',
        },
    }),
);
