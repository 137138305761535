import { Box, makeStyles, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Spacer from 'Components/Spacer';
import React, { FC } from 'react';
import { useMediaQuery } from 'Hooks/useMediaQuery';

const EventPageSkeleton: FC = () => {
  const classes = useStyles();
  const { isDeviceSm } = useMediaQuery();

  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Skeleton variant="rect" width="10%" height={20} />
        <Spacer height={15} />
        <Skeleton variant="rect" height={55} width="95%" />
        <Spacer height={10} />
        <Skeleton variant="rect" width="70%" height={25} />
        <Spacer height={40} />
      </Box>
      <Box className={classes.eventContainer}>
        <Box className={classes.imageContainer}>
          <Skeleton variant="rect" width="100%" height={320} />
        </Box>
        <Box className={classes.eventDescription}>
          <Box className={classes.eventRows}>
            {[1, 2, 3, 4, 5, 6].map((index) => (
              <Box className={classes.eventRow} key={`skeletion-row-${index}`}>
                <Skeleton variant="rect" width="100%" height={40} />
                {isDeviceSm ? null : <Skeleton variant="rect" width="100%" height={40} />}
              </Box>
            ))}
          </Box>

          {/* {[1, 2, 3, 4, 5, 6].map((index) => (
            <Box>
              
            </Box>
          ))} */}
        </Box>
      </Box>
      <Box className={classes.chatContainer}>
        <Spacer height={60} />
        <Skeleton variant="rect" width="90%" height={40} />
        <Spacer height={20} />
        <Skeleton variant="rect" width="60%" height={40} />
        <Spacer height={40} />
        <Box display="flex" flexDirection="row" alignItems="center">
          <Skeleton variant="circle" width={60} height={60} />
          <Box gridRowGap={2} width="90%" paddingLeft={2}>
            <Skeleton variant="rect" width="60%" height={40} />
            <Spacer height={8} />
            <Skeleton variant="rect" width="100%" height={40} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    aspectRatio: '1/1',
    margin: '0 auto',
    padding: theme.spacing(2),
  },
  titleContainer: {
    margin: '0 auto',
  },
  eventContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  imageContainer: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  eventDescription: {
    width: '50%',
    display: 'flex',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  eventRows: {
    display: 'flex',
    gap: theme.spacing(2),
    width: '100%',
    flexDirection: 'column',
  },
  eventRow: {
    display: 'flex',
    gap: theme.spacing(2),
    width: '100%',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  chatContainer: {
    margin: '0 auto',
  },
}));

export default EventPageSkeleton;
