/* eslint-disable no-unused-expressions */
import PgDialog from 'Components/PgDialog';
import ResetPasswordForm, { ResetPasswordFormData } from 'Forms/ResetPasswordForm';
import React, { FC, useState } from 'react';
import { TAuthDialogContentProps } from '../@types';
import useAuth from '../useAuth';

const ChangePassword: FC<TAuthDialogContentProps> = ({ onDone, meta = {} }) => {
  const { onChange, user } = meta;
  const { changePassword } = useAuth();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const handleSubmit = async (resetFormData: ResetPasswordFormData) => {
    if (!user?.id) return;
    setIsUpdating(true);
    await changePassword(user?.id, resetFormData.password);
    onChange?.(resetFormData.password);
    onDone?.();
    setIsUpdating(false);
  };

  return (
    <PgDialog
      heading="Change Password"
      body={<ResetPasswordForm isSubmitting={isUpdating} onSubmit={handleSubmit} />}
      positive={false}
      negative={false}
      isForm
    />
  );
};

export default ChangePassword;
