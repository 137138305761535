import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import Typo from '../../Components/Typo';
import clsx from 'clsx';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import PgButton from 'Components/PgButton';

export interface CancelTransactionProps {
  cancelText?: string;
  agreeText?: string;
  onCancel: () => void;
  onAgree: () => void;
}

const CancelTransaction: FC<CancelTransactionProps> = (props) => {
  const { /* cancelText = 'STAY', agreeText = 'LEAVE', */ onCancel, onAgree } = props;
  const { isDeviceSm } = useMediaQuery();
  const classes = useStyles({ isDeviceSm });

  return (
    <Box className={classes.root}>
      <Typo gutterBottom variant="h6" weight="bold">
        Leave Checkout?
      </Typo>
      <Typo align="center" variant="body2">
        The event you’ve selected may not be
        <br />
        available later.
      </Typo>
      <Box mt={4} display="flex" alignItems="center" className={isDeviceSm ? classes.buttonBox : ''}>
        <PgButton onClick={onCancel} className={clsx(classes.outlinedButton, classes.button)} secondary>
          STAY
        </PgButton>
        <PgButton onClick={onAgree} className={classes.button} primary>
          LEAVE
        </PgButton>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
  // const {isDeviceSm} = props;
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: (props) => (props.isDeviceSm ? '100vh' : '100%'),
    backgroundColor: theme.palette.common.white,
  },
  outlinedButton: {
    // border: `px solid ${theme.palette.common.black}`,
  },
  button: {
    width: 170,
    height: 44,
    margin: theme.spacing(0, 1),
  },
  responsiveRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    backgroundColor: theme.palette.common.black,
    // overflowX:'hidden',
  },
  buttonBox: {
    width: '90%',
  },
}));

export default CancelTransaction;
