import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';
import { DragDropContext, DragDropContextProps, Draggable, DraggableProvided, Droppable, DroppableProps } from 'react-beautiful-dnd';

export interface DraggableListProps<T = any> {
  handleDragEnd: DragDropContextProps['onDragEnd'];
  id: string;
  items: T[];
  verticalSpacing?: number;
  containerClass?: string;
  direction?: DroppableProps['direction'];
  renderListItem: (item: T, index: number, draggableProvided: DraggableProvided) => JSX.Element;
}

function DraggableList<T>(props: DraggableListProps<T>): JSX.Element {
  const { handleDragEnd, id, items, renderListItem, direction = 'vertical', verticalSpacing = 10, containerClass } = props;
  const classes = useStyles({});
  const theme = useTheme();

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={`${id}-drag`} direction={direction}>
        {(provided, snapshot) => (
          <div
            className={containerClass}
            ref={provided.innerRef}
            style={{
              backgroundColor: snapshot.isDraggingOver ? 'rgba(0,0,0,0.1)' : 'transparent',
            }}
            {...provided.droppableProps}
          >
            {items.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Draggable key={index} draggableId={index + id} index={index}>
                {(dragProvided, dragSnapshot) => {
                  const { style, ...draggableProps } = dragProvided.draggableProps;
                  return (
                    <div
                      style={{
                        ...style,
                        border: dragSnapshot.isDragging ? `1px solid ${theme.palette.secondary.main}` : '1px solid transparent',
                        marginBottom: index === items.length - 1 ? 0 : verticalSpacing,
                      }}
                      ref={dragProvided.innerRef}
                      {...draggableProps}
                      key={`${index + id}dragItem`}
                    >
                      {renderListItem(item, index, dragProvided)}
                    </div>
                  );
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

const useStyles = makeStyles<Theme, any>(() => ({}));

export default DraggableList;
