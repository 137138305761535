import { max } from 'lodash';
import { TContentType, TDrop } from 'Models/Drop/@types';
import EventModel, { TEvent } from 'Models/Event';
import { EventTier, TicketTier } from 'Models/Event/ticketing/@types';
import ResourceModel from 'Models/Resource';
import { TResource } from 'Models/Resource/@types';
import { Tag } from 'Models/Tags/@types';
import moment from 'moment';

export type DropActionType =
  | 'PURCHASE_TICKET'
  | 'GOING'
  | 'RSVP'
  | 'RSVPD'
  | 'REMIND_ME'
  | 'REMINDER_SET'
  | 'PAST_EVENT'
  | 'CANCELLED_EVENT'
  | 'LINK'
  | 'SOLD_OUT'
  | 'JOIN_NOW'
  | 'REGISTER';

export enum DropButtonText {
  GET_TICKETS = 'Get Tickets',
  GOING = 'Going',
  RSVP = 'RSVP',
  RSVPD = `RSVP'D`,
  REMIND_ME = 'Remind Me',
  REMINDER_SET = 'Reminder Set',
  PAST_EVENT = 'Past Event',
  CANCELLED = 'Canceled',
  SOLD_OUT = 'Sold Out',
  JOIN_NOW = 'Join Now',
  REGISTER = 'Register',
}

const MEDIA_BUTTON_TEXT: Record<string, string> = {
  video: 'Watch',
  podcast: 'Listen',
  article: 'Read',
  collect: 'Buy',
  album: 'Listen',
  soundtrack: 'Listen',
};

const isPastRelease = (drop: TDrop, dropType: TContentType): boolean => {
  if (dropType === 'Event') {
    const maxDate = max((drop as TEvent).eventDates?.map((date) => date.endDate || date.startDate)) as string;
    return new Date(maxDate) < new Date();
  }
  return ResourceModel.isPastRelease((drop as TResource).releaseDate);
};

const isResourceTypeMatched = (resourceType: TContentType, matchType: TContentType): boolean => {
  return resourceType?.toLowerCase() === matchType.toString().toLowerCase();
};

const getEventPrimaryTag = (moment: TDrop): Tag | undefined => {
  const tagId = moment.tagIds?.[0];
  return tagId ? moment.tags?.find((tag) => tag.id === tagId) : undefined;
};

const getResourcePrimaryTag = (moment: TDrop): Tag | undefined => {
  return moment.tags?.[0];
};

const isEventMoment = (type: TContentType): boolean => {
  return isResourceTypeMatched(type, 'Event');
};

const isMediaType = (resourceType: TContentType): boolean => {
  return (
    isResourceTypeMatched(resourceType, 'article') ||
    isResourceTypeMatched(resourceType, 'collect') ||
    isResourceTypeMatched(resourceType, 'podcast') ||
    isResourceTypeMatched(resourceType, 'video')
  );
};

const getEventActionType = (isPast = false, isReminded = false, event: TEvent): DropActionType => {
  const isCancelled = event.canceled;
  const ticketTier = event._tiers?.find((t) => t.type === event.tierType);
  const isSoldOut = EventModel.isEventSoldOut(event.enabledTier?._ticketTiers ?? ticketTier?._ticketTiers ?? []);
  const isMetaverseEvent = event.subType === 'metaverse' || event.eventType === 'metaverse';
  const tierType = event.tierType ?? 'externalSite';
  if (isPast) return 'PAST_EVENT';
  if (isCancelled) return 'CANCELLED_EVENT';
  if (event.tierType === 'free') {
    if (isSoldOut && !ticketTier?._ticketTiers?.[0]?.isInfinite) return 'SOLD_OUT';
  } else if (isSoldOut) return 'SOLD_OUT';
  if (isMetaverseEvent && tierType === 'free') return isReminded ? 'GOING' : 'JOIN_NOW';
  if (tierType === 'free' && !isReminded) return 'REGISTER';
  if (EventModel.isTicketEvent(tierType)) return isReminded ? 'GOING' : 'PURCHASE_TICKET';
  return isReminded ? 'RSVPD' : 'RSVP';
};

const getDropActionType = (resourceType: TContentType, drop: TDrop): DropActionType => {
  const isPast = isPastRelease(drop, resourceType);
  const isReminded = drop.isReminded;
  if (isEventMoment(resourceType)) return getEventActionType(isPast, isReminded, drop as TEvent);
  if (isPast && resourceType) return 'LINK';
  return isReminded ? 'REMINDER_SET' : 'REMIND_ME';
};

const getButtonText = (resourceType: TContentType, actionType: DropActionType): string => {
  switch (actionType) {
    case 'PAST_EVENT':
      return DropButtonText.PAST_EVENT;
    case 'CANCELLED_EVENT':
      return DropButtonText.CANCELLED;
    case 'SOLD_OUT':
      return DropButtonText.SOLD_OUT;
    case 'GOING':
      return DropButtonText.GOING;
    case 'PURCHASE_TICKET':
      return DropButtonText.GET_TICKETS;
    case 'RSVPD':
      return DropButtonText.RSVPD;
    case 'RSVP':
      return DropButtonText.RSVP;
    case 'LINK':
      return MEDIA_BUTTON_TEXT[resourceType.toLowerCase()];
    case 'REMINDER_SET':
      return DropButtonText.REMINDER_SET;
    case 'REMIND_ME':
      return DropButtonText.REMIND_ME;
    case 'JOIN_NOW':
      return DropButtonText.JOIN_NOW;
    case 'REGISTER':
      return DropButtonText.REGISTER;
  }
};

const isSameDate = (eventDates: TEvent['eventDates']): boolean => {
  return moment(eventDates?.[0]?.startDate).date() === moment(eventDates?.[0]?.endDate).date();
};

const getPurchasedTicketCount = (ticketTier: TicketTier, purchasedTickets: TEvent['purchasedTickets'] = []): number => {
  const { id } = ticketTier ?? {};
  return purchasedTickets
    .filter(p => p.transaction?.bill.ticketTierInformation.id === id)
    .reduce((prev, curr) => prev + curr.quantity, 0);
}

export default {
  isPastRelease,
  getEventPrimaryTag,
  getResourcePrimaryTag,
  isResourceTypeMatched,
  isEventMoment,
  isMediaType,
  getButtonText,
  getDropActionType,
  isSameDate,
  getPurchasedTicketCount,
};
