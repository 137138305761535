import { Box, makeStyles, Theme } from '@material-ui/core';
import PgButton from 'Components/PgButton';
import PgDialog from 'Components/PgDialog';
import PgTypo from 'Components/PgTypo';
import { AppDialogContext } from 'Contexts/AppDialogContext';
import OtpForm from 'Forms/OtpForm';
import useAsyncTask from 'Hooks/useAsyncTask';
import { TPhone } from 'Models/User';
import React, { FC, useContext, useState } from 'react';
import { FormConfig, IFormActionProps, ReactForm } from 'react-forms';
import { APP_ROUTES } from 'Routes';
import useAppStyles from 'Theme/Styles';
import MESSAGES from 'Utils/Messages';
import * as Yup from 'yup';
import { TAuthDialogContentProps } from '../@types';
import useAuth from '../useAuth';

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const formValidation = Yup.object({
  phone: Yup.object().shape({
    countryCode: Yup.string().required(MESSAGES.PHONE_COUNTRY_CODE_REQUIRED),
    phone: Yup.string().matches(phoneRegExp, MESSAGES.PHONE_NUMBER_INVALID).required(MESSAGES.PHONE_NUMBER_REQUIRED),
  }),
});

const actionConfig = (classes: Record<string, string>): IFormActionProps => ({
  submitButtonText: 'Continue',
  submitButtonLayout: 'fullWidth',
  submitButtonProps: {
    size: 'large',
  },
  containerClassNames: classes.buttonContainer,
});
const inputConfig = (classes: Record<string, string>): Array<Array<FormConfig> | FormConfig> => [
  {
    type: 'phone-input',
    valueKey: 'phone',
    fieldProps: {
      countryCodeProps: {
        variant: 'outlined',
        label: 'Country Code',
        placeholder: 'Country Code',
        classes: {
          select: classes.countryCodeSelect,
        },
      },
      phoneProps: {
        label: 'Phone Number',
        placeholder: 'Phone Number',
        InputProps: {
          style: { minWidth: 170 },
        },
      },
    },
  },
];

interface IOTPFormDialogProps {
  phone: TPhone;
  classes: Record<string, string>;
  appClasses: Record<string, string>;
  editPhone: () => void;
  requestToken: () => Promise<void>;
  verifyOTP: (token: string) => Promise<void>;
}

const OTPFormDialog: FC<IOTPFormDialogProps> = ({ phone, classes, appClasses, editPhone, requestToken, verifyOTP }) => {
  return (
    <PgDialog
      heading="Verification Code"
      subheading={`Enter the code we sent via SMS to ${phone.countryCode}  ${phone.phone}.`}
      body={<OtpForm onSubmit={(tokenData: { token: string }) => verifyOTP(tokenData.token)} isSubmitting={false} />}
      actionButtons={
        <>
          <Box mt={1}>
            <PgTypo b2>
              Didn't get the code?
              <PgButton quaternary onClick={requestToken} underlined className={classes.textBtn}>
                Resend
              </PgButton>
              or
              <PgButton quaternary underlined href={APP_ROUTES.CONTACT.path} isExternalLink className={classes.textBtn}>
                Contact us
              </PgButton>
            </PgTypo>
          </Box>
          <PgButton quaternary underlined onClick={editPhone}>
            Change Phone
          </PgButton>
        </>
      }
      isForm
    />
  );
};

const PhoneVerification: FC<TAuthDialogContentProps> = ({ onDone, changeStep, meta }) => {
  const classes = useStyles();
  const appClasses = useAppStyles();
  const [phone, setPhone] = useState<TPhone>(meta?.phone ?? {});
  const [isVerifyingOTP, setIsVerifyingOTP] = useState<boolean>(false);
  const { requestPhoneOTP, verifyPhone } = useAuth();

  const handleResendToken = async () => {
    if (!phone) return;
    await handleRequestToken(phone);
  };
  const handleRequestToken = async (phone: TPhone) => {
    return requestPhoneOTP(phone);
  };
  const handleSubmit = useAsyncTask(async (phoneData: { phone: TPhone }) => {
    await handleRequestToken(phoneData.phone);
    setIsVerifyingOTP(true);
    setPhone(phoneData.phone);
  });

  const handleVerifyOTP = async (token: string) => {
    if (!phone) return;
    await verifyPhone(phone, token);
    changeStep('phone-verified');
    onDone?.();
  };

  if (phone && isVerifyingOTP) {
    return (
      <OTPFormDialog
        classes={classes}
        appClasses={appClasses}
        phone={phone}
        editPhone={() => setIsVerifyingOTP(false)}
        requestToken={handleResendToken}
        verifyOTP={handleVerifyOTP}
      />
    );
  }
  return (
    <PgDialog
      heading="Phone Verification"
      body={
        <>
          <PgTypo b4>Get a text reminder when you RSVP to drops so you don’t miss out.</PgTypo>
          <Box pt={4} pb={2.5}>
            <ReactForm
              initialValues={phone ? { phone } : {}}
              formId="phone-verification"
              config={inputConfig(classes)}
              actionConfig={actionConfig(classes)}
              onSubmit={handleSubmit.run}
              validationSchema={formValidation}
              formSettings={{
                verticalSpacing: 20,
              }}
              isInProgress={handleSubmit.status === 'PROCESSING'}
            />
            {!!meta?.hideDontAskOption ? null : (
              <Box mt={2}>
                <PgButton quaternary underlined onClick={() => changeStep('dont-ask-phone')}>
                  Don&apos;t ask me again
                </PgButton>
              </Box>
            )}
          </Box>
        </>
      }
      positive={false}
      negative={false}
    />
  );
};

export default PhoneVerification;

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  countryCodeSelect: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    '& button': {
      // backgroundColor: theme.palette.secondary.main,
    },
  },
  textBtn: {
    minWidth: 'fit-content',
    margin: '0px 2px',
  },
}));
