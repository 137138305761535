import React, { useCallback, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MobileMenuDialog from '.';
import { useHistory } from 'react-router';
import helpers from 'Utils/helpers';
import QueryString from 'qs';

function useMobileMenuDialog() {
  const classes = useStyles({});

  const history = useHistory();
  const { search } = history.location;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!search) return;
    const params = helpers.locationParams<{ openMobileMenu: string }>(search);
    const { openMobileMenu } = params;
    if (!openMobileMenu) return;
    const isMenuOpen = JSON.parse(openMobileMenu);
    setOpen(isMenuOpen);
    const updatedParams = QueryString.stringify({ ...params, openMobileMenu: undefined });
    history.replace({ search: updatedParams });
  }, [search]);

  const openDialog = useCallback(() => setOpen(true), []);

  const closeDialog = useCallback(() => setOpen(false), []);

  return {
    openDialog,
    closeDialog,
    DialogComponent: open ? <MobileMenuDialog onClose={closeDialog} open={open} /> : null,
  };
}

export default useMobileMenuDialog;

const useStyles = makeStyles<Theme, any>((theme) => createStyles({}));
