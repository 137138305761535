import { Box, Button, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import MembersSvg from 'Assets/icons/Members.svg';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import PgButton from 'Components/PgButton';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import ReadMoreLess from 'Components/ReadMoreLess';
import ActiveMemberDrawer from 'Features/Discussion/ActiveMemberDrawer';
import ActiveMemberMobileDrawer from 'Features/Discussion/ActiveMemberMobileDrawer';
import DiscussionLayout, { DiscussionLayoutProps } from 'Features/Discussion/DiscussionLayout';
import DiscussionAboutDialog from 'Features/Discussion/DiscussionAboutDialog';
import PinnedMessageDialog from 'Features/Discussion/PinnedMessageDialog';
import { TDiscussionConfig } from 'Features/Discussion/useDiscussion';
import useConfirmationDialog from 'Hooks/useConfirmationDialog';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import DiscussionModel from 'Models/Discussion';
import { TDiscussion } from 'Models/Discussion/@types';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { generatePath, Redirect, useLocation } from 'react-router-dom';
import { APP_ROUTES } from 'Routes';
import { useStoreActions, useStoreState } from 'Stores';
import { CommunityJoinButton } from 'Features/Profile/CommunityJoinButton';
import { FollowChangeHandler } from 'Features/Profile/@types';
import { TCommunity } from 'Models/Community/@types';
import { EGateType } from 'Models';
import { ChatContext } from 'Contexts/ChatContext';
import { ChatMembersContentProps } from 'Features/Discussion/ChatMembersContent';
import SVGCIcon from 'Components/SVGComponents/SVGCIcon';
import hash from 'Assets/icons/hash.svg';
import { BORDER_BLACK } from 'Theme/themeConstants';

export interface DiscussionProps { }

const Discussion: FC<DiscussionProps> = (props) => {
    const classes = useStyles();

    const { community, appUser } = useStoreState(({ AuthStore: { appUser }, CommunityStore: { community } }) => ({ community, appUser }));
    const { setCommunity } = useStoreActions(({ CommunityStore: { setCommunity } }) => ({
        setCommunity,
    }));
    const { search } = useLocation();
    const activeDiscussionId = useMemo(() => {
        const searchParams = new URLSearchParams(search);
        return searchParams.get('discussionId') || '';
    }, [search]);
    const { memberIds = [], title } = (community?.discussions || []).find((i) => i.id === activeDiscussionId) || {};
    const isJoined = memberIds.includes(appUser?.id || '');
    const [showInfoDialog, setShowInfoDialog] = useState(false);
    const toggleShowInfoDialog = () => setShowInfoDialog((e) => !e);
    const chatDisabledConfig: TDiscussionConfig['disableChatConfig'] = useMemo(() => {
        if (community?.visibility === 'PUBLIC' || community?.isFollowed) {
            return {
                isDisabled: false,
            };
        }
        return {
            isDisabled: true,
            disabledMessage:
                community?.visibility === 'INVITE_ONLY'
                    ? "You need an invite to access this community's conversations."
                    : `Unlock access to this community with a ${community?.nftGates?.[0]?.tokenName ?? 'non fungible'} token.`,
        };
    }, [appUser, community?.isFollowed, community?.visibility, community?.nftGates?.length]);

    const chatConfig = useMemo<TDiscussionConfig>(() => ({
        discussionIds: community?.discussionIds ? community?.discussionIds : [],
        activeDiscussionId,
        // discussionId: community?.discussionId || '',
        creatorId: community?.userId || '',
        subject: 'Community',
        subjectId: community?.id || '',
        disableChatConfig: chatDisabledConfig,
    }), [community?.discussionIds, activeDiscussionId, community?.id, chatDisabledConfig])

    const { initChat } = useContext(ChatContext)

    useEffect(() => {
        if (activeDiscussionId) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (chatConfig) initChat(chatConfig)
        }
    }, [activeDiscussionId]);

    const withConfirm = useConfirmationDialog();

    const joinDiscussion = () => {
        if (!community || !appUser) return;

        withConfirm(
            async () => {
                await DiscussionModel.leaveJoinDiscussion(activeDiscussionId, appUser.id, 'join');
                setCommunity({
                    ...community,
                    discussions: (community.discussions || []).map((i) =>
                        i.id === activeDiscussionId ? { ...i, memberIds: [...i.memberIds, appUser.id] } : i,
                    ),
                });
            },
            {
                message: 'Join conversation',
                body: 'You will be able to participate in this conversation and receive notifications for it.',
                agreeText: 'JOIN',
            },
            {
                successToastMessage: `You joined #${title}`,
            },
        );
    };

    const handleJoinComm: FollowChangeHandler<TCommunity> = (comm) => {
        setCommunity(comm);
    }

    const allowMessaging = useMemo(
        () => (!!community?.followId && isJoined) || appUser?.id === community?.userId,
        [community?.followId, isJoined, appUser?.id, community?.userId]
    )

    const [mobileDrawerState, setMobileDrawerState] = useState<'chat' | 'members'>()

    if (community?.id && !appUser?.id) return <Redirect to={`/${community.slug}`} />;
    if (!community?.id) return <div />;
    return (
        <div id="conversation-block" className={classes.root}>
            <DiscussionLayout
                getDiscussionHeader={(config, discussion, isAuthenticated) => (
                    <DiscussionHeader
                        toggleShowInfoDialog={toggleShowInfoDialog}
                        showInfoDialog={showInfoDialog}
                        config={config}
                        onJoin={joinDiscussion}
                        discussion={{ ...discussion, memberIds }}
                        isJoined={isJoined}
                        isCommunityFollowed={!!community.followId}
                        isAdmin={appUser?.id === community.userId}
                        community={community}
                        isAuthenticated={isAuthenticated}
                        onJoinComm={handleJoinComm}
                        allowMessaging={allowMessaging}
                        onOpenDrawer={() => setMobileDrawerState('chat')}
                    />
                )}
                config={chatConfig}
                allowMessaging={allowMessaging}
                getDiscussionSideComp={(config, discussion) => (
                    <ActiveMemberDrawer
                        config={config}
                        allowMessaging={allowMessaging}
                        showChat={discussion?.discussionType && discussion?.discussionType !== 'text'}
                        mobileDrawerProps={{ onClose: () => setMobileDrawerState(undefined), open: !!mobileDrawerState }}
                    />
                )}
            />
        </div>
    );
};

interface DiscussionHeaderProps extends Pick<ChatMembersContentProps, 'allowMessaging' | 'memberDrawerDisabled' | 'showChat'> {
    // eslint-disable-next-line react/require-default-props
    discussion?: TDiscussion;
    isCommunityFollowed?: boolean;
    isJoined: boolean;
    onJoin: () => void;
    config: TDiscussionConfig;
    showInfoDialog: boolean;
    toggleShowInfoDialog: () => void;
    isAdmin: boolean;
    community: TCommunity;
    isAuthenticated?: boolean;
    onJoinComm?: FollowChangeHandler;
    onOpenDrawer?: () => void;
}

export const DiscussionHeader: React.FC<DiscussionHeaderProps> = ({
    discussion,
    isJoined,
    onJoin,
    config,
    showInfoDialog,
    toggleShowInfoDialog,
    isCommunityFollowed,
    isAdmin,
    community,
    isAuthenticated = true,
    onJoinComm,
    allowMessaging,
    memberDrawerDisabled,
    showChat,
    onOpenDrawer,
}) => {
    const classes = useStyles();
    const { title: discussionName, description, discussionType } = discussion || {};
    const { isDeviceSm } = useMediaQuery();
    const [showMemberDrawer, setShowMemberDrawer] = useState(false);
    const toggleShowMemberDrawer = () => setShowMemberDrawer((e) => !e);
    const [showPinnedMsgDialog, setShowPinnedMsgDialog] = useState(false);
    const toggleShowPinnedMsgDialog = () => setShowPinnedMsgDialog((e) => !e);
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
    const isBanned = useMemo(() => !!(appUser?.id && community.bannedUserIds.includes(appUser.id)), [appUser?.id, community.bannedUserIds]);
    return (
        <Box display="flex" justifyContent="space-between" alignItems="flex-start" p={isDeviceSm ? 2 : 4} pb={2} borderBottom={BORDER_BLACK}>
            <Box>
                {isDeviceSm ? (
                    <PgButton quaternary href={generatePath(`${APP_ROUTES.COMMUNITY_DETAIL.path}/?tab=1`, { communityId: community.slug ?? community.id ?? '#' })}>
                        <PgIcon icon="icon-arrow-back" style={{ marginRight: 16 }} />
                        <Box display="flex">
                            <PgTypo c1>{`#`}</PgTypo>
                            <PgTypo c1 className={classes.discussionHeader}>{`${discussionName}`}</PgTypo>
                        </Box>
                    </PgButton>
                ) : (
                    <Box display="flex">
                        {discussionType && discussionType !== 'text' ? (
                            <SVGCIcon
                                icon={discussionType === 'live-stream' ? 'icon-stream' : 'icon-mic-outlined'}
                                color={'primary'}
                                style={{ marginRight: 8, width: 20, height: 20, marginTop: 3 }}
                            />
                        ) : (
                            <img alt={discussionName} src={hash} height={18} width={18} className={classes.hashImg} />
                        )}
                        <PgTypo h6 className={classes.discussionHeader}>{`${discussionName}`}</PgTypo>
                    </Box>
                )}
                {description && !isDeviceSm ? <ReadMoreLess text={description} marginLeft={3} maxLength={80} typoProps={{ b2: true }} /> : null}
            </Box>
            {isJoined ? (
                <Box display={isDeviceSm ? "block" : "flex"} alignItems="flex-start" height={20}>
                    {isDeviceSm || !isAuthenticated ? null : discussion?.pinnedMessage && (isAdmin || !discussion.isDisabled) ? (
                        <PgButton onClick={toggleShowPinnedMsgDialog} variant="text" size="small" className={classes.pinnedButton}>
                            <PgTypo c3 style={{ textTransform: 'capitalize' }}>
                                Pinned
                            </PgTypo>
                            <PgIcon icon="icon-map-pin" color="primary" />
                        </PgButton>
                    ) : null}
                    <Box display='flex' alignItems='center'>
                        <IconButton onClick={toggleShowInfoDialog} className={classes.infoButton}>
                            <PgIcon icon="icon-info" />
                        </IconButton>
                        {isDeviceSm ? (
                            <IconButton onClick={onOpenDrawer} className={classes.infoButton}>
                                <img src={MembersSvg} style={{ height: 18 }} />
                            </IconButton>
                        ) : null}
                    </Box>
                </Box>
            ) : isCommunityFollowed ? (
                <PgButton primary onClick={onJoin} size="large">
                    <PgTypo b4Bold>
                        Join
                    </PgTypo>
                </PgButton>
            ) : community.visibility === 'INVITE_ONLY' || isBanned ? null : (
                <CommunityJoinButton profile={community} onFollowChange={onJoinComm} />
            )}
            {showInfoDialog && discussion ? (
                <DiscussionAboutDialog
                    config={config}
                    onClose={toggleShowInfoDialog}
                    isAuthenticated={isAuthenticated}
                    open={showInfoDialog}
                    discussion={discussion}
                />
            ) : null}
            {/* {isDeviceSm ? (
                <ActiveMemberMobileDrawer onClose={toggleShowMemberDrawer} open={showMemberDrawer} config={config} />
            ) : (
                <ActiveMemberDrawer config={config} allowMessaging={allowMessaging} memberDrawerDisabled={memberDrawerDisabled} showChat={discussion?.discussionType && discussion?.discussionType !== 'text'} />
            )} */}
            {showPinnedMsgDialog && discussion && discussion.pinnedMessage ? (
                <PinnedMessageDialog
                    isAdmin={isAdmin}
                    config={config}
                    discussion={discussion}
                    onClose={toggleShowPinnedMsgDialog}
                    open={showPinnedMsgDialog}
                />
            ) : null}
        </Box>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        root: {
            width: '100%',
            //   padding: theme.spacing(4, 4, 0),
            boxSizing: 'border-box',
            height: 'calc(100vh - 50px)',
            [theme.breakpoints.down('sm')]: {
                // padding: theme.spacing(2, 3, 0),
            },
        },
        pinnedButton: {
            paddingTop: 0,
        },
        discussionHeader: {
            wordBreak: 'break-all',
            fontFamily: 'unset',
            marginLeft: '10px',
        },
        text: {
            color: `${theme.palette.text.disabled} !important`,
        },
        infoButton: {
            padding: theme.spacing(0),
            marginLeft: theme.spacing(1),
            '&:hover': {
                backgroundColor: 'none',
            },
        },
        hashImg: { marginTop: theme.spacing(0.5) },
    });
});

export default Discussion;
