import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import PgTypo from './PgTypo';
import { IFieldProps } from 'react-forms';

export interface PrivacyTextInfoProps extends IFieldProps {
  fieldProps?: {
    options?: { title: string; subtitle: string }[];
    subtitle?: string;
    showSubtitle?: boolean;
    classes?: { subtitle?: string; optionRoot?: string; optionTitle?: string; optionSubtitle?: string };
  };
}

const infoTexts = [
  { title: 'Open', subtitle: 'Drops and Conversations are visible to everyone and anyone on Playground can join your community in a click.' },
  { title: 'Invite only', subtitle: 'Invite-only communities are discoverable on Playground but members can’t join without an invite from you.' },
  {
    title: 'Token Gated',
    subtitle: "Members can join your community and view your Drops but they can't access Conversations until they are NFT verified.",
  },
];

const PrivacyTextInfo: FC<PrivacyTextInfoProps> = (props) => {
  const { fieldProps } = props;
  const classes = useStyles();

  const options = fieldProps?.options ?? infoTexts;
  const subtitle = fieldProps?.subtitle ?? 'In private communities, only approved members can access Conversations';
  const showSubtitle = fieldProps?.showSubtitle ?? true;
  const fieldClasses = fieldProps?.classes;

  return (
    <React.Fragment>
      {subtitle && showSubtitle ? (
        <Box py={1.5}>
          <PgTypo b4 className={fieldClasses?.subtitle}>
            {subtitle}
          </PgTypo>
        </Box>
      ) : null}
      <Box>
        {options.map(({ title, subtitle }) => (
          <Box key={title} className={fieldClasses?.optionRoot}>
            <PgTypo c3 component="span" className={fieldClasses?.optionTitle}>
              {title}
            </PgTypo>
            <PgTypo b4 component="span" className={fieldClasses?.optionSubtitle}>
              &nbsp;—&nbsp;{subtitle}
            </PgTypo>
          </Box>
        ))}
      </Box>
    </React.Fragment>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({});
});

export default PrivacyTextInfo;
