import { TPhone, User } from 'Models/User/@types';
import { request } from 'Utils';
import { TAuthResponse, TCredentials, TMessageResponse, TSignUpData, TUserAuthResponse } from './@types';
import { sanitizedEmail } from './utils';

export const login = async (credentials: TCredentials): Promise<TAuthResponse> => {
  return request<TAuthResponse>({
    url: '/users/login',
    method: 'post',
    data: { ...credentials, email: sanitizedEmail(credentials.email) },
  });
};

export const signup = async (signUpData: TSignUpData): Promise<TUserAuthResponse> => {
  return request<TUserAuthResponse>({
    url: '/users/signupAuth',
    method: 'post',
    data: { ...signUpData, email: sanitizedEmail(signUpData.email) },
  });
};

export const forgotPassword = (email: string, redirectUrl?: string): Promise<TAuthResponse> =>
  request({
    url: '/users/forgetPassword',
    method: 'post',
    data: { email, redirectUrl },
  });

export const requestEmailVerificationLink = async (email: string, redirectUrl: string = window.location.href): Promise<boolean> => {
  await request<User>({
    url: '/users/sendToken',
    method: 'post',
    data: { email, extras: { redirectUrl } },
  });
  return true;
};
// export const verifyEmailToken = async (email: string, token: string): Promise<TUserAuthResponse> => {
//   return request<TUserAuthResponse>({
//     url: `/users/verifyToken`,
//     method: 'post',
//     data: {
//       email,
//       token,
//     },
//   });
// };

export const updatePassword = async (id: string, password: string): Promise<User> => {
  return request<User>({
    url: `/users/${id}`,
    method: 'patch',
    data: { id, password },
  });
};

export const requestPhoneOTP = async (phone: TPhone): Promise<TMessageResponse> => {
  return request<TMessageResponse>({
    url: `/users/sendToken`,
    method: 'post',
    data: {
      phone,
    },
  });
};

export const updatePhone = async (phone: TPhone, token: string): Promise<TUserAuthResponse> => {
  return request<TUserAuthResponse>({
    url: `/users/updatePhoneWithToken`,
    method: 'post',
    data: { ...phone, token },
  });
};

export default {};
