/* eslint-disable react/require-default-props */
import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box, BoxProps, Button, ButtonProps, DialogProps, IconButton } from '@material-ui/core';
import DialogWrapper from 'Components/DialogWrapper';
import Typo from 'Components/Typo';
import { Close } from '@material-ui/icons';
import Spacer from 'Components/Spacer';
import clsx from 'clsx';

export interface BasicActionDialogProps {
    open: boolean;
    onClose: () => void;
    title?: string;
    subtitle?: string;
    additionalComponent?: JSX.Element;
    additionalComponentContainerProps?: BoxProps;
    onDone?: () => void;
    doneBtnText?: string | JSX.Element;
    doneBtnprops?: Omit<ButtonProps, 'onClick' | 'variant' | 'fullWidth' | 'color'>;
    onCancel?: () => void;
    cancelBtnprops?: Omit<ButtonProps, 'onClick' | 'variant' | 'fullWidth' | 'color'>;
    cancelBtnText?: string | JSX.Element;
    dialogMaxWidth?: number;
    disableEscapeKeyDown?: boolean;
    showCloseBtn?: boolean;
    dialogProps?: Omit<DialogProps, 'open' | 'onClose'>;
    dialogPaperClass?: string
}

const BasicActionDialog: FC<BasicActionDialogProps> = (props) => {
    const {
        open,
        onClose,
        title,
        subtitle,
        additionalComponent,
        additionalComponentContainerProps,
        onDone,
        doneBtnText,
        doneBtnprops,
        cancelBtnprops,
        onCancel,
        cancelBtnText,
        dialogMaxWidth,
        disableEscapeKeyDown,
        showCloseBtn = true,
        dialogProps = {},
        dialogPaperClass
    } = props;

    const classes = useStyles({ maxWidth: dialogMaxWidth });

    return (
        <DialogWrapper open={open} onClose={onClose} classes={{ paper: clsx(classes.root, dialogPaperClass) }} disableEscapeKeyDown={disableEscapeKeyDown} {...dialogProps}>
            {showCloseBtn && (
                <Box position="absolute" top={5} right={5}>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
            )}
            <Box px={2.5} pt={4} pb={3} display="flex" flexDirection="column" height="100%" className={classes.main}>
                {!title && !subtitle ? null : (
                    <Box mb={1.5}>
                        {title && (
                            <Typo align="center" variant="h5" weight="bold">
                                {title}
                            </Typo>
                        )}
                        {subtitle && (
                            <>
                                <Spacer height={20} />
                                <Typo align="center" variant="body1">
                                    {subtitle}
                                </Typo>
                            </>
                        )}
                    </Box>
                )}
                <Box {...additionalComponentContainerProps}>{additionalComponent}</Box>
                {!onCancel && !onDone ? null : (
                    <>
                        <Box flex={1} />
                        <Box display="flex" alignItems="center" mt={2}>
                            {onCancel ? (
                                <Button variant="outlined" fullWidth onClick={onCancel} {...cancelBtnprops}>
                                    {cancelBtnText || 'CANCEL'}
                                </Button>
                            ) : null}
                            {onDone && onCancel ? <Spacer width={40} /> : null}
                            {onDone ? (
                                <Button variant="contained" fullWidth color="secondary" onClick={onDone} {...doneBtnprops}>
                                    {doneBtnText || 'DONE'}
                                </Button>
                            ) : null}
                        </Box>
                    </>
                )}
            </Box>
        </DialogWrapper>
    );
};

const useStyles = makeStyles<Theme, { maxWidth?: number }>((theme) =>
    createStyles({
        root: {
            maxWidth: (props) => `${props.maxWidth ?? 420}px !important`,
            // width: (props) => `${props.maxWidth ?? 420}px !important`,
            width: '100%',
            minHeight: 'unset !important',
            borderRadius: 'unset',
        },
        main: { backgroundColor: theme.palette.background.paper },
    }),
);

export default BasicActionDialog;
