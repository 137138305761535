/* eslint-disable react/require-default-props */
/* eslint-disable no-underscore-dangle */
import { Box, BoxProps, createStyles, FormHelperText, FormHelperTextProps, IconButton, LinearProgress, makeStyles, Portal } from '@material-ui/core';
import RemoveIcon from 'Components/RemoveIcon';
import { FormikProps } from 'formik';
import useAsyncTask from 'Hooks/useAsyncTask';
import { get } from 'lodash';
import PictureModel from 'Models/Picture';
import { Picture } from 'Models/Picture/@types';
import { parsePicture } from 'Models/Picture/pictureParser';
import React, { CSSProperties, FC, useMemo, useState } from 'react';
import { getFieldError, IFieldProps, TFile } from 'react-forms';
import { useSnackbar } from 'notistack';

import helpers from 'Utils/helpers';
import { COLOR } from 'Theme/themeConstants';
// import { ReactCropProps } from 'react-image-crop';
import ImageCropper from 'Components/ImageCropper';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import ToastMessage from 'Components/ToastMessage';
import PgButton from 'Components/PgButton';
import BasicActionDialog from 'Dialogs/BasicActionDialog';
import ImageGenerator from 'Features/ImageGenerator';
import ADD_IMG from 'Assets/images/add_img.png';

export const MAX_FILE_SIZE = 10000;

export interface ImagePickerFieldProps {
    label?: string;
    name?: string;
    imgHW?: number; // This has to be a number because this is the width that will be applied for transforming the url using imageKit's api.
    // imagePlaceholderHeight: number | string;
    customParser?: (img: any) => Picture | string;
    helperText?: string;
    classes?: ImagePickerClasses;
    helperTextProps?: FormHelperTextProps;
    imageUploadType?: string;
    imageUploadCropConfig?: Cropper.Options;
    showImageBtn?: boolean;
    fieldMainWrapperStyles?: CSSProperties;
    unsplashPickerWrapProps?: BoxProps;
    uploadIcon?: JSX.Element;
    disabled?: boolean;
    showVariableSizes?: boolean;
    hasUnsplash?: boolean;
}

interface ImagePickerClasses {
    label?: string;
    subtitle?: string;
    helperText?: string;
    root?: string;
}

export interface ImagePickerProps extends IFieldProps {
    fieldProps?: ImagePickerFieldProps;
}
const ImagePicker: FC<ImagePickerProps> = (props) => {
    const { fieldProps = {} as ImagePickerFieldProps, fieldConfig, formikProps = {} as FormikProps<any> } = props;
    const {
        name = '',
        label = 'Add Images',
        helperText,
        customParser,
        imgHW = 90,
    /* imagePlaceholderHeight = 50, */ classes: propClasses,
        helperTextProps,
        // imageUploadType,
        imageUploadCropConfig,
        uploadIcon,
        fieldMainWrapperStyles = {},
        unsplashPickerWrapProps = {},
        disabled,
        showVariableSizes,
        hasUnsplash = true,
    } = fieldProps;
    const classes = useStyles();
    const valueKey = fieldConfig?.valueKey || '';
    const value: any = get(formikProps, `values.${name}`);
    // const [uploadingImgNum, setUploadingImgNum] = useState<number | undefined>();
    // const numAddedImages = typeof value?.length === 'number' ? value?.length : 0;
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [isRemoveCall, setIsRemoveCall] = useState(false);
    // const { withCropping = true, cropConfig={} } = props;
    const withCropping = true;
    const cropConfig = imageUploadCropConfig;
    const [originalImage, setOriginalImage] = useState<TFile>();
    const pictureUpload = async (img: { base64: string; name: string; cropData: Cropper.Data }) => {
        const image = await PictureModel.upload(img);
        if (image) {
            if (!customParser && formikProps) formikProps.setFieldValue(valueKey, { ...parsePicture(image), cropData: img.cropData });
            else if (formikProps && customParser) formikProps.setFieldValue(valueKey, customParser({ ...image, cropData: img.cropData })); // Checking formikProps is only to workaround eslint warnings. If eslint updates allow disabling the error, this check can be removed.
        }
    };
    const pictureUploadTask = useAsyncTask(pictureUpload);
    const fieldName = name.includes('[0]') ? name.slice(0, -3) : name;
    let fieldError = getFieldError(fieldName, formikProps as any);
    const _error = useMemo(() => formikProps.errors?.[fieldName], [formikProps.errors, fieldName]);
    if (isRemoveCall) fieldError = _error;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;

        if (files) {
            const file = files[0];
            const fileSizeKiloBytes = file.size / 1024;
            // console.log('fileSie' , file.size);
            if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                enqueueSnackbar(
                    ToastMessage({
                        title: 'Oops! Choose an image less than 10MB!',
                    }),
                    { variant: 'error' }
                );
                return;
            }
            const reader = new FileReader();
            reader.onload = (event) => {
                if (!file.type.includes('image')) {
                    enqueueSnackbar(
                        ToastMessage({
                            title: 'File you selected does not seem to be an image',
                        }),
                        { variant: 'error' }
                    );
                    return;
                }
                if (file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/gif') {
                    enqueueSnackbar(
                        ToastMessage({
                            title: 'Only jpeg, png and gif files are allowed',
                        }),
                        { variant: 'error' }
                    );
                    return;
                }
                const fileInfo: TFile = {
                    name: file.name,
                    type: file.type,
                    size: `${Math.round(file.size / 1000)} kB`,
                    base64: reader.result,
                    file,
                };
                if (file.type === 'image/gif')
                    handleCroppedImageUPload(fileInfo.base64 as string, {} as Cropper.Data, fileInfo.name);
                else {
                    setOpen(true);
                    setOriginalImage(fileInfo);
                }
            };
            reader.readAsDataURL(file);
        }
        // files &&
        //   processFilesWithCallback(
        //     files,
        //     (prop: { imgs: TFile[]; _rem: any[] }) => {
        //       pictureUploadTask.run(prop);
        //     }
        //   );
    };

    const handleClick = (event: any) => {
        const { target = {} } = event || {};
        target.value = '';
    };

    const handleCroppedImageUPload = (originalBase64: string, cropCoordinates: Cropper.Data, imageName?: string) => {
        if (originalImage || originalBase64) {
            pictureUploadTask.run({ name: imageName ?? originalImage?.name ?? '', base64: originalBase64, cropData: cropCoordinates });
            setOpen(false);
        }
    };

    const removeItem = (event: React.MouseEvent<HTMLElement>) => {
        if (value) {
            event.stopPropagation();
            event.preventDefault();
            formikProps.setFieldValue(name, null);
            setIsRemoveCall(true);
        }
    };
    // const handleComplete = (croppedBase64: string) => {
    // 	console.count('handleComplete');
    // 	if (croppedImage)
    // 		props.onDone?.([{ ...croppedImage, base64: croppedBase64 }]);
    // };
    const isLoading = pictureUploadTask.status === 'PROCESSING';

    const [showImgGenerateDialog, setShowImgGenerateDialog] = useState(false);
    const handleImageSelect = (imgUrl: string) => {
        formikProps.setFieldValue(valueKey, { url: imgUrl });
        setShowImgGenerateDialog(false)
    }

    return (
        <Box position='relative'>
            {!!label && (
                <Box mb={1}>
                    <PgTypo b4 color={fieldError ? 'error' : undefined}>{label}</PgTypo>
                </Box>
            )}
            <Box display="flex">
                <div className={value && value?.url ? classes.hasImage : classes.imgPlaceholder} style={fieldMainWrapperStyles}>
                    {isLoading ? (
                        <LinearProgress color="secondary" className={classes.loader} />
                    ) : (
                        <>
                            {!value || !value?.url
                                ? uploadIcon ? uploadIcon : <PgIcon icon="icon-add" color="secondary" styleClass={classes.icon} />
                                // ? <img src={ADD_IMG} alt='add-img' className={classes.addImg} />
                                // : <PgIcon icon="icon-add" color="secondary" styleClass={classes.icon} />
                                : null}
                            {value?.url ? (
                                <Box overflow="hidden">
                                    {' '}
                                    <img
                                        className={classes.img}
                                        width={imgHW}
                                        src={value?.url?.includes('imagekit') ? helpers.getPictureUrl(value, imgHW) : value?.url}
                                        alt=""
                                        style={{ aspectRatio: cropConfig?.aspectRatio?.toString() }}
                                    />{' '}
                                </Box>
                            ) : null}
                            <input
                                accept="image/*"
                                title=""
                                type="file"
                                name={`${name}`}
                                onChange={(event) => handleChange(event)}
                                className={classes.input}
                                onClick={handleClick}
                                disabled={disabled}
                            />
                            {withCropping && originalImage?.base64 ? (
                                <>
                                    <ImageCropper
                                        open={open}
                                        onClose={() => setOpen(false)}
                                        base64={originalImage.base64 as string}
                                        cropConfig={cropConfig}
                                        onComplete={handleCroppedImageUPload}
                                        showVariableSizes={showVariableSizes}
                                    />
                                </>
                            ) : null}
                        </>
                    )}
                    {disabled ? null : (
                        <div id="imageRemoveOverlay" className={classes.imageRemoveOverlay}>
                            <IconButton onClick={(event) => removeItem(event)} disabled={disabled}>
                                <RemoveIcon color="contrast" />
                            </IconButton>
                        </div>
                    )}
                </div>
            </Box>
            {helperText || fieldError || (pictureUploadTask.status === 'ERROR' && pictureUploadTask.message === 'request entity too large') ? (
                <FormHelperText {...helperTextProps} className={propClasses?.helperText} error={!!fieldError}>
                    {fieldError || helperText}
                </FormHelperText>
            ) : null}
            {
                hasUnsplash ? <Box mt={2.5} {...unsplashPickerWrapProps}>
                    <PgButton disabled={disabled} quaternary underlined onClick={() => setShowImgGenerateDialog(true)}>Or select from Unsplash</PgButton>
                </Box> : null
            }

            <Portal>
                <BasicActionDialog
                    open={showImgGenerateDialog}
                    onClose={() => setShowImgGenerateDialog(false)}
                    additionalComponent={<ImageGenerator onImageSelect={handleImageSelect} />}
                    additionalComponentContainerProps={{ pt: 2 }}
                    dialogMaxWidth={800}
                    dialogProps={{ scroll: 'paper' }}
                />
            </Portal>
        </Box>
    );
};

export default ImagePicker;

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            backgroundColor: `${theme.palette.secondary.main}`,
            border: `1px dashed ${theme.palette.secondary.main}`,
            borderRadius: 3,
            position: 'relative',
            zIndex: 1,
            verticalAlign: 'middle',
            textAlign: 'center',
            '&:not(:last-child)': {
                marginRight: 20,
            },
        },
        disabled: {
            backgroundColor: theme.palette.grey.A200,
            border: 'none',
        },
        imgPlaceholder: {
            width: 88,
            height: 88,
            borderRadius: 3,
            border: `1px dashed ${theme.palette.secondary.main}`,
            position: 'relative',
            display: 'grid',
            placeItems: 'center',
        },
        input: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            width: 'inherit',
            height: 'inherit',
            zIndex: 2,
            '&:not([disabled])': {
                cursor: 'pointer',
            },
        },
        hasImage: {
            border: 'none',
            backgroundColor: 'transparent',
            position: 'relative',
            '&:hover #imageRemoveOverlay': {
                display: 'flex',
            },
        },
        icon: {
            // marginRight: '50%',
            // marginLeft: '50%',
            // transform: 'translate(-50%, -50%)',
            // marginTop: '50%',
            // marginBottom: '50%',
            color: theme.palette.secondary.main,
        },
        disabledSVG: {
            opacity: 0,
        },
        img: {
            display: 'inline-block',
            zIndex: 2,
            position: 'relative',
            objectFit: 'cover',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
            },
        },
        loader: {
            transform: 'translateY(-50%)',
            marginTop: '50%',
            marginBottom: '50%',
            width: '100%',
        },
        imageRemoveOverlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: 'none',
            cursor: 'pointer',
            width: 'inherit',
            height: 'inherit',
            zIndex: 9,
            justifyContent: 'center',
            alignItems: 'center',
            '& .icon': {
                zIndex: 9,
            },
            '&::before': {
                backgroundColor: COLOR.secondary.blue,
                content: "' '",
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                opacity: 0.5,
                borderRadius: 3,
            },
        },
        addImg: { maxWidth: 335, maxHeight: 335, height: '100%', width: '100%', objectFit: 'cover' }
    })
);
