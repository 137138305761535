import { Box, Checkbox, FormControlLabel, Theme, createStyles, makeStyles } from '@material-ui/core';
import PgButton from 'Components/PgButton';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import SubmitButton from 'Components/SubmitButton';
import { LEGAL_LINK } from 'Constants/links';
import { TICKETING_TERMS_ERROR } from 'Forms/Ticketing/useEventTicketFormConfig';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import useShowToast from 'Hooks/useShowToast';
import { TContentType } from 'Models/Drop/@types';
import { CustomNotificationCheckbox } from 'Screens/UserSettings/Sections/AccountNotifications';
import { BORDER_BLACK } from 'Theme/themeConstants';
import { Formik, FormikConfig, FormikProps } from 'formik';
import React, { FC, useMemo } from 'react';
import { FormConfig, MLFormContent, RowSchema, getFieldError } from 'react-forms';
import * as Yup from 'yup';

export interface IRSVPFormData {
  firstName: string;
  lastName: string;
  email: string;
  isTermsAccepted: boolean;
}

const ValidationSchema = Yup.object().shape<IRSVPFormData>({
  firstName: Yup.string().required('First name required'),
  lastName: Yup.string().required('Last name required'),
  email: Yup.string().email('Invalid Email').required('Email required'),
  isTermsAccepted: Yup.bool().oneOf([true], TICKETING_TERMS_ERROR).required(),
});

const textFieldSpacing = { styles: { marginTop: 20 } };

const fieldSchemas: Record<keyof Omit<IRSVPFormData, 'isTermsAccepted'>, FormConfig> = {
  firstName: { type: 'text', valueKey: 'firstName', fieldProps: { label: 'First Name', placeholder: 'First name' }, ...textFieldSpacing },
  lastName: { type: 'text', valueKey: 'lastName', fieldProps: { label: 'Last Name', placeholder: 'Last name' }, ...textFieldSpacing },
  email: { type: 'text', valueKey: 'email', fieldProps: { label: 'Email Address', placeholder: 'Email Address' }, ...textFieldSpacing },
};

export interface RSVPFormProps {
  onSubmit?: FormikConfig<Partial<IRSVPFormData>>['onSubmit'];
  isLoading?: boolean;
  dropType?: TContentType;
}

const RSVPForm: FC<RSVPFormProps> = (props) => {
  const { onSubmit, isLoading = false, dropType = 'Event' } = props;

  const classes = useStyles();

  const { isDeviceSm } = useMediaQuery();

  const formSchema = useMemo<RowSchema[]>(
    () => [...(isDeviceSm ? [fieldSchemas.firstName, fieldSchemas.lastName] : [[fieldSchemas.firstName, fieldSchemas.lastName]]), fieldSchemas.email],
    [isDeviceSm],
  );

  const showToast = useShowToast();

  const handleErrorCheck = (formikProps: FormikProps<Partial<IRSVPFormData>>) => () => {
    if (!formikProps.values.isTermsAccepted) showToast(TICKETING_TERMS_ERROR, undefined, 'error');
  };

  return (
    <Formik<Partial<IRSVPFormData>> initialValues={{ isTermsAccepted: true }} onSubmit={onSubmit ?? (() => {})} validationSchema={ValidationSchema}>
      {(formikProps) => {
        const termsError = getFieldError('isTermsAccepted', formikProps);
        return (
          <form onSubmit={formikProps.handleSubmit} style={{ width: '100%' }}>
            <Box p={isDeviceSm ? 2 : 3}>
              <MLFormContent
                formId="drop-rsvp-form"
                schema={formSchema}
                formikProps={formikProps}
                settings={{ verticalSpacing: 20, horizontalSpacing: 20 }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!formikProps.values.isTermsAccepted}
                    onChange={formikProps.handleChange}
                    name="isTermsAccepted"
                    icon={<PgIcon icon="icon-checkbox-off" color={!!termsError ? 'error' : undefined} />}
                    checkedIcon={<CustomNotificationCheckbox />}
                  />
                }
                label={
                  <PgTypo b4 color={!!termsError ? 'error' : 'primary'}>
                    I have read and accept the{' '}
                    <PgButton quaternary isExternalLink className={classes.termsBtn} href={LEGAL_LINK} color="inherit">
                      terms and conditions
                    </PgButton>
                  </PgTypo>
                }
              />
            </Box>
            <Box display="flex" px={isDeviceSm ? 2 : 3} py={2} borderTop={BORDER_BLACK} justifyContent="flex-end">
              <SubmitButton color="primary" variant="contained" type="submit" onClick={handleErrorCheck(formikProps)} loading={isLoading}>
                {dropType === 'Event' ? 'RSVP' : 'Set Reminder'}
              </SubmitButton>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default RSVPForm;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    termsBtn: {
      border: 'none',
      fontWeight: theme.typography.fontWeightRegular,
      textDecoration: 'underline',
      marginTop: -2,
    },
  }),
);
