/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { FormControl, FormHelperText, MenuItem, Select } from '@material-ui/core';
import { TEvent } from 'Models/Event';
import { FormikProps } from 'formik';
import dayjs from 'dayjs';
import Typo from 'Components/Typo';
import { EventTicketFormData } from './EventTicketForm';

export interface EventDateSelectorProps {
  event: TEvent;
  formikProps?: FormikProps<Partial<EventTicketFormData>>;
}

const EventDateSelector: FC<EventDateSelectorProps> = (props) => {
  const { event, formikProps } = props;
  const classes = useStyles({});

  if (!event.frequency || !event.eventDates) return null;
  if (event.frequency === 'oneTime') return null;

  const handleDateChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    formikProps?.handleChange(e);
    formikProps?.setValues({ eventDate: e.target.value as string });
  };

  return (
    <FormControl fullWidth>
      <Typo weight="medium" className={classes.label}>
        Date
      </Typo>
      <Select
        label="Select date"
        labelId="event-date-label"
        name="eventDate"
        value={formikProps?.values.eventDate || 'Select date'}
        onChange={handleDateChange}
        placeholder="Select date"
        inputProps={{
          placeholder: 'Select date',
        }}
        // defaultValue="Select date"
        error={!!formikProps?.errors.eventDate}
        className={classes.select}
      >
        <MenuItem disabled value="Select date">
          Select Date
        </MenuItem>
        {event.eventDates.map((e, i) => {
          const date = dayjs(e.startDate).format('ddd DD MMM, h:mm a');
          return (
            <MenuItem key={i} value={e.startDate}>
              {date}
            </MenuItem>
          );
        })}
      </Select>
      {!!formikProps?.errors.eventDate && <FormHelperText error={!!formikProps.errors.eventDate}>{formikProps.errors.eventDate}</FormHelperText>}
    </FormControl>
  );
};

export default EventDateSelector;

const useStyles = makeStyles<Theme, any>((theme) =>
  createStyles({
    label: {
      fontSize: theme.typography.body2.fontSize,
    },
    select: {
      backgroundColor: theme.palette.grey.A400,
    },
  })
);
