import UserListDialog from 'Features/User/UserListDialog';
import { FetchUsersParams } from 'Features/User/UserSearch';
import DropModel from 'Models/Drop';
import { TContentType } from 'Models/Drop/@types';
import { User } from 'Models/User/@types';
import React, { useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { APP_ROUTES } from 'Routes';

const useAttendeeListDialog = (dropType: TContentType = 'Event') => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow((e) => !e);
  const { id } = useParams<{ id: string }>();

  const fetchData = async (params: FetchUsersParams) => {
    // return await EventModel.getEventRsvpGuests(id, params.term, { skip: params.skip, limit: 20 });
    return await DropModel.getDropGuests(dropType, id, { term: params.term, skip: params.skip, limit: 20 });
  };
  const history = useHistory();

  const onUserClick = (user: User) => {
    history.push(generatePath(APP_ROUTES.USER_DETAIL.path, { id: user.slug || user.id }));
  };
  return {
    openDialog: toggleShow,
    Component: show ? <UserListDialog open={show} onClose={toggleShow} title="Going" fetchData={fetchData} onUserClick={onUserClick} /> : null,
  };
};

export default useAttendeeListDialog;
