import React, { FC } from 'react';
import { Box, Link, makeStyles, Theme } from '@material-ui/core';
import PgTypo from 'Components/PgTypo';
import MapsView from 'Components/MapsView';
import { IEventPageProps } from './@types';
import moment from 'moment';
import useCountdown from 'Hooks/useCountdown';
import PgButton from 'Components/PgButton';

const VirtualLocation: FC<IEventPageProps & { onCountDownEnds?: () => void }> = ({ event, classes, onCountDownEnds }) => {
    const startDate = event.eventDates?.[0]?.startDate;
    const diff = moment(startDate).diff(moment(), 'hour', true);
    const countDown = useCountdown(diff < 12 && startDate ? moment(startDate).subtract(6, 'hours').toISOString() : '');
    if (!event.isReminded) return <PgTypo c2>Please RSVP/Get tickets to access the url.</PgTypo>;
    if (diff > 12) return <PgTypo c2>The URL to join will be open 6 hours before the event.</PgTypo>;
    if (diff < 12 && diff >= 6 && countDown && !event.platform?.link) {
        if (countDown.hours === 0 && countDown.minutes === 0 && countDown.seconds <= 1) onCountDownEnds?.();
        return (
            <PgTypo c2>
                The URL to join will be open in {countDown.hours}:{countDown.minutes}:{countDown.seconds}
            </PgTypo>
        );
    }

    // if (!event?.platform?.link) return <PgTypo b2>TBD</PgTypo>;
    if (!event?.platform?.link) return null;

    return (
        <Box>
            {/* <PgTypo>via {event.platformName ?? event.platform?.provider}</PgTypo> */}
            {/* <PgTypo> */}
            <Link className={classes?.locationLink} href={event.platformUrl ?? event.platform?.link} target="_blank" rel="noreferrer">
                <PgTypo b2>{event.platformUrl ?? event.platform?.link}</PgTypo>
            </Link>
            {/* </PgTypo> */}
        </Box>
    );
};

const PhysicalLocation: FC<IEventPageProps> = ({ event, classes }) => {
    // if (!event.address || !event.location) return <PgTypo b2>TBD</PgTypo>;
    if (!event.address || !event.location) return null;

    return (
        <Box flex={1} className={classes?.locationMap}>
            {event.address?.structured_formatting ? (
                <Box>
                    <PgTypo b2>{event.address?.structured_formatting?.main_text}</PgTypo>
                    <PgTypo b4>{event.address?.structured_formatting?.secondary_text}</PgTypo>
                </Box>
            ) : (
                <Box>
                    <PgTypo b2>{event.address?.fullAddress}</PgTypo>
                </Box>
            )}

            {/* {!!event.address.url && (
                <PgButton href={event.address.url} isExternalLink quaternary>
                    <PgTypo b2>{event.address?.url}</PgTypo>
                </PgButton>
            )} */}

            {!!(event.location.lat && event.location.lng) && (
                <MapsView
                    points={{ lat: event.location?.lat, lng: event.location?.lng }}
                    containerElement={<Box className={classes?.containerElement} />}
                    mapElement={<Box className={classes?.mapElement} />}
                />
            )}
        </Box>
    );
};

const EventLocation: FC<IEventPageProps & { onCountDownEnds?: () => void }> = ({ event, onCountDownEnds }) => {
    const classes = useStyles();
    // if (!event?.featureToggle?.where) return <PgTypo b2>TBD</PgTypo>;
    if (!event?.featureToggle?.where) return null;
    if (event.eventType === 'offline') return <PhysicalLocation event={event} classes={classes} />;
    if (event.eventType === 'online' || event.eventType === 'metaverse')
        return <VirtualLocation event={event} classes={classes} onCountDownEnds={onCountDownEnds} />;
    return null;
};

export default EventLocation;

const useStyles = makeStyles((theme: Theme) => ({
    locationMap: {
        width: '100%',
    },
    locationLink: {
        // color: theme.palette.common.black,
        color: theme.palette.secondary.main,
        textDecoration: 'underline',
        wordBreak: 'break-word',
        // '&:hover': {
        //   textDecoration: 'underline',
        // },
    },
    mapElement: {
        height: '100%',
        borderRadius: 10,
    },
    containerElement: {
        height: theme.spacing(15),
        width: '100%',
        overflow: 'hidden',
    },
}));
