import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// eslint-disable-next-line import/no-cycle
import { SVGCIconProps } from '../SVGCIcon';

export interface KlaviyoIconProps extends SVGCIconProps {
  iconProps?: {
    color?: string;
  };
}

export const KlaviyoIcon: FC<KlaviyoIconProps> = (props) => {
  const { width = 37, height = 24, iconProps = {} } = props;
  const { color } = iconProps;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.37466 10.5823L16.9117 0.291955C17.5887 -0.0973182 18.418 -0.0973182 19.095 0.291955L34.6321 10.5823C35.2414 10.8869 35.5122 11.6147 35.2076 12.2409C35.0891 12.4948 34.886 12.6979 34.6321 12.8164L32.5503 14.1873C27.4559 6.14797 16.8271 3.76156 8.78777 8.83903C6.63831 10.21 4.81042 12.0209 3.4395 14.1873L1.34081 12.8164C0.731518 12.4948 0.49457 11.7501 0.799218 11.1408C0.934617 10.9039 1.13772 10.7008 1.37466 10.5823ZM17.978 10.4638C13.5267 10.4807 9.38015 12.7656 7.01066 16.5399L10.5818 18.9094C13.0359 14.8305 18.3334 13.5272 22.3954 15.9813C23.5971 16.7091 24.5956 17.7077 25.3234 18.9094L28.8946 16.5399C26.5589 12.7487 22.4292 10.4469 17.978 10.4638ZM17.978 19.0617C16.404 19.0786 14.9654 19.9248 14.1868 21.2958L16.5902 22.8698C16.9794 23.2083 17.4703 23.3775 17.978 23.3775C18.4857 23.3775 18.9935 23.1914 19.3658 22.8698L21.7692 21.2958C21.0076 19.9079 19.552 19.0617 17.978 19.0617Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="17.9922"
          y1="23.3715"
          x2="17.9922"
          y2="0.0027997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EFC119" />
          <stop offset="0.3314" stopColor={color || '#92C84E'} />
          <stop offset="0.4859" stopColor={color || '#71BF5B'} />
          <stop offset="0.7506" stopColor={color || '#15B158'} />
          <stop offset="0.903" stopColor={color || '#23AC69'} />
          <stop offset="1" stopColor={color || '#23AC69'} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Klaviyo: FC<KlaviyoIconProps> = (props) => {
  const classes = useStyles();
  return (
    <i className={classes.root}>
      <KlaviyoIcon {...props} />
    </i>
  );
};

export default Klaviyo;

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      position: 'relative',
      display: 'inline-block',
    },
    playIcon: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-70%)',
    },
  })
);
