import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormConfig, IFormActionProps } from 'react-forms';
import usePasswordField from 'Components/usePasswordField';
import * as Yup from 'yup';
import { TextFieldProps } from '@material-ui/core';

// eslint-disable-next-line import/prefer-default-export
export const useFormConfig = (disableEmail: boolean) => {
  const classes = useStyles();
  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid Email').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });
  const actionConfig: IFormActionProps = {
    submitButtonText: 'Log IN',
    submitButtonLayout: 'fullWidth',
    submitButtonProps: {
      size: 'large',
    },
    containerClassNames: classes.buttonContainer,
  };
  const passwordField = usePasswordField('password', 'Password', false, {
    placeholder: 'Password',
    className: classes.eachField,
  });
  const inputConfig: Array<Array<FormConfig> | FormConfig> = [
    {
      type: 'text',
      valueKey: 'email',
      fieldProps: {
        placeholder: 'Email address',
        label: 'Email address',
        className: classes.eachField,
        fullWidth: true,
        variant: 'outlined',
        disabled: disableEmail,
      } as TextFieldProps,
    },
    passwordField,
  ];
  return { actionConfig, inputConfig, validationSchema };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      '& button': {
        // backgroundColor: theme.palette.secondary.main,
      },
    },
    eachField: {
      paddingBottom: 20,
    },
  }),
);
