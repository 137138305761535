import { makeStyles } from '@material-ui/core';
import PgButton from 'Components/PgButton';
import PgDialog from 'Components/PgDialog';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import React, { FC } from 'react';
import { useStoreState } from 'Stores';
import { FONT_FAMILY } from 'Theme/themeConstants';
import { TAuthDialogContentProps } from '../@types';
import { TWalletConnectHookProps, WalletType } from '../useWalletConnect';

const WalletError: FC<
  TAuthDialogContentProps & {
    handleErrorBackClick: TWalletConnectHookProps['handleErrorBackClick'];
    walletMeta: TWalletConnectHookProps['walletMeta'];
  }
> = (props) => {
  const { handleErrorBackClick, walletMeta } = props;
  const classes = useStyles();
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
  let metamaskNotInstalled = false;
  if (typeof window.ethereum === 'undefined' && walletMeta.wallet === WalletType.MetaMask) {
    metamaskNotInstalled = true;
  }

  const { isDeviceSm } = useMediaQuery();
  return (
    <PgDialog
      headingContainerProps={{ display: 'flex', justifyContent: 'center', position: 'relative' }}
      headingProps={{ h3: !isDeviceSm, h7: isDeviceSm, align: 'center', typoWeight: 'fontWeightBold', h6: false }}
      subheadingProps={{ className: classes.subtitle, c2: false, b1: true }}
      heading={metamaskNotInstalled ? 'MetaMask extension is not installed' : 'Could not connect to wallet'}
      subheading={
        metamaskNotInstalled ? 'Please install required browser extension to continue the sign up process.' : 'Please try again after some time'
      }
      actionButtons={
        <PgButton variant="outlined" onClick={handleErrorBackClick}>
          {appUser && appUser.id ? 'Cancel' : 'Back to login'}
        </PgButton>
      }
    />
  );
};

export default WalletError;

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontFamily: FONT_FAMILY.secondary,
    marginBottom: theme.spacing(4),
  },
}));
