/* eslint-disable camelcase */
import { capitalize, intersection } from 'lodash';
import { TESSearchItem, TESSearchResponse, TFilter } from 'Models/App/@types';
import { parseEvent } from 'Models/Event/eventParsers';
import { ResourceType } from 'Models/Resource/@types';
import { parseResource } from 'Models/Resource/resourceParsers';
import { TUserFavorited, TUserFollows, User } from 'Models/User';
import { request } from 'Utils';
import helpers from 'Utils/helpers';
import { TDrop, TDropSearchResponse, TContentType, OnDisplayEntity, TSetReplayResponse, TDropType } from './@types';

export let resourceSearchController: AbortController | null = null;
class DropModel {
    static parseDropsList = (list?: TESSearchItem<TDrop>[]): TDrop[] => {
        if (!list) return [];
        return list.map((item) => {
            if (item.hit.doc_type === 'Event') return parseEvent(item.hit);
            return parseResource(item.hit);
        });
    };

    static search = async (userId?: string, params?: TFilter): Promise<TDropSearchResponse> => {
        resourceSearchController = new AbortController();
        const data = await request<TESSearchResponse<TDrop>>({
            url: `/Resources/search`,
            params: { ...(params ?? {}), userId },
            signal: resourceSearchController.signal,
        });
        return { total: data.total ?? 0, results: DropModel.parseDropsList(data.results), aggregations: {} };
    };

    static parseDocType = (docType: TContentType): string => {
        if (docType && docType !== 'Event') return `${docType.toLowerCase()}s`;
        return 'Events';
    };

    static addFavourite = async (doc_type: TContentType, subjectId: string, userId: string): Promise<TUserFavorited<User>> => {
        const docType = DropModel.parseDocType(doc_type);
        const res = await request({
            url: `/${docType}/${subjectId}/create-favourite/${userId}`,
            method: 'POST',
        });
        return res[0];
    };

    static removeFavourite = async (favouriteId: string): Promise<void> => {
        await request<boolean>({
            url: `/Favourites/${favouriteId}/remove-favourite`,
            method: 'DELETE',
        });
    };

    static RSVP = async (doc_type: TContentType, subjectId: string, userId: string, password?: string): Promise<TUserFollows<User>> => {
        const docType = DropModel.parseDocType(doc_type);
        const res = await request<TUserFollows<User>>({
            url: `/${docType}/${subjectId}/create-reminder/${userId}`,
            method: 'POST',
            data: { password },
        });
        return res;
    };

    static optOut = async (rsvpId: string): Promise<void> => {
        await request<boolean>({
            url: `/Reminders/${rsvpId}/remove-reminder`,
            method: 'DELETE',
        });
    };

    static getDropTypeFromPath = (path: string): ResourceType => {
        const dropType = path
            .split('/')
            .filter((i) => i.length)[0]
            ?.toLowerCase();

        switch (dropType) {
            case 'video':
                return 'video';
            case 'merch':
                return 'collect';
            case 'podcast':
                return 'podcast';
            case 'article':
                return 'article';
            case 'album':
                return 'album';
            case 'soundtrack':
                return 'soundtrack';
            default:
                return 'video';
        }
    };

    static setReplay = (dropType: ResourceType, dropId: string, userId: string) => {
        return request<TSetReplayResponse>({
            url: `/${dropType}s/${dropId}/replay/${userId}`,
            method: 'POST',
        });
    };

    static removeReplay(id: string) {
        return request<{ count: number }>({
            url: `/Replays/${id}/un-replay`,
            method: 'DELETE',
        });
    }

    static createReplayBulk(subjectId: string, subjectType: TContentType, onDisplayEntitys: OnDisplayEntity[]) {
        return request({
            url: `/Replays/createReplayBulk`,
            method: 'POST',
            data: { subjectId, subjectType: capitalize(subjectType), onDisplayEntitys },
        });
    }

    static removeReplayBulk(replayIds: string[]) {
        return request({
            url: `/Replays/removeReplayBulk`,
            method: 'POST',
            data: { replayIds },
        });
    }

    /**
     *
     * @param event
     * @param appUser
     * @returns checks if appuser is collaborator , if yes then returns the community
     */
    static isCollaborator = (event: TDrop, appUser?: User) => {
        if (!appUser) return undefined;
        const appUserCommunities = (appUser?.userCommunities || []).map((i) => i.id);
        const collaborators = event?.collaborators?.map((i) => i.id);
        const collabortingComm = intersection(collaborators || [], appUserCommunities || []);
        if (collabortingComm.length) return event?.collaborators?.find((i) => i.id === collabortingComm[0]);
    };

    /**
     *
     * @param event
     * @param appUser
     * @returns checks if appuser has edit rights , if yes then returns the community
     */
    static canEditDrop = (event: TDrop, appUser?: User) => {
        if (!event || !appUser || !appUser.id) return undefined;

        // if the appUser is creator or owner
        if (event.userId === appUser.id) return event.community;

        // check is collaboration
        return DropModel.isCollaborator(event, appUser);
    };

    static canPublishUnpublish = (event: TDrop, appUser?: User) => {
        if (!event || !event.id || !appUser || !appUser.id) return undefined;

        // if the appUser is creator or owner
        if (event.userId === appUser.id) return event.community;
    };

    static getDropGuests = async (dropType: TContentType, dropId: string, filter?: TFilter) => {
        try {
            const { metadata, data } = await request<{ metadata: { total: number }; data: { user: User }[] }>({
                url: `/Resources/${helpers.capitalize(dropType)}/${dropId}/search-rsvp-guests`,
                method: 'GET',
                params: { searchText: filter?.term, from: filter?.skip, limit: filter?.limit },
            });
            return { total: metadata?.total ?? 0, users: data.filter((i) => !!i.user).map((i) => i.user) };
        } catch (error) {
            console.error(error);
            return { total: 0, users: [] };
        }
    };

    static getCollaborators = (drop: TDrop) => {
        const collaborators = [...(drop.collaborators || [])];
        if (drop.community) collaborators.unshift(drop.community);
        return collaborators;
    };

    static getCommunityDropsCount = async (communityId = '') => {
        const res = await request<Record<TDropType, number>>({
            url: '/Resources/dropCount',
            method: 'GET',
            params: { communityId },
        });
        return res;
    };
}

export default DropModel;
