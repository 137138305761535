import { Box, Checkbox, createStyles, FormControlLabel, IconButton, makeStyles, Theme } from '@material-ui/core';
import PgButton from 'Components/PgButton';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import SVGCIcon from 'Components/SVGComponents/SVGCIcon';
import ToastMessage, { ToastMessageProps } from 'Components/ToastMessage';
import { OptionsObject, useSnackbar } from 'notistack';
import React, { FC, useState } from 'react';
import { BORDER_BLACK } from 'Theme/themeConstants';
import MESSAGES from 'Utils/Messages';
import Papa from 'papaparse';
import { InvitationDialogProps } from '..';
import helpers from 'Utils/helpers';
import CHECK_BOX_DEFAULT from 'Assets/icons/checkbox/checkbox_default.svg';
import CHECK_BOX_SELECTED from 'Assets/icons/checkbox/checkbox_selected.svg';
import { useStoreDispatch, useStoreState } from 'Stores';
import { ANALYTICS_USER_EVENT } from 'Analytics/analyticActions';
import clsx from 'clsx';

export interface ImportCsvProps extends Pick<InvitationDialogProps, 'onUploadCsv'> { }

const MAX_FILE_SIZE = 10000;

const getEmail = (value: any) => {
    const val = Object.fromEntries(Object.entries(value).map(([k, v]) => [k?.trim(), v]));
    return (val?.email ?? val?.EMAIL ?? val?.Email) as string;
};

const ImportCsv: FC<ImportCsvProps> = (props) => {
    const { onUploadCsv } = props;
    const classes = useStyles();

    const { community } = useStoreState(({ CommunityStore: { community } }) => ({ community }));
    const dispatch = useStoreDispatch();

    const [csv, setCsv] = useState<any>();
    const [emails, setEmails] = useState<string[]>([]);
    //   const [allowAutoApprove, setAllowAutoApprove] = useState(false);
    const [csvCardData, setCsvCardData] = useState<{ name: string; total: number; totalInvalid: number; firstEmail: string; totalValid: number }>();

    const { enqueueSnackbar } = useSnackbar();
    const showToast = (message: string | ToastMessageProps, options?: OptionsObject | undefined) =>
        enqueueSnackbar(ToastMessage(typeof message === 'string' ? { title: message } : message), options);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        if (files && files.length) {
            const file = files[0];
            const fileSizeKiloBytes = file.size / 1024;
            if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                showToast(MESSAGES.FILE_SIZE_EXCEED, { variant: 'error' });
                return;
            }
            if (file.type !== 'text/csv' && file.type !== 'application/vnd.ms-excel') {
                showToast(MESSAGES.ONLY_CSV_ALLOWED, { variant: 'error' });
                return;
            }
            Papa.parse(file, {
                skipEmptyLines: true,
                header: true,
                complete(results) {
                    const invalidEmails = results.data.filter((f: any) => {
                        const isValid = helpers.validateEmail(getEmail(f) ?? '');
                        if (!isValid) return getEmail(f);
                    });
                    const emails = results.data
                        ?.filter((f: any) => {
                            const isValid = helpers.validateEmail(getEmail(f) ?? '');
                            if (isValid) return getEmail(f);
                        })
                        .map((m) => getEmail(m)) as string[];
                    if ((!emails || !emails.length) && (!invalidEmails || !invalidEmails.length)) {
                        showToast(MESSAGES.INVITE_CSV_COL_NOT_FOUND, { variant: 'error' });
                        return;
                    }
                    setEmails(emails);
                    setCsvCardData({
                        name: file.name,
                        total: results.data.length,
                        totalInvalid: invalidEmails.length ?? 2,
                        firstEmail: getEmail(results.data?.[0]) ?? '',
                        totalValid: emails.length,
                    });
                    setCsv(file);
                },
            });
        }
    };

    const handleSendClick = () => {
        if (!csv) return;
        // onUploadCsv?.(csv, allowAutoApprove);
        onUploadCsv?.(csv, true);
        if (emails.length)
            dispatch({
                type: ANALYTICS_USER_EVENT,
                data: {
                    eventName: 'COMMUNITY_INVITE',
                    context: { community, emails, inviteDate: new Date().toISOString(), communityId: community?.id },
                    source: window.location.pathname,
                },
            });
        setEmails([]);
        // setAllowAutoApprove(false);
        setCsvCardData(undefined);
        setCsv(undefined);
    };

    return (
        <div>
            {csv && csvCardData ? (
                <Box className={classes.fileCard}>
                    <Box className={classes.fileNameWrap}>
                        <PgTypo variant="caption" className={classes.fileName}>
                            {csvCardData.name ?? csv.name}
                        </PgTypo>
                        <IconButton className={classes.deleteBtn} onClick={() => setCsv(undefined)}>
                            <PgIcon icon="icon-trash" />
                        </IconButton>
                    </Box>
                    <PgTypo variant="caption" className={classes.invited}>
                        Inviting: {csvCardData.firstEmail} {csvCardData.total > 1 ? `and ${csvCardData.total - 1} others` : ''}
                    </PgTypo>
                    {csvCardData.totalInvalid ? (
                        <PgTypo variant="caption" color="error">
                            {csvCardData.totalInvalid} out of {csvCardData.total} emails are invalid.
                        </PgTypo>
                    ) : null}
                </Box>
            ) : (
                <>
                    <PgTypo variant="caption" style={{ marginBottom: 16 }}>
                        Upload a CSV. File should only contain the emails in the first column and no other information. Max size 10mb*.{' '}
                        <a download="csv-template.csv" href="./Assets/files/sample-csv.csv" className={classes.downloadBtn}>
                            Download CSV template
                        </a>
                    </PgTypo>
                    <Box className={classes.inputWrap}>
                        <SVGCIcon icon="icon-file-upload" />
                        <PgTypo variant="caption" className={classes.fieldLabel}>
                            Drop file or click here to choose file.
                        </PgTypo>
                        <input accept=".csv" title="" type="file" onChange={handleChange} className={classes.input} />
                    </Box>
                </>
            )}
            {/* <FormControlLabel
        control={
          <Checkbox
            checked={allowAutoApprove}
            onChange={(_, c) => setAllowAutoApprove(c)}
            checkedIcon={<img src={CHECK_BOX_SELECTED} alt="checkbox-selected" className={classes.checkBoxIcon} />}
            icon={<img src={CHECK_BOX_DEFAULT} alt="checkbox-default" className={clsx(classes.checkBoxIcon, classes.uncheckedIcon)} />}
          />
        }
        label={
          <PgTypo variant="overline" upperCase color="secondary" typoWeight="fontWeightBold">
            let users join community without verification process
          </PgTypo>
        }
        className={classes.labelRoot}
      /> */}
            <PgButton primary fullWidth className={classes.submitBtn} onClick={handleSendClick} disabled={!csv || !csvCardData?.totalValid}>
                send invite
            </PgButton>
        </div>
    );
};

export default ImportCsv;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputWrap: {
            position: 'relative',
            height: 120,
            border: `1px dashed ${theme.palette.secondary.main}`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        fieldLabel: { marginTop: theme.spacing(1) },
        input: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            width: '100%',
            height: 'inherit',
            zIndex: 2,
            '&:not([disabled])': {
                cursor: 'pointer',
            },
        },
        fileCard: {
            padding: theme.spacing(2),
            border: BORDER_BLACK,
        },
        fileNameWrap: {
            display: 'flex',
            gap: theme.spacing(2),
        },
        fileName: { flex: 1 },
        invited: { color: theme.palette.text.disabled, margin: theme.spacing(0.5, 0) },
        deleteBtn: { padding: 'unset' },
        submitBtn: { marginTop: theme.spacing(4), [theme.breakpoints.down('sm')]: { marginTop: theme.spacing(2.5) } },
        checkBoxIcon: { width: 20, height: 20 },
        uncheckedIcon: { filter: theme.palette.type === 'dark' ? 'invert(100%)' : undefined },
        labelRoot: { marginTop: theme.spacing(4), [theme.breakpoints.down('sm')]: { alignItems: 'flex-end', marginTop: theme.spacing(2.5) } },
        downloadBtn: { font: 'inherit', fontWeight: theme.typography.fontWeightBold, color: theme.palette.secondary.main, textDecoration: 'underline' },
    }),
);
