/* eslint-disable camelcase */
/**

        DOCS: https://developers.google.com/maps/documentation/javascript/places#find_place_from_query

        'google' namespace is came from script added to index.html
        1. <script type="text/javascript" src="https://maps.googleapis.com/maps/api/js?key=<google-api-key>&libraries=places"></script>
        2. Installing @types/googlemaps
        3. adding "types": ["googlemaps"] in compilerOptions in tsconfig.json
 */
export type TGooglePlaceSuggestCategories = '(cities)' | 'establishment' | 'address';
const RADIUS_OF_EARTH_IN_KM = 6371;
const toRadian = (angle: number) => (Math.PI / 180) * angle;
export interface TPosition {
  lat: number;
  lng: number;
}

export interface offsett {
  lenght: number;
  offset: number;
}

export interface StructuredFormatting {
  main_text: string;
  main_text_matched_substrings: offsett[];
  secondary_text: string;
}
export interface PlacesSuggestType {
  description: string;
  matched_substring: offsett[];
  place_id: string;
  reference: string;
  structured_formatting: StructuredFormatting;
  terms: { offset: number; value: string }[];
  types: string[];
}
const geoAddressFields = [
  'street_number',
  'route',
  'postal_code',
  'country',
  'administrative_area_level_1',
  'administrative_area_level_2',
  'locality',
  'sublocality_level_1',
  'sublocality_level_2',
  'route',
  'street_number',
  'opening_hours',
  'price_level',
];

const GoogleUtils = {
  geoAddressFields,

  parseLatLng: (lat: string | number, lng: string | number): TPosition => {
    return { lat: parseFloat(lat.toString()), lng: parseFloat(lng.toString()) };
  },
  /* utils functions */
  placeSuggest: (input: string, types: TGooglePlaceSuggestCategories[]) =>
    new Promise((resolve, reject) => {
      if (google) {
        const request: google.maps.places.AutocompletionRequest = {
          input,
          types,
        };
        const service = new google.maps.places.AutocompleteService();
        service.getPlacePredictions(request, (results: any) => {
          resolve(results);
        });
      }
    }),

  placeDetails: (placeId: string, fields?: Array<string>): Promise<google.maps.places.PlaceResult> =>
    new Promise((resolve, reject) => {
      if (google) {
        const request = {
          placeId,
          // fields: GoogleUtils.geoAddressFields
        };
        const service = new google.maps.places.PlacesService(document.createElement('div'));
        service.getDetails(request, (result) => {
          resolve(result);
        });
      }
    }),

  placeTypesParser: (types: string[] | undefined, typeMap: Record<string, string[]>): string[] => {
    if (!types) return [];
    const newTypes: string[] = [];
    const keys = Object.keys(typeMap);
    keys.forEach((item) => {
      const values = typeMap[item];
      values.forEach((val) => {
        const index = types.indexOf(val);
        if (index > -1) newTypes.push(item);
      });
    });
    return newTypes;
  },

  transformAddress: (place: google.maps.places.PlaceResult): Record<string, any> | undefined => {
    if (!place) return undefined;
    const addressComponents = place.address_components || [];
    type TGeoAddress = Record<typeof GoogleUtils.geoAddressFields[number], string>;
    const geoAddress: TGeoAddress = {} as TGeoAddress;

    GoogleUtils.geoAddressFields.forEach((addressField) => {
      addressComponents.forEach((addComp) => {
        if (addComp.types && addComp.types.length && addComp.types.indexOf(addressField) !== -1) geoAddress[addressField] = addComp.long_name;
      });
    });
    const shAddress = {
      placeid: place.place_id,
      full_address: place.formatted_address,
      address1: [geoAddress.administrative_area_level_1, geoAddress.route, geoAddress.sublocality_level_2]?.filter((f) => f)?.join(', '),
      state: geoAddress.administrative_area_level_1,
      city: geoAddress.locality,
      locality: geoAddress.sublocality_level_1,
      zipcode: geoAddress.postal_code,
      country: geoAddress.country,
    };
    return shAddress;
  },

  getDistance: (coord1: TPosition, coord2: TPosition): number => {
    const toRad = (x: number) => (x * Math.PI) / 180;
    const lat1 = coord1.lat;
    const lon1 = coord1.lng;
    const lat2 = coord2.lat;
    const lon2 = coord2.lng;
    const x1 = lat2 - lat1;

    // Earth's mean readius in KMs
    const R = 6371;

    const dLat = toRad(x1);
    const x2 = lon2 - lon1;
    const dLon = toRad(x2);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;

    // Distance in KMs
    return d;
  },

  formatOpeningHours: (openingHours: google.maps.places.OpeningHours | undefined) => {
    if (!openingHours) return undefined;
    const periods: any[] = [];
    openingHours?.periods?.forEach((period) => {
      const p = {
        open: period.open.time,
        close: period.close ? period.close.time : '',
        day: period.open.day,
      };
      periods.push(p);
    });
    return { periods };
  },
  haversineDistance: (location1: TPosition, location2: TPosition, isMiles = false) => {
    const x1 = location2.lat - location1.lat;
    const x2 = location2.lng - location1.lng;
    const dLat = toRadian(x1);
    const dLon = toRadian(x2);
    const lat1 = toRadian(location1.lat);
    const lat2 = toRadian(location2.lat);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const finalDistance = RADIUS_OF_EARTH_IN_KM * c;
    if (isMiles) return finalDistance / 1.60934;
    return finalDistance;
  },
  getPlaceInfo: (coordinates: TPosition): Promise<google.maps.GeocoderResult[]> =>
    new Promise((resolve, reject) => {
      const { lat, lng } = coordinates;
      if (google) {
        const service = new google.maps.Geocoder();
        service.geocode(
          {
            location: {
              lat: parseFloat(lat as unknown as string),
              lng: parseFloat(lng as unknown as string),
            },
          },
          (results) => resolve(results)
        );
      }
    }),
};

export default GoogleUtils;
