import { Box, createStyles, FormControl, InputLabel, makeStyles, Select, TextField, Theme } from '@material-ui/core';
import PgButton from 'Components/PgButton';
import PgTypo from 'Components/PgTypo';
import Listing from 'Features/ReactFormFields/TokenList/Listing';
import TokenForm from 'Features/ReactFormFields/TokenList/TokenForm';
import { Formik, FormikHelpers } from 'formik';
import useAsyncTask from 'Hooks/useAsyncTask';
import useConfirmationDialog from 'Hooks/useConfirmationDialog';
import { get } from 'lodash';
import { EGateType } from 'Models';
import { NFTDetails } from 'Models/Community/@types';
import { TDiscussion } from 'Models/Discussion/@types';
import React, { FC, useState } from 'react';
import { getFieldError } from 'react-forms';
import { THEME_PALETTE } from 'Theme/themeConstants';
import * as Yup from 'yup';

const PRIVACY_INFO = [
  { id: 0, name: 'Open', subtitle: 'Drop and conversation are visible to everyone and anyone.', value: EGateType.OPEN },
  { id: 1, name: 'Password Protect', subtitle: 'Users will need a password to unlock this conversation.', value: EGateType.PASSWORD_PROTECTED },
  { id: 2, name: 'Token Gated', subtitle: 'Only owners of this token will be able to access.', value: EGateType.NFT_GATED },
];

export interface PrivacyProps {
  discussion: TDiscussion;
  onPrivacyChange: (data: Partial<TDiscussion>) => Promise<any>;
}

const Privacy: FC<PrivacyProps> = (props) => {
  const { discussion, onPrivacyChange } = props;

  const classes = useStyles();

  const [editingToken, setEditingToken] = useState<Partial<NFTDetails> | undefined>({});
  const [toUpdateOrAdd, setToUpdateOrAdd] = useState<{ type: 'update' | 'add'; updateIndex?: number } | undefined>();

  const withConfirmationDialog = useConfirmationDialog();

  const privacyChangeRunner = useAsyncTask<{ values: Partial<TDiscussion>, formikHelpers: FormikHelpers<Partial<TDiscussion>> }>(async ({ values, formikHelpers }) => {
    await onPrivacyChange(values);
    formikHelpers.setSubmitting(false)
    let gateType = values.gateType;
    if (values.gateType === EGateType.NFT_GATED && (!values.nftGates?.length || !values.nftGates))
      gateType = EGateType.OPEN;
    formikHelpers.setValues({ ...discussion, gateType, password: values.password })
  })

  return (
    <Formik<Partial<TDiscussion>>
      initialValues={discussion}
      onSubmit={(values, formikHelpers) => privacyChangeRunner.run({ values, formikHelpers })}
      validationSchema={ValidationSchema}
      enableReinitialize
    >
      {formikProps => {
        const passwordErr = getFieldError('password', formikProps);
        const tokensDetailList: NFTDetails[] = get(formikProps, `values.nftGates`) ?? [];

        const handleAddToken = (values: Partial<NFTDetails>) => {
          const newValue =
            toUpdateOrAdd?.type === 'update' && toUpdateOrAdd.updateIndex !== undefined
              ? tokensDetailList.map((token, index) => (index === toUpdateOrAdd.updateIndex ? values : token))
              : [...tokensDetailList, values];
          formikProps?.setFieldValue('nftGates', newValue);
          setEditingToken(undefined);
          setToUpdateOrAdd(undefined);
        };

        const removeToken = (token: string) => {
          const newValue = tokensDetailList.filter((tokenItem) => tokenItem.tokenAddress !== token);
          formikProps.setFieldValue('nftGates', newValue);
        };

        return (
          <form onSubmit={formikProps.handleSubmit}>
            {PRIVACY_INFO.map((p) => (
              <PgTypo variant="caption" key={p.id}>
                <span className={classes.infoTitle}>{p.name + ' - '}</span>
                {p.subtitle}
              </PgTypo>
            ))}

            <FormControl fullWidth className={classes.selectField}>
              <InputLabel id="chat-about-dialog-privacy-selector" variant='outlined'>
                Access type
              </InputLabel>
              <Select
                value={formikProps.values.gateType}
                onChange={e => {
                  if (e.target.value !== EGateType.NFT_GATED) setToUpdateOrAdd(undefined);
                  formikProps.handleChange(e);
                }}
                onBlur={formikProps.handleBlur}
                labelId="chat-about-dialog-privacy-selector"
                name='gateType'
                variant='outlined'
                fullWidth
                native
              >
                {PRIVACY_INFO.map(m => (
                  <option value={m.value} key={m.id}>{m.name}</option>
                ))}
              </Select>
            </FormControl>

            {formikProps.values.gateType === EGateType.PASSWORD_PROTECTED && (
              <TextField
                name='password'
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.password}
                variant='outlined'
                label="Password*"
                error={!!passwordErr}
                helperText={passwordErr}
                className={classes.passwordField}
                fullWidth
              />
            )}

            {formikProps.values.gateType === EGateType.NFT_GATED && (
              <>
                {!toUpdateOrAdd && !!tokensDetailList.length && (
                  <Listing
                    tokens={formikProps.values.nftGates ?? []}
                    onEdit={(token, index) => {
                      if (formikProps.isSubmitting) return;
                      setEditingToken(token);
                      setToUpdateOrAdd({ type: 'update', updateIndex: index });
                    }}
                    onRemove={tokenAddr => {
                      if (formikProps.isSubmitting) return
                      withConfirmationDialog(() => removeToken(tokenAddr), {
                        message: 'Are you sure you want to drop your NFT gate?',
                        body: "Your members won't need to be verified before accessing Conversations and you'll lose all your gate info. ",
                      });
                    }}
                    isActionDisabled={formikProps.isSubmitting}
                  />
                )}

                {!toUpdateOrAdd && !tokensDetailList.length && (
                  <PgButton
                    disabled={formikProps.isSubmitting}
                    secondary
                    onClick={() => {
                      setEditingToken({});
                      setToUpdateOrAdd({ type: 'add' });
                    }}
                  >
                    ADD NFT GATE
                  </PgButton>
                )}

                {toUpdateOrAdd && (
                  <Box p={2.5} pt={0} border={`1px solid ${THEME_PALETTE.grey.A900}`}>
                    <TokenForm
                      editingToken={editingToken}
                      onCancel={() => {
                        setEditingToken(undefined);
                        setToUpdateOrAdd(undefined)
                      }}
                      onSubmit={handleAddToken}
                      showFormHeader={false}
                    />
                  </Box>
                )}
              </>
            )}

            <Box display='flex' justifyContent='flex-end' mt={2.5}>
              <PgButton primary type='submit' disabled={!!toUpdateOrAdd} className={classes.saveBtn}>save</PgButton>
            </Box>
          </form>
        )
      }}
    </Formik>
  );
};

export default Privacy;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoTitle: { fontWeight: theme.typography.fontWeightBold },
    selectField: { margin: theme.spacing(5, 0, 2.5) },
    passwordField: { margin: theme.spacing(2.5, 0) },
    saveBtn: { width: '100%', maxWidth: 180 },
  }),
);

const ValidationSchema = Yup.object().shape({
  password: Yup.string().when('gateType', { is: EGateType.PASSWORD_PROTECTED, then: Yup.string().required('Password required.') }),
})
