import React, { FC, memo } from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { TPosition } from 'Models/App/@types';

interface MapsViewProps {
  style?: Record<string, unknown>;
  points: TPosition | TPosition[];
  zoom?: 1 | 5 | 10 | 15 | 20;
}

const MapView: FC<MapsViewProps> = memo((props) => {
  const { style, points, zoom = 15 } = props;

  const bounds = new google.maps.LatLngBounds();
  let initialCenter: TPosition;
  if (Array.isArray(points)) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < points.length; i++) {
      bounds.extend(points[i]);
    }
    initialCenter = {
      lat: bounds.getCenter().lat(),
      lng: bounds.getCenter().lng(),
    };
  } else {
    initialCenter = points;
  }

  return (
    <div className="location-map" style={style}>
      <GoogleMap
        defaultZoom={zoom}
        center={initialCenter}
        options={{
          gestureHandling: 'cooperative',
        }}
      >
        {Array.isArray(points) ? (
          points.map((point, index) => (
            <Marker
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              position={point}
              clickable
              visible
              animation={google.maps.Animation.DROP}
            />
          ))
        ) : (
          <Marker position={points} clickable visible animation={google.maps.Animation.DROP} />
        )}
      </GoogleMap>
    </div>
  );
});

export default withGoogleMap(MapView);
