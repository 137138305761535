import React, { FC, useMemo, useState } from 'react';
import { TCommunity } from 'Models/Community/@types';
import { FollowChangeHandler, IProfileProps } from './@types';
import { useStoreActions, useStoreDispatch, useStoreState } from 'Stores';
import PgButton, { PgButtonProps } from 'Components/PgButton';
import useUserActions from 'Features/User/useUserActions';
import useAuthDialog from 'Features/Auth/useAuthDialog';
import useConfirmationDialog from 'Hooks/useConfirmationDialog';
import CommunityModel from 'Models/Community';
import { User } from 'Models/User/@types';
import UserModel, { TUserFollows } from 'Models/User';
import NFTDetailsSlider from 'Features/Auth/NFTDetailsSlider';
import { CircularProgress, makeStyles, Theme } from '@material-ui/core';
import PgTypo from 'Components/PgTypo';
import Spacer from 'Components/Spacer';
import useAsyncTask from 'Hooks/useAsyncTask';
import Menu, { MenuOption } from 'Components/Menu';
import PgIcon from 'Components/PgIcon';
import clsx from 'clsx';
import Analytics from 'Analytics';
import { ANALYTICS_USER_EVENT } from 'Analytics/analyticActions';
import { useLocation } from 'react-router-dom';
import { omit } from 'lodash';

export interface CommunityJoinButtonProps extends Omit<PgButtonProps, 'type'> {
    profile: TCommunity;
    onFollowChange?: FollowChangeHandler;
    type?: IProfileProps['type'];
}
export const CommunityJoinButton: FC<CommunityJoinButtonProps> = ({ profile, onFollowChange, type = 'community', ...restProps }) => {
    const { appUser, appConstants } = useStoreState(({ AuthStore: { appUser }, App: { appConstants } }) => ({ appUser, appConstants }));
    const { setAppUser, fetchMe } = useStoreActions(({ AuthStore: { setAppUser, fetchMe } }) => ({ setAppUser, fetchMe }));
    const { leaveCommunity, joinCommunityProfile } = useUserActions();
    const { openDialog: openAuthDialog } = useAuthDialog();
    const withConfirmationDialog = useConfirmationDialog();

    const dispatch = useStoreDispatch();
    const { pathname } = useLocation();
    const classes = useStyles();

    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

    const isGatedComm = useMemo(() => Boolean(
        (profile as TCommunity).visibility === (appConstants?.Community.visibility.NFT_GATED ?? 'NFT_GATED') &&
        (profile as TCommunity).nftGates?.length
    ),
        [profile, appConstants?.Community.visibility]
    );

    const handleLeaveCommunity = async () => {
        withConfirmationDialog(
            async () => {
                if (!appUser) return;
                await /* doAction( */ leaveCommunity(profile.followId as string, profile.name as string); //, [profile.followId as string, profile.name as string]);
                CommunityModel.getCommunityById(profile?.id, { include: ['discussions'] })
                    .then((comm) => {
                        const followsCommunities = appUser.followsCommunities?.filter((f) => f.id !== profile.id);
                        setAppUser({ ...appUser, followsCommunities } as User);
                        onFollowChange?.({ ...profile, ...comm, followersCount: Math.max((profile.followersCount || 0) - 1, 0) }, false);
                        dispatch({ type: ANALYTICS_USER_EVENT, data: { eventName: 'LEAVE_COMMUNITY', context: { community: profile, source: pathname, communityId: profile.id } } });
                    })
                    .catch((err) => {
                        setAppUser({ ...appUser, followsCommunities: [...(appUser.followsCommunities ?? []), profile] } as User);
                        onFollowChange?.(profile, false);
                    });

                // setJoinedAnchorEl(null);
            },
            {
                message: `Are you sure you want to leave ${UserModel.getUserName(profile)}?`,
                body:
                    profile.visibility === appConstants?.Community.visibility.INVITE_ONLY ?? 'INVITE_ONLY'
                        ? 'You will need an invite to join again since this is an invite only community'
                        : undefined,
            },
        );
    };
    const handleJoinCommunity = async () => {
        if (!appUser) {
            openAuthDialog();
            return;
        }
        if (!appUser.emailVerified) {
            openAuthDialog('verify-email-first', { subtitle: `You must verify your account via email before you can join a ${isGatedComm ? 'gated ' : ''}community` });
            return;
        }
        if (
            // !appUser?.publicAddress &&
            type === 'community' && isGatedComm
        ) {
            // openAuthDialog(appUser?.publicAddress ? 'unlock-gated' : 'wallet-select', {
            openAuthDialog('unlock-gated', {
                heading: `Unlock ${(profile as TCommunity).name}`,
                subtitle: '',
                body: (
                    <>
                        <NFTDetailsSlider nftGates={(profile as TCommunity).nftGates ?? []} />
                        <Spacer height={20} />
                        <PgTypo b4>In order to continue, you must connect the wallet where you own the token.</PgTypo>
                    </>
                ),
                variant: 'small',
                community: profile,
            }).then(() => {
                if (!appUser?.publicAddress) fetchMe();
            });
        } else {
            await joinCommunityProfile(profile, appUser, setAppUser, onFollowChange);
        }
    };
    const handleJoinCommunityTask = useAsyncTask(handleJoinCommunity);
    const handleLeaveCommunityTask = useAsyncTask(handleLeaveCommunity);
    const isLoading = handleJoinCommunityTask.status === 'PROCESSING' || handleLeaveCommunityTask.status === 'PROCESSING';
    const options: MenuOption[] = [
        {
            label: 'Leave community',
            onClick: handleLeaveCommunityTask.run,
        },
    ];
    const openMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchor(e.currentTarget);
    };
    const joinBtnText = useMemo(() => {
        if (type === 'community' && (profile as TCommunity).visibility === appConstants?.Community?.visibility?.NFT_GATED) {
            return profile.isFollowed ? (
                <>
                    UNLOCKED <PgIcon icon="icon-carrot-right" styleClass={clsx(classes.icon, { [classes.arrowDown]: !!anchor })} />
                </>
            ) : (
                'UNLOCK'
            );
        }
        return profile.isFollowed ? (
            <>
                JOINED <PgIcon icon="icon-carrot-right" styleClass={clsx(classes.icon, { [classes.arrowDown]: !!anchor })} />
            </>
        ) : (
            'JOIN'
        );
    }, [profile, appConstants, anchor]);
    return (
        <>
            <PgButton secondary onClick={!profile.isFollowed ? handleJoinCommunityTask.run : openMenu} {...restProps}>
                {isLoading ? <CircularProgress size={24} /> : joinBtnText}
            </PgButton>
            <Menu id="community-leave-menu" handleClose={() => setAnchor(null)} anchorEl={anchor} options={options} />
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    icon: {
        transition: 'all 300ms ease',
        marginInlineStart: 14,
        marginInlineEnd: -14,
    },
    arrowDown: {
        transform: 'rotate(90deg)',
    },
}));
