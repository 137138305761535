import { alpha, darken, StyleRules } from '@material-ui/core';
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides';
import { COLOR } from 'Theme/themeConstants';

const Switch: Partial<StyleRules<ComponentNameToClassKey['MuiSwitch']>> = {
  colorSecondary: {
    '&$checked': {
      color: COLOR.tertiary.music,
      '&:hover': {
        backgroundColor: alpha(COLOR.tertiary.music, 0.04),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    '&$checked + $track': {
      backgroundColor: COLOR.tertiary.music,
    },
  },
};

export default Switch;
