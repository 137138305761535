import AxiosUtils, { request } from 'Utils';

import { NotificationPreferences, TCount, TFilter, TNotification, TParams } from 'Models/App/@types';

import { SearchResponse, JSONType } from 'Typings/Global';

import { AxiosRequestConfig } from 'axios';

import { ChartData } from 'Models/Insights/@types';
import { InviteLink, TCommunity } from 'Models/Community/@types';
import { TPurchasedTicket } from 'Models/PurchasedTicket/@types';
import { TEvent } from 'Models/Event/@types';
import capitalize from 'lodash/capitalize';
import {
  User,
  Role,
  UserSearchAggregations,
  TUserInviteRequest,
  TUserInvite,
  TUserFollows,
  TUserFavorited,
  InvitationTypes,
  Referral,
  TUserFollowWithMeta,
  ICreateUserWEmailResponse,
} from './@types';

import { parseUser, parseUserFormData, parseReferral, parseFollowUserData } from './userParser';
import { NotificationConfigurations } from 'Features/Notifications/types';

export const isUserCommunity = (user: User): boolean => !!user.roles.find((role) => role.name === 'COMMUNITY');

export const search = async (
  input: string,

  filter?: Record<string, unknown>,
  useParser = true,
): Promise<SearchResponse<User>> => {
  let params = filter;

  if (!filter) params = {};

  params = { user: { include: 'roles' }, ...filter };

  const users = await request<SearchResponse<User>>({
    url: '/users/search',

    params: {
      term: input,

      filter: params,
    },
  }).catch(AxiosUtils.throwError);

  return {
    ...users,

    results: users.results.map((u) => ({ ...u, hit: useParser ? parseUser(u.hit) : u.hit })),
  };
};
export const searchUsersForCommunity = async (communityId: string, term?: string, filter?: TFilter) => {
  return request<{ total: number; results: Array<{ hit: User }> }>({
    url: `/users/community-follower`,
    method: 'get',
    params: {
      communityId,
      term,
      filter,
    },
  });
};
export const searchUsers = async (term?: string, filter?: TFilter) => {
  return request<{ total: number; results: Array<{ hit: User }> }>({
    url: `/users/search`,
    method: 'get',
    params: {
      term,
      filter,
    },
  });
};

export const relatedEntitySearch = async (
  term: string,
  entityFilter?: Record<string, any>,
  axiosConfig?: AxiosRequestConfig,
): Promise<SearchResponse<User, UserSearchAggregations>> => {
  const users = await request<SearchResponse<User, UserSearchAggregations>>({
    ...axiosConfig,

    url: '/users/related-entity-search',

    params: {
      term,

      entityFilter,
    },
  }).catch(AxiosUtils.throwError);

  return {
    ...users,

    results: users.results.map((u) => ({ ...u, hit: parseUser(u.hit) })),
  };
};

export const logOut = async (): Promise<Record<string, any> | undefined> => {
  let resp;

  try {
    resp = await request({
      url: '/users/logout',

      method: 'post',
    });

    // eslint-disable-next-line no-empty
  } catch (error) {}

  return resp;
};

export const getSingleUser = async (
  id: string,

  params?: TParams,
): Promise<User> => {
  const res = await request({
    url: `users/${id}`,

    params,
  }).catch(AxiosUtils.throwError);

  return parseUser(res);
};

export const fetchMe = async (filter?: TFilter): Promise<User> => {
  const user = await request<User>({
    url: '/users/me',

    params: {
      filter: {
        // include: ['roles', 'userSetting'],
        include: ['roles', 'wallet'],
      },
    },
  });

  return parseUser(user);
};

export const deleteUsers = async (args: {
  ids: string[];
}): Promise<{
  ids: string[];
}> => {
  await request({
    url: 'users/trash',

    method: 'DELETE',

    params: args,
  }).catch(AxiosUtils.throwError);

  return args;
};

export const changeRole = (ids: string[], role: Role, add = true): Promise<User> =>
  request({
    url: 'users/changeRoles',

    method: 'patch',

    params: { role, userIds: ids, add },
  });

export const postUser = async (data: Partial<User>): Promise<User> => {
  if (data.id) {
    return updateUser({ ...data, id: data.id });
  }

  const res = await request<User>({
    url: '/users',

    method: 'post',

    data: { ...parseUserFormData(data) },
  }).catch(AxiosUtils.throwError);

  return { ...data, ...parseUser(res) } as User;
};

export const updateUser = async (data: Partial<User> & Pick<User, 'id'>): Promise<User> => {
  const res = await request<User>({
    url: `/users/${data.id}`,

    method: 'patch',

    data,
    // data: { ...parseUserFormData(data) },
  }).catch(AxiosUtils.throwError);

  return { ...data, ...parseUser(res) } as User;
};

export const updateBlockStatus = (
  id: string,

  blockStatus: boolean,
): Promise<Record<string, any>> =>
  request({
    url: `users/${id}/update-block-status`,

    data: { block: blockStatus },

    method: 'post',
  });

export const getFollowers = (id: string, params?: TParams): Promise<User[]> => request<User[]>({ url: `users/${id}/followers`, params });

export const communityStats = async (id: string, startDate: string, endDate: string, granularity: string, insights: string[]): Promise<ChartData> => {
  const res = await request<ChartData>({
    // url: `/users/community-stats`,
    url: `/users/community-insights`,
    params: {
      // dateRange: {
      //   startDate,

      //   endDate,
      // },
      communityId: id,
      startDate,
      endDate,
      granularity,
      insights,
    },
  });
  return JSON.parse(JSON.stringify(res));
};

export const getUserSetting = async (id: string, params?: Record<string, any>): Promise<JSONType> => {
  const res = await request<JSONType>({
    url: `/users/${id}/userSetting`,

    method: 'GET',

    params,
  });

  return res;
};

export const getUsersIdGetInvites = async (id: string, filter?: any): Promise<TUserInvite[]> => {
  const res = await request({
    url: `/users/${id}/get-invites`,
    method: 'get',
    params: {
      filter,
    },
  });
  return res as TUserInvite[];
};

export const postUsersInviteUser = async (
  uids: string[],
  type: 'email',
  invitationTo: InvitationTypes,
  redirectUrl?: string,
  subjectId?: string,
): Promise<TUserInviteRequest[]> => {
  const res = await request({
    url: `/users/invite-user`,
    method: 'post',
    data: {
      uids,
      type,
      redirectUrl,
      invitationTo,
      subjectType: invitationTo === 'home' ? undefined : capitalize(invitationTo),
      subjectId,
    },
  });

  return res as TUserInviteRequest[];
};

export const inviteMultipleUsersToCommunity = async (
  emailIds: string[],
  type: 'email',
  communityId: string,
  redirectUrl?: string,
  autoVerify = false,
) => {
  const res = await request({
    url: '/users/invite-multiple-users',
    method: 'post',
    data: {
      emailIds,
      type,
      communityId,
      invitationTo: 'community',
      redirectUrl,
      autoVerify,
    },
  });
  return res;
};

export const deleteInvite = async (id: string) =>
  request({
    url: `/PartialReferrals/${id}/delete-invite`,
    method: 'DELETE',
  });

export const cancelInviteToCommunity = async (data: { type: 'email'; communityId: string; emailId: string; referralId: string }) =>
  request({
    url: '/users/cancel-email-invite',
    method: 'post',
    data,
  });

export const redeemReferCode = async (params: { accessToken: string; referCode: string }) =>
  request({
    url: '/users/redeem-refer-code',
    method: 'GET',
    params,
  });

/**
 * Queries follows of user.
 */
export const getUsersIdFollows = async (id: string, filter?: any): Promise<TUserFollows<User>[]> => {
  const res = await request({
    url: `/users/${id}/follows`,
    method: 'get',
    params: {
      filter,
    },
  });

  return res as TUserFollows<User>[];
};

export const postUsersIdFollowUserId = (userId: any, id: string): Promise<TUserFollows<User>> => {
  return request({
    url: `/users/${id}/follow/${userId}`,
    method: 'post',
  });
};

export const postUsersChangePassword = (oldPassword: string, newPassword: string): Promise<Record<string, any>> => {
  return request({
    url: `/users/change-password`,
    method: 'post',

    data: {
      oldPassword,
      newPassword,
    },
  });
};

export const checkIfEmailExists = (email: string): Promise<boolean> => {
  return request({
    url: `users/email-exists`,
    method: 'get',

    params: {
      email,
    },
  });
};

export const getUsersSlugExists = (slug: string): Promise<boolean> => {
  return request<boolean>({
    url: `/users/slug-exists`,
    method: 'get',
    params: {
      slug,
    },
  });
};

export const getUsersCommunity = (filter?: any): Promise<TCommunity> => {
  return request<TCommunity>({
    url: `/users/me`,
    method: 'get',
    params: {
      filter,
    },
  });
};

export const canDeactivate = async (id: string) =>
  request({
    url: `/users/can-deactivate`,
    method: 'GET',
    params: {
      id,
    },
  });
export const reActivateUser = (id: string) =>
  request({
    url: `/AppModels/request-activation`,
    method: 'POST',
    data: {
      entityId: id,
      entityType: 'user',
    },
  });

export const requestUserDeactivation = async (id: string, deleteEntity = false) =>
  request({
    url: `/AppModels/request-deactivation`,
    method: 'POST',
    data: {
      entityId: id,
      entityType: 'user',
      deleteEntity,
    },
  });

export const getPurchasedTickets = (id: string, filter?: any, when?: string): Promise<TPurchasedTicket[]> => {
  return request<TPurchasedTicket[]>({
    url: `/users/${id}/purchased-tickets`,
    method: 'get',
    params: {
      filter,
      when,
    },
  });
};

export const getFavoritesCount = (id: string, where?: any): Promise<TCount> => {
  return request<TCount>({
    url: `/users/${id}/favourites/count`,
    method: 'get',
    params: {
      where,
    },
  });
};

export function getFavorites<T>(userId: string, filter?: any): Promise<TUserFavorited<T>[]> {
  return request<TUserFavorited<T>[]>({
    url: `/users/${userId}/favourites`,
    method: 'get',
    params: {
      filter,
    },
  });
}

export const getCreatedEvent = async (userId: string, filter: TFilter) =>
  request<TEvent[]>({
    url: `users/${userId}/created-events`,
    params: { filter },
  });

export const updateAskAgain = (id: string): Promise<User> => {
  return request<User>({
    url: `/users/${id}`,
    method: 'patch',
    data: { id, dontAskPhoneRsvp: true },
  });
};

export const validatePassword = (id: string, password: string): Promise<boolean> => {
  return request<boolean>({
    url: `users/${id}/validate-password`,
    method: 'POST',
    data: { password },
  });
};

export const getInviteLink = async (data: { redirectUrl: string; communityId: string }): Promise<InviteLink> =>
  request({
    url: `/users/get-shareable-link`,
    method: 'POST',
    data,
  });

export const getCommunityReferral = async (data: { redirectUrl: string; referredEmailId?: string }): Promise<Referral> => {
  /**
   *   In future this endpoint may be used for both community invite and event invite. If so, rename this fn.
   */
  const referral = await request<Referral>({
    url: '/users/get-referral-from-link',
    method: 'GET',
    params: data,
  });
  return parseReferral(referral);
};

export const getNotifications = async (filter?: TFilter) =>
  request<TNotification[]>({
    url: '/notifications/get',
    method: 'GET',
    params: { filter },
  });

export const readNotification = async (id: string) =>
  request({
    url: '/notifications/read',
    method: 'POST',
    data: { id },
  });

export const getNotificationCount = async (params?: JSONType) =>
  request<number>({
    url: '/notifications/count',
    method: 'GET',
    params,
  });

export const markAllNotifsAsRead = async () =>
  request<{ count: number }>({
    url: 'notifications/read-bulk',
    method: 'POST',
  });

export const getNotificationPreferences = async (): Promise<NotificationPreferences> =>
  request({
    url: 'notificationPreferences/get',
    method: 'GET',
  });

export const updateNotificationPreferences = async (data: NotificationPreferences): Promise<NotificationPreferences> =>
  request({
    url: 'notificationPreferences/update',
    method: 'POST',
    data: {
      updatedObject: data,
    },
  });

export const getNotificationConfigurations = async (): Promise<NotificationConfigurations> =>
  request({
    url: 'notificationPreferences/get-configurations',
    method: 'GET',
  });

export const resetUnreadNotifsCount = async () =>
  request({
    url: '/notifications/reset-unread-count',
    method: 'POST',
  });

export const verifyUser = async (userId: string, verify: boolean) =>
  request({
    url: 'users/verify-user',
    method: 'PATCH',
    data: {
      id: userId,
      verify,
    },
  });

export const getUserFollowers = async (userId: string, term: string, limit?: number, from?: number) => {
  const res = await request<TUserFollowWithMeta>({
    url: `/users/${userId}/search-followers`,
    method: 'GET',
    params: { term, from, limit },
  });
  return parseFollowUserData(res);
};

export const getUserFollowings = async (userId: string, term: string, limit?: number, from?: number) => {
  const res = await request<TUserFollowWithMeta>({
    url: `/users/${userId}/search-following-users`,
    method: 'GET',
    params: { term, from, limit },
  });
  return parseFollowUserData(res);
};

export const getWeb3AuthToken = async (accessToken: string) =>
  request({
    url: '/token',
    method: 'GET',
    headers: {
      Authorization: accessToken,
    },
  });

export const getGoogleZKLoginLink = async () => {
  const res = await request<{ loginURL: string }>({
    url: '/users/sui-auth-link',
    method: 'GET',
  });
  return res;
}

export const googleZKLoginCallback = async (id_token: string) => {
  const res = await request<{ accessTokenId: string }>({
    url: '/users/sui-callback',
    method: 'GET',
    params: { id_token }
  });
  return res;
}

export const createUserWithEmail = async (data: { email: string } & Partial<User>) => {
  const res = await request<ICreateUserWEmailResponse>({
    url: '/users/create-user-via-email',
    method: 'POST',
    data,
  });
  return res;
};
