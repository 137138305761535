import React, { FC, useState } from 'react';
import { IFieldProps, MLFormContent, FormConfig, getFieldError } from 'react-forms';
import { Box, IconButton, FormControl, FormControlProps, FormHelperText, FormHelperTextProps } from '@material-ui/core';
import SVGCIcon from 'Components/SVGComponents/SVGCIcon';
import { createStyles, makeStyles, Theme, alpha } from '@material-ui/core/styles';
import clsx from 'clsx';
import { COLOR, THEME_PALETTE } from 'Theme/themeConstants';
import PgIcon from 'Components/PgIcon';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import { SupportedEventPlatforms } from 'Models/Event';

export interface ILinkSelectionFieldProps {
  formControlProps?: FormControlProps;
  formHelperTextProps?: FormHelperTextProps;
  helperText?: string;
  type?: 'streaming' | 'metaverse';
}
export interface ILinkSelectionProps extends IFieldProps {
  fieldProps?: ILinkSelectionFieldProps;
}

interface PlatformProps {
  id: SupportedEventPlatforms;
  icon: JSX.Element;
  selectedIcon?: JSX.Element;
  formConfig: Array<FormConfig | FormConfig[]>;
}

const getLinkInput = (label: string, helperText?: string | JSX.Element): FormConfig => ({
  type: 'url-field',
  valueKey: 'link',
  fieldProps: {
    label,
    helperText,
    InputProps: {
      endAdornment: <PgIcon icon="icon-globe" />,
    },
    required: true,
  },
});

const rondayLink: PlatformProps = {
  id: 'ronday',
  formConfig: [
    getLinkInput(
      'Ronday group invite link',
      <a href="https://www.rondayapp.com/" target="_blank" style={{ textDecoration: 'underline' }}>
        Learn more about Ronday
      </a>,
    ),
  ],
  icon: <SVGCIcon icon="icon-ronday" />,
  selectedIcon: <SVGCIcon icon="icon-ronday" />,
};

const StreamingServices: Array<PlatformProps> = [
  {
    id: 'youtube',
    icon: <SVGCIcon icon="icon-youtube" />,
    selectedIcon: <SVGCIcon icon="icon-youtube" color="secondary" iconProps={{ backgroundColor: THEME_PALETTE.common.white }} />,
    formConfig: [getLinkInput('YouTube Live Link')],
  },
  {
    id: 'twitch',
    icon: <SVGCIcon icon="icon-twitch" />,
    selectedIcon: <SVGCIcon icon="icon-twitch" color="contrast" />,
    formConfig: [getLinkInput('Twitch Link')],
  },
  {
    id: 'vimeo',
    icon: <SVGCIcon icon="icon-vimeo" />,
    selectedIcon: <SVGCIcon icon="icon-vimeo" color="secondary" iconProps={{ backgroundColor: THEME_PALETTE.common.white }} />,
    formConfig: [getLinkInput('Vimeo Video Link')],
  },
  {
    id: 'clubhouse',
    icon: <SVGCIcon icon="icon-clubhouse" />,
    selectedIcon: <SVGCIcon icon="icon-clubhouse" />,
    formConfig: [getLinkInput('Clubhouse Drop In Link')],
  },
  {
    id: 'zoom',
    icon: <SVGCIcon icon="icon-zoom" />,
    selectedIcon: (
      <SVGCIcon
        icon="icon-zoom"
        iconProps={{
          backgroundColor: THEME_PALETTE.common.white,
          iconColor: 'secondary',
        }}
      />
    ),
    formConfig: [
      getLinkInput('Zoom Web Link'),
      // [
      //   {
      //     type: 'phone',
      //     valueKey: 'phone',
      //     fieldProps: {
      //       countryCodeLabel: 'Join by Phone',
      //       phoneLabel: ' ',
      //       emptyItem: true,
      //       emptyItemText: 'Code',
      //       countryCodeFormControlProps: {},
      //       countryCodeProps: {
      //         disableUnderline: true,
      //         fullWidth: true,
      //         style: {
      //           backgroundColor: THEME_PALETTE.grey.A200,
      //           padding: '0 14px',
      //           borderRadius: 4,
      //           borderBottom: 'none',
      //           margin: 0,
      //         },
      //       },
      //       phoneNumberProps: {
      //         variant: 'outlined',
      //         fullWidth: true,
      //       },
      //     },
      //   },
      //   {
      //     type: 'text',
      //     valueKey: 'passcode',
      //     fieldProps: {
      //       label: 'Passcode/ Pin',
      //     },
      //   },
      // ],
    ],
  },
  {
    id: 'global',
    icon: <PgIcon icon="icon-globe" color="primary" />,
    selectedIcon: <PgIcon icon="icon-globe" color="contrast" />,
    formConfig: [getLinkInput('Event Streaming Link')],
  },
  rondayLink,
];

const metaversePlatforms: PlatformProps[] = [
  {
    id: 'nowhere',
    formConfig: [getLinkInput('Nowhere Link')],
    icon: <SVGCIcon icon="icon-nowhere" />,
    selectedIcon: <SVGCIcon icon="icon-nowhere" />,
  },
  {
    id: 'spatial',
    formConfig: [getLinkInput('Spatial Link')],
    icon: <SVGCIcon icon="icon-spatial" />,
    selectedIcon: <SVGCIcon icon="icon-spatial" />,
  },
  {
    id: 'sandbox',
    formConfig: [getLinkInput('Sandbox Link')],
    icon: <SVGCIcon icon="icon-sandbox" />,
    selectedIcon: <SVGCIcon icon="icon-sandbox" />,
  },
  rondayLink,
];

const prefixFormElementsKey = (formConfig: Array<FormConfig | FormConfig[]>, valueKey: string): Array<FormConfig | FormConfig[]> => {
  // TODO Fix this logick=
  const items = formConfig.map((item) => {
    if (isArray(item)) return item.map((t) => ({ ...t, valueKey: `${valueKey}.${t.valueKey}` }));
    return { ...item, valueKey: `${valueKey}.${item.valueKey}` };
  });
  return items;
};
const LinkSelection: FC<ILinkSelectionProps> = (props) => {
  const { fieldConfig, formikProps, fieldProps = {} as ILinkSelectionFieldProps } = props;
  const { formControlProps, formHelperTextProps, helperText, type = 'streaming' } = fieldProps;
  const valueKey = fieldConfig?.valueKey || '';
  const value = formikProps?.values[valueKey] || {};
  const providerId = value.provider;
  const [buttonHoveredOver, setButtonHoveredOver] = useState<string | undefined>();

  const [streamItem, setStreamItem] = useState<PlatformProps | undefined>(
    find(type === 'metaverse' ? metaversePlatforms : StreamingServices, { id: providerId }),
  );
  const classes = useStyles();

  const handleSelection = (item: PlatformProps) => () => {
    setStreamItem(item);
    if (formikProps) formikProps.setFieldValue(`${valueKey}`, { ...formikProps.values[valueKey], provider: item.id });
  };

  const fieldError = formikProps ? getFieldError(`${valueKey}.provider`, formikProps) : '';

  return (
    <FormControl fullWidth error={!!fieldError} {...formControlProps}>
      <Box>
        {(type === 'metaverse' ? metaversePlatforms : StreamingServices).map((item) => (
          <IconButton
            className={clsx(classes.button, streamItem?.id === item.id ? classes.buttonSelected : '')}
            key={item.id}
            onClick={handleSelection(item)}
            onMouseLeave={() => setButtonHoveredOver(undefined)}
            onMouseOver={() => setButtonHoveredOver(item.id)}
          >
            {streamItem?.id === item.id || buttonHoveredOver === item.id ? item.selectedIcon : item.icon}
          </IconButton>
        ))}
      </Box>
      {streamItem && (
        <Box className={classes.formContainer}>
          <MLFormContent
            schema={prefixFormElementsKey(streamItem.formConfig, valueKey)}
            formikProps={formikProps}
            formId="stream-link-component"
            settings={{ verticalSpacing: 0.1 }}
          />
        </Box>
      )}
      <FormHelperText {...formHelperTextProps} error={!!fieldError}>
        {fieldError || helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default LinkSelection;

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    button: {
      borderRadius: 0,
      height: 60,
      width: 60,
      border: `1px solid ${alpha(theme.palette.secondary.main, 0.5)}`,
      marginRight: 15,
      '&:hover': {
        backgroundColor: COLOR.secondary.blue,
      },
    },
    buttonSelected: {
      backgroundColor: theme.palette.secondary.main,
    },
    formContainer: {
      marginTop: 40,
    },
  }),
);
