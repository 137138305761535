import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import reduxActionMapping from './actionMapping';

const analyticsMap: Middleware = (store: MiddlewareAPI) => (next: Dispatch) => (action: any) => {
  next(action);
  reduxActionMapping(action, store);
  return;
};

export default analyticsMap;
