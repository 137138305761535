import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { FONT_FAMILY, FONT_WEIGHTS } from './themeConstants';

const typography: TypographyOptions = {
  fontFamily: FONT_FAMILY.primary,
  ...FONT_WEIGHTS,
  // Ex h1
  h1: {
    fontSize: '4.5rem',
    lineHeight: '5rem',
    fontFamily: FONT_FAMILY.secondary,
    fontWeight: FONT_WEIGHTS.fontWeightBold,
  },
  // Ex h2
  h2: {
    fontSize: '3rem',
    lineHeight: '3.5rem',
    fontFamily: FONT_FAMILY.secondary,
    fontWeight: FONT_WEIGHTS.fontWeightBold,
  },
  // Ex h3
  h3: {
    fontSize: '2rem',
    lineHeight: '40px',
    fontFamily: FONT_FAMILY.secondary,
    fontWeight: FONT_WEIGHTS.fontWeightBold,
  },
  // Ex h4
  h4: {
    fontSize: '1.75rem',
    lineHeight: '2rem',
    fontFamily: FONT_FAMILY.secondary,
    fontWeight: FONT_WEIGHTS.fontWeightBold,
  },
  // Ex h5
  h5: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    fontFamily: FONT_FAMILY.secondary,
    fontWeight: FONT_WEIGHTS.fontWeightRegular,
  },
  // Ex h6
  h6: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    fontFamily: FONT_FAMILY.secondary,
    fontWeight: FONT_WEIGHTS.fontWeightBold,
  },
  // Ex h7
  subtitle1: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    fontFamily: FONT_FAMILY.secondary,
    fontWeight: FONT_WEIGHTS.fontWeightRegular,
  },
  // Ex c1
  subtitle2: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontFamily: FONT_FAMILY.secondary,
    fontWeight: FONT_WEIGHTS.fontWeightBold,
  },
  // b2
  body1: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontFamily: FONT_FAMILY.primary,
    fontWeight: FONT_WEIGHTS.fontWeightRegular,
  },
  // Ex b1
  body2: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontFamily: FONT_FAMILY.secondary,
    fontWeight: FONT_WEIGHTS.fontWeightRegular,
  },
  // b4
  caption: {
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
    fontFamily: FONT_FAMILY.primary,
    fontWeight: FONT_WEIGHTS.fontWeightRegular,
  },
  // b6
  overline: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontFamily: FONT_FAMILY.primary,
    fontWeight: FONT_WEIGHTS.fontWeightRegular,
    textTransform: 'initial',
  },
  // Buttons text
  button: {
    fontFamily: FONT_FAMILY.secondary,
    fontWeight: FONT_WEIGHTS.fontWeightBold,
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
};

export default typography;
