import { makeStyles, TextFieldProps } from '@material-ui/core';
import usePasswordField from 'Components/usePasswordField';
import { InputWithValidStatusFieldProps } from 'Features/ReactFormFields/InputWithValidStatusField';
import UserModel from 'Models/User';
import { RowSchema } from 'react-forms';
import helpers from 'Utils/helpers';
import * as Yup from 'yup';

export const useIdentityVerifyConfig = () => {
  const classes = styles();
  const validationSchema = Yup.object({
    password: Yup.string().required('Password is required to verify your identity'),
  });
  const passwordField = usePasswordField('password', 'Password', false, {
    placeholder: 'Password',
    className: classes.inputField,
  });
  const config: RowSchema = [passwordField];
  return { validationSchema, config };
};

export const useChangeEmailConfig = () => {
  const classes = styles();
  const validationSchema = Yup.object({
    email: Yup.string().email('Email must be of format: name@domain.com').required('Please enter your new email'),
    confirmEmail: Yup.string()
      .email('Email must be of format: name@domain.com')
      .required('Please confirm your email by typing it here again.')
      .oneOf([Yup.ref('email'), ''], "Emails don't match"),
  });
  const config: RowSchema = [
    {
      type: 'input-w-status',
      valueKey: 'email',
      fieldProps: {
        label: 'New email address',
        showStatusIcon: false,
        TextFieldCustomisationProps: {
          fullWidth: true,
          placeholder: 'Email address',
          variant: 'outlined',
        },
        validText: 'Email address available',
        invalidText: 'Email address in use',
        isValid: async (text: string) => {
          const data = await UserModel.checkIfEmailExists(text);
          return !data;
        },
      } as InputWithValidStatusFieldProps['fieldProps'],
    },
    {
      type: 'text',
      valueKey: 'confirmEmail',
      fieldProps: {
        placeholder: 'Email address',
        label: 'Confirm email address',
        className: classes.inputField,
        fullWidth: true,
        variant: 'outlined',
      } as TextFieldProps,
    },
  ];
  return { validationSchema, config };
};

const styles = makeStyles((theme) => ({
  inputField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
}));
