import React, { FC } from 'react';
import { IFieldProps } from 'react-forms';
import OTP from 'react-otp-input';

export interface OtpInputProps extends IFieldProps {}

const OtpInput: FC<OtpInputProps> = (props) => {
  const value = props.formikProps?.values[props.fieldConfig?.valueKey || ''];
  // const error = !!props.formikProps?.errors['email']
  // const errorText = props.formikProps?.errors['email'] || ''
  const handleChange = (value: any) => {
    props.formikProps?.setFieldValue(props.fieldConfig?.valueKey || '', value);
  };
  return (
    <OTP
      numInputs={6}
      onChange={handleChange}
      value={value}
      shouldAutoFocus
      containerStyle={{justifyContent: 'center'}}
      focusStyle={{
        // backgroundColor: alpha('#707070', 0.4),
        backgroundColor: '#000',
        height: 48,
        width: 48,
        color: '#fff',
        outline: '1px solid #fff',
        margin: '0px 4px',
        fontSize: 16,
        fontFamily: 'Nue Plak',
      }}
      inputStyle={{
        // backgroundColor: alpha('#707070', 0.4),
        backgroundColor: '#000',
        height: 48,
        width: 48,
        border: 'none',
        color: '#fff',
        margin: '0px 4px',
        fontSize: 16,
        fontFamily: 'Nue Plak',
        outline: '1px solid #fff',
      }}
    />
  );
};

export default OtpInput;
