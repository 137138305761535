import React, { FC, useMemo, useState } from 'react';
import { Box, makeStyles, Link as MUILink } from '@material-ui/core';
import { ReactForm } from 'react-forms';
import { useStoreActions, useStoreState } from 'Stores';
import { FormikHelpers } from 'formik';
import { useAuth } from 'Features/Auth/useAuth';
import Typo from 'Components/Typo';
import { Link } from 'react-router-dom';
import { useChangeEmailConfig, useIdentityVerifyConfig } from './config';
import UserModel from 'Models/User';
import PgTypo from 'Components/PgTypo';
import EmailSentDialog from 'Features/Auth/SignUp/EmailSentDialog';

interface EmailEditMultiStepFormProps {}
type Steps = 'verify-identity' | 'update-email' | 'end';
const EmailEditMultiStepForm: FC<EmailEditMultiStepFormProps> = (props) => {
  const classes = styles();
  const [step, setStep] = useState<Steps>('verify-identity');
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
  const { setAppUser } = useStoreActions(({ AuthStore: { setAppUser } }) => ({ setAppUser }));
  const configObject = {
    'verify-identity': useIdentityVerifyConfig(),
    'update-email': useChangeEmailConfig(),
  };
  const { requestEmailVerification } = useAuth();
  const onSubmit = async (values: unknown, formikHelpers: FormikHelpers<unknown>) => {
    switch (step) {
      default:
      case 'verify-identity': {
        if (appUser) {
          const { password } = values as { password: string };
          const isVerified = await UserModel.validatePassword(appUser.id, password);
          if (isVerified) {
            setStep('update-email');
          } else {
            formikHelpers.setErrors({
              password: 'Unable to verify identity. Please check the password you have entered.',
            });
          }
        }
        break;
      }
      case 'update-email': {
        const { email, error } = values as { email: string; error: boolean };
        if (error) {
          formikHelpers.setErrors({
            email: 'This email is already exist.',
          });
          return;
        }
        if (appUser) {
          const data = await UserModel.updateUser({ id: appUser.id, email, emailVerified: false });
          if (data) {
            setAppUser({ ...appUser, email: email.toLowerCase(), emailVerified: false });
            await requestEmailVerification(email);
            setStep('end');
          }
        }
        setStep('end');
        break;
      }
    }
  };
  const { title, subtitle } = useMemo(() => {
    switch (step) {
      case 'verify-identity':
        return {
          title: 'Change Email Address',
          subtitle: `We first need to verify it's you. 
Please enter your password to continue.`,
        };
      case 'update-email':
        return {
          title: 'Change Email Address',
          subtitle:
            "Enter your new email address to continue. You will need to respond to a verification email we'll send to this address to continue using your Playground account.",
        };
      default:
      case 'end':
        return {
          title: 'Almost there!',
          subtitle: `We have sent you a link to verify this change. 
Please check your email.`,
        };
    }
  }, [step]);
  return step !== 'end' ? (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" position="relative">
        <PgTypo align="center" color="primary" variant="h6" style={{ marginBottom: 12 }}>
          {title}
        </PgTypo>
        <PgTypo align="center" variant="caption" style={{ letterSpacing: '0.02em' }}>
          {subtitle}
        </PgTypo>
      </Box>
      <Box mt={3}>
        <ReactForm
          config={configObject[step].config}
          formId={step}
          validationSchema={configObject[step].validationSchema}
          actionConfig={{
            submitButtonLayout: 'fullWidth',
            submitButtonText: 'CONTINUE',
            submitButtonProps: {
              color: 'secondary',
            },
            containerClassNames: classes.buttonContainer,
          }}
          onSubmit={onSubmit}
        />
      </Box>
    </>
  ) : (
    <EmailSentDialog />
  );
};

export default EmailEditMultiStepForm;

const styles = makeStyles((theme) => ({
  buttonContainer: {
    // marginTop: 40,
    // '& > button': {
    //   backgroundColor: theme.palette.secondary.main,
    //   color: `${theme.palette.text.secondary} !important`,
    //   border: 'none',
    //   fontWeight: 700,
    //   fontSize: '14px',
    // },
  },
}));
