import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import DialogWrapper from 'Dialogs';
import Typo from 'Components/Typo';

export interface NotAvailableProps {
    open: boolean
    message: string
    onClose: () => void
}

const NotAvailable: FC<NotAvailableProps> = ({ open, message, onClose }) => {

    return (
        <DialogWrapper disableBackdropClick={false} open={open} onBackdropClick={onClose}>
            <Box p={5}>
                <Typo contrast weight='bold'>{message}</Typo>
            </Box>
        </DialogWrapper>
    )
}


export default NotAvailable;