import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { MeetingProvider, useMeeting, useParticipant } from '@videosdk.live/react-sdk';
import ReactPlayer from 'react-player';
import { TDiscussion } from 'Models/Discussion/@types';
import DiscussionModel from 'Models/Discussion';
import { useStoreState } from 'Stores';
import UserModel from 'Models/User';
import { Box, IconButton, Theme, Tooltip, alpha, createStyles, makeStyles } from '@material-ui/core';
import { Mic, MicOff, ScreenShare, StopScreenShare, Videocam, VideocamOff } from '@material-ui/icons';
import PgButton from 'Components/PgButton';
import PgTypo from 'Components/PgTypo';
import clsx from 'clsx';
import useLayoutQuery from 'Hooks/useLayoutQuery';
import { useLocation } from 'react-router';
import { history } from 'App';

function ParticipantView(props: { participantId: string, isScreenShareOn?: boolean }) {
    const micRef = useRef<HTMLAudioElement>(null);
    const { webcamStream, micStream, webcamOn, micOn, isLocal, displayName, ...rest } = useParticipant(props.participantId);

    const videoStream = useMemo(() => {
        if (webcamOn && webcamStream) {
            const mediaStream = new MediaStream();
            mediaStream.addTrack(webcamStream.track);
            return mediaStream;
        }
    }, [webcamStream, webcamOn]);

    useEffect(() => {
        if (micRef.current) {
            if (micOn && micStream) {
                const mediaStream = new MediaStream();
                mediaStream.addTrack(micStream.track);

                micRef.current.srcObject = mediaStream;
                micRef.current.play().catch((error) => console.error('videoElem.current.play() failed', error));
            } else {
                micRef.current.srcObject = null;
            }
        }
    }, [micStream, micOn]);

    const classes = useStyles();

    const { width, ref } = useLayoutQuery();

    return (
        <div ref={ref} className={classes.videoWrap} style={{ display: props.isScreenShareOn ? 'none' : undefined }}>
            <audio ref={micRef} autoPlay playsInline muted={isLocal} />
            {props.isScreenShareOn ? null : (
                <>
                    {webcamOn ? (
                        <ReactPlayer
                            playsinline // very very imp prop
                            pip={false}
                            light={false}
                            controls={false}
                            muted={true}
                            playing={true}
                            url={videoStream}
                            height="100%"
                            width="100%"
                            style={{ transform: isLocal ? 'rotateY(180deg)' : undefined }}
                            onError={(err: any) => {
                                console.log(err, 'participant video error');
                            }}
                        />
                    ) : (
                        <PgTypo contrast typoWeight="fontWeightBold" style={{ fontSize: width / 15 }}>
                            {displayName}
                        </PgTypo>
                    )}
                    <Box className={clsx(classes.participantNameTag, 'participantNameTag')}>
                        {!micOn && <MicOff fontSize="small" color="error" />}
                        <PgTypo b4 contrast>
                            {displayName}
                        </PgTypo>
                    </Box>
                </>
            )}
        </div>
    );
}

const ScreenShareView: FC<{ presenterId: string }> = ({ presenterId }) => {
    const { screenShareStream, screenShareOn } = useParticipant(presenterId);

    const videoStream = useMemo(() => {
        if (screenShareOn && screenShareStream) {
            const mediaStream = new MediaStream();
            mediaStream.addTrack(screenShareStream.track);
            return mediaStream;
        }
    }, [screenShareStream, screenShareOn]);

    return (
        <Box>
            {screenShareOn && (
                <ReactPlayer
                    playsinline // very very imp prop
                    pip={false}
                    light={false}
                    controls={false}
                    muted={true}
                    playing={true}
                    url={videoStream}
                    height="100%"
                    width="100%"
                    onError={(err: any) => {
                        console.log(err, 'participant video error');
                    }}
                />
            )}
        </Box>
    );
};

const withToolTip = (child: JSX.Element, title: string) => <Tooltip title={title}>{child}</Tooltip>;

function MeetingView() {
    const [joined, setJoined] = useState<'JOINING' | 'JOINED'>();
    //Get the method which will be used to join the meeting.
    //We will also get the participants list to display all participants
    const {
        join,
        participants,
        leave,
        toggleMic,
        toggleWebcam,
        localMicOn,
        localWebcamOn,
        toggleScreenShare,
        localScreenShareOn,
        presenterId,
        ...rest
    } = useMeeting({
        //callback for when meeting is joined successfully
        onMeetingJoined: () => {
            setJoined('JOINED');
        },
    });
    const joinMeeting = () => {
        setJoined('JOINING');
        join();
    };

    const leaveMeeting = () => {
        leave();
        setJoined(undefined);
    };

    const { search, pathname } = useLocation();

    useEffect(() => {
        const unblock = history.block((location, action) => {
            if (location.pathname !== pathname || location.search !== search)
                leaveMeeting();
        });

        return () => {
            unblock();
        };
    }, [])

    const showScreenShareButton = useMemo(() => (presenterId ? (localScreenShareOn ? true : false) : true), [presenterId, localScreenShareOn]);

    const classes = useStyles()

    return (
        <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' height='100%' width='100%'>
            {joined && joined == 'JOINED' ? (
                <>
                    {presenterId ? (
                        <ScreenShareView presenterId={presenterId} />
                    ) : null}
                    <Box width='100%'>
                        <Box className={clsx({ [classes.videoWrapGridLayout]: [...participants.keys()].length > 1 })}>
                            {[...participants.keys()].map((participantId) => (
                                <ParticipantView participantId={participantId} key={participantId} isScreenShareOn={!!presenterId} />
                            ))}
                        </Box>
                    </Box>

                    <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
                        {withToolTip(
                            <IconButton onClick={() => toggleMic()}>{localMicOn ? <Mic /> : <MicOff />}</IconButton>,
                            localMicOn ? 'Mute' : 'Unmute'
                        )}
                        {withToolTip(
                            <IconButton onClick={() => toggleWebcam()}>{localWebcamOn ? <Videocam /> : <VideocamOff />}</IconButton>,
                            `${localWebcamOn ? 'Stop' : 'Start'} Video`
                        )}
                        {showScreenShareButton && withToolTip(
                            <IconButton onClick={() => toggleScreenShare()}>{localScreenShareOn ? <StopScreenShare /> : <ScreenShare />}</IconButton>,
                            localScreenShareOn ? 'Stop Share' : 'Screen Share'
                        )}
                        <PgButton quaternary underlined className={classes.leaveBtn} onClick={leaveMeeting}>
                            Leave
                        </PgButton>
                    </Box>
                </>
            ) : (
                <>
                    <PgTypo align='center' h6 fontFamily='primary' style={{ marginBottom: 28 }}>Start the talk!</PgTypo>
                    <PgButton primary onClick={joinMeeting} disabled={joined === 'JOINING'}>Join voice</PgButton>
                </>
            )}
        </Box>
    );
}
const VideoChat: FC<TDiscussion> = (props) => {
    const { roomId } = props;
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
    const [roomAuthToken, setRoomAuthToken] = useState<string>();
    useEffect(() => {
        DiscussionModel.getVideoChatAuthToken().then(setRoomAuthToken);
    }, []);
    if (!roomAuthToken || !roomId) return null;
    return (
        <MeetingProvider
            config={{
                meetingId: roomId,
                micEnabled: true,
                webcamEnabled: false,
                name: UserModel.getUserName(appUser),
                maxResolution: 'hd',
                mode: 'CONFERENCE',
            }}
            token={roomAuthToken}
        >
            <MeetingView />
        </MeetingProvider>
    );
};
export default VideoChat;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        videoWrap: {
            position: 'relative',
            background: theme.palette.common.black,
            width: '100%',
            aspectRatio: '796 / 454.250',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': { '& .participantNameTag': { display: 'flex' } },
        },
        participantNameTag: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            backgroundColor: alpha(theme.palette.common.black, 0.4),
            display: 'none',
            alignItems: 'center',
            gap: theme.spacing(1),
            padding: theme.spacing(1),
        },
        // videoWrap: {
        //   '& div': { aspectRatio: 2, width: '100%' },
        // },
        videoWrapGridLayout: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: theme.spacing(2),
            [theme.breakpoints.down('sm')]: { display: 'block' },
        },
        leaveBtn: { color: theme.palette.error.main },
    }),
);
