import React, { useState, type FC, PropsWithChildren } from 'react';
import { Box, BoxProps, ButtonBase, Collapse, Theme, createStyles, makeStyles } from '@material-ui/core';
import PgIcon, { PgIconProps } from 'Components/PgIcon';
import clsx from 'clsx';
import PgTypo, { PgTypoProps } from 'Components/PgTypo';
import { BORDER_BLACK } from 'Theme/themeConstants';

interface CollapsableContainerProps {
    title?: string
    containerProps?: BoxProps;
    pgIconProps?: PgIconProps;
    pgTypoProps?: PgTypoProps;
}
const CollapsableContainer: FC<PropsWithChildren<CollapsableContainerProps>> = ({ children, title, containerProps, pgIconProps, pgTypoProps }) => {

    const [isOpen, setIsOpen] = useState(true);

    const classes = useStyles({ isOpen });

    return (
        <Box width={'100%'} {...containerProps}>
            <Box borderBottom={BORDER_BLACK} borderTop={BORDER_BLACK} paddingY={2.5}>
                <Box component={ButtonBase} className={classes.titleWrap} onClick={() => setIsOpen(!isOpen)}>
                    {title ? <PgTypo {...pgTypoProps} b2>{title}</PgTypo> : null}
                    <PgIcon icon="icon-arrow-next" size="small" styleClass={clsx(classes.aboutIcon, { [classes.aboutIconExpand]: !!isOpen })} {...pgIconProps} />
                </Box>
                <Collapse in={!!isOpen}>
                    {children}
                </Collapse>
            </Box>
        </Box>
    );
};

export default CollapsableContainer;


const useStyles = makeStyles<Theme, { isOpen: boolean }>((theme) =>
    createStyles({
        titleWrap: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: (props) => theme.spacing(props?.isOpen ? 2 : 0),
            cursor: 'pointer',
        },
        aboutIcon: { transform: 'rotate(0deg)', transition: '300ms ease-in', cursor: 'pointer' },
        aboutIconExpand: { transform: 'rotate(90deg)' },
    }),
);
