import { action, Action, thunk, Thunk } from 'easy-peasy';
import { TicketTier } from 'Models/Event/ticketing/@types';
import Web3Model from 'Models/Web3';
import { INFT } from 'Models/Web3/@types';

export interface NFTListingState {
    nfts: INFT[];
    setNfts: Action<NFTListingState, INFT[]>;
    fetchNfts: Thunk<NFTListingState, { publicAddr: string; chain: string | number; blockchain: TicketTier['blockchain'] }>;
}

const NFTListingStore: NFTListingState = {
    nfts: [],

    setNfts: action((state, payload) => {
        state.nfts = payload;
    }),

    fetchNfts: thunk(async (actions, { publicAddr, chain, blockchain }) => {
        const res = blockchain === 'sui' ? await Web3Model.getSuiNfts(publicAddr) : await Web3Model.getWalletNFT(publicAddr, chain);
        actions.setNfts(Array.isArray(res) ? res : res.result ?? []);
    }),
};

export default NFTListingStore;
