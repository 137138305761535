import React, { FC, useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { TBill } from 'Models/Transactions/@types';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { TEvent } from 'Models/Event';
import DialogWrapper from '..';
import TicketingEventCard from '../../Features/Ticketing/TicketingEventCard';
import EventTicketForm, { EventTicketFormProps } from '../../Forms/Ticketing/EventTicketForm';
import StripeForm, { StripeFormProps } from '../../Forms/Ticketing/StripeForm';
import CancelTransaction, { CancelTransactionProps } from '../../Forms/Ticketing/CancelTransaction';
import TransactionConfirmation, { TransactionConfirmationProps } from '../../Forms/Ticketing/TransactionConfirmation';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

type FormStateType = 'eventRegistration' | 'cardDetail' | 'transactionCancel' | 'transactionConfirmation';
export interface TicketingDialogProps {
  open: boolean;
  event: TEvent;
  bill: TBill | null;
  numTickets?: number;
  handleCancelTransaction?: () => void;
  handleClose: () => void;
  formState: FormStateType;
  // handleRegister: (data: Partial<EventTicketFormData>) => Promise<void>
  EventRegistrationFormProps: EventTicketFormProps;
  StripeFormProps: StripeFormProps;
  CancelTransactionProps: CancelTransactionProps;
  TransactionConfirmationProps: TransactionConfirmationProps;
}

const URL = 'https://13047a08779c.ngrok.io';

const TicketingDialog: FC<TicketingDialogProps> = (props) => {
  const {
    open,
    bill,
    handleClose,
    event,
    numTickets = 1,
    handleCancelTransaction,
    formState,
    EventRegistrationFormProps,
    StripeFormProps,
    CancelTransactionProps,
    TransactionConfirmationProps,
  } = props;
  const classes = useStyles({});
  // const stripe = useStripe();
  // const elements = useElements();
  // const [clientSecret, setClientSecret] = useState('');
  const { isDeviceSm } = useMediaQuery();
  // useEffect(() => {
  // window
  //     .fetch(URL + "/api/StripeAccounts/createPaymentIntent", {
  //         method: "POST",
  //         headers: {
  //             "Content-Type": "application/json",
  //             "Authorization": "n10S6rkJFpnpCW1JdMpCgpAVyEjp4zqyblvTlT64EBOdVEAhhPWuQgA8EfKONFSU"
  //         },
  //         body: JSON.stringify({ amount: 200 })
  //     })
  //     .then(res => {
  //         return res.json();
  //     })
  //     .then(data => {
  //         console.log("data", data)
  //         setClientSecret(data);
  //     });
  // }, []);

  // const initialPayment: StripeFormProps['onSubmit'] = async (form) => {
  //   if (!elements || !stripe || !form.stripeCard) return;

  //   const payload = await stripe.confirmCardPayment(clientSecret, {
  //     payment_method: {
  //       card: form.stripeCard,
  //     },
  //   });
  //   console.log('payload', payload);
  // };

  const getFormComponent = useCallback(
    (_formState: FormStateType) => {
      if (_formState === 'cardDetail') return <StripeForm {...StripeFormProps} />;
      if (_formState === 'eventRegistration') return <EventTicketForm {...EventRegistrationFormProps} />;
      if (_formState === 'transactionCancel') return <CancelTransaction {...CancelTransactionProps} />;
      return <TransactionConfirmation {...TransactionConfirmationProps} />;
    },
    [StripeFormProps, EventRegistrationFormProps, CancelTransactionProps, TransactionConfirmationProps]
  );

  return (
    <DialogWrapper open={open} className={classes.main} classes={{ paper: classes.paper }} variant="light" onClose={handleClose} scroll="body">
      {formState === 'transactionCancel' ? (
        <Box width={isDeviceSm ? '100vw' : 920} height={600}>
          <CancelTransaction {...CancelTransactionProps} />
        </Box>
      ) : (
        <Box display="flex">
          {!isDeviceSm ? <TicketingEventCard bill={bill} totalTickets={numTickets} event={event} variant={EventRegistrationFormProps.type} /> : null}
          <Box width="100%">
            <IconButton onClick={formState === 'cardDetail' ? handleCancelTransaction : handleClose} className={classes.closeIcon}>
              <Close />
            </IconButton>
            {getFormComponent(formState)}
          </Box>
        </Box>
      )}
    </DialogWrapper>
  );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
  main: {
    zIndex: `${theme.zIndex.modal + 10} !important` as CreateCSSProperties<any>['zIndex'],
  },
  paper: {
    maxWidth: 900,
    minWidth: 900,
    [theme.breakpoints.down('sm')]: {
      minWidth: `100%`,
    },
  },
  closeIcon: {
    color: theme.palette.common.black,
    position: 'absolute',
    top: 22,
    right: 16,
    zIndex: 12,
  },
}));

export default TicketingDialog;
