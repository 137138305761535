import React, { FC } from 'react';
import { ButtonProps, CircularProgress, CircularProgressProps } from '@material-ui/core';
import PgButton from './PgButton';

export interface SubmitButtonProps extends ButtonProps {
  loading: boolean;
  loaderColor?: CircularProgressProps['color'];
}

const SubmitButton: FC<SubmitButtonProps> = ({ loading, loaderColor, children, disabled, ...buttonProps }) => (
  <PgButton primary disabled={loading || disabled} {...buttonProps}>
    {loading ? <CircularProgress color={loaderColor} size={24} /> : children}
  </PgButton>
);

export default SubmitButton;
