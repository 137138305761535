import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import PgButton from 'Components/PgButton';
import PgTypo from 'Components/PgTypo';
import Spacer from 'Components/Spacer';
import NFTDetailsSlider from 'Features/Auth/NFTDetailsSlider';
import useAuthDialog from 'Features/Auth/useAuthDialog';
import PasswordForm from 'Features/Form/PasswordForm';
import useAsyncTask from 'Hooks/useAsyncTask';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import useToastMessage from 'Hooks/useToastMessage';
import { EGateType } from 'Models';
import { TDiscussion } from 'Models/Discussion/@types';
import React, { FC } from 'react'
import { useStoreActions, useStoreState } from 'Stores';
import MESSAGES, { getTemplateString } from 'Utils/Messages'

export interface DiscussionGatesProps {
  onUnlock?: () => void;
  discussion: TDiscussion
}

const DiscussionGates: FC<DiscussionGatesProps> = (props) => {
  const { onUnlock, discussion } = props;

  const { isDeviceSm } = useMediaQuery();
  const classes = useStyles();

  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
  const { fetchMe, unlockDiscussion } = useStoreActions(({ AuthStore: { fetchMe }, DiscussionStore: { unlockDiscussion } }) => ({ fetchMe, unlockDiscussion }));

  const { openDialog } = useAuthDialog();

  const withToast = useToastMessage();

  const handleUnlockToken = useAsyncTask(async (password?: string) => {
    if (discussion.gateType === EGateType.NFT_GATED)
      openDialog(appUser?.publicAddress ? 'unlock-gated' : 'wallet-select', {
        heading: `You need a token to unlock this conversation`,
        subtitle: '',
        body: (
          <>
            <NFTDetailsSlider nftGates={discussion.nftGates ?? []} />
            <Spacer height={20} />
            <PgTypo b4>In order to continue, you must connect the wallet where you own the token.</PgTypo>
          </>
        ),
        variant: 'small',
        runExternal: true,
        externalCallBack: async () => await withToast(async () => {
          const { isAuthorized } = await unlockDiscussion({ discussionId: discussion.id, password });
          if (!isAuthorized) throw new Error(MESSAGES.CONVERSATION_PASS_UNLOCK_ERR);
          onUnlock?.()
        }, {
          successToastMessage: getTemplateString('CONVERSATION_UNLOCK_SUCCESS', { name: discussion?.title ?? '' }),
        }),
      }).then(() => {
        if (!appUser?.publicAddress) fetchMe();
      });

    if (discussion.gateType === EGateType.PASSWORD_PROTECTED && password) {
      await withToast(async () => {
        const { isAuthorized } = await unlockDiscussion({ discussionId: discussion.id, password });
        if (!isAuthorized) throw new Error(MESSAGES.CONVERSATION_PASS_UNLOCK_ERR);
        onUnlock?.()
      }, {
        successToastMessage: getTemplateString('CONVERSATION_UNLOCK_SUCCESS', { name: discussion?.title ?? '' }),
      });
    }
  });

  if (discussion.gateType === EGateType.OPEN) return null;

  if (discussion.gateType === EGateType.PASSWORD_PROTECTED)
    return (
      <Box height={'100%'} display="flex" alignItems={'center'} justifyContent={'center'}>
        <Box maxWidth={600}>
          <PgTypo align="center" variant="h6" style={{ marginBottom: 12 }}>
            You need a password to unlock this conversation.
          </PgTypo>
          <PgTypo align="center" variant="body1" style={{ marginBottom: 34 }}>
            No password? Contact the creator to get access.
          </PgTypo>
          <PasswordForm afterSubmit={() => { }} id="" onPasswordSubmit={handleUnlockToken.run} />
        </Box>
      </Box>
    );

  if (discussion.gateType === EGateType.NFT_GATED)
    return (
      <Box display={"flex"} alignItems={'center'} justifyContent={'center'} pt={isDeviceSm ? 4.5 : 7.5}>
        <Box maxWidth={560}>
          <PgTypo h3={!isDeviceSm} h7={isDeviceSm} align='center' typoWeight='fontWeightBold' className={classes.unlockNftTitle}>
            You need a token to unlock this conversation
          </PgTypo>
          <PgButton primary fullWidth onClick={() => handleUnlockToken.run(undefined)}>Unlock</PgButton>
        </Box>
      </Box>
    );

  return null;
}

export default DiscussionGates;

const useStyles = makeStyles((theme: Theme) => createStyles({
  unlockNftTitle: { marginBottom: theme.spacing(3) },
  unlockNftText: { margin: theme.spacing(2.5, 0, 1.5) },
}))
