import useSocketListener from 'Hooks/useSocketListener';
import { TNotification } from 'Models/App/@types';
import { useEffect } from 'react';

const useNotificationsSocket = (onNotificationReceive: (data: TNotification) => void) => {
  const { socket, socketConnected, SOCKET_EVENT_KEYS } = useSocketListener();
  const addListener = () => {
    try {
      socket.removeListener(SOCKET_EVENT_KEYS.NOTIFICATION);
      socket.on(SOCKET_EVENT_KEYS.NOTIFICATION, onNotificationReceive);
    } catch (err) {
      console.log('Error while subscribing to notification socket', err);
    }
  };
  useEffect(() => {
    if (socketConnected) {
      addListener();
    }
  }, [socketConnected]);

  return {};
};

export default useNotificationsSocket;
