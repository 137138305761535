import React from 'react';
import PgEmptyState from 'Components/PgEmptyState';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES } from '../Routes/index';

const Page404 = (): JSX.Element => {
  const emptyStateText = 'Not all hope is lost.';
  const emptyStateTitle = '404 error got ya bored af';
  const history = useHistory();
  return (
    <div>
      {/* <Typography variant="h2">404: Page Not Found</Typography> */}
      <PgEmptyState
        type="Title"
        title={emptyStateTitle}
        text={emptyStateText}
        buttonName="DISCOVER NEW DROPS"
        onButtonClick={() => history.push(APP_ROUTES.DROPS.path)}
      />
    </div>
  );
};

export default Page404;
