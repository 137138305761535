/* eslint-disable react/require-default-props, no-unused-expressions */
import _, { get } from 'lodash';
import React, { FC, useMemo } from 'react';
import { Box, FormHelperText, makeStyles, Theme } from '@material-ui/core';
import { IFieldProps, getFieldError } from 'react-forms';
import clsx from 'clsx';
import DateInput, { DateInputProps } from '../../Components/FormFields/DateInput';

export const DATE_FIELD_DATE_FORMAT = 'dddd MMM DD, YYYY';
// const TIME_FORMAT = 'HH:mm';

export interface DateFieldProps extends IFieldProps {
  fieldProps?: {
    placeholder?: string;
    label?: string;
    dateInputProps?: DateInputProps;
    onDateSet?: (date: string) => void;
    // timezone?: string;
    disabled?: boolean;
    onDisableClick?: () => void;
    rootClassName?: string;
    showIcon?: boolean
    dateFormat?: string;
  };
}

/* IMPORTANT: If you're using this component, for initialValues, send the date in the format given in DATE_FIELD_DATE_FORMAT. The var is exported for this purpose. You may parse it to some other format once the form is submitted */

const DateField: FC<DateFieldProps> = (props) => {
  const { formikProps, fieldConfig, fieldProps = {} } = props;
  const { dateInputProps, onDateSet /* timezone = 'GMT' */, disabled, onDisableClick, rootClassName, showIcon, dateFormat } = fieldProps;
  // console.count('DateField');
  const valueKey = fieldConfig?.valueKey || '';
  const value = _.get(formikProps?.values, valueKey);
  const fieldName = valueKey.includes('[0]') ? valueKey.split('[')[0] : valueKey; // Error message may be set on parent obj. `fieldName` is used for getting the error from that.
  const fieldError = useMemo(() => getFieldError(valueKey, formikProps ?? {}), [valueKey, formikProps]);
  const error = useMemo(() => getFieldError(fieldName, formikProps ?? {}), [fieldName, formikProps]);
  // const momentDate = useMemo(() => {
  //   return value ? moment.utc(value) /* .tz(timezone) */ : undefined;
  // }, [/* timezone, */ value, formikProps?.values]);
  // const momentDate = moment.utc(value);
  // const [date, setDate] = useState<moment.Moment | undefined>(momentDate);
  // console.log('datefield', { momentDate, date, value, valueKey, timezone });
  // const time = (momentDate ?? moment()).format(TIME_FORMAT);

  // useEffect(() => {
  //   if (!date) return;
  //   // const dateObj = moment.tz(`${date} ${time}`, `${DATE_FORMAT} ${TIME_FORMAT}`, timezone).utc().format();
  //   const dateString = date.toISOString();
  //   // eslint-disable-next-line no-unused-expressions
  //   formikProps?.setFieldValue(valueKey, dateString);
  //   if (onDateSet) onDateSet(dateString);
  // }, [date]);

  // useEffect(() => {
  //   if (value) setDate(moment(value).utc() /*  .tz(timezone) */);
  // }, [timezone]);

  // useEffect(() => {
  //   if (date) setDate(date.utc() /* .tz(timezone) */);
  // }, [timezone]);

  const classes = useStyles({});
  return (
    <Box onClick={disabled ? onDisableClick : undefined} className={rootClassName}>
      <DateInput
        label={fieldProps.label}
        labelClass={clsx({ [classes.label]: (typeof error === 'string' && error !== '') || (typeof fieldError === 'string' && fieldError !== '') })}
        // onChange={(value) => {
        //   // eslint-disable-next-line no-unused-expressions
        //   if (value && typeof value !== 'string') formikProps?.setFieldValue(valueKey, value.format(DATE_FIELD_DATE_FORMAT));
        // }}
        onChange={(value) => {
          if (typeof value === 'string') {
            formikProps?.setFieldValue(valueKey, value);
            onDateSet?.(value);
          } else {
            onDateSet?.(value.format(dateFormat ?? DATE_FIELD_DATE_FORMAT));
            formikProps?.setFieldValue(valueKey, value.format(dateFormat ?? DATE_FIELD_DATE_FORMAT));
          }
        }}
        utc
        value={value}
        placeholder={fieldProps.placeholder}
        dateFormat={dateFormat ?? DATE_FIELD_DATE_FORMAT}
        inputProps={{ onBlur: formikProps?.handleBlur, readOnly: true, name: valueKey, disabled, value: value ?? '' }}
        showIcon={showIcon}
        {...dateInputProps}
      />
      {error || fieldError ? (
        <FormHelperText error>
          {typeof error === 'string' ? error : typeof fieldError === 'string' ? fieldError : (console.log({ error, fieldError }), null)}
        </FormHelperText>
      ) : null}
    </Box>
  );
};

export default DateField;

const useStyles = makeStyles<Theme, any>((theme) => ({
  label: {
    color: theme.palette.error.main,
  },
}));
