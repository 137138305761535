import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, BoxProps, Button, ButtonProps } from '@material-ui/core';
import SubmitButton, { SubmitButtonProps } from 'Components/SubmitButton';
import PgButton from 'Components/PgButton';

export interface FormActionButtonProps {
  handleSubmit: () => void;
  handleCancel?: () => void;
  loading?: boolean;
  disabled?: boolean;
  BoxProps?: BoxProps;
  fullWidth?: boolean;
  cancelButtonProps?: Omit<ButtonProps, 'fullWidth'>;
}

const FormActionButton: FC<FormActionButtonProps> = ({
  handleCancel,
  handleSubmit,
  loading = false,
  disabled = false,
  BoxProps,
  fullWidth,
  cancelButtonProps,
}) => {
  const classes = useStyles({});

  return (
    <Box width="100%" boxSizing="border-box" {...BoxProps}>
      <SubmitButton
        disabled={disabled}
        loading={loading}
        variant="contained"
        onClick={handleSubmit}
        fullWidth={fullWidth}
      >
        SAVE CHANGES
      </SubmitButton>
      {handleCancel ? (
        <>
          <PgButton secondary onClick={handleCancel} {...cancelButtonProps} fullWidth={fullWidth}>
            CANCEL
          </PgButton>
        </>
      ) : null}
    </Box>
  );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
  primary: {
    padding: '6px 30px 10px 30px',
    fontWeight: theme.typography.fontWeightBold,
    marginRight: 8,
  },
}));

export default FormActionButton;
