import { Avatar, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { initialGenerator } from 'Constants/initialGenerator';
import useLayoutQuery from 'Hooks/useLayoutQuery';
import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { COLOR, FONT_WEIGHTS } from 'Theme/themeConstants';
import helpers from 'Utils/helpers';

export type UserAvatarSize = 25 | 40 | 60 | 80 | 220 | string;

export interface IUserAvatar {
  name: string;
  size: UserAvatarSize;
  url?: string;
  className?: string;
  useInitialGenerator?: boolean;
  isColorStatic?: boolean;
  style?: CSSProperties;
}

const UserAvatar: FC<IUserAvatar> = ({ name, size = 40, url, className, useInitialGenerator: useInitalGenerator = true, isColorStatic: isHoverColorStatic = false, style }) => {
  const colorArray = Object.values(COLOR.tertiary);
  const [borderColor, setBorderColor] = useState<string>("");
  const color = colorArray[helpers.randomNumberGenerator(0, colorArray.length - 1)];
  useEffect(() => {
    setBorderColor(color);
  }, []);
  const borderColorClass = isHoverColorStatic ? borderColor : color;
  const { ref, width } = useLayoutQuery();
  const classes = useStyles({ size, color: borderColorClass, width });
  if (url) return <Avatar src={url} alt="community-avatar" className={clsx(classes.avatar, className)} style={style} />;
  return (
    <Avatar ref={ref} alt="community-avatar" className={clsx(classes.avatar, classes.borders, className)} style={style}>
      {useInitalGenerator ? initialGenerator(name) : name ?? 'PG'}
    </Avatar>
  );
};

const useStyles = makeStyles<Theme, { size: UserAvatarSize; color: string; width: number }>((theme) => ({
  avatar: (props) => ({
    height: props.size,
    width: props.size,
    aspectRatio: '1 / 1',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    // fontSize: theme.typography.h1.fontSize,
    fontSize: props.width / 3,
    fontWeight: FONT_WEIGHTS.fontWeightBold,
  }),
  borders: {
    // borderWidth: theme.spacing(0.5),
    borderWidth: ({ width }) => (width / 25 < 2 ? 2 : width / 25),
    borderColor: (props) => props.color,
    borderStyle: 'solid',
  },
}));

export default UserAvatar;
