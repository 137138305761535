import helpers from 'Utils/helpers';
import { ANALYTICS_SCRIPT /* META_PIXEL_SCRIPT */, PROD_SCRIPT, TEST_SCRIPT } from './Analytics/Script';
import Config from './config';
import Utils from './Utils';

const VALIDATE_CONFIG_PROPERTIES = ['BASE_URL', 'API_URL'];

const validateConfig = () => {
  VALIDATE_CONFIG_PROPERTIES.forEach((key) => {
    const val = Config.get(key);
    if (!val) throw new Error(`App config must define ${key}`);
  });
};

const addScript = (script: string) => {
  const el = document.createElement('script');
  el.innerHTML = script;
  document.head.appendChild(el);
};
const addAnalyticsScript = () => {
  if (helpers.isProduction()) addScript(PROD_SCRIPT);
  else addScript(TEST_SCRIPT);
  // addScript(META_PIXEL_SCRIPT);
};

const Boot = () =>
  new Promise((resolve, reject) => {
    validateConfig();
    /** Override console.log as per environment file */
    console.log('Console logging', Config.get('CONSOLE_LOGGING'));
    if (Config.get('CONSOLE_LOGGING') === 'false') {
      console.log = () => {};
    }
    const apiUrl = Config.get('API_URL');
    console.log('API url ', apiUrl);
    if (apiUrl) Utils.setBaseAPI_URL(apiUrl);
    addAnalyticsScript();
    // if (!helpers.isProduction()) {
    //   const { analytics } = window;
    //   analytics.debug(true);
    // }
    resolve(true);
  });

export default Boot;
