import React, { FC, useState } from 'react';
import { Box, ButtonProps, FormControlProps, FormHelperTextProps, Portal } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IFieldProps, getMenuOptions, MenuOptions, MenuOptionObject, getFieldError } from 'react-forms';
import get from 'lodash/get';
import { User } from 'Models/User';
import useAuthDialog from 'Features/Auth/useAuthDialog';
import PgButton from 'Components/PgButton';
import PgTypo from 'Components/PgTypo';
import { FONT_WEIGHTS } from 'Theme/themeConstants';
import clsx from 'clsx';
import BasicActionDialog from 'Dialogs/BasicActionDialog';
import useAuth from 'Features/Auth/useAuth';
export type addPhoneNumberButtonType = 'addPhoneNumber' | 'verifyPhoneNumber' | 'removePhoneNumber';
export interface IToggleButtonProps extends IFieldProps {
  fieldProps?: {
    options?: MenuOptions<MenuOptionObject>;
    defaultButtonProps?: ButtonProps;
    formControlProps?: FormControlProps;
    formHelperTextProps?: FormHelperTextProps;
    helperText?: string;
    onChange?: (menu: any) => void;
    buttonType?: addPhoneNumberButtonType;
    verifiedStatus?: boolean;
    phoneObj?: User['phone'];
  };
}

const defaultBtnProps: ButtonProps = {
  variant: 'outlined',
  color: 'secondary',
};
const selectedBtnProps: ButtonProps = {
  variant: 'contained',
  color: 'secondary',
};
const AddPhoneButton: FC<IToggleButtonProps> = (props) => {
  const { formikProps, fieldConfig, fieldProps = {} } = props;
  const { options = [], defaultButtonProps = defaultBtnProps, formControlProps, formHelperTextProps, helperText, onChange } = fieldProps;
  const classes = useStyles();
  const menuOptions = getMenuOptions(options);
  const value = get(formikProps?.values, fieldConfig?.valueKey || ''); // formikProps?.values[(fieldConfig?.valueKey || '')];
  const fieldError = formikProps ? getFieldError(fieldConfig?.valueKey || '', formikProps) : '';
  const { buttonType, verifiedStatus, phoneObj } = fieldProps;
  const handleSelection = (menu: MenuOptionObject) => () => {
    formikProps?.setFieldValue(fieldConfig?.valueKey || '', menu.value);
    onChange?.(menu);
  };
  const { openDialog } = useAuthDialog();
  const { removePhoneNumber } = useAuth();

  const [open, setOpen] = useState(false);
  const closeDialog = () => setOpen(false);

  const handleRemove = async () => {
    await removePhoneNumber();
    setOpen(false);
  };

  return (
    <Box display="flex" flexDirection="column">
      {buttonType === 'addPhoneNumber' ? (
        <PgButton secondary className={classes.button} onClick={() => openDialog('verifyPhone', { hideDontAskOption: true })}>
          ADD PHONE NUMBER
        </PgButton>
      ) : (
        <>
          {verifiedStatus === true ? (
            <PgTypo b4 align="left" className={classes.verifiedPhoneText} style={{ marginBottom: '10px' }}>
              {`${phoneObj?.countryCode} ${phoneObj?.phone} has been verified`}
            </PgTypo>
          ) : null}

          <Box display="flex" flexDirection="column">
            {verifiedStatus !== true ? (
              <PgTypo b4 align="left">
                {`We’ve sent you an SMS to ${phoneObj?.countryCode} ${phoneObj?.phone}.Please check your messages`}
              </PgTypo>
            ) : null}
            <Box display="flex" flexDirection="row" alignItems="center" mt="4px">
              {verifiedStatus !== true ? (
                <>
                  <PgButton
                    secondary
                    className={classes.button}
                    onClick={() => openDialog('verifyPhone', { phone: phoneObj, hideDontAskOption: true })}
                  >
                    VERIFY
                  </PgButton>
                </>
              ) : null}
              <PgTypo
                b4
                align="center"
                className={classes.editPhonestyle}
                underline
                onClick={() => openDialog('verifyPhone', { phone: phoneObj, hideDontAskOption: true })}
              >
                Change
              </PgTypo>
              <PgTypo className={clsx(classes.editPhonestyle, classes.removePhoneNo)} onClick={() => setOpen(true)} underline>
                remove
              </PgTypo>
            </Box>
          </Box>
        </>
      )}
      <Portal>
        <BasicActionDialog
          open={open}
          onClose={closeDialog}
          title="Remove phone number"
          subtitle="You will no longer receive SMS reminders for Playground drops."
          cancelBtnText="CANCEL"
          doneBtnText="REMOVE"
          onCancel={closeDialog}
          onDone={handleRemove}
        />
      </Portal>
    </Box>
  );
};

// export default memo(ToggleButtons);
export default AddPhoneButton;

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    buttonsContainer: {
      flex: 1,
      flexDirection: 'row',
    },
    button: {
      marginRight: 20,
      maxWidth: '235px',
    },
    verifiedPhoneText: {
      cursor: 'pointer',
      color: theme.palette.secondary.main,
    },
    editPhonestyle: {
      color: '#1A090D',
      fontWeight: FONT_WEIGHTS.fontWeightBold,
      marginRight: 12,
      cursor: 'pointer',
    },
    removePhoneNo: {
      color: '#B71840',
    },
  })
);
