import Axios from "axios";
import React, { useState } from "react";

function usePromoCode() {
    const [promoCode, setPromoCode] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPromoCode(e.target.value);
    }

    const checkPromoCode = async (eventId: string, code: string) => {
        const { data: isValid } = await Axios.request<boolean>({
            url: 'promoCodes/validate',
            params: {
                eventId,
                code
            }
        })
        return isValid;
    }

    return {
        promoCode,
        handleChange,
        checkPromoCode,
        clear: () => setPromoCode('')
    }

}

export default usePromoCode;