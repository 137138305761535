import { Button, ButtonProps, darken, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { FONT_FAMILY, FONT_WEIGHTS } from 'Theme/themeConstants';

export type PgButtonWidth = 120 | 140 | 180 | 320;
export interface ButtonTypeProps {
    primary?: boolean;
    secondary?: boolean;
    tertiary?: boolean;
    quaternary?: boolean;
}
export interface PgButtonProps extends ButtonProps, ButtonTypeProps {
    tcolor?: string;
    href?: string;
    isExternalLink?: boolean;
    underlined?: boolean;
    hoverUnderline?: boolean;
    width?: PgButtonWidth;
    openInNewTab?: boolean;
}

const PgButton = React.forwardRef<HTMLButtonElement, PgButtonProps>((props, ref) => {
    const { children, className, openInNewTab = true, ...rest } = props;
    const { isExternalLink, href, disabled } = rest;
    const buttonProps = getButtonProps(rest);
    const classes = useStyles(props);

    if (href && !isExternalLink && !disabled) {
        const linkProps = getLinkProps(buttonProps);
        return (
            <Button
                className={clsx(classes.button, className)}
                component={Link}
                to={href}
                {...ref}
                {...linkProps}
                onClick={rest.onClick as LinkProps['onClick']}
            >
                {children}
            </Button>
        );
    }
    if (href && isExternalLink && !disabled) {
        const linkProps = getLinkProps(buttonProps);
        return (
            <Button
                className={clsx(classes.button, className)}
                href={href}
                target={openInNewTab ? '_blank' : undefined}
                {...ref}
                {...linkProps}
                onClick={rest.onClick as LinkProps['onClick']}
            >
                {children}
            </Button>
        );
    }

    return (
        <Button className={clsx(classes.button, className)} ref={ref} {...buttonProps}>
            {children}
        </Button>
    );
});

const getButtonProps = (props: PgButtonProps): ButtonProps => {
    const { primary, secondary, tertiary, quaternary, tcolor, isExternalLink, underlined, hoverUnderline, width, ...rest } = props;
    const updatedProps: Partial<ButtonProps> = {};
    if (primary) {
        updatedProps.variant = 'contained';
        updatedProps.color = 'secondary';
    } else if (secondary) {
        updatedProps.variant = 'outlined';
    } else if (tertiary) {
        updatedProps.variant = rest.variant ?? 'text';
    } else if (quaternary) {
        updatedProps.disableElevation = true;
        updatedProps.disableFocusRipple = true;
        updatedProps.disableRipple = true;
    }
    return { ...rest, ...updatedProps };
};

const getButtonTypeStyles = (theme: Theme, props: PgButtonProps) => {
    const { quaternary, tcolor, underlined, hoverUnderline } = props;
    if (quaternary) {
        return {
            padding: 0,
            fontSize: theme.typography.caption.fontSize,
            fontFamily: FONT_FAMILY.primary,
            fontWeight: FONT_WEIGHTS.fontWeightBold,
            color: tcolor,
            textDecoration: underlined ? 'underline' : undefined,
            '&:hover': {
                backgroundColor: 'transparent',
                color: tcolor ? darken(tcolor, 0.2) : undefined,
                textDecoration: underlined || hoverUnderline ? 'underline' : undefined,
            },
            '&:focus': {
                backgroundColor: 'transparent',
                color: tcolor ? darken(tcolor, 0.3) : undefined,
                textDecoration: underlined ? 'underline' : undefined,
            },
            '&.Mui-disabled': {
                color: tcolor,
                opacity: 0.4,
            },
        };
    }
    return {};
};

const getLinkProps = (buttonProps: ButtonProps) => {
    const linkProps = (({ variant, color, disableElevation, disableRipple, disableFocusRipple, id }) => ({
        variant,
        color,
        disableElevation,
        disableRipple,
        disableFocusRipple,
        id
    }))(buttonProps);
    return linkProps;
};

const useStyles = makeStyles<Theme, PgButtonProps>((theme) => ({
    button: (props) => {
        const buttonTypeStyles = getButtonTypeStyles(theme, props);
        return {
            ...buttonTypeStyles,
            textTransform: props.quaternary ? 'initial' : 'uppercase',
            width: props.fullWidth ? '100%' : props.width,
        };
    },
}));

export default PgButton;
PgButton.displayName = 'PgButton';
