/* eslint-disable import/no-cycle */
import React, { FC, Suspense, useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import { useStoreActions, useStoreState } from 'Stores';
import Loader from 'Components/Loader';
import { APP_ROUTES } from 'Routes';
import lazyImport from '../../lazy-import';

const EventDetail = lazyImport('../Screens/Dashboard/EventDetail');
const EventForm = lazyImport('../Screens/Dashboard/EventForm');
const EventIssue = lazyImport('../Screens/Dashboard/EventIssue');
const OrderDetail = lazyImport('.../Screens/Dashboard/OrderDetail');
const QRForm = lazyImport('.../Screens/Dashboard/QRForm')

const EventNavigation: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { fetchEventDetail } = useStoreActions(({ EventDetailStore: { fetchEventDetail } }) => ({ fetchEventDetail }));
  const { loading } = useStoreState(({ EventDetailStore: { loading } }) => ({
    loading,
  }));

  useEffect(() => {
    fetchEventDetail({ id, filters: { include: ['includePlatformLinkInfo'] } });
  }, [id]);

  return loading ? (
    <Loader fullScreen />
  ) : (
    <>
      <Suspense fallback={null}>
        <Switch>
          <Route path={EVENT_ROUTES.QR_FORM_NEW.path} component={QRForm} />
          <Route path={EVENT_ROUTES.QR_FORM_EDIT.path} component={QRForm} />
          <Route path={EVENT_ROUTES.ORDER.path} component={OrderDetail} />
          <Route path={EVENT_ROUTES.ISSUE.path} component={EventIssue} />
          <Route path={EVENT_ROUTES.EDIT_EVENT.path} component={EventForm} />
          <Route path={EVENT_ROUTES.DUPLICATE_EVENT.path} component={EventForm} />
          <Route path={EVENT_ROUTES.EVENT_HOME.path} component={EventDetail} />
          {/* <Route path="/*" exact component={Page404} /> */}
        </Switch>
      </Suspense>
    </>
  );
};

export default EventNavigation;

export const EVENT_BASE_PATH = `${APP_ROUTES.DASHBOARD_HOME.path}/events-and-media/events/:id`;
const QR_FORM_BASE_PATH = `${EVENT_BASE_PATH}/qr-form`;

export const EVENT_ROUTES = {
  EVENT_HOME: {
    path: EVENT_BASE_PATH,
  },
  EDIT_EVENT: {
    path: `${EVENT_BASE_PATH}/edit`,
  },
  DUPLICATE_EVENT: {
    path: `${EVENT_BASE_PATH}/duplicate`,
  },
  ORDER: {
    path: `${EVENT_BASE_PATH}/orders/:orderId`,
  },
  ISSUE: {
    path: `${EVENT_BASE_PATH}/issue/:issueId`,
  },
  ISSUE_CONVERSATIONS: {
    path: `${EVENT_BASE_PATH}/issue/:issueId/conversations`,
  },
  QR_FORM_NEW: {
    path: `${QR_FORM_BASE_PATH}/new`,
  },
  QR_FORM_EDIT: {
    path: `${QR_FORM_BASE_PATH}/edit/:qrId`,
  },
};
