import { StyleRules } from '@material-ui/core';
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides';
import { COLOR, THEME_PALETTE } from 'Theme/themeConstants';
import typography from 'Theme/typography';

const FormHelperText: Partial<StyleRules<ComponentNameToClassKey['MuiFormHelperText']>> = {
  root: {
    fontSize: typography.overline?.fontSize,
    '&.Mui-error': {
      color: THEME_PALETTE.error.light,
    },
    marginLeft: 0,
    color: COLOR.secondary.blue,
    marginTop: 0,
  },
  contained: {
    marginLeft: 0,
    color: COLOR.secondary.blue,
    marginTop: 0,
  },
};

export default FormHelperText;
