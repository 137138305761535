import { Box, CircularProgress, Icon, Link } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Autolinker } from 'autolinker';
import clsx from 'clsx';
import PgButton from 'Components/PgButton';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import PgTypoWMention from 'Components/TypographyWMention';
import UserAvatar from 'Components/UserAvatar';
import { renderHtmlStr } from 'Features/Mention';
import useAsyncTask from 'Hooks/useAsyncTask';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { IDiscussionPermissions, TMessage } from 'Models/Discussion/@types';
import { getUserName } from 'Models/User/utils';
import moment from 'moment';
import React, { FC, memo, useMemo, useState } from 'react';
import { SHADES } from 'Theme/newThemeConstants';
import { COLOR } from 'Theme/themeConstants';
import helpers from 'Utils/helpers';
import ChatInput from './ChatInput';
import MessageActions from './MessageActions';
import _ReplyCard from './ReplyCard';
import { TDiscussionConfig, TDiscussionSendMessageFn } from './useDiscussion';
import { autoLinkerReplaceFn } from 'Constants/initialGenerator';
import { generatePath } from 'react-router';
import { APP_ROUTES } from 'Routes';

const ReplyCard = memo(_ReplyCard);


export interface MessageCardProps {
    message: TMessage;
    config: TDiscussionConfig;
    hideReplies?: boolean;
    fetchReplies: (meesage: TMessage, loadAll?: boolean) => Promise<void>;
    isCreator?: boolean;
    isDisabled?: boolean;
    handlePinUnpin: (message: TMessage) => Promise<void>;
    sendMessage: TDiscussionSendMessageFn;
    isMessageByCreator?: boolean;
    disableReply?: boolean;
    chatPermissions: IDiscussionPermissions;
}

export const getDate = (date: string): string => {
    const msgDate = moment(date).startOf('day').format('DD/MM/YYYY');
    const todayDate = moment().startOf('day').format('DD/MM/YYYY');
    const yesterDate = moment().subtract(1, 'day').startOf('day').format('DD/MM/YYYY');
    if (msgDate === todayDate) return moment(date).format('HH:mm');
    if (msgDate === yesterDate) return 'Yesterday';
    return moment(date).format('MMM DD');
};

const MessageCard: FC<MessageCardProps> = ({
    message,
    fetchReplies,
    isCreator,
    isDisabled,
    handlePinUnpin,
    sendMessage,
    isMessageByCreator = false,
    disableReply = false,
    config,
    hideReplies = false,
    chatPermissions,
}) => {
    const [showReply, setShowReply] = React.useState(false);
    const classes = useStyles();
    // eslint-disable-next-line no-underscore-dangle
    const profileImageUrl = useMemo(() => helpers.getPictureUrl(message.user?._profileImages?.filter((i) => !!i)?.[0]), [message.user?._profileImages, helpers.getPictureUrl]);
    const userLink = useMemo(() => generatePath(APP_ROUTES.USER_DETAIL.path, { id: message.user?.slug ?? '#' }), [message.user?.slug]);

    const { isDeviceSm } = useMediaQuery();

    const canShareLinks = useMemo(
        () => !!chatPermissions.canUserShareLinks || isMessageByCreator,
        [chatPermissions.canUserShareLinks, isMessageByCreator],
    );

    const canReply = useMemo(() => !chatPermissions.isUserReadOnly || !!isCreator, [chatPermissions.isUserReadOnly, isCreator]);

    const { isDeletedOrHidden, showCreatorHiddenView } = useMemo(() => ({
        isDeletedOrHidden: message.isDeleted || (message.isHidden && !isCreator),
        showCreatorHiddenView: message.isHidden && !message.isDeleted && isCreator,
    }), [message.isDeleted, message.isHidden, isCreator]);
    const msgText = useMemo(() => {
        if (isDeletedOrHidden) return 'This message has been deleted.';
        if (canShareLinks)
            return Autolinker.link(message.text || '', { newWindow: true, replaceFn: autoLinkerReplaceFn });
        return message.text;
    }, [message.text, isDeletedOrHidden,]);

    const toggleShowReply = () => setShowReply((e) => !e);
    const [replyFetchAttempts, setReplyFetchAttempts] = useState(0);
    const hasMoreReplies = useMemo(() => {
        if (!message.repliesCount || !message.replies?.length) return false;
        return message.replies?.length < message.repliesCount;
    }, [message.replies?.length, message.repliesCount]);
    const handleFetchReplies = useAsyncTask(async () => {
        await fetchReplies(message, replyFetchAttempts >= 2);
        setReplyFetchAttempts((e) => e + 1);
    });
    const [showMessageAction, setShowMessageAction] = useState(false);
    const toggleMessageAction = (action: boolean) => {
        if (isDeviceSm) return;
        setShowMessageAction(action);
    };

    const { date, userName } = useMemo(() => ({
        date: getDate(message.created),
        userName: getUserName(message.user),
    }), [message.created, message.user?.id])

    return (
        <Box className={clsx(classes.msgRoot, { [classes.hovered]: showMessageAction })}>
            <Box
                className={clsx(classes.parentMessage, { [classes.hidden]: showCreatorHiddenView })}
                onMouseEnter={() => toggleMessageAction(true)}
                onMouseLeave={() => toggleMessageAction(false)}
            >
                <Link href={userLink}>
                    <UserAvatar className={classes.userIcon} isColorStatic={true} url={profileImageUrl} size={''} name={message?.user?.firstName!} />
                </Link>
                <Box flex={1} ml={1} className={classes.msgContent}>
                    <Box display='flex' alignItems='flex-start' justifyContent='space-between' width='100%'>
                        <Box className={classes.msgMeta}>
                            <Link className={classes.userName} href={userLink}>
                                <PgTypo limitLines={1} c2>{userName}</PgTypo>
                            </Link>
                            <Box mr={isDeviceSm ? 1 : 2}>
                                <PgTypo b4 className={classes.greyText}>{date}</PgTypo>
                            </Box>

                            {message.isPinned ? (
                                <Box display="flex" alignItems="center" mr={isDeviceSm ? 1 : 2}>
                                    <PgIcon icon="icon-map-pin" color="secondary" />
                                    <PgTypo b4 color="secondary">
                                        Pinned
                                    </PgTypo>
                                </Box>
                            ) : null}
                            {isMessageByCreator ? (
                                <Box className={classes.creatorTag} mr={isDeviceSm ? 1 : 2}>
                                    <PgTypo b4>Creator</PgTypo>
                                </Box>
                            ) : null}
                            {showCreatorHiddenView ? (
                                <Box display="flex" alignItems="center" mr={isDeviceSm ? 1 : 2}>
                                    <Icon className={classes.hiddenIcon}>visibility_off_outlined</Icon>
                                    <PgTypo b4Bold className={classes.greyText}>
                                        Hidden
                                    </PgTypo>
                                </Box>
                            ) : null}
                        </Box>

                        <MessageActions
                            replyButtonProps={{
                                onClick: toggleShowReply,
                                disabled: isDisabled || ((message.isDeleted || message.isHidden) && !message.repliesCount) || disableReply || !canReply,
                            }}
                            onReplyClick={toggleShowReply}
                            config={config}
                            visible={isDeviceSm || showMessageAction}
                            handlePinUnpin={handlePinUnpin}
                            isCreator={isCreator}
                            message={message}
                            onClose={() => toggleMessageAction(false)}
                        />
                    </Box>

                    {/* <PgTypo
            dangerouslySetInnerHTML={{ __html: `<p>${msgText}</p>` }}
            className={clsx({ [classes.greyText]: isDeletedOrHidden, [classes.italicsText]: isDeletedOrHidden }, classes.text)}
            b2
          /> */}
                    <PgTypoWMention
                        // dangerouslySetInnerHTML={{ __html: `<p>${msgText}</p>` }}
                        className={clsx({ [classes.greyText]: isDeletedOrHidden, [classes.italicsText]: isDeletedOrHidden }, classes.text)}
                        b2
                        renderMentionHTMLStr={renderHtmlStr}
                    >
                        {msgText}
                    </PgTypoWMention>
                    {!!message.images?.length && !isDeletedOrHidden && (
                        <Box py={1} display='flex' alignItems='flex-start' flexWrap='wrap' gridGap={16}>
                            {message.images.map(img => (
                                <img src={helpers.getPictureUrl(img)} alt={img.name} className={classes.img} />
                            ))}
                        </Box>
                    )}
                </Box>
            </Box>
            <Box className={classes.replySection}>
                {showReply ? (
                    <ChatInput
                        width="100%"
                        sendMessage={async (...p) => {
                            await sendMessage(...p);
                            setShowReply(false);
                        }}
                        sendButtonClassName={classes.sendButtonClassName}
                        parentMessageId={message.id}
                        discussionId={config.activeDiscussionId}
                    />
                ) : null}
                {hideReplies
                    ? null
                    : (message.replies || []).map((msg) => {
                        return (
                            <Box key={msg.id} py={1}>
                                <ReplyCard message={msg} isCreator={isCreator} config={config} chatPermissions={chatPermissions} />
                            </Box>
                        );
                    })}
                {hasMoreReplies && !hideReplies ? (
                    <PgButton quaternary tcolor={SHADES.grey} onClick={handleFetchReplies.run} variant="text" className={classes.replyButton}>
                        {handleFetchReplies.status === 'PROCESSING' ? <CircularProgress size="20px" /> : `See more replies`}
                    </PgButton>
                ) : null}
            </Box>
        </Box>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        msgRoot: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(1, 0),
        },
        hovered: {
            backgroundColor: '#F8F8F8',
        },
        parentMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            position: 'relative',
        },
        creatorTag: {
            padding: theme.spacing(0, 0.5),
            background: COLOR.tertiary.music,
        },
        replySection: {
            marginLeft: 36 + 8, // image size plus left margin
        },
        userIcon: {
            height: `36px !important`,
            width: `36px !important`,
            fontSize: `14px`,
        },
        msgContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            overflowX: 'hidden',
        },
        text: {
            whiteSpace: 'pre-wrap',
            display: 'block',
            wordBreak: 'break-word',
            '& > p': {
                display: 'contents',
            },
            '&> p > a[href], & > a[href]': {
                textDecoration: 'underline',
                wordBreak: 'break-all',
                textUnderlineOffset: '2px',

                color: theme.palette.secondary.main,
            },
        },
        userName: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                marginRight: theme.spacing(1),
            },
        },

        msgMeta: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
        greyText: {
            color: SHADES.grey,
        },
        replyButton: {
            minWidth: 0,
            padding: 0,
        },
        sendButtonClassName: {
            right: 16,
        },
        italicsText: {
            fontStyle: 'italic',
        },
        hidden: {
            opacity: 0.5,
        },
        hiddenIcon: {
            fontSize: `1rem`,
            marginRight: theme.spacing(0.5),
        },
        img: {
            width: '100%',
            maxWidth: 350,
        },
    });
});

export default MessageCard;
