import { ANALYTICS_USER_EVENT } from 'Analytics/analyticActions';
import { history } from 'App';
import ToastMessage, { ToastMessageProps } from 'Components/ToastMessage';
import useAuth from 'Features/Auth/useAuth';
import useAuthDialog from 'Features/Auth/useAuthDialog';
import { omit } from 'lodash';
import UserModel, { TUserCommunity } from 'Models/User';
import { OptionsObject, useSnackbar } from 'notistack';
import { userInfo } from 'os';
import qs from 'qs';
import { useEffect } from 'react';
import { generatePath, useLocation } from 'react-router';
import { useStoreActions, useStoreDispatch, useStoreState } from 'Stores';
import utils from 'Utils';
import helpers from 'Utils/helpers';
import MESSAGES from 'Utils/Messages';
import useToastMessage from './useToastMessage';
import { autoCreateComm } from 'Constants/initialGenerator';
import { TCommunity } from 'Models/Community/@types';
import { APP_ROUTES } from 'Routes';
import { useMediaQuery } from './useMediaQuery';

export interface TEmailVerificationParams {
  // verificationToken?: string;
  // verificationEmail?: string;
  emailVerified?: boolean;
  error?: 'INVALID_ACCESS_TOKEN' | 'ACCESS_TOKEN_EXPIRED';
  hyperCommCrt?: boolean
}

export interface TExternalSignupParams {
  provider: string;
}

export interface TResetVerifcationParams {
  email?: string;
  token?: string;
}

const useAppLocationFlows = () => {
  const { search, pathname } = useLocation();
  const { appUser, isAuthenticated } = useStoreState(({ AuthStore: { appUser, isAuthenticated }, InviteStore: { communityReferral } }) => ({
    appUser,
    communityReferral,
    isAuthenticated,
  }));
  const dispatch = useStoreDispatch();
  const { /* verifyEmail, */ login } = useAuth();
  // const { openDialog } = useAuthDialog();
  const { enqueueSnackbar: showSnackbar } = useSnackbar();
  const enqueueSnackbar = (message: string | ToastMessageProps, options?: OptionsObject | undefined) =>
    showSnackbar(ToastMessage(typeof message === 'string' ? { title: message } : message), options);

  const { getCommunityReferral, setAppUser, authenticate } = useStoreActions(({ InviteStore: { getCommunityReferral }, AuthStore: { setAppUser, authenticate } }) => ({ getCommunityReferral, setAppUser, authenticate }));
  const { openDialog: openAuthDialog } = useAuthDialog();

  const { isDeviceSm } = useMediaQuery();

  const initCreateCommAnalytics = (newCommData: TCommunity | TUserCommunity) => {
    dispatch({
      type: ANALYTICS_USER_EVENT,
      data: {
        eventName: 'COMMUNITY_CREATED',
        context: { user: omit(appUser, ['otpResult', 'firstName', 'isPublished', 'password']), community: newCommData, communityId: newCommData.id },
        source: pathname,
      },
    });
    dispatch({
      type: ANALYTICS_USER_EVENT,
      data: { eventName: 'COMMUNITY_DETAILS', context: { communityDetails: newCommData, communityId: newCommData.id }, source: pathname },
    });
  }

  /**
   * Email verification handling
   */
  useEffect(() => {
    // if (!search || appUser?.emailVerified) return;
    if (!search) return;
    const { error, emailVerified, hyperCommCrt } = helpers.locationParams<TEmailVerificationParams>(search) ?? {};
    const isEmailVerified = Boolean(emailVerified);
    const hyperCommCreteFlow = Boolean(hyperCommCrt);
    if (!error && !isEmailVerified && !hyperCommCreteFlow) return;

    if (isEmailVerified || hyperCommCreteFlow) {
      if (appUser && appUser.emailVerified) autoCreateComm(appUser).then((newComm) => {
        if (!newComm) return
        setAppUser({ ...appUser, userCommunities: [ ...(appUser.userCommunities ?? []), newComm as TUserCommunity] });
        initCreateCommAnalytics(newComm);
        history.replace({
          pathname: generatePath(APP_ROUTES.COMMUNITY_DETAIL.path, { communityId: newComm.slug ?? newComm.id ?? '#' }),
          search: isDeviceSm ? 'openMobileMenu=true' : undefined,
        });
      });
      if (emailVerified) enqueueSnackbar('Your account has been verified.', { variant: 'success' });
      // dispatch({
      //   type: ANALYTICS_USER_EVENT,
      //   data: {
      //     eventName: 'USER_EMAIL_VERIFIED',
      //     context: { user: appUser, isEmailVerified: !!appUser?.emailVerified, email: appUser?.email },
      //     source: pathname,
      //   },
      // });
    }

    if (error === 'ACCESS_TOKEN_EXPIRED')
      enqueueSnackbar('The access token has expired. Please request a new access token and try again.', { variant: 'error' });
    if (error === 'INVALID_ACCESS_TOKEN')
      enqueueSnackbar('The access token is invalid. Please check the url.', { variant: 'error' });

    const params = helpers.locationParams<{}>(search) ?? {};
    const updatedParams = qs.stringify({ ...params, error: undefined, emailVerified: undefined });
    history.replace({ search: updatedParams });

    // if (!verificationToken) return;
    // verifyEmail(verificationToken).then(() => {
    //   history.replace(pathname);
    //   openAuthDialog('email-verified');
    // });
  }, [appUser?.id]);

  /**
   * Reset password handling
   */
  useEffect(() => {
    if (!search) return;
    const resetPasswordParams = helpers.locationParams<TResetVerifcationParams>(search);
    if (!resetPasswordParams?.token) return;
    utils.setAuthHeader(resetPasswordParams.token);
    UserModel.fetchMe()
      .then((user) => {
        openAuthDialog('change-password', {
          user,
          onChange: (password: string) => {
            login(user.email, password);
            history.replace(pathname);
          },
        });
      })
      .catch((err) => {
        enqueueSnackbar(MESSAGES.CHANGE_PASSWORD_ERROR_INVALID_TOKEN, {
          variant: 'error',
        });
      });
  }, [search]);
  const withToast = useToastMessage();

  /**
   * Referral handling
   */
  useEffect(() => {
    if (!search) return;
    const params = helpers.locationParams<{ referrer: string; commName: string; flowName: 'communityJoin' }>(search);
    const { referrer, flowName, commName } = params;
    if (!referrer && !flowName && !commName) return;
    if (flowName && flowName === 'communityJoin') {
      withToast(() => {}, { successToastMessage: `You have joined ${commName ?? ''} successfully.` });
      return;
    }
    const updatedParams = qs.stringify({ ...params, referrer: undefined });
    if (referrer) {
      if (!appUser) {
        getCommunityReferral({ redirectUrl: window.location.href });
      } else {
        if (!window.location.pathname.split('/')?.[2])
          withToast(async () => await UserModel.redeemReferCode({ referCode: referrer, accessToken: utils.getAccessToken() ?? '' }), {
            successToastMessage: `You have joined ${commName ?? ''} successfully.`,
          });
      }
    }
    history.replace({
      search: updatedParams,
    });
  }, [appUser]);

  /**
   * Provider handling
   */
  useEffect(() => {
    if (!search || !appUser) return;
    const params = helpers.locationParams<{ provider: string; isNewUser: boolean; redirectTo: string }>(search);
    const { provider, isNewUser: isNewUserStr, redirectTo } = params;
    const isNewUser = Boolean(isNewUserStr);
    if (!provider && !isNewUser && !redirectTo) return;
    if (provider && !isNewUser)
      dispatch({
        type: ANALYTICS_USER_EVENT,
        data: { eventName: 'USER_LOG_IN', context: { user: appUser, type: '3rd-party-auth', provider }, source: pathname },
      });
    if (provider && isNewUser) {
      withToast(() => {}, { successToastMessage: MESSAGES.LOGIN_SUCCESS });
      history.replace({
        search: '',
      });
      dispatch({
        type: ANALYTICS_USER_EVENT,
        data: {
          eventName: 'USER_SIGNUP',
          context: { user: omit(userInfo, ['otpResult', 'firstName', 'isPublished']), type: '3rd-party-auth', provider },
          source: pathname,
        },
      });
    }
    if (redirectTo && isAuthenticated) {
      const isDashboardRoute = redirectTo.includes('/dashboard/');
      if (isDashboardRoute) {
        const splitRoute = redirectTo.split('/');
        const communityId = splitRoute?.[0] === '' ? splitRoute?.[1] : splitRoute?.[0];
        if (!communityId) {
          history.replace({ pathname: '/', search: '' });
          return;
        }
        const isManagedComm = appUser.userCommunities?.find((u) => u.slug === communityId || u.id === communityId);
        if (!isManagedComm) {
          history.replace({ pathname: '/', search: '' });
          return;
        }
        history.push(redirectTo);
      } else history.push(redirectTo);
    }
  }, [search, appUser, isAuthenticated]);

  useEffect(() => {
    if (!search) return;
    const params = helpers.locationParams<{ errorToast: string, walletType: string }>(search);
    if (params.walletType) openAuthDialog();
    if (!params?.errorToast) return;
    withToast(() => {}, { genraToastMessage: params.errorToast, variant: 'error' });
    // remove errorToast from url
    const updatedParams = qs.stringify({ ...params, errorToast: undefined });
    history.replace({
      search: updatedParams,
    });
  }, [search]);

  // const urlHashString = window.location.hash.slice(1);

  // useEffect(() => {
  //   const { id_token } = helpers.locationParams<{ id_token?: string }>(urlHashString);
  //   if (!id_token) return;
  //   UserModel.googleZKLoginCallback(id_token).then(({ accessTokenId }) => {
  //     authenticate({ token: accessTokenId });
  //   });
  // }, [urlHashString])

  return undefined;
};

export default useAppLocationFlows;
