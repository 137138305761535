import React from 'react';
import ViewModel from './@types';

/**
 * HOC
 *
 * binds A View with its ViewModel
 */
function withViewModel<P, H>(C: React.FC<P>, vm: ViewModel<H>) {
  return (props: P) => {
    const state = vm.Hook();

    return (
      <vm.CtxProvider value={state}>
        <C {...props} {...state} />
      </vm.CtxProvider>
    );
  };
}

export default withViewModel;
