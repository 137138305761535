import React, { FC } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

const Album: FC<SVGCIconProps> = (props) => {

  const { color = 'black', height = 24, width = 22 } = props;

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.3394 9.08737L10.3401 9.0873C11.5022 8.96034 12.53 9.32165 13.367 10.1509C13.7288 10.5119 13.8633 10.695 14.0369 11.0577L14.0369 11.0577L14.0385 11.061C14.1987 11.3833 14.2962 11.6335 14.3551 11.8839C14.4139 12.1341 14.4368 12.3955 14.4368 12.7432C14.4368 13.7891 14.103 14.6009 13.3674 15.3292L13.3667 15.3298C12.5865 16.1082 11.7352 16.4362 10.6004 16.3919C9.59606 16.3475 8.88869 16.0382 8.17257 15.3295C7.46821 14.6267 7.15277 13.9097 7.10844 12.932C7.05838 11.7881 7.38757 10.9286 8.16625 10.1573C8.80753 9.52316 9.51655 9.17493 10.3394 9.08737ZM11.6494 9.32602L11.6495 9.32571L10.7358 9.2356C10.7356 9.2356 10.7354 9.2356 10.7352 9.2356C9.74779 9.24205 9.00833 9.55814 8.28626 10.2722L8.28624 10.2722C7.53987 11.0104 7.20014 11.8978 7.26345 12.9346C7.37131 14.7615 8.74644 16.1249 10.5745 16.2385L10.5749 16.2385C11.6136 16.3017 12.503 15.9631 13.2431 15.2185L13.2431 15.2185C13.9538 14.5034 14.2884 13.7176 14.2884 12.7314L14.2884 12.7294C14.2861 12.4435 14.2546 12.1586 14.1944 11.8791L14.1946 11.879L14.1918 11.8683C13.8743 10.6582 12.8631 9.64297 11.6494 9.32602Z" stroke={color} stroke-width="0.5" stroke-miterlimit="10"/>
      <path d="M10.1683 12.1194C9.96196 12.2429 9.79102 12.5604 9.79102 12.8133C9.79102 13.0367 9.98554 13.3366 10.2154 13.4777C10.8933 13.8893 11.6832 13.0602 11.2647 12.3781C11.1504 12.2027 10.9726 12.0779 10.7684 12.0297C10.5643 11.9815 10.3493 12.0136 10.1683 12.1194Z" stroke={color} stroke-width="0.5" stroke-miterlimit="10"/>
      <path d="M10.6188 4.15506V4.39862L10.1137 4.42568C8.55762 4.51589 7.13235 5.01955 5.83788 5.93665C5.31468 6.3065 4.42163 7.19053 4.06532 7.69117C3.12716 9.01271 2.62201 10.4335 2.51827 12.0527L2.48669 12.5308H1.95898V12.2827C1.95898 11.6152 2.15744 10.4876 2.41453 9.71181C3.00087 7.94375 4.30436 6.28395 5.92809 5.24657C6.35657 4.97144 7.23158 4.54746 7.73674 4.37607C8.54712 4.10119 9.3937 3.94768 10.249 3.92053L10.6188 3.91602V4.15506Z" fill={color}/>
      <path d="M10.6195 5.38168C10.6195 5.51248 10.6014 5.58464 10.5654 5.58464C10.3489 5.58464 9.4513 5.68838 9.16264 5.74701C7.14652 6.14844 5.34238 7.53762 4.42227 9.39588C3.99378 10.2619 3.71865 11.3353 3.71865 12.1562V12.5306H3.20898L3.24056 12.0795C3.40293 9.73416 4.53954 7.72255 6.43388 6.42808C7.53892 5.67485 9.00478 5.20126 10.2947 5.18322L10.6195 5.17871V5.38168Z" fill={color}/>
      <path d="M10.583 6.50942C10.592 6.59963 10.592 6.68082 10.5784 6.68984C10.5649 6.69886 10.4702 6.71239 10.3574 6.71239C9.97406 6.71239 9.19377 6.85221 8.72469 7.01007C7.73779 7.33506 6.85853 7.92369 6.18201 8.7123C5.50549 9.50091 5.05746 10.4595 4.88639 11.4843C4.84287 11.7426 4.81426 12.0031 4.80069 12.2646V12.5307H4.38574L4.41731 12.1564C4.52105 10.8258 4.91345 9.75236 5.64413 8.77813C6.74465 7.32129 8.49467 6.4102 10.3078 6.36058L10.5649 6.35156L10.583 6.50942Z" fill={color}/>
      <circle cx="10.7708" cy="12.7288" r="10.2708" stroke={color}/>
      <path d="M19.4239 0.0954367C19.3185 0.18585 19.3134 0.226034 19.3084 1.13519L19.2984 2.07951L19.429 2.14481C19.4772 2.16759 19.5213 2.19813 19.5596 2.23522C19.487 2.32922 19.4064 2.41662 19.3185 2.49642C19.0774 2.73752 18.9568 3.00374 18.9568 3.29005C18.9568 3.68687 19.2481 4.14396 19.6098 4.29967L19.8107 4.39008V12.5374L18.8212 13.562C18.2787 14.1296 17.7664 14.6671 17.681 14.7525L17.5303 14.9182L17.7011 15.094C17.7965 15.1895 17.897 15.2699 17.9271 15.2699C17.9572 15.2699 18.133 15.1192 18.3088 14.9283C18.4846 14.7374 19.0171 14.1899 19.4943 13.6926L20.3632 12.7985V4.38004L20.534 4.31976C20.76 4.24944 21.0966 3.87774 21.1619 3.63664C21.2875 3.17452 21.197 2.82291 20.8555 2.49642L20.6094 2.25532L20.7349 2.15486L20.8655 2.0544V0.205942L20.7199 0.105482C20.5943 0.0150689 20.4989 0 20.0518 0C19.6249 0.00502298 19.5143 0.020092 19.4239 0.0954367Z" fill={color}/>
      <path d="M16.9981 15.1948C16.9209 15.2794 16.8611 15.3784 16.8223 15.4861C16.7921 15.5816 16.7318 15.6519 16.6716 15.6619C16.6113 15.672 16.3501 15.898 16.0839 16.1642C15.6218 16.6213 15.5916 16.6414 15.5012 16.561C15.4108 16.4807 15.3706 16.5209 14.5619 17.3296L13.708 18.1835L14.2103 18.6858L14.7126 19.1881L15.5665 18.3342C16.3752 17.5255 16.4104 17.4802 16.33 17.3898C16.2496 17.2994 16.2697 17.2743 16.7218 16.8273C16.983 16.5661 17.199 16.33 17.199 16.2998C17.204 16.2044 17.3497 16.0587 17.4451 16.0487C17.5657 16.0387 17.9022 15.7473 17.9022 15.6519C17.9022 15.6117 17.7816 15.466 17.641 15.3254C17.3346 15.0341 17.1789 15.0039 16.9981 15.1948Z" fill={color}/>
    </svg>
  )
}

export default Album;
