import PgTypo from 'Components/PgTypo';
import { ConnectWalletButton } from 'Screens/UserSettings/Sections/ConnectWallet';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { BuilderProps, IFieldProps, MLFormContent } from 'react-forms';
import useStyles from './styles';
import { Formik, FormikProps } from 'formik';
import PgButton from 'Components/PgButton';
import { Box, BoxProps, IconButton, TextField, Theme, createStyles, makeStyles } from '@material-ui/core';
import { BORDER_BLACK } from 'Theme/themeConstants';
import { useWeb3React } from '@web3-react/core';
import NFTTicketingLoadingStates, { INFTTicketingFormData, TEditConfig, TOnDoneFn } from 'Features/Wallet/NFTTicketingLoadingStates';
import EventModel, { TEvent } from 'Models/Event';
import { EventTier, TicketTier } from 'Models/Event/ticketing/@types';
import clsx from 'clsx';
import PgIcon from 'Components/PgIcon';
import { difference, get, isEmpty, isEqual, pick } from 'lodash';
import { getAddChainParameters } from 'Web3/Chains';
import { Option } from 'Models/App/@types';
import { useStoreState } from 'Stores';
import * as Yup from 'yup';
import helpers from 'Utils/helpers';
import useShowToast from 'Hooks/useShowToast';
import { WalletType } from 'Models/Web3/@types';

const withSubtitle = (title: string, subtitle: string) => (
  <>
    {title}
    <PgTypo b6 color="inherit">
      {subtitle}
    </PgTypo>
  </>
);

const getSchema = (
  classes: ReturnType<typeof useStyles>,
  onChainChange?: Function,
  isChainFieldDisabled = false,
  chainOptions?: Option<number>[],
  isClaimNotSet = false,
  isMetaNotSet = false,
) => [
    { type: 'text', valueKey: 'name', fieldProps: { placeholder: 'Ticket name*', disabled: isClaimNotSet || isMetaNotSet } },
    {
      type: 'text',
      valueKey: 'description',
      fieldProps: { placeholder: 'Description*', minRows: 3, multiline: true, disabled: isClaimNotSet || isMetaNotSet },
    },
    {
      type: 'image-upload',
      valueKey: 'image',
      fieldProps: {
        label: 'ADD AN ASSET FOR YOUR TICKET*',
        imageUploadCropConfig: { aspectRatio: 1 },
        disabled: isClaimNotSet || isMetaNotSet,
      },
    },
    {
      type: 'text',
      valueKey: 'supply',
      classNames: [classes.withSubtitleLabel],
      fieldProps: {
        label: withSubtitle('Supply*'.toUpperCase(), 'The number of items that can be minted. No gas cost to you.'),
        type: 'number',
        disabled: isMetaNotSet,
      },
    },
    {
      type: 'text',
      valueKey: 'maxQuantity',
      classNames: [classes.textField],
      fieldProps: { label: 'Max Quantity*'.toUpperCase(), type: 'number', disabled: isMetaNotSet },
    },
    {
      type: 'select',
      valueKey: 'chainId',
      classNames: [classes.textField],
      fieldProps: {
        options: chainOptions,
        label: 'Blockchain'.toUpperCase(),
        onChange: onChainChange,
        disabled: isChainFieldDisabled,
      },
    },
  ];

interface FormData extends INFTTicketingFormData {
  chainId: number;
}

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Ticket name required'),
  description: Yup.string().required('Description required'),
  image: Yup.object().required('Asset required').nullable(),
  supply: Yup.number()
    .integer('Supply should be an integer')
    .min(1, 'Supply cannot be less than or equal to 0')
    .required('Supply required'),
  maxQuantity: Yup.number()
    .integer('Max quantity should be an integer')
    .min(1, 'Max quantity cannot be less than or equal to 0')
    .max(Yup.ref('supply'), 'Max quantity should be less than supply')
    .required('Max quantity required'),
  price: Yup.number().min(0, 'Price cannot be less than or equal to 0').required('Price required'),
});

export interface NFTTicketingProps extends IFieldProps {
  fieldProps?: NFTFieldProps;
}
export interface NFTFieldProps extends Partial<BuilderProps> {
  hasWalletDetails?: boolean;
}

const toastMapper = {
  setClaim: 'Oops! Ticket still need to be claimed. Please do not refresh this page or the contract address will be lost.',
  invalidConnection: 'Public address or chain is incorrect! Please check your connection and try again.'
}

const buttonStates = { "deploy-contract": '', "set-claim": 'claim ticket', "set-shared-meta": 'set nft' }

const NFTTicketing: FC<NFTTicketingProps> = (props) => {

  const {
    eventFormikProps,
    contractAddress,
    initialNFTTicketingComplete,
    contractState,
    currTier,
    creatorPublicAddress,
    contractChainId,
    dropId,
  } = useMemo(() => {
    const eventFormikProps = props.formikProps as FormikProps<Partial<TEvent>>;
    const tier = eventFormikProps.values.enabledTier?._ticketTiers?.find(f => f);
    return { eventFormikProps, ...tier, currTier: tier, dropId: eventFormikProps.values.id };
  }, [props.formikProps]);

  const { fieldProps = {} } = props;

  const { hasWalletDetails = true } = fieldProps;

  const classes = useStyles();
  const extraClasses = useExtraStyles();

  const [openLoader, setOpenLoader] = useState<FormData & { editConfig?: TEditConfig }>();
  const [isFormView, setIsFormView] = useState(false);

  const { connector, chainId } = useWeb3React();

  const contractData = useMemo<Partial<FormData>>(() => ({
    name: currTier?.name,
    description: currTier?.ticketInstructions,
    image: currTier?.pictureUrl ? { url: currTier?.pictureUrl } : undefined,
    chainId: currTier?.contractChainId ?? chainId,
    maxQuantity: currTier?.maxTicketsPerOrder,
    supply: currTier?.ticketsAvailable,
    price: currTier?.price,
  }), [currTier])

  const { chains, appUser } = useStoreState(({ App: { chains }, AuthStore: { appUser } }) => ({ chains, appUser }));

  useEffect(() => {
    if (!contractAddress && !isEmpty(currTier) && appUser?.recentWalletUsed === WalletType.SUI) setIsFormView(false)
    else if (!contractAddress) setIsFormView(true);
  }, [contractAddress, currTier, appUser?.recentWalletUsed])

  const handleChainChange = (formikProps: FormikProps<Partial<FormData>>) => async (e: React.ChangeEvent<any>) => {
    const chainId = parseInt(e.target.value, 10);
    if (!chainId) return;
    await connector.activate(getAddChainParameters(chainId, chains));
    // formikProps.setFieldValue('chainId', chainId);
    formikProps.setFieldValue('enabledTier._ticketTiers[0]contractChainId', chainId)
  }

  const handleSubmit = (formikProps: FormikProps<Partial<FormData>>) => () => {
    formikProps.handleSubmit();
    if (!isEmpty(formikProps.errors) || !formikProps.values.name) return;

    if (appUser?.publicAddress && appUser.recentWalletUsed === WalletType.SUI) {
      const { price, maxQuantity, description, image, name, supply } = formikProps.values ?? {};
      const ticketTier = (eventFormikProps?.values?.enabledTier?._ticketTiers?.[0] ?? {}) as TicketTier
      const enabledTier = {
        ...eventFormikProps.values.enabledTier,
        _ticketTiers: [{
          ...ticketTier,
          price,
          maxTicketsPerOrder: maxQuantity ?? 2,
          ticketsAvailable: supply,
          name: name ?? '',
          creatorPublicAddress: appUser?.publicAddress,
          ticketInstructions: description,
          pictureUrl: image?.url,
          blockchain: 'sui',
          contractChainId: chainId,
        }],
      } as EventTier
      eventFormikProps.setFieldValue('enabledTier', enabledTier);
      return;
    }

    if (!contractAddress) setOpenLoader(formikProps.values as FormData);
    else if (contractState === 'set-claim') setOpenLoader({ ...formikProps.values, editConfig: 'set-claim' } as FormData);
    else {
      const contractDeployKeys = ['name', 'description', 'image'], claimPhaseKeys = ['maxQuantity', 'price', 'supply'];
      const updatedKeys = Object.keys(formikProps.values).filter(k => {
        const isDataEqual = isEqual(get(formikProps.values, k), get(formikProps.initialValues, k))
        if (!isDataEqual) return k;
      });
      if (contractState === 'set-shared-meta' && !updatedKeys.length) setOpenLoader({ ...formikProps.values, editConfig: 'set-shared-meta' } as FormData);
      else if (!updatedKeys.length) handleNFTTicketing({ contractAddr: contractAddress, updatedValues: formikProps.values as INFTTicketingFormData });
      else if (updatedKeys.length > 3) setOpenLoader(formikProps.values as FormData);
      else {
        const contractKeys = difference(updatedKeys, claimPhaseKeys);
        const claimKeys = difference(updatedKeys, contractDeployKeys);
        if (claimKeys.length && contractKeys.length) setOpenLoader(formikProps.values as FormData);
        else if (claimKeys.length) setOpenLoader({ ...formikProps.values, editConfig: 'set-claim' } as FormData);
        else setOpenLoader({ ...formikProps.values, editConfig: 'deploy-contract' } as FormData);
      }
    }
  };

  const showToast = useShowToast();

  const handleNFTTicketing: TOnDoneFn = ({ contractAddr, error, updatedValues, errorAt }) => {
    const { price, maxQuantity, supply, name, description, image } = updatedValues ?? openLoader ?? {};
    if (contractAddr) {
      const ticketTier = (eventFormikProps?.values?.enabledTier?._ticketTiers?.[0] ?? {}) as TicketTier
      const enabledTier = {
        ...eventFormikProps.values.enabledTier,
        _ticketTiers: [{
          ...ticketTier,
          price,
          maxTicketsPerOrder: maxQuantity ?? 2,
          contractAddress: contractAddr,
          ticketsAvailable: supply,
          name: name ?? '',
          contractChainId: chainId,
          creatorPublicAddress: appUser?.publicAddress,
          ticketInstructions: description,
          pictureUrl: image?.url,
          contractState: !error ? null : errorAt,
          initialNFTTicketingComplete: !ticketTier?.initialNFTTicketingComplete ? !error : ticketTier?.initialNFTTicketingComplete,
        }],
      } as EventTier
      if (!error) setIsFormView(false);
      // eventFormikProps.setValues({ ...eventFormikProps.values, enabledTier });
      eventFormikProps.setFieldValue('enabledTier', enabledTier)
      if (errorAt === 'set-claim') showToast(toastMapper.setClaim);
      try { if (dropId) EventModel.updateEvent({ id: dropId, enabledTier }); } catch (error) { console.error(error); }
    };
    setOpenLoader(undefined);
  }

  const isConnectionValid = useMemo(
    () => creatorPublicAddress === appUser?.publicAddress && chainId === contractChainId,
    [creatorPublicAddress, appUser?.publicAddress, chainId, contractChainId],
  );

  const handleInvalidConnection = () => showToast(toastMapper.invalidConnection, undefined, 'error');

  const handleDeleteClick = () => {
    setIsFormView(true);
    eventFormikProps.setValues({ ...eventFormikProps.values, enabledTier: { ...eventFormikProps.values.enabledTier, _ticketTiers: [] } } as TEvent);
  }

  const hideChangeWalletBtn = useMemo(() => {
    if (!appUser?.publicAddress && creatorPublicAddress) return false;
    if (appUser?.recentWalletUsed === WalletType.SUI && currTier?.blockchain === 'sui') return true;
    return appUser?.publicAddress === creatorPublicAddress;
  }, [appUser?.publicAddress, creatorPublicAddress])

  const currencySymbol = chains?.[chainId ?? 0]?.currencySymbol;

  return (
    <div>
      {
        hasWalletDetails ?
          <>
            <PgTypo b4 upperCase>
              wallet
            </PgTypo>
            <ConnectWalletButton hideDisconnectBtn hideChangeBtn={hideChangeWalletBtn} rootProps={{ mt: 1.5, my: 2.5 }} />
          </>
          : null
      }

      {!!appUser?.publicAddress && appUser.recentWalletUsed !== WalletType.SUI && (
        // <>
        //   {(!contractAddress && appUser.recentWalletUsed !== WalletType.SUI) || contractState || isFormView ? (
        //     <Formik<Partial<FormData>>
        //       initialValues={contractData}
        //       onSubmit={() => {}}
        //       validationSchema={ValidationSchema}
        //       isInitialValid={false}
        //       enableReinitialize
        //     >
        //       {(formikProps) => {
        //         const priceFieldError = getFieldError('price', formikProps);
        //         return (
        //           <Box p={2.5} border={BORDER_BLACK}>
        //             <MLFormContent
        //               formId="nft-deploy-form"
        //               settings={{ verticalSpacing: 20 }}
        //               schema={getSchema(
        //                 classes,
        //                 handleChainChange(formikProps),
        //                 !!contractAddress,
        //                 Object.values(chains ?? {}).map(c => ({ name: c.name, value: c.chainId })),
        //                 contractState === 'set-claim',
        //                 contractState === 'set-shared-meta',
        //               )}
        //               formikProps={formikProps}
        //             />
        //             <TextField
        //               type='number'
        //               name='price'
        //               label={withSubtitle('Set a price*'.toUpperCase(), 'MINT will happen at time of purchase from attendee.')}
        //               value={helpers.getFieldValue(formikProps, 'price')}
        //               onChange={formikProps.handleChange}
        //               onBlur={formikProps.handleBlur}
        //               helperText={priceFieldError && priceFieldError !== '' ? priceFieldError : `Ticket price will be in ${currencySymbol ?? 'USDT'}. This value may vary depending on the volatility of the currency.`}
        //               fullWidth
        //               variant='outlined'
        //               className={clsx(classes.withSubtitleLabel, extraClasses.priceField)}
        //               InputProps={{ startAdornment: <PgIcon icon='icon-money' size='small' /> }}
        //               error={!!priceFieldError}
        //               disabled={contractState === 'set-shared-meta'}
        //             />
        //             <PgButton primary fullWidth onClick={handleSubmit(formikProps)}>
        //               {contractState ? buttonStates[contractState] : `${!contractAddress ? 'create' : 'update'} nft ticket`}
        //             </PgButton>
        //           </Box>
        //         );
        //       }}
        //     </Formik>
        //   ) : (
        //     <NFTComp
        //       img={contractData.image?.url ?? ''}
        //       title={contractData.name ?? ''}
        //       subtitle={`Price: $${contractData.price}`}
        //       onEdit={isConnectionValid ? () => setIsFormView(true) : handleInvalidConnection}
        //       onDelete={isConnectionValid ? handleDeleteClick : handleInvalidConnection}
        //       containerProps={{ px: 2.5, py: 2, border: BORDER_BLACK }}
        //     />
        //   )}
        //   {!!openLoader && (
        //     <NFTTicketingLoadingStates
        //       {...openLoader}
        //       onDone={handleNFTTicketing}
        //       contractAddress={contractAddress}
        //       isEditFlow={!!(contractAddress && initialNFTTicketingComplete)}
        //     />
        //   )}
        // </>

        <>
          <MLFormContent
            formId='nft-form'
            formikProps={eventFormikProps}
            schema={[
              {
                type: 'select',
                valueKey: 'chainId',
                classNames: [classes.textField],
                fieldProps: {
                  options: Object.values(chains ?? {}).map(c => ({ name: c.name, value: c.chainId })),
                  label: 'Blockchain'.toUpperCase(),
                  onChange: handleChainChange(eventFormikProps),
                  disabled: !!contractAddress,
                  value: chainId,
                },
              }
            ]}
          />
        </>
      )}
    </div>
  );
};

export default NFTTicketing;

interface NFTCompProps {
  img: string;
  title: string;
  subtitle?: string;
  containerProps?: BoxProps;
  onEdit?: () => void;
  onDelete?: () => void;
  extraComp?: JSX.Element;
  imageClassName?: string;
}

export const NFTComp: FC<NFTCompProps> = (props) => {
  const { img, title, subtitle, containerProps, onEdit, onDelete, extraComp, imageClassName } = props;
  const extraClasses = useExtraStyles();
  return (
    <Box display='flex' alignItems='center' gridGap={16} {...containerProps}>
      <img src={img} alt={title} className={clsx(imageClassName, extraClasses.contractImg)} />
      <Box flex={1}>
        <PgTypo c2 style={{ marginBottom: 4 }}>{title}</PgTypo>
        {!!subtitle && <PgTypo b2>{subtitle}</PgTypo>}
        {!!extraComp && extraComp}
      </Box>
      {!!onEdit && <IconButton onClick={onEdit}><PgIcon icon='icon-edit' /></IconButton>}
      {!!onDelete && <IconButton onClick={onDelete}><PgIcon icon='icon-trash' /></IconButton>}
    </Box>
  )
}

const useExtraStyles = makeStyles((theme: Theme) => createStyles({
  priceField: {
    marginBottom: theme.spacing(2.5),
    '& input[type="number"]': {
      "& ::-webkit-outer-spin-button": { "-webkit-appearance": "none", margin: 0 },
      "&::-webkit-inner-spin-button": { "-webkit-appearance": "none", margin: 0 },
      appearance: "textfield",
    },
  },
  contractImg: { width: 80, height: 80, objectFit: 'cover' },
}));
