import { omit } from 'lodash';
import { WalletType } from 'Models/Web3/@types';
import sanitize from 'sanitize-html';
import { JSONType } from 'Typings/Global';
import { defaultSanitizeProps } from 'Utils/Constants';
import { Guest, Referral, User, TUserFollowWithMeta } from './@types';

// TODO Remove unnecessary keys handling
export const parseUser = (data: JSONType): User => {
  const {
    id,
    firstName,
    lastName,
    username,
    name,
    email: ctm,
    created,
    updated,
    roles = [],
    _profileImages,
    _profileBanner,
    featured,
    isFollowing,
    isFollowed,
    isPublished,
    slug,
    contactEnabled,
    contactEmail,
    isTwitterAuthenticated,
    followersCount,
    biography,
    advanceFollow,
    subtitle,
    transactionsTotal,
    userFirstActionAt,
    _city,
    referCode,
    emailVerified,
    phoneVerified,
    phone,
    supportUrl,
    tagIds,
    placeId,
    location,
    address,
    primaryEventCategoryId,
    secondaryEventCategoryId,
    isPublic,
    userCommunities,
    followsCommunities,
    socialLinks,
    wallet,
    dontAskPhoneRsvp,
    isWeb3EmailSetup,
    publicAddress,
    isDeactivated,
    isDeletionRequested,
    unReadNotificationCount,
    missingAuthFields,
    recentWalletUsed,
    purchasedTicketAmount,
    purchasedTicketQuantity,
    communityFollowCount,
    receiveRsvpOn,
    userFollowingCount,
    followId,
    cryptoWallet,
    dateOfBirth,
    referralId,
    doc_type,
  } = data;
  const user: User = {
    id,
    transactionsTotal,
    firstName,
    userFirstActionAt,
    lastName,
    username,
    isFollowing,
    isFollowed,
    name,
    contactEnabled,
    contactEmail,
    subtitle,
    biography,
    created,
    updated,
    slug,
    email: ctm,
    roles: roles.map((r: any) => r.name),
    profileImages: _profileImages,
    profileBanner: _profileBanner,
    _profileImages,
    _profileBanner,
    isFeatured: !!featured,
    isBlocked: !isPublished,
    isTwitterAuthenticated: !!isTwitterAuthenticated,
    followersCount: followersCount || 0,
    advanceFollow: !!advanceFollow,
    _city,
    referCode,
    emailVerified,
    phoneVerified,
    phone,
    supportUrl,
    tagIds,
    placeId,
    location,
    address,
    primaryEventCategoryId,
    secondaryEventCategoryId,
    isPublic,
    userCommunities,
    followsCommunities,
    socialLinks,
    wallet,
    dontAskPhoneRsvp,
    isWeb3EmailSetup,
    publicAddress,
    isDeactivated,
    isDeletionRequested,
    unReadNotificationCount,
    missingAuthFields,
    recentWalletUsed: recentWalletUsed ?? WalletType.METAMASK,
    purchasedTicketAmount,
    purchasedTicketQuantity,
    communityFollowCount,
    receiveRsvpOn,
    userFollowingCount: userFollowingCount ?? 0,
    followId,
    cryptoWallet,
    dateOfBirth,
    referralId,
    doc_type,
  };
  return user as User;
};

export const parseUserFormData = (user: Partial<User>): JSONType => {
  let formData = {};

  const {
    id,
    firstName,
    lastName,
    biography,
    profileBanner,
    username,
    name,
    isFeatured,
    phone,
    profileImages,
    password,
    advanceFollow,
    subtitle,
    contactEmail,
    contactEnabled,
    supportUrl,
    tagIds,
    email,
    _city,
    placeId,
    location,
    address,
    primaryEventCategoryId,
    secondaryEventCategoryId,
    slug,
    isPublic,
    socialLinks,
  } = user;
  formData = {
    ...(id ? { id } : {}),
    name,
    firstName,
    lastName,
    biography: biography && sanitize(biography, defaultSanitizeProps),
    username,
    featured: isFeatured,
    phone: {
      countryCode: (phone as any).split('-')[0],
      phone: (phone as any).split('-')[1],
    },
    _profileBanner: profileBanner,
    _profileImages: Object.values(profileImages as any),
    password,
    advanceFollow: !!advanceFollow,
    subtitle,
    contactEnabled,
    contactEmail,
    supportUrl,
    tagIds,
    email,
    _city,
    placeId,
    location,
    address,
    primaryEventCategoryId,
    secondaryEventCategoryId,
    slug,
    isPublic,
    socialLinks,
  };

  return formData;
};

export const parseToGuest = (obj: JSONType): Guest => {
  // eslint-disable-next-line no-underscore-dangle
  const ticketId: string = obj._id;
  const user: User = obj.user[0];
  const data = omit(obj, ['_id', 'users']);
  return { ...data, ticketId, user } as Guest;
};

export const parseReferral = (obj: JSONType): Referral => {
  return { ...obj } as Referral;
};

export const parseFollowUserData = (obj: TUserFollowWithMeta): TUserFollowWithMeta => {
  const { data, ...rest } = obj;
  const res = data.filter((f) => f.user).map((m) => ({ ...m, user: { ...m.user, id: m?.user?.id ?? (m?.user as any)._id } }));
  return { ...rest, data: res } as TUserFollowWithMeta;
};
