import React, { FC } from 'react'
import { SVGCIconProps } from '../SVGCIcon';

const TwitterNew: FC<SVGCIconProps> = (props) => {
  const { color = 'white', height = 21, width = 20 } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
      <path
        d="M15.6831 1.5H18.7366L12.0655 9.12462L19.9135 19.5H13.7677L8.95477 13.2074L3.4477 19.5H0.392311L7.5277 11.3446L3.8147e-06 1.5H6.3L10.6505 7.25169L15.6812 1.5H15.6831ZM14.6114 17.6723H16.3034L5.38154 3.23169H3.56585L14.6114 17.6723Z"
        fill={color}
      />
    </svg>
  )
}

export default TwitterNew;
