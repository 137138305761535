import { Box, FormHelperText, TextField, Theme, createStyles, makeStyles } from '@material-ui/core';
import PgButton from 'Components/PgButton';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import { INSTAGRAM_LINK, TWITTER_LINK, INVITE_READ_MORE_LINK, PLAYGROUND_STUDIOS_LINK, CAREERS_LINK, LEGAL_LINK, PLAYGROUND_ACC_LINK, CULTURE_FUND, CONTACT_GMAIL_LINK } from 'Constants/links';
import { RAINBOW_PAPER_LINK } from 'Constants/links';
import { AppToastContext } from 'Contexts/AppToastContext';
import Dark from 'Features/DarkTheme';
import useAsyncTask from 'Hooks/useAsyncTask';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { request } from 'Resources/AxiosUtils';
import { APP_ROUTES } from 'Routes';
import { FONT_FAMILY } from 'Theme/themeConstants';
import { JSONType } from 'Typings/Global';
import MESSAGES from 'Utils/Messages';
import helpers from 'Utils/helpers';
import clsx from 'clsx';
import React, { FC, useContext, useState } from 'react';
import PG_LOGO from 'Assets/images/PLAYGROUND_BRANDMARK_WHITE.svg';
import SVGCIcon from 'Components/SVGComponents/SVGCIcon';

interface TTab {
    isExternal?: boolean;
    link?: string;
    text: string;
    icon?: React.ReactNode;
    id: number;
}

const SocialIcons: TTab[] = [
    { id: 1, text: 'IG', link: INSTAGRAM_LINK, isExternal: true, icon: <PgIcon icon="icon-instagram" color="contrast" /> },
    { id: 2, text: 'TW', link: TWITTER_LINK, isExternal: true, icon: <SVGCIcon icon='icon-twitter' /> },
    { id: 3, text: 'PG', link: PLAYGROUND_ACC_LINK, isExternal: !helpers.isProduction(), icon: <img src={PG_LOGO} alt='PG-logo' height={20} /> },
];

const Links: TTab[] = [
    { id: 9, text: 'For Creators', link: APP_ROUTES.CREATOR.path, isExternal: false },
    { id: 1, text: 'About', link: APP_ROUTES.ABOUT_US.path, isExternal: false },
    { id: 5, text: 'Contact', link: CONTACT_GMAIL_LINK, isExternal: true },
    // { id: 3, text: 'Careers', link: CAREERS_LINK, isExternal: true },
    // { id: 8, text: 'Playground Studios', link: PLAYGROUND_STUDIOS_LINK, isExternal: true },
    { id: 10, text: 'For Brands', link: APP_ROUTES.BRANDS.path, isExternal: false },
    { id: 11, text: 'Ambassadors', link: APP_ROUTES.AMBASSADOR.path, isExternal: false },
    // { id: 2, text: 'Zine', isExternal: false },
    // { id: 4, text: 'Web 3', link: RAINBOW_PAPER_LINK, isExternal: true },
    // { id: 6, text: 'FAQ', link: INVITE_READ_MORE_LINK, isExternal: true },
    { id: 7, text: 'T&Cs', link: LEGAL_LINK, isExternal: true },
    // { id: 12, text: 'Culture Fund', link: CULTURE_FUND, isExternal: true },
]


const Footer: FC = () => {

    const { isDeviceSm } = useMediaQuery();

    const classes = useStyles();

    const { showToast } = useContext(AppToastContext);

    const [val, setVal] = useState({ email: '', err: '' });

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        e.persist();
        setVal(v => ({ ...v, email: e.target?.value }));
    }

    const handleClick = useAsyncTask(async () => {
        const isValid = helpers.validateEmail(val.email);
        if (!isValid && val.email) {
            setVal(v => ({ ...v, err: 'Invalid email format' }));
            return;
        }
        await request<JSONType>({
            url: `/AppModels/subscribeToNewsletter`,
            method: 'post',
            data: { email: val.email },
        })
            .then(() => showToast(MESSAGES.EMAIL_SUBSCRIBED, { variant: 'success' }))
            .catch(() => showToast(MESSAGES.EMAIL_REQUIRED, { variant: 'error' }));

        setVal({ email: '', err: '' });
    })

    return (
        <Dark>
            <footer className={classes.root}>
                <Box display='flex' gridGap={isDeviceSm ? 40 : 60} flexDirection={isDeviceSm ? 'column' : 'row'}>

                    <PgTypo className={classes.titleMain}>The World Plays Here</PgTypo>

                    <Box className={classes.midSection}>
                        {Links.map(l => (
                            <PgButton
                                key={l.id}
                                quaternary
                                fullWidth
                                isExternalLink={l.isExternal}
                                href={l.link}
                                className={clsx(classes.btn, classes.midSecBtn)}
                            >{l.text}</PgButton>
                        ))}
                    </Box>

                    {isDeviceSm ? null : <Box flex={1} />}

                    <Box display='flex' gridGap={40} alignItems='center' flexDirection={isDeviceSm ? 'column' : 'row'}>
                        <Box width={isDeviceSm ? '100%' : 290}>
                            <Box className={classes.inputWrap}>
                                <TextField fullWidth placeholder='Subscribe' className={classes.input} value={val.email} onChange={handleChange} />
                                <PgButton
                                    quaternary
                                    className={clsx(classes.btn, classes.playBtn)}
                                    disabled={handleClick.status === 'PROCESSING'}
                                    onClick={() => handleClick.run({})}
                                >Go Play</PgButton>
                            </Box>
                            {!!val.err && <FormHelperText variant='outlined' error className={classes.helperTxt}>{val.err}</FormHelperText>}
                        </Box>
                        <Box display='flex' gridGap={16}>
                            {SocialIcons.map(s => (
                                <PgButton key={s.id} quaternary href={s.link} isExternalLink={s.isExternal} className={classes.btn}>{s.icon}</PgButton>
                            ))}
                        </Box>
                    </Box>

                </Box>
            </footer>
        </Dark>
    )
}

export default Footer;

export const FOOTER_HEIGHT = 136;
// export const FOOTER_HEIGHT = 120;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        backgroundColor: theme.palette.common.black,
        padding: theme.spacing(5, 7.5),
        [theme.breakpoints.down('sm')]: { padding: theme.spacing(5, 4) },
    },
    inputWrap: { borderBottom: `1px solid ${theme.palette.common.white}`, display: 'flex', alignItems: 'baseline', gap: theme.spacing(2), width: '100%' },
    input: { '& div::before, div:hover::before, div::after': { border: 'none !important' }, '& input': { ...theme.typography.caption } },
    playBtn: { fontFamily: FONT_FAMILY.tertiary, ...theme.typography.overline },
    btn: { minWidth: 'unset', whiteSpace: 'nowrap', justifyContent: 'unset' },
    midSection: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: theme.spacing(1),
        maxWidth: 550,
        width: '100%',
        [theme.breakpoints.down('sm')]: { gridTemplateColumns: 'repeat(2, 1fr)', maxWidth: 'unset' }
    },
    midSecBtn: { fontWeight: theme.typography.fontWeightRegular },
    titleMain: { fontSize: 18, fontFamily: FONT_FAMILY.tertiary, fontWeight: theme.typography.fontWeightBold, lineHeight: '20px', maxWidth: 110, width: '100%' },
    helperTxt: { margin: theme.spacing(0.5, 0, 0) },
}));
