import React, { FC } from 'react';
import { COLOR } from 'Theme/themeConstants';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// eslint-disable-next-line import/no-cycle
import { SVGCIconProps } from '../SVGCIcon';

export interface YoutubeIconProps extends SVGCIconProps {
  iconProps?: {
    backgroundColor?: string;
    playIconWidth?: number;
    playIconHeight?: number;
  };
}
export const YoutubePlay: FC<YoutubeIconProps> = (props) => {
  const { iconProps = {}, color = COLOR.primary.white } = props;
  const { playIconWidth = 10, playIconHeight = 12 } = iconProps;
  const classes = useStyles();
  return (
    <svg
      className={classes.playIcon}
      width={playIconWidth}
      height={playIconHeight}
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M.27 11.343L9.943 5.74.27.138v11.205z" fill={color} />
    </svg>
  );
};

export const YoutubeBG: FC<YoutubeIconProps> = (props) => {
  const { iconProps = {}, width = 38, height = 27 } = props;
  const { backgroundColor = '#FF0000' } = iconProps;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 38 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.843 4.761a4.694 4.694 0 00-3.304-3.304C30.64.667 18.98.667 18.98.667s-11.66.024-14.557.814a4.693 4.693 0 00-3.305 3.304C.243 9.933-.097 17.777 1.143 22.72a4.693 4.693 0 003.305 3.304c2.897.79 14.557.79 14.557.79s11.66 0 14.558-.79a4.693 4.693 0 003.304-3.304c.924-5.155 1.209-12.994-.024-17.958z"
        fill={backgroundColor}
      />
    </svg>
  );
};

export const Youtube: FC<YoutubeIconProps> = (props) => {
  const classes = useStyles();
  return (
    <i className={classes.root}>
      <YoutubeBG {...props} />
      <YoutubePlay {...props} />
    </i>
  );
};

export default Youtube;

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'inline-block',
    },
    playIcon: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-70%)',
    },
  })
);
