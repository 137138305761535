import { Box, BoxProps, ButtonBase, Collapse, Switch, SwitchProps, Theme, createStyles, makeStyles } from '@material-ui/core';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import { BORDER_BLACK } from 'Theme/themeConstants';
import helpers from 'Utils/helpers';
import clsx from 'clsx';
import { FormikProps } from 'formik';
import React, { FC, ReactNode } from 'react';
import { BuilderProps, IFieldProps, MLFormContent } from 'react-forms';

export interface WithCollapsedConfigProps extends IFieldProps {
    fieldProps?: WithCollapsedConfigFieldProps;
}

export interface WithCollapsedConfigFieldProps extends Partial<BuilderProps> {
    title?: string;
    switchProps?: SwitchProps;
    rootProps?: BoxProps;
    additionalTitleComp?: ReactNode;
}

const WithCollapsedConfig: FC<WithCollapsedConfigProps> = (props) => {
    const { fieldProps = {}, formikProps, fieldConfig } = props;
    const {
        title,
        switchProps = {},
        rootProps = {},
        formId = 'with-collapsed-form-config',
        schema = [],
        actionConfig = { displayActions: false },
        additionalTitleComp,
        ...formContentProps
    } = fieldProps;

    const valueKey = fieldConfig?.valueKey ?? '';

    const isOpen = helpers.getFieldValue(formikProps as FormikProps<any>, valueKey) || switchProps.checked;

    const classes = useStyles({ isOpen: !!isOpen });

    return (
        <Box borderBottom={!!isOpen ? BORDER_BLACK : undefined} {...rootProps}>
            <Box
                component={ButtonBase}
                onClick={() => (switchProps.onChange ? switchProps.onChange({} as any, !isOpen) : formikProps?.setFieldValue(valueKey, !isOpen))}
                className={classes.titleWrap}
            >
                <PgTypo b1 typoWeight="fontWeightBold">
                    {title}
                </PgTypo>
                {!!additionalTitleComp && additionalTitleComp}
                <PgIcon icon="icon-arrow-next" size="small" styleClass={clsx(classes.aboutIcon, { [classes.aboutIconExpand]: !!isOpen })} />
                {/* <Switch className={classes.toggleSwitch} checked={!!isOpen} value={isOpen} onChange={(_, c) => formikProps?.setFieldValue(valueKey, c)} {...switchProps} /> */}
            </Box>
            <Collapse in={!!isOpen}>
                {!!isOpen ? (
                    <MLFormContent formId={formId} schema={schema} formikProps={formikProps} actionConfig={actionConfig} {...formContentProps} />
                ) : null}
            </Collapse>
        </Box>
    );
};

export default WithCollapsedConfig;

const useStyles = makeStyles<Theme, { isOpen: boolean }>((theme) =>
    createStyles({
        titleWrap: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: (props) => theme.spacing(props.isOpen ? 2 : 0),
        },
        toggleSwitch: { display: 'none' },
        aboutIcon: { transform: 'rotate(0deg)', transition: '300ms ease-in' },
        aboutIconExpand: { transform: 'rotate(90deg)' },
    }),
);
