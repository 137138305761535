/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { FC, useState, useEffect, useMemo } from 'react';
import { alpha, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, MenuItem, Select } from '@material-ui/core';
import { MLFormContent, getFieldError } from 'react-forms';
import { Formik, FormikConfig, FormikProps } from 'formik';
import PgIcon from 'Components/PgIcon';
import clsx from 'clsx';
import { TEvent } from 'Models/Event';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import TicketingEventCard from 'Features/Ticketing/TicketingEventCard';
import { TBill } from 'Models/Transactions/@types';
import { useSnackbar } from 'notistack';
import find from 'lodash/find';
import { TicketTier } from 'Models/Event/ticketing/@types';
import PgTypo from 'Components/PgTypo';
import PromoCodeInput, { PromoCodeInputProps } from './PromoCodeInput';
import { TOption } from '../RemindMeForm/CountryCodeSelect';
import POWERED_BY_STRIPE from '../../Assets/Logo/powered_by_stripe.png';
import {
  BORDER_BLACK,
  COLOR,
  DIVIDER_BORDER_BLACK,
  THEME_PALETTE,
  TICKETING_FORM_COLOR,
} from '../../Theme/themeConstants';
import Spacer from '../../Components/Spacer';
import { TICKETING_TERMS_ERROR, useEventTicketFormConfig } from './useEventTicketFormConfig';
import SubmitButton from '../../Components/SubmitButton';
import EventDateSelector from './EventDateSelector';
import { AgeRestrictionMap } from 'Screens/EventPage/Detail';
import AddressSelector from './AddressSelector';
import { NFTComp } from 'Screens/Dashboard/DropFormScreen/Sections/NFTTicketing';
import DropUtils from 'Utils/DropUtils';
import { CustomNotificationCheckbox } from 'Screens/UserSettings/Sections/AccountNotifications';
import helpers from 'Utils/helpers';
import PgButton from 'Components/PgButton';
import { LEGAL_LINK } from 'Constants/links';
import useShowToast from 'Hooks/useShowToast';
import { useStoreState } from 'Stores';
import { WalletType } from 'Models/Web3/@types';
import MESSAGES from 'Utils/Messages';

export interface EventTicketFormProps {
  initialValues?: Partial<EventTicketFormData>;
  type?: 'paid' | 'free';
  ticketLimit?: number;
  error?: string;
  onSubmit: FormikConfig<Partial<EventTicketFormData>>['onSubmit'];
  loading?: boolean;
  onQuantityUpdate?: (quantity: number) => void;
  PromoCodeInputProps?: PromoCodeInputProps;
  onTierUpdate: any;
  tiers: TicketTier[] | null;
  selectedTierId: string;
  bill: TBill | null;
  event: TEvent;
  numTickets: number;
}

export type EventTicketFormData = {
  firstName: string;
  lastName: string;
  quantity: number;
  email: string;
  phone: string;
  emailReminder: boolean;
  phoneReminder: boolean;
  countryCode: TOption;
  eventDate: string;
  dob: string;
  publicAddress?: string;
  termsAccepted?: boolean;
};

// attachField('country', <CountryCodeSelect />)
// attachField('pg-text', <PgTextField />)

const EventTicketForm: FC<EventTicketFormProps> = (props) => {
  const {
    initialValues = {},
    onSubmit,
    error,
    PromoCodeInputProps,
    loading = false,
    onQuantityUpdate,
    type = 'free',
    ticketLimit = 4,
    tiers,
    selectedTierId,
    onTierUpdate,
    bill,
    numTickets,
    event,
  } = props;
  const { isDeviceSm } = useMediaQuery();
  const classes = useStyles({ tiers, isDeviceSm });
  const { inputConfig, validationSchema, repeatEventValidationSchema } = useEventTicketFormConfig(
    event?.featureToggle?.ageRestriction || event?.ageRestriction ? event?.ageRestriction : undefined,
  );
  const [tierTicketLimit, setTierTicketLimit] = useState<number>(ticketLimit);
  const title = type === 'free' ? 'Register for Event' : 'Purchase Tickets';
  const buttonText = type === 'free' ? 'Register' : 'Check Out';
  const [quantityAvailable, setQuantityAvailable] = useState(1);
  const handleSelectedTier = (data: TicketTier) => {
    onTierUpdate(data?.id);
    setTierTicketLimit(data?.maxTicketsPerOrder);
  };
  const isDecimal = (num: any) => {
    return num !== parseInt(num, 10);
  };
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'info', anchorOrigin: { horizontal: 'center', vertical: 'bottom' } });
    }
  }, [error, enqueueSnackbar]);

  const selectedTier = tiers?.find((t) => t.id === selectedTierId);

  const maxPurchaseCount = useMemo<number>(() => {
    if (!selectedTier) return 0;
    const purchasedTickets = DropUtils.getPurchasedTicketCount(selectedTier, event.purchasedTickets)
    if (purchasedTickets) return selectedTier.maxTicketsPerOrder - purchasedTickets
    return selectedTier?.maxTicketsPerOrder;
  }, [selectedTier])

  const showToast = useShowToast()

  const handleErrorCheck = (formikProps: FormikProps<Partial<EventTicketFormData>>) => () => {
    if (!formikProps.values.termsAccepted) showToast(TICKETING_TERMS_ERROR, undefined, 'error')
  }

  const isSuiEvent = useMemo(() => event.tierType === 'nft' && selectedTier?.blockchain === 'sui', [event.tierType, selectedTier?.blockchain])

  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }))

  const disableCheckout = useMemo(() => isSuiEvent && appUser?.recentWalletUsed !== WalletType.SUI, [isSuiEvent, appUser?.recentWalletUsed])

  useEffect(() => {
    if (disableCheckout) showToast(MESSAGES.SUI_WALLET_NOT_CONNECTED, undefined, 'error');
  }, []);

  const MultiTier: FC = () => {
    return (
      <>
        {/* <Spacer height={isDeviceSm ? 45 : 50} /> */}
        {event?.tierType === 'multi' ? (
          <Box my={2.5}>
            <PgTypo c2>Select tier</PgTypo>
          </Box>
        ) : (
          <Spacer height={20} />
        )}

        {tiers?.map((t) => (
          <div key={t.id}>
            <Box
              border={BORDER_BLACK}
              display="flex"
              flexDirection="column"
              onClick={() => handleSelectedTier(t)}
              // borderColor={THEME_PALETTE.primary.contrastText}
              key={t.id}
              // height={85}
              pb={1.5}
              className={selectedTierId === t.id ? clsx(classes.selectedTier, classes.tierBox) : classes.tierBox}
            >
              <Box display="flex">
                <Box className={classes.tierIcon}>
                  <PgIcon icon={selectedTierId === t.id ? 'icon-check-on' : 'icon-check-off'} color="primary" size="small" />
                </Box>
                <Box display="flex" flexDirection="column" width="100%">
                  <Box display="flex" flexDirection="row">
                    <Box className={classes.tierInformation} width="100%">
                      <PgTypo c3>{event?.tierType === 'multi' ? t.name : 'General Admission'}</PgTypo>
                      {t.remainingTickets !== 0 || t.isInfinite ? event.id === '652edc6d7dbec650df6f9a42' ? null : (
                        <PgTypo b5>
                          {t.maxTicketsPerOrder} ticket purchase limit. {isDeviceSm ? <br /> : ' '}
                          {t.isInfinite ? '∞' : t.remainingTickets} available
                        </PgTypo>
                      ) : (
                        <PgTypo c3 className={classes.soldOutText}>
                          Sold Out!
                        </PgTypo>
                      )}
                    </Box>
                    <Box
                      // width={480}
                      className={classes.tierPrice}
                      textAlign="right"
                      width="70%"
                    >
                      <PgTypo c5>{t.price ? (isDecimal(t.price) ? `$${t.price}` : `$${t.price}.00`) : 'Free'}</PgTypo>
                    </Box>
                  </Box>
                  <PgTypo
                    b5
                    // whiteSpace="pre-wrap"
                    dangerouslySetInnerHTML={{
                      __html: t.ticketInstructions ?? '',
                    }}
                    style={{ wordBreak: 'break-word', lineHeight: '16px', letterSpacing: '0.02em', marginLeft: 10.27 }}
                  />
                </Box>
              </Box>
            </Box>
            <Spacer height={20} />
          </div>
        ))}
      </>
    );
  };
  return (
    <Formik<Partial<EventTicketFormData>>
      initialValues={{ quantity: 1, ...initialValues, termsAccepted: true }}
      validationSchema={event?.frequency === 'oneTime' ? validationSchema : repeatEventValidationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const termsError = getFieldError('termsAccepted', formikProps);
        return (
          <form className={classes.form} onSubmit={formikProps.handleSubmit}>
            <Box display="flex" flexDirection="column" overflow={isDeviceSm ? 'auto' : undefined}>
              <Box
                p={!isDeviceSm ? '31px 0 0 39px' : '35.2px 0 26px 20.7px'}
                className={classes.title}
                style={{ backgroundColor: 'white', width: '100%', paddingBottom: '8px' }}
              >
                <PgTypo h6>{title}</PgTypo>
                {!!event?.ageRestriction && event?.featureToggle?.ageRestriction && !!AgeRestrictionMap[event?.ageRestriction] && (
                  <PgTypo b2 className={classes.ageResRoot}>
                    <span className={classes?.ageResTitle}>{AgeRestrictionMap[event.ageRestriction].title}</span>
                    {AgeRestrictionMap[event.ageRestriction].subtitle}
                  </PgTypo>
                )}
              </Box>
              {!isDeviceSm ? null : (
                <Box p={0} width="100%" className={classes.testBox}>
                  <TicketingEventCard bill={bill} totalTickets={numTickets} event={event} variant={type} />
                </Box>
              )}

              <Box
                pl={isDeviceSm ? 3 : 5}
                pr={isDeviceSm ? 3 : 5}
                display="flex"
                flexDirection="column"
                className={(tiers?.length as number) > 1 ? classes.formBox : ''}
              >
                <MultiTier />
                {!maxPurchaseCount ? (
                  <Box px={4} py={10}>
                    <PgTypo h6 align='center' style={{ color: THEME_PALETTE.grey.A900 }}>You’ve reached the maximum ticket purchase limit for this event.</PgTypo>
                  </Box>
                ) : (
                  <>
                    {onQuantityUpdate || (tiers && (event?.tierType === 'multi' || event?.tierType === 'single' || event.tierType === 'nft')) ? (
                      <Box py={3} borderBottom={DIVIDER_BORDER_BLACK}>
                        <Box display="flex" flexDirection="column">
                          {!event || event.frequency === 'oneTime' ? null : (
                            <Box mb={3} maxWidth={285}>
                              <EventDateSelector event={event} formikProps={formikProps} />
                            </Box>
                          )}
                          <Box alignItems="center" display="flex" flexWrap="wrap">
                            {onQuantityUpdate && selectedTier?.maxTicketsPerOrder && selectedTier?.maxTicketsPerOrder > 1 && maxPurchaseCount > 1 && (
                              <Box mb={3}>
                                <FormControl className={PromoCodeInputProps?.isApplied || PromoCodeInputProps?.error ? classes.alignQtyBox : ''}>
                                  {tiers != null ? null : setTierTicketLimit(ticketLimit)}
                                  {/* <InputLabel id="quantity-select" className={classes.quantityText}>
                                                      Quantity
                                                      </InputLabel> */}
                                  <PgTypo gutterBottom b4>
                                    Quantity
                                  </PgTypo>

                                  <Select
                                    labelId="quantity-select"
                                    name="quantity"
                                    variant="outlined"
                                    value={
                                      formikProps.values?.quantity &&
                                      formikProps.values?.quantity <= selectedTier.maxTicketsPerOrder &&
                                      formikProps.values.quantity <= (selectedTier.remainingTickets || 0)
                                        ? formikProps.values.quantity
                                        : 1
                                    }
                                    onChange={(e) => {
                                      formikProps.handleChange(e);
                                      onQuantityUpdate(e.target.value as number);
                                    }}
                                    MenuProps={{
                                      className: classes.menuPopper,
                                      style: {
                                        zIndex: 1310,
                                      },
                                    }}
                                    className={classes.select}
                                  >
                                    {Array.from({ length: maxPurchaseCount }).map((_, i) => (
                                      <MenuItem key={i} value={i + 1}>
                                        {i + 1}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            )}
                            {onQuantityUpdate && selectedTier?.maxTicketsPerOrder && selectedTier?.maxTicketsPerOrder > 1 && <Spacer width={41} />}
                            {PromoCodeInputProps && tiers && (event?.tierType === 'multi' || event?.tierType === 'single') ? (
                              <Box mb={2.5}>
                                <PromoCodeInput
                                  label="Promo code"
                                  {...PromoCodeInputProps}
                                  inputClass={classes.promoCodeInput}
                                  btnClass={classes.promoCodeButton}
                                />
                              </Box>
                            ) : null}
                          </Box>
                        </Box>
                      </Box>
                    ) : null}

                    <Box pt={2.5}>
                      <PgTypo c1 style={{ marginTop: 10 }}>
                        Contact Information
                      </PgTypo>
                      <Spacer height={30} />
                      <MLFormContent settings={{ verticalSpacing: 20 }} schema={inputConfig} formikProps={formikProps} formId="ticket-contact-info" />
                    </Box>

                    {/* Wallet Selector */}
                    {event.tierType === 'nft' && (
                      <Box py={2} borderTop={BORDER_BLACK}>
                        <AddressSelector onWalletChange={w => formikProps.setFieldValue('publicAddress', w.address)} isSuiEvent={isSuiEvent} />
                      </Box>
                    )}

                    {/* NFT */}
                    {event.tierType === 'nft' && (
                      <Box py={2} borderTop={BORDER_BLACK}>
                        <PgTypo c1>NFT Ticket</PgTypo>
                        {tiers?.map(t => (
                          <NFTComp
                            img={t.pictureUrl ?? ''}
                            title={t.name ?? ''}
                            subtitle={t.ticketInstructions ?? event.description ?? ''}
                            containerProps={{ mt: 2, alignItems: 'flex-start' }}
                          />
                        ))}
                      </Box>
                    )}

                    <Box pb={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!formikProps.values.termsAccepted}
                            onChange={formikProps.handleChange}
                            name='termsAccepted'
                            icon={<PgIcon icon="icon-checkbox-off" color={!!termsError ? 'error' : undefined} />}
                            checkedIcon={<CustomNotificationCheckbox />}
                          />
                        }
                        label={
                          <PgTypo b4 color={!!termsError ? 'error' : 'primary'}>
                            I have read and accept the <PgButton quaternary isExternalLink className={classes.termsBtn} href={LEGAL_LINK} color='inherit'>terms and conditions</PgButton>
                          </PgTypo>
                        }
                      />
                    </Box>
                  </>
                )}


              </Box>
              {!!quantityAvailable && !!maxPurchaseCount && (
                <>
                  <Box flex={1} />
                  <Box className={classes.footer}>
                    {type === 'paid' && !isDeviceSm ? (
                      <>
                        <PgTypo className={classes.footerText}>Checkout is</PgTypo>
                        <img src={POWERED_BY_STRIPE} height="13px" alt="powered by stripe" />
                      </>
                    ) : null}
                    <SubmitButton
                      loading={loading}
                      disabled={!!error || disableCheckout}
                      className={clsx(classes.submitButton, `${error ? classes.disabledButton : ''}`)}
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={handleErrorCheck(formikProps)}
                    >
                      {buttonText}
                    </SubmitButton>
                  </Box>
                </>
              )}
            </Box>
          </form>
        )
      }}
    </Formik>
  );
};

const useStyles = makeStyles<Theme, { tiers: any; isDeviceSm: boolean }>((theme) => ({
  formBox: {
    overflowY: 'auto',
    maxHeight: 800,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'unset',
      overflowY: 'unset',
    },
  },
  soldOutText: {
    color: '#FF0400 !important',
  },
  form: {
    // backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: (props) => (props.tiers ? (props.isDeviceSm ? '100vh' : '100%') : '100%'),
    // overflowY: (props) => (!(props?.tiers?.length > 1) ? 'hidden' : 'scroll'),
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  footer: {
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(2, 2),
    borderTop: DIVIDER_BORDER_BLACK,
    marginBottom: -4,
    backgroundColor: theme.palette.common.white,
    zIndex: 1,
    '& img': {
      position: 'relative',
      top: 3,
      margin: '0px 20px 0px 4px',
    },
  },
  submitButton: {
    // color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: '16px',
    padding: '15px 30px !important',
  },
  footerText: {
    color: THEME_PALETTE.grey.B300,
    fontSize: 13,
    marginTop: '7px',
  },
  label: {
    color: theme.palette.common.black,
  },
  select: {
    width: 120,
    minWidth: 0,
    paddingLeft: '8px',
    fontSize: theme.typography.caption.fontSize,
    borderBottom: '1px solid #fff',
    // color: '#fff !important',
    color: TICKETING_FORM_COLOR,
    // '&:hover': {
    //     color: '#fff',
    //     borderBottom: '1px solid #fff',
    // },
    '&:focus': {
      color: '#fff !important',
      borderBottom: '1px solid #fff',
    },
  },
  underline: {
    color: '#fff !important',
  },
  menuPopper: {
    zIndex: theme.zIndex.modal + 10,
  },
  quantityText: {
    color: theme.palette.common.black,
    // fontSize: '16px',
    // variant="body2" weight="medium"
  },
  contactInformation: {
    letterSpacing: 1.1,
  },
  tierHeading: {
    letterSpacing: 1.1,
    marginBottom: 19,
    // marginTop: (props) => (props?.tiers?.length > 1 ? 22 : 0),
    marginTop: 22,
  },
  tierInformation: {
    letterSpacing: '0.04em',
    marginLeft: 10.27,
    marginTop: 12,
    width: (props) => (props.isDeviceSm ? '135%' : '100%'),
  },
  tierIcon: {
    marginTop: 14,
    marginLeft: 21.27,
  },
  tierPrice: {
    marginRight: 14,
    marginTop: 14,
    letterSpacing: 0.64,
  },
  selectedTier: {
    // backgroundColor: theme.palette.secondary.main,
    // color: `${theme.palette.text.secondary} !important`,
    backgroundColor: COLOR.tertiary.music,
    color: `${COLOR.primary.black} !important`,
    borderColor: theme.palette.secondary.main,
    top: 20,
  },
  tierBox: {
    letterSpacing: 0.64,
    cursor: 'pointer',
  },
  testBox: {
    backgroundColor: theme.palette.primary.contrastText,
  },
  title: {
    // marginTop: 38,
    // marginLeft: 40,
    // margin: '38px 0 0 40px',
  },
  disabledButton: {
    backgroundColor: THEME_PALETTE.grey.A100,
    color: `${theme.palette.common.black}!important`,
  },
  alignQtyBox: {
    marginBottom: '20px',
  },
  promoCodeButton: {
    maxWidth: '158px',
  },
  promoCodeInput: {
    '& *:not(button)': {
      fontSize: `${theme.typography.caption.fontSize}!important`,
      fontWeight: `${theme.typography.fontWeightRegular}!important`,
    },
  },
  ageResRoot: { marginTop: theme.spacing(1.5) },
  ageResTitle: { fontWeight: theme.typography.fontWeightBold },
  termsBtn: {
    border: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    textDecoration: 'underline',
    marginTop: -2,
  }
}));

export default EventTicketForm;
