import React, { FC } from 'react';
import { COLOR } from 'Theme/themeConstants';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// eslint-disable-next-line import/no-cycle
import { SVGCIconProps } from '../SVGCIcon';

export interface VimeoIconProps extends SVGCIconProps {
  iconProps?: {
    backgroundColor?: string;
    iconWidth?: number;
    iconHeight?: number;
  };
}
export const VimeoIcon: FC<VimeoIconProps> = (props) => {
  const { iconProps = {}, color = COLOR.primary.white } = props;
  const { iconWidth = 22, iconHeight = 19 } = iconProps;
  const classes = useStyles();
  return (
    <svg
      className={classes.icon}
      width={iconWidth}
      height={iconHeight}
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.665 7.57c-2.17 4.636-7.41 10.95-10.722 10.95-3.264 0-3.736-6.961-5.519-11.595C3.547 4.647 2.98 5.17 1.336 6.32L.333 5.027C2.731 2.919 5.132.469 6.607.333c1.66-.16 2.682.975 3.065 3.406.505 3.194 1.21 8.151 2.443 8.151.96 0 3.325-3.933 3.446-5.338.216-2.059-1.513-2.121-3.014-1.479 2.374-7.781 12.258-6.348 8.118 2.496z"
        fill={color}
      />
    </svg>
  );
};

export const VimeoBG: FC<VimeoIconProps> = (props) => {
  const { iconProps = {}, width = 38, height = 38 } = props;
  const { backgroundColor = '#2196F3' } = iconProps;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 37.666c10.31 0 18.667-8.357 18.667-18.666C37.667 8.69 29.309.333 19 .333 8.69.333.333 8.69.333 19 .333 29.309 8.691 37.666 19 37.666z"
        fill={backgroundColor}
      />
    </svg>
  );
};

export const Vimeo: FC<VimeoIconProps> = (props) => {
  const classes = useStyles();
  return (
    <i className={classes.root}>
      <VimeoBG {...props} />
      <VimeoIcon {...props} />
    </i>
  );
};

export default Vimeo;

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      position: 'relative',
      display: 'inline-block',
    },
    icon: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-55%)',
    },
  })
);
