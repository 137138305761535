/* eslint-disable import/no-cycle */
import { Box, Link } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PgTypo from 'Components/PgTypo';
import { PointsDialogContext } from 'Contexts/PointsDialogContext';
import React, { useContext } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES } from 'Routes';
import { ACCOUNTS_ROUTES } from 'Routes/AccountNavigation';
import { pluralParser } from 'Screens/Search';
import { useStoreActions, useStoreState } from 'Stores';
import { COLOR } from 'Theme/themeConstants';

const NOTION_LOCATION = 'https://playgroundapp.notion.site/playgroundapp/Welcome-to-Playground-1f2501812b614711917d459b7dc56e3f';
export interface TPointsEventData {
  description: string;
  equivalentCredits: number;
  flowId: keyof POINTS_FLOW_MAP;
  isMultiplier: boolean;
  multiplierValue: number;
  receivingPoints: number;
  userId: string;
  meta: Record<string, string>;
}

const usePointsNotification = () => {
  const { showDialog, hideDialog } = useContext(PointsDialogContext);
  const history = useHistory();
  const { appUser, community } = useStoreState(({ AuthStore: { appUser }, CommunityStore: { community } }) => ({ appUser, community }));
  const { setAppUser, addTotalCredits } = useStoreActions(({ AuthStore: { setAppUser, addTotalCredits } }) => ({ setAppUser, addTotalCredits }));

  const classes = useStyles();

  const getDialogContentForFlow = (data: TPointsEventData) => {
    switch (data.flowId) {
      case 'SIGN_UP':
        return {
          title: 'Sign up complete',
          subtitle: (
            <Box display="flex" alignItems="baseline">
              <PgTypo gutterBottom align="center" c2 className={classes.subtitle}>
                You have earned ${data.receivingPoints} Playground {pluralParser('point', data.receivingPoints)}!&nbsp;
                <Link underline="always" href={NOTION_LOCATION} className={classes.subtitle}>
                  What is this?
                </Link>
              </PgTypo>
            </Box>
          ),
          body: `Complete your profile to earn your next point.`,
          actionText: `Take me there!`,
          onAction: () => {
            history.push(ACCOUNTS_ROUTES.INVITE_FRIENDS.path);
            hideDialog();
          },
        };
      case 'COMPLETE_PERSONAL_PROFILE':
        return {
          title: `You’ve earned ${data.receivingPoints} ${pluralParser('point', data.receivingPoints)}`,
          subtitle: (
            <Box display="flex" alignItems="baseline">
              <PgTypo gutterBottom align="center" c2 className={classes.subtitle}>
                Earn more points when friends you invite to Playground sign up!
                <Link underline="always" href={NOTION_LOCATION} className={classes.subtitle}>
                  What is this?
                </Link>
              </PgTypo>
            </Box>
          ),
          // body: `Earn more points when friends you invite to Playground sign up!`,
          actionText: `Invite Friends`,
          onAction: () => {
            history.push(ACCOUNTS_ROUTES.INVITE_FRIENDS.path);
            hideDialog();
          },
        };
      case 'INVITED_USER_SIGN_UP':
        return {
          title: `You’ve earned ${data.receivingPoints} ${pluralParser('point', data.receivingPoints)}`,
          subtitle: `A friend you invited signed up for Playground!`,
          body: `Earn even more points when your invites create communities.`,
          actionText: `Invite More Friends`,
          onAction: () => {
            history.push(ACCOUNTS_ROUTES.INVITE_FRIENDS.path);
            hideDialog();
          },
        };
      case 'INVITED_USER_BECOMES_CURATOR':
        return {
          title: `You’ve earned ${data.receivingPoints} ${pluralParser('point', data.receivingPoints)}`,
          subtitle: `A friend you invited to Playground started a new community.`,

          actionText: `Invite More Friends`,
          onAction: () => {
            history.push(ACCOUNTS_ROUTES.INVITE_FRIENDS.path);
            hideDialog();
          },
        };
      case 'ATTEND_FOLLOWING_COMMUNITY_DROP':
        return {
          title: `You’ve earned ${data.receivingPoints} ${pluralParser('point', data.receivingPoints)}`,
          subtitle: `Earn more points when you join and participate in new communities!`,
          actionText: `Browse communities`,
          onAction: () => {
            history.push(APP_ROUTES.COMMUNITIES.path);
            hideDialog();
          },
        };
      case 'APPROVED_CREATOR_PUBLISH_FIRST_COMMUNITY':
        return {
          title: `You’ve earned ${data.receivingPoints} ${pluralParser('point', data.receivingPoints)}`,
          subtitle: `Earn 5 more points when you create your first drop!`,
          body: `FYI: points can be used as credits toward an upgraded Creator plan later on 🤑`,
          actionText: `Add drop`,
          onAction: () => {
            if (data.meta?.communitySlug || appUser?.userCommunities?.[0]?.slug)
              history.push(`/${data.meta.communitySlug || appUser?.userCommunities?.[0]?.slug}/dashboard/overview?openAddcontent=true`);
            hideDialog();
          },
        };
      case 'INVITED_CURATOR_WHICH_GREW_FOLLOW_100':
        return {
          title: `You now have ${data.receivingPoints} ${pluralParser('point', data.receivingPoints)}`,
          subtitle: ` Whoa! A creator you invited has grown their community big time!`,
          body: `As a way to say thanks, we’ve 2X’d the points you’ve earned this month.`,
          actionText: `Invite more creators`,
          onAction: () => {
            history.push(ACCOUNTS_ROUTES.INVITE_FRIENDS.path);
            hideDialog();
          },
        };

      case 'OWN_COMMUNITY_OVER_100':
        return {
          title: `You’ve earned ${data.receivingPoints} ${pluralParser('point', data.receivingPoints)}`,
          subtitle: `Your community is growing!`,
          body: `As you grow, and as your members participate in your community, the more you earn.`,
          actionText: `Invite new members to join`,
          onAction: () => {
            history.push(ACCOUNTS_ROUTES.INVITE_FRIENDS.path);
            hideDialog();
          },
        };
      case 'CREATOR_COMMUNITY_OVER_500':
      case 'CREATOR_COMMUNITY_OVER_1000':
        return {
          title: `You’ve earned ${data.receivingPoints} ${pluralParser('point', data.receivingPoints)}`,
          subtitle: `Your community is on fire`,
          body: `As a thx for being so awesome, we’ve 2X’d the points you’ve earned this month.`,
          actionText: `Invite more members to join`,
          onAction: () => {
            history.push(ACCOUNTS_ROUTES.INVITE_FRIENDS.path);
            hideDialog();
          },
        };
      case 'PUBLISH_DROP_IN_COMMUNITY':
        return {
          title: `You’ve earned ${data.receivingPoints} ${pluralParser('point', data.receivingPoints)}`,
          subtitle: `How good is that!? `,
          body: `Add drops and invite community members to earn more points.`,
          actionText: `Invite members to join`,
          onAction: () => {
            history.push(
              generatePath(`${APP_ROUTES.COMMUNITY_DETAIL.path}/?openInvite=true`, {
                communityId: community?.slug ?? community?.id ?? data.meta.communitySlug ?? '#',
              })
            );
            hideDialog();
          },
        };
      case 'SUI_NFT_TICKET_PURCHASE':
        return {
          title: 'Congrats!',
          subtitle: 'You have earned 5 points!',
          body: 'FYI: points can be used as credits toward an upgrade and as loyalty points for rewards!',
          actionText: 'gotcha',
          onAction: hideDialog,
        }
      default:
        return {
          title: 'Profile complete',
          subtitle: `You have earned ${data.receivingPoints} Playground ${pluralParser('point', data.receivingPoints)}! WTF is this?`,
          body: `Earn more points when friends you invite to Playground sign up!`,
          actionText: `Invite Friends`,
          action: () => {
            history.push(ACCOUNTS_ROUTES.INVITE_FRIENDS.path);
            hideDialog();
          },
        };
    }
  };
  const notify = (data: TPointsEventData) => {
    showDialog({ open: true, ...getDialogContentForFlow(data) });
    if (appUser) {
      addTotalCredits(data?.receivingPoints || 0);
    }
  };

  return {
    notify,
  };
};
export default usePointsNotification;

export type POINTS_FLOW_MAP = {
  SIGN_UP: 'SIGN_UP';
  COMPLETE_PERSONAL_PROFILE: 'COMPLETE_PERSONAL_PROFILE';
  INVITED_USER_SIGN_UP: 'INVITED_USER_SIGN_UP';
  INVITED_USER_BECOMES_CURATOR: 'INVITED_USER_BECOMES_CURATOR';
  ATTEND_FOLLOWING_COMMUNITY_DROP: 'ATTEND_FOLLOWING_COMMUNITY_DROP';
  APPROVED_CREATOR_PUBLISH_FIRST_COMMUNITY: 'APPROVED_CREATOR_PUBLISH_FIRST_COMMUNITY';
  INVITED_CURATOR_WHICH_GREW_FOLLOW_100: 'INVITED_CURATOR_WHICH_GREW_FOLLOW_100';
  CREATOR_COMMUNITY_OVER_500: 'CREATOR_COMMUNITY_OVER_500';
  CREATOR_COMMUNITY_OVER_1000: 'CREATOR_COMMUNITY_OVER_1000';
  QUATERLY_FEATURED: 'QUATERLY_FEATURED';
  OWN_COMMUNITY_OVER_100: 'OWN_COMMUNITY_OVER_100';
  PUBLISH_DROP_IN_COMMUNITY: 'PUBLISH_DROP_IN_COMMUNITY';
  SUI_NFT_TICKET_PURCHASE: 'SUI_NFT_TICKET_PURCHASE';
};

const useStyles = makeStyles<Theme>(() => {
  return createStyles({
    subtitle: {
      color: COLOR.tertiary.activism,
    },
  });
});
