import { IconButton, Portal, Theme } from '@material-ui/core';
import { Repeat } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import BasicActionDialog from 'Dialogs/BasicActionDialog';
import useUserActions from 'Features/User/useUserActions';
import { OnDisplayEntity, TContentType, TDrop } from 'Models/Drop/@types';
import EventModel, { TEvent } from 'Models/Event';
import React, { FC, useState } from 'react';
import { COLOR } from 'Theme/themeConstants';
import AccountSelect from './AccountSelect';
import useReplayActions from './useReplayActions';

export interface ReplayButtonProps {
  drop: TDrop;
  dropType: TContentType;
  onDone?: () => void;
  isDisabled?: boolean;
}

const ReplayButton: FC<ReplayButtonProps> = (props) => {
  const { drop, dropType, onDone, isDisabled = false } = props;
  const classes = useStyles({ isReplayed: !!drop.isReplayed });
  const [open, setOpen] = useState(false);

  const { handleReplayActions: handleToggleReplay } = useReplayActions();
  const { isUserEligible } = useUserActions();

  const replayValues: OnDisplayEntity[] =
    drop?.replayedOn?.map((r) => ({
      onDisplayEntityId: r.onDisplayEntityId,
      onDisplayEntityType: r.onDisplayEntityType,
    })) ?? [];

  const handleReplayClick = async () => {
    const isEligible = await isUserEligible();
    if (isEligible) setOpen(true);
  };

  const handleSubmit = async (entities: OnDisplayEntity[]) => {
    try {
      console.log(dropType);
      await handleToggleReplay(drop, dropType, entities);
      onDone?.();
    } catch (error) {
      //error handling
      console.error(error);
    }
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleReplayClick} className={classes?.replayBtn} disabled={isDisabled}>
        <Repeat fontSize="small" className={classes?.replayIcon} />
      </IconButton>

      <Portal>
        <BasicActionDialog
          open={open}
          onClose={() => setOpen(false)}
          title={
            drop.isReplayed ? `Remove replay` : `Replay ${EventModel.isEvent(drop) || dropType === 'Event' ? (drop as TEvent).name : drop.title}`
          }
          subtitle={drop.isReplayed ? undefined : `Display this drop on your personal or community profile. Select one or more`}
          additionalComponent={
            <AccountSelect isReplayed={drop.isReplayed} value={replayValues} onCancel={() => setOpen(false)} onSubmit={handleSubmit} />
          }
        />
      </Portal>
    </>
  );
};

export default ReplayButton;

const useStyles = makeStyles<Theme, { isReplayed: boolean }>((theme) => ({
  replayBtn: {
    backgroundColor: (props) => (props.isReplayed ? COLOR.tertiary.music : theme.palette.common.white),
    padding: theme.spacing(1),
    '&:hover svg': {
      color: theme.palette.common.black,
    },
  },
  replayIcon: {
    color: theme.palette.common.black,
  },
}));
