/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Drawer, List, ListItem } from '@material-ui/core';
import React, { FC, useMemo, useEffect, useContext } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PG_LOGO from 'Assets/images/pg_logo_1.svg';
import { APP_HEADER_HEIGHT } from 'Features/AppHeader';
import { useStoreActions, useStoreState } from 'Stores';
import { TUserCommunity } from 'Models/User/@types';
import ToLeft from 'Assets/icons/sidebar/to_left.svg';
import { generatePath, useHistory, useLocation } from 'react-router';
import EventQuery from 'Features/Query/EventQuery';
import { DialogContext } from 'Dialogs/DialogContext';
import { get } from 'lodash';
import { appNavigator } from 'Utils/routeUtils';
import AdminConsoleDefaultSidebar from 'Screens/Dashboard/CreatorRequests/Sidebar/AdminConsoleDefaultSidebar';
import RoleUtils from 'Utils/RoleUtils';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { NO_PRINT_CLASS } from 'Constants/variables';
import DefaultSidebar from './DefaultSidebar';
import ManagedCommunity from './ManagedCommunity';
import { APP_ROUTES } from '../../Routes/index';
import JoinedCommunity from './JoinedCommunity';
import { EVENT_MEDIA_ROUTES } from 'Routes/DashboardRoutes/EventsAndMediaNavigation';
import { TDropType } from 'Models/Drop/@types';
import DropFormSidebar from 'Screens/Dashboard/DropFormScreen/Sections/DropFormSidebar';
import Dark from 'Features/DarkTheme';

export const DRAWER_SIDEBAR_COLLAPSED_WIDTH = 70;
export const DRAWER_SIDEBAR_EXTENDED_WIDTH = 300;

export interface DrawerSidebarProps {
  open: boolean;
}

const DrawerSidebar: FC<DrawerSidebarProps> = ({ open }) => {
  const history = useHistory();
  const { appUser, sidebarStep, community } = useStoreState(
    ({ AuthStore: { appUser }, DrawerSidebarStore: { sidebarStep }, CommunityStore: { community } }) => ({
      appUser,
      sidebarStep,
      community,
    })
  );

  const { setSidebarStep, setOpen } = useStoreActions(({ DrawerSidebarStore: { setSidebarStep, setOpen } }) => ({
    setSidebarStep,
    setOpen,
  }));

  const { addContent } = useContext(DialogContext);

  const { search, pathname } = useLocation();
  useEffect(() => {
    const eventQuery = EventQuery.fromQuery(search);
    const isOpenAddcontent = get(eventQuery, '_query.openAddcontent');
    if (isOpenAddcontent) {
      addContent();
    }
  }, []);

  useEffect(() => {
    if (community?.id && appUser?.id) {
      if (community.userId === appUser.id) setSidebarStep('managedCommunity');
      else setSidebarStep('joinedCommunity');
    } else {
      setSidebarStep('default');
    }
  }, [community?.id, appUser?.id]);

  const classes = useStyles({ open });

  const urlParams = useParams<{ dropType: TDropType, dropId: string, communityId: string }>();

  const handleClick = async (community: TUserCommunity) => {
    // setSidebarStep('managedCommunity');
    // if (!appUser) return;
    // const stripe = await CommunityModel.getStripeAccount(community?.id ?? '');
    // setAppUser({
    //   ...appUser,
    //   currentCommunity: { ...community, isStripeConnected: !!stripe?.account?.payouts_enabled },
    // });
    appNavigator.communityProfile(community);
  };

  const handleBack = () => {
    setSidebarStep('default');
    history.push(APP_ROUTES.HOME_PAGE.path);
  };

  const component = useMemo(() => {
    if (RoleUtils.isSuperAdmin(appUser)) {
      return <AdminConsoleDefaultSidebar open={open} />;
    }

    const { dropType, dropId, communityId } = urlParams;
    if (
      dropType && communityId && pathname === generatePath(EVENT_MEDIA_ROUTES.NEW_DROP_FORM.path, { ...urlParams })
      || dropId && dropType && communityId && pathname === generatePath(EVENT_MEDIA_ROUTES.EDIT_DROP_FORM.path, { ...urlParams })
      || (history.location.state as any)?.['sideBarState'] === 'dropForm'
    ) return <DropFormSidebar onChange={setOpen} />;

    switch (sidebarStep) {
      case 'default':
        return <DefaultSidebar onClick={handleClick} handleChange={setOpen} />;
      case 'managedCommunity':
        return <ManagedCommunity onChange={setOpen} onBack={handleBack} />;
      case 'joinedCommunity':
        return <JoinedCommunity onChange={setOpen} />;
      default:
        return null;
    }
  }, [open, sidebarStep, pathname, urlParams, history.location.state]);

  return (
    <>
      <ListItem className={classes.sidebarHeader}>
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
          {/* <a href="/"> */}
          <Link
            to={APP_ROUTES.HOME_PAGE.path}
            // onMouseEnter={mouseOver}
            // onMouseLeave={mouseOut}
            className={classes.logo}
          >
            {/* {isMouseOver ? (
                <LottieImage lotteJSON={PG_LOGO_ANIMATED} dimension={{ width: '100%', height: `${APP_HEADER_HEIGHT}px` }} />
              ) : ( */}
            <img src={PG_LOGO} height={APP_HEADER_HEIGHT} alt="PG_Logo" />
            {/* )} */}
          </Link>
          {/* </a> */}

          {!open ? null : (
            <Box mr={1} onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
              <img src={ToLeft} alt="" />
            </Box>
          )}
        </Box>
      </ListItem>
      <Drawer variant="permanent" open={open} className={clsx(classes.root, NO_PRINT_CLASS)}>
        <Dark>
          <List className={classes.drawerList}>{component}</List>
        </Dark>
      </Drawer>
    </>
  );
};

export default DrawerSidebar;

const useStyles = makeStyles<Theme, { open: boolean }>((theme) =>
  createStyles({
    drawerList: {
      paddingTop: 62,
    },
    root: {
      '& .MuiDrawer-paper': {
        width: (props) => (props.open ? DRAWER_SIDEBAR_EXTENDED_WIDTH : DRAWER_SIDEBAR_COLLAPSED_WIDTH),
        overflowX: 'hidden',
        borderRight: `1px solid ${theme.palette.common.white}`,
        '& > div': { flex: '1 1 0%' },
      },
      transition: '200ms ease-in',
    },
    sidebarHeader: {
      backgroundColor: theme.palette.common.black,
      height: APP_HEADER_HEIGHT,
      padding: '0 5px',
      borderRight: `1px solid ${theme.palette.common.white}`,
      borderBottom: `1px solid ${theme.palette.common.white}`,
      top: 0,
      width: (props) => (props.open ? DRAWER_SIDEBAR_EXTENDED_WIDTH : DRAWER_SIDEBAR_COLLAPSED_WIDTH),
      zIndex: theme.zIndex.modal + 1,
      position: 'fixed',
    },
    mainNav: {
      padding: theme.spacing(2, 0),
      borderBottom: `1px solid ${theme.palette.common.black}`,
    },
    listItem: {
      padding: theme.spacing(2),
      marginLeft: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
    },
    listItemSm: {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
    },
    pgIcon: {
      fontSize: 30,
    },
    communityImgSm: {
      width: 32,
      height: 32,
      objectFit: 'cover',
      borderRadius: 8,
      border: `2px solid ${theme.palette.common.black}`,
      marginRight: (props) => (props.open ? 12 : 0),
    },
    logo: {
      height: APP_HEADER_HEIGHT,
      marginLeft: 5,
    },
  })
);
