import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { ChevronLeft, Close } from '@material-ui/icons';
import Typo from 'Components/Typo';
import { BORDER_WHITE, THEME_PALETTE } from 'Theme/themeConstants';

export interface MenuHeaderProps {
  title: string;
  titleClassName?: string;
  onClose?: () => void;
  onBack?: () => void;
}

const MenuHeader: FC<MenuHeaderProps> = (props) => {
  const { title, onBack, onClose, titleClassName } = props;

  const classes = useStyles({});

  return (
    <Box className={classes.root} px={2} borderBottom={BORDER_WHITE}>
      <Box display="flex" alignItems="center">
        {onBack ? (
          <Box onClick={onBack} display="flex">
            <ChevronLeft htmlColor="#FFF" />
          </Box>
        ) : null}
        <Box>
          <Typo contrast weight="regular" className={titleClassName}>
            {title}
          </Typo>
        </Box>
      </Box>
      {onClose ? (
        <Box onClick={onClose} display="flex">
          <Close htmlColor="#FFF" />
        </Box>
      ) : null}
    </Box>
  );
};

export const MOBILE_MENU_HEADER_HEIGHT = 50;

export default MenuHeader;

const useStyles = makeStyles<Theme, any>((theme) =>
  createStyles({
    root: {
      height: MOBILE_MENU_HEADER_HEIGHT,
      width: '100%',
      backgroundColor: THEME_PALETTE.common.black,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  })
);
