import { useContext } from 'react';
import { AppToastContext } from 'Contexts/AppToastContext';
import MESSAGES from 'Utils/Messages';
import ToastMessage from 'Components/ToastMessage';
import { useSnackbar } from 'notistack';
import get from 'lodash/get';
import useToastMessage from '../../Hooks/useToastMessage';
import usersModel, { InvitationTypes } from '../../Models/User';
import { useStoreState } from '../../Stores';
import UserModel from '../../Models/User';

function useAppUserActions() {
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({
    appUser,
  }));
  const withToast = useToastMessage();
  const { showToast } = useContext(AppToastContext);
  const { enqueueSnackbar } = useSnackbar();
  const inviteUsersToCommunity = async (emails: string[], communityId: string, redirectUrl?: string, autoVerify?: boolean) => {
    try {
      const data = await usersModel.inviteMultipleUsersToCommunity(emails, 'email', communityId, redirectUrl, autoVerify);
      enqueueSnackbar(
        ToastMessage({
          title: 'Ding! Your invite was delivered.',
          subtitle: 'Earn points when your friends sign up. More invites? More points. ',
        }),
        { variant: 'success' },
      );
    } catch (err) {
      const errorMessage = get(err, 'response.data.error.message');
      // enqueueSnackbar(errorMessage || 'Something went wrong.', { variant: 'error' });
      enqueueSnackbar(
        errorMessage ||
          ToastMessage({
            title: 'An email address you entered needs attention.',
            subtitle: 'Please check to make sure the email addresses you included are correct.',
          }),
        { variant: 'error' },
      );
    }
  };
  const inviteUsers = async (emails: string[], invitationTo: InvitationTypes, redirectUrl?: string, subjectId?: string) => {
    if (!appUser) return;
    try {
      const data = await usersModel.postUsersInviteUser(emails, 'email', invitationTo, redirectUrl, subjectId);
      // const successCount = data.filter((d) => d.status === 'fulfilled').length;
      // const errorCount = data.filter((d) => d.status === 'rejected').length;
      // if (errorCount) enqueueSnackbar('Some invites could not be sent', { variant: 'error' });
      // if (successCount)
      //   enqueueSnackbar(
      //     ToastMessage({
      //       title: 'Invites sent successfully',
      //       subtitle: `You have sent ${successCount} invites successfully. Send more invites to receive points when they sign up!`,
      //     }),
      //     { variant: 'success' }
      //   );
      enqueueSnackbar(
        ToastMessage({
          title: MESSAGES.INVITE_SENT_SUCCESS_TITLE,
          subtitle: MESSAGES.INVITE_SENT_SUCCESS_SUBTITLE,
        }),
        { variant: 'success' },
      );
    } catch (err) {
      const errorMessage = get(err, 'response.data.error.message');
      // enqueueSnackbar(errorMessage || 'Something went wrong.', { variant: 'error' });
      enqueueSnackbar(
        errorMessage ||
          ToastMessage({
            title: MESSAGES.INVITE_SENT_ERROR_TITLE,
            subtitle: MESSAGES.INVITE_SENT_ERROR_SUBTITLE,
          }),
        { variant: 'error' },
      );
    }
  };

  const followUser = async (userId: string): Promise<string> => {
    if (!appUser) return '';
    const { data } = await withToast(async () => UserModel.postUsersIdFollowUserId(appUser.id, userId), {
      successToastMessage: MESSAGES.FOLLOWED_SUCCESSFULLY,
    });
    return data?.id || '';
  };

  const unfollowUser = async (followId: string) => {
    if (!appUser) return;
    // const { data } = await withToast(async () => await FollowsModel.dete(followId), {
    //   successToastMessage: MESSAGES.UNFOLLOWED_SUCCESSFULLY,
    // });
    // return data;
  };

  const followCommunity = async (communityId: string, communityName: string) => {
    if (!appUser) return null;
    // const { data } = await withToast(async () => CommunityModel.follow(appUser.id, communityId), {
    //   successToastMessage: `You have joined @${communityName} successfully.`,
    // });
    // return data?.id || '';
  };

  return {
    followUser,
    unfollowUser,
    inviteUsers,
    followCommunity,
    inviteUsersToCommunity,
  };
}

export default useAppUserActions;
function enqueueSnackbar(arg0: string, arg1: { variant: string }) {
  throw new Error('Function not implemented.');
}
