import DropQuery, { TDropQuery } from 'Features/Query/DropsQuery';
import useAsyncTask from 'Hooks/useAsyncTask';
import { TDrop } from 'Models/Drop/@types';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'Stores';

export interface IDropsListHookProps {
  drops: TDrop[];
  total?: number;
  isLoading: boolean;
  hasMore?: boolean;
  loadMore: () => Promise<void>;
  setQuery: (dropQuery: DropQuery) => Promise<void>;
  query: DropQuery;
  init: () => Promise<void>;
  fetchList?: () => Promise<void>;
  onFilterChange?: (query: TDropQuery) => Promise<void>;
  clearFilters?: () => Promise<void>;
  setDropList?: (params: { drop: TDrop[] }) => void;
  updateDropList?: (params: { drop: TDrop; action: 'ADD' | 'UPDATE' | 'PUT' | 'DELETE' }) => void;
}

export interface DropListConfig {
  route: string;
}

export const useDropsList = ({ route }: DropListConfig): IDropsListHookProps => {
  const { search } = useLocation();
  const history = useHistory();
  const [dropQuery, setDropQuery] = useState<DropQuery>(DropQuery.fromQuery(search));
  const { drops = [], hasMore, total } = useStoreState(({ DropsStore: { drops, hasMore = true, total } }) => ({ drops, hasMore, total }));
  const { fetchDrops, setDropList, updateDropList } = useStoreActions(({ DropsStore: { fetchDrops, setDropList, updateDropList } }) => ({
    fetchDrops,
    setDropList,
    updateDropList,
  }));
  const fetchDropsRunner = useAsyncTask(fetchDrops);

  useEffect(() => {
    setDropQuery(DropQuery.fromQuery(search));
  }, [search]);

  const loadMore = async () => {
    if (fetchDropsRunner.status === 'PROCESSING') return;
    fetchDropsRunner.run({
      appendResult: true,
      query: dropQuery,
    });
  };
  const setQuery = async (dropQuery: DropQuery, isRefreshList = true) => {
    setDropQuery(dropQuery);
    if (isRefreshList) history.push(`${route}?${dropQuery.queryString}`);
  };
  const init = async () => {
    fetchList();
  };
  const fetchList = async (query?: DropQuery) => {
    fetchDropsRunner.run({
      appendResult: false,
      query: query ?? dropQuery,
    });
  };
  const clearFilters = async () => {
    setQuery(new DropQuery({}));
    fetchList();
  };

  const onFilterChange = async (filterQuery: TDropQuery) => {
    setQuery(dropQuery.clone(filterQuery));
  };
  return {
    drops,
    isLoading: fetchDropsRunner.status === 'PROCESSING',
    hasMore,
    loadMore,
    setQuery,
    query: dropQuery,
    init,
    onFilterChange,
    fetchList,
    clearFilters,
    setDropList,
    total,
    updateDropList,
  };
};
