import { PublicKey } from '@solana/web3.js';
import { useState, useEffect, useRef } from 'react';
import { PhantomProvider, WindowWithSolana } from './types';

export const useProvider = () => {
  const [provider, setProvider] = useState<PhantomProvider | null>(null);

  useEffect(() => {
    if ('solana' in window) {
      const solWindow = window as WindowWithSolana;
      if (solWindow?.solana?.isPhantom) {
        setProvider(solWindow.solana);
        // setWalletAvail(true);
      }
    }
  }, []);
  return provider;
};
