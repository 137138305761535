import { Box, CircularProgress, IconButton, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PgButton from 'Components/PgButton';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import UserListItem from 'Components/UserListItem';
import { debounce } from 'lodash';
import { User } from 'Models/User';
import React, { FC, useEffect, useRef, useState } from 'react';
import { DIVIDER_BORDER_BLACK } from 'Theme/themeConstants';

interface UserListData {
  users: User[];
  total: number;
}

export type FetchUsersParams = { term: string; skip: number };

export interface UserSearchProps {
  fetchData: (params: FetchUsersParams) => Promise<UserListData>;
  onUserClick?: (user: User) => void;
}

const UserSearch: FC<UserSearchProps> = ({ fetchData, onUserClick }) => {
  const classes = useStyles();
  const [usersData, setUsersData] = useState<UserListData>({ total: 0, users: [] });
  const [filterData, setFilterData] = useState<FetchUsersParams>({ skip: 0, term: '' });
  const [loading, setLoading] = useState<boolean>(false);
  const debouncedSearch = useRef(
    debounce(async (filter: FetchUsersParams) => {
      setLoading(true);
      try {
        const resp = await fetchData(filter);
        if (filter.skip === 0) setUsersData({ ...resp });
        else setUsersData((e) => ({ ...resp, users: [...e.users, ...resp.users] }));
      } catch (err) {
        setUsersData({ total: 0, users: [] });
      } finally {
        setLoading(false);
      }
    }, 500),
  ).current;
  useEffect(() => {
    debouncedSearch(filterData);
  }, [filterData]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterData({ skip: 0, term: e.target.value });
  };
  const onClear = () => {
    if (filterData.term) setFilterData({ skip: 0, term: '' });
  };
  const handlePageChange = () => {
    setFilterData((e) => ({ ...e, skip: usersData.users.length }));
  };

  return (
    <Box className={classes.root}>
      <TextField
        fullWidth
        onChange={handleChange}
        value={filterData.term}
        placeholder="Search"
        InputProps={{
          endAdornment: (
            <IconButton onClick={onClear} size="small">
              <PgIcon icon={filterData.term ? 'icon-close' : 'icon-search'} />
            </IconButton>
          ),
        }}
      />
      {filterData.skip === 0 && loading ? (
        <Box my={1} display="flex" justifyContent={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box my={2} className={'userContainer'}>
            {usersData.users.map((item) => {
              return (
                <UserListItem
                  style={{ cursor: 'pointer' }}
                  onClick={() => onUserClick?.(item)}
                  py={1}
                  borderBottom={DIVIDER_BORDER_BLACK}
                  key={item.slug}
                  user={item}
                />
              );
            })}
            <Box my={1} display="flex" justifyContent={'center'}>
              {loading ? (
                <CircularProgress />
              ) : usersData.users.length < usersData.total ? (
                <PgButton onClick={handlePageChange}>
                  <PgTypo b4Bold>Load More </PgTypo>
                </PgButton>
              ) : null}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  });
});

export default UserSearch;
