import React, { FC } from 'react';
import { Box, makeStyles, MenuItemProps } from '@material-ui/core';
import {
  IFieldProps,
  MenuOptions,
  MUISelectField,
  MUITextField,
} from 'react-forms';
import { ArrayHelpers } from 'formik';
import { useMediaQuery } from 'Hooks/useMediaQuery';

/*
 * The component is intended to be used within a FieldArray. It is not expected to work properly outside a fieldArray
 * It will add objects of the format { code: string, value: string, type: string } to the array with the given valueKey
 */

interface PromoCodeFieldProps {
  codeLabel?: string;
  amountLabel?: string;
  typeLabel?: string;
  typeOptions?: MenuOptions<MenuItemProps>;
  disabled?: boolean;
}

interface PromoCodeProps extends IFieldProps {
  fieldProps?: PromoCodeFieldProps;
  name?: string;
  itemIndex?: number;
  arrayHelpers?: ArrayHelpers;
}

const PromoCode: FC<PromoCodeProps> = (props) => {
  const { fieldProps = {}, name = '', formikProps, itemIndex = 0 } = props;
  const {
    codeLabel = 'Promotional Code',
    amountLabel = 'Amount/Percent Discounted',
    typeLabel = '',
    typeOptions = defaultTypeOptions,
    disabled = false,
  } = fieldProps;
  const classes = styles();
  const {isDeviceSm} = useMediaQuery();

  return (
    <Box mt={5} display={isDeviceSm ? 'block' : "flex"} mr={isDeviceSm ? 5 : 2.5}>
      <Box flex={1}>
        <MUITextField
          fieldProps={{
            label: codeLabel,
            variant: 'outlined',
            fullWidth: true,
            name: `${name}[${itemIndex}].code`,
            required: true,
            disabled,
          }}
          formikProps={formikProps}
        />
      </Box>
      <Box flex={1} display="flex" ml={isDeviceSm ? 0 : 2.5} mt={isDeviceSm ? 5 : 0}>
        <MUITextField
          fieldProps={{
            type: 'number',
            label: amountLabel,
            variant: 'outlined',
            className: classes.value,
            name: `${name}[${itemIndex}].discountValue`,
            required: true,
            disabled
          }}
          formikProps={formikProps}
        />
        <div className={classes.divider} />
        <MUISelectField
          fieldProps={{
            label: typeLabel || ' ',
            options: typeOptions,
            variant: 'outlined',
            className: classes.types,
            name: `${name}[${itemIndex}].discountType`,
            disabled
          }}
          formikProps={formikProps}
        />
      </Box>
    </Box>
  );
};

export default PromoCode;

const styles = makeStyles((theme) => ({
  types: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: 72,
    minWidth: 72,
    '&>div': {
      padding: '9.25px 14px',
      height: 37,
    },
  },
  value: {
    width: 188,
    [theme.breakpoints.down('sm')]: {
      width: 100,
    },
    '&>div': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      minWidth: 188,
      [theme.breakpoints.down('sm')]: {
        minWidth: 100,
      }
    },
    '&>label': {
      width: 210,
    },
    [theme.breakpoints.down('sm')]: {
      flex: 1
    },
  },
  divider: {
    backgroundColor: theme.palette.grey['200'],
    width: 1,
  },
}));

const defaultTypeOptions = [
  {
    name: '%',
    value: 'percent',
  },
  {
    name: '$',
    value: 'flat',
  },
];
