import { Box, List, ListItem } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PgButton from 'Components/PgButton';
import PgTypo from 'Components/PgTypo';
// import { AuthDialogContext } from 'Contexts/AuthDialogContext';
import Spacer from 'Components/Spacer';
import useNavigation from 'Features/AppHeader/useNavigation';
import { Step } from 'Features/Auth/@types';
import useAuthDialog from 'Features/Auth/useAuthDialog';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import MenuHeader from './MenuHeader';
import { TPgIcon } from 'Components/PgIcon';

export interface LogOutMenuProps {
  onClose: () => void;
}

export interface MobileMenuItem {
  id: string | number;
  name: string | React.ReactNode;
  link?: string;
  allowForDeactivated?: boolean;
  pgIconName?: TPgIcon;
  imgSrc?: string;
  imgAlt?: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  isExternal?: boolean;
}

const LogOutMenu: FC<LogOutMenuProps> = (props) => {
  const { onClose } = props;

  const classes = useStyles({});

  const { openDialog } = useAuthDialog(() => onClose());
  const { NAVLINKS } = useNavigation();

  const history = useHistory();

  const handleBtnClick = (state?: Step) => () => {
    // onClose(); // do not close dialog, it will unmount the dialog and openDialog wont work
    openDialog(state, { setStep: state }).catch(() => {});
  };

  const handleListItemClick = (link: string) => {
    onClose();
    history.push(link);
  };

  return (
    <Box>
      <Box position="sticky" top={0}>
        <MenuHeader title="Menu" onClose={onClose} />
      </Box>
      <List style={{ padding: 0 }}>
        {NAVLINKS.map((m) => {
          return (
            <ListItem key={m.id} onClick={() => handleListItemClick(m.url)} className={classes.ListItem}>
              <PgTypo c1>{m.label}</PgTypo>
            </ListItem>
          );
        })}
      </List>
      <Box py={4} px={3}>
        <PgButton primary fullWidth onClick={handleBtnClick()} className={classes.btn}>
          LOG IN
        </PgButton>
        <Spacer height={10} />
        <PgButton secondary fullWidth onClick={handleBtnClick('signup')} className={classes.btn}>
          SIGN UP
        </PgButton>
      </Box>
    </Box>
  );
};

export default LogOutMenu;

const useStyles = makeStyles<Theme, any>((theme) =>
  createStyles({
    ListItem: {
      padding: theme.spacing(3),
      cursor: 'pointer',
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    btn: {
      // color: THEME_PALETTE.common.white,
      // fontWeight: theme.typography.fontWeightBold,
    },
  }),
);
