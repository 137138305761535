import ToastMessage from "Components/ToastMessage";
import { OptionsObject, useSnackbar } from "notistack"

function useShowToast() {

  const { enqueueSnackbar } = useSnackbar();

  return (title: string, subtitle?: string, variant: OptionsObject['variant'] = 'info') =>
    enqueueSnackbar(ToastMessage({ title, subtitle }), { variant });

}

export default useShowToast
