import React, { FC, useMemo } from 'react';
import { Box, Avatar } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import PgTypo from 'Components/PgTypo';
import { BORDER_BLACK } from 'Theme/themeConstants';
import useConfirmationDialog from 'Hooks/useConfirmationDialog';
import CollaborationRequestModel from 'Models/CollaborationRequest';
import { TCollaborationRequest } from 'Models/CollaborationRequest/@types';
import useAsyncTask from 'Hooks/useAsyncTask';
import { AvatarGroup } from '@material-ui/lab';
import { useStoreState } from 'Stores';
import { ResourceDocType } from 'Features/Query/ResourceQuery';
import { TEvent } from 'Models/Event/@types';
import { TResource } from 'Models/Resource/@types';
import get from 'lodash/get';
import { TCommunity } from 'Models/Community/@types';
import PgButton from 'Components/PgButton';

export interface CollaborationDropProps {
  drop: TEvent | TResource;
  handleDropChange: (drop: TEvent | TResource) => void;
  dropType: ResourceDocType | 'Event';
}
export interface CollaborationRequestProps extends CollaborationDropProps {}

const getDropName = (drop: TEvent | TResource) => {
  return get(drop, 'name') || get(drop, 'title');
};

const CollaborationRequest: FC<CollaborationRequestProps> = ({ drop, handleDropChange }) => {
  const classes = useStyles();
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
  const withConfirm = useConfirmationDialog();
  const requests = useMemo(
    () =>
      (drop?.collaborationRequestInfo || []).filter(
        (i) => i.status === 'PENDING' && appUser?.userCommunities?.findIndex((ii) => ii.id === i.subjectId) !== -1,
      ),
    [drop?.collaborationRequestInfo, appUser?.userCommunities],
  );
  const onStatusChange = useAsyncTask(async (param: { request: TCollaborationRequest; status: TCollaborationRequest['status'] }) => {
    const { request } = param;
    await CollaborationRequestModel.updateStatus(request.id, param.status);
    if (drop && request) {
      const newDrop: typeof drop = {
        ...drop,
        collaborationRequestInfo: drop.collaborationRequestInfo.filter((i) => i.id !== request.id),
        collaborators: request.subject && param.status === 'ACCEPTED' ? [...drop.collaborators, request.subject as TCommunity] : drop.collaborators,
      };
      handleDropChange(newDrop);
    }
  });
  const handleAcceptClick = (request: TCollaborationRequest) => {
    if (!request) return;

    withConfirm(
      async () => {
        try {
          await onStatusChange.run({ status: 'ACCEPTED', request });
        } catch (err) {
          // ERROR
        }
      },
      {
        message: 'Accept invitation',
        agreeText: 'ACCEPT',
        body: 'You will be displayed as a collaborator on this drop and you’ll have administrative access to edit some basic features.',
      },
      {
        successToastMessage: 'Successfully added as a collaborator on this drop',
      },
    );
  };
  const handleDenyClick = (request: TCollaborationRequest) => {
    if (!request) return;

    withConfirm(
      async () => {
        try {
          await onStatusChange.run({ status: 'DENIED', request });
        } catch (err) {
          // ERROR
        }
      },
      {
        message: 'Decline invitation',
        agreeText: 'DECLINE',
        body: 'Are you sure you want to decline the invitation to collaborate on this drop?',
      },
      {
        successToastMessage: 'Successfully declined',
      },
    );
  };
  const eventCommunityProfile = useMemo(() => {
    return drop?.community?._profileImages?.[0]?.url;
  }, [drop]);
  if (!drop) return <div />;

  return (
    <Box width={'100%'}>
      {requests.map((request) => {
        const comm = appUser?.userCommunities?.find((i) => i.id === request.subjectId);
        const myCommunityProfile = comm?._profileImages?.[0]?.url;
        /**Snoopverse invited @name to be displayed as a collaborator on House I Built Concert */
        const text = `${drop?.community?.name} invited ${comm?.name || 'you'} to be displayed as a collaborator on ${getDropName(drop)}`;

        return (
          <Box key={request.id} className={classes.root} display="flex" alignItems="center">
            {eventCommunityProfile || myCommunityProfile ? (
              <Box mr={1}>
                <AvatarGroup max={3} spacing="small">
                  <Avatar className={classes.img} alt={request.subject?.name} src={eventCommunityProfile} />
                  <Avatar className={classes.img} alt={drop.community?.name} src={myCommunityProfile} />
                </AvatarGroup>
              </Box>
            ) : null}
            <Box flex={1}>
              <PgTypo c2>{text}</PgTypo>
            </Box>
            <Box ml={1} display="flex" alignItems="center">
              <PgButton primary onClick={() => handleAcceptClick(request)}>
                ACCEPT
              </PgButton>
              <Box mr={2} />
              <PgButton secondary onClick={() => handleDenyClick(request)}>
                DECLINE
              </PgButton>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    root: {
      padding: theme.spacing(3, 0, 1.5),
      margin: theme.spacing(0, 6),
      borderBottom: BORDER_BLACK,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2, 1),
        margin: theme.spacing(0, 'auto'),
        flexDirection: 'column',
        textAlign: 'center',
        gap: theme.spacing(1),
      },
    },
    img: {
      width: 60,
      height: 60,
      borderRadius: '50%',
    },
  });
});

export default CollaborationRequest;
