import React, { FC } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

const Mic: FC<SVGCIconProps> = (props) => {
  const { color = '#ACACAC', height = 24, width = 24, style } = props;
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} style={style} xmlns="http://www.w3.org/2000/svg">
      <rect x="8" y="2" width="8" height="14" rx="4" fill={color} />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.80298 12.7478C6.7445 12.3377 6.41434 12 6.00013 12C5.58591 12 5.24575 12.3371 5.29119 12.7488C5.63655 15.8781 8.12101 18.3629 11.2501 18.7088V21.25H9.00013C8.58591 21.25 8.25013 21.5858 8.25013 22C8.25013 22.4142 8.58591 22.75 9.00013 22.75H15.0001C15.4143 22.75 15.7501 22.4142 15.7501 22C15.7501 21.5858 15.4143 21.25 15.0001 21.25H12.7501V18.7088C15.8792 18.3629 18.3637 15.8781 18.7091 12.7488C18.7545 12.3371 18.4143 12 18.0001 12C17.5859 12 17.2558 12.3377 17.1973 12.7478C16.8343 15.2931 14.6458 17.25 12.0001 17.25C9.3545 17.25 7.16594 15.2931 6.80298 12.7478Z"
        fill={color}
      />
    </svg>
  );
};

export default Mic;
