import { Box, Switch, SwitchProps } from '@material-ui/core';
import PgButton from 'Components/PgButton';
import PgTypo from 'Components/PgTypo';
import useAsyncTask from 'Hooks/useAsyncTask';
import { IDiscussionPermissions, TDiscussion } from 'Models/Discussion/@types';
import { THEME_PALETTE } from 'Theme/themeConstants';
import { Formik, FormikProps } from 'formik';
import React, { FC, useCallback } from 'react';

export interface PermissionsProps {
  discussion: TDiscussion;
  onPermissionChange: (data: Partial<TDiscussion>) => Promise<any>;
}

const Permissions: FC<PermissionsProps> = (props) => {

  const { discussion, onPermissionChange } = props;

  const getPermissionsConfig = useCallback((formikProps: FormikProps<Partial<IDiscussionPermissions>>) => {
    const { isUserReadOnly, canUserShareLinks, canUserShareMedia } = formikProps.values;
    return [
      {
        title: 'Text',
        permissions: [
          {
            checked: canUserShareLinks,
            onChange: formikProps.handleChange,
            name: 'canUserShareLinks',
            label: 'Allow members to share links.',
          },
        ],
      },
      {
        title: 'Media',
        permissions: [
          {
            checked: canUserShareMedia,
            onChange: formikProps.handleChange,
            name: 'canUserShareMedia',
            label: 'Allow members to share images, videos and gifs.',
            disabled: true,
          },
        ],
      },
      {
        title: 'Advanced',
        permissions: [
          {
            checked: isUserReadOnly,
            onChange: formikProps.handleChange,
            name: 'isUserReadOnly',
            label: 'Read-Only Channel for Members (Admins only can post)',
          },
        ],
      },
    ] as WithTitlePermissionsProps[]
  }, []);

  const handleSubmit = useAsyncTask(async (values: Partial<IDiscussionPermissions>) => {
    await onPermissionChange({ ...discussion, permissions: { ...discussion.permissions, ...values } });
  })

  return (
    <Formik<Partial<IDiscussionPermissions>> onSubmit={handleSubmit.run} initialValues={discussion.permissions} enableReinitialize>
      {formikProps => (
        <form onSubmit={formikProps.handleSubmit}>
          <Box display='flex' flexDirection='column' gridGap={20}>
            {getPermissionsConfig(formikProps).map((p, i) => <WithTitlePermissions {...p} key={i} />)}
            <PgButton primary type='submit' fullWidth disabled={handleSubmit.status === 'PROCESSING'}>Save</PgButton>
          </Box>
        </form>
      )}
    </Formik>
  )
}

export default Permissions;

interface WithTitlePermissionsProps {
  title: string;
  permissions: PermissionSwitchProps[];
}

const WithTitlePermissions: FC<WithTitlePermissionsProps> = (props) => {

  const { title, permissions } = props;

  if (!permissions.length) return null;

  return (
    <Box>
      <PgTypo c2>{title}</PgTypo>
      {permissions.map((p, i) => <PermissionSwitch {...p} key={p.id || i} />)}
    </Box>
  )
}

interface PermissionSwitchProps extends SwitchProps { label: string }

const PermissionSwitch: FC<PermissionSwitchProps> = (props) => {

  const { label, ...switchProps } = props;

  return (
    <Box display='flex' alignItems='center' gridGap={16} py={1.5} borderBottom={`1px solid ${THEME_PALETTE.grey.A300}`}>
      <PgTypo b2 style={{ flex: '1 1 100%' }}>{label}</PgTypo>
      <Switch {...switchProps} />
    </Box>
  )
}
