import React, { FC, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { IFieldProps } from 'react-forms';
import { useElements, CardNumberElement, CardCvcElement, CardExpiryElement, useStripe } from '@stripe/react-stripe-js';
import get from 'lodash/get';
import { TICKETING_FORM_COLOR, TICKETING_FORM_PLACEHOLDER_COLOR } from 'Theme/themeConstants';
import Spacer from 'Components/Spacer';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import PgTypo from 'Components/PgTypo';

export interface StripeCardDetailProps extends IFieldProps {}

const StripeCardDetail: FC<StripeCardDetailProps> = (props) => {
  const classes = useStyles({});
  const key = props.fieldConfig?.valueKey || '';

  const label = get(props, 'fieldConfig.fieldProps.label') || '';
  const elements = useElements();
  const { isDeviceSm } = useMediaQuery();

  const [showError, setShowError] = useState<{ cardNumber: boolean; cardExpiry: boolean; cardCvc: boolean }>({
    cardNumber: true,
    cardExpiry: true,
    cardCvc: true,
  });

  useEffect(() => {
    const el = elements?.getElement(CardNumberElement);

    el?.on('change', (event) => {
      console.log('change', event);
    });
  }, [props.formikProps, elements]);

  // const handleChange = (value: any) => {
  //   console.log('value stripe change', value);
  //   if (value.empty && !value.complete) {
  //     console.log('error');
  //   }
  //   // if (elements) props.formikProps?.setFieldValue(key, elements.getElement(CardElement));

  //   if (elements) props.formikProps?.setFieldValue(key, elements.getElement(CardNumberElement));
  //   else console.error('STRIPE ELEMENTS > NULL');
  // };

  const handleCardNumberChange = (value: any) => {
    setShowError((err) => ({ ...err, [value.elementType]: !!value.error }));
    if (elements) props.formikProps?.setFieldValue(key, elements.getElement(CardNumberElement));
    else console.error('STRIPE ELEMENTS > NULL');
  };
  const handleCardExpiryChange = (value: any) => {
    setShowError((err) => ({ ...err, [value.elementType]: !!value.error }));
    if (elements) props.formikProps?.setFieldValue(key, elements.getElement(CardNumberElement));
    else console.error('STRIPE ELEMENTS > NULL');
  };
  const handleCardCvcChange = (value: any) => {
    setShowError((err) => ({ ...err, [value.elementType]: !!value.error }));
    if (elements) props.formikProps?.setFieldValue(key, elements.getElement(CardNumberElement));
    else console.error('STRIPE ELEMENTS > NULL');
  };

  // style: {
  //     base: {
  //       fontSize,
  //       color: "#424770",
  //       letterSpacing: "0.025em",
  //       fontFamily: "Source Code Pro, monospace",
  //       "::placeholder": {
  //         color: "#aab7c4"
  //       }
  //     },
  //     invalid: {
  //       color: "#9e2146"
  //     }
  //   }
  const cardStyle = {
    style: {
      base: {
        // color: '#fff',
        // '::placeholder': {
        //     color: '#5C5C5C',
        // },
        color: TICKETING_FORM_COLOR,
        width: '280px',
        height: '32px',
        // letterSpacing: '0.025em',
        // fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: TICKETING_FORM_PLACEHOLDER_COLOR,
        },
        // backgroundColor: THEME_PALETTE.grey.A400,
      },
      empty: {
        // width: '280px !important',
        // height: '32px !important',
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };
  const cvcExpiryCardStyle = {
    style: {
      base: {
        // color: '#fff',
        // '::placeholder': {
        //     color: '#5C5C5C',
        // },
        color: TICKETING_FORM_COLOR,
        width: '120px',

        // letterSpacing: '0.025em',
        // fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: TICKETING_FORM_PLACEHOLDER_COLOR,
        },
        // backgroundColor: THEME_PALETTE.grey.A400,
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  return (
    <>
      <Box className={classes.root} flexDirection={isDeviceSm ? 'column' : 'row'}>
        {/* {label ? (
                <Typo gutterBottom contrast variant="caption">
                    {label}
                </Typo>
            ) : null}
            <CardElement id="card-element" options={{ ...cardStyle }} onChange={handleChange} /> */}
        <Box display="flex" flexDirection="column" width={isDeviceSm ? '100%' : '250px'} className={classes.elementContainer}>
          {label ? (
            <PgTypo gutterBottom variant="caption">
              {label}
            </PgTypo>
          ) : null}
          <CardNumberElement id="card-number-element" options={{ ...cardStyle }} onChange={handleCardNumberChange /*handleChange*/} />
          {showError.cardNumber && <PgTypo id="card-number-element-error" color="error" className={classes.stripeError} />}
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" mt={isDeviceSm ? 3 : ''}>
          <Box
            display="flex"
            flexDirection="column"
            width={isDeviceSm ? '100%' : '120px'}
            className={classes.elementContainer}
            ml={isDeviceSm ? undefined : 2.5}
          >
            <PgTypo gutterBottom variant="caption">
              Expiry
            </PgTypo>
            <CardExpiryElement id="card-expiry-element" options={{ ...cvcExpiryCardStyle }} onChange={handleCardExpiryChange /*handleChange*/} />
            {showError.cardExpiry && <PgTypo id="card-expiry-element-error" color="error" className={classes.stripeError} />}
          </Box>
          <Box flex="display" flexDirection="column" width={isDeviceSm ? '100%' : '120px'} className={classes.elementContainer} ml={2.5}>
            <PgTypo gutterBottom variant="caption">
              CVC
            </PgTypo>
            <CardCvcElement id="card-cvc-element" options={{ ...cvcExpiryCardStyle }} onChange={handleCardCvcChange /*handleChange*/} />
            {showError.cardCvc && <PgTypo id="card-cvc-element-error" color="error" className={classes.stripeError} />}
          </Box>
        </Box>
      </Box>
      <Spacer height={33} />
    </>
  );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
  root: {
    paddingBottom: 4,
    // borderBottom: '1px solid white',
    display: 'flex',
    justifyContent: 'space-between',
  },
  elementContainer: {
    '& > div': {
      padding: '10px !important',
      backgroundColor: theme.palette.grey.A200,
      height: '37px',
      // padding: '8px',
      // '&:hover': {
      //   backgroundColor: theme.palette.grey.A400,
      // },
    },
  },
  stripeError: {
    fontSize: theme.typography.overline.fontSize,
  },
}));

export default StripeCardDetail;
