// eslint-disable-next-line no-use-before-define
import React, { FC, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  createStyles,
  Icon,
  makeStyles,
  Theme,
  TypographyProps,
} from '@material-ui/core';

import clsx from 'clsx';
import { DragDropContext, DragDropContextProps, Draggable, Droppable } from 'react-beautiful-dnd';
import PgIcon from 'Components/PgIcon';
import isString from 'lodash/isString';
import PgTypo from 'Components/PgTypo';

export interface ConfigurableAccordionRow {
  id: string;
  disableDrag?: boolean;
  title: string | JSX.Element;
  subtitle?: string;
  content: JSX.Element;
  icon?: string | JSX.Element;
  disabled?: boolean;
  accordionProps?: Omit<AccordionProps, 'children'>;
  /* Note:
   * 1. Setting className here will override root class in classes
   * 2. This is meant for singular component only. i.e.,
   * you want to apply these props for only one/very few of several accordions.
   * Anything set here will override the props set in the defaultAccordionProps
   */
  summaryProps?: AccordionSummaryProps; // Similar ^^
  detailsProps?: AccordionDetailsProps; // Same ^^
}

export interface ConfigurableAccordionClasses {
  root?: string;
  accordion?: string;
  summary?: string;
  title?: string;
}

export interface ConfigurableAccordionProps {
  draggable?: boolean;
  id: string;
  onDragEnd?: DragDropContextProps['onDragEnd'];
  rows: ConfigurableAccordionRow[];
  classes?: ConfigurableAccordionClasses;
  titleProps?: TypographyProps;
  subtitleProps?: TypographyProps;
  defaultAccordionProps?: Omit<AccordionProps, 'children'>; // Note: setting className here will override root class in classes
  defaultSummaryProps?: AccordionSummaryProps; // Similar ^^
  defaultDetailsProps?: AccordionDetailsProps; // Same ^^
  disableIconFlip?: boolean;
  selectedStep?: number;
  onChange?: (event: React.ChangeEvent<Record<string, unknown>>, index: number, isExpanded?: boolean, row?: ConfigurableAccordionRow) => void;
  accordionSummaryHeight?: number; // Provide these two props only when you want the accordion to automatically scroll the selected step to the top of the page.
  spaceBetweenSteps?: number; // If you haven't modified the space between two steps using classes ignore this prop. Also this only impacts the scroll position of the accordion when selectedStep changes.
}

const ConfigurableAccordion: FC<ConfigurableAccordionProps> = (props) => {
  const { draggable = false, onDragEnd, id: accordionId, accordionSummaryHeight, ...configurableAccordionProps } = props;
  const {
    rows,
    titleProps,
    subtitleProps,
    classes: propClasses,
    defaultAccordionProps,
    defaultSummaryProps,
    defaultDetailsProps,
    disableIconFlip,
    selectedStep = 1,
    onChange,
    spaceBetweenSteps = 18,
  } = configurableAccordionProps;
  const classes = useStyles({ accordionSummaryHeight });

  useEffect(() => {
    if (accordionSummaryHeight) {
      window.scrollTo(0, (selectedStep - 1) * (accordionSummaryHeight + spaceBetweenSteps)); // Bring current viewing step within viewport dimensions
    }
  }, [selectedStep]);

  const handleChange = (row: ConfigurableAccordionRow, index: number) => (event: React.ChangeEvent<Record<string, unknown>>, isExpanded: boolean) => {
    onChange?.(event, index, isExpanded, row);
  };

  const handleDragEnd: DragDropContextProps['onDragEnd'] = (res, provided) => {
    onDragEnd?.(res, provided);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={accordionId}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={{
              backgroundColor: snapshot.isDraggingOver ? 'rgba(0,0,0,0.1)' : 'transparent',
            }}
            {...provided.droppableProps}
            className={propClasses?.root}
          >
            {rows.map((row, index) => {
              const { id, disableDrag = false, title, subtitle, content, icon, disabled, accordionProps, summaryProps, detailsProps } = row;
              const isDragDisabled = !draggable || disableDrag || disabled;
              const dragId = `draggable-${index}-${accordionId}`;

              return (
                <Draggable isDragDisabled={isDragDisabled} key={row.id} draggableId={dragId} index={index}>
                  {(dragProvided, dragSnapshot) => (
                    <Accordion
                      ref={dragProvided.innerRef}
                      {...dragProvided.draggableProps}
                      onChange={handleChange(row, index)}
                      expanded={!disabled && selectedStep - 1 === index}
                      TransitionProps={{
                        unmountOnExit: true,
                      }}
                      key={id}
                      className={clsx(propClasses?.accordion)}
                      {...defaultAccordionProps}
                      {...accordionProps}
                      classes={{
                        expanded: clsx({
                          [classes.disableIconFlip]: disableIconFlip,
                        }),
                        ...defaultAccordionProps?.classes,
                        ...accordionProps?.classes,
                      }}
                    >
                      <AccordionSummary
                        aria-controls={`panel${index}-content`}
                        {...defaultSummaryProps}
                        {...summaryProps}
                        classes={{
                          ...summaryProps?.classes,
                          root: clsx(summaryProps?.classes?.root, {
                            [classes.dragging]: dragSnapshot.isDragging,
                            [classes.disabledSummary]: disabled,
                          }),
                          content: clsx(classes.accordionSummary, summaryProps?.classes?.content),
                        }}
                      >
                        {draggable ? (
                          <Box onClick={(e) => (isDragDisabled ? null : e.stopPropagation())} mr={1} height={24} {...dragProvided.dragHandleProps}>
                            <PgIcon color={isDragDisabled ? 'default' : 'secondary'} icon="icon-drag" />
                          </Box>
                        ) : null}
                        {isString(title) ? (
                          <div>
                            <PgTypo c1 {...titleProps}>
                              {title.length > 45 ? `${title.slice(0, 44)}....` : title}
                            </PgTypo>
                            <PgTypo b6 {...subtitleProps}>
                              {subtitle}
                            </PgTypo>
                          </div>
                        ) : (
                          title
                        )}

                        <Box flex={1} />
                        {typeof icon === 'string' ? <Icon>{icon}</Icon> : icon}
                      </AccordionSummary>
                      <AccordionDetails {...defaultDetailsProps} {...detailsProps}>
                        {content}
                      </AccordionDetails>
                    </Accordion>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ConfigurableAccordion;

const useStyles = makeStyles<Theme, { accordionSummaryHeight?: number }>((theme) =>
  createStyles({
    disableIconFlip: {
      '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: 'unset',
      },
    },
    dragging: {
      border: `1px solid ${theme.palette.secondary.main} !important`,
    },
    accordionSummary: {
      display: 'flex',
      alignItems: 'center',
      minHeight: ({ accordionSummaryHeight }) => accordionSummaryHeight,
      boxSizing: 'border-box',
      margin: '0 !important',
      padding: theme.spacing(1, 0),
      paddingLeft: 10,
    },
    disabledSummary: {
      backgroundColor: theme.palette.grey.A100,
    },
  }),
);
