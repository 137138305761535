import { SupportedEventPlatforms } from 'Models/Event';
import { request } from 'Resources/AxiosUtils';
import * as API from './api';
import { TicketTier } from './ticketing/@types';
import * as Utils from './utils';

export * from './@types';

export const PLATFORM_CONSTANTS: Record<SupportedEventPlatforms, string> = {
    youtube: 'Youtube',
    twitch: 'Twitch',
    vimeo: 'Vimeo',
    clubhouse: 'Club House',
    zoom: 'Zoom',
    global: 'Streaming Link',
    nowhere: 'Nowhere',
    sandbox: 'Sandbox',
    spatial: 'Spatial',
    ronday: 'Ronday',
};

const EventModel = {
    ...API,
    getAvailableTickets: (id: string) =>
        request<TicketTier[]>({
            url: `/events/${id}/getAvailableTickets`,
            method: 'GET',
        }),
    ...Utils,
};

export default EventModel;
