/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable camelcase */
import { AxiosRequestConfig } from 'axios';
import { TESSearchItem, TESSearchResponse, TFilter } from 'Models/App/@types';
import { Picture } from 'Models/Picture/@types';
import { Invitee, UserSearchAggregations, WithDocType } from 'Models/User/';
import { TUserFavorited, User } from 'Models/User/@types';
import { parseUser } from 'Models/User/userParser';
import AxiosUtils from 'Resources/AxiosUtils';
import { JSONType, SearchResponse } from 'Typings/Global';
import { request } from 'Utils';
import { parseCommunity } from './CommunityParser';
import { TRefundRequest } from 'Models/RefundRequest/@types';
import {
    NewsLetterProviderType,
    TCommunity,
    TCommunityRefundRequestListData,
    TCommunitySettings,
    TCommunitySearchResponse,
    CreateCommunityApprove,
    IQRManagerProps,
    TCommunitiesFeeds,
} from './@types';
import { APP_ROUTES } from 'Routes';

export let comunityMemberAbortController: AbortController | null = null;

class CommunityModel {
    static postCommunity = (data: Partial<TCommunity>): Promise<TCommunity> => {
        return request<TCommunity>({
            url: `/Communities/create-new`,
            method: 'post',
            data,
        });
    };

    static getRefundRequestForCommunities = async (id: string, filter?: TFilter): Promise<TCommunityRefundRequestListData> => {
        const resp = await request<{ total: number; results: Array<{ hit: TRefundRequest }> }>({
            url: `/Communities/${id}/refund-req-search`,
            method: 'get',
            params: {
                filter,
            },
        });
        return { total: resp.total, results: resp.results.map(({ hit }) => hit) };
    };

    static getCommunityById = async (id: string, filter?: TFilter): Promise<TCommunity> => {
        const data = await request<TCommunity>({
            url: `/Communities/${id}`,
            method: 'get',
            params: {
                filter,
            },
        });
        return parseCommunity(data);
    };

    static toggleFeatureCommunity<T = any>(id: string, isFeatured: boolean) {
        return request<T>({
            url: `/Communities/${id}/feature-community`,
            method: 'patch',
            params: {
                isFeatured,
            },
        });
    }

    static search<T = any>(term: string, filter: Record<string, any>) {
        return request<T>({
            url: `/Communities/search`,
            method: 'get',
            params: {
                term,
                filter: {
                    ...filter,
                    Community: {
                        include: [
                            {
                                relation: 'user',
                                scope: {
                                    fields: ['id', 'slug', 'firstName', '_profileImages'],
                                },
                            },
                        ],
                    },
                    searchFields: ['name^8', 'communityOwner.firstName^5', 'communityOwner.lastName^3'],
                },
            },
        });
    }

    static async updateCommunity(id: string, data: Partial<TCommunity>): Promise<TCommunity> {
        const res = await request<TCommunity>({
            url: `/Communities/${id}/update-data`,
            method: 'post',
            data,
        });
        return parseCommunity(res);
    }

    static getCommunitySlugExist(slug: string): Promise<boolean> {
        return request<boolean>({
            url: `/Communities/slug-exists`,
            method: 'get',
            params: {
                slug,
            },
        });
    }

    static relatedEntitySearch = async (
        communityId: string,
        term: string,
        entityFilter?: Record<string, any>,
        axiosConfig?: AxiosRequestConfig,
    ): Promise<SearchResponse<WithDocType<User | Invitee, 'user' | 'PartialReferral'>, UserSearchAggregations> | undefined> => {
        const users = await request<SearchResponse<WithDocType<User | Invitee, 'user' | 'PartialReferral'>, UserSearchAggregations>>({
            ...axiosConfig,

            url: `/Communities/${communityId}/related-entity-search`,

            params: {
                term,

                entityFilter,
            },
        }).catch(AxiosUtils.throwError);
        if (users) {
            return {
                ...users,

                results: users.results.map((u) => ({
                    ...u,
                    hit:
                        u.hit.doc_type === 'user'
                            ? (parseUser(u.hit) as WithDocType<User, 'user'>)
                            : (u.hit as WithDocType<Invitee, 'PartialReferral'>),
                })),
            };
        }
    };

    static getCommunitySettings = (id: string): Promise<TCommunitySettings> => {
        return request<TCommunitySettings>({
            url: `communitysettings/${id}/settings`,
        });
    };

    static saveNewsLetterConfig = async (id: string, providerType?: NewsLetterProviderType, apiKey?: string) => {
        const res = await request({
            url: `/communitysettings/${id}/upsert-newsletterconfig`,
            method: 'PATCH',
            data:
                providerType && apiKey
                    ? {
                          [providerType]: {
                              apiKey,
                          },
                      }
                    : {},
        });

        return res;
    };

    static getStripeLink = async (params?: Record<string, unknown>) => {
        const res = await request<JSONType>({
            url: `/StripeAccounts/connect-account-link`,
            method: 'GET',
            params,
        });

        return res;
    };

    static getStripeAccount = async (communityId: string) => {
        const res = await request({
            url: `/StripeAccounts/my-account`,
            params: {
                communityId,
            },
        });

        return res;
    };

    static canDeactivate = async (id: string, userId: string) =>
        request({
            url: `/Communities/${id}/can-deactivate`,
            method: 'GET',
            params: {
                userId,
            },
        });

    static requestCommunityDeactivation = async (id: string, deleteEntity = false) =>
        request({
            url: `/AppModels/request-deactivation`,
            method: 'POST',
            data: {
                entityId: id,
                entityType: 'Community',
                deleteEntity,
            },
        });

    static getName(community?: TCommunity) {
        if (!community) return 'PG Community';
        if (community.firstName || community.lastName) {
            if (community.lastName) return `${community.firstName || ''} ${community.lastName || ''}`;
            return `${community.firstName || ''}`;
        }
        return community.name || 'PG Community';
    }

    static getAvatarImage = (community?: TCommunity): Picture | undefined => {
        if (!community) return undefined;
        return (
            // eslint-disable-next-line no-underscore-dangle
            (community.profileImages ?? community._profileImages)?.find((i) => !!i)
        );
    };

    static reActivateCommunity = (id: string): Promise<Record<string, any>> =>
        request({
            url: `/AppModels/request-activation`,
            method: 'POST',
            data: {
                entityId: id,
                entityType: 'Community',
            },
        });

    static parseCommunitiesList = (list?: TESSearchItem<TCommunity>[]) => {
        if (!list) return [];
        return list.map((item) => {
            return parseCommunity(item.hit);
        });
    };

    static getCommunitiesSummary = async (term: string, filter?: TFilter): Promise<TCommunitySearchResponse> => {
        const data = await request<TESSearchResponse<TCommunity>>({
            url: `/Communities/search`,
            params: {
                term,
                filter,
            },
        });
        return { total: data.total ?? 0, results: CommunityModel.parseCommunitiesList(data.results), aggregations: {} };
    };

    static createCommunityApprove = (data: Partial<TCommunity>): Promise<CreateCommunityApprove> => {
        return request({
            url: `/CreatorRequests/request-new-community`,
            method: 'post',
            data,
        });
    };

    static disconnectStripeAccount = (communityId: string) => {
        return request({
            url: `/StripeAccounts/${communityId}/disconnect`,
            method: 'POST',
        });
    };

    static joinCommunity = async (communityId: string, userId: string): Promise<TUserFavorited<TCommunity>> => {
        const res: TUserFavorited<TCommunity> = await request({
            url: `/Communities/${communityId}/follow/${userId}`,
            method: 'POST',
        });
        return res;
    };

    static leaveCommunity = async (followId: string) => {
        return request({
            url: `/Follows/${followId}/unfollow`,
            method: 'DELETE',
        });
    };
    static toggleDropsDisplay(id: string, isSoftPublish: boolean) {
        return request({
            url: `/Communities/${id}/soft-publish-drops`,
            method: 'PATCH',
            data: { isSoftPublish },
        });
    }

    static getOnlineMembers = (id: string) =>
        request<{ online: User[]; offline: User[] }>({
            url: `Communities/${id}/members-status`,
        });

    static toggleCommunityHighlight = (communityId: string, highlight: boolean) => {
        return request<{ highlighted: boolean }>({
            url: `/Communities/${communityId}/highlight`,
            method: 'PATCH',
            data: {
                highlight,
            },
        });
    };

    static getHighlightedCommunity = () => {
        return request<TCommunity>({
            url: `/Communities/highlighted-community`,
            method: 'GET',
        });
    };

    static getAllEventCsv = (id: string, filter?: TFilter) =>
        request({
            url: `Communities/${id}/all-events-csv`,
            params: {
                filter,
            },
        });

    static getCommunityMembers = (communityId: string, term = '', entityFilter?: TFilter, filterCommunityIds?: string[]) => {
        comunityMemberAbortController = new AbortController();
        return request<TESSearchResponse<User, Record<string, any>>>({
            url: `/Communities/${communityId}/ecosystem-search`,
            method: 'GET',
            params: { term, entityFilter, filterCommunityIds: JSON.stringify(filterCommunityIds) },
            signal: comunityMemberAbortController.signal,
        });
    };

    static deleteCommunity = (id: string) => {
        return request<{ deletionRequestSent: boolean }>({
            url: `/Communities/${id}/delete`,
            method: 'POST',
        });
    };

    static toggleShowCommUserCount = (id: string, visibility: boolean) => {
        return request<{ showMemberCount: boolean }>({
            url: `/Communities/${id}/toggle-member-count`,
            method: 'PATCH',
            data: { visibility },
        });
    };

    static uploadInviteCsv = (id: string, file: any, autoVerify = false, redirectUrl = APP_ROUTES.SIGN_UP.path + '?flow=inviteCsv') => {
        let formData = new FormData();
        formData.append('', file);
        return request({
            url: `/Communities/${id}/invite-via-csv`,
            method: 'POST',
            data: formData,
            params: { redirectUrl, autoVerify },
        });
    };

    static qrManager = (eventId: string, params?: IQRManagerProps) => {
        return request({
            url: `/Communities/events/${eventId}/qr-manager`,
            params,
        });
    };

    static getCommunitiesFeeds = async () => {
        const res = await request<TCommunitiesFeeds>({
            url: '/Communities/get-feeds',
            method: 'GET',
        });
        return res;
    };
}

export default CommunityModel;
