import { parseResource } from '../Resource/resourceParsers';
import { request } from '../../Resources/AxiosUtils';
import { TFilter } from '../App/@types';
import { parseEvent } from '../Event/eventParsers';

class FavoritesModel {
  static parseFavoritesList = (list?: any[]): any[] => {
    if (!list) return [];
    return list.map((item) => {
      item.subject.doc_type = item.subjectType;
      if (item.subjectType === 'Event') return parseEvent(item.subject);
      return parseResource(item.subject);
    });
  };

  static favorites = async (slug: string, filter?: TFilter): Promise<any> => {
    const data = await request<any>({
      url: `/users/${slug}/favourites`,
      params:{
        filter
      },
    });
    return { total: data.total ?? 0, results: FavoritesModel.parseFavoritesList(data), aggregations: {} };
  };

  static favoritesCount = async (slug: string): Promise<number> => {
    const count = await request<number>({
      url: `/users/${slug}/favourites/count`,
    });
    return count;
  };
}

export default FavoritesModel;
