import useAsyncTask from 'Hooks/useAsyncTask';
import useToastMessage from 'Hooks/useToastMessage';
import UserModel from 'Models/User';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'Stores';
import useAuth from '../Auth/useAuth';
import SettingConfirmationDialog, { SettingConfirmationDialogProps } from './SettingConfirmationDialog';

const useDeactivationListener = () => {
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
  const { logout } = useAuth();
  const [dialogConfig, setDialogConfig] = useState<Omit<SettingConfirmationDialogProps, 'onClose'>>({ open: false });
  useEffect(() => {
    if (appUser?.id) checkDeactivation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUser?.id]);
  const onClose = async () => {
    setDialogConfig({ open: false });
    logout(false);
  };
  const checkDeactivation = () => {
    if (!appUser || !appUser.id) return;
    if (appUser.isDeletionRequested) {
      setDialogConfig({
        title: 'Account deletion pending.',
        body: `An account that previously used this email address is pending deletion. Sign up again using new credentials to access Playground.`,
        open: true,
        confirmText: 'LOG OUT',
        onConfirm: onClose,
        showCancel: false,
      });
    } else if (appUser.isDeactivated) {
      setDialogConfig({
        open: true,
        title: 'Reactivate account',
        body: `You previously deactivated your account. To use Playground, reactivate it now.`,
        confirmText: 'REACTIVATE',
        onConfirm: () => onReActivate.run({}),
      });
    }
  };
  const withToast = useToastMessage();
  const onReActivate = useAsyncTask(async () => {
    try {
      await withToast(
        async () => {
          await UserModel.reActivateUser(appUser?.id!);
        },
        {
          successToastMessage: 'Account re-activation request sent successfully.',
        },
      );
    } finally {
      // setShowDialog(true);
    }
  });
  return {
    component: dialogConfig.open ? (
      <SettingConfirmationDialog {...dialogConfig} onClose={onClose} loading={onReActivate.status === 'PROCESSING'} />
    ) : null,
  };
};
export default useDeactivationListener;
