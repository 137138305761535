/* eslint-disable import/no-cycle */
import React, { FC, Suspense, useMemo } from 'react';
import { Redirect, Route, Switch, useLocation, useParams } from 'react-router-dom';

// eslint-disable-next-line import/no-cycle
import { APP_ROUTES } from 'Routes';
import { Box, LinearProgress } from '@material-ui/core';
import Heading from 'Components/Heading';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { THEME_PALETTE } from 'Theme/themeConstants';
import Typo from 'Components/Typo';
import { useStoreState } from 'Stores';
import { isEmpty } from 'lodash';
import Page404 from '../../Screens/Page404';
import lazyImport from '../../lazy-import';

import EventNavigation, { EVENT_ROUTES } from './EventNavigation';
import ResourceNavigation, { RESOURCE_ROUTES } from './ResourceNavigation';
import PgTypo from 'Components/PgTypo';
import { generatePath } from 'react-router';

const Events = lazyImport('../Screens/Dashboard/EventAndMedia/Events');
const EventForm = lazyImport('../Screens/Dashboard/EventForm');
const Discussion = lazyImport('../Screens/Discussion');
const DropFormScreen = lazyImport('../Screens/Dashboard/DropFormScreen');

const EventsAndMediaNavigation: FC = () => {
  const {communityId} = useParams<{communityId:string}>();
  return (
    <>
      {/* {shouldShowNavTabs ? <NavHeader /> : null} */}
      {/* {shouldShowNavTabs ? (
        <Heading
          title="Manage Drops"
          subtitle="View and manage your upcoming drops."
          additionalComponent={searchComponent}
          containerProps={{
            display: isDeviceSm ? 'block' : 'flex',
            px: isDeviceSm ? 2 : 6,
            py: isDeviceSm ? 3 : 5,
            bgcolor: THEME_PALETTE.common.white,
            maxWidth: 1600,
          }}
        />
      ) : null} */}
      <Suspense fallback={null}>
        <Switch>
          <Route
            path={EVENT_MEDIA_ROUTES.HOME.path}
            exact
            render={() => <Redirect to={generatePath(EVENT_MEDIA_ROUTES.EVENTS_LIST.path, { communityId })} />}
          />

          {/* <Route path={EVENT_MEDIA_ROUTES.NEW_EVENT.path} component={EventForm} /> */}
          {/* <Route path={EVENT_MEDIA_ROUTES.NEW_DROP_FORM.path} component={DropFormScreen} /> */}
          <Route path={Object.keys(dropFormRoutes).map(k => (dropFormRoutes as any)?.[k]?.path)} component={DropFormScreen} />
          <Route path={EVENT_ROUTES.EVENT_HOME.path} component={EventNavigation} />
          <Route path={EVENT_MEDIA_ROUTES.EVENTS_LIST.path} component={Events} />
          <Route path={EVENT_MEDIA_ROUTES.EVENTS_LIST.path} component={Events} />
          <Route path="/conversations" component={Discussion} />
          {/* <Route path={RESOURCE_ROUTES.RESOURCE_WATCH_EDIT.path} component={ResourceNavigation} />
          <Route path={RESOURCE_ROUTES.RESOURCE_LISTEN_EDIT.path} component={ResourceNavigation} />
          <Route path={RESOURCE_ROUTES.RESOURCE_READ_EDIT.path} component={ResourceNavigation} />
          <Route path={RESOURCE_ROUTES.RESOURCE_COLLECT_EDIT.path} component={ResourceNavigation} /> */}

          <Route path="/*" exact component={Page404} />
        </Switch>
      </Suspense>
    </>
  );
};

export default EventsAndMediaNavigation;

export const EVENTS_MEDIA_BASE_PATH = `${APP_ROUTES.DASHBOARD_HOME.path}/events-and-media`;

const dropFormRoutes = {
  NEW_DROP_FORM: {
    path: `${EVENTS_MEDIA_BASE_PATH}/:dropType/new`,
  },
  EDIT_DROP_FORM: {
    path: `${EVENTS_MEDIA_BASE_PATH}/:dropType/:dropId/edit`,
  },
  // DUPLICATE_DROP_FORM: { path: `${EVENTS_MEDIA_BASE_PATH}/:dropType/:dropId/duplicate` },
  DUPLICATE_DROP_FORM: { path: `${EVENTS_MEDIA_BASE_PATH}/:dropType/:dropId/duplicate` },
}

export const EVENT_MEDIA_ROUTES = {
  HOME: {
    path: EVENTS_MEDIA_BASE_PATH,
  },
  // EVENT_DETAIL: {
  // 	path: `${BASE_PATH}/events/:id`
  // },
  // ADD_EDIT_EVENT: {
  // 	path: `${BASE_PATH}/events/edit/:id`
  // },
  NEW_EVENT: {
    path: `${EVENTS_MEDIA_BASE_PATH}/events/new`,
  },
  // TO_CREATED_EVENT: {
  //   path: `${EVENTS_MEDIA_BASE_PATH}/events/:id`,
  // },
  EVENTS_LIST: {
    path: `${EVENTS_MEDIA_BASE_PATH}/events`,
  },
  WATCH: {
    path: `${EVENTS_MEDIA_BASE_PATH}/watch`,
  },
  NEW_WATCH: {
    path: `${EVENTS_MEDIA_BASE_PATH}/watch/new`,
  },
  LISTEN: {
    path: `${EVENTS_MEDIA_BASE_PATH}/listen`,
  },
  NEW_LISTEN: {
    path: `${EVENTS_MEDIA_BASE_PATH}/listen/new`,
  },
  READ: {
    path: `${EVENTS_MEDIA_BASE_PATH}/read`,
  },
  NEW_READ: {
    path: `${EVENTS_MEDIA_BASE_PATH}/read/new`,
  },
  COLLECT: {
    path: `${EVENTS_MEDIA_BASE_PATH}/collect`,
  },
  NEW_COLLECT: {
    path: `${EVENTS_MEDIA_BASE_PATH}/collect/new`,
  },
  ...dropFormRoutes,
};

const whitelist = [
  EVENT_MEDIA_ROUTES.EVENTS_LIST.path,
  EVENT_MEDIA_ROUTES.WATCH.path,
  EVENT_MEDIA_ROUTES.LISTEN.path,
  EVENT_MEDIA_ROUTES.READ.path,
  EVENT_MEDIA_ROUTES.COLLECT.path,
];
