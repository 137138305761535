import Axios, { CancelTokenSource } from 'axios';
import { action, Action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';
import ResourceQuery from 'Features/Query/ResourceQuery';
import { TFilter } from 'Models/AppModels';
import ResourceModel from 'Models/Resource';
import { TResource } from 'Models/Resource/@types';
import { parseResource } from 'Models/Resource/resourceParsers';
import SoundtrackModel from 'Models/Soundtrack';
import { TRootStore } from 'Stores';


export interface SoundtrackState {
  soundtrackDetail?: TResource;
  setSoundtrackDetail: Action<SoundtrackState, TResource | undefined>;
  fetchSoundtrackDetail: Thunk<SoundtrackState, { id: string, filters?: TFilter }, null, TRootStore>;
  loadingDetails: boolean;
  setLoadingDetails: Action<SoundtrackState, boolean>;

  soundtrackList: TResource[];
  fetchSoundtrackList: Thunk<SoundtrackState, ResourceQuery, null, TRootStore>;
  setSoundtrackList: Action<SoundtrackState, { soundtrackList: TResource[] }>;

  loading: boolean;
  setLoading: Action<SoundtrackState, boolean>;

  total: number;
  setTotal: Action<SoundtrackState, number>;

  query: ResourceQuery;
  setQuery: Action<SoundtrackState, ResourceQuery>;
  onQueryChange: ThunkOn<SoundtrackState, Record<string, unknown>, TRootStore>;
}

let cancelToken: CancelTokenSource | undefined;

const SoundtrackStore: SoundtrackState = {
  loadingDetails: false,
  soundtrackList: [],
  total: 0,
  loading: false,
  query: new ResourceQuery({}, { page: 1 }),

  setSoundtrackDetail: action((state, payload) => {
    state.soundtrackDetail = payload;
  }),
  setLoadingDetails: action((state, payload) => {
    state.loadingDetails = payload;
  }),

  setSoundtrackList: action((state, { soundtrackList }) => {
    state.soundtrackList = soundtrackList;
  }),
  setTotal: action((state, payload) => {
    state.total = payload;
  }),
  setQuery: action((state, payload) => {
    state.query = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  fetchSoundtrackList: thunk(async (actions, query, { getStoreState }) => {
    actions.setLoading(true);
    const { community } = getStoreState().CommunityStore;
    if (!community) return;

    query.communityId = community?.id ?? '';
    query.docType = 'Collect';

    if (cancelToken) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
    cancelToken = Axios.CancelToken.source();

    const res = await ResourceModel.search(
      community?.id ?? '',
      '',
      {
        Soundtrack: {
          include: 'tags',
        },
        ...query.searchFilter,
      },
      { cancelToken: cancelToken.token }
    );

    if (res) {
      const soundtrackList: TResource[] = [...res.results.map((res) => parseResource(res.hit))];

      actions.setTotal(res.total);
      actions.setSoundtrackList({ soundtrackList });
    }
    actions.setLoading(false);
  }),
  fetchSoundtrackDetail: thunk(async (actions, { id, filters = {} }) => {
    try {
      actions.setLoadingDetails(true);
      const res = await SoundtrackModel.getSoundtrack(id, filters);
      actions.setSoundtrackDetail(res);
    } catch (error) {
      console.error(error)
    } finally {
      actions.setLoadingDetails(false);
    }
  }),
  onQueryChange: thunkOn(
    (actions) => actions.setQuery,
    async (actions, { payload }) => {
      actions.fetchSoundtrackList(payload);
    }
  ),
};

export default SoundtrackStore;

