
import axios from 'axios';
// THIS IS A GENERATED FILE; PLEASE DO NOT MAKE CHANGES HERE

/** 
 * Fetches belongsTo relation subject.
 */ 
export function get_Reminders_id_subject<T = any>(id: string,refresh?: any,) {
    return axios.request<T>({
        url: `/Reminders/${id}/subject`,
        method: 'get',
        params: {
            refresh
        },
        
    })
}

/** 
 * Fetches belongsTo relation schedule.
 */ 
export function get_Reminders_id_schedule<T = any>(id: string,refresh?: any,) {
    return axios.request<T>({
        url: `/Reminders/${id}/schedule`,
        method: 'get',
        params: {
            refresh
        },
        
    })
}

/** 
 * Fetches belongsTo relation rsvpSchedule.
 */ 
export function get_Reminders_id_rsvpSchedule<T = any>(id: string,refresh?: any,) {
    return axios.request<T>({
        url: `/Reminders/${id}/rsvpSchedule`,
        method: 'get',
        params: {
            refresh
        },
        
    })
}

/** 
 * Fetches belongsTo relation user.
 */ 
export function get_Reminders_id_user<T = any>(id: string,refresh?: any,) {
    return axios.request<T>({
        url: `/Reminders/${id}/user`,
        method: 'get',
        params: {
            refresh
        },
        
    })
}

/** 
 * Find all instances of the model matched by filter from the data source.
 */ 
export function get_Reminders<T = any>(filter?: any,) {
    return axios.request<T>({
        url: `/Reminders`,
        method: 'get',
        params: {
            filter
        },
        
    })
}

/** 
 * Create a new instance of the model and persist it into the data source.
 */ 
export function post_Reminders<T = any>(data?: any,) {
    return axios.request<T>({
        url: `/Reminders`,
        method: 'post',
        
        data: {
            ...data
        },
    })
}

/** 
 * Replace an existing model instance or insert a new one into the data source.
 */ 
export function put_Reminders<T = any>(data?: any,) {
    return axios.request<T>({
        url: `/Reminders`,
        method: 'put',
        
        data: {
            ...data
        },
    })
}

/** 
 * Patch an existing model instance or insert a new one into the data source.
 */ 
export function patch_Reminders<T = any>(data?: any,) {
    return axios.request<T>({
        url: `/Reminders`,
        method: 'patch',
        
        data: {
            ...data
        },
    })
}

/** 
 * Replace an existing model instance or insert a new one into the data source.
 */ 
export function post_Reminders_replaceOrCreate<T = any>(data?: any,) {
    return axios.request<T>({
        url: `/Reminders/replaceOrCreate`,
        method: 'post',
        
        data: {
            ...data
        },
    })
}

/** 
 * Update an existing model instance or insert a new one into the data source based on the where criteria.
 */ 
export function post_Reminders_upsertWithWhere<T = any>(where?: any,data?: any,) {
    return axios.request<T>({
        url: `/Reminders/upsertWithWhere`,
        method: 'post',
        params: {
            where
        },
        data: {
            ...data
        },
    })
}

/** 
 * Check whether a model instance exists in the data source.
 */ 
export function get_Reminders_id_exists<T = any>(id: string,) {
    return axios.request<T>({
        url: `/Reminders/${id}/exists`,
        method: 'get',
        
        
    })
}

/** 
 * Find a model instance by {{id}} from the data source.
 */ 
export function get_Reminders_id<T = any>(id: string,filter?: any,) {
    return axios.request<T>({
        url: `/Reminders/${id}`,
        method: 'get',
        params: {
            filter
        },
        
    })
}

/** 
 * Replace attributes for a model instance and persist it into the data source.
 */ 
export function put_Reminders_id<T = any>(id: string,data?: any,) {
    return axios.request<T>({
        url: `/Reminders/${id}`,
        method: 'put',
        
        data: {
            ...data
        },
    })
}

/** 
 * Patch attributes for a model instance and persist it into the data source.
 */ 
export function patch_Reminders_id<T = any>(id: string,data?: any,) {
    return axios.request<T>({
        url: `/Reminders/${id}`,
        method: 'patch',
        
        data: {
            ...data
        },
    })
}

/** 
 * Delete a model instance by {{id}} from the data source.
 */ 
export function delete_Reminders_id<T = any>(id: string,) {
    return axios.request<T>({
        url: `/Reminders/${id}`,
        method: 'delete',
        
        
    })
}

/** 
 * Replace attributes for a model instance and persist it into the data source.
 */ 
export function post_Reminders_id_replace<T = any>(id: string,data?: any,) {
    return axios.request<T>({
        url: `/Reminders/${id}/replace`,
        method: 'post',
        
        data: {
            ...data
        },
    })
}

/** 
 * Find first instance of the model matched by filter from the data source.
 */ 
export function get_Reminders_findOne<T = any>(filter?: any,) {
    return axios.request<T>({
        url: `/Reminders/findOne`,
        method: 'get',
        params: {
            filter
        },
        
    })
}

/** 
 * Update instances of the model matched by {{where}} from the data source.
 */ 
export function post_Reminders_update<T = any>(where?: any,data?: any,) {
    return axios.request<T>({
        url: `/Reminders/update`,
        method: 'post',
        params: {
            where
        },
        data: {
            ...data
        },
    })
}

/** 
 * Count instances of the model matched by where from the data source.
 */ 
export function get_Reminders_count<T = any>(where?: any,) {
    return axios.request<T>({
        url: `/Reminders/count`,
        method: 'get',
        params: {
            where
        },
        
    })
}

/** 
 * Create a change stream.
 */ 
export function get_Reminders_change_stream<T = any>(options?: any,) {
    return axios.request<T>({
        url: `/Reminders/change-stream`,
        method: 'get',
        params: {
            options
        },
        
    })
}

/** 
 * Create a change stream.
 */ 
export function post_Reminders_change_stream<T = any>(options?: any,) {
    return axios.request<T>({
        url: `/Reminders/change-stream`,
        method: 'post',
        
        data: {
            options
        },
    })
}

/** 
 * 
 */ 
export function delete_Reminders_id_remove_reminder<T = any>(id: string,) {
    return axios.request<T>({
        url: `/Reminders/${id}/remove-reminder`,
        method: 'delete',
        
        
    })
}
