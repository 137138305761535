import { action, Action, thunk, Thunk } from 'easy-peasy';
import { TUserCommunity } from 'Models/User/@types';
import UserModel from 'Models/User';
import { TCommunity } from 'Models/Community/@types';
import CommunityModel from 'Models/Community';

export type TSidebarSteps = 'default' | 'managedCommunity' | 'joinedCommunity';

export interface TDrawerSidebarState {
  open: boolean;
  setOpen: Action<TDrawerSidebarState, boolean>;

  sidebarStep: TSidebarSteps;
  setSidebarStep: Action<TDrawerSidebarState, TSidebarSteps>;

  userCommunity: TUserCommunity[];
  userFollowedCommunity: TUserCommunity[];
  setUserFollowedCommunity: Action<TDrawerSidebarState, TUserCommunity[]>;
  setUserCommunity: Action<TDrawerSidebarState, TUserCommunity[]>;
  getUserCommunity: Thunk<TDrawerSidebarState>;

  currentCommunity: TCommunity | undefined;
  setCurrentCommunity: Action<TDrawerSidebarState, TCommunity>;
  getCurrentCommunity: Thunk<TDrawerSidebarState, string>;
}

const DrawerSidebarStore: TDrawerSidebarState = {
  open: true,
  sidebarStep: 'default',
  userCommunity: [],
  userFollowedCommunity: [],
  currentCommunity: undefined,

  setSidebarStep: action((state, payload) => {
    state.sidebarStep = payload;
  }),

  setOpen: action((state, payload) => {
    state.open = payload;
  }),

  setUserCommunity: action((state, payload) => {
    state.userCommunity = payload;
  }),

  setUserFollowedCommunity: action((state, payload) => {
    state.userFollowedCommunity = payload;
  }),

  setCurrentCommunity: action((state, payload) => {
    state.currentCommunity = payload;
  }),

  getUserCommunity: thunk(async (actions) => {
    const data = await UserModel.getUsersCommunity();
    if (data?.userCommunities) actions.setUserCommunity(data.userCommunities);
    if (data?.followsCommunities) actions.setUserFollowedCommunity(data.followsCommunities);
  }),

  getCurrentCommunity: thunk(async (actions, id) => {
    const data = await CommunityModel.getCommunityById(id);
    actions.setCurrentCommunity(data);
  }),
};

export default DrawerSidebarStore;
