import React, { FC } from 'react';
import { Box, BoxProps } from '@material-ui/core';

export interface EmptyFieldProps {
  fieldProps?: BoxProps;
}

const EmptyField: FC<EmptyFieldProps> = (props) => {
  const { fieldProps = {} } = props;
  return <Box width="100%" {...fieldProps} />;
};

export default EmptyField;
