/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, createStyles, FormHelperText, FormHelperTextProps, IconButton, InputLabel, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { FormikProps } from 'formik';
import { get } from 'lodash';
import { Picture } from 'Models/Picture/@types';
import React, { FC, useState } from 'react';
import { getFieldError, IFieldProps, processFilesWithCallback, TFile } from 'react-forms';
import { arrayMoveImmutable } from 'array-move';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import { COLOR } from 'Theme/themeConstants';
import EditIcon from '@material-ui/icons/Edit';
import PgIcon from 'Components/PgIcon';
import helpers from 'Utils/helpers';
import DialogForm, { DialogFormProps } from './DialogForm';
import ToastMessage from 'Components/ToastMessage';
import { useSnackbar } from 'notistack';
// import { Crop } from 'react-image-crop';

export interface MultiImagePickerWithDialogFieldProps {
  numImages?: number;
  label: string;
  name?: string;
  subtitle?: string | JSX.Element;
  imgHW?: number; // This has to be a number because this is the width that will be applied for transforming the url using imageKit's api.
  // imagePlaceholderHeight: number | string;
  helperText?: string;
  classes?: MultiImagePickerWithDialogClasses;
  helperTextProps?: FormHelperTextProps;
  limitToNumImages?: boolean;
  cropConfig: DialogFormProps['cropConfig'];
}

interface MultiImagePickerWithDialogClasses {
  label?: string;
  subtitle?: string;
  helperText?: string;
  root?: string;
}

export interface MultiImagePickerWithDialogProps extends IFieldProps {
  fieldProps?: MultiImagePickerWithDialogFieldProps;
}

const MAX_FILE_SIZE = 10000;

const MultiImagePickerWithDialog: FC<MultiImagePickerWithDialogProps> = (props) => {
  const { fieldProps = {} as MultiImagePickerWithDialogFieldProps, formikProps = {} as FormikProps<any> } = props;
  const {
    name = '',
    numImages = 1,
    label = 'Add Images',
    helperText,
    imgHW = 90,
    /* imagePlaceholderHeight = 50, */ subtitle,
    classes: propClasses,
    helperTextProps,
    limitToNumImages = false,
    cropConfig,
  } = fieldProps;
  const classes = useStyles();
  const value: any[] = get(formikProps, `values.${name}`);
  const { enqueueSnackbar } = useSnackbar();
  // const [uploadingImgNums, setUploadingImgNums] = useState<number[]>([]);
  // const [uploadError, setUploadError] = useState<string | undefined>(undefined);
  // const [videoData, setVideoData] = useState<any[]>([]);
  const [valuesToModifyUsingDialog, setValuesToModifyUsingDialog] = useState<Picture[] | TFile[]>([]);
  const numAddedImages = typeof value?.length === 'number' ? value?.length : 0;
  // const pictureUpload = async (prop: { imgs: TFile[]; _rem: any[] }) => {
  //   const { imgs } = prop;
  //   try {
  //     const valueToAdd = (
  //       await Promise.all(imgs.map((img) => PictureModel.upload(img)))
  //     ).map((img) => ({ img: img as Picture }));
  //     formikProps.setFieldValue(name, [...(value ?? []), ...valueToAdd]);
  //     setValuesToModifyUsingDialog(valueToAdd);
  //     setUploadError(undefined);
  //     setUploadingImgNums([]);
  //   } catch (err) {
  //     setUploadingImgNums([]);
  //     setUploadError(err.message);
  //   }
  // };

  const fieldError = getFieldError(name, formikProps as any);

  const handleClick = (event: any) => {
    const { target = {} } = event || {};
    target.value = '';
  };

  const handleChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    // imgNum: number
  ) => {
    const { files } = event.target;

    if (files === null) {
      return <div />;
    }
    const { hasInvalidFile } = helpers.checkImageForFileSize(files);
    if (hasInvalidFile === true) {
      enqueueSnackbar(
        ToastMessage({
          title: 'Oops! Choose an image less than 10MB!',
        }),
        { variant: 'error' },
      );
      return;
    }

    for (let i = 0; i < (files?.length ?? 0); i += 1) {
      const reader = new FileReader();

      reader.onload = (progressEvent) => {
        /**
         * onload receives only progressEvent and to check what type of file we get, we must rely on *
         * the target (a FileReader instance) and the bas64 result in it.
         */
        const resultBase64String = progressEvent.target?.result as string;

        if (!resultBase64String.split('/')[0].includes('image')) {
          enqueueSnackbar(
            ToastMessage({
              title: 'One of the files you selected does not seem to be an image',
            }),
            { variant: 'error' },
          );
          return;
        }
        if (!['image/png', 'image/jpeg', 'image/gif', 'image/jpg'].includes(resultBase64String.split('/')[0])) {
          enqueueSnackbar(
            ToastMessage({
              title: 'Only jpeg, png and gif files are allowed',
            }),
            { variant: 'error' },
          );
          return;
        }
        setValuesToModifyUsingDialog((prev) => [...prev, files?.item(i) as any]);
      };

      const file = files?.item(i);
      if (file?.type?.startsWith('image')) {
        processFilesWithCallback(
          [file],
          // eslint-disable-next-line no-loop-func
          (prop: { imgs: TFile[]; _rem: any[] }) => {
            const imgsToAdd = prop.imgs.filter((file) => {
              if (!file.type.startsWith('image')) {
                enqueueSnackbar(
                  ToastMessage({
                    title: 'One of the files you selected does not seem to be an image',
                  }),
                  { variant: 'error' },
                );
                return false;
              }
              if (!['image/png', 'image/jpeg', 'image/gif', 'image/jpg'].includes(file.type)) {
                enqueueSnackbar(
                  ToastMessage({
                    title: 'Only jpeg, png and gif files are allowed',
                  }),
                  { variant: 'error' },
                );
                return false;
              }
              return true;
            });

            setValuesToModifyUsingDialog((prev) => [...prev, ...imgsToAdd]);

            // setVideoData((prev) => [...prev, file as any]);
          },
        );
      } else {
        reader.readAsDataURL(file as any);
      }
    }
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) {
      // onReorder(stateList)
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      // onReorder(stateList)
      return;
    }
    if ((value?.length || 0) > 0) {
      const newValues = arrayMoveImmutable(value, source.index, destination.index);
      formikProps.setFieldValue(name, newValues);
    }
  };

  const removeItem = (event: React.MouseEvent<HTMLElement>, index: number) => {
    if (value?.[index]) {
      event.stopPropagation();
      event.preventDefault();
      const newValues = [...(value || [])];
      newValues.splice(index, 1);
      formikProps.setFieldValue(name, newValues);
    }
  };

  return (
    <Box>
      <Box>
        <InputLabel className={propClasses?.label} shrink={false} variant='standard'>
          {label}
        </InputLabel>
        {
          // eslint-disable-next-line no-nested-ternary
          subtitle ? typeof subtitle === 'string' ? <Typography className={propClasses?.subtitle}>{subtitle}</Typography> : subtitle : null
        }
      </Box>
      <Box display="flex" className={classes.imageBoxOverflow}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="test-id" direction="horizontal">
            {(provided, snapshot) => (
              <div
                className={classes.droppableArea}
                ref={provided.innerRef}
                style={{
                  backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'transparent',
                }}
                {...provided.droppableProps}
              >
                {[...Array(value?.length >= numImages ? value?.length ?? 0 : numImages).keys()].map((number) => {
                  const disabled = number > numImages;
                  const dragId = `draggable-${number}`;
                  return (
                    <Draggable isDragDisabled={disabled} key={number} draggableId={dragId} index={number}>
                      {(dragProvided, _snapshot) => (
                        <div
                          ref={dragProvided.innerRef}
                          className={clsx(classes.root, propClasses?.root, {
                            [classes.disabled]: disabled,
                            [classes.hasImage]: !!value?.[number],
                            [classes.draggingElement]: _snapshot.isDragging,
                          })}
                          key={`${name}${number}`}
                          {...dragProvided.draggableProps}
                        >
                          <Box
                            className={clsx(classes.rearrangeIcon, {
                              [classes.invisible]: !value?.[number],
                            })}
                            {...dragProvided.dragHandleProps}
                          >
                            <OpenWithIcon />
                          </Box>
                          <>
                            {!value?.[number] ? (
                              <PgIcon
                                icon="icon-add"
                                styleClass={clsx(classes.svg, {
                                  [classes.disabledSVG]: disabled,
                                })}
                              />
                            ) : null}
                            {value?.[number] ? (
                              <>
                                <Box>
                                  {value?.[number]?.mimetype?.startsWith('image') || value?.[number]?.fileType === 'image' ? (
                                    <img className={classes.img} src={helpers.getPictureUrl(value[number])} height={imgHW} alt="" />
                                  ) : (
                                    // eslint-disable-next-line jsx-a11y/media-has-caption
                                    // <video height={imgHW} src={value?.[number]?.url as any} />
                                    <></>
                                  )}
                                </Box>
                                <Box
                                  onClick={() => {
                                    setValuesToModifyUsingDialog([value[number]]);
                                    // setVideoData(value[number]);
                                  }}
                                  className={clsx(classes.editImageIcon, {
                                    [classes.invisible]: !value?.[number],
                                  })}
                                >
                                  <EditIcon />
                                </Box>
                              </>
                            ) : null}
                            <input
                              accept="video/*,image/*"
                              disabled={disabled}
                              title=""
                              type="file"
                              name={`${name}[${numAddedImages}]`}
                              onChange={handleChange}
                              onClick={handleClick}
                              className={classes.input}
                              multiple
                            />
                          </>
                          <div id="imageRemoveOverlay" className={classes.imageRemoveOverlay}>
                            <IconButton onClick={(event) => removeItem(event, number)}>
                              <PgIcon icon="icon-add" color="contrast" styleClass={classes.rotate45} />
                            </IconButton>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {numAddedImages >= numImages && !limitToNumImages ? (
                  <div className={clsx(classes.root, propClasses?.root)}>
                    <input
                      accept="video/*,image/*"
                      title=""
                      type="file"
                      name={`${name}[${numAddedImages}]`}
                      onChange={handleChange}
                      className={classes.input}
                      multiple
                    />
                    <PgIcon icon="icon-add" styleClass={classes.svg} />
                  </div>
                ) : null}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <DialogForm
        valuesToModify={valuesToModifyUsingDialog}
        title="Image Upload"
        subtitle="Use mouse to zoom. Any additional details for your image will be displayed in the image gallery."
        // videoData={videoData}
        cropConfig={cropConfig}
        actions={{
          submit: (addedImages: Picture[]) => {
            formikProps.setFieldValue(name ?? '', getUpdatedValues(value ?? [], addedImages));
          },
          close: () => {
            setValuesToModifyUsingDialog([]);
            // setVideoData([]);
          },
        }}
        open={!!valuesToModifyUsingDialog.length}
      />
      {helperText || fieldError ? (
        <FormHelperText {...helperTextProps} className={propClasses?.helperText} error={!!fieldError}>
          {fieldError || helperText}
        </FormHelperText>
      ) : null}
    </Box>
  );
};

export default MultiImagePickerWithDialog;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: `${theme.palette.secondary.main}1A`,
      border: `1px dashed ${theme.palette.secondary.main}`,
      borderRadius: 3,
      position: 'relative',
      zIndex: 1,
      verticalAlign: 'middle',
      textAlign: 'center',
      width: 90,
      height: 90,
    },
    disabled: {
      backgroundColor: theme.palette.grey.A200,
      border: 'none',
    },
    imgPlaceholder: {
      width: 200,
      height: 200,
      borderRadius: 3,
      border: `1px solid ${theme.palette.primary.main}1A`,
    },
    input: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      opacity: 0,
      width: 'inherit',
      height: 'inherit',
      zIndex: 2,
      '&:not([disabled])': {
        cursor: 'pointer',
      },
    },
    hasImage: {
      border: 'none',
      backgroundColor: 'transparent',
      width: 'auto',
      height: 'auto',
      '&:hover #imageRemoveOverlay': {
        display: 'flex',
      },
    },
    draggingElement: {
      '&:hover #imageRemoveOverlay': {
        display: 'none !important',
      },
    },
    svg: {
      marginRight: '50%',
      marginLeft: '50%',
      transform: 'translate(-50%, -50%)',
      marginTop: '50%',
      marginBottom: '50%',
      color: theme.palette.secondary.main,
    },
    disabledSVG: {
      opacity: 0,
    },
    img: {
      display: 'inline-block',
      zIndex: 2,
      position: 'relative',
      float: 'left',
      borderRadius: 3,
      maxWidth: 200,
    },
    loader: {
      transform: 'translateY(-50%)',
      marginTop: '50%',
      marginBottom: '50%',
    },
    imageRemoveOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'none',
      cursor: 'pointer',
      width: 'inherit',
      height: 'inherit',
      zIndex: 9,
      justifyContent: 'center',
      alignItems: 'center',
      '&::before': {
        backgroundColor: COLOR.secondary.blue,
        content: "' '",
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        opacity: 0.5,
        borderRadius: 3,
      },
    },
    rearrangeIcon: {
      color: theme.palette.common.white,
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 25,
      cursor: 'grab',
    },
    editImageIcon: {
      color: theme.palette.common.white,
      position: 'absolute',
      bottom: 0,
      left: 0,
      zIndex: 25,
      cursor: 'pointer',
    },
    invisible: {
      display: 'none',
    },
    droppableArea: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
    },
    rotate45: {
      transform: 'rotate(45deg)',
    },
    imageBoxOverflow: {
      // flexDirection: 'column',
      // flexWrap: 'wrap',
      // overflowX: 'auto',
      // overflowY: 'auto',
      // marginTop: 5,
      flexShrink: 1,
    },
  }),
);

const getUpdatedValues = (currentValues: Picture[], newValues: Picture[]) => {
  const resValues = currentValues.slice();
  if (!currentValues.length) {
    return newValues;
  }
  newValues.forEach((newValue) => {
    const index = resValues.findIndex((val) => val.id === newValue.id);
    if (index === -1) {
      resValues.push(newValue);
      return;
    }
    resValues[index] = { ...resValues[index], ...newValue };
  });
  return resValues;
};
