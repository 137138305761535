/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { FC, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { max } from 'lodash';
import PgTypo from 'Components/PgTypo';
import { useStoreState } from 'Stores';
import { TEvent } from 'Models/Event';
import DropUtils from 'Utils/DropUtils';

export interface StatusBarProps {
  event: TEvent | undefined;
  customProps?: {
    text: string;
    bgColor: string;
    color: string;
  };
}
export type Status = 'published' | 'draft' | 'canceled' | 'pastEvent' | 'soldOut';

const StatusBar: FC<StatusBarProps> = ({ event, customProps }) => {
  const { availableTickets = [] } = useStoreState(({ EventDetailStore: { availableTickets } }) => ({ availableTickets }));
  const status: Status | undefined = useMemo(() => {
    if (!event) return undefined;
    if (event.eventDates) {
      const maxDate = max(event.eventDates.map((date) => date.endDate || date.startDate)) as string;
      if (new Date(maxDate) < new Date()) return 'pastEvent';
    }
    if (event?.canceled) return 'canceled';
    if (DropUtils.getDropActionType('Event', event) === 'SOLD_OUT') return 'soldOut';
    return undefined;
  }, [event, availableTickets]);
  const labelText = useMemo(() => {
    switch (status) {
      case 'pastEvent':
        return `This is a past event.`;
      case 'canceled':
        return `This event has been canceled.`;
      case 'soldOut':
        return 'This event is sold out.';
      default:
        return '';
    }
  }, [status]);
  const classes = useStyles({ status, customProps });
  if (!status && !customProps) return null;
  return (
    <Box className={classes.root}>
      <PgTypo c3 className={classes.text}>
        {customProps ? customProps.text : labelText}
      </PgTypo>
    </Box>
  );
};

const useStyles = makeStyles<Theme, { status?: Status; customProps: StatusBarProps['customProps'] }>((theme) => {
  return createStyles({
    root: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(1.5, 0),
      background: (props) => (props.customProps ? props.customProps.bgColor : props.status === 'canceled' ? '#FF0400' : theme.palette.secondary.main),
    },
    text: {
      color: (props) => props.customProps?.color ?? theme.palette.common.white,
    },
  });
});

export default StatusBar;
