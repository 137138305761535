/* eslint-disable consistent-return */
/* eslint-disable react/require-default-props */
/* eslint-disable default-case */
import React, { FC, isValidElement, useCallback, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  darken,
  Divider,
  lighten,
  ListItemIcon,
  ListItemText,
  Menu as MuiMenu,
  MenuItem,
  MenuProps as MuiMenuProps,
  PopoverOrigin,
  useTheme,
} from '@material-ui/core';
import { COLOR } from 'Theme/themeConstants';
import clsx from 'clsx';
import { EmailShareButton } from 'react-share';
import { omit } from 'lodash';

export interface ShareData {
  body?: any;
  title?: any;
  url?: any;
}
export interface MenuProps {
  id: string;
  variant?: 'filled' | 'dark-filled' | 'default';
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  options: MenuOption[];
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  menuType?: string;
  borderColor?: string;
  menuProps?: Omit<MuiMenuProps, 'open'>;
}

interface OptionProps {
  onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void | Promise<void>;
  label: string;
  icon?: JSX.Element;
  button?: React.FC<any>;
  type?: 'email' | 'fb' | 'twitter' | 'linkedIn';
  data?: ShareData;
  className?: string;
}

export type MenuOption = OptionProps | 'divider' | JSX.Element;

const returnShareButton = (button: string) => {
  switch (button) {
    case 'email':
      return EmailShareButton;
    case 'fb':
      return EmailShareButton;
    case 'twitter':
      return EmailShareButton;
  }
};

const Menu: FC<MenuProps> = ({
  id,
  anchorEl,
  handleClose,
  options,
  variant = 'default',
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'center',
  },
  menuType,
  borderColor,
  menuProps,
}) => {
  const classes = useStyles({});
  const theme = useTheme();
  const bgClass = classes[`bg-${variant}`];
  const textClass = classes[`text-${variant}`];

  const socialIconClick = (name: string, title: string, url: string) => {
    if (name === 'Email') {
      window.location.href = encodeURI(`mailto:
                    ?body=${title} ${url}`);
    }
    handleClose();
  };
  const createOnClick = useCallback((onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void) => {
    return async (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      onClick?.(event);
      handleClose();
    };
  }, []);

  return (
    <MuiMenu
      id={id}
      elevation={3}
      anchorEl={anchorEl}
      // keepMounted
      className={clsx(classes.root, menuProps?.className)}
      classes={{ list: classes.listRoot }}
      open={Boolean(anchorEl)}
      getContentAnchorEl={null}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      style={{
        zIndex: theme.zIndex.modal + 2,
        // Had to add it here , as jss styling was not taking !important.
        // And the app header has a zIndex of 1301 to be usable when login dialog is in use.
      }}
      {...omit(menuProps, 'className')}
    >
      {options.map((opt, i) => {
        if (opt === 'divider') return <Divider key={i} style={{ backgroundColor: borderColor ?? undefined }} />;

        if (isValidElement(opt)) return <div key={i}>{opt}</div>;

        const o = opt as OptionProps;
        return (
          <MenuItem key={o?.label.toLowerCase()} onClick={createOnClick(o?.onClick)} className={bgClass}>
            {menuType === 'share-menu' && o?.button ? (
              <o.button
                onClick={() => socialIconClick(o?.label, o?.data?.title.TITLE, o?.data?.url.LINK)}
                className={classes.flex}
                url={o?.data?.url.LINK}
                quote={o?.data?.title.TITLE + o?.data?.url.LINK}
                body={o?.data?.body.BODY}
                title={o?.type === 'email' || o?.type === 'fb' ? undefined : o?.data?.title.TITLE}
              >
                {o.icon ? <ListItemIcon className={textClass}>{o.icon}</ListItemIcon> : null}
                <ListItemText className={clsx(classes.text, textClass, classes.buttonLabel, o.className)}>{o.label}</ListItemText>
              </o.button>
            ) : (
              <>
                {o?.icon ? <ListItemIcon className={textClass}>{o?.icon}</ListItemIcon> : null}
                <ListItemText className={clsx(classes.text, textClass, o.className)}>{o?.label}</ListItemText>
              </>
            )}
          </MenuItem>
        );
      })}
    </MuiMenu>
  );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
  root: {
    zIndex: theme.zIndex.modal + 1,
  },
  listRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  flex: {
    display: 'flex',
  },
  buttonLabel: {
    margin: 0,
  },
  text: {
    fontSize: '16px',
    '& span': {
      fontSize: '16px',
    },
    letterSpacing: '1px',
  },
  'bg-default': {},
  'bg-filled': {
    backgroundColor: COLOR.tertiary.music,
    '&:hover': {
      backgroundColor: darken(COLOR.tertiary.music, 0.2),
    },
  },
  'bg-dark-filled': {
    backgroundColor: COLOR.primary.black,
    '&:hover': {
      backgroundColor: lighten(COLOR.primary.black, 0.2),
    },
  },
  'text-default': {
    color: COLOR.primary.black,
  },
  'text-filled': {
    color: theme.palette.common.black,
  },
  'text-dark-filled': {
    color: theme.palette.common.white,
  },
}));

export default Menu;
