/* eslint-disable react/require-default-props */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-expressions */
import React, { createRef, FC, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ClickAwayListener, IconButton, InputBase, Popper } from '@material-ui/core';
import PgIcon from 'Components/PgIcon';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
// import { SEARCH_PAGE_PATH } from 'Routes/DashboardRoutes';
import { APP_ROUTES } from 'Routes';
import PgButton from 'Components/PgButton';
import SearchResult from './SearchResult';
import useSearch from './useSearch';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import { APP_HEADER_HEIGHT } from 'Features/AppHeader';

export interface SearchBarProps {
    focusOnMount?: boolean;
    onComplete?: () => void;
    variant?: 'dark' | 'light';
}

const DOC_TYPES = ['Community', 'Event', 'Video', 'Podcast', 'Article', 'Collect', 'Album', 'Soundtrack'];

const SearchBar: FC<SearchBarProps> = (props) => {
    const { focusOnMount, onComplete, variant = 'light' } = props;
    const classes = useStyles({ variant });
    const history = useHistory();
    const { onChange, searchTerm, result, clearInput } = useSearch(DOC_TYPES);
    // const { loading: loadingSummary, tagsSummaries } = useSummary();
    const inputRef = createRef<HTMLInputElement>();
    const containerRef = createRef<HTMLInputElement>();
    const { isDeviceSm } = useMediaQuery();
    const [anchor, setAnchor] = useState<HTMLInputElement | null>(null);

    useEffect(() => {
        if (focusOnMount) {
            inputRef.current?.focus();
        }
        // eslint-disable-next-line react/destructuring-assignment
    }, [inputRef, focusOnMount]);

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => setAnchor(containerRef.current);
    const handleClickAway = () => {
        setAnchor(null);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            redirectToSearchPage();
        }
    };

    const redirectToSearchPage = () => {
        clearInput();
        setAnchor(null);
        inputRef.current?.blur();
        history.push(`${APP_ROUTES.SEARCH.path}?q=${searchTerm}`);
        // window.location.href = `${SEARCH_PAGE_PATH}?q=${searchTerm}`;
        onComplete?.();
    };

    // const loading = useMemo(() => loadingSummary || loadingResult, [loadingResult, loadingSummary]);

    const shouldShowResults = Boolean(anchor) && !!result.length && !!searchTerm;

    const onResultClick = () => {
        handleClickAway();
        onComplete?.();
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.root}>
                <div className={classes.search} ref={containerRef}>
                    {/* <div className={classes.icon}>
                        {searchTerm ? <IconButton onClick={clearInput} className={classes.clearIcon}><Icon>close</Icon></IconButton> :
                            <CustomIcon icon={SEARCH} variant='dark' size={isDeviceSm ? 30 : undefined} />
                        }
                    </div> */}
                    <InputBase
                        inputRef={inputRef}
                        value={searchTerm}
                        onChange={onChange}
                        onKeyPress={handleKeyPress}
                        onFocus={handleFocus}
                        placeholder="Search events, people and communities"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        startAdornment={
                            searchTerm ? (
                                <IconButton onClick={clearInput} className={classes.clearIcon}>
                                    <PgIcon icon="icon-close" size="small" styleClass={classes.close} />
                                </IconButton>
                            ) : (
                                <PgIcon icon="icon-search" styleClass={clsx(classes.clearIcon, classes.close)} size="small" />
                            )
                        }
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </div>
                <Popper
                    popperOptions={{ placement: 'bottom-start' }}
                    className={classes.popper}
                    open={shouldShowResults}
                    anchorEl={anchor}
                    // eslint-disable-next-line no-nested-ternary
                    style={{ width: isDeviceSm ? '100%' : searchTerm ? 320 : 400, left: isDeviceSm ? -5 : undefined }}
                    transition
                >
                    <SearchResult clickAway={handleClickAway} onResultClick={onResultClick} searchResult={result} />
                    <PgButton primary onClick={redirectToSearchPage} fullWidth className={classes.loadAllBtn}>
                        LOAD ALL RESULTS
                    </PgButton>
                </Popper>
            </div>
        </ClickAwayListener>
    );
};

// export const SEARCHBAR_WIDTH = 320;
export const SEARCHBAR_WIDTH = 438;
export const SEARCHBAR_WIDTH_MEDIUM = 210;
export const SEARCHBAR_WIDTH_SM = '100%';

const useStyles = makeStyles<Theme, { variant: SearchBarProps['variant'] }>((theme) => ({
    root: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: SEARCHBAR_WIDTH_MEDIUM,
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(0),
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: SEARCHBAR_WIDTH,
            marginLeft: theme.spacing(4),
            marginRight: theme.spacing(1.25),
        },
    },
    search: {
        position: 'relative',
        display: 'flex',
        height: 33,
        justifyContent: 'center',
        // backgroundColor: theme.palette.grey['A400'],
        // color: theme.palette.common.black,
        // borderBottom: DIVIDER_BORDER_LIGHT,
        color: (props) => (props.variant === 'dark' ? theme.palette.common.black : theme.palette.common.white),
        borderBottom: (props) => `1px solid ${props.variant === 'dark' ? theme.palette.common.black : theme.palette.common.white}`,
        alignItems: 'center',
        // marginLeft: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            // marginLeft: theme.spacing(0),
            width: SEARCHBAR_WIDTH_SM,
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            // marginLeft: theme.spacing(4),
        },
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        opacity: 1,
        fontSize: 14,
        padding: theme.spacing(0.375, 1, 0.5, 0),
        transition: theme.transitions.create('width'),
        width: '100%',
        height: 33,
        // [theme.breakpoints.up('sm')]: {
        //     width: SEARCHBAR_WIDTH,
        // },
        '&::placeholder': {
            opacity: 1,
            fontSize: 14,
        },
        '&:focus::placeholder': {
            color: 'black',
        },
    },
    icon: {
        padding: theme.spacing(0, 1, 0, 0),
        height: '100%',
        // pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    clearIcon: {
        position: 'absolute',
        right: 0,
        color: 'inherit',
        padding: theme.spacing(0),
        margin: '0px 8px',
    },
    close: {
        position: 'absolute',
        right: 0,
        color: 'inherit',
        fontWeight: theme.typography.fontWeightBold,
    },
    popper: {
        maxHeight: `calc(100vh - ${APP_HEADER_HEIGHT}px)`,
        overflow: 'auto',
        [theme.breakpoints.between('md', 'sm')]: {
            width: `${SEARCHBAR_WIDTH_MEDIUM}px !important`,
        },
        width: `${SEARCHBAR_WIDTH}px !important`,
        zIndex: theme.zIndex.drawer + theme.zIndex.modal,
        padding: 20,
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[6],
        [theme.breakpoints.down('sm')]: {
            width: `${SEARCHBAR_WIDTH_SM} !important`,
            maxHeight: `calc(100vh - 150px)`,
        },
    },
    loadAllBtn: {
        fontWeight: theme.typography.fontWeightBold,
        '&:hover': {
            color: theme.palette.common.white,
        },
    },
}));

export default SearchBar;
