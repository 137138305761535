import { Avatar, Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import SVGCIcon from 'Components/SVGComponents/SVGCIcon';
import React, { FC } from 'react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { COLOR, THEME_PALETTE } from 'Theme/themeConstants';

export interface SocialInviteProps {
  url: string;
  body?: string;
}
const SOCIAL_LINKS: { Button: any; label: string; name: string; icon?: React.ReactNode }[] = [
  { Button: LinkedinShareButton, label: 'LI', name: 'LinkedIn', icon: <PgIcon icon="icon-linkedin" style={{ color: THEME_PALETTE.common.black }} /> },
  { Button: TwitterShareButton, label: 'TW', name: 'Twitter', icon: <SVGCIcon icon="icon-twitter" color='contrast' /> },
  { Button: FacebookShareButton, label: 'FB', name: 'Facebook', icon: <PgIcon icon="icon-fb" style={{ color: THEME_PALETTE.common.black }} /> },
];
const SocialInvite: FC<SocialInviteProps> = (props) => {
  const classes = useStyles();
  const { url, body } = props;
  return (
    <Box my={2}>
      <PgTypo b2>Share on social.</PgTypo>
      <Box display="flex" mt={1.5} alignItems="center" gridGap={16}>
        {SOCIAL_LINKS.map((s) => (
          <Avatar key={s.name} className={classes.avatar}>
            <s.Button body={body} quote={body} key={s.name} title={body} url={url}>
              {/* <PgTypo c1 color="textSecondary">
                {s.label}
              </PgTypo> */}
              <Box display="flex" alignItems="center" justifyContent="center" className={classes.iconWrap}>
                {s.icon}
              </Box>
            </s.Button>
          </Avatar>
        ))}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    avatar: {
      marginRight: theme.spacing(1),
      backgroundColor: COLOR.tertiary.music,
    },
    iconWrap: { '& path': { fill: theme.palette.common.black } },
  });
});

export default SocialInvite;
