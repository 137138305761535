import PgDialog from 'Components/PgDialog';
import EmailEditMultiStepForm from 'Forms/EmailEditMultiStepForm';
import React, { FC } from 'react';
import { TAuthDialogContentProps } from '../@types';

const EditEmail: FC<TAuthDialogContentProps> = (props) => {
  return <PgDialog body={<EmailEditMultiStepForm />} positive={false} negative={false} />;
};

export default EditEmail;
