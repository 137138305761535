import { Box, makeStyles, Theme, Toolbar, ToolbarProps } from '@material-ui/core';
import clsx from 'clsx';
import PgButton from 'Components/PgButton';
import PgTypo from 'Components/PgTypo';
import { COMMUNITY_TAB_NAVIGATION_ID, NO_PRINT_CLASS, UNVERIFIED_EMAIL_BANNER_ID } from 'Constants/variables';
import useAuth from 'Features/Auth/useAuth';
import useAuthDialog from 'Features/Auth/useAuthDialog';
import { IBannerMessage } from 'Features/BannerMessage';
import React, { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { APP_ROUTES } from 'Routes';
import { useStoreState } from 'Stores';

export type AlertType = 'error' | 'info';
export interface AlertProps {
    heading?: React.ReactNode;
    subheading: React.ReactNode;
    type: AlertType;
    toolBarProps?: ToolbarProps;
}

const Alert: FC<AlertProps> = ({ type, heading, subheading, toolBarProps }) => {
    const classes = useStyles({});
    return (
        <Toolbar id={UNVERIFIED_EMAIL_BANNER_ID} {...toolBarProps} className={clsx(classes.toolbar, toolBarProps?.className)}>
            {heading && <PgTypo c3>{heading}</PgTypo>}
            <PgTypo b6>{subheading}</PgTypo>
        </Toolbar>
    );
};

const VerifyEmailBanner: FC<IBannerMessage> = ({ appUser }) => {
    const { requestEmailVerification } = useAuth();
    const { openDialog } = useAuthDialog();
    const sendEmailHandler = async () => {
        if (!appUser) return;
        openDialog('verifyEmailPrompt');
        await requestEmailVerification(appUser.email);
    };
    const classes = useStyles({});

    return (
        <Box className={clsx(classes.verifyEmail, NO_PRINT_CLASS)}>
            <PgTypo b6>Looks like your email is not verified.</PgTypo>
            <PgButton quaternary underlined onClick={sendEmailHandler} className={classes.alertButton}>
                Resend Verification
            </PgButton>
            <PgTypo b6>or</PgTypo>
            <PgButton quaternary href={APP_ROUTES.CONTACT.path} underlined className={classes.alertButton}>
                Contact Us
            </PgButton>
        </Box>
    );
};

const AlertMessage: FC = () => {
    const { appUser, open } = useStoreState(({ AuthStore: { appUser }, DrawerSidebarStore: { open } }) => ({
        appUser,
        open,
    }));
    const isCommunityNavTabPresent = !!document.getElementById(COMMUNITY_TAB_NAVIGATION_ID);
    const classes = useStyles({ isCommunityNavTabPresent, sidebarOpen: open });
    const { search } = useLocation();
    const isCommunityConversations = useMemo(() => {
        return search.includes('discussionId');
    }, [search]);
    if (appUser && !appUser?.emailVerified)
        return (
            <Alert
                type="error"
                toolBarProps={{
                    className: clsx(classes.unverifiedAlert, { [classes.communityMargin]: isCommunityConversations }),
                }}
                subheading={<VerifyEmailBanner appUser={appUser} />}
            />
        );
    return null;
};

const ALERT_HEIGHT = 40;

const useStyles = makeStyles<Theme, { isCommunityNavTabPresent?: boolean; sidebarOpen?: boolean }>((theme) => ({
    unverifiedAlert: {
        // position: 'fixed',
        // left: (props) => (props.sidebarOpen ? DRAWER_SIDEBAR_EXTENDED_WIDTH : DRAWER_SIDEBAR_COLLAPSED_WIDTH),
        // right: 0,
        // top: (props) => (props.isCommunityNavTabPresent ? APP_HEADER_HEIGHT + NAVIGETION_TABS_HEIGHT : APP_HEADER_HEIGHT),
        // zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            // left: '0px !important',
            // right: '0px !important',
            padding: `${theme.spacing(2)}px !important`,
        },
    },
    toolbar: {
        minHeight: ALERT_HEIGHT,
        background: theme.palette.error.light,
        color: theme.palette.error.contrastText,
        padding: 0,
        justifyContent: 'center',
    },
    communityMargin: {
        // right: MEMBER_DRAWER_WIDTH,
        // marginRight: MEMBER_DRAWER_WIDTH,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        },
    },
    subheading: {
        textTransform: 'none',
    },
    alertButton: {
        color: theme.palette.common.white,
        margin: '-5px 2px 0px',
        fontSize: '0.75rem',
    },
    verifyEmail: {
        textAlign: 'center',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
}));

export default AlertMessage;
