import Axios from 'axios';
import EventsModel from 'Models/Event';
import { EventTicketFormData } from '../../Forms/Ticketing/EventTicketForm';
import useToastMessage from '../../Hooks/useToastMessage';
import EventRegistrationModel from '../../Models/EventRegistration';
import { TicketTier } from 'Models/Event/ticketing/@types';

export interface PaymentAuthInstance {
  isNewUser: boolean;
  tempAccessToken: string;
}

export interface PaymentStatus extends PaymentAuthInstance {
  name: string;
  quantity: number;
  refunded: boolean;
  orderId: string;
  canceled: boolean;
  id: string;
  created: Date;
  updated: Date;
  transactionId: string;
  isPurchaseSuccess?: boolean;
  nftAssets?: INFTAsset[];
  ticketTier?: TicketTier
}

export interface INFTAsset {
  id: string;
  status: 'PENDING' | 'CLAIMED' | 'ERROR';
  metadata: INFTAssetMetadata;
}

export interface INFTAssetMetadata {
  id: string;
  receipt: {
    objectChanges?: SuiObjectChange[] | null;
  }
}

type ObjectOwner =
/** Object is exclusively owned by a single address, and is mutable. */
{
    AddressOwner: string;
} /**
* Object is exclusively owned by a single object, and is mutable. The object ID is converted to
* SuiAddress as SuiAddress is universal.
*/ | {
    ObjectOwner: string;
} /** Object is shared, can be used by any address, and is mutable. */ | {
    Shared: {
        /** The version at which the object became shared */
        initial_shared_version: string;
    };
} | 'Immutable';

type SuiObjectChange =
/** Module published */
{
    digest: string;
    modules: string[];
    packageId: string;
    type: 'published';
    version: string;
} /** Transfer objects to new address / wrap in another object */ | {
    digest: string;
    objectId: string;
    objectType: string;
    recipient: ObjectOwner;
    sender: string;
    type: 'transferred';
    version: string;
} /** Object mutated. */ | {
    digest: string;
    objectId: string;
    objectType: string;
    owner: ObjectOwner;
    previousVersion: string;
    sender: string;
    type: 'mutated';
    version: string;
} /** Delete object */ | {
    objectId: string;
    objectType: string;
    sender: string;
    type: 'deleted';
    version: string;
} /** Wrapped object */ | {
    objectId: string;
    objectType: string;
    sender: string;
    type: 'wrapped';
    version: string;
} /** New object creation */ | {
    digest: string;
    objectId: string;
    objectType: string;
    owner: ObjectOwner;
    sender: string;
    type: 'created';
    version: string;
};
export interface PaymentIntentResponse extends PaymentAuthInstance {
  clientSecret: string;
  transactionId: string;
}

function useEventTicketing() {
  const withToast = useToastMessage();

  const registerForEvent = async (data: Partial<EventTicketFormData>, eventId: string) => {
    await withToast(
      async () => {
        await EventRegistrationModel.createNewEventRegistration({ ...data, eventId });
      },
      {
        successToastMessage: 'Successfully registered for event',
      }
    );
  };

  const checkForPaymentStatus = async (transactionId: string, accessToken?: string): Promise<PaymentStatus> => {
    const res = await Axios.request<PaymentStatus>({
      url: `/stripeAccounts/checkForPaymentIntentStatusChange`,
      method: 'POST',
      data: {
        transactionId,
      },
      headers: accessToken ? { Authorization: accessToken } : undefined,
    });
    return res.data;
  };

  const purchaseTicket = async (
    eventId: string,
    ticketCount: number,
    userDetails: Partial<EventTicketFormData>,
    ticketTierId?: string,
    promoCode?: string,
    password?: string
  ) => {
    const data = await EventsModel.get_Events_id_purchase_tickets<PaymentIntentResponse | PaymentStatus>(
      eventId,
      ticketCount,
      userDetails,
      ticketTierId,
      promoCode,
      password
    );
    // const { data } = await Axios.request<{ clientSecret: string, transactionId: string }>({
    //     url: `stripeAccounts/createPaymentIntent`,
    //     method: 'POST',
    //     data: {
    //         eventId,
    //         ticketCount,
    //         userDetails,
    //         ticketTierId,
    //     }
    // })
    return data;
  };

  return { registerForEvent, purchaseTicket, checkForPaymentStatus };
}

export default useEventTicketing;
