/* eslint-disable import/prefer-default-export */
import { THEME_PALETTE } from 'Theme/themeConstants';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { FormConfig, IFormActionProps } from 'react-forms';
import * as Yup from 'yup';
import usePasswordField from 'Components/usePasswordField';
import { IProps } from '.';

export const useFormConfig = (props: IProps) => {
  const classes = useStyles();
  const actionConfig: IFormActionProps = {
    submitButtonText: 'CHANGE',
    submitButtonLayout: 'fullWidth',
    submitButtonProps: {
      size: 'large',
    },

    containerClassNames: classes.buttonContainer,
  };

  const passwordField = usePasswordField('password', 'New Password', false, {
    // className: classes.textField,
    // fullWidth: true,
    // variant: 'standard',
    // InputLabelProps: {
    //   disabled: true,
    //   error: false, // hide label when error
    //   classes: {
    //     disabled: classes.hideLabel,
    //   },
    // },
    // FormHelperTextProps: {
    //   classes: {
    //     root: classes.whiteBG,
    //   },
    // },
    placeholder: 'Password',
    // InputProps: {
    //   color: 'secondary',
    //   disableUnderline: true,
    //   placeholder: 'Password',
    // },
  });
  const repeatPasswordField = usePasswordField('confirmPassword', 'Confirm Password', false, {
    // className: classes.textField,
    style: { marginTop: '17px' },
    // // label: 'Community Name',
    // fullWidth: true,
    // variant: 'standard',
    // // placeholder: 'First name',
    // InputLabelProps: {
    //   disabled: true,
    //   error: false, // hide label when error
    //   classes: {
    //     disabled: classes.hideLabel,
    //   },
    // },
    // FormHelperTextProps: {
    //   // variant: 'standard',
    //   // filled: false,
    //   // className: classes.whiteBG,
    //   classes: {
    //     root: classes.whiteBG,
    //     // shrink: classes.labelShrink,
    //   },
    // },
    placeholder: 'Confirm Password',
    // InputProps: {
    //   color: 'secondary',
    //   disableUnderline: true,
    // },
  });
  const inputConfig: Array<Array<FormConfig> | FormConfig> = [
    // {
    //   type: 'section-header',
    //   valueKey: 'section-header-5',
    //   fieldProps: {
    //     title: 'Password',
    //     style: {
    //       letterSpacing: 0,
    //       fontWeight: 600,
    //       position: 'relative',
    //       top: '75px',
    //       marginTop: '-80px',
    //     },
    //     titleWeight: 'medium',
    //   },
    // },
    passwordField,
    // {
    //   type: 'section-header',
    //   valueKey: 'section-header-5',
    //   fieldProps: {
    //     title: 'Confirm Password',
    //     style: {
    //       letterSpacing: 0,
    //       fontWeight: 600,
    //       position: 'relative',
    //       top: '90px',
    //       marginTop: '-80px',
    //     },
    //     titleWeight: 'medium',
    //   },
    // },
    repeatPasswordField,
  ];
  const validationSchema = Yup.object({
    password: Yup.string().min(8, 'Password must be minimum 8 character long').required('Password required'),
    confirmPassword: Yup.string()
      .required('Confirm password required')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
  });

  return {
    actionConfig,
    inputConfig,
    validationSchema,
  };
};

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    buttonContainer: {
      marginTop: 40,
      '& > button': {
        // backgroundColor: THEME_PALETTE.secondary.main,
        // color: `${THEME_PALETTE.text.secondary}!important`,
        border: 'none',
        fontWeight: 700,
        fontSize: '14px',
      },
    },
    adornmentIcon: {
      cursor: 'pointer',
    },
    textFieldClass: {
      backgroundColor: `${theme.palette.primary.main}!important`,
      color: theme.palette.common.white,
      // borderBottom: '1px solid' + theme.palette.common.white,
      '& input': {
        color: 'white',
        padding: '20px 1px 7px',
      },
    },
    labelClass: {
      // position: 'absolute',
      // top: '-22px',
      left: ' -10px',
      color: theme.palette.common.white,
    },
    inputStatusClass: {
      left: '-11px',
      top: '4px',
      position: 'relative',
    },
    textField: {
      backgroundColor: `${theme.palette.grey.A400}!important`,
      '& div.MuiInput-underline:before': {
        borderBottom: '0px',
      },
      '& > div': {
        width: '100%',
        // position: 'absolute',
        // top: '24px',
      },
      '& > div > input': {
        padding: '5px 4px 5px 8px',
      },
      '& > div:hover': {
        border: 'none !important',
      },
      height: '30px',
      '& label + .MuiInput-formControl': {
        marginTop: '0px !important',
      },
    },
    whiteBG: {
      backgroundColor: 'white !important',
    },
    hideLabel: {
      display: 'none',
    },
    passwordInput: {
      marginTop: '0px !important',
    },
  }),
);
