/* eslint-disable import/no-cycle */
import { Box, createStyles, makeStyles, Portal, Theme } from '@material-ui/core';
import AppHeader from 'Features/AppHeader';
import MobileAppHeader from 'Features/AppHeader/header';
import React, { FC, useEffect, useMemo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ANALYTICS_USER_EVENT } from 'Analytics/analyticActions';
import clsx from 'clsx';
import { ZERO_LEFT_MARGIN_CLASS } from 'Constants/variables';
import AlertMessage from 'Features/Alerts';
import FormFields from 'Features/ReactFormFields/FormFields';
import useDeactivationListener from 'Features/Settings/useDeactivationListener';
import DrawerSidebar, { DRAWER_SIDEBAR_COLLAPSED_WIDTH, DRAWER_SIDEBAR_EXTENDED_WIDTH } from 'Features/Sidebar/DrawerSidebar';
import useAppLocationFlows from 'Hooks/useAppLocationFlows';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import useSocketListener from 'Hooks/useSocketListener';
import { useLocation } from 'react-router';
import { useStoreActions, useStoreDispatch, useStoreState } from 'Stores';
import RootContextProviders from '../Contexts';
import AppNavigation from '../Routes';

FormFields();

export interface TEmailVerificationParams {
    verificationToken?: string;
    verificationEmail?: string;
}

const routesWithoutDrawerSidebar = ['/awards*'];

const Layout: FC = () => {
    const { pathname } = useLocation();
    const dispatch = useStoreDispatch();

    /**
     * for storing previous routes
     */
    const { setPrevRoutes } = useStoreActions(({ App: { setPrevRoutes } }) => ({ setPrevRoutes }));
    useEffect(() => {
        setPrevRoutes(window.location);
        dispatch({
            type: ANALYTICS_USER_EVENT,
            data: { eventName: 'PAGE_VIEWED', context: { user: appUser, path: pathname, url: window.location.href }, source: pathname },
        });
    }, [pathname]);

    const { isDeviceSm } = useMediaQuery();
    const classes = useStyles();
    useAppLocationFlows();

    const { component: deactivationComponent } = useDeactivationListener();

    const { appUser, open } = useStoreState(({ AuthStore: { appUser }, DrawerSidebarStore: { open } }) => ({
        appUser,
        open,
    }));
    const { socket, socketInit, disconnectSocket, authenticateSocket } = useSocketListener();

    useEffect(() => {
        console.log('SOCKET_DEBUG', 'socketInit running');
        socketInit();
        dispatch({
            type: ANALYTICS_USER_EVENT,
            data: { eventName: 'APP_LOADED', context: { user: appUser }, source: pathname },
        });

        return () => {
            console.log('SOCKET_DEBUG', 'disconnectSocket running');
            disconnectSocket();
        };
    }, []);

    useEffect(() => {
        if (appUser && socket.connected) {
            console.log(`Authenticating socket for user ${appUser.id}`);
            authenticateSocket(socket);
        }
    }, [appUser?.id, socket.connected]);

    const marginLeft = useMemo(() => {
        if (appUser) {
            if (open) return `${DRAWER_SIDEBAR_EXTENDED_WIDTH}px`;
            return `${DRAWER_SIDEBAR_COLLAPSED_WIDTH}px`;
        }
        return 0;
    }, [open, appUser]);

    const shouldShowDrawerSidebar = useMemo(() => {
        let shouldShowDrawerSidebar = !!appUser && !isDeviceSm;
        routesWithoutDrawerSidebar.forEach((route) => {
            if (new RegExp(route).test(pathname)) shouldShowDrawerSidebar = false;
        });
        return shouldShowDrawerSidebar;
    }, [appUser, isDeviceSm, pathname]);

    return (
        <div>
            {/* Show Side Bar
          - when logged in
          Don't Show side bar
          - when logged out
          - awards/* route
      */}
            {shouldShowDrawerSidebar && <DrawerSidebar open={open} />}
            <Box ml={shouldShowDrawerSidebar ? marginLeft : 0} className={clsx(classes.root, ZERO_LEFT_MARGIN_CLASS)}>
                {/* {isDeviceSm ? (
          <MobileAppHeader creditPoints={appUser?.wallet?.credits_total || 0} />
        ) : (
          <AppHeader showLogo={!shouldShowDrawerSidebar} creditPoints={appUser?.wallet?.credits_total || 0} />
          )} */}
                <AppHeader showLogo={!shouldShowDrawerSidebar} creditPoints={appUser?.wallet?.credits_total || 0} />
                <AlertMessage />
                <Box /* mt={isDeviceSm ? 0 : '50px'} */>
                    <AppNavigation />
                </Box>
            </Box>
            <Portal>
                {/* <BasicActionDialog
          open={openDialog}
          title="Sit tight! We'll review your community profile as soon as we can. Keep an eye on your inbox for a welcome email."
          onCancel={handleClick}
          cancelBtnText="BROWSE MORE COMMUNITIES"
          disableEscapeKeyDown
          showCloseBtn={false}
        /> */}
                {deactivationComponent}
            </Portal>
        </div>
    );
};

const RootLayout: FC = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, [pathname]);

    return (
        <RootContextProviders>
            <Layout />
        </RootContextProviders>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        root: {
            // minHeight: `calc(100vh + ${APP_HEADER_HEIGHT}px)`,
        },
    });
});

export default RootLayout;
