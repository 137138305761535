import qs from 'qs';

export interface CommunitiesQuery {
  page: number;
  approvalStatus: 'all' | 'pending' | 'suspended' | 'ignored' | 'approved';
  q: string;
}

export const COMMUNITIES_PER_PAGE = 20;

const communitiesQueryUtil = {
  getSearchQuery: (query: CommunitiesQuery): Record<string, any> => {
    let approvalStatus = ['PENDING', 'IGNORED'];
    if (query.approvalStatus === 'pending') {
      approvalStatus = ['PENDING'];
    } else if (query.approvalStatus === 'suspended') {
      approvalStatus = ['SUSPENDED'];
    } else if (query.approvalStatus === 'ignored') {
      approvalStatus = ['IGNORED'];
    } else if (query.approvalStatus === 'approved') {
      approvalStatus = ['APPROVED'];
    }

    return {
      where: {
        approvalStatus,
      },
      sort: { 'name.keyword': { order: 'asc' } },
      limit: COMMUNITIES_PER_PAGE,
      skip: (query.page - 1) * COMMUNITIES_PER_PAGE,
    };
  },

  getQueryFromSearch: (search: string): CommunitiesQuery => {
    const q = qs.parse(search, {
      ignoreQueryPrefix: true,
    }) as Record<string, string>;

    return {
      page: q.page ? Number(q.page) : 1,
      approvalStatus: (q.approvalStatus ? q.approvalStatus : 'approved') as CommunitiesQuery['approvalStatus'],
      q: '',
    };
  },

  getSearchQueryString: (query: CommunitiesQuery) => {
    return qs.stringify(
      {
        page: query.page,
        // userSort: query.sort.user,
        // communitySort: query.sort.community,
        approvalStatus: query.approvalStatus,
        // q: query.q,
      },
      {
        addQueryPrefix: true,
        encode: false,
      },
    );
  },
};

export default communitiesQueryUtil;
