import React, { FC } from 'react';
import Joyride, { Props as JoyRideProps, Step } from 'react-joyride';

export interface OnboardState {
  run: boolean;
  steps: Step[];
  hideCloseButton?: boolean;
}

export interface OnboardingProps extends JoyRideProps {}

const Onboarding: FC<OnboardingProps> = (props) =>{
    return (
      <Joyride
        continuous
        hideBackButton
        hideCloseButton
        disableCloseOnEsc
        disableOverlayClose
        scrollToFirstStep
        styles={{
          options: {
            zIndex: 10000,
          },

        }}
        {...props}
      />
    );
}

export default Onboarding
