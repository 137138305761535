import * as API from './api';
import * as Utils from './utils';

export * from './@types';

const UserModel = {
  ...API,
  ...Utils,
};

export default UserModel;
