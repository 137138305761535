import { TDrop } from 'Models/Drop/@types';
import { useCallback, useState } from 'react';
import RSVPDialog from '.';
import React from 'react';
import { IRSVPFormData } from './Sections/RSVPForm';
import UserModel from 'Models/User';
import useShowToast from 'Hooks/useShowToast';
import MESSAGES from 'Utils/Messages';
import useAuthDialog from 'Features/Auth/useAuthDialog';
import { useStoreActions } from 'Stores';
import useAsyncTask from 'Hooks/useAsyncTask';

function useRSVPDialog(onDone?: (drop: TDrop, userId: string, password?: string) => any) {
  const [drop, setDrop] = useState<TDrop & { password?: string }>();

  const showToast = useShowToast();

  const { openDialog: openAuthDialog } = useAuthDialog();

  const { authenticate } = useStoreActions(({ AuthStore: { authenticate } }) => ({ authenticate }));

  const openDialog = useCallback((drop: TDrop) => {
    setDrop(drop);
  }, []);

  const closeDialog = useCallback(() => {
    setDrop(undefined);
  }, []);

  const handleFormSubmit = useAsyncTask(async (values: Partial<IRSVPFormData>) => {
    const { email, firstName, lastName } = values;

    if (!email) return;

    const isEmailExist = await UserModel.checkIfEmailExists(email);
    if (isEmailExist) {
      showToast(MESSAGES.EMAIL_EXIST_LOGIN, undefined, 'info');
      closeDialog();
      openAuthDialog();
      return;
    }

    const { accessToken } = await UserModel.createUserWithEmail({ email, firstName, lastName });
    await authenticate({ token: accessToken.id });

    const { password, ...restDrop } = drop ?? {};
    onDone?.(restDrop as TDrop, accessToken.userId, password);
    closeDialog();
  });

  return {
    openDialog,
    closeDialog,
    component: drop?.id ? (
      <RSVPDialog
        open={!!drop.id}
        drop={drop}
        onClose={closeDialog}
        onSubmit={handleFormSubmit.run}
        isLoading={handleFormSubmit.status === 'PROCESSING'}
      />
    ) : null,
  };
}

export default useRSVPDialog;
