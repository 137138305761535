/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
import React, { FC } from 'react';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';

import TimePicker from 'rc-time-picker';
import { getFieldError, IFieldProps } from 'react-forms';
import { get } from 'lodash';
import { createStyles, FormHelperText, FormHelperTextProps, makeStyles, Theme, Typography } from '@material-ui/core';
import { inputStyles } from 'Components/FormFields/inputStyles';
import clsx from 'clsx';
import PgIcon from 'Components/PgIcon';
import DateTimeUtils from 'Resources/DateTimeUtils';

export interface ITimeFieldProps extends IFieldProps {
  fieldProps?: {
    label?: string;
    placeholder?: string;
    timezone?: string;
    onTimeSet?: (updatedDate: string) => void;
    helperText?: string;
    helperTextProps?: FormHelperTextProps;
    timeInputFormat?: string;
    minuteStep?: number;
    allowEmpty?: boolean;
    disabled?: boolean;
    onDisableClick?: () => void;
  };
}

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mm';

const RCTimePicker: FC<ITimeFieldProps> = (props) => {
  const { fieldConfig, formikProps, fieldProps = {} } = props;
  const {
    label = 'Time',
    timezone = 'GMT',
    placeholder,
    onTimeSet,
    helperText,
    helperTextProps,
    timeInputFormat = 'h:mm a',
    minuteStep = 5,
    allowEmpty = false,
    disabled,
    onDisableClick,
  } = fieldProps;
  const valueKey = fieldConfig?.valueKey || '';
  const value = get(formikProps?.values, valueKey);
  // const date = value ? moment.utc(value).tz(timezone) : undefined;
  // const date = moment(new Date(0)).utc();

  // const [time, setTime] = useState<number | undefined>(DateTimeUtils.getMinutes(value));
  const inputClass = inputStyles();
  const classes = useStyles();
  const error = getFieldError(valueKey, formikProps || {});
  // useEffect(() => {
  //   if (!time) return;
  //   // const baseDate = date ?? moment(new Date(0)).utc();
  //   const updatedDate = date
  //     .add('minutes', time)
  //     // .tz(`${baseDate.format(DATE_FORMAT)} ${time}`, `${DATE_FORMAT} ${TIME_FORMAT}`, timezone)
  //     // .utc()
  //     .format();
  //   // eslint-disable-next-line no-unused-expressions
  //   formikProps?.setFieldValue(valueKey, updatedDate);
  //   if (onTimeSet) onTimeSet(updatedDate);
  // }, [time, timezone]);

  return (
    <div onClick={disabled ? onDisableClick : undefined}>
      {label ? (
        <Typography className={clsx(inputClass.label, { [classes.errorLabel]: !!error })} variant="caption">
          {label}
        </Typography>
      ) : null}
      <div className={classes.root}>
        <TimePicker
          defaultOpenValue={moment(new Date(0)).utc()}
          placeholder={placeholder}
          showSecond={false}
          value={value ? moment(new Date(0)).utc().minutes(DateTimeUtils.getMinutes(value)) : undefined}
          className={clsx(inputClass.input, classes.input)}
          onChange={(v) => {
            if (!v) return;
            // eslint-disable-next-line no-unused-expressions
            formikProps?.setFieldValue(valueKey, v.format(TIME_FORMAT));
            // eslint-disable-next-line no-unused-expressions
            onTimeSet?.(v.format(TIME_FORMAT));
          }}
          format={timeInputFormat}
          use12Hours
          // inputReadOnly
          disabled={!!disabled}
          minuteStep={minuteStep}
          popupClassName={classes.popup}
          inputIcon={<PgIcon styleClass={classes.inputIcon} icon="icon-time" />}
          allowEmpty
          clearIcon={<></>}
        />
        <div className={classes.helperText}>
          <FormHelperText {...helperTextProps} error={!!error}>
            {error || helperText}
          </FormHelperText>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      // display: 'flex',
      width: '100%',
      position: 'relative',
      '& button': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
      '& .rc-time-picker-input': {
        background: 'transparent',
        position: 'absolute',
        fontSize: '14px',
        border: 'none',
        top: '8px',
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.common.black,
        left: '10px',
        outline: 'none',
      },
    },
    input: {
      fontWeight: 700,
      fontSize: theme.typography.body2.fontSize,
    },
    inputIcon: {
      position: 'absolute',
      right: 10,
      top: 10,
    },
    popup: {
      // background: 'pink',
      marginTop: '45px',
      zIndex: theme.zIndex.modal + theme.zIndex.snackbar,
    },
    helperText: {
      '& p': {
        marginTop: '2px',
      },
    },
    errorLabel: {
      color: theme.palette.error.main,
    },
  })
);

export default RCTimePicker;
