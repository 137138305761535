import { ANALYTICS_USER_EVENT } from "Analytics/analyticActions";
import useAsyncTask from "Hooks/useAsyncTask";
import AppModel from "Models/App";
import CommunityModel from "Models/Community";
import { TCommunity } from "Models/Community/@types";
import { TUserCommunity, User } from "Models/User";
import { WalletType } from "Models/Web3/@types";
import helpers from "Utils/helpers";
import { coinbaseWallet } from "Web3/Connector/Coinbase";
import { metaMask } from "Web3/Connector/Metamask";
import { phantom } from "Web3/Connector/PhantomWallet";
import { walletConnect } from "Web3/Connector/WalletConnect";
import { Match } from "autolinker";

export const initialGenerator = (name: string) => {
  if (!name) return '';
  return `${name.split(' ')?.[0]?.[0] || ''}${name.split(' ')?.[1]?.[0] || name.split(' ')?.[2]?.[0] || ''}`;
};

export const sentenceCaseConvertor = (name: string) => {
  return name.split(' ')?.[0]?.[0]?.toUpperCase() + name.slice(1).toLowerCase();
};

export const autoLinkerReplaceFn = (match: Match) => {
  switch (match.getType()) {

    case 'url':
      return helpers.isValidUrl(match.getAnchorHref());

    case 'email':
      return helpers.validateEmail(match.getMatchedText());

  }
}


const getCommData = async (appUser: User) => {
  const { urls: { regular: img1 } } = await AppModel.getRandomImage(appUser.slug);
  const { urls: { regular: img2 } } = await AppModel.getRandomImage(appUser.slug);
  return { name: appUser.slug, slug: appUser.slug, _profileImages: [{ url: img1 }], _profileBanner: { url: img2 } };
};

export const autoCreateComm = async (appUser: User) => {
  try {
    const data = await getCommData(appUser);
    const { creatorRequest, communityInstance } = await CommunityModel.createCommunityApprove(data);

    return { ...communityInstance, id: creatorRequest.communityId, slug: communityInstance?.slug ?? '' } as TCommunity;
  } catch (error) {
    console.error(error)
  }
};

export const connectWalletEagerly = async (appUser: User) => {
  if (appUser.recentWalletUsed === WalletType.METAMASK) await metaMask.connectEagerly();
  else if (appUser.recentWalletUsed === WalletType.COINBASE) await coinbaseWallet.connectEagerly();
  else if (appUser.recentWalletUsed === WalletType.WALLETCONNECT) await walletConnect.connectEagerly();
  else if (appUser.recentWalletUsed === WalletType.PHANTOM) await phantom.connectEagerly?.();
}
