import useAsyncTask from "Hooks/useAsyncTask";
import AppModel from "Models/App";
import { IUnsplashPrams, IUnsplashResponse } from "Models/App/@types";
import { useEffect, useMemo, useState } from "react";

function useImageGenerator(defaultParams: Partial<IUnsplashPrams> = {}) {

  const [params, setParams] = useState<IUnsplashPrams>({ query: '', per_page: 12, ...defaultParams });
  const [result, setResult] = useState<IUnsplashResponse>();

  const fetchData = useAsyncTask(async (params: IUnsplashPrams) => {
    const res = await AppModel.getUnsplashImages(params);
    setResult(r => ({ ...res, results: (params.page ?? 1) > 1 ? [...(r?.results ?? []), ...res.results] : res.results }));
  })

  useEffect(() => {
    if (params.page && params.page <= 1) setResult(undefined);
    fetchData.run({ ...params, query: params.query || 'random' });
  }, [params]);

  const isLoading = useMemo(() => fetchData.status === 'PROCESSING', [fetchData.status]);

  return { setParams, params, result, isLoading };
}

export default useImageGenerator;
