import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Pagination as MuiPagination, PaginationItem, PaginationProps as MuiPaginationProps } from '@material-ui/lab';
import { LISTING_LIMIT } from 'Features/Query/PaginationQuery';

export interface PaginationProps extends Pick<MuiPaginationProps, 'onChange' | 'page'> {
  total: number;
  itemsPerPage?: number;
}

const Pagination: FC<PaginationProps> = ({ total, itemsPerPage = LISTING_LIMIT, onChange, ...paginationProps }) => {
  const classes = useStyles({});

  const count = Math.ceil(total / itemsPerPage);

  const handleChange = (e: React.ChangeEvent<unknown>, page: number) => {
    if (page === paginationProps.page) return;
    onChange?.(e, page)
  }

  return (
    <MuiPagination
      renderItem={(item) => (
        <PaginationItem
          classes={{
            page: classes.page,
            selected: classes.selectedPage,
            disabled: classes.disabled,
          }}
          {...item}
        />
      )}
      className={classes.root}
      onChange={handleChange}
      {...paginationProps}
      count={count}
    />
  );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
  root: {
    boxShadow: theme.shadows[3],
    padding: theme.spacing(1),
    lineHeight: 0,
    border: '1px solid black',
    '& ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected)': {
      paddingBottom: '2px',
    },
  },
  page: {
    fontWeight: 400,
    // paddingBottom: '2px',
  },
  selectedPage: {
    backgroundColor: 'transparent !important',
    fontWeight: 700,
    paddingBottom: '2px',
  },
  disabled: {
    display: 'none',
  },
  previousNext: {
    color: 'pink',
  },
}));

export default Pagination;
