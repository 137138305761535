import useDiscussion, { TDiscussionConfig } from 'Features/Discussion/useDiscussion';
import React, { FC, useState } from 'react';

interface IChatContext extends ReturnType<typeof useDiscussion> {
  initChat: (config: TDiscussionConfig) => void;
  chatConfig: TDiscussionConfig | undefined;
}

export const ChatContext = React.createContext<IChatContext>({
  currentDiscussion: undefined,
  fetchMessages: async () => {},
  fetchReplies: async () => {},
  handlePinUnpin: async () => {},
  handleUnlockedDiscussion: async () => {},
  hasGate: false,
  isAdmin: false,
  isBanned: false,
  isFetchingMessage: true,
  loading: true,
  sendMessage: async () => {},
  initChat: () => {},
  chatConfig: undefined,
  hasLeftChat: false,
});

export const ChatProvider: FC = (props) => {
  const { children } = props;

  const [chatConfig, setChatConfig] = useState<TDiscussionConfig>({} as TDiscussionConfig);

  const values = useDiscussion(chatConfig);

  const handleChatInit: IChatContext['initChat'] = (chatConfigData) => {
    setChatConfig({ reverse: true, ...chatConfigData });
  };

  return <ChatContext.Provider value={{ ...values, initChat: handleChatInit, chatConfig }}>{children}</ChatContext.Provider>;
};
