import { action, Action, thunk, Thunk } from 'easy-peasy';
import { LoginFormData } from 'Forms/Login';
import UserModel from 'Models/User';
import { User } from 'Models/User/@types';
import { get, omit } from 'lodash';
import { Address, TLocation } from 'Models/Address/@types';
import { TFilter } from 'Models/App/@types';
import AuthModel from 'Models/Auth';
import { TDrop } from 'Models/Drop/@types';
import EventModel, { TEvent } from 'Models/Event';
import { TRootStore } from 'Stores';
import utils from 'Utils';
import helpers from 'Utils/helpers';
import MESSAGES from 'Utils/Messages';
import { registrationComplete } from 'Analytics/trackers';

interface IAuthenticatePayload {
  token: string;
  communityId?: string;
  user?: User;
}

export const CURRENT_COMMUNITY_KEY = 'currentCommunityId';
export interface AuthState {

  socketConnectionId: string;
  setSocketConnectionId: Action<AuthState, string>;

  socketConnected: boolean;
  setSocketConnected: Action<AuthState, boolean>;

  appUser?: User;
  setAppUser: Action<AuthState, User | undefined>;
  addTotalCredits: Action<AuthState, number>;

  isAuthenticated: boolean;

  setIsAuthenticated: Action<AuthState, boolean>;

  authenticate: Thunk<AuthState, IAuthenticatePayload, null, TRootStore, Promise<User>>;

  followDialogShownUserIds: string[];
  addfollowDialogShownUsersIds: Action<AuthState, { item: string }>;

  signup: Thunk<AuthState, User & { referringCode?: string }, null, TRootStore>;
  login: Thunk<AuthState, LoginFormData, null, TRootStore>;
  fetchMe: Thunk<AuthState, void, null, TRootStore>;
  logout: Thunk<AuthState, void, null, TRootStore>;

  updateUser: Thunk<AuthState, Partial<User> & { id: string }, null, TRootStore, Promise<Partial<User>>>;

  eventReminded: Action<AuthState, { reminderId: string; event: TEvent }>;
  eventUnReminded: Action<AuthState, string>;
  contentFavorited: Action<AuthState, { favouriteId: string; event: TDrop }>;
  contentUnFavorited: Action<AuthState, string>;
  createdEvents: Partial<TDrop>[];
  savedEvents: Partial<TDrop>[];
  setSavedEvents: Action<AuthState, TDrop[]>;
  setCreatedEvents: Action<AuthState, TDrop[]>;
  fetchSavedEvents: Thunk<AuthState, TFilter>;
}

const AuthStore: AuthState = {
  socketConnected: false,
  setSocketConnected: action((state, payload) => {
    state.socketConnected = payload;
  }),

  socketConnectionId: '',
  setSocketConnectionId: action((state, payload) => {
    state.socketConnectionId = payload;
  }),

  addTotalCredits: action((state, payload) => {
    const appUser = state.appUser;
    if (appUser && appUser.wallet) {
      state.appUser = {
        ...appUser,
        wallet: { ...appUser.wallet, credits_total: (appUser.wallet?.credits_total ?? 0) + payload },
      };
    }
  }),

  isAuthenticated: false,
  setAppUser: action((state, payload) => {
    state.appUser = state.isAuthenticated ? { ...state.appUser, ...payload } as User : payload;
    if (payload?.currentCommunity?.id) utils.setCookie(CURRENT_COMMUNITY_KEY, payload.currentCommunity.id);
  }),
  savedEvents: [],
  createdEvents: [],

  followDialogShownUserIds: [],
  addfollowDialogShownUsersIds: action((state, { item }) => {
    state.followDialogShownUserIds = helpers.toggleItemFromList(state.followDialogShownUserIds, item, '', (a, b) => a === b);
  }),
  setIsAuthenticated: action((state, payload) => {
    state.isAuthenticated = payload;
  }),

  authenticate: thunk(async (actions, payload) => {
    utils.setAuthHeader(payload.token);
    utils.setAccessToken(payload.token);
    try {
      const user = payload.user ?? (await UserModel.fetchMe());

      actions.setAppUser(user);
      // if (includes(user.roles, 'ADMIN') || includes(user.roles, 'COMMUNITY')) {
      actions.setIsAuthenticated(true);
      return user;
    } catch (error) {
      actions.logout();
      throw error;
    }
  }),

  signup: thunk(async (actions, params) => {
    try {
      if (get(params, 'error') || get(params, 'errorslug')) {
        throw new Error(MESSAGES.GENERAL_ERROR);
        return;
      }
      const { email, password, firstName, lastName, location, placeId, address, slug, referringCode } = params;
      if (!email || !slug || !password) return;
      const data = await AuthModel.signup({
        email,
        password,
        firstName: slug,
        slug,
        // lastName,
        referringCode,
        location: location ?? ({} as TLocation),
        placeId,
        address: address ?? ({} as Address),
      });
      registrationComplete();
      const { accessToken, ...rest } = data;
      await actions.authenticate({ token: accessToken.id });
      return rest;
    } catch (error) {
      // error handling
      console.error(error);
      throw error;
    }
  }),

  login: thunk(async (actions, params) => {
    const data = await AuthModel.login({ ...params });
    const userData = await actions.authenticate({ token: data.id });
    return userData;
  }),

  fetchMe: thunk(async (actions) => {
    const user = await UserModel.fetchMe();
    actions.setAppUser(user);
    actions.setIsAuthenticated(true);
  }),
  logout: thunk(async (actions, _, { getStoreActions, getState }) => {
    try {
      await UserModel.logOut();
      // getStoreActions().CommunityStore.setCommunity(undefined);
    } catch (error) {
      // Catch error
    }
    utils.removeAuthHeader();
    utils.clearCookies();
    // actions.setAppUser(undefined);
    getState().appUser = undefined;
    actions.setIsAuthenticated(false);
  }),
  updateUser: thunk(async (actions, params, { getState }) => {
    const { roles, ...rest } = params;
    const appUser = getState().appUser;
    const data = await UserModel.updateUser({ ...rest, id: params.id });
    Object.keys(data).forEach((key) => data[key as keyof User] === undefined && delete data[key as keyof User]);
    if (appUser)
      actions.setAppUser({
        ...appUser,
        ...data,
        roles: appUser?.roles ?? [],
        wallet: appUser?.wallet /* Wallet info is not returned by update endpoint */,
      });
    return data;
  }),

  contentFavorited: action((state, { event, favouriteId }) => {
    state.savedEvents = state.savedEvents.map((e) => (e.id === event.id ? { ...e, ...event, isFavourited: true, favouriteId } : e) as TDrop);
    state.createdEvents = state.createdEvents.map((e) => (e.id === event.id ? { ...event, isFavourited: true, favouriteId } : e));
  }),
  contentUnFavorited: action((state, eventId) => {
    state.savedEvents = state.savedEvents.filter((e) => e.id !== eventId);
    state.createdEvents = state.createdEvents.map((e) => (e.id === eventId ? omit(e, ['isFavourited', 'favouriteId']) : e) as TDrop);
  }),
  eventReminded: action((state, { event, reminderId }) => {
    state.savedEvents = state.savedEvents.map((e) => (e.id === event.id ? { ...e, ...event, isReminded: true, reminderId } : e));
    state.createdEvents = state.createdEvents.map((e) => (e.id === event.id ? { ...event, isReminded: true, reminderId } : e));
  }),
  eventUnReminded: action((state, eventId) => {
    state.savedEvents = state.savedEvents.filter((e) => e.id !== eventId);
    state.createdEvents = state.createdEvents.map((e) => (e.id === eventId ? omit(e, ['isReminded', 'reminderId']) : e) as TDrop);
  }),
  setSavedEvents: action((state, payload) => {
    state.savedEvents = [...payload];
  }),
  setCreatedEvents: action((state, payload) => {
    state.createdEvents = [...payload];
  }),
  fetchSavedEvents: thunk(async (actions, _params, { getState }) => {
    const userId = getState().appUser?.id || '';
    if (!userId) return;
    const data = await UserModel.getFavorites<TEvent>(userId, {
      where: { subjectType: 'Event' },
      include: {
        relation: 'subject',
        scope: {
          include: ['user', 'tags'],
        },
      },
      limit: 50,
    });
    const createdEvents = await EventModel.getMyEvents({
      include: ['user', 'tags'],
      limit: 50,
    } as TFilter);
    actions.setSavedEvents(data.filter((d) => Boolean(d.subject)).map((d) => d.subject!));
    actions.setCreatedEvents(createdEvents);
  }),
};

export default AuthStore;
