import { initializeConnector } from '@web3-react/core';
import { Network } from '@web3-react/network';
import { Phantom } from 'web3-react-phantom';

import { URLS } from '../Chains';

export const [phantom, hooks] = initializeConnector<Phantom>(
  (actions) =>
    new Phantom({
      actions,
      options: {
        mustBePhantom: true,
      },
    }),
);
