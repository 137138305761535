import { Box, ButtonBase, IconButton, Popover, TextField, Theme, createStyles, darken, makeStyles } from '@material-ui/core';
import PgIcon from 'Components/PgIcon';
import React, { FC, MouseEvent, ReactNode, useState } from 'react';
import { SocialMediaLinks } from 'Models/User';
import { IFieldProps, getFieldError } from 'react-forms';
import { FormikProps } from 'formik';
import { TCommunity } from 'Models/Community/@types';
import { omit, pick } from 'lodash';
import { SHADES } from 'Theme/newThemeConstants';
import PgTypo from 'Components/PgTypo';
import PgButton from 'Components/PgButton';
import { FONT_FAMILY } from 'Theme/themeConstants';
import SVGCIcon from 'Components/SVGComponents/SVGCIcon';
import UrlInput from 'Components/Inputs/UrlInput';
import { URL_PROTOCOL_CHECK_REG_EXP } from 'Constants/RegExp';

type SocialLinkKeys = keyof Omit<SocialMediaLinks, 'websiteUrl' | 'clubHouseUrl'>

const linksIcons: Record<SocialLinkKeys, ReactNode> = {
  instagramUrl: <PgIcon icon="icon-instagram" />,
  twitterUrl: <SVGCIcon icon="icon-twitter" color='contrast' width={19} height={19} />,
  tikTokUrl: <SVGCIcon icon="icon-tiktok" color='contrast' />,
  // clubHouseUrl: <PgIcon icon="icon-clubhouse" />,
  facebookUrl: <PgIcon icon="icon-fb" />,
  linkedInUrl: <PgIcon icon="icon-linkedin" />,
  youTubeUrl: <PgIcon icon="icon-youtube" />,
  twitchUrl: <PgIcon icon="icon-twitch" />,
  spotifyUrl: <PgIcon icon="icon-spotify" />,
  soundCloudUrl: <PgIcon icon="icon-soundcloud" />,
  discordUrl: <SVGCIcon icon='icon-discord' color='contrast' />,
}

type TSocialLinkObject = { key: SocialLinkKeys, value: ReactNode };

const SocialLinksField: FC<IFieldProps> = (props) => {

  const formikProps = props.formikProps as FormikProps<Partial<TCommunity>>;
  const currentSocialLinks = formikProps?.values?.socialLinks ?? {};

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [changeOption, setChangeOption] = useState<TSocialLinkObject>();

  const popOverId = Boolean(anchorEl) ? 'social-links-selector-popover' : undefined;
  const availableOptions = Object.entries(omit(linksIcons, Object.keys(currentSocialLinks))).map(([key, value]) => ({ key, value }) as TSocialLinkObject);
  const links = Object.entries(pick(linksIcons, Object.keys(currentSocialLinks))).map(([key, value]) => ({ key, value }) as TSocialLinkObject);

  const handleClick = (option: TSocialLinkObject) => (e: MouseEvent<HTMLButtonElement>) => {
    if (!availableOptions.length) return;
    setChangeOption(option);
    setAnchorEl(e.currentTarget);
  }

  const handleClose = () => setAnchorEl(null);

  const handleOptionSelect = (currOption: TSocialLinkObject) => () => {
    handleClose();
    if (!changeOption) return;
    const socialLinks = Object.fromEntries(Object.entries(currentSocialLinks).map(([key, value]) => {
      if (key === changeOption.key) return [currOption.key, value];
      return [key, value];
    }));
    formikProps.setValues({ ...formikProps.values, socialLinks: socialLinks });
  }

  const handleRemove = (link: TSocialLinkObject) => () =>
    formikProps.setValues({ ...formikProps.values, socialLinks: omit(currentSocialLinks, link.key) });

  const handleAdd = () =>
    formikProps.setValues({ ...formikProps.values, socialLinks: { ...currentSocialLinks, [availableOptions?.[0]?.key]: '' } });

  const classes = useStyles();

  return (
    <Box display='flex' flexDirection='column' gridGap={16}>
      {links.map(link => {

        const fieldErr = getFieldError(`socialLinks.${link.key}`, formikProps);

        return (
          <Box display='flex' alignItems='flex-start' gridGap={4}>
            <Box aria-describedby={popOverId} component={ButtonBase} onClick={handleClick(link)} pl={1} bgcolor={SHADES.grey} height={37}>
              {link?.value}
              <PgIcon icon='icon-carrot-down' />
            </Box>
            <Popover
              id={popOverId}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
              <Box display='flex' flexDirection='column'>
                {availableOptions.map(o => {
                  const name = o.key.slice(0, -3);
                  return (
                    <Box key={o.key} component={ButtonBase} className={classes.optionBtn} onClick={handleOptionSelect(o)}>
                      {o.value}
                      <PgTypo b2 className={classes.optionName}>{name?.split('')?.[0]?.toUpperCase() + name.slice(1)}</PgTypo>
                    </Box>
                  );
                })}
              </Box>
            </Popover>

            <Box display='flex' alignItems='flex-start' flex={1}>
              {console.log('test link value', link.key, currentSocialLinks?.[link.key])}
              <TextField
                name={`socialLinks.${link.key}`}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={currentSocialLinks?.[link.key]}
                variant='outlined'
                placeholder='Insert your url'
                error={!!fieldErr}
                helperText={fieldErr}
                fullWidth
              />
              <IconButton onClick={handleRemove(link)}>
                <PgIcon icon='icon-close' />
              </IconButton>
            </Box>
          </Box>
        );
      })}
      {!!availableOptions.length && (
        <PgButton primary onClick={handleAdd} className={classes.addBtn}>Add social</PgButton>
      )}
    </Box>
  )
}

export default SocialLinksField;

const useStyles = makeStyles((theme: Theme) => createStyles({
  addBtn: {
    maxWidth: 126,
    ...theme.typography.overline,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    fontFamily: FONT_FAMILY.secondary,
    backgroundColor: theme.palette.common.white,
    '&:hover': { backgroundColor: darken(theme.palette.common.white, 0.15) },
    '&:focus': { backgroundColor: darken(theme.palette.common.white, 0.15) },
  },
  optionName: { marginLeft: theme.spacing(1) },
  optionBtn: { padding: theme.spacing(1, 2), justifyContent: 'flex-start' },
}));
