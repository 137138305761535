import React from 'react';
import { makeStyles, Theme, Typography, TypographyProps } from '@material-ui/core';
import clsx from 'clsx';
import { TypographyStyleOptions } from '@material-ui/core/styles/createTypography';
import { FONT_FAMILY, FONT_WEIGHTS, THEME_PALETTE } from 'Theme/themeConstants';
import { omit } from 'lodash';
import sanitize from 'sanitize-html';
import { defaultSanitizeProps } from 'Utils/Constants';
import helpers from 'Utils/helpers';

interface TypeProps {
  /** Headings */
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  h5?: boolean;
  h6?: boolean;
  h7?: boolean;

  /** Bold fonts */
  c1?: boolean;
  c2?: boolean;
  c3?: boolean;
  c4?: boolean;
  c5?: boolean;
  b4Bold?: boolean;

  /** Body fonts */
  b1?: boolean;
  b2?: boolean;
  b3?: boolean;
  b4?: boolean;
  b5?: boolean;
  b6?: boolean;

}
export interface PgTypoProps extends TypographyProps, TypeProps {
  component?: React.ElementType;
  underline?: boolean;
  limitLines?: number;
  upperCase?: boolean;
  typoWeight?: keyof typeof FONT_WEIGHTS;
  contrast?: boolean;
  typoColor?: 'primary' | 'secondary' | 'default' | 'contrast' | 'error';
  fontFamily?: keyof typeof FONT_FAMILY;
}

const PgTypo = React.forwardRef<HTMLElement, PgTypoProps>((props, ref) => {
  const { children, className, typoWeight, style, contrast, typoColor, fontFamily } = props;
  const styles: PgTypoProps['style'] = {
    fontWeight: typoWeight ? FONT_WEIGHTS[typoWeight] : undefined,
    fontFamily: fontFamily ? FONT_FAMILY[fontFamily] : undefined,
    ...style,
  };
  const typoProps = getTypoProps(omit(props, ['typoWeight', 'typoColor', 'contrast', 'upperCase', 'underline', 'underline']));
  const classes = useStyles({ ...props, style: styles });

  return (
    <Typography
      ref={ref}
      {...typoProps}
      className={clsx(classes.typography, className, {
        [classes.colorPrimary]: typoColor === 'primary',
        [classes.colorSecondary]: typoColor === 'secondary',
        [classes.colorDefault]: typoColor === 'default',
        [classes.colorContrast]: typoColor === 'contrast' || contrast,
        [classes.colorError]: typoColor === 'error',
      })}
    >
      {children}
    </Typography>
  );
});

export default PgTypo;

/** Convert extended props to the base typography props.
 * 1. Theme variants are mapped to the extended props.
 */
const getTypoProps = (props: PgTypoProps): TypographyProps => {
  const { h1, h2, h3, h4, h5, h6, h7, c1, c2, c3, c4, c5, b4Bold, b1, b2, b3, b4, b5, b6, underline, limitLines, ...rest } = props;
  if (rest.dangerouslySetInnerHTML) {
    // console.log('setting dangerous html', { html: rest.dangerouslySetInnerHTML.__html });
    rest.dangerouslySetInnerHTML = {
      __html: sanitize(rest.dangerouslySetInnerHTML.__html, defaultSanitizeProps),
    };
  }

  const updatedProps: TypographyProps = {};
  if (h1) updatedProps.variant = 'h1';
  if (h2) updatedProps.variant = 'h2';
  if (h3) updatedProps.variant = 'h3';
  if (h4) updatedProps.variant = 'h4';
  if (h5) updatedProps.variant = 'h5';
  if (h6) updatedProps.variant = 'h6';
  if (h7) updatedProps.variant = 'subtitle1';
  if (c1) updatedProps.variant = 'subtitle2';
  if (b1) updatedProps.variant = 'body2';
  if (b2) updatedProps.variant = 'body1';
  if (b4) updatedProps.variant = 'caption';
  if (b6) updatedProps.variant = 'overline';
  return { ...rest, ...updatedProps };
};

/**
 * Get the font styles for the extended props which are not supported with
 * theme typography variants.
 * @param theme
 * @param props
 * @returns
 */
const getFontsStyles = (theme: Theme, props: PgTypoProps): TypographyStyleOptions => {
  const { c2, c3, c4, c5, b3, b5, b4Bold } = props;
  if (c2) return { ...theme.typography.subtitle2, fontFamily: FONT_FAMILY.primary, fontWeight: FONT_WEIGHTS.fontWeightBold };
  if (c3) return { ...theme.typography.caption, fontFamily: FONT_FAMILY.primary, fontWeight: FONT_WEIGHTS.fontWeightBold };
  if (c4) return { ...theme.typography.caption, fontFamily: FONT_FAMILY.secondary, fontWeight: FONT_WEIGHTS.fontWeightBold };
  if (b3) return { ...theme.typography.caption, fontFamily: FONT_FAMILY.secondary };
  if (b5) return { ...theme.typography.overline, fontFamily: FONT_FAMILY.secondary };
  if (c5) return { ...theme.typography.overline, fontWeight: FONT_WEIGHTS.fontWeightBold };
  if (b4Bold) return { ...theme.typography.caption, fontFamily: FONT_FAMILY.primary, fontWeight: FONT_WEIGHTS.fontWeightBold };
  return {} as TypographyStyleOptions;
};

const useStyles = makeStyles<Theme, PgTypoProps>((theme) => ({
  typography: (props) => {
    const fontStyles = getFontsStyles(theme, props);
    return {
      ...props.style,
      ...fontStyles,
      // fontWeight: (props) => (props.typoWeight ? theme.typography[props.typoWeight] : undefined),
      textDecoration: props?.underline || props.underline ? 'underline' : undefined,
      overflow: props.limitLines ? 'hidden' : undefined,
      textOverflow: props.limitLines ? 'ellipsis' : undefined,
      lineClamp: props.limitLines,
      WebkitLineClamp: props.limitLines,
      boxOrient: 'vertical',
      display: '-webkit-box',
      textTransform: props.upperCase ? 'uppercase' : undefined,
    };
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  colorContrast: {
    color: theme.palette.common.white,
  },
  colorSecondary: {
    color: theme.palette.secondary.main,
  },
  colorDefault: {
    color: THEME_PALETTE.grey.B300,
  },
  colorError: {
    color: theme.palette.error.main,
  },
}));

PgTypo.displayName = 'PgTypo';
