import React, { FC } from 'react';
import InfiniteScroll, { Props as InfiniteScrollProps } from 'react-infinite-scroll-component';
/**
 * for reference : https://www.npmjs.com/package/react-infinite-scroll-component
 */
export interface InfiniteScrollListProps extends InfiniteScrollProps { }

const InfiniteScrollList: FC<InfiniteScrollListProps> = (props) => {
    const { children, ...restProps } = props;
    return (
        <InfiniteScroll {...restProps}>
            {children}
        </InfiniteScroll>
    )
}



export default InfiniteScrollList
