import React, { FC, } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

export interface PgPointsProps extends SVGCIconProps {
    iconProps?: {
        color?: string;
    };
}

const PgPoints: FC<PgPointsProps> = (props) => {
    const { width, height, iconProps = {} } = props;
    const { color } = iconProps;
    return (
        <svg width={width ?? "16"} height={height ?? "16"} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_28733_121582)">
                <path d="M15.2974 8C15.2974 12.0302 12.0302 15.2974 8 15.2974C3.96978 15.2974 0.702637 12.0302 0.702637 8C0.702637 3.96978 3.96978 0.702637 8 0.702637C12.0302 0.702637 15.2974 3.96978 15.2974 8Z" fill="white" stroke="#010101" stroke-miterlimit="10" />
                <path d="M7.91889 3.62695L9.02527 6.938L12.5187 6.96637L9.71018 9.04539L10.7639 12.3767L7.91889 10.3463L5.07796 12.3767L6.1276 9.04539L3.31909 6.96637L6.81251 6.938L7.91889 3.62695Z" fill={color ?? "#010101"} />
            </g>
            <defs>
                <clipPath id="clip0_28733_121582">
                    <rect width={width ?? "16"} height={height ?? "16"} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default PgPoints;
