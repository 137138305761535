import { action, Action, actionOn, ActionOn, thunk, Thunk } from 'easy-peasy';
import { omit } from 'lodash';
import { TESSearchItem } from 'Models/App/@types';
import DropModel from 'Models/Drop';
import { TContentType, TDrop } from 'Models/Drop/@types';
import { TEvent } from 'Models/Event';
import { TResource } from 'Models/Resource/@types';
import usersModel from 'Models/User';
import { TRootStore } from 'Stores';

export type FavouriteEventsWithType = { content: TEvent | TResource; contentType: TContentType };
export type InfiniteScrollSetAction = 'APPEND' | 'SET';

const LIMIT = 48;

export interface TUserFavouriteEventsState {
  loading: boolean;
  setLoading: Action<TUserFavouriteEventsState, boolean>;

  totalFavouriteEvents: number;
  setTotalFavouriteEvents: Action<TUserFavouriteEventsState, number>;
  getTotalFavouriteEvents: Thunk<TUserFavouriteEventsState, { userId: string }>;

  favouriteEvents: TDrop[];
  setFavouriteEvents: Action<TUserFavouriteEventsState, { list: TDrop[]; action: InfiniteScrollSetAction }>;
  getFavouriteEvents: Thunk<TUserFavouriteEventsState, { userId: string; action: InfiniteScrollSetAction; skip?: number }>;

  onContentFavorite: ActionOn<TUserFavouriteEventsState, TRootStore>;
  onContentUnFavorite: ActionOn<TUserFavouriteEventsState, TRootStore>;
  onEventRemind: ActionOn<TUserFavouriteEventsState, TRootStore>;
  onEventUnRemind: ActionOn<TUserFavouriteEventsState, TRootStore>;
}

const UserFavouriteEventsStore: TUserFavouriteEventsState = {
  loading: false,
  favouriteEvents: [],
  totalFavouriteEvents: 0,

  setLoading: action((state, payload) => {
    state.loading = payload;
  }),

  setTotalFavouriteEvents: action((state, payload) => {
    state.totalFavouriteEvents = payload;
  }),

  setFavouriteEvents: action((state, payload) => {
    state.favouriteEvents = payload.action === 'APPEND' ? [...state.favouriteEvents, ...payload.list] : payload.list;
  }),

  getTotalFavouriteEvents: thunk(async (actions, { userId }) => {
    const data = await usersModel.getFavoritesCount(userId);

    actions.setTotalFavouriteEvents(data.count);
  }),

  getFavouriteEvents: thunk(async (actions, { userId, action, skip = 0 }, { getState }) => {
    actions.setLoading(true);

    const data = await usersModel.getFavorites<TEvent>(userId, {
      where: {
        or: [
          { subjectType: 'Event' },
          { subjectType: 'Video' },
          { subjectType: 'Collect' },
          { subjectType: 'Podcast' },
          { subjectType: 'Article' },
          { subjectType: 'Soundtrack' },
          { subjectType: 'Album' },
        ],
      },
      include: {
        relation: 'subject',
        scope: {
          include: ['tags', 'community', 'ticketingInfo', 'collaborators'],
        },
      },
      order: 'updated DESC',
      limit: LIMIT,
      skip,
    });

    actions.setFavouriteEvents({
      list: DropModel.parseDropsList(
          data
          .filter((d) => d.subject)
          .map((d) => {
            return { hit: { ...d.subject, doc_type: d.subjectType } } as TESSearchItem<TDrop>;
          })
        ),
      action,
    });
    actions.setLoading(false);
  }),

  onContentFavorite: actionOn(
    (actions, storeActions) => storeActions.AuthStore.contentFavorited,
    (state, { payload: { event, favouriteId } }) => {
      state.favouriteEvents = state.favouriteEvents.map((e) =>
        e.id === event.id ? { ...e, ...event, isFavourited: true, favouriteId } : e
      ) as TDrop[];
    }
  ),
  onContentUnFavorite: actionOn(
    (actions, storeActions) => storeActions.AuthStore.contentUnFavorited,
    (state, { payload }) => {
      state.favouriteEvents = state.favouriteEvents.map((e) => (e.id === payload ? omit(e, ['isFavourited', 'favouriteId']) : e)) as TDrop[];
    }
  ),
  onEventRemind: actionOn(
    (actions, storeActions) => storeActions.AuthStore.eventReminded,
    (state, { payload: { event, reminderId } }) => {
      state.favouriteEvents = state.favouriteEvents.map((e) => (e.id === event.id ? { ...e, ...event, isReminded: true, reminderId } : e));
    }
  ),
  onEventUnRemind: actionOn(
    (actions, storeActions) => storeActions.AuthStore.eventUnReminded,
    (state, { payload }) => {
      state.favouriteEvents = state.favouriteEvents.map((e) => (e.reminderId === payload ? omit(e, ['isReminded', 'reminderId']) : e)) as TDrop[];
    }
  ),
};

export default UserFavouriteEventsStore;
