/* eslint-disable import/no-cycle */
import React from 'react';
// eslint-disable-next-line import/no-cycle
import { TSVGCIcon } from './SVGCIcon';
import youtube from './icons/youtube';
import twitch from './icons/twitch';
import vimeo from './icons/vimeo';
import clubHouse from './icons/ClubHouse';
import zoom from './icons/zoom';
import remove from './icons/Remove';
import Klaviyo from './icons/Klaviyo';
import Spatial from './icons/Spatial';
import Sandbox from './icons/Sandbox';
import Nowhere from './icons/Nowhere';
import Ronday from './icons/Ronday';
import UploadFile from './icons/UploadFile';
import TikTok from './icons/TikTok';
import Bell from './icons/Bell';
import Cube from './icons/Cube';
import Wallet from './icons/Wallet';
import Discord from './icons/Discord';
import Chat from './icons/Chat';
import Album from './icons/Album';
import Soundtrack from './icons/Soundtrack';
import TwitterNew from './icons/TwitterNew';
import Key from './icons/Key';
import Streaming from './icons/Streaming';
import Mic from './icons/Mic';
import MicOutlined from './icons/MicOutlined';
import Hash from './icons/Hash';
import Settings from './icons/Settings';
import AddUser from './icons/AddUser';
import Menu from './icons/Menu';
import PgPoints from './icons/PgPoints';
import Info from './icons/info';
import Locked from './icons/locked';
import share from './icons/share';

const Mapping: Record<Partial<TSVGCIcon>, React.ElementType> = {
    'icon-youtube': youtube,
    'icon-twitch': twitch,
    'icon-vimeo': vimeo,
    'icon-clubhouse': clubHouse,
    'icon-zoom': zoom,
    'icon-remove': remove,
    'icon-klaviyo': Klaviyo,
    'icon-sandbox': Sandbox,
    'icon-spatial': Spatial,
    'icon-nowhere': Nowhere,
    'icon-ronday': Ronday,
    'icon-file-upload': UploadFile,
    'icon-tiktok': TikTok,
    'icon-bell': Bell,
    'icon-cube': Cube,
    'icon-wallet': Wallet,
    'icon-discord': Discord,
    'icon-chat': Chat,
    'icon-album': Album,
    'icon-soundtrack': Soundtrack,
    'icon-twitter': TwitterNew,
    'icon-key': Key,
    'icon-stream': Streaming,
    'icon-mic': Mic,
    'icon-mic-outlined': MicOutlined,
    'icon-hash': Hash,
    'icon-settings': Settings,
    'icon-add-user': AddUser,
    'icon-menu': Menu,
    'icon-points': PgPoints,
    "icon-info": Info,
    'icon-lock': Locked,
    'icon-share': share,
};

export default Mapping;
