import { THEME_PALETTE } from "Theme/themeConstants";
import sanitize from "sanitize-html";

export const DIV_TO_DECODE_HTML_TAGS = document.createElement('div');

export const INVITE_READ_MORE_LINK = 'https://playgroundapp.notion.site/WTF-08a3be8829bb41299fbf1a40ba0390cd';

export const TERMS_OF_SERVICE_LINK = 'https://playgroundapp.notion.site/Terms-of-Service-8f387733c152474eb70124a5a2b71595';

export const PRIVACY_POLICY_LINK = 'https://playgroundapp.notion.site/Privacy-Policy-9e86753c19ee4f389d023fe29566cac5';

export const ZENDESK_SUPPORT_LINK = 'https://playgroundapp.zendesk.com/hc/en-us/requests/new';

export const defaultSanitizeProps: sanitize.IOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a', 'img', 'u', 'br', 'p', 'span', 's'],
  allowedAttributes: {
    span: ['style'],
    img: ['src', 'srcset', 'alt', 'title', 'width', 'height'],
    a: ['href', { name: 'rel', values: ['noopener noreferrer'] }, { name: 'target', values: ['_blank'] }, 'style', 'class'],
  },
  transformTags: {
    a: function(tagName, attribs) {
      return {
        tagName,
        attribs: { target: '_blank', style: `color: ${THEME_PALETTE.info.main};`, ...attribs },
      };
    },
  },
};
