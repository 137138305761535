import React, { FC } from 'react';
import { Box, CircularProgress, Dialog } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PgTypo, { PgTypoProps } from 'Components/PgTypo';
import PgButton from 'Components/PgButton';

export interface SettingConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  body?: string | JSX.Element;
  cancelText?: string;
  confirmText?: string;
  onConfirm?: () => Promise<void>;
  showCancel?: boolean;
  loading?: boolean;
  classes?: {
    paper?: string;
  };
  titleProps?: PgTypoProps;
}

const SettingConfirmationDialog: FC<SettingConfirmationDialogProps> = (props) => {
  const classes = useStyles();
  const {
    open,
    onClose,
    title,
    body,
    loading = false,
    onConfirm,
    confirmText,
    showCancel = true,
    cancelText = 'Cancel',
    classes: propClasses,
    titleProps,
  } = props;
  const handleClose = () => {
    onClose();
  };
  const handleConfirm = () => {
    onConfirm?.();
  };
  return (
    <Dialog
      PaperProps={{ className: clsx(classes.paper, propClasses?.paper) }}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className={classes.title}>
        <PgTypo variant="h3" {...titleProps}>
          {title}
        </PgTypo>
      </Box>

      <Box className={classes.content}>{typeof body === typeof '' ? <PgTypo variant="caption">{body}</PgTypo> : body}</Box>
      {/* <DialogActions className={classes.action}> */}
      <Box p={1} className={classes.action}>
        {showCancel && (
          <PgButton onClick={handleClose} secondary fullWidth>
            {cancelText}
          </PgButton>
        )}
        {confirmText && onConfirm ? (
          <PgButton onClick={handleConfirm} primary fullWidth>
            {loading ? <CircularProgress /> : confirmText}
          </PgButton>
        ) : null}
      </Box>
      {/* </DialogActions> */}
    </Dialog>
  );
};
const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    paper: {
      width: 500,
      padding: theme.spacing(4, 2),
    },
    title: {
      padding: 0,
      flex: '0 0 auto',
      margin: 0,
      textAlign: 'center',
    },
    content: {
      flex: '1 1 auto',
      padding: theme.spacing(1, 3),
      overflowY: 'auto',
      textAlign: 'center',
    },
    action: {
      display: 'flex',
      width: '100%',
      gap: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
  });
});

export default SettingConfirmationDialog;
