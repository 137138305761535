/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Formik, FormikConfig, useFormikContext } from 'formik';
import { MLFormBuilder } from 'react-forms';
import * as Yup from 'yup';
import { useFormConfig } from './config';

export interface CheckEmailExistFormData {
  email: string;
}

export interface CheckEmailExistFormProps {
  onSubmit: (data: Partial<CheckEmailExistFormData>) => any;
  initialData: Partial<CheckEmailExistFormData>;
  isSubmitting: boolean;
  innerRef?: FormikConfig<Partial<CheckEmailExistFormData>>['innerRef'];
}

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Email address required'),
});

const CheckEmailExistForm: React.FC<CheckEmailExistFormProps> = (props) => {
  return (
    <Formik<Partial<CheckEmailExistFormData>>
      initialValues={props.initialData}
      onSubmit={props.onSubmit}
      innerRef={props.innerRef}
      validationSchema={validationSchema}
    >
      <FormConsumer isSubmitting={props.isSubmitting} />
    </Formik>
  );
};

const FormConsumer: React.FC<Pick<CheckEmailExistFormProps, 'isSubmitting'>> = (props) => {
  const { actionConfig, schema } = useFormConfig();
  const formikProps = useFormikContext();

  return (
    <MLFormBuilder
      schema={schema}
      settings={{ verticalSpacing: 10, horizontalSpacing: 48 }}
      formId="check-email-exist"
      actionConfig={actionConfig}
      formikProps={formikProps}
      isInProgress={props.isSubmitting}
    />
  );
};

export default CheckEmailExistForm;
