/* eslint-disable no-nested-ternary */
/*  eslint-disable react/require-default-props, jsx-a11y/img-redundant-alt */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { Formik, FormikProps } from 'formik';
import { MLFormContent, TFile } from 'react-forms';
import { Picture } from 'Models/Picture/@types';
import PictureModel from 'Models/Picture';
import useAsyncTask from 'Hooks/useAsyncTask';
import * as Yup from 'yup';
import AppModel from 'Models/App';
// import helpers from 'Utils/helpers';
// import ImageCropperWrapper from 'Components/ImageCropperWrapper';
import 'cropperjs/dist/cropper.css';
import Cropper from 'react-cropper';
import { Area } from 'react-easy-crop';
import { TAsset } from 'Models/App/@types';
import BasicActionDialog from 'Dialogs/BasicActionDialog';
import ImageCropper, { getCroppedImg } from 'Components/ImageCropper';
// import { Crop } from 'react-image-crop';

export interface DialogFormProps {
  valuesToModify: Picture[] | TFile[] | TAsset[];
  title: string;
  subtitle: string;
  actions: {
    submit: (values: Picture[]) => void;
    close: () => void;
  };
  open?: boolean;
  // videoData?: any;
  cropConfig?: Omit<Cropper.Options, 'checkOrientation' | 'onInitialized' | 'src' | 'viewMode' | 'center'>;
}

interface ComponentState {
  imagesState: (FileWithAdditionalFields | Picture | TAsset)[];
  currentModifyingIndex: number;
  lastAction?: SubmitActions;
}
type SubmitActions = 'next' | 'submit' | 'prev';
type FileWithAdditionalFields = TFile & {
  source?: string;
  caption?: string;
};

function DialogForm({ valuesToModify, open = false, actions, title, subtitle, /* videoData, */ cropConfig }: DialogFormProps): JSX.Element | null {
  const classes = styles();
  const { submit, close } = actions;
  const [componentState, setComponentState] = useState<ComponentState>({
    imagesState: valuesToModify,
    currentModifyingIndex: 0,
  });
  const { imagesState, currentModifyingIndex, lastAction } = componentState;
  const formRef = useRef<FormikProps<Picture | FileWithAdditionalFields>>(null);
  // const [croppedImageBase64, setCroppedImageBase64] = useState<string | null>(null);
  const [cropper, setCropper] = useState<Cropper | null>(null);

  const [cropperData, setCropperData] = useState<{ croppedAreaPixels: Area, rotate: number }>()

  useEffect(() => {
    setComponentState({
      imagesState: valuesToModify,
      currentModifyingIndex: 0,
    });
  }, [valuesToModify]);

  const next = (values: Picture) => {
    const nextImagesState = [...imagesState];
    nextImagesState[currentModifyingIndex] = {
      ...values,
    };
    setComponentState({
      imagesState: nextImagesState,
      currentModifyingIndex: currentModifyingIndex + 1,
    });
  };

  const pictureUploadTask = useAsyncTask(PictureModel.upload);
  const videoUploadingTask = useAsyncTask(AppModel.postSingleAsset);
  const status = useMemo(
    () => checkTypeAndUploadStatus(imagesState[currentModifyingIndex]),
    [(imagesState[currentModifyingIndex] as Picture)?.url, (imagesState[currentModifyingIndex] as TFile)?.base64, currentModifyingIndex]
  );

  useEffect(() => {
    if (
      cropper &&
      status.uploaded &&
      status.type === 'image' &&
      imagesState[currentModifyingIndex] &&
      (imagesState[currentModifyingIndex] as Picture).cropData
    ) {
      cropper.setCropBoxData((imagesState[currentModifyingIndex] as Picture).cropData as Cropper.Data);
    }

    return () => {};
  }, [cropper, status.uploaded, status.type, currentModifyingIndex, imagesState[currentModifyingIndex]]);

  const currentImage = useMemo(() =>
    status.uploaded
      ? (imagesState[currentModifyingIndex] as Picture)?.url || ''
      : ((imagesState[currentModifyingIndex] as TFile)?.base64 as string),
  [status.uploaded, imagesState]);

  const handleSubmit = async (action: SubmitActions) => {
    setComponentState({ ...componentState, lastAction: action });
    if (!formRef.current) return;
    let { values } = formRef.current;
    if (status.type === 'image' && cropperData) {
      const image = status.uploaded
        ? (imagesState[currentModifyingIndex] as Picture)?.url || ''
        : ((imagesState[currentModifyingIndex] as TFile)?.base64 as string)
      const croppedImageData = await getCroppedImg(image, cropperData?.croppedAreaPixels, cropperData?.rotate)
      // if (cropper)
        values = {
          ...(!status.uploaded
            ? await pictureUploadTask.run({
                ...values,
                name: 'new.jpg',
                // base64: (imagesState[currentModifyingIndex] as FileWithAdditionalFields).base64 as string,
                base64: croppedImageData ?? '',
              })
            : (imagesState[currentModifyingIndex] as Picture)),
          source: values?.source,
          caption: values?.caption,
          // cropData: cropper.getData(),
        };
      // else console.error('cropper not initialized');
    } else if (status.type === 'video') {
      values = {
        ...(!status.uploaded
          ? (await videoUploadingTask.run(imagesState[currentModifyingIndex]))?.[0]
          : (imagesState?.[currentModifyingIndex] as TAsset)),
        source: values?.source,
        caption: values?.caption,
      };
    }
    switch (action) {
      case 'next':
        next(values as Picture);
        return;
      case 'prev':
        prev(values as Picture);
        return;
      case 'submit':
      default: {
        const nextImagesState = [...imagesState];
        nextImagesState[currentModifyingIndex] = {
          ...values,
        };
        submit(nextImagesState as Picture[]); // Instead of setting state and then passing the new state, we pass the next state because setting the next state and passing the new state cannot happen in the same function.
        close();
      }
    }
  };
  const prev = (values: Picture) => {
    const nextImagesState = [...imagesState];
    nextImagesState[currentModifyingIndex] = {
      ...values,
    };
    setComponentState({
      imagesState: nextImagesState,
      currentModifyingIndex: currentModifyingIndex - 1,
    });
  };

  // const onCropCompleted = (base64: string) => {
  //   setCroppedImageBase64(base64);
  // };
  // if (!open) return null;

  const cropperFormComponent = useMemo(
    () => (
      <Formik
        onSubmit={() => {}}
        initialValues={imagesState[currentModifyingIndex]}
        innerRef={formRef}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          <>
            <Box display="flex" flexDirection="column" className={classes.showScrollbars}>
              <Box textAlign="center">
                {status.type === 'image' ? (
                  // <Cropper
                  //   {...cropConfig}
                  //   center
                  //   src={
                  //     status.uploaded
                  //       ? (imagesState[currentModifyingIndex] as Picture)?.url
                  //       : ((imagesState[currentModifyingIndex] as TFile)?.base64 as string)
                  //   }
                  //   viewMode={1}
                  //   checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                  //   onInitialized={(instance: Cropper) => {
                  //     setCropper(instance);
                  //   }}
                  // />
                  <ImageCropper
                    base64={currentImage}
                    cropConfig={cropConfig}
                    cropperOnly
                    open
                    onClose={() => {}}
                    onComplete={() => {}}
                    onCropComplete={(croppedAreaPixels, rotate) => setCropperData({ croppedAreaPixels, rotate })}
                  />
                ) : null}
              </Box>
              <Box mt={3} className={classes.fieldContent}>
                <MLFormContent
                  schema={getSchema(formikProps)}
                  formikProps={formikProps}
                  formId="dialog-form"
                  settings={{ verticalSpacing: 0, horizontalSpacing: 20 }}
                />
              </Box>
            </Box>
          </>
        )}
      </Formik>
    ),
    [imagesState, currentModifyingIndex, formRef, validationSchema, status, pictureUploadTask, videoUploadingTask, classes]
  );

  return (
    <BasicActionDialog
      open={open}
      onClose={close}
      title={`${title} ${currentModifyingIndex + 1}/${imagesState.length}`}
      subtitle="Zoom image to resize. Additional details will be displayed in the image gallery."
      additionalComponent={cropperFormComponent}
      cancelBtnText={
        currentModifyingIndex !== 0 ? (
          (pictureUploadTask.status === 'PROCESSING' || videoUploadingTask.status === 'PROCESSING') && lastAction === 'prev' ? (
            <CircularProgress />
          ) : (
            'PREVIOUS'
          )
        ) : undefined
      }
      onCancel={currentModifyingIndex !== 0 ? () => handleSubmit('prev') : close}
      cancelBtnprops={{ disabled: pictureUploadTask.status === 'PROCESSING' || videoUploadingTask.status === 'PROCESSING' }}
      doneBtnText={
        (pictureUploadTask.status === 'PROCESSING' || videoUploadingTask.status === 'PROCESSING') && !!lastAction && lastAction !== 'prev' ? (
          <CircularProgress />
        ) : currentModifyingIndex + 1 < imagesState.length ? (
          'NEXT'
        ) : undefined
      }
      onDone={() => handleSubmit(currentModifyingIndex + 1 < imagesState.length ? 'next' : 'submit')}
      doneBtnprops={{
        disabled: pictureUploadTask.status === 'PROCESSING' || videoUploadingTask.status === 'PROCESSING' || !formRef.current?.isValid,
      }}
      dialogMaxWidth={650}
    />
  );
}

export default DialogForm;

const styles = makeStyles((theme) => ({
  img: {
    borderRadius: 5,
    height: 100,
    maxWidth: 185,
  },
  subtitle: {
    paddingBottom: 10,
    paddingLeft: 40,
  },
  button: {
    padding: theme.spacing(0, '30px'),
  },
  fieldContent: {
    paddingTop: 15,
  },
  showScrollbars: {
    msOverflowStyle: 'unset',
    scrollbarWidth: 'unset' /* Firefox */,
  },
}));

const getSchema = (formikProps: FormikProps<Picture | FileWithAdditionalFields>) => {
  if (!formikProps) return [];

  // if (!formikProps?.values?.caption) formikProps.initialValues.caption = '';
  if (formikProps?.initialValues) {
    if (!formikProps.initialValues.caption) formikProps.initialValues.caption = '';
    if (!formikProps.initialValues.source) formikProps.initialValues.source = '';
  }
  // if (!formikProps?.values?.source) formikProps.initialValues.source = '';

  return [
    {
      type: 'text',
      valueKey: 'source',
      fieldProps: {
        variant: 'outlined',
        label: 'Copyright + Source',
        fullWidth: true,
        placeholder: 'Photograph (C) Artist. Courtesy of Creator.',
      },
    },
    // {
    //   type: 'text',
    //   valueKey: 'caption',
    //   fieldProps: {
    //     variant: 'outlined',
    //     label: 'Description',
    //     helperText: `${captionCharactersLeft > 0 ? captionCharactersLeft : 0
    //       } character${captionCharactersLeft !== 1 ? 's' : ''} left`,
    //     FormHelperTextProps: {
    //       className: classes.helperText,
    //     },
    //     multiline: true,
    //     rows: 4,
    //     fullWidth: true,
    //     placeholder: 'Photograph (C) Artist. Courtesy of Creator.',
    //   },
    // },
  ];
};

const validationSchema = Yup.object({
  caption: Yup.string().max(120, 'Description must be a maximum of 120 characters long'),
});

const checkTypeAndUploadStatus = (item: FileWithAdditionalFields | Picture): TypeAndUploadStatus => {
  const result: TypeAndUploadStatus = {
    type: 'image',
    uploaded: false,
  };
  if ((item as FileWithAdditionalFields)?.type?.startsWith('video') || (item as TAsset)?.mimetype?.startsWith('video')) {
    result.type = 'video';
  }
  if ((item as Picture | TAsset)?.url) {
    result.uploaded = true;
  }

  return result;
};
type TypeAndUploadStatus = { type: 'image' | 'video'; uploaded: boolean };
