import React, { FC } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

const Wallet: FC<SVGCIconProps> = (props) => {
  const { color = 'black', height = 15, width = 16 } = props;
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.8571 2.61224H1.79592C1.57942 2.61224 1.37178 2.52624 1.21869 2.37315C1.0656 2.22006 0.979592 2.01242 0.979592 1.79592C0.979592 1.57942 1.0656 1.37178 1.21869 1.21869C1.37178 1.0656 1.57942 0.979592 1.79592 0.979592H12.898C13.0279 0.979592 13.1524 0.927989 13.2443 0.836134C13.3362 0.744279 13.3878 0.619698 13.3878 0.489796C13.3878 0.359894 13.3362 0.235312 13.2443 0.143458C13.1524 0.0516033 13.0279 0 12.898 0H1.79592C1.32027 0.00214742 0.86472 0.192049 0.528385 0.528385C0.192049 0.86472 0.00214742 1.32027 0 1.79592V12.2449C0.00214742 12.7205 0.192049 13.1761 0.528385 13.5124C0.86472 13.8488 1.32027 14.0387 1.79592 14.0408H14.8571C15.1602 14.0408 15.4509 13.9204 15.6653 13.7061C15.8796 13.4918 16 13.2011 16 12.898V3.7551C16 3.452 15.8796 3.16131 15.6653 2.94698C15.4509 2.73265 15.1602 2.61224 14.8571 2.61224ZM15.0204 12.898C15.0204 12.9413 15.0032 12.9828 14.9726 13.0134C14.942 13.044 14.9004 13.0612 14.8571 13.0612H1.79592C1.57942 13.0612 1.37178 12.9752 1.21869 12.8221C1.0656 12.669 0.979592 12.4614 0.979592 12.2449V3.39592C1.23272 3.52393 1.51226 3.59102 1.79592 3.59184H14.8571C14.9004 3.59184 14.942 3.60904 14.9726 3.63966C15.0032 3.67027 15.0204 3.7118 15.0204 3.7551V12.898ZM12.7347 8.32653C12.7347 8.48798 12.6868 8.64581 12.5971 8.78006C12.5074 8.9143 12.3799 9.01893 12.2308 9.08072C12.0816 9.1425 11.9175 9.15867 11.7591 9.12717C11.6008 9.09567 11.4553 9.01793 11.3411 8.90376C11.227 8.7896 11.1492 8.64414 11.1177 8.48579C11.0862 8.32744 11.1024 8.1633 11.1642 8.01414C11.226 7.86497 11.3306 7.73748 11.4648 7.64778C11.5991 7.55808 11.7569 7.5102 11.9184 7.5102C12.1349 7.5102 12.3425 7.59621 12.4956 7.7493C12.6487 7.90239 12.7347 8.11003 12.7347 8.32653Z"
        fill={color}
      />
    </svg>
  );
};

export default Wallet;
