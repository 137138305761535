import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { TDiscussion } from 'Models/Discussion/@types';
import { FormControlLabel, Switch } from '@material-ui/core';
import Typo from 'Components/Typo';
import { useStoreActions } from 'Stores';
import DiscussionModel from 'Models/Discussion';
import useConfirmationDialog from 'Hooks/useConfirmationDialog';
import { TDiscussionConfig } from './useDiscussion';
import PgTypo from 'Components/PgTypo';
import { COLOR } from 'Theme/themeConstants';

export interface ChatActiveFlagProps {
  discussion: TDiscussion;
  config: TDiscussionConfig;
}
const DropConversationInactiveDialogCopy = {
  // body: 'This conversation will be hidden from your community members. You can still view and take action from within the conversation, or reactivate it at any time.',
  body: 'This conversation will be hidden from your community members. You can reactivate it at any time.',
  message: 'Make conversation inactive',
  agreeText: 'CONFIRM',
};
const DropConversationAactiveDialogCopy = {
  body: 'This conversation will be visible to the public.',
  message: 'Make active',
  agreeText: 'CONFIRM',
};
const ConversationInactiveDialogCopy = {
  // body: 'This conversation will be hidden from your community members. You can still view and take action from within the conversation, or reactivate it at any time.',
  body: 'This conversation will be hidden from your community members. You can reactivate it at any time.',
  message: 'Make conversation inactive',
  agreeText: 'CONFIRM',
};
const ConversationAactiveDialogCopy = {
  body: 'This conversation will be visible to your community members.',
  message: 'Make active',
  agreeText: 'CONFIRM',
};
const ChatActiveFlag: FC<ChatActiveFlagProps> = ({ discussion, config }) => {
  const classes = useStyles();
  const isChatEnabled = !discussion.isDisabled;
  const { updateDiscussions } = useStoreActions(({ DiscussionStore: { updateDiscussions } }) => ({ updateDiscussions }));
  const withConfirm = useConfirmationDialog();
  const updateDiscussion = async () => {
    try {
      withConfirm(
        async () => {
          await DiscussionModel.updateDiscussion(config.subjectId, config.subject, {
            isDisabled: !discussion.isDisabled,
            id: config.activeDiscussionId,
          });
          updateDiscussions({ discussion: { ...discussion, isDisabled: !discussion.isDisabled }, action: 'PUT' });
        },
        // eslint-disable-next-line no-nested-ternary
        isChatEnabled
          ? config.subject === 'Community'
            ? ConversationInactiveDialogCopy
            : DropConversationInactiveDialogCopy
          : config.subject === 'Community'
          ? ConversationAactiveDialogCopy
          : DropConversationAactiveDialogCopy,
      );
    } catch (err) {
      /** */
    }
  };
  const onChange = () => {
    updateDiscussion();
  };
  const labelText = isChatEnabled ? 'Chat is active' : 'Conversation inactive';
  return (
    <FormControlLabel
      className={classes.formControl}
      control={<Switch checked={isChatEnabled} onChange={onChange} name="isChatEnabled" />}
      label={
        <PgTypo b2 color="primary">
          {labelText}
        </PgTypo>
      }
    />
  );
};

const useStyles = makeStyles<Theme>(() => {
  return createStyles({
    formControl: {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
      marginLeft: 0,
    },
  });
});

export default ChatActiveFlag;
