import { Collapse, IconButton, ListItem, Tooltip, useTheme } from '@material-ui/core';
import { createStyles, makeStyles, Theme, alpha } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React, { FC, useMemo, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'Stores';
import hash from 'Assets/icons/hash.svg';
import { TDiscussion } from 'Models/Discussion/@types';
import useToastMessage from 'Hooks/useToastMessage';
import DiscussionModel from 'Models/Discussion';
import PgIcon from 'Components/PgIcon';
import { initialGenerator } from 'Constants/initialGenerator';
import useSocketListener from 'Hooks/useSocketListener';
import CreateDiscussionDialog from './CreateDiscussionDialog';
import { APP_ROUTES } from 'Routes';
import { DASHBOARD_ROUTES } from 'Routes/DashboardRoutes/AppNavigation';
import PgTypo from 'Components/PgTypo';
import CONVERSATION_ICON from 'Assets/icons/Chat.svg';
import { createStream } from '@livepeer/react';
import SVGCIcon from 'Components/SVGComponents/SVGCIcon';

export interface SidebarDiscussionMenuProps {}

const SidebarDiscussionMenu: FC<SidebarDiscussionMenuProps> = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const activeDiscussionId = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get('discussionId') || '';
  }, [search]);
  const [show, setShow] = useState(false);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const { setCommunity } = useStoreActions(({ CommunityStore: { setCommunity } }) => ({ setCommunity }));
  const { appUser, open, community } = useStoreState(({ AuthStore: { appUser }, DrawerSidebarStore: { open }, CommunityStore: { community } }) => ({
    appUser,
    open,
    community,
  }));
  const items = community?.discussions || [];
  const isMyCommunity = useMemo(() => appUser?.id === community?.userId, [appUser?.id, community?.id]);
  const onToggle = () => {
    setShow((e) => !e);
  };
  const toggleShowCreateDialog = () => setShowCreateDialog((e) => !e);
  const history = useHistory();
  const handleConversationClick = (id: string) => {
    if (community?.userId === appUser?.id)
      history.push(generatePath(`${DASHBOARD_ROUTES.CONVERSATIONS.path}?discussionId=${id}`, { communityId: community?.slug }));
    else history.push(generatePath(`${APP_ROUTES.COMMUNITY_CONVERSATIONS.path}?discussionId=${id}`, { communityId: community?.slug }));
  };
  const joinedDiscussions = items.filter((i) => isMyCommunity || (appUser?.id && i.memberIds.includes(appUser?.id || '')));
  const notJoinedDiscussions = isMyCommunity ? [] : items.filter((i) => !appUser?.id || (!i.memberIds.includes(appUser?.id || '') && !i.isDisabled));

  const theme = useTheme();

  const discussionListMap = (items: TDiscussion[], title: string, isJoined = false) => {
    if (items.length === 0) return <div />;
    return (
      <>
        {/* {isMyCommunity ? null : (
          <div className={classes.discussionSectionTitle}>
            <PgTypo b4 underline>
              {title}
            </PgTypo>
          </div>
        )} */}
        {items.map((item, i) => {
          if (!isMyCommunity && item.isDisabled) return null;
          return (
            <Tooltip key={item.id} title={item.title} placement="right" arrow>
              <ListItem onClick={() => handleConversationClick(item.id)}  className={clsx(classes.listItem)} button>
                <SVGCIcon
                  icon={item.discussionType === 'live-stream' ? 'icon-stream' : item.discussionType === 'video' ? 'icon-mic' : 'icon-hash'}
                  htmlColor={isJoined ? theme.palette.primary.main : alpha(theme.palette.primary.main, 0.4)}
                  style={{ marginRight: 8, marginLeft: item.discussionType === 'video' ? -4 : undefined }}
                />
                <PgTypo
                  c3={item.id === activeDiscussionId}
                  b4={item.id !== activeDiscussionId}
                  style={{ color: isJoined ? undefined : alpha(theme.palette.primary.main, 0.4) }}
                  className={classes.listItemText}
                >
                  {item.title}
                </PgTypo>
                {item.unreadMessageCount ? <div className={classes.redDot} /> : null}
              </ListItem>
            </Tooltip>
          );
        })}
      </>
    );
  };
  const withToast = useToastMessage();
  const { socket, SOCKET_EVENT_KEYS } = useSocketListener();
  const onSubmit = async (data: Partial<TDiscussion>) => {
    if (!community || !appUser) return;
    try {
      withToast(
        async () => {
          let discussionData = data;
          if (data.discussionType === 'live-stream' && !data.stream) {
            const streamData = await createStream({ name: data.title ?? '' });
            discussionData = { ...discussionData, stream: streamData };
          } else if (data.discussionType !== 'live-stream' && data.stream) {
            await DiscussionModel.deleteDiscussion(data.stream.id).catch(console.error)
            delete discussionData.stream;
          }
          const newDiscussion = await DiscussionModel.createDiscussion(discussionData, community.id);
          if (newDiscussion)
            setCommunity({
              ...community,
              discussions: [...(community.discussions || []), newDiscussion],
              discussionIds: [...(community.discussionIds || []), newDiscussion.id],
            });
          socket.emit(SOCKET_EVENT_KEYS.JOIN_COMMUNITY, {
            communityId: community.id,
          });
          toggleShowCreateDialog();
          history.push(
            generatePath(DASHBOARD_ROUTES.CONVERSATIONS.path + `/?discussionId=${newDiscussion.id}`, {
              communityId: community.slug ?? community.id ?? '#',
            }),
          );
        },
        {
          successToastMessage: `#${data.title} created successfully `,
        },
      );
    } catch (er) {
      // er
    }
  };
  if (open)
    return (
      <div className={clsx(classes.root, { [classes.itemActive]: !!activeDiscussionId })}>
        <div className={clsx(classes.titleContainer, 'conversations')} onClick={onToggle}>
          <img alt="conversation" src={CONVERSATION_ICON} className={classes.conversationIcon} />
          <PgTypo b4>Conversations</PgTypo>
          <IconButton size="small" className={clsx(classes.collapseIcon)} style={{ transform: show ? 'rotateX(180deg)' : 'rotateX(0deg)' }}>
            <ExpandMoreIcon className={classes.expandIcon} />
          </IconButton>
        </div>
        <Collapse in={show}>
          <div className={classes.itemsContainer}>
            {isMyCommunity ? (
              <ListItem className={clsx(classes.listItem)} button onClick={toggleShowCreateDialog}>
                <PgTypo c3 underline>
                  Create new conversation
                </PgTypo>
              </ListItem>
            ) : null}
            {discussionListMap(joinedDiscussions, 'Joined', true)}
            {discussionListMap(notJoinedDiscussions, 'Not Joined')}
          </div>
        </Collapse>

        {showCreateDialog ? <CreateDiscussionDialog onSubmit={onSubmit} open={showCreateDialog} onClose={toggleShowCreateDialog} /> : null}
      </div>
    );
  const withToolTip = (child: JSX.Element, title: string) => (
    <Tooltip title={title} placement="right" arrow>
      {child}
    </Tooltip>
  );
  return (
    <div className={clsx(classes.root, { [classes.itemActive]: !!activeDiscussionId })}>
      {withToolTip(
        <div className={clsx(classes.titleContainer, classes.collapsed)} onClick={onToggle}>
          <img alt="conversation" src={CONVERSATION_ICON} className={clsx(classes.conversationIcon, classes.collapsedHash)} />
        </div>,
        'Conversations',
      )}
      <Collapse in={show}>
        <div>
          {isMyCommunity && (
            <Tooltip title="Create new conversation" placement="right" arrow>
              <ListItem className={clsx(classes.listItem, classes.collapsed)} button onClick={toggleShowCreateDialog}>
                <PgIcon icon="icon-add" />
              </ListItem>
            </Tooltip>
          )}
          {items.map((item) => {
            if (!isMyCommunity && item.isDisabled) return null;
            return (
              <Tooltip key={item.id} title={item.title} placement="right" arrow>
                <ListItem onClick={() => handleConversationClick(item.id)} className={clsx(classes.listItem, classes.collapsed)} button>
                  <PgTypo c3={item.id === activeDiscussionId} b4={item.id !== activeDiscussionId}>
                    #&nbsp;{initialGenerator(item.title)}
                  </PgTypo>
                </ListItem>
              </Tooltip>
            );
          })}
        </div>
      </Collapse>
      {withToolTip(
        <ListItem
          className={clsx(classes.listItem, classes.collapsed, classes.collapseIcon)}
          style={{ transform: show ? 'rotateX(180deg)' : 'rotateX(0deg)' }}
          button
          onClick={onToggle}
        >
          <ExpandMoreIcon className={classes.expandIcon} />
        </ListItem>,
        show ? 'See less' : 'See more',
      )}

      {showCreateDialog ? <CreateDiscussionDialog onSubmit={onSubmit} open={showCreateDialog} onClose={toggleShowCreateDialog} /> : null}
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    root: {
      width: '100%',
    },
    hashImg: {
      height: 24,
      width: 24,
      marginRight: 10,
      filter: theme.palette.type === 'dark' ? 'invert(100%)' : undefined,
    },
    chatIcon: { filter: theme.palette.type === 'dark' ? 'invert(100%)' : undefined },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '9px 16px',

      '& p': {
        lineHeight: '15px',
        flex: 1,
      },

      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    itemsContainer: {
      marginLeft: 16,
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: theme.spacing(1.4, 2),
      '& span': {
        lineHeight: 1.3,
      },
      '& p': {
        overflow: 'hidden',
      },
    },
    listItemText: {
      display: 'inline-block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
    },
    border: {
      // borderBottom: `1px Dashed ${grey['600']}`
    },
    checkbox: {
      marginLeft: -3,
    },
    collapseIcon: {
      transition: '300ms ease-in-out',
    },
    redDot: {
      backgroundColor: theme.palette.error.main,
      height: 8,
      width: 8,
      marginLeft: 8,
      borderRadius: '50%',
      flexShrink: 0,
    },
    avatar: {
      background: theme.palette.background.paper,
      color: theme.palette.primary.main,
      width: 24,
      height: 24,
    },
    collapsed: {
      paddingRight: 0,
      paddingLeft: 0,
      justifyContent: 'center',
      padding: theme.spacing(1, 0),
    },
    collapsedHash: {
      marginRight: 0,
    },
    itemActive: {
      backgroundColor: alpha(theme.palette.common.black, 0.04),
    },
    expandIcon: { color: theme.palette.primary.main },
    conversationIcon: { width: 24, marginRight: 10 },
  });
});

export default SidebarDiscussionMenu;
