import React, { FC, useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Slide } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import EmailInvite from './sections/EmailInvite';
import InviteLink from './sections/InviteLink';
import TabNav from './sections/TabNav';
import ImportCsv from './sections/ImportCsv';
import { InvitationTypes } from 'Models/User';
import helpers from 'Utils/helpers';

export interface InvitationDialogProps {
  open: boolean;
  onClose: () => void;
  inviteLink: string;
  sendInvites: (emails: string[], autoVerify?: boolean) => Promise<void>;
  socialShareText?: string;
  variant?: 'share' | 'invite';
  isInviteLinkLoading?: boolean;
  onUploadCsv?: (data: any, autoVerify?: boolean) => void;
  inviteTo: InvitationTypes;
  isMyComm?: boolean;
}

const InvitationDialog: FC<InvitationDialogProps> = (props) => {
  const classes = useStyles();
  const {
    open,
    onClose,
    inviteLink,
    sendInvites,
    socialShareText = '',
    variant = 'invite',
    isInviteLinkLoading,
    onUploadCsv,
    inviteTo,
    isMyComm = false,
  } = props;

  const [currTab, setCurrTab] = useState(0);

  useEffect(() => setCurrTab(0), []);

  return (
    <Dialog maxWidth={false} open={open} onClose={onClose} PaperProps={{ className: classes.root }}>
      <DialogTitle id="simple-dialog-title" disableTypography>
        <PgTypo variant="h5" typoWeight="fontWeightBold" align="center">
          Share the love
        </PgTypo>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <PgIcon icon="icon-close" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TabNav currentTab={currTab} onTabChange={setCurrTab} hiddenTabIds={inviteTo === 'community' && isMyComm ? undefined : [2]} />
        <Slide in={currTab === 0} direction="right" mountOnEnter unmountOnExit>
          <Box py={2}>
            <InviteLink socialShareText={socialShareText} inviteLink={inviteLink} isInviteLinkLoading={isInviteLinkLoading} />
          </Box>
        </Slide>
        <Slide in={currTab === 1} direction="right" mountOnEnter unmountOnExit>
          <Box py={2}>
            <EmailInvite variant={variant} sendInvites={sendInvites} isMyComm={isMyComm} />
          </Box>
        </Slide>
        <Slide in={currTab === 2} direction="right" mountOnEnter unmountOnExit>
          <Box py={2}>
            <ImportCsv onUploadCsv={onUploadCsv} />
          </Box>
        </Slide>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    root: {
      width: 650,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      position: 'relative',
      margin: theme.spacing(3),
    },
    closeButton: {
      position: 'absolute',
      top: 8,
      right: 12,
    },
    textField: {
      // background: '#F8F8F8',
      // border: 'none',
      display: 'flex !important',
      flexWrap: 'wrap',
    },
    chip: {
      borderRadius: 4,
      background: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    inviteTextField: {
      padding: `4px 14px`,
    },
    inputField: {
      width: 0,
      flex: '1 1 0%',
      height: 32,
      margin: theme.spacing(1, 0),
      padding: 0,
    },
    inputContainers: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      marginTop: theme.spacing(2),
    },
    actionDiv: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    rowheight: {
      height: 56,
    },
  });
});

export default InvitationDialog;
