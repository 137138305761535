import React, { FC } from 'react';
import { Box, Tooltip } from '@material-ui/core';
import CommunityModel from 'Models/Community';
import helpers from 'Utils/helpers';
import { makeStyles, Theme } from '@material-ui/core/styles';
import PgTypo from 'Components/PgTypo';
import { TCommunity } from 'Models/Community/@types';
import PgButton from 'Components/PgButton';
import UserAvatar from 'Components/UserAvatar';
import { useStoreState } from 'Stores';
import { generatePath, useHistory } from 'react-router';
import { IEventPageProps } from './@types';
import { CommunityJoinButton } from 'Features/Profile/CommunityJoinButton';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from 'Routes';
import { TEvent } from 'Models/Event';

const CreatorInfo: FC<IEventPageProps & { setEventDetail?: (event: TEvent) => void, isMyDrop?: boolean }> = ({ event, setEventDetail, isMyDrop = false }) => {
  const avatar = CommunityModel.getAvatarImage(event.community as TCommunity);
  const name = CommunityModel.getName(event.community as TCommunity);
  // const { joinCommunity, leaveCommunity } = useUserActions();
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
  const classes = useStyles();

  const collaboratorsImgs = event.collaborators
    ?.map((collab) => ({ imgUrl: helpers.getPictureUrl(collab._profileImages?.[0]), slug: collab.slug, title: collab.name || '' }))
    .filter((i) => !!i.imgUrl);

  const history = useHistory();
  const isBanned = appUser?.id && event.community?.bannedUserIds.includes(appUser.id);

  // const handleCommunity = async () => {
  //   if (event.community?.isFollowed) {
  //     await leaveCommunity(event.community.followId as string, event.community.name as string);
  //     event.community.isFollowed = false;
  //   } else {
  //     const res: TUserFollows<User> | undefined = await joinCommunity(event.community.id, appUser?.id as string, event.community.name as string);
  //     if (res) {
  //       event.community.isFollowed = true;
  //       event.community.followId = res.id;
  //     }
  //   }
  // };

  // const communityRunner = useAsyncTask(handleCommunity);

  const handleFollowChange = (followId?: string) => {
    if (event.community?.isFollowed) {
      setEventDetail?.({ ...event, community: { ...event.community, isFollowed: false, followId: undefined } });
    } else {
      if (followId) {
        setEventDetail?.({ ...event, community: { ...event.community, isFollowed: true, followId } });
      }
    }
  };

  const showJoinToggleButton =
    (event?.community?.visibility !== 'INVITE_ONLY' || (event?.community?.visibility === 'INVITE_ONLY' && event.community?.isFollowed)) && !isMyDrop;

  const withLink = (child: React.ReactNode) => (
    <Link to={generatePath(APP_ROUTES.COMMUNITY_DETAIL.path, { communityId: event?.community?.slug ?? event?.community?.id ?? '#' })}>{child}</Link>
  );

  if (collaboratorsImgs.length) collaboratorsImgs?.unshift({
    imgUrl: helpers.getPictureUrl(event.community._profileImages?.[0]),
    slug: event?.community?.slug ?? event?.community?.id ?? '',
    title: event?.community?.name ?? '',
  })

  if (!event.community) return null;
  return (
    <Box display="flex">
      {collaboratorsImgs.length > 1 ? (
        <Box display={'flex'} alignItems="center" flexWrap={'wrap'} gridGap={10}>
          {collaboratorsImgs.map((collab) => {
            return (
              <Tooltip title={collab.title}>
                <div>
                  <PgButton quaternary href={generatePath(APP_ROUTES.COMMUNITY_DETAIL.path, { communityId: collab.slug })}>
                    <UserAvatar name={collab.title} size={'48px'} url={collab.imgUrl} key={collab.slug} />
                  </PgButton>
                </div>
              </Tooltip>
            );
          })}
        </Box>
      ) : (
        <>
          {withLink(<UserAvatar url={helpers.getPictureUrl(avatar, 200)} name={name} size={80} />)}
          <Box marginLeft={2.5}>
            {withLink(<PgTypo c2>{name}</PgTypo>)}
            {showJoinToggleButton && !isBanned ? (
              <Box marginTop={1.5}>
                <CommunityJoinButton
                  profile={event.community}
                  style={{ minWidth: 140 }}
                  onFollowChange={(subjectId, isFollowed, followId) => handleFollowChange(followId)}
                />
              </Box>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
};

export default CreatorInfo;

const useStyles = makeStyles<Theme>((theme) => ({
  img: {
    width: 48,
    height: 48,
    marginRight: 8,
    marginBottom: 12,
    cursor: 'pointer',
  },
}));
