import { lighten } from '@material-ui/core/styles';
import useAsyncTask from 'Hooks/useAsyncTask';
import useConfirmationDialog from 'Hooks/useConfirmationDialog';
import useSocketListener from 'Hooks/useSocketListener';
import get from 'lodash/get';
import DiscussionModel from 'Models/Discussion';
import { TDiscussionSubject, TMessage } from 'Models/Discussion/@types';
import { Picture } from 'Models/Picture/@types';
import { User } from 'Models/User/@types';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'Stores';
import { THEME_PALETTE } from 'Theme/themeConstants';
import helpers from 'Utils/helpers';

export interface TDiscussionConfig {
    subject: TDiscussionSubject;
    discussionIds: Array<string>;
    subjectId: string;
    creatorId?: string;
    activeDiscussionId: string;
    reverse?: boolean;
    disableChatConfig?:
    | {
        isDisabled: false;
    }
    | { isDisabled: true; disabledMessage: string };
}
export const MESSAGE_FETCH_LIMIT = 20;
export const REPLY_FETCH_LIMIT = 2;

export type JoinDiscussionResp = { isSuccess: boolean };
export type NewMessageResp = { message: TMessage; author: User; parentMessageId?: string };
export type DeleteMessageResp = { messageId: string; discussionId: string };
export type UserStatusUpdateResp = { userId: string; discussionId: string; type: TStatus; user: User };
export type TStatus = 'online' | 'offline';
export type TDiscussionSendMessageFn = (message: string, parentMessageId?: string, images?: Picture[]) => Promise<void>;

const useDiscussion = (config: TDiscussionConfig) => {
    const { socket, socketConnected, SOCKET_EVENT_KEYS } = useSocketListener();
    const [chatConnected, setChatConnected] = useState(false);
    const [hasGate, setHasGate] = useState(false)
    const { discussions, appUser, community } = useStoreState(
        ({ DiscussionStore: { discussions }, AuthStore: { appUser }, CommunityStore: { community } }) => ({ discussions, appUser, community }),
    );
    const communityDetails = community;
    const isAdmin = useMemo(() => appUser?.id === config.creatorId, [appUser?.id, config.creatorId]);
    const {
        fetchDiscussion,
        fetchMessages,
        appendMessage,
        fetchPinnedMessage,
        pinUnpinMessage,
        updateDiscussions,
        updateSingleMessage,
        updateUnreadCountInCommunityDiscussions,
        updateOnlineUsers,
    } = useStoreActions(
        ({
            DiscussionStore: {
                updateUnreadCountInCommunityDiscussions,
                fetchDiscussion,
                fetchMessages,
                appendMessage,
                fetchPinnedMessage,
                pinUnpinMessage,
                updateDiscussions,
                updateSingleMessage,
            },
            CommunityStore: { updateOnlineUsers },
        }) => ({
            updateUnreadCountInCommunityDiscussions,
            fetchDiscussion,
            fetchMessages,
            appendMessage,
            fetchPinnedMessage,
            updateDiscussions,
            pinUnpinMessage,
            updateSingleMessage,
            updateOnlineUsers,
        }),
    );
    const currentDiscussion = useMemo(() => discussions.find((i) => i.id === config.activeDiscussionId), [discussions, config.activeDiscussionId]);
    const history = useHistory();
    const isBanned = useMemo(() => !!(appUser?.id && currentDiscussion?.bannedUserIds?.includes(appUser?.id)), [appUser?.id, currentDiscussion?.bannedUserIds]);
    const getMessages = useAsyncTask(async (initialFetch: boolean) => {
        setHasGate(false);
        try {
            const currentLength = currentDiscussion?.messages?.length || 0;

            const res = await fetchMessages({
                config,
                filter: {
                    include: ['user', { relation: 'replies', scope: { include: 'user', limit: REPLY_FETCH_LIMIT } }],
                    order: 'created desc',
                    limit: MESSAGE_FETCH_LIMIT,
                    skip: initialFetch ? 0 : currentLength,
                },
            });
            return res;
        } catch (error) {
            console.error(error);
            const responseCode = get(error, 'response.data.error.responseCode');
            if (
                responseCode === 'PASSWORD_PROTECTED'
                || responseCode === 'USER_DOES_NOT_HAVE_ANY_NFT_OF_THE_REQUIRED_CONTRACT'
                || responseCode === 'USER_WALLET_NOT_CONNECTED'
            )
                setHasGate(true);
            else throw error;
        }
    });

    const handleBanEvent = (data: { discussionId: string }) => {
        if (currentDiscussion?.id === data.discussionId && appUser?.id)
            updateDiscussions({ discussion: { ...currentDiscussion, bannedUserIds: [...currentDiscussion.bannedUserIds, appUser.id] }, action: 'UPDATE' });
    };

    const getInitialMessages = async () => {
        getMessages
            .run(true)
            .then((msgs: Array<TMessage>) => {
                if (msgs?.length) {
                    const messageID = config.reverse ? msgs[msgs.length - 1].id : msgs[0].id;
                    DiscussionModel.markDiscussionRead(msgs[0].discussionId, messageID).then(() => {
                        if (config.subjectId === communityDetails?.id && config.subject === 'Community')
                            updateUnreadCountInCommunityDiscussions({ discussionId: config.activeDiscussionId, unreadMessageCount: 0 });
                    });
                }
            })
            .finally(() => {
                /**
                 * once connected , attach listeners.
                 */
                addListeners();
            });
    }

    const getChatData = useAsyncTask(async () => {
        try {
            await fetchDiscussion({ id: config.activeDiscussionId, subject: config.subject });
        } catch (err) {
            const erorr = get(err, 'response.data.error.code');
            if (erorr === 'MODEL_NOT_FOUND' && config.discussionIds[0])
                history.push(`${history.location.pathname}?discussionId=${config.discussionIds[0]}`);
        }
        try {
            fetchPinnedMessage({ config });
        } catch (e) {
            /** handle error */
        }
        getInitialMessages();

    });

    useEffect(() => {
        if (chatConnected) getChatData.run({});
    }, [chatConnected, config.activeDiscussionId]);

    const addListeners = () => {
        try {
            socket.removeListener(SOCKET_EVENT_KEYS.NEW_MESSAGE);
            socket.on(SOCKET_EVENT_KEYS.NEW_MESSAGE, (data: NewMessageResp) => {
                appendMessage({
                    message: {
                        ...data.message,
                        user: { ...data.author, id: data.message.userId },
                        parentMessageId: data.parentMessageId,
                        isNew: data.parentMessageId === undefined,
                    },
                    reverse: config.reverse,
                });
                if (config.activeDiscussionId === data.message.discussionId) {
                    DiscussionModel.markDiscussionRead(data.message.discussionId, data.message.id);
                } else if (config.subjectId === communityDetails?.id && config.subject === 'Community') {
                    updateUnreadCountInCommunityDiscussions({ discussionId: data.message.discussionId, unreadMessageCount: 1 });
                }

                if (!data.parentMessageId && data.message?.id) {
                    const msgElem = document.getElementById(data.message?.id);
                    if (msgElem && data.message.userId === appUser?.id) {
                        if (config.reverse) {
                            const elem = document.getElementsByClassName('infinite-scroll-component')?.[0];
                            if (elem) {
                                helpers.scrollTo(data.message?.id)
                            }
                        } else window.scrollTo({ top: msgElem.offsetTop - 115, behavior: 'smooth' });
                    }
                    if (msgElem) {
                        msgElem.style.background = lighten(THEME_PALETTE.secondary.main, 0.95);
                        setTimeout(() => {
                            msgElem.style.background = '#fff';
                        }, 3000);
                    }
                }
            });
            socket.removeListener(SOCKET_EVENT_KEYS.MESSAGE_DELETED);
            socket.on(SOCKET_EVENT_KEYS.MESSAGE_DELETED, (data: DeleteMessageResp) => {
                updateSingleMessage({ discussionId: data.discussionId, message: { id: data.messageId, isDeleted: true } });
            });
            socket.removeListener(SOCKET_EVENT_KEYS.MESSAGE_HIDDEN);
            socket.on(SOCKET_EVENT_KEYS.MESSAGE_HIDDEN, (data: DeleteMessageResp) =>
                updateSingleMessage({ discussionId: data.discussionId, message: { id: data.messageId, isHidden: true } }),
            );
            socket.removeListener(SOCKET_EVENT_KEYS.MESSAGE_UNHIDDEN);
            socket.on(SOCKET_EVENT_KEYS.MESSAGE_UNHIDDEN, (data: DeleteMessageResp) =>
                updateSingleMessage({ discussionId: data.discussionId, message: { id: data.messageId, isHidden: false } }),
            );

            socket.removeListener(SOCKET_EVENT_KEYS.USER_OFFLINE);
            socket.on(SOCKET_EVENT_KEYS.USER_OFFLINE, (data: UserStatusUpdateResp) => {
                updateOnlineUsers({ user: data.user, action: 'REMOVE' });
            });

            socket.removeListener(SOCKET_EVENT_KEYS.USER_ONLINE);
            socket.on(SOCKET_EVENT_KEYS.USER_ONLINE, (data: UserStatusUpdateResp) => {
                updateOnlineUsers({ user: data.user, action: 'ADD' });
            });

            socket.removeListener('userBan');
            socket.on('userBan', handleBanEvent);
        } catch (err) {
            /** */
        }
    };

    const onConnect = (data: JoinDiscussionResp) => {
        if (!data.isSuccess) return;
        setChatConnected(true);
    };

    const fetchReplies = async (message: TMessage, loadAll = false) => {
        const replyCount = message.repliesCount || 0;
        const currentReplyCount = message.replies?.length || 0;
        if (currentReplyCount < replyCount && currentDiscussion) {
            const newReplies = await DiscussionModel.getMessages(config.subject, config.subjectId, config.activeDiscussionId, {
                where: { parentMessageId: message.id },
                skip: currentReplyCount,
                order: 'created ASC',
                limit: loadAll ? replyCount - currentReplyCount : REPLY_FETCH_LIMIT,
                include: ['user'],
            });
            updateDiscussions({
                discussion: DiscussionModel.updateMessageInDiscussion(currentDiscussion, {
                    ...message,
                    replies: [...(message.replies || []), ...newReplies],
                }),
                action: 'UPDATE',
            });
        }
    };

    const sendMessage: TDiscussionSendMessageFn = async (message, parentMessageId, images) => {
        await DiscussionModel.postMessage(config.activeDiscussionId, config.subject, config.subjectId, {
            text: message,
            discussionId: config.activeDiscussionId,
            parentMessageId,
            images,
        });
    };

    const initDiscussion = useAsyncTask(async () => {
        /**
         * Initialize discussion and get messages for the current discussion
         */

        if (config.subject === 'Community') {
            socket.emit(
                SOCKET_EVENT_KEYS.JOIN_COMMUNITY,
                {
                    communityId: config.subjectId,
                },
                onConnect,
            );
        } else {
            socket.emit(
                SOCKET_EVENT_KEYS.JOIN_RESOURCE,
                {
                    resourceId: config.subjectId,
                    resourceType: config.subject,
                },
                onConnect,
            );
        }
    });
    useEffect(() => {
        /**
         * Call initiator
         */
        if (config.subjectId && socketConnected && appUser?.id) {
            initDiscussion.run({});
        }
    }, [config.subjectId, socketConnected, appUser?.id]);

    const withConfirm = useConfirmationDialog();
    const handlePinUnpinTask = useAsyncTask(async (message: TMessage) => {
        if (currentDiscussion?.pinnedMessage && !message.isPinned) {
            withConfirm(async () => pinUnpinMessage({ discussionId: config.activeDiscussionId, message }), {
                body: 'Pinning this comment to the top will replace the comment that is currently pinned.',
                message: 'Pin new comment',
                agreeText: 'CONFIRM',
            });
        } else if (currentDiscussion?.pinnedMessage?.id === message.id && message.isPinned) {
            withConfirm(async () => pinUnpinMessage({ discussionId: config.activeDiscussionId, message }), {
                body: 'Are you sure you want to unpin this comment?',
                message: 'Unpin comment',
                agreeText: 'CONFIRM',
            });
        } else await pinUnpinMessage({ discussionId: config.activeDiscussionId, message });
    });

    const handleUnlockedDiscussion = () => {
        getInitialMessages();
        setHasGate(false);
    };

    const isFetchingMessage = useMemo(() => getMessages.status === 'PROCESSING', [getMessages.status]);
    const loading = useMemo(
        () => initDiscussion.status === 'PROCESSING' || getChatData.status === 'PROCESSING',
        [initDiscussion.status, getChatData.status],
    );

    const hasLeftChat = useMemo(
        () => isAdmin ? false : !appUser?.id || (!currentDiscussion?.memberIds.includes(appUser?.id || '') && !currentDiscussion?.isDisabled && config.subject === 'Community'),
        [currentDiscussion?.memberIds, currentDiscussion?.isDisabled],
    );

    return {
        loading,
        sendMessage,
        currentDiscussion,
        isAdmin,
        handlePinUnpin: handlePinUnpinTask.run,
        fetchMessages: getMessages.run,
        isFetchingMessage,
        isBanned,
        fetchReplies,
        hasGate,
        handleUnlockedDiscussion,
        hasLeftChat,
    };
};
export default useDiscussion;
