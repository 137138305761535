/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import DateTimeUtils, { DEFAULT_DATE_FORMAT, DEFAULT_DATE_RANGE_FORMAT } from 'Resources/DateTimeUtils';
import { Tag } from 'Models/Tags/@types';
import { TEvent } from './@types';
import { TicketingTierType, TicketTier } from './ticketing/@types';
import { IDistancedDrops, PLATFORM_CONSTANTS } from './index';
import { TEventResourse } from '../Resource/@types';
import { TPosition } from 'Models/AppModels';
import GoogleUtils from 'Resources/google';
import DropUtils from 'Utils/DropUtils';
import { isEmpty } from 'lodash';

export const getEventDateTime = (eventDates: TEvent['eventDates'], timeZone = 'GMT', dateFormat = 'dddd MMM DD, YYYY', timeFormat = 'hh:mm A') => {
  const startDate = eventDates?.[0]?.startDate;
  const endDate = eventDates?.[0]?.endDate;
  if (!startDate || !endDate)
    return {
      startDate: undefined,
      endDate: undefined,
      startTime: undefined,
      endTime: undefined,
      timeZoneCode: timeZone,
    };
  const momentStartDate = moment.utc(startDate).tz(timeZone);
  const momentEndDate = moment.utc(endDate).tz(timeZone);
  return {
    startDate: momentStartDate.format(dateFormat),
    endDate: momentEndDate.format(dateFormat),
    startTime: momentStartDate.format(timeFormat),
    endTime: momentEndDate.format(timeFormat),
    timeZoneCode: timeZone,
  };
};

export const getEventDate = (eventDates: TEvent['eventDates'], timeZone = 'GMT', format?: string): string => {
  const date = eventDates?.[0]?.startDate;
  if (!date) return '';
  const momentDate = moment.utc(date).tz(timeZone);
  return momentDate.format(format ?? 'dddd MMM DD, YYYY');
};

export const getEventTime = (
  eventDates: TEvent['eventDates'],
  timeZone = 'GMT',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _isAppendTimezone = true,
): string => {
  const startDate = eventDates?.[0]?.startDate;
  const endDate = eventDates?.[0]?.endDate;
  if (!startDate || !endDate) return '';
  const momentStartDate = moment.utc(startDate).tz(timeZone);
  const momentEndDate = moment.utc(endDate).tz(timeZone);
  const timeStr = `${moment(momentStartDate).format('hh:mm A')} - ${moment(momentEndDate).format('hh:mm A')}`;
  return timeStr;
};

export const getEventCurrentDate = (event?: TEvent, isLocal?: boolean): TEvent['eventDates'][0] | undefined => {
  if (!event) return undefined;
  const timeZone = isLocal ? moment.tz.guess() : event.timeZone.name;
  const eventDates = event.eventDates ?? [];
  const today = DateTimeUtils.getDate(moment().utc().toISOString(), timeZone);
  let currentDate: TEvent['eventDates'][0] | undefined = eventDates.find((date) =>
    today.isSameOrBefore(DateTimeUtils.getDate(date.endDate, timeZone)),
  );
  // eslint-disable-next-line prefer-destructuring
  if (!currentDate) currentDate = eventDates[eventDates.length - 1];
  return currentDate;
};

export const getEventStartDate = (event?: TEvent): string => {
  /**
   * @author Adnan Husain
   * @description Returns the closest date , as per current time , from event-dates-array.
   */
  const len = event?.eventDates?.length || 0;
  const today = moment();
  if (!len) return '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    const startDate = event?.eventDates[i].startDate || '';
    const endDate = event?.eventDates[i].endDate || '';
    if (i === 0) {
      /**
       * (i === 0) check if event is yet to start.
       */
      if (today.isSameOrBefore(endDate)) return startDate;
    } else if (i === len - 1) {
      /**
       * (i === len - 1) check if event had ended.
       */
      return startDate;
    } else {
      /**
                Now if current time is between yesterdays endTime and todays endTime.
            */
      const yesterDayEndTime = event?.eventDates[i - 1].endDate || '';
      if (today.isSameOrBefore(endDate) && today.isSameOrAfter(yesterDayEndTime)) return startDate;
    }
  }
  /**
   * In worst case return last date by default.
   */
  return event?.eventDates[len - 1]?.startDate ?? '';
};

export const isEventSoldOut = (ticketTiers: TicketTier[]) => {
  if (ticketTiers.length === 0) return false;
  // return !!ticketTiers.filter((i) => typeof i.remainingTickets !== undefined && i.remainingTickets === 0).length;
  return ticketTiers.reduce((prev, curr) => prev && !!(typeof curr.remainingTickets !== 'undefined' && curr.remainingTickets === 0), true);
};

export const getEventLocation = (event: TEvent): string | undefined => {
  if (event.eventType === 'online' || event.eventType === 'metaverse') return PLATFORM_CONSTANTS[event.platform?.provider] ?? event.platformName;
  if (event.eventType === 'offline') return event.address?.city ?? event.address.fullAddress;
  return undefined;
};

export const getEventDateWithLocation = (event: TEvent, isLocal = true): string => {
  if (!event || !event.eventDates.length || isEmpty(event?.eventDates?.[0])) return '';
  const { startDate, endDate } = event.eventDates?.[0];
  const formattedDate = (date: string, format: string = DEFAULT_DATE_FORMAT) =>
    DateTimeUtils.formatDate(date, format, isLocal ? undefined : event.timeZone.name);
  let date;
  if (DropUtils.isSameDate(event.eventDates)) date = formattedDate(startDate);
  else date = `${formattedDate(startDate, DEFAULT_DATE_RANGE_FORMAT)} - ${formattedDate(endDate)}`;
  const location = getEventLocation(event);
  if (!location) return date ?? '';
  const joinWord = event.eventType === 'online' ? 'on' : 'in';
  return `${date} ${joinWord} ${location}`;
};

export const getEventEndTime = (event: TEvent, day: string) => {
  return event?.eventDates?.find((i) => i.startDate === day)?.endDate;
};

// TODO remove post refactor
export const { getFormattedDate } = DateTimeUtils;

export const isTicketEvent = (tierType: TicketingTierType): boolean => {
  return tierType === 'free' || tierType === 'single' || tierType === 'multi' || tierType === 'nft';
};

export const isEvent = (item: TEventResourse): item is TEvent => {
  return (item as TEvent).doc_type === 'Event';
};

export function getPrimaryTag(event: TEvent): Tag | undefined {
  const tagId = event.tagIds?.[0];
  return tagId ? event.tags?.find((t) => t.id === tagId) : undefined;
}

export function getEventLocationSuffix(event: TEvent) {
  let suffix = '';
  if (!event) return ''; // In some instances, the data returned from the back-end might not contain event (eg.: an event was deleted but is referenced by other entities). This line exists to prevent crashes in such cases
  if (event.type === 'online' && ((event.platform && event.platform.provider) || event.platformName)) {
    // suffix = ` on ${PLATFORM_CONSTANTS[event.platform?.provider ?? ''] ?? event.platformName ?? ''}`;
    suffix = PLATFORM_CONSTANTS[event.platform?.provider ?? '']
      ? ` on ${PLATFORM_CONSTANTS[event.platform?.provider ?? '']}`
      : event.platformName
      ? ` on ${event.platformName}`
      : ` Online`;
  } else if (event.type === 'offline' && (event.address?.city || event.address?.locality || event.address?.fullAddress)) {
    suffix = ` in ${event.address?.city ?? event.address?.locality ?? event.address.fullAddress}`;
  }
  return suffix;
}

export const getTicketInfo = (event: TEvent) => {
  const { tierType } = event;
  const tier = event._tiers?.find((t) => t.type === tierType);
  if (!tier) return null;
  const ticketTier = tier?._ticketTiers?.[0];
  if (!ticketTier) return null;
  return ticketTier;
};

export const getColloborators = (event: TEvent) => {
  const collaborators = [...(event.collaborators || [])];
  if (event.community) collaborators.unshift(event.community);
  return collaborators;
};

export const distanceWiseDrops = (location: TPosition, drops: TEvent[], distance = 50): IDistancedDrops => {
  let farDrops: TEvent[] = [];
  let nearDrops: TEvent[] = [];
  drops.forEach((drop) => {
    if (!drop.location?.lat || !drop.location?.lng) return;
    const distanceBetween = GoogleUtils.haversineDistance(location, { lat: drop.location.lat, lng: drop.location.lng });

    if (distanceBetween > distance) farDrops.push(drop);
    else nearDrops.push(drop);
  });

  return {
    in: nearDrops,
    out: farDrops,
  };
};

/*  static getFormattedDateResourceCard(
    date?: string,
    startTime?: string,
    tz?: string,
    offset = 0,
    variant: 'short' | 'full' | 'dateOnly' | 'monthDateOnly' | 'textDateOnly' = 'short'
  ) {
    if (!date) return '';
    let result = '';

    const dates = new Date();
    if (dates && !offset) {
      result = dates.toTimeString()?.match(new RegExp('[A-Z](?!.*[(])', 'g'))?.join('') || '';
    }

    if (variant === 'short') {
      const dateCard = moment(date)
        .tz(tz ?? 'America/New_York')
        .format('MMM DD,');
      const timeCard = `${dayjs(startTime).format(' hh:mm a')} ${result || ''}`;
      return dateCard + timeCard;
    }
  } */

export const EVENT_FIELDS: (keyof TEvent)[] = [
  'name',
  'id',
  'description',
  'tierType',
  '_tiers',
  'slug',
  'eventDates',
  '_primaryImage',
  'tagIds',
  'tags',
  'userId',
  'communityId',
  'frequency',
  'repeatUntil',
  'subtitle',
  'type',
  'platformName',
  'platformUrl',
  'address',
  'rsvpUrl',
  'featured',
  'platform',
  'isFavourited',
  'isReminded',
  'reminderId',
  'prices',
  'category',
  'when',
  'canceled',
  'isUserFeatured',
  'isPublished',
  'favouriteCount',
  'reminderCount',
  'dateChanged',
  'timeZone',
  'ticketingInfo',
  'collaboratingCommunityIds',
  'collaborators',
  'taxInfo',
  'location',
  'subType',
  'gateType',
  'nftGates',
  'ageRestriction',
  'featureToggle',
] as (keyof TEvent)[];
