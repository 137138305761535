import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import React from 'react';
import { useStoreState } from 'Stores';
import UserInterestForm from './UserInterestForm';

const Interests: React.FC = () => {
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({
    appUser,
  }));
  const classes = useStyles();
  const { isDeviceSm } = useMediaQuery();
  return (
    <div className={classes.root}>
      {/* {submitHandler.status === 'PROCESSING' ? <Loader overlayed /> : null} */}
      {/* <AccountSettingsTitle title="Interests" /> */}
      {/* <Typo gutterBottom variant="body2" className={classes.subtitle}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sit proin
        adipiscing purus odio purus urna. Eget porttitor lacus, convallis at
        morbi faucibus dui vitae.
      </Typo> */}

      {/* <Box mt={isDeviceSm ? 4 : 5} width={isDeviceSm ? '100%' : 320}> */}
      <Box mt={isDeviceSm ? 4 : 5} width="100%">
        <UserInterestForm
          initialData={appUser?.tagIds || []}
          // onSubmit={submitHandler.run}
        />
      </Box>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
      marginTop: '-50px',
    },
    subtitle: {
      color: theme.palette.grey.A200,
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2.5, 2.5, 0, 2.5),
      },
    },
  })
);

export default Interests;
