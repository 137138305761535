import Axios, { CancelTokenSource } from 'axios';
import { action, Action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';
import CommunityUserQuery, { LocationOption } from 'Features/Query/CommunityUserQuery';
import CommunityModel from 'Models/Community';
import { InviteLink, TCommunity } from 'Models/Community/@types';
import { Invitee, User, UserSearchAggregations, WithDocType } from 'Models/User/@types';
// eslint-disable-next-line import/no-cycle
import { TRootStore } from 'Stores';
import helpers from 'Utils/helpers';

const DEFAULT_FACETS = {
  attendees: { doc_count: 0 },
  city: { doc_count: 0 },
  subscribers: { doc_count: 0 },
  invitees: { doc_count: 0 },
  banned: { doc_count: 0 },
  favouriters: { doc_count: 0 },
  expiredInvites: { doc_count: 0 },
  unregistered_expired_invitees: { doc_count: 0 },
  unregistered_invitees: { doc_count: 0 },
};

export interface CommunityState {
  /**
   * This community instance will be consumed by community detail page and dashboard pages.
   */
  community: TCommunity | undefined;
  setCommunity: Action<CommunityState, TCommunity | undefined>;
  fetchMemberStatus: Thunk<CommunityState, string>;
  fetchFollowersCount: Thunk<CommunityState, string>;
  updateOnlineUsers: Action<CommunityState, { user: User; action: 'ADD' | 'REMOVE' }>;

  users: WithDocType<User | Invitee, 'user' | 'PartialReferral'>[];
  setUsers: Action<CommunityState, WithDocType<User | Invitee, 'user' | 'PartialReferral'>[]>;
  fetchCommunityUsers: Thunk<CommunityState, CommunityUserQuery, null, TRootStore>;

  facets: UserSearchAggregations;
  fetchFacets: Thunk<CommunityState, void, null, TRootStore>;
  setFacets: Action<CommunityState, UserSearchAggregations>;

  total: number;
  setTotal: Action<CommunityState, number>;

  allUsersCount: number;
  setAllUsersCount: Action<CommunityState, number>;
  initialFacets: UserSearchAggregations;
  setInitialFacets: Action<CommunityState, UserSearchAggregations>;
  fetchAllUsersCount: Thunk<CommunityState, void, null, TRootStore>;

  query: CommunityUserQuery;
  setQuery: Action<CommunityState, CommunityUserQuery>;
  onQueryChange: ThunkOn<CommunityState, Record<string, unknown>, TRootStore>;

  loading: boolean;
  setLoading: Action<CommunityState, boolean>;

  communityDetails: TCommunity | undefined;
  setCommunityDetails: Action<CommunityState, TCommunity>;
  getCommunityDetails: Thunk<CommunityState, { id: string }>;

  getCommunityOnLogin: ThunkOn<CommunityState, void, TRootStore>;
}

let cancelToken: CancelTokenSource | undefined;

const CommunityStore: CommunityState = {
  community: undefined,
  setCommunity: action((state, payload) => {
    state.community = payload;
  }),
  fetchMemberStatus: thunk(async (action, id, { getState }) => {
    const community = getState().community;
    if (id === community?.id) {
      const resp = await CommunityModel.getOnlineMembers(id);
      action.setCommunity({
        ...community,
        onlineUsers: resp.online,
        offlineUsers: resp.offline,
      });
    }
  }),
  fetchFollowersCount: thunk(async (action, id, { getState }) => {
    const community = getState().community;
    if (id === community?.id) {
      const counts = await CommunityModel.relatedEntitySearch(id, '', { limit: 1, userFilter: ['subscribers', 'attendees'] });
      action.setCommunity({
        ...community,
        followersCount: counts?.total || 0,
      });
    }
  }),
  updateOnlineUsers: action((state, { user, action }) => {
    if (!state.community) return;
    let { onlineUsers = [], offlineUsers = [] } = state.community;
    if (action === 'REMOVE') {
      onlineUsers = onlineUsers.filter((i) => i.id !== user.id);
      offlineUsers = helpers.updateItemList(offlineUsers, user, 'PUT');
    } else {
      offlineUsers = offlineUsers.filter((i) => i.id !== user.id);
      onlineUsers = helpers.updateItemList(onlineUsers, user, 'PUT');
    }
    state.community = { ...state.community, onlineUsers, offlineUsers };
  }),
  users: [],
  total: 0,
  facets: DEFAULT_FACETS,
  initialFacets: DEFAULT_FACETS,
  allUsersCount: 0,
  loading: false,
  query: new CommunityUserQuery({}, { page: 1 }),
  communityDetails: undefined,
  setInitialFacets: action((state, payload) => {
    state.initialFacets = payload;
  }),
  setCommunityDetails: action((state, payload) => {
    state.communityDetails = payload;
  }),
  setUsers: action((state, payload) => {
    state.users = payload;
  }),
  setFacets: action((state, payload) => {
    state.facets = payload;
  }),
  setTotal: action((state, payload) => {
    state.total = payload;
  }),
  setAllUsersCount: action((state, payload) => {
    state.allUsersCount = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setQuery: action((state, payload) => {
    state.query = payload;
  }),
  fetchAllUsersCount: thunk(async (actions, query, { getState }) => {
    const { community } = getState();
    const res = await CommunityModel.relatedEntitySearch(community?.id ?? '', '', { limit: 1 });
    if (!res) return;
    actions.setAllUsersCount(res.total);
    if (res.aggregations) actions.setInitialFacets(res.aggregations);
  }),
  fetchFacets: thunk(async (actions, _, { getState }) => {
    const { query, community } = getState();
    actions.setLoading(true);
    const res = await CommunityModel.relatedEntitySearch(community?.id ?? '', '');
    actions.setLoading(false);
    if (!res) return;
    if (res.aggregations) {
      actions.setFacets(res.aggregations);
      query.totalLocations = res.aggregations.city.buckets?.filter((bucket) => bucket.key !== LocationOption.unknown)?.length || 0;
    }
  }),
  fetchCommunityUsers: thunk(async (actions, query, { getState }) => {
    actions.setLoading(true);
    const { community } = getState();

    if (cancelToken) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
    cancelToken = Axios.CancelToken.source();

    const res = await CommunityModel.relatedEntitySearch(community?.id ?? '', '', { ...query.searchFilter }, { cancelToken: cancelToken.token });
    actions.setLoading(false);
    if (!res) return;
    actions.setTotal(res.total);
    actions.setUsers(res.results.map((r) => r.hit));
    if (res.aggregations) actions.setFacets(res.aggregations);
  }),
  getCommunityDetails: thunk(async (actions, { id }, { getState }) => {
    const { community } = getState();
    const data = await CommunityModel.getCommunityById(id);
    actions.setCommunityDetails(data);
    actions.setCommunity({ ...community, ...data })
    return { ...community, ...data };
  }),
  onQueryChange: thunkOn(
    (actions) => actions.setQuery,
    async (actions, { payload }) => {
      // await actions.fetchFacets();
      actions.fetchCommunityUsers(payload);
    },
  ),
  getCommunityOnLogin: thunkOn(
    (_a, { AuthStore: { login } }) => login,
    async (action, _, { getStoreState }) => {
      const comm = getStoreState().CommunityStore.community;
      if (comm) {
        const communityData = await CommunityModel.getCommunityById(comm.id);
        action.setCommunity({ ...comm, followId: communityData.followId, isFollowed: communityData.isFollowed, isBanned: communityData.isBanned });
        action.fetchMemberStatus(communityData.id);
      }
    },
  ),
};

export default CommunityStore;
