import React, { FC, memo, useState } from 'react';
import {
  Button,
  ButtonProps,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  IFieldProps,
  getMenuOptions,
  MenuOptions,
  MenuOptionObject,
  getFieldError,
} from 'react-forms';
import get from 'lodash/get';
import PgIcon from 'Components/PgIcon';
import Spacer from 'Components/Spacer';

export interface IToggleButtonProps extends IFieldProps {
  fieldProps?: {
    options?: MenuOptions<MenuOptionObject>;
    defaultButtonProps?: ButtonProps;
    formControlProps?: FormControlProps;
    formHelperTextProps?: FormHelperTextProps;
    helperText?: string;
  };
}

const defaultBtnProps: ButtonProps = {
  variant: 'outlined',
  color: 'secondary',
};
const selectedBtnProps: ButtonProps = {
  variant: 'contained',
  color: 'secondary',
};
const PromoBtn: FC<IToggleButtonProps> = (props) => {
  const [btnName, setBtnName] = useState('ADD A PROMO CODE');
  console.log('toggle button props', props);
  const { formikProps, fieldConfig, fieldProps = {} } = props;
  const {
    options = [],
    defaultButtonProps = defaultBtnProps,
    formControlProps,
    formHelperTextProps,
    helperText,
  } = fieldProps;
  console.log('PB', fieldProps);
  const classes = useStyles();
  const menuOptions = getMenuOptions(options);
  console.log('Menu options', menuOptions);
  const value = get(formikProps?.values, fieldConfig?.valueKey || ''); // formikProps?.values[(fieldConfig?.valueKey || '')];
  console.log('value', value);
  const fieldError = formikProps
    ? getFieldError(fieldConfig?.valueKey || '', formikProps)
    : '';
  const counter = 0;
  const handleSelection = (menu: MenuOptionObject) => () => {
    formikProps?.setFieldValue(fieldConfig?.valueKey || '', menu.value);

    // if (formikProps?.counter? !== 0 ) {
    // formikProps?.setFieldValue('counter' || '', -1);
    // }

    // formikProps?.setFieldValue(fieldProps?.counter?.name || '', 1);

    // console.log(fieldProps?.counter);
    // console.log('hello', fieldProps?.options[0]);

    // {
    //   btnName === 'ADD A PROMO CODE'
    //     ? formikProps?.setFieldValue('counter', 0)
    //     : console.log('a', 10);
    // }
    setBtnName('ADD ANOTHER PROMO CODE');
    console.log(btnName);

    formikProps?.setFieldValue(
      'counter',
      formikProps?.values.counter === undefined
        ? 1
        : formikProps?.values.counter + 1
    );
  };

  console.log('fp counter', formikProps?.values.counter);
  return (
    <FormControl fullWidth error={!!fieldError} {...formControlProps}>
      <div className={classes.buttonsContainer}>
        {menuOptions.map((menu: any) => {
          const btnProps =
            menu.value === value
              ? { ...defaultBtnProps, ...selectedBtnProps }
              : defaultBtnProps;
          console.log('menu check', menu);
          {
            if (menu.value === true) {
              return (
                <Button
                  variant="outlined"
                  key={menu.value}
                  onClick={handleSelection(menu)}
                  className={classes.button}
                  {...defaultBtnProps}
                >
                  <PgIcon icon="icon-add" color="secondary" />
                  <Spacer width={12} />
                  {formikProps?.values.counter === undefined
                    ? 'ADD A PROMO CODE'
                    : 'ADD ANOTHER PROMO CODE'}
                </Button>
              );
            }
          }
        })}
      </div>

      <FormHelperText {...formHelperTextProps} error={!!fieldError}>
        {fieldError || helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default memo(PromoBtn);

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    buttonsContainer: {
      flex: 1,
      flexDirection: 'row',
    },
    button: {
      marginRight: 20,
    },
  })
);
