import CollaborationRequest from 'Features/Collaboration/CollaborationRequest';
import { TEvent } from 'Models/Event';
import { TResource } from 'Models/Resource/@types';
import React, { FC, useContext } from 'react';
import { EventContext } from './EventViewModel';

export interface EventCollaborationRequestProps {}

const EventCollaborationRequest: FC<EventCollaborationRequestProps> = (props) => {
  const { eventDetail: event, setEventDetail } = useContext(EventContext);
  const onChange = (drop: TEvent | TResource) => {
    setEventDetail(drop as TEvent);
  };
  if (!event) return <div />;
  return <CollaborationRequest drop={event} dropType="Event" handleDropChange={onChange} />;
};

export default EventCollaborationRequest;
