/* eslint-disable no-unused-expressions */
import { hooks as metaMaskHooks } from 'Web3/Connector/Metamask';
import { hooks as coinbaseHooks } from 'Web3/Connector/Coinbase';
import { hooks as wallletConnectHooks } from 'Web3/Connector/WalletConnect';
import { useProvider as usePhantomProvider } from 'Web3/Connector/Phantom';
import base58 from 'bs58';
import { useWallet as useSuiWallet } from '@suiet/wallet-kit';

const { useProvider: useMetaMaskProvider } = metaMaskHooks;
const { useProvider: useCoinbaseProvider } = coinbaseHooks;
const { useProvider: useWalletConnectProvider } = wallletConnectHooks;

export default function useWeb3Actions() {
  const metaMaskProvider = useMetaMaskProvider();
  const coinbaseProvider = useCoinbaseProvider();
  const walletConnectProvider = useWalletConnectProvider();
  const phantomProvider = usePhantomProvider();

  const suiWallet = useSuiWallet();

  const getMetaMaskSignature = async (nonce: string) => {
    const signer = metaMaskProvider?.getSigner();
    const signature = await signer?.signMessage(nonce);
    return signature;
  };

  const getCoinBaseSignature = async (nonce: string) => {
    const signer = coinbaseProvider?.getSigner();
    const signature = await signer?.signMessage(nonce);
    return signature;
  };

  const getWalletConnectSignature = async (nonce: string) => {
    const signer = walletConnectProvider?.getSigner();
    const signature = await signer?.signMessage(nonce);
    return signature;
  };

  const getPhantomSignature = async (nonce: string) => {
    if (phantomProvider) {
      let signedMessage = await phantomProvider.signMessage(new TextEncoder().encode(nonce), 'utf8');
      const encoded = base58.encode(signedMessage.signature);
      return encoded;
    }
  };

  const getSuiSignature = async (nonce: string) => {
    if (!suiWallet.connected) return;
    const signature = await suiWallet.signMessage({
      message: new TextEncoder().encode(nonce),
    });
    return signature;
  };

  return {
    getMetaMaskSignature,
    getCoinBaseSignature,
    getWalletConnectSignature,
    getPhantomSignature,
    getSuiSignature,
  };
}
