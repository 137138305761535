import React, { FC, useEffect, useMemo, useState } from 'react';
import { getFieldError, IFieldProps } from 'react-forms';
import moment from 'moment-timezone';
import { Box, FormHelperText, IconButton, makeStyles, TextField, TextFieldProps, Theme } from '@material-ui/core';
import { SingleDatePicker } from 'react-dates';
import PgIcon, { TPgIcon } from 'Components/PgIcon';
import PopoverWrapper from 'Components/PopoverWrapper';
import _ from 'lodash';
import PgButton, { PgButtonProps } from 'Components/PgButton';
import PgTypo from 'Components/PgTypo';
import clsx from 'clsx';
import { inputStyles } from 'Components/FormFields/inputStyles';

export interface SingleDatepickerProps extends IFieldProps {
  fieldProps?: {
    popoverLabel?: string;
    placeholder?: string;
    dateInputFormat?: string;
    onDateSet?: (date: string) => void;
    textField?: boolean;
    icon?: TPgIcon;
    buttonProps?: PgButtonProps;
    textFieldProps?: TextFieldProps;
    timezone?: string;
    label?: string;
  };
}

const SingleDatepicker: FC<SingleDatepickerProps> = (props) => {
  const { formikProps, fieldConfig, fieldProps = {} } = props;
  const {
    popoverLabel = 'Single Datepicker',
    placeholder = 'Select Date',
    dateInputFormat = 'dddd MMM DD, YYYY',
    onDateSet,
    textField,
    icon = 'icon-calendar',
    buttonProps,
    textFieldProps,
    timezone = 'GMT',
    label,
  } = fieldProps;

  const valueKey = fieldConfig?.valueKey || '';
  const value = _.get(formikProps?.values, valueKey);
  const fieldName = valueKey.includes('[0]') ? valueKey.split('[')[0] : valueKey; // Error message may be set on parent obj. `fieldName` is used for getting the error from that.
  const error = useMemo(() => getFieldError(fieldName, formikProps ?? {}), [fieldName, formikProps]);
  const momentDate = useMemo(() => {
    return value ? moment(new Date(value)).utc().tz(timezone) : null;
  }, [timezone, value, formikProps?.values]);
  // const momentDate = moment.utc(value);
  const [date, setDate] = useState<moment.Moment | null>(momentDate);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | HTMLDivElement | HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const classes = useStyles(props);

  useEffect(() => {
    if (!date) return;
    const dateString = date.format(dateInputFormat);
    // eslint-disable-next-line no-unused-expressions
    formikProps?.setFieldValue(valueKey, dateString);
    if (onDateSet) onDateSet(dateString);
  }, [date]);

  useEffect(() => {
    if (value) setDate(moment(value).utc().tz(timezone));
  }, [timezone]);

  useEffect(() => {
    if (date) setDate(date.utc().tz(timezone));
  }, [timezone]);

  return (
    <>
      <Box>
        {label ? (
          <PgTypo b4 className={clsx(classes.label, { [classes.errorLabel]: !!error })}>
            {label}
          </PgTypo>
        ) : null}
        {textField && (
          <Box className={classes.inputContainer}>
            <IconButton className={classes.inputButton} onClick={(event) => setAnchorEl(event.currentTarget)}>
              <PgIcon icon={icon} />
            </IconButton>
            <TextField
              variant="outlined"
              aria-describedby={id}
              onChange={formikProps?.handleChange}
              onBlur={formikProps?.handleBlur}
              onClick={(event) => setAnchorEl(event.currentTarget)}
              placeholder={placeholder}
              value={value ? moment(value).format(dateInputFormat) : null}
              InputProps={{ ...textFieldProps?.InputProps }}
              fullWidth
            />
            {error ? <FormHelperText error>{typeof error === 'string' ? error : (console.log({ error }), null)}</FormHelperText> : null}
          </Box>
        )}
        {!textField && (
          <PgButton {...buttonProps} aria-describedby={id} onClick={(event) => setAnchorEl(event.currentTarget)}>
            {popoverLabel}
          </PgButton>
        )}
        <PopoverWrapper
          anchorEl={anchorEl}
          id="date-picker"
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box className={classes.datepickerContainer}>
            <SingleDatePicker
              date={date}
              onDateChange={(value) => {
                value && setDate(moment(value).utc().tz(timezone));
                setAnchorEl(null);
              }}
              focused={open}
              onFocusChange={() => {}}
              initialVisibleMonth={() => moment()}
              id="single-date-picker"
              navPrev={<PgIcon style={{ paddingLeft: 30 }} icon="icon-carrot-left" />}
              navNext={<PgIcon style={{ paddingRight: 30 }} icon="icon-carrot-right" />}
              numberOfMonths={1}
            />
          </Box>
        </PopoverWrapper>
      </Box>
    </>
  );
};

export default SingleDatepicker;

const useStyles = makeStyles<Theme, any>((theme) => ({
  label: {
    color: theme.palette.common.black,
  },
  navPrev: {
    marginTop: 25,
    marginLeft: 35,
  },
  navNext: {
    marginLeft: 189,
  },
  input: {
    marginBottom: 10,
  },
  inputContainer: {
    position: 'relative',
  },
  inputButton: {
    position: 'absolute',
    right: 0,
    // top: '50%',
    top: '18.5px',
    transform: 'translateY(-50%)',
    borderRadius: 0,
    zIndex: 10,
  },
  datepickerContainer: {
    '& .CalendarDay__default': {
      border: 'none !important',
    },
    '& .SingleDatePicker_picker': {
      top: '0 !important',
    },
    '& .DateInput_fangStroke': {
      display: 'none !important',
    },
    '& .DayPicker_weekHeader': {
      fontWeight: '800 !important',
      color: '#000000 !important',
      textTransform: 'uppercase !important',
    },
    '& .CalendarMonth_caption': {
      color: '#000000 !important',
      fontWeight: '800 !important',
    },
    '& .DayPickerNavigation_button__disabled': {
      border: 'none !important',
    },
    '& .DayPickerNavigation': {
      display: 'flex !important',
      justifyContent: 'space-between !important',
      top: '25px !important',
    },
    '& .DayPickerKeyboardShortcuts_buttonReset': {
      display: 'none !important',
    },
    '& .SingleDatePickerInput': {
      width: '309px !important',
      height: '299px !important',
    },
  },
}));
