import Axios, { CancelTokenSource } from 'axios';
import { action, Action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';
import ResourceQuery from 'Features/Query/ResourceQuery';
import { TFilter } from 'Models/AppModels';
import PodcastModel from 'Models/Podcast';
import ResourceModel from 'Models/Resource';
import { TResource } from 'Models/Resource/@types';
import { parseResource } from 'Models/Resource/resourceParsers';
// eslint-disable-next-line import/no-cycle
import { TRootStore } from 'Stores';

export interface ListenState {
  audioDetail?: TResource;
  setAudioDetail: Action<ListenState, TResource | undefined>;
  fetchAudioDetail: Thunk<ListenState, { id: string, filters?: TFilter }, null, TRootStore>;
  loadingDetails: boolean;
  setLoadingDetails: Action<ListenState, boolean>;

  listenList: TResource[];
  fetchListenList: Thunk<ListenState, ResourceQuery, null, TRootStore>;
  setListenList: Action<ListenState, { listenList: TResource[] }>;

  loading: boolean;
  setLoading: Action<ListenState, boolean>;

  total: number;
  setTotal: Action<ListenState, number>;

  query: ResourceQuery;
  setQuery: Action<ListenState, ResourceQuery>;
  onQueryChange: ThunkOn<ListenState, Record<string, unknown>, TRootStore>;
}

let cancelToken: CancelTokenSource | undefined;

const ListenStore: ListenState = {
  loadingDetails: false,
  listenList: [],
  total: 0,
  loading: false,
  query: new ResourceQuery({}, { page: 1 }),
  setAudioDetail: action((state, payload) => {
    state.audioDetail = payload;
  }),
  setLoadingDetails: action((state, payload) => {
    state.loadingDetails = payload;
  }),
  setListenList: action((state, { listenList }) => {
    state.listenList = listenList;
  }),
  setTotal: action((state, payload) => {
    state.total = payload;
  }),
  setQuery: action((state, payload) => {
    state.query = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  fetchListenList: thunk(async (actions, query, { getStoreState }) => {
    actions.setLoading(true);
    const { community } = getStoreState().CommunityStore;
    if (!community) return;

    query.communityId = community?.id ?? '';
    query.docType = 'Podcast';

    if (cancelToken) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
    cancelToken = Axios.CancelToken.source();

    const res = await ResourceModel.search(
      community?.id ?? '',
      '',
      {
        Podcast: {
          include: 'tags',
        },
        ...query.searchFilter,
      },
      { cancelToken: cancelToken.token }
    );
    if (res) {
      const listenList: TResource[] = [...res.results.map((res) => parseResource(res.hit))];

      actions.setTotal(res.total);
      actions.setListenList({ listenList });
    }
    actions.setLoading(false);
  }),
  fetchAudioDetail: thunk(async (actions, { id, filters = {} }) => {
    try {
      actions.setLoadingDetails(true);
      const res = await PodcastModel.getPodcast(id, filters);
      actions.setAudioDetail(res);
    } catch (error) {
      console.error(error);
    } finally {
      actions.setLoadingDetails(false);
    }
  }),
  onQueryChange: thunkOn(
    (actions) => actions.setQuery,
    async (actions, { payload }) => {
      actions.fetchListenList(payload);
    }
  ),
};

export default ListenStore;
