import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

export interface SpacerProps {
  height?: number;
  width?: number;
}

const Spacer: FC<SpacerProps> = ({ height = 1, width = 1 }) => {
  const classes = useStyles({});

  return <Box height={height} width={width} className={classes.root} />;
};

const useStyles = makeStyles<Theme, any>(() => ({}));

export default Spacer;
