import { Box, Button, IconButton, makeStyles, TextField, TextFieldProps, Theme } from '@material-ui/core';
import PgIcon, { TPgIcon } from 'Components/PgIcon';
import PopoverWrapper from 'Components/PopoverWrapper';
import moment from 'moment';
import React, { FC, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { IFieldProps } from 'react-forms';
import _ from 'lodash';
import PgButton, { PgButtonProps } from '../../../Components/PgButton';

export interface DateRangepickerProps extends IFieldProps {
  fieldProps?: {
    popoverLabel?: string;
    placeholder?: string;
    dateInputFormat?: string;
    onRangeDatesSet?: (dates: { startDate: string | null; endDate: string | null }) => void;
    textField?: boolean;
    icon?: TPgIcon;
    buttonProps?: PgButtonProps;
    textFieldProps?: TextFieldProps;
  };
}

const DateRangepicker: FC<DateRangepickerProps> = (props) => {
  const { formikProps, fieldConfig, fieldProps = {} } = props;
  const {
    popoverLabel = 'Date Rangepicker',
    placeholder = 'Select Date',
    dateInputFormat = 'DD-MM-YYYY',
    onRangeDatesSet,
    textField,
    icon = 'icon-calendar',
    buttonProps,
    textFieldProps,
  } = fieldProps;

  const [inputText, setInputText] = useState<string>();

  const valueKey = fieldConfig?.valueKey || '';
  const value = _.get(formikProps?.values, valueKey);

  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>('startDate');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const renderClearButton = () => {
    const onClearClick = () => {
      // eslint-disable-next-line no-unused-expressions
      formikProps?.setFieldValue(valueKey, { startDate: null, endDate: null });
      if (onRangeDatesSet) onRangeDatesSet({ startDate: null, endDate: null });
      setInputText('');
    };
    return (
      <>
        <hr style={{ width: 600 }} />
        <PgButton {...buttonProps} onClick={onClearClick} style={{ fontSize: 14, fontWeight: 400, textTransform: 'capitalize' }}>
          Clear
        </PgButton>
      </>
    );
  };

  const classes = useStyles({});

  return (
    <>
      <Box>
        {textField && (
          <Box className={classes.inputContainer}>
            <IconButton className={classes.inputButton} onClick={(event) => setAnchorEl(event.currentTarget)}>
              <PgIcon icon={icon} />
            </IconButton>
            <TextField
              variant="filled"
              aria-describedby={id}
              onClick={(event) => setAnchorEl(event.currentTarget)}
              placeholder={placeholder}
              value={inputText}
              InputProps={{ ...textFieldProps?.InputProps, classes: { input: classes.input } }}
              fullWidth
            />
          </Box>
        )}
        {!textField && (
          <Button aria-describedby={id} onClick={(event) => setAnchorEl(event.currentTarget)}>
            {popoverLabel}
          </Button>
        )}
        <PopoverWrapper
          anchorEl={anchorEl}
          id="date-picker"
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box height="400px" width="618px">
            <Box className={classes.datepickerContainer}>
              <DateRangePicker
                startDate={value?.startDate ? moment(value?.startDate, dateInputFormat) : null}
                endDate={value?.endDate ? moment(value?.endDate, dateInputFormat) : null}
                startDateId="start-date"
                endDateId="end-date"
                focusedInput={focusedInput}
                isOutsideRange={() => false}
                initialVisibleMonth={() => moment().subtract(1, 'M')}
                onDatesChange={(v) => {
                  if (v.startDate) {
                    // eslint-disable-next-line no-unused-expressions
                    formikProps?.setFieldValue(valueKey, { startDate: v.startDate.format(dateInputFormat), endDate: value?.endDate });
                  }
                  if (v.endDate) {
                    // eslint-disable-next-line no-unused-expressions
                    formikProps?.setFieldValue(valueKey, { startDate: value?.startDate, endDate: v.endDate.format(dateInputFormat) });
                    if (onRangeDatesSet) onRangeDatesSet({ startDate: value?.startDate, endDate: v.endDate.format(dateInputFormat) });
                    setInputText(`${value?.startDate} - ${v.endDate.format(dateInputFormat)}`);
                  }
                }}
                onFocusChange={setFocusedInput}
                onClose={() => setFocusedInput('startDate')}
                navPrev={<PgIcon style={{ paddingLeft: 30 }} icon="icon-carrot-left" />}
                navNext={<PgIcon style={{ paddingRight: 30 }} icon="icon-carrot-right" />}
                calendarInfoPosition="bottom"
                renderCalendarInfo={renderClearButton}
                regular
              />
            </Box>
          </Box>
        </PopoverWrapper>
      </Box>
    </>
  );
};

export default DateRangepicker;

const useStyles = makeStyles<Theme, any>((theme) => ({
  input: {
    marginBottom: 10,
  },
  inputContainer: {
    position: 'relative',
  },
  inputButton: {
    position: 'absolute',
    right: 0,
    top: 4,
    borderRadius: 0,
    zIndex: 10,
  },
  datepickerContainer: {
    '& .CalendarDay__default': {
      border: 'none !important',
    },
    '& .DateRangePicker_picker': {
      top: '0  !important',
    },
    '& .DateInput_fangStroke': {
      display: 'none !important',
    },
    '& .DayPicker_weekHeader': {
      fontWeight: '800 !important',
      color: '#000000 !important',
      textTransform: 'uppercase !important',
    },
    '& .CalendarMonth_caption': {
      color: '#000000 !important',
      fontWeight: '800 !important',
    },
    '& .DayPickerNavigation_button__disabled': {
      border: 'none !important',
    },
    '& .DayPickerNavigation': {
      display: 'flex !important',
      justifyContent: 'space-between !important',
      top: '25px !important',
    },
    '& .DayPickerKeyboardShortcuts_buttonReset': {
      display: 'none !important',
    },
  },
}));
