import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Moment } from 'moment';
import React, { FC } from 'react';
import Datetime, {
  DatetimepickerProps as DateTimePickerProps,
} from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import DateTimeUtils from 'Resources/DateTimeUtils';
import PgIcon from '../PgIcon';
import { inputStyles } from './inputStyles';

export interface TimeInputProps extends DateTimePickerProps {
  label?: string;
  offset?: string;
  format?: string;
  placeholder?: string;
}

const TimeInput: FC<TimeInputProps> = (props) => {
  const classes = useStyles();
  const inputClass = inputStyles();
  const {
    label,
    offset,
    onChange,
    format = 'HH:mm',
    placeholder,
    ...rest
  } = props;

  const handleChange = (dateValue: string | Moment) => {
    onChange?.(
      DateTimeUtils.setTimeWithoutOffset(dateValue as string, offset, format)
    );
  };

  return (
    <>
      {label ? (
        <Typography className={inputClass.label} variant="caption">
          {label}
        </Typography>
      ) : null}
      <div className={classes.root}>
        <Datetime
          dateFormat={false}
          timeFormat={format}
          className={classes.container}
          inputProps={{
            className: clsx(inputClass.input, classes.input),
            placeholder,
          }}
          utc
          onChange={handleChange}
          input
          {...rest}
        />
        <PgIcon styleClass={classes.inputIcon} icon="icon-time" />
      </div>
    </>
  );
};

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      // display: 'flex',
      width: '100%',
      position: 'relative',
      '& button': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
    input: {
      fontWeight: 700,
      fontSize: theme.typography.body2.fontSize,
    },
    inputIcon: {
      position: 'absolute',
      right: 10,
      top: 10,
    },
  })
);

export default TimeInput;
