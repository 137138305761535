export interface TSearchResult<T = any> {
  total: number;
  results: {
    hit: T;
  }[];
}

export enum EGateType {
  PASSWORD_PROTECTED = 'password',
  NFT_GATED = 'nft',
  OPEN = 'open',
}
