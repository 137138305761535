import React, { FC, useMemo, useState } from 'react';
import { Avatar, Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { User } from 'Models/User';
import MenuDots from 'Assets/icons/menu-dots.svg';
import PgTypo from 'Components/PgTypo';
import { COLORS } from 'Theme/newThemeConstants';
import { generatePath, useHistory } from 'react-router-dom';
import useUserActions, { DiscussionUserChangeAction } from './useUserActions';
import { TDiscussionConfig } from './useDiscussion';
import { TMessageMenuItem } from './MessageActions';
import { useStoreState } from 'Stores';
import helpers from 'Utils/helpers';
import UserListItem from 'Components/UserListItem';
import { APP_ROUTES } from 'Routes';
// import Menu from 'Components/Menu';

export interface MemberListItemProps {
  member: User;
  isOnline?: boolean;
  config: TDiscussionConfig;
  onUserChange?: DiscussionUserChangeAction;
}
interface MenuActionItem {
  label: string;
  onClick: () => void;
  id: string;
  hidden?: boolean;
}
const MemberListItem: FC<MemberListItemProps> = (props) => {
  const classes = useStyles();
  const { member, isOnline, onUserChange, config } = props;
  const history = useHistory();
  const { discussions, community, appUser } = useStoreState(
    ({ DiscussionStore: { discussions }, CommunityStore: { community }, AuthStore: { appUser } }) => ({
      discussions,
      community,
      appUser,
    })
  );
  const discussion = useMemo(() => {
    return discussions.find((i) => i.id === config.activeDiscussionId);
  }, [discussions, config.activeDiscussionId]);
  const { blockUser, removeUser, joinUser, unBlockUser } = useUserActions(config, onUserChange);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const toggleMenu = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e ? e.currentTarget : null);
  };

  const isJoined = !!discussion?.memberIds.includes(member.id);
  const isBlocked = !!community?.bannedUserIds?.includes(member.id);
  const isSelf = member.id === appUser?.id;
  const isCreator = config.creatorId === appUser?.id;
  const menuItems: TMessageMenuItem[] = [
    {
      label: 'See profile',
      onClick: () => history.push(`/user/${member.slug || member.id}`),
      id: 'see-profile',
      hidden: false,
    },
    isBlocked
      ? {
          label: 'UnBlock member',
          id: 'un-block',
          onClick: () => {
            unBlockUser.run(member);
          },
          hidden: !isCreator || isSelf || config.subject !== 'Community',
        }
      : {
          label: 'Block member',
          id: 'block',
          onClick: () => {
            blockUser.run(member);
          },
          hidden: !isCreator || isSelf || config.subject !== 'Community',
        },

    {
      label: 'Remove from conversation',
      id: 'remove',
      onClick: () => {
        removeUser.run(member);
      },
      hidden: !isCreator || isSelf || isBlocked || !isJoined,
    },
    {
      label: 'Add to conversation',
      onClick: () => {
        joinUser.run(member);
      },
      hidden: !isCreator || isSelf || isBlocked || isJoined,
      id: 'Add-member',
    },
  ];
  const handleMenuClick = (item: TMessageMenuItem) => {
    item.onClick();
    toggleMenu();
  };

  const onUserClick = (user: User) => {
    history.push(generatePath(APP_ROUTES.USER_DETAIL.path, { id: user.slug || user.id }));
  };
  return (
    <Box display="flex" alignItems={'center'}>
      <UserListItem style={{cursor : 'pointer'}} onClick={() => onUserClick(member)} flex={1} user={member} isOnline={isOnline} />
      <Box>
        <IconButton onClick={toggleMenu} size="small">
          <img src={MenuDots} alt="menu" className={classes.menuImg} />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        onClose={() => toggleMenu()}
        id="user-actions"
        open={Boolean(anchorEl)}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        {menuItems.map((item) =>
          item.hidden ? null : (
            <MenuItem key={item.id} onClick={() => handleMenuClick(item)}>
              {item.label}
            </MenuItem>
          )
        )}
      </Menu>
    </Box>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    onlineDot: {
      position: 'absolute',
      width: 12,
      height: 12,
      borderRadius: '50%',
      top: 0,
      right: 0,
      background: COLORS.positive,
    },
    menuImg: {
      width: 15,
      height: 15,
      [theme.breakpoints.down('sm')]: {
        height: 15,
      },
    },
  });
});

export default MemberListItem;
