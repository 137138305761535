import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { CURRENT_COMMUNITY_KEY } from 'Stores/Auth';
import Cookies from 'universal-cookie';

export type TCookieKey = string;
const cookies = new Cookies();

export interface TAPIResponse {
  message?: string;
  responseCode: number;
  timeStamp?: string;
}

export const request = async <T = Record<string, any>>(config: AxiosRequestConfig, log = true): Promise<T> => {
  if (!axios.defaults.baseURL) {
    throw new Error('Error: Base Url is not provided');
  }
  const resp = await axios.request<T>(config);

  return resp.data;
};

const utils = {
  removeAuthHeader: () => {
    delete axios.defaults.headers.common.Authorization;
  },

  getAccessToken: () => cookies.get('access_token'),
  setAccessToken: (data: string) => cookies.set('access_token', data, { path: '/' }),
  deleteAccessToken: () => cookies.remove('access_token', { path: '/' }),
  setCookie: (key: TCookieKey, data: string, domain?: string) => cookies.set(key, data, { path: '/', domain }),
  getCookie: (key: TCookieKey) => cookies.get(key),
  clearCookie: (key: TCookieKey, domain?: string) => cookies.remove(key, { path: '/', domain }),
  clearCookies: () => {
    utils.deleteAccessToken();
    localStorage.removeItem(CURRENT_COMMUNITY_KEY);
  },

  setBaseAPI_URL: (url: string) => {
    axios.defaults.baseURL = url;
    axios.defaults.withCredentials = true;
  },

  setHeader: (type = 'Content-Type', value = 'application/json') => {
    axios.defaults.headers.post[type] = value;
  },

  setAuthHeader: (accessToken?: string) => {
    axios.defaults.headers.common.Authorization = accessToken ?? '';
  },

  throwError: (error: AxiosError) => {
    console.log('Error', error.response);
    throw error;
  },
  getApiResponse: (error: AxiosError): TAPIResponse => {
    const apiError = (error.response?.data as { error: any })?.error;
    const message = apiError?.message?.message ?? apiError?.message;
    const responseCode = (apiError?.message?.responseCode ?? apiError?.responseCode) as number;
    const timeStamp = apiError?.message?.timestamp ?? apiError?.timestamp;
    return {
      message,
      responseCode,
      timeStamp,
    };
  },
  setLocalStorage: (key: string, value: string) => localStorage.setItem(key, value),
  getLocalStorage: (key: string) => localStorage.getItem(key),
};

export default utils;
