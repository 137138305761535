import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormConfig, IFormActionProps } from 'react-forms';
import usePasswordField from 'Components/usePasswordField';
import * as Yup from 'yup';
import UserModel from 'Models/User';
import { debounce } from 'lodash';
// import { FONT_WEIGHTS, TICKETING_FORM_COLOR } from 'Theme/themeConstants';
import { InputWithValidStatusFieldProps } from 'Features/ReactFormFields/InputWithValidStatusField';
import clsx from 'clsx';
import { getSlugValidation } from 'Constants/validations';

const checkEmailExistHandler = async (email: string) => {
  const doesEmailExist = await UserModel.checkIfEmailExists(email);

  return doesEmailExist;
};

const debouncedEmailCheck = debounce(checkEmailExistHandler, 500);

const SLUG_MAX_LENGTH = 30;

// eslint-disable-next-line import/prefer-default-export
export const useFormConfig = (disableEmail?: boolean, nextStep?: () => void) => {
  const classes = useStyles();

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid Email').required('Email is required'),
    // .test('emailExists', 'Email already exists', function (e: any) {
    //   if (!e) return true;
    //   const { path, createError } = this;
    //   return new Promise(async (resolve, reject) => {
    //     if (await debouncedEmailCheck(e)) {
    //       reject(createError({ path, message: 'Email already exists' }));
    //     }
    //     resolve(true);
    //   });
    // }),
    // firstName: Yup.string().required('First Name is required'),
    // lastName: Yup.string().required('Last Name is required'),
    // city: Yup.object().required('City is required'),
    slug: getSlugValidation(),
    password: Yup.string().min(8, 'Password must have atleast 8 characters.').required('Password is required'),
    // confirmPassword: Yup.string()
    //   .required('Confirm Password is required')
    //   .oneOf([Yup.ref('password')], 'Passwords must match'),
  });
  const actionConfig: IFormActionProps = {
    submitButtonText: 'SIGN UP',
    submitButtonLayout: 'fullWidth',
    submitButtonProps: {
      size: 'large',
    },
    containerClassNames: classes.buttonContainer,
  };
  const passwordField = usePasswordField('password', 'Password', false, {
    placeholder: 'Password',
    className: classes.eachField,
  });
  const confirmPasswordField = usePasswordField('confirmPassword', 'Confirm Password', false, {
    placeholder: 'Password',
    className: classes.eachField,
  });
  const emailStep = [
    {
      type: 'input-w-status',
      valueKey: 'email',
      fieldProps: {
        label: 'Email address',
        showStatusIcon: false,
        TextFieldCustomisationProps: {
          fullWidth: true,
          placeholder: 'Email address',
          variant: 'outlined',
        },
        showSeparateErrors: true,
        disabled: disableEmail,
        validText: 'Email address available',
        invalidText: 'Email address in use',
        isValid: async (text: string) => {
          const data = await UserModel.checkIfEmailExists(text);
          return !data;
        },
        labelProps: { b2: true, b4: false },
      } as InputWithValidStatusFieldProps['fieldProps'],
    },
    passwordField,
  ];
  const usernameStep = [
    {
      type: 'input-w-status',
      valueKey: 'slug',
      fieldProps: {
        label: 'Playground Username',
        showStatusIcon: false,
        TextFieldCustomisationProps: {
          fullWidth: true,
          placeholder: 'Username',
          variant: 'outlined',
        },
        validText: 'Username available',
        invalidText: 'Username in use',
        showSeparateErrors: true,
        showSlugErrors: true,
        isValid: async (text: string) => {
          const data = await UserModel.getUsersSlugExists(text);
          return !data;
        },
      } as InputWithValidStatusFieldProps['fieldProps'],
      styles: {
        marginBottom: 20,
        textAlign: 'left',
      },
    },
  ];
  // const inputConfig: Array<Array<FormConfig> | FormConfig> = [
  // {
  //   type: 'text',
  //   valueKey: 'email',
  //   fieldProps: {
  //     label: 'Email',
  //     className: classes.eachField,
  //     placeholder: 'Email',
  //     fullWidth: true,
  //     variant: 'outlined',
  //   },
  // },
  // {
  //   type: 'empty',
  //   valueKey: 'empty-1',
  // },
  // [
  //   {
  //     type: 'text',
  //     valueKey: 'firstName',
  //     fieldProps: {
  //       label: 'First Name',
  //       className: classes.eachField,
  //       placeholder: 'First Name',
  //       variant: 'outlined',
  //       fullWidth: false,
  //       InputProps: {
  //         style: { minWidth: 100 },
  //       },
  //     },
  //   },
  //   {
  //     type: 'text',
  //     valueKey: 'lastName',
  //     fieldProps: {
  //       label: 'Last Name',
  //       className: classes.eachField,
  //       placeholder: 'Last Name',
  //       variant: 'outlined',
  //       fullWidth: false,
  //       InputProps: {
  //         style: { minWidth: 100 },
  //       },
  //     },
  //   },
  // ],
  //   {
  //     type: 'google-autocomplete',
  //     valueKey: 'city',
  //     fieldProps: {
  //       suggestionTypes: ['(cities)'],
  //       label: 'City',
  //       className: clsx(classes.eachField, classes.cityField),
  //       hasMapDisabled: true,
  //       // helperText: 'You’ll be able to connect with events, communities, and people in your area.',
  //       fullWidth: true,
  //       suggestionProps: {
  //         autoSuggestProps: {
  //           placeholder: 'City',
  //           disableSearchIcon: true,
  //         },
  //       },
  //     },
  //   },
  //   {
  //     type: 'section-header',
  //     valueKey: 'city-text',
  //     fieldProps: {
  //       subtitle: 'You’ll be able to connect with events, communities, \n and people in your area.',
  //       style: {
  //         letterSpacing: 0,
  //         top: '80px',
  //         marginTop: '-35px',
  //         textAlign: 'left',
  //         marginBottom: '0.5rem',
  //       },
  //       subtitleStyle: {
  //         fontWeight: FONT_WEIGHTS.fontWeightRegular,
  //         color: TICKETING_FORM_COLOR,
  //         fontSize: '0.75rem',
  //       },
  //     },
  //   },
  //   passwordField,
  //   confirmPasswordField,
  // ];
  const inputConfig = {
    emailStep,
    usernameStep,
    passwordStep: [passwordField, confirmPasswordField],
  };
  return { actionConfig, inputConfig, validationSchema };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      '& button': {
        // backgroundColor: theme.palette.secondary.main,
      },
    },
    eachField: {
      marginTop: 20,
    },
    nameField: {
      minWidth: '50px !important',
    },
    inputItem: {
      // border: '1px solid pink',
    },
    cityField: {
      '& input': {
        padding: theme.spacing(0, 0.5) + ' !important',
      },
      '& .MuiInputBase-root': {
        paddingRight: '9px !important',
      },
    },
  }),
);
