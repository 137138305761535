import { Box, Icon, Link } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Autolinker } from 'autolinker';
import clsx from 'clsx';
import PgTypo from 'Components/PgTypo';
import PgTypoWMention from 'Components/TypographyWMention';
import UserAvatar from 'Components/UserAvatar';
import { renderHtmlStr } from 'Features/Mention';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { IDiscussionPermissions, TMessage } from 'Models/Discussion/@types';
import { getUserName } from 'Models/User/utils';
import React, { FC, useMemo, useState } from 'react';
import { SHADES } from 'Theme/newThemeConstants';
import { COLOR } from 'Theme/themeConstants';
import MessageActions from './MessageActions';
import { getDate } from './MessageCard';
import { TDiscussionConfig } from './useDiscussion';
import helpers from 'Utils/helpers';
import { autoLinkerReplaceFn } from 'Constants/initialGenerator';
import { generatePath } from 'react-router';
import { APP_ROUTES } from 'Routes';

export interface ReplyCardProps {
    message: TMessage;
    isCreator?: boolean;
    config: TDiscussionConfig;
    chatPermissions: IDiscussionPermissions;
}

const ReplyCard: FC<ReplyCardProps> = ({ message, isCreator, config, chatPermissions }) => {
    const classes = useStyles();

    // eslint-disable-next-line no-underscore-dangle
    const profileImageUrl = useMemo(() => helpers.getPictureUrl(message.user?._profileImages?.filter((i) => !!i)?.[0]), [message.user?._profileImages, helpers.getPictureUrl]);
    const userLink = useMemo(() => generatePath(APP_ROUTES.USER_DETAIL.path, { id: message.user?.slug ?? '#' }), [message.user?.slug]);

    const { isDeletedOrHidden, showCreatorHiddenView } = useMemo(() => ({
        isDeletedOrHidden: message.isDeleted || (message.isHidden && !isCreator),
        showCreatorHiddenView: message.isHidden && !message.isDeleted && isCreator,
    }), [message.isDeleted, message.isHidden, isCreator]);

    const isMessageByCreator = useMemo(() => message.userId === config.creatorId, [message.userId, config.creatorId]);

    const canShareLinks = useMemo(
        () => !!chatPermissions.canUserShareLinks || isMessageByCreator,
        [chatPermissions.canUserShareLinks, isMessageByCreator],
    );

    const msgText = useMemo(() => {
        if (isDeletedOrHidden) return 'This message has been deleted.';
        // return Autolinker.link(message.text?.replace(/<\/?[^>]+(>|$)/g, '') || '');
        if (canShareLinks)
            return Autolinker.link(message.text || '', { newWindow: true, replaceFn: autoLinkerReplaceFn });
        return message.text;
    }, [message, isDeletedOrHidden, canShareLinks]);

    const { isDeviceSm } = useMediaQuery();
    const [showMessageAction, setShowMessageAction] = useState(false);
    const toggleMessageAction = (action: boolean) => {
        if (isDeviceSm) return;
        setShowMessageAction(action);
    };
    return (
        <Box
            className={clsx(classes.msgRoot, { [classes.hidden]: showCreatorHiddenView })}
            onMouseEnter={() => toggleMessageAction(true)}
            onMouseLeave={() => toggleMessageAction(false)}
        >
            <Box className={classes.leftBorder} />
            <Box flex={1} mx={1} className={classes.msgContent}>
                <Box className={classes.msgMeta}>
                    <UserAvatar className={classes.userIcon} isColorStatic={true} url={profileImageUrl} size={''} name={message?.user?.firstName!} />
                    <Link className={classes.userName} href={userLink}>
                        <PgTypo c2>{getUserName(message.user)}</PgTypo>
                    </Link>
                    <Box mr={isDeviceSm ? 1 : 2}>
                        <PgTypo b4 className={classes.greyText}>
                            {getDate(message.created)}
                        </PgTypo>
                    </Box>
                    {isMessageByCreator ? (
                        <Box className={classes.creatorTag} mr={isDeviceSm ? 1 : 2}>
                            <PgTypo b4>Creator</PgTypo>
                        </Box>
                    ) : null}
                    {showCreatorHiddenView ? (
                        <Box display="flex" alignItems="center" mr={isDeviceSm ? 1 : 2}>
                            <Icon className={classes.hiddenIcon}>visibility_off_outlined</Icon>
                            <PgTypo b4Bold className={classes.greyText}>
                                Hidden
                            </PgTypo>
                        </Box>
                    ) : null}
                </Box>
                <PgTypoWMention
                    // dangerouslySetInnerHTML={{ __html: `<p style='display:contents'>${msgText}</p>` }}
                    className={clsx({ [classes.greyText]: isDeletedOrHidden, [classes.italicsText]: isDeletedOrHidden }, classes.text)}
                    b2
                    renderMentionHTMLStr={renderHtmlStr}
                >
                    {msgText}
                </PgTypoWMention>
                {!!message.images?.length && !isDeletedOrHidden && (
                    <Box py={1} display='flex' alignItems='flex-start' flexWrap='wrap' gridGap={16}>
                        {message.images.map(img => (
                            <img src={helpers.getPictureUrl(img)} alt={img.name} className={classes.img} />
                        ))}
                    </Box>
                )}
            </Box>
            <MessageActions
                onClose={() => toggleMessageAction(false)}
                config={config}
                visible={isDeviceSm || showMessageAction}
                isCreator={isCreator}
                message={message}
            />
        </Box>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        msgRoot: {
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            position: 'relative',
        },
        leftBorder: {
            height: 48,
            width: 2,
            background: theme.palette.primary.main,
        },
        userIcon: {
            height: `20px !important`,
            width: `20px !important`,
            fontSize: `8px`,
            marginRight: theme.spacing(1),
        },
        msgContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        userName: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                marginRight: theme.spacing(1),
            },
        },

        msgMeta: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
        greyText: {
            color: SHADES.grey,
        },
        replyButton: {
            minWidth: 0,
        },

        italicsText: {
            fontStyle: 'italic',
        },
        hidden: {
            opacity: 0.5,
        },
        hiddenIcon: {
            fontSize: `1rem`,
            marginRight: theme.spacing(0.5),
        },
        creatorTag: {
            padding: theme.spacing(0, 0.5),
            background: COLOR.tertiary.music,
        },
        text: {
            whiteSpace: 'pre-wrap',
            display: 'block',
            wordBreak: 'break-word',
            '& > p': {
                display: 'contents',
            },
            '&> p > a, & > a': {
                textDecoration: 'underline',
                wordBreak: 'break-all',
                textUnderlineOffset: '2px',
                color: theme.palette.secondary.main,
            },
        },
        img: {
            width: '100%',
            maxWidth: 350,
        },
    });
});

export default ReplyCard;
