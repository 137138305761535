import React, { FC, useState } from 'react';
import { ButtonBase, makeStyles } from '@material-ui/core';
import PgTypo from 'Components/PgTypo';
import NotificationsMenu from './NotificationsMenu';
import { useStoreActions, useStoreState } from 'Stores';
import SVGCIcon from 'Components/SVGComponents/SVGCIcon';
import { COLOR } from 'Theme/themeConstants';

interface NotifButtonProps { }

const NotifButton: FC<NotifButtonProps> = ({ }) => {
    const classes = styles();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
    const { resetUnreadNotifsCount } = useStoreActions(({ UserStore: { resetUnreadNotifsCount } }) => ({ resetUnreadNotifsCount }));
    const notificationCount = (appUser?.unReadNotificationCount ?? 0) > 99 ? '+99' : appUser?.unReadNotificationCount ? ` ${appUser.unReadNotificationCount}` : '';
    return (
        <>
            <ButtonBase
                className={classes.root}
                onClick={(event) => {
                    setAnchorEl((curr) => (curr === null ? event?.currentTarget : null));
                    resetUnreadNotifsCount();
                }}
            >
                <SVGCIcon icon="icon-bell" iconProps={{ color: notificationCount ? COLOR.tertiary.music : 'transparent' }} />
                {!!notificationCount && <PgTypo c5 style={{ whiteSpace: 'pre-wrap' }}>{notificationCount}</PgTypo>}
            </ButtonBase>
            <NotificationsMenu
                handleClose={() => {
                    setAnchorEl(null);
                    resetUnreadNotifsCount();
                }}
                anchorEl={anchorEl}
            />
        </>
    );
};

export default NotifButton;

const styles = makeStyles((theme) => ({
    root: {
        display: 'inline-flex',
        alignItems: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            // paddingRight: theme.spacing(2),
        },
    },
}));
