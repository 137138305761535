import { AxiosError } from 'axios';
import utils from 'Utils';
import { STATUS_CODES } from 'status-codes';
import isString from 'lodash/isString';
import moment from 'moment';

const { getStatusText } = STATUS_CODES;
export type MyEnumKeysAsStrings = keyof typeof STATUS_CODES.AUTH;

const DEFAULT_ERROR_MESSAGE = 'Something went wrong.';
export const sanitizedEmail = (email: string): string => {
  if (!email) return '';
  return email.toLowerCase();
};

export const getAuthErrorMessages = (error: AxiosError): string => {
  const apiResponse = utils.getApiResponse(error);
  if (!apiResponse || !apiResponse.responseCode) return DEFAULT_ERROR_MESSAGE;
  const { message, responseCode, timeStamp } = apiResponse;
  const errorType = getStatusText(responseCode) as MyEnumKeysAsStrings;
  switch (errorType) {
    case 'INVALID_EMAIL_DATA':
      return 'Invalid email.';
    case 'INVALID_PHONE_DATA':
      return 'Invalid phone.';
    case 'EMAIL_PROVIDER_ERROR':
      return 'There is an issue in sending verification code on you email.';
    case 'PHONE_PROVIDER_ERROR':
      // return 'There is an issue in sending verification code on you phone.';
      return 'That doesn’t look like a valid phone number. Please try again.';
    case 'INVALID_TOKEN':
      return 'The code you provided is either expired or invalid.';
    case 'CANNOT_SEND_OTP':
      if (responseCode === 1009 && timeStamp) return `You can resend a verification code in ${moment(timeStamp).diff(moment(), 'seconds')} seconds.`;
      return isString(message) && timeStamp ? message : 'Could not send code , please try again later.';
    case 'EMAIL_REQUIRED':
      return 'Email is required.';
    case 'PHONE_REQUIRED':
      return 'Phone is required.';
    case 'EMAIL_EXISTS':
      return 'Email already exists.';
    case 'PHONE_EXISTS':
      return 'Phone already exists.';
    case 'PASSWORD_REQUIRED':
      return 'Password is required.';
    case 'EMAIL_OR_PHONE_NOT_FOUND':
      return 'The email or phone you entered does not exists.';
    case 'COUNTRY_CODE_REQUIRED':
      return 'Country code is required.';
    case 'COUNTRY_CODE_AND_PHONE_REQUIRED':
      return 'Country code and phone is required.';
    case 'LOGIN_FAILED':
      return 'Login failed.';
    case 'EMAIL_NOT_VERIFIED':
      return 'Your email is not verified.';
    case 'PHONE_NOT_VERIFIED':
      return 'Your phone is not verified.';
    default:
      return isString(message) ? message : DEFAULT_ERROR_MESSAGE;
  }
};

export default {};
