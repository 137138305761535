/* eslint-disable import/no-cycle */
/* eslint-disable no-empty */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-throw-literal */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-catch */
import { useStripe } from '@stripe/react-stripe-js';
import { getCountryCode } from 'Constants/CountryList';
import { StripeContext } from 'Contexts/StripeContext';
import { StripeFormData } from 'Forms/Ticketing/StripeForm';
import { TBill } from 'Models/Transactions/@types';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useContext, useState } from 'react';
import { useStoreActions, useStoreDispatch, useStoreState } from 'Stores';
import usePromoCode from 'Features/Ticketing/usePromoCode';
import Loader from 'Components/Loaders/Loader';
import useEventActions from 'Features/Event/useEventActions';
import helpers from 'Utils/helpers';
import get from 'lodash/get';
import useToastMessage from 'Hooks/useToastMessage';
import TicketingDialog, { TicketingDialogProps } from '.';
import useEventTicketing, { PaymentIntentResponse, PaymentStatus } from '../../Features/Ticketing/useEventTicketing';
import { EventTicketFormData, EventTicketFormProps } from '../../Forms/Ticketing/EventTicketForm';
import { TransactionConfirmationProps } from '../../Forms/Ticketing/TransactionConfirmation';
import useAsyncTask from '../../Hooks/useAsyncTask';
import EventModel, { TEvent } from '../../Models/Event';
import useNativePay from './useNativePay';
import NotAvailable from './NotAvailable';
import MESSAGES from 'Utils/Messages';
import { ANALYTICS_USER_EVENT } from 'Analytics/analyticActions';
import { useLocation } from 'react-router';
import { omit } from 'lodash';
import UserModel from 'Models/User';
import ToastMessage from 'Components/ToastMessage';
import useAuthDialog from 'Features/Auth/useAuthDialog';
import moment from 'moment';

const idGenerator = helpers.idGenerator();
const EMAIL_EXIST_ERR = 'Email already exist';

const filters = {
  include: [
    'tags',
    'tiers',
    { relation: 'community', scope: { fields: ['_profileImages', 'id', 'name'] } },
    'includePurchasedTickets',
    'collaborators',
  ],
};

interface UserUpdateMeta { firstName?: string, lastName?: string, dob?: string }

function useTicketingDialog(afterTicketPurchase?: (isSoldOut?: boolean) => void) {
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
  const { openDialog: openAuthDialog } = useAuthDialog();
  const [event, setEvent] = useState<TEvent | null>(null);
  const { setStripeAccount, setStripeAccountByAccountId } = useContext(StripeContext);
  const [formState, setFormState] = useState<TicketingDialogProps['formState']>('eventRegistration');
  const { purchaseTicket } = useEventTicketing();
  const [password, setPassword] = useState<string>();
  const [numTickets, setNumTickets] = useState(1);
  const [appliedPromoCode, setAppliedPromoCode] = useState('');
  const [promoCodeError, setPromoCodeError] = useState('');
  const [secret, setSecret] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [stripeAccountError, setStripeAccountError] = useState('');
  const { fetchEventDetail, setAppUser } = useStoreActions(({ EventDetailStore: { fetchEventDetail }, AuthStore: { setAppUser } }) => ({
    fetchEventDetail, setAppUser,
  }));
  const { availableTickets, chains } = useStoreState(({ EventDetailStore: { availableTickets }, App: { chains } }) => ({ availableTickets, chains }));

  // const [transactionId, setTransactionId] = useState('');
  const { checkPromoCode, handleChange: handlePromoCodeChange, promoCode, clear: clearPromoCode } = usePromoCode();
  const [transactionConfirmationProps, setTransactionConfirmationProps] = useState<TransactionConfirmationProps>({
    onTransactionComplete: afterTicketPurchase,
  });
  const [eventType, setEventType] = useState<EventTicketFormProps['type']>('free');
  const ticketInfo = event ? EventModel.getTicketInfo(event) : null;
  const stripe = useStripe();
  const { createReminder } = useEventActions();
  const [bill, setBill] = useState<TBill | null>(null);
  const [billError, setBillError] = useState('');
  const [isTicketPurchased, setIsTicketPurchased] = useState<{ isPurchased: boolean, meta?: UserUpdateMeta}>({ isPurchased: false });
  const { Component: NativePayButton, createPaymentRequestButton, disconnectListener } = useNativePay();
  const dispatch = useStoreDispatch();
  const { pathname } = useLocation();
  // const eventTiers = event?._tiers?.[0]?._ticketTiers;
  // const intitialTicketPrice = ticketInfo?.id;
  const [selectedTierId, setSelectedTierId] = useState('');

  useEffect(() => {
    if (event && numTickets) {
      if (!ticketInfo) return;
      setBillError('');
      if (selectedTierId === '') setSelectedTierId(ticketInfo?.id as string);
      else {
        EventModel.get_Event_id_bill(
          event.id,
          numTickets,
          event?.tierType === 'multi' ? selectedTierId : (ticketInfo?.id as string),
          appliedPromoCode === '' ? undefined : appliedPromoCode,
        )
          .then((res) => {
            setBill(res.data);
            setBillError('');
          })
          .catch((err) => {
            // setBill(null);
            if (get(err, 'response.data.error.responseCode') === 'SOLD_OUT' && event.tierType === 'multi')
              setBillError(MESSAGES.TICKET_TIER_SOLD_OUT);
            else setBillError(get(err, 'response.data.error.message') ?? MESSAGES.GENERAL_ERROR);
            setNumTickets(1);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numTickets, event, appliedPromoCode, selectedTierId]);

  useEffect(() => {
    return () => {
      disconnectListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openTicketingDialog = useCallback(async (_event: TEvent, password?: string) => {
    setBill(null);
    const eventDetail = await fetchEventDetail({ id: _event.id, filters });
    setEvent(eventDetail);
    // setupStripe.run(eventDetail);
    if (eventDetail.tierType !== 'free' && eventDetail.tierType !== 'externalSite') {
      setupStripe.run(eventDetail);
    }
    setPassword(password);

    setAppliedPromoCode('');
    setPromoCodeError('');
    clearPromoCode();
    setNumTickets(1);
    setEventType(eventDetail.tierType === 'single' || eventDetail.tierType === 'multi' || eventDetail.tierType === 'nft' ? 'paid' : 'free');
    setTransactionConfirmationProps({});
    setFormState('eventRegistration');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setupStripe = useAsyncTask(async (event: TEvent) => {
    setStripeAccountError('');
    if (event.tierType === 'free') return;
    const isSuiEvent = event?._tiers?.find((f) => f.type === 'nft')?._ticketTiers?.[0]?.blockchain === 'sui';
    if (event.tierType === 'nft' && !isSuiEvent) {
      setStripeAccount();
      return;
    }
    try {
      const { data } = await EventModel.get_Events_id_stripe_account_id(event.id);
      setStripeAccountByAccountId(data);
    } catch (error) {
      // enqueueSnackbar('Stripe account not linked', { variant: 'error' })
      // closeDTicketingDialog();
      setStripeAccountError('Ticketing is not currently available for this event.');
    }
  });

  const closeDTicketingDialog = useCallback(() => {
    if (isTicketPurchased.isPurchased) {
      if (event) createReminder(event, idGenerator.next().value.toString());
      const remainingTickets = event?._tiers
        ?.find((f) => f.type === event.tierType)
        ?._ticketTiers.reduce(
          (prev, curr) => prev + (curr.id === selectedTierId ? (curr?.remainingTickets ?? 0) - numTickets : curr.remainingTickets ?? 0),
          0,
        );
      if (appUser) setAppUser({ ...appUser, ...isTicketPurchased.meta})
      afterTicketPurchase?.(remainingTickets === 0);
      setIsTicketPurchased({ isPurchased: false });
    }
    setEvent(null);
  }, [isTicketPurchased, appUser]);

  const handleFreeEvent = async (data: Partial<EventTicketFormData>) => {
    if (!event) return;
    try {
      const { data: res } = await purchaseTicket(event.id, numTickets, data, ticketInfo?.id, undefined, password);
      const paymentStatus = res as unknown as PaymentStatus;
      setTransactionConfirmationProps({
        email: data.email || '',
        paymentStatus,
        eventDetail: event.description || '',
        owner: `${data.firstName || ''} ${data.lastName || ''}`,
        totalTickets: numTickets,
        event,
        bill,
      });
      if (appUser) setAppUser({ ...appUser, firstName: data.firstName, lastName: data.lastName, dateOfBirth: data.dob });
      setIsTicketPurchased({ isPurchased: true });
      setFormState('transactionConfirmation');
      dispatch({
        type: ANALYTICS_USER_EVENT,
        data: { eventName: 'TICKET_PURCHASED', context: { user: appUser, eventType, bill }, source: pathname },
      });
      // afterTicketPurchase?.();
    } catch (error) {
      throw error;
    }
  };

  const handlePaidEvent = async (data: Partial<EventTicketFormData>) => {
    if (!event) return;
    try {
      const res = await purchaseTicket(
        event?.id ?? '',
        numTickets,
        data,
        event?.tierType === 'multi' ? selectedTierId : ticketInfo?.id,
        appliedPromoCode ?? undefined,
        password,
      );
      const { clientSecret, transactionId, tempAccessToken, isNewUser } = res.data as PaymentIntentResponse;
      const { isPurchaseSuccess } = res.data as PaymentStatus;
      if (clientSecret) {
        createPaymentRequestButton(
          {
            country: 'US',
            currency: 'usd',
            total: {
              amount: helpers.formatAmount((bill?.total || 0) * 100, 2),
              label: 'Total',
            },
          },
          clientSecret,
        );
        setSecret(clientSecret);
      }
      setTransactionConfirmationProps({
        email: data.email || '',
        transactionId,
        eventDetail: event?.description || '',
        owner: `${data.firstName || ''} ${data.lastName || ''}`,
        totalTickets: numTickets,
        // onTransactionComplete: afterTicketPurchase,
        paymentStatus: isPurchaseSuccess ? (res.data as PaymentStatus) : undefined,
        event,
        bill,
        tempAccessToken,
        isNewUser,
      });
      if (isPurchaseSuccess) {
        if (appUser) setAppUser({ ...appUser, firstName: data.firstName, lastName: data.lastName, dateOfBirth: data.dob });
        setIsTicketPurchased({ isPurchased: true });
        setFormState('transactionConfirmation');
      } else {
        setFormState('cardDetail');
        setIsTicketPurchased({ isPurchased: false, meta: { firstName: data.firstName, lastName: data.lastName, dob: data.dob }});
      }
    } catch (error) {
      throw error;
    }
  };

  const withToast = useToastMessage();

  const registerHandler = useAsyncTask(async (data: Partial<EventTicketFormData>) => {
    if (!event) return;
    try {
      if (data.email && !appUser) {
        const isExist = await UserModel.checkIfEmailExists(data.email);
        if (isExist) {
          enqueueSnackbar(ToastMessage({ title: MESSAGES.EMAIL_EXIST_LOGIN }), { variant: 'info' });
          closeDTicketingDialog();
          openAuthDialog();
          return;
        }
      }
      if (eventType === 'paid') {
        await handlePaidEvent(data);
      } else {
        await withToast(
          async () => {
            await handleFreeEvent(data);
          },
          {
            genraToastMessage: 'Registration successful',
            errorToastMessage: 'Registration Failed',
            variant: 'success',
            anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
          },
        );
      }
      // else await handleFreeEvent(data);

      // createReminder(event, idGenerator.next().value.toString());
    } catch (error) {}
  });

  const completeStripePaymentHandler = useAsyncTask<Partial<StripeFormData>>(async (data) => {
    if (!stripe || !secret || !data.stripeCard || !event) return;
    try {
      const res = await stripe.confirmCardPayment(secret, {
        payment_method: {
          card: data.stripeCard,
        },
      });
      if (res.error) {
        if (res.error.type === 'validation_error') return;
        setFormState('transactionCancel');
        throw 'err';
      }
      enqueueSnackbar(ToastMessage({ title: 'Checkout successful' }), {
        variant: 'success',
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      });
      if (appUser) setAppUser({ ...appUser, ...isTicketPurchased.meta });
      setIsTicketPurchased(t => ({ isPurchased: true }));
      setFormState('transactionConfirmation');
      dispatch({
        type: ANALYTICS_USER_EVENT,
        data: {
          eventName: 'TICKET_PURCHASED',
          context: { user: omit(appUser, ['otpResult', 'firstName', 'isPublished', 'password']), eventType, bill },
          source: pathname,
        },
      });
    } catch {
      enqueueSnackbar('Checkout failed', { variant: 'error', anchorOrigin: { horizontal: 'center', vertical: 'bottom' } });
    }
  });

  const handlePromoCodeApply = async (code: string) => {
    if (!event) return;
    const isValid = await checkPromoCode(event.id, code);
    if (isValid) {
      setAppliedPromoCode(code);
      setPromoCodeError('');
    } else {
      setAppliedPromoCode('');
      setPromoCodeError('Promo code invalid');
    }
  };

  const handleClearPromoCode = () => {
    clearPromoCode();
    setAppliedPromoCode('');
    setPromoCodeError('');
  };
  // const multiTierIndex = findIndex(event?._tiers, (t) => { return t.name === "multi" } )

  const handleSmsAction = () => {
    closeDTicketingDialog();
    openAuthDialog('verifyPhone', { phone: appUser?.phone, hideDontAskOption: true });
  };

  return {
    openTicketingDialog,
    closeDTicketingDialog,
    Component:
      setupStripe.status === 'PROCESSING' ? (
        <Loader overlayed />
      ) : stripeAccountError ? (
        <NotAvailable onClose={closeDTicketingDialog} open={!!event} message={stripeAccountError} />
      ) : event && bill ? (
        <TicketingDialog
          formState={formState}
          bill={bill}
          handleClose={closeDTicketingDialog}
          open={!!event}
          event={event}
          numTickets={numTickets}
          handleCancelTransaction={() => setFormState('transactionCancel')}
          CancelTransactionProps={{ onAgree: closeDTicketingDialog, onCancel: () => setFormState('cardDetail') }}
          EventRegistrationFormProps={{
            error: billError,
            ticketLimit: ticketInfo?.maxTicketsPerOrder || 1,
            type: eventType,
            initialValues: {
              email: appUser?.email,
              firstName: appUser?.firstName,
              lastName: appUser?.lastName,
              phone: appUser?.phone?.phone,
              countryCode: getCountryCode(appUser?.phone?.countryCode),
              dob: appUser?.dateOfBirth ? appUser.dateOfBirth : !!event.ageRestriction && event?.featureToggle?.ageRestriction ? moment().toISOString() : undefined,
            },
            // onQuantityUpdate: (ticketInfo?.maxTicketsPerOrder || 1) === 1 ? undefined : setNumTickets,
            onQuantityUpdate: setNumTickets,
            onSubmit: registerHandler.run,
            loading: registerHandler.status === 'PROCESSING',
            PromoCodeInputProps: {
              isApplied: !!appliedPromoCode,
              helperText: appliedPromoCode ? 'promo code applied' : promoCodeError,
              onApply: handlePromoCodeApply,
              onClear: handleClearPromoCode,
              error: !!promoCodeError,
              value: promoCode,
              onChange: handlePromoCodeChange,
            },
            onTierUpdate: setSelectedTierId,
            selectedTierId,
            // tiers: eventTiers?.length as number >= 1 ? event?._tiers[multiTierIndex]?._ticketTiers : null,
            tiers: availableTickets as any,
            bill,
            event,
            numTickets,
          }}
          StripeFormProps={{
            NativePayButton,
            bill,
            variant: eventType,
            event,
            loading: completeStripePaymentHandler.status === 'PROCESSING',
            onSubmit: completeStripePaymentHandler.run,
            onBack: () => setFormState('eventRegistration'),
            totalTickets: numTickets,
          }}
          TransactionConfirmationProps={{ ...transactionConfirmationProps, onSmsActionClick: handleSmsAction }}
        />
      ) : null,
  };
}

export default useTicketingDialog;
