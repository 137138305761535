import React, { FC, isValidElement } from 'react';
import { Box, BoxProps, IconButton } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import twitter from 'Assets/Logo/twitter.png';
import discord from 'Assets/Logo/discord.png';
import google from 'Assets/Logo/google.png';
import PgTypo from 'Components/PgTypo';
import config from 'config';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import PgButton from 'Components/PgButton';
import Spacer from 'Components/Spacer';
import SVGCIcon from 'Components/SVGComponents/SVGCIcon';

export interface ISocialAuthItems {
    id: string; //this will also be used as provider
    label: string;
    icon: string | JSX.Element;
    url: string;
}
export type SocialAuthStep = "LOG_IN" | "SIGNUP";
const SocialAuthItems = (step?: SocialAuthStep): ISocialAuthItems[] => [
    {
        id: 'google',
        label: step ? SOCIAL_AUTH_LABEL_MAPPING[step] : "Google",
        icon: google,
        url: '/auth/google/login',
    },
    // {
    //     id: 'discord',
    //     label: 'Discord',
    //     icon: discord,
    //     url: '/auth/discord',
    // },
    // {
    //     id: 'twitter',
    //     // label: 'Twitter',
    //     // icon: twitter,
    //     label: '',
    //     icon: <SVGCIcon icon='icon-twitter' color={isDeviceSm ? 'primary' : undefined} />,
    //     url: '/auth/twitter',
    // },
];

export interface SocialAuthProps {
    rootProps?: BoxProps;
    socialAuthStep?: SocialAuthStep;
}

const SocialAuth: FC<SocialAuthProps> = (props) => {
    const { isDeviceSm } = useMediaQuery();
    const classes = useStyles();
    const getUrl = (item: ISocialAuthItems) => {
        // const currentHost = window.location.host;
        const host = config.get('BASE_URL');
        return `${host}${item.url}?redirectUrl=${window.location.pathname}`; //${currentHost}`;
    };
    return (
        <Box my={2} display="flex" flexDirection={'column'} alignItems="center" style={{ gap: 16 }} {...props.rootProps}>
            {/* <PgTypo b1>Or continue with</PgTypo> */}
            <Box display={'flex'} width='100%' justifyContent='center' style={{ gap: 20 }}>
                {SocialAuthItems(props?.socialAuthStep).map((item) => {
                    const url = getUrl(item);
                    return (
                        <PgButton
                            fullWidth
                            secondary
                            key={item.id}
                            className={classes.btn}
                            href={url}
                            isExternalLink={true}
                            openInNewTab={false}
                        >
                            {isValidElement(item.icon) ? item.icon : <img src={item.icon as string} alt={item.label} />}
                            {!item.label ? null : (
                                <>
                                    <Spacer width={12} />
                                    {item.label}
                                </>
                            )}
                        </PgButton>
                    );
                })}
            </Box>
        </Box>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        btn: {
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(1.5),
                // backgroundColor: theme.palette.common.white,
                minWidth: 'unset',
            },
        },
    });
});

export default SocialAuth;

const SOCIAL_AUTH_LABEL_MAPPING: Record<SocialAuthStep, string> = {
    "LOG_IN": "log in with Google",
    "SIGNUP": "sign up with Google"
};
