/* eslint-disable import/no-cycle */
import { Box, useTheme } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DialogWrapper from 'Components/DialogWrapper';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { TUserCommunity } from 'Models/User/@types';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router';
import { APP_ROUTES } from 'Routes';
import { useStoreState } from 'Stores';
import { THEME_PALETTE } from 'Theme/themeConstants';
import LogInMenu from './LogInMenu';
import LogOutMenu from './LogOutMenu';
import Dark from 'Features/DarkTheme';

export interface MobileMenuDialogProps {
  open: boolean;
  onClose: () => void;
}

const MobileMenuDialog: FC<MobileMenuDialogProps> = (props) => {
  const { open, onClose } = props;

  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({
    appUser,
  }));

  const { isDeviceSm } = useMediaQuery();
  const classes = useStyles({});
  const history = useHistory();

  useEffect(() => {
    if (appUser) setMenuStep('login');
    else setMenuStep('logout');
  }, []);

  const [menuStep, setMenuStep] = useState<'login' | 'logout' | 'manageCommunity' | 'joinedCommunity' | null>(null);

  const handleClick = async (community: TUserCommunity, searchParams?:string) => {
    let path = generatePath(APP_ROUTES.COMMUNITY_DETAIL.path, {
      communityId: community.slug ?? community.id,
    });
    if(searchParams)
    path = path + `?${searchParams}`;
    console.log('path',path)
    history.push(path);
    onClose();
  };


  const component = useMemo(() => {
    switch (menuStep) {
      case 'login':
        return <LogInMenu onClose={onClose} onClick={handleClick} />;
      case 'logout':
        return <LogOutMenu onClose={onClose} />;
        // case 'manageCommunity':
        //   return community ? <ManagedCommunities community={community} onClose={onClose} onBack={handleBack} /> : null;
        // case 'joinedCommunity':
        //   return community ? <JoinedCommunity community={community} onClose={onClose} onBack={handleBack} /> : null;
        // case null:
        return null;
      default:
        return null;
    }
  }, [menuStep]);

  const theme = useTheme();

  return (
    <DialogWrapper open={open} fullScreen={isDeviceSm} zIndex={theme.zIndex.modal}>
      <Dark>
        <Box className={classes.root}>{component}</Box>
      </Dark>
    </DialogWrapper>
  );
};

export default MobileMenuDialog;

const useStyles = makeStyles<Theme, any>((theme) =>
  createStyles({
    root: {
      // backgroundColor: THEME_PALETTE.common.white,
      width: '100%',
      height: '100%',
    },
  })
);
