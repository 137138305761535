import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ResetLinkForm from 'Forms/ResetLink';
import useAsyncTask from 'Hooks/useAsyncTask';
import { Box } from '@material-ui/core';
import useAppStyles from 'Theme/Styles';
import PgDialog from 'Components/PgDialog';
import PgButton from 'Components/PgButton';
import useAuth from '../useAuth';
import { TAuthDialogContentProps } from '../@types';
import Timer from '../AuthDialogContent/Timer';
import { TIMEOUT } from 'Constants/variables';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { FONT_FAMILY } from 'Theme/themeConstants';
import Spacer from 'Components/Spacer';

const ResetPassword: React.FC<TAuthDialogContentProps> = (props) => {
  const { onDone, changeStep, meta } = props;
  const classes = useStyles({});
  const [canReset, setCanReset] = useState(true);
  const [startTimer, setStartTimer] = useState(false);
  const appClasses = useAppStyles();
  const { forgotPassword } = useAuth();
  const handleForgotPassword = async (email: string) => {
    try {
      await forgotPassword(email.toLowerCase());
      setCanReset(false);
      setStartTimer(true);
    } catch (error) {
      // error handling
      console.error(error);
      throw error;
    }
  };
  const forgotPasswordRunner = useAsyncTask(handleForgotPassword);
  const handleTimerComplete = () => {
    setStartTimer(false);
    setCanReset(true);
  };

  const { isDeviceSm } = useMediaQuery();

  return (
    <PgDialog
      headingContainerProps={{ display: 'flex', justifyContent: 'center', position: 'relative', pb: 2.5 }}
      headingProps={{ h3: !isDeviceSm, h7: isDeviceSm, align: 'center', typoWeight: 'fontWeightBold', h6: false }}
      subheadingProps={{ className: classes.subtitle, c2: false, b1: true }}
      heading="Reset Password"
      subheading="Enter your registered email below to receive password reset instructions"
      prev={() => {
        changeStep(meta?.setStep ?? 'login');
      }}
      body={
        <>
          <Box mt={2.5}>
            <ResetLinkForm
              isSubmitting={forgotPasswordRunner.status === 'PROCESSING'}
              initialData={{ email: meta?.email }}
              onSubmit={forgotPasswordRunner.run}
              disableAction={!canReset}
            />
          </Box>
          <Timer
            showTimer={!canReset}
            timeInSeconds={TIMEOUT.FORGET_PASSWORD}
            startTimer={startTimer}
            onTimerComplete={handleTimerComplete}
            mt={2.5}
          />
        </>
      }
      actionButtons={
        meta?.email ? (
          <></>
        ) : (
          <>
            <Spacer height={32} />
            <PgButton
              quaternary
              underlined
              onClick={() => {
                changeStep('login');
              }}
              className={classes.backBtn}
            >
              Back to login
            </PgButton>
          </>
        )
      }
    />
  );
};

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontFamily: FONT_FAMILY.secondary,
    marginBottom: theme.spacing(5),
  },
  backBtn: {
    width: 'fit-content',
    margin: '0 auto',
    '& .MuiButton-label': {
      fontFamily: FONT_FAMILY.secondary,
      fontSize: 16,
    },
  },
}));

export default ResetPassword;
