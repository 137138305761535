import { Box, makeStyles, Theme } from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import React, { FC } from 'react';
import PgTypo from './PgTypo';

export type TagSize = 'small' | 'medium' | 'large';

export interface TagProps {
  size: TagSize;
  color: string;
  nameColor?: string;
}

const Tag: FC<TagProps> = (props) => {
  const { size, color, children } = props;
  const classes = useStyles(props);
  return (
    <Box className={classes.root}>
      <FiberManualRecord className={classes.badge} />
      <PgTypo {...(size === 'small' ? { c5: true } : { c2: true })} className={classes.tagName}>
        {children}
      </PgTypo>
    </Box>
  );
};

const useStyles = makeStyles<Theme, TagProps>((theme) => ({
  root: {
    display: 'flex',
    width: 'fit-content',
  },
  badge: (props) => ({
    color: props.color,
    height: getBadgeDimension(props.size, theme),
    width: getBadgeDimension(props.size, theme),
    marginTop: 'auto',
    marginBottom: 'auto',
  }),
  tagName: (props) => ({
    margin: theme.spacing('auto', 'auto', 'auto', tagNameMargin(props.size)),
    textTransform: 'uppercase',
    lineHeight: props.size !== 'small' ? '24px' : '16px',
    color: props.nameColor ?? theme.palette.common.black,
  }),
}));

const getBadgeDimension = (size: TagProps['size'], theme: Theme) => {
  if (size === 'large') return theme.spacing(5) / 0.66;
  if (size === 'small') return theme.spacing(1.5) / 0.66;
  return theme.spacing(2.5) / 0.66;
};

const tagNameMargin = (size: TagProps['size']) => {
  if (size === 'large') return -0.25;
  if (size === 'small') return 0.25;
  return 0.2;
};

export default Tag;
