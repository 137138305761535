import { TResource } from 'Models/Resource/@types';
import { parseResource, parseResourceFormData } from 'Models/Resource/resourceParsers';
import AxiosUtils, { request } from 'Resources/AxiosUtils';
import { JSONType } from 'Typings/Global';

class ArticleModel {
  static getAllArticles = async (params?: Record<string, any>): Promise<JSONType[]> => {
    const res = await request<JSONType[]>({
      url: '/articles',
      method: 'GET',
      params,
    });
    return res.map(parseResource);
  };

  static getArticle = async (id: string, params?: Record<string, any>): Promise<TResource> => {
    const res = await request<JSONType>({
      url: `/resources/${id}/find/Article`,
      method: 'GET',
      params,
    });
    return parseResource(res);
  };

  static updateArticle = async (data: Partial<TResource> & { id: string }): Promise<TResource> => {
    const res = await request({
      url: `/articles/${data.id}`,
      method: 'patch',
      data: { ...parseResourceFormData(data) },
    }).catch(AxiosUtils.throwError);
    if (!res) return Promise.reject();
    return { ...data, ...parseResource(res) } as TResource;
  };

  static deleteArticles = async (ids: string[]): Promise<JSONType> => {
    const res = await request<JSONType>({
      url: '/articles/trash',
      method: 'DELETE',
      data: { ids },
    });
    return res;
  };

  static togglePublishArticle = (id: string, publish: boolean): Promise<JSONType> =>
    request<JSONType>({
      url: `/articles/${id}/publish`,
      method: 'post',
      params: { publish },
    });
}

export default ArticleModel;
