import { Box, alpha, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
// import { stubFalse } from 'lodash';
import React, { FC } from 'react';
import './style.css';
// import LOADER from 'Assets/loader.gif';
import { LOADER_MAX_SIZE, LoaderComp } from 'Components/Loader';

export interface LoaderProps {
    overlayed?: boolean
}

const Loader: FC<LoaderProps> = ({ overlayed = false }) => {
    const classes = useStyles({});


    return (
        <Box className={clsx({
            [classes.overlayed]: overlayed,
            [classes.root]: !overlayed,
        })} display='flex' width='100%' justifyContent='center'>
            {/* <div className="spinner"> */}
            <LoaderComp />
        </Box>
    )
}


const useStyles = makeStyles<Theme, any>((theme) => ({

    overlayed: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: theme.zIndex.appBar + 1000,
        height: `100vh`,
        width: `100vw`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: alpha('#fff', 0.4)
    },
    root: {
        height: '100vh',
        alignItems: 'center',
    },
    imgBox: {
        // margin: `100px auto`,
        maxWidth: LOADER_MAX_SIZE,
        maxHeight: LOADER_MAX_SIZE,
    },
    img: { width: '100%', height: '100%', aspectRatio: '1 / 1', objectFit: 'cover' },
}));

export default Loader;
