import React, { FC, useMemo } from 'react';
import { Avatar, Box, BoxProps } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Invitee, User } from 'Models/User';
import helpers from 'Utils/helpers';
import { getUserName } from 'Models/User/utils';
import PgTypo from './PgTypo';
import { COLORS } from 'Theme/newThemeConstants';
import UserAvatar from './UserAvatar';

export interface UserListItemProps extends BoxProps {
  user: User;
  isOnline?: boolean;
}

const UserListItem: FC<UserListItemProps> = ({ user, isOnline, ...boxProps }) => {
  const classes = useStyles();
  const { image, name } = useMemo(
    () => ({
      image: helpers.getPictureUrl(user?._profileImages?.[0]),
      name:
        !user.firstName && !user.lastName && user.name
          ? user.name
          : user.slug || user.firstName || user.lastName
          ? getUserName(user)
          : (user as Invitee).referredUserEmail,
    }),
    [user],
  );
  return (
    <Box display={'flex'} alignItems="center" {...boxProps}>
      <Box mr={1.5} position="relative">
      <UserAvatar className={classes.userImg} url={image} size={''} name={name} />
        {isOnline ? <Box className={classes.onlineDot} /> : null}
      </Box>
      <Box flex={1}>
        <PgTypo>{name}</PgTypo>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    userImg: {
      width: `32px`,
      height: `32px`,
      fontSize: '14px !important',
    },
    onlineDot: {
      position: 'absolute',
      width: 12,
      height: 12,
      borderRadius: '50%',
      top: 0,
      right: 0,
      background: COLORS.positive,
    },
  });
});

export default UserListItem;
