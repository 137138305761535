import { AppBar, Box, makeStyles, Theme, Toolbar } from '@material-ui/core';
import POINTS_ICON from 'Assets/icons/points_icon.svg';
import clsx from 'clsx';
import PgTypo from 'Components/PgTypo';
import Spacer from 'Components/Spacer';
import NotifButton from 'Features/Notifications/NotifButton';
import SearchBar from 'Features/Search/SearchBar';
import { DRAWER_SIDEBAR_COLLAPSED_WIDTH, DRAWER_SIDEBAR_EXTENDED_WIDTH } from 'Features/Sidebar/DrawerSidebar';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ACCOUNTS_ROUTES } from 'Routes/AccountNavigation';
import { useStoreState } from 'Stores';
import { NO_PRINT_CLASS } from 'Theme/Styles';
import PG_LOGO from '../../Assets/Logo/PLAYGROUND_WORDMARK-S_WHITE_2.svg';
import NavGroup from './NavGroup';
import UserMenu from './UserMenu';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import MobileNav from './MobileNav';
import useNavigation from './useNavigation';

export interface IAppHeader {
    showLogo?: boolean;
    creditPoints?: number;
}

export const APP_HEADER_HEIGHT = 50;

const Search: FC = () => (
    <Box display='flex' justifyContent='center' flex={1}>
        <SearchBar />
    </Box>
)

const Appheader: FC<IAppHeader> = ({ showLogo, creditPoints }) => {
    const classes = useStyles();
    const { open } = useStoreState(({ DrawerSidebarStore: { open } }) => ({
        open,
    }));
    const sideBarClass = showLogo ? classes.rootSideBarClosed : open ? classes.rootSideBarOpen : classes.rootSideBarCollapsed;

    const { isDeviceSm } = useMediaQuery();
    const { NAVLINKS, RIGHT_NAV_LINKS } = useNavigation();

    if (isDeviceSm) return <MobileNav />;

    return (
        <>
            <AppBar position="sticky" className={clsx(NO_PRINT_CLASS, classes.root, sideBarClass)}>
                <Toolbar className={classes.toolbar}>
                    {showLogo && (
                        <>
                            <Box height={APP_HEADER_HEIGHT}>
                                <Link to="/">
                                    <img src={PG_LOGO} alt="pg-logo" className={classes.logo} />
                                </Link>
                            </Box>
                            <Search />
                        </>
                    )}

                    <div className={NO_PRINT_CLASS}>
                        <NavGroup navLinks={NAVLINKS} />
                    </div>
                    {!showLogo && <Search />}
                    {!showLogo && (
                        <>
                            <NotifButton />
                            <Spacer width={20} />
                            <PgPoints points={creditPoints ?? 0} />
                            <Spacer width={20} />
                        </>
                    )}
                    <Box display='flex'>
                        <NavGroup navLinks={RIGHT_NAV_LINKS} />
                        <UserMenu />
                    </Box>
                </Toolbar>
                {/* <BannerMessage /> */}
            </AppBar>
        </>
    );
};

export default Appheader;

export const PgPoints: FC<{ points: number }> = ({ points }) => {
    const classes = useStyles();
    return (
        <Link to={ACCOUNTS_ROUTES.INVITE_FRIENDS.path} target="_self" className={classes.pointsRoot}>
            <img src={POINTS_ICON} alt="Points icon" />
            <PgTypo contrast c5 style={{ whiteSpace: 'pre-wrap' }}>
                {points > 99 ? '+99' : `  ${points}`}
            </PgTypo>
        </Link>
    );
};

const useStyles = makeStyles<Theme>((theme) => ({
    root: {
        zIndex: theme.zIndex.modal + 1,
        left: DRAWER_SIDEBAR_COLLAPSED_WIDTH,
        width: 'auto',
    },
    rootSideBarCollapsed: {
        left: DRAWER_SIDEBAR_COLLAPSED_WIDTH,
    },
    rootSideBarOpen: {
        left: DRAWER_SIDEBAR_EXTENDED_WIDTH,
    },
    rootSideBarClosed: {
        left: 0,
    },
    toolbar: {
        height: APP_HEADER_HEIGHT,
        minHeight: APP_HEADER_HEIGHT,
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: 0,
    },
    icon: {
        color: theme.palette.primary.contrastText,
        cursor: 'pointer',
    },
    logoContainerSm: {
        marginBottom: 8,
        padding: theme.spacing(1, 1, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1.5, 1, 1),
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '& a': {
            display: 'flex',
            color: theme.palette.common.white,
            textDecoration: 'none',
            // width: '100px'
            width: 190,
        },
    },
    logo: {
        height: APP_HEADER_HEIGHT,
        paddingTop: 13,
        paddingBottom: 13,
        marginLeft: 28,
    },
    menuContainer: {
        padding: theme.spacing(0, 4.5, 0),
        marginRight: '-80px',
        minWidth: 'fit-content',
    },
    navTab: {
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    focusTab: {
        textDecoration: 'underline',
    },
    pointsRoot: {
        display: 'flex',
        alignItems: 'flex-start',
        borderRadius: theme.spacing(12.5),
        padding: theme.spacing(1, 1.75),
        minWidth: theme.spacing(8),
    },
}));
