/* eslint-disable react/require-default-props */
import { Box, List, ListItem } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import POINTS_ICON from 'Assets/icons/points_icon.svg';
import clsx from 'clsx';
import Onboarding, { OnboardState } from 'Components/Onboarding';
import PgButton from 'Components/PgButton';
import PgIcon, { TPgIcon } from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import useNavigation from 'Features/AppHeader/useNavigation';
import WalletList from 'Features/AppHeader/WalletList';
import useAuth from 'Features/Auth/useAuth';
import useAuthDialog from 'Features/Auth/useAuthDialog';
import OnboardingPopup, { OnboardingPopupProps } from 'Features/Popover/OnboardingPopup';
import { COMMUNITIES_PREVIEW_COUNT, CREATE_COMMUNITY_MAX_COUNT } from 'Features/Sidebar/DefaultSidebar';
import { isString } from 'lodash';
import { TUserCommunity, User } from 'Models/User';
import React, { FC, isValidElement, useCallback, useEffect, useState } from 'react';
import { TooltipRenderProps } from 'react-joyride';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES } from 'Routes';
import { ACCOUNTS_ROUTES } from 'Routes/AccountNavigation';
import { useStoreState } from 'Stores';
import { MobileMenuItem } from './LogOutMenu';
import MenuHeader from './MenuHeader';
import UserAvatar from 'Components/UserAvatar';
import helpers from 'Utils/helpers';
import TICKET_ICON from 'Assets/icons/ticket.svg';
import { commOnboardStepsCls, communityOnboardSteps, hideOnboard, isOnboardHidden } from 'Constants/onboardingContent';
import { CONTACT_GMAIL_LINK } from 'Constants/links';
import SVGCIcon from 'Components/SVGComponents/SVGCIcon';
import HOME_ICON from 'Assets/icons/home.svg';
import GLOBE_ICON from 'Assets/icons/globe.svg';
import EVENT_ICON from 'Assets/icons/add-drop.svg';

export interface LogInMenuProps {
    onClose: () => void;
    onClick?: (community: TUserCommunity, searchParams?: string) => void;
}



export const GeneralMenuItem = (appUser?: User): MobileMenuItem[] => [
    {
        id: 0,
        name: 'Home',
        link: APP_ROUTES.HOME_PAGE.path,
        imgSrc: HOME_ICON,
        imgAlt: 'home-icon',
    },
    {
        id: 5,
        name: 'Drops',
        link: APP_ROUTES.DROPS.path,
        imgSrc: EVENT_ICON,
        imgAlt: 'globe-icon',
    },
    {
        id: 1,
        name: 'Communities',
        link: APP_ROUTES.COMMUNITIES.path,
        imgSrc: GLOBE_ICON,
        imgAlt: 'globe-icon',
    },
    helpers.isProduction() ? undefined : {
        id: 3,
        name: 'Rewards',
        link: APP_ROUTES.REWARDS.path,
        icon: <img src={POINTS_ICON} alt="Points icon" width={28} />,
    },
    {
        id: 2,
        name: (
            <Box display={'flex'} alignItems="baseline" style={{ gap: 8 }}>
                <PgTypo b2 component={'span'} style={{ marginLeft: 14 }}>
                    Playboard
                </PgTypo>
                <PgTypo color="primary" c3>
                    ({appUser?.wallet?.credits_total || 0})
                </PgTypo>
            </Box>
        ),
        link: ACCOUNTS_ROUTES.INVITE_FRIENDS.path,
        icon: <img src={POINTS_ICON} alt="Points icon" />,
    },
    // {
    //     id: 3,
    //     name: 'Orders',
    //     link: ACCOUNTS_ROUTES.TICKETS.path,
    //     // icon: <PgIcon icon="icon-ticket" />,
    //     imgSrc: TICKET_ICON,
    //     imgAlt: 'ticket-icon',
    // },
    // {
    //     id: 4,
    //     name: 'Favorites',
    //     link: generatePath(APP_ROUTES.FAVORITES.path, { slug: appUser?.slug ?? appUser?.id }),
    //     // icon: <PgIcon icon="icon-save-fill" />,
    //     icon: 'icon-save-fill',
    // },
].filter(f => f) as MobileMenuItem[];

export const BottomItem = (appUser?: User, onWalletListItemClick?: () => void): Array<MobileMenuItem | JSX.Element> => [
    {
        id: 3,
        name: 'My Profile',
        link: generatePath(APP_ROUTES.USER_DETAIL.path, { id: appUser?.slug ?? appUser?.id ?? '#' }),
        icon: <PgIcon icon="icon-person" />,
    },
    <WalletList onListItemClick={onWalletListItemClick} />,
    {
        id: 7,
        name: 'Orders',
        link: ACCOUNTS_ROUTES.TICKETS.path,
        icon: <img src={TICKET_ICON} alt='ticket-icon' width={24} />
    },
    {
        id: 8,
        name: 'Favorites',
        link: generatePath(APP_ROUTES.FAVORITES.path, { slug: appUser?.slug ?? appUser?.id ?? '#' }),
        icon: <PgIcon icon='icon-save' />,
    },
    {
        id: 5,
        icon: <SVGCIcon icon='icon-add-user' color='primary' />,
        name: 'Invites',
        link: ACCOUNTS_ROUTES.INVITE_FRIENDS.path,
    },
    {
        id: 4,
        name: 'Settings',
        link: ACCOUNTS_ROUTES.SETTINGS.path,
        // icon: <img src={SETTINGS_ICON} alt='settings-icon' width={24} />,
        icon: <SVGCIcon icon='icon-settings' color='primary' />,
    },
    {
        id: 6,
        icon: <PgIcon icon="icon-question" />,
        name: 'Support',
        // onClick: handleSupportClick,
        link: CONTACT_GMAIL_LINK,
        isExternal: true,
    },
];

const LogInMenu: FC<LogInMenuProps> = (props) => {
    const { onClose, onClick } = props;
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({
        appUser,
    }));

    const { logout } = useAuth();
    const { openDialog } = useAuthDialog(() => onClose());

    const { NAVLINKS } = useNavigation();

    const [seeMore, setSeeMore] = useState(false);
    const [seeMoreComm, setSeeMoreComm] = useState(false);

    const classes = useStyles({});

    const history = useHistory();

    // const hideOnboarding = (skipped: boolean = false) => {
    //   setOnboardState((e) => ({ ...e, run: false, steps: [] }));
    //   localStorage.setItem(`${appUser?.id || ''}_onboard`, skipped ? 'closed' : `started`);
    // };
    // const shouldShowOnboarding = () => {
    //   /**
    //    * @author Adnan Husain
    //    * We show this onboarding helper if and only if the onboarding has not been done or initiated and user has only one community.
    //    */
    //   const isHelperHidden = localStorage.getItem(`${appUser?.id || ''}_onboard`);
    //   const userCommunitiesCount = appUser?.userCommunities?.length || 0;
    //   if (isHelperHidden || userCommunitiesCount !== 1) return;
    //   setOnboardState({
    //     tooltipProps: {
    //       onClick: () => {
    //         if (appUser?.userCommunities?.[0]) onClick?.(appUser.userCommunities[0], 'showOnboard=1');
    //         hideOnboarding();
    //       },
    //       onClose: () => hideOnboarding(true),
    //       content: {
    //         actionText: 'YAY',
    //         body: 'Click here to build and grow your community with powerful creator tools.',
    //         title: 'Your community plays here ⚡️',
    //       },
    //     },
    //     run: true,
    //     steps: [
    //       {
    //         target: `.onboard-${appUser?.userCommunities?.[0].slug}`,
    //         content: `Build and grow your community with powerful creator tools. `,
    //         placement: 'bottom',
    //         disableBeacon: true,
    //       },
    //     ],
    //   });
    // };
    const [onboardState, setOnboardState] = useState<OnboardState & { tooltipProps: OnboardingPopupProps }>({
        run: false,
        steps: [],
        tooltipProps: {
            onClick: () => { },
            content: { title: '', actionText: '', body: '' },
        },
    });
    const hideHelperDialog = () => {
        setOnboardState((e) => ({ ...e, run: false, steps: [] }));
        hideOnboard('manage-comm-sidebar');
        // localStorage.setItem(`${appUser?.id || ''}_helperState`, `${new Date().getTime()}`);
        // history.push(APP_ROUTES.CREATE_COMMUNITY.path, { flow: 'hyperCommCreate' });
    };

    const { target: onboardStateTarget, ...onboardStepContent } = communityOnboardSteps()[0];

    const handleNext = () => {
        onClose();
        history.replace({ search: 'tab=2' });
    }

    const shouldShowCreateHelper = () => {
        setTimeout(() => {
            /**
             * @author Adnan Husain
             * We show create community helper if and only if the helper is not hidden by user and user has no communities.
             */
            // const isHelperHidden = localStorage.getItem(`${appUser?.id || ''}_helperState`);
            // const userHasCommunities = !!appUser?.userCommunities?.length;
            // if (isHelperHidden || userHasCommunities) {
            //   // if we don't need to show helper then we check if onboarding is needed
            //   shouldShowOnboarding();
            //   return;
            // }
            if (isOnboardHidden('manage-comm-sidebar') || !appUser?.userCommunities?.length) return;
            setOnboardState({
                tooltipProps: {
                    onClose: hideHelperDialog,
                    onClick: handleNext,
                    // content: {
                    //   title: 'Bring your people together.',
                    //   body: 'Create a community for party people, internet icons or your favorite exes. Then, access creator tools to help you grow!',
                    //   actionText: 'GOT IT',
                    // },
                    // content: onboardingContent.createCommunity,
                    // showCloseIconBtn: true,
                    content: onboardStepContent,
                },
                run: true,
                steps: [
                    {
                        // target: '.create-community',
                        target: onboardStateTarget,
                        content: 'some content',
                        placement: 'bottom',
                        disableBeacon: true,
                    },
                ],
            });
        }, 1000);
    };
    const ToolTip = ({ tooltipProps }: TooltipRenderProps) => (
        <div {...tooltipProps}>
            <OnboardingPopup {...onboardState.tooltipProps} />
        </div>
    );
    useEffect(() => {
        if (appUser?.id && appUser.emailVerified) {
            shouldShowCreateHelper();
        }
    }, [appUser?.id, appUser?.emailVerified]);

    const userCommunity = appUser?.userCommunities ?? [];
    const userFollowedCommunity = (appUser?.followsCommunities ?? []).filter((f) => !f?.isDeactivated);

    const handleListItemClick = (link?: string, isExternal = false) => {
        onClose();
        if (link) {
            if (isExternal) window.open(link, '_blank');
            else history.push(link);
        }
    };

    const handleBtnClick = () => {
        onClose();
        logout();
        history.push('/');
    };
    const handleSupportClick = useCallback(() => {
        // window.open(ZENDESK_SUPPORT_LINK, '_blank');
        history.push(APP_ROUTES.CONTACT.path);
        // setAnchorEl(null);
    }, []);

    const handleCreateCommunity = () => {
        if (!appUser?.emailVerified) {
            openDialog('verify-email-first').then(() => onClose());
            return;
        }
        history.push(APP_ROUTES.CREATE_COMMUNITY.path);
        onClose();
    };
    const handleJoinACommunity = () => {
        history.push(APP_ROUTES.COMMUNITIES.path);
        onClose();
    };

    return (
        <>
            <Onboarding
                callback={() => { }}
                run={onboardState.run}
                scrollOffset={300}
                stepIndex={0}
                steps={onboardState.steps}
                tooltipComponent={ToolTip}
                spotlightPadding={10}
                scrollToFirstStep
                styles={{
                    options: {
                        zIndex: 10000,
                    },
                    tooltip: {
                        width: 'auto',
                    },
                }}
            />
            <Box height="100%" overflow="auto">
                <Box position="sticky" zIndex={1} top={0}>
                    <MenuHeader title="Menu" onClose={onClose} />
                </Box>

                <List style={{ padding: 0 }}>
                    {NAVLINKS.map((m) => {
                        return (
                            <ListItem
                                key={m.id}
                                onClick={() => {
                                    handleListItemClick(m.url);
                                    m.onClick?.();
                                }}
                                className={clsx(classes.listItem, classes.clickableItem)}
                            >
                                <PgTypo c1>{m.label}</PgTypo>
                            </ListItem>
                        );
                    })}
                    <Box className={classes.listItem}>
                        <Box>
                            <PgTypo c1>My Playground</PgTypo>
                            {GeneralMenuItem(appUser).map((c) => {
                                return (
                                    // <PgTypo b2 key={c.id} onClick={() => handleListItemClick(c.link)} style={{ marginTop: 24, marginLeft: 8 }}>
                                    //   {c.name}
                                    // </PgTypo>
                                    <GenralMenuItem
                                        icon={c.icon}
                                        onClick={() => {
                                            handleListItemClick(c.link);
                                            c.onClick?.();
                                        }}
                                        imgSrc={c.imgSrc}
                                        imgAlt={c.imgAlt}
                                        classNames={classes.clickableItem}
                                        text={c.name}
                                        key={c.id}
                                    />
                                );
                            })}
                        </Box>
                    </Box>
                    <Box className={classes.listItem}>
                        <Box width="100%">
                            <PgTypo c1>Communities I Manage</PgTypo>
                            <Communities
                                communities={userCommunity}
                                onClick={(c) => onClick?.(c)}
                                isSeeMore={seeMoreComm}
                                handleSeeMore={() => setSeeMoreComm(!seeMoreComm)}
                            />
                            {userCommunity?.length >= CREATE_COMMUNITY_MAX_COUNT ? null : (
                                <div className={clsx(classes.createButtonElem)}>
                                    <PgButton primary fullWidth className={clsx(classes.createNewBtn, 'create-community')} onClick={handleCreateCommunity}>
                                        {/* <PgTypo>Create New Community</PgTypo> */}
                                        Create New Community
                                    </PgButton>
                                </div>
                            )}
                        </Box>
                    </Box>
                    {!!userFollowedCommunity.length && (
                        <ListItem className={classes.listItem}>
                            <Box width="100%">
                                <PgTypo c1>Communities I’ve Joined</PgTypo>
                                {userFollowedCommunity.length ? (
                                    <Communities
                                        communities={userFollowedCommunity}
                                        onClick={(c) => onClick?.(c)}
                                        isSeeMore={seeMore}
                                        handleSeeMore={() => setSeeMore(!seeMore)}
                                    />
                                ) : (
                                    // <ListItem className={classes.listItem}>
                                    <div className={clsx(classes.createButtonElem)}>
                                        <PgButton onClick={handleJoinACommunity} variant="outlined" fullWidth>
                                            join a community
                                        </PgButton>
                                    </div>

                                    // </ListItem>
                                )}
                            </Box>
                        </ListItem>
                    )}
                    {BottomItem(appUser, onClose).map((item) => {
                        if (isValidElement(item)) return <div key={'wallet-list'}>{item}</div>;
                        const b = item as MobileMenuItem;
                        return (
                            <ListItem
                                key={b.id}
                                className={clsx(classes.listItem, classes.clickableItem)}
                                onClick={() => {
                                    handleListItemClick(b.link, b.isExternal);
                                    b.onClick?.();
                                }}
                            >
                                <GenralMenuItem text={b.name} icon={b.icon} textOnly />
                            </ListItem>
                        );
                    })}
                </List>
                <Box py={4} px={3}>
                    <PgButton secondary fullWidth onClick={handleBtnClick} className={classes.btn}>
                        <PgTypo c1>LOG OUT</PgTypo>
                    </PgButton>
                </Box>
            </Box>
        </>
    );
};

export default LogInMenu;

export const GenralMenuItem: FC<{
    onClick?: () => void;
    text: string | React.ReactNode;
    icon?: React.ReactNode;
    textOnly?: boolean;
    classNames?: string;
    imgSrc?: string;
    imgAlt?: string;
}> = (props) => {
    const { onClick, text, icon, textOnly = false, classNames, imgSrc, imgAlt } = props;
    return (
        <Box
            // component={ButtonBase}
            onClick={onClick}
            className={classNames}
            style={{ display: 'flex', alignItems: 'center', marginTop: textOnly ? undefined : 24 }}
        >
            {(icon || imgSrc) && (imgSrc ? <img src={imgSrc} width={24} alt={imgAlt} /> : typeof icon === 'string' ? <PgIcon icon={icon as TPgIcon} /> : icon)}
            {isString(text) ? (
                <PgTypo b2 style={{ marginLeft: 8 }}>
                    {text}
                </PgTypo>
            ) : (
                text
            )}
        </Box>
    );
};

interface CommunitiesProps {
    communities: TUserCommunity[];
    onClick?: (community: TUserCommunity) => void;
    isSeeMore: boolean;
    handleSeeMore: () => void;
}

const Communities: FC<CommunitiesProps> = (props) => {
    const { communities, onClick, isSeeMore, handleSeeMore } = props;
    const classes = useStyles({});
    if (!communities || !communities.length) return null;
    if (isSeeMore || communities.length < COMMUNITIES_PREVIEW_COUNT + 1) {
        return (
            <>
                {communities.map((c) => {
                    return <Community key={c.id} community={c} onClick={onClick} />;
                })}
                {isSeeMore && (
                    <PgButton fullWidth quaternary className={classes.seeMoreBtn} onClick={handleSeeMore} disableFocusRipple disableTouchRipple disableRipple>
                        <PgTypo c3>See less</PgTypo>
                    </PgButton>
                )}
            </>
        );
    }
    return (
        <>
            {communities.slice(0, COMMUNITIES_PREVIEW_COUNT).map((c) => {
                return <Community key={c.id} community={c} onClick={onClick} />;
            })}
            <PgButton fullWidth quaternary onClick={handleSeeMore} className={classes.seeMoreBtn}>
                <PgTypo c3 align="center">
                    See More
                </PgTypo>
            </PgButton>
        </>
    );
};

interface CommmunityProps {
    community: TUserCommunity;
    onClick?: (Community: TUserCommunity) => void;
}

const Community: FC<CommmunityProps> = (props) => {
    const { community, onClick } = props;
    const classes = useStyles({});
    return (
        <Box
            className={clsx({ [classes.disabled]: community.isDeletionRequested }, `onboard-${community.slug}`, classes.clickableItem, commOnboardStepsCls.manageComm)}
            display="flex"
            alignItems="center"
            ml={1}
            mt={3}
            onClick={() => onClick?.(community)}
        >
            {/* {community?._profileImages?.[0]?.url ? (
        <Avatar src={community?._profileImages?.[0]?.url} className={classes.avatar} />
      ) : (
        <Avatar className={classes.avatar}>{initialGenerator(community.name)}</Avatar>
      )} */}
            <UserAvatar name={community.name} size='32px' url={helpers.getPictureUrl(community?._profileImages?.[0])} style={{ marginRight: 12 }} />

            <PgTypo b2>{community.name}</PgTypo>
        </Box>
    );
};

const useStyles = makeStyles<Theme, any>((theme) =>
    createStyles({
        listItem: {
            padding: theme.spacing(2.5, 3),
            borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
        clickableItem: {
            cursor: 'pointer',
        },
        btn: {
            border: `1px solid ${theme.palette.primary.main}`,
            fontWeight: theme.typography.fontWeightBold,
        },
        disabled: {
            cursor: 'default',
            pointerEvents: 'none',
            opacity: 0.5,
        },
        avatar: {
            marginRight: theme.spacing(1.5),
            width: 32,
            height: 32,
            fontSize: 32 / 3,
            fontWeight: theme.typography.fontWeightBold,
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        seeMoreBtn: {
            fontSize: theme.typography.body2.fontSize,
            textDecoration: 'underline',
            fontWeight: theme.typography.fontWeightRegular,
            marginTop: theme.spacing(3),
            marginLeft: theme.spacing(1),
            padding: 0,
            textTransform: 'none',
        },
        createButtonElem: {
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(4),
        },
        createNewBtn: {
            // minWidth:40,
            // width: 40,
            height: 40,
        },
        icon: {
            width: 24,
            height: 24,
        },
    }),
);
