import { CHAIN_NAMESPACES } from '@web3auth/base';
// import { Web3AuthNoModalOptions } from '@web3auth/no-modal';
import { OpenloginAdapterOptions } from '@web3auth/openlogin-adapter';
import Web3Auth, { Web3AuthOptions } from '@web3auth/single-factor-auth';

const isProductionWeb = window.location.hostname === 'playgroundapp.com'; // FIXME: use env var

export const VERIFIER_ID = 'playground-jwt';
// const VERIFIER_JWT_FIELD = 'sub'; // unused in favor of single-factor-auth
const POLY_CHAIN_ID = !isProductionWeb ? '0x13881' : '0x89';
const POLY_RPC = !isProductionWeb
  ? 'https://polygon-mumbai.g.alchemy.com/v2/D0WsmDQ9BXquG_Ni5hNYVDu2hdHARCq7'
  : 'https://polygon-mainnet.g.alchemy.com/v2/Zff_YXVobJ_JlrltXxmk4lsbFTcmFHBT';
const WEB3_AUTH_CHAIN_NAMESPACE = CHAIN_NAMESPACES.EIP155;
const WEB3_AUTH_CLIENT_ID = 'BEyhoMeYWen1gMbr9YUntA5LWRyPlzT4q-5Ti1dFU3BAmBelZPtB2grJoXpqEwwp6piGlLxe9ZyaWef4BZDtV-A';
const RPCMap = {
  [CHAIN_NAMESPACES.EIP155]: {
    [POLY_CHAIN_ID]: POLY_RPC,
  },
};

export const openLoginAdapterConfig: OpenloginAdapterOptions = {
  adapterSettings: {
    loginConfig: {
      jwt: {
        typeOfLogin: 'jwt',
        verifier: VERIFIER_ID,
        clientId: WEB3_AUTH_CLIENT_ID,
      },
    },
  },
} as const;

export const web3AuthConfig: Web3AuthOptions = {
  chainConfig: {
    chainNamespace: WEB3_AUTH_CHAIN_NAMESPACE,
    chainId: POLY_CHAIN_ID,
    blockExplorer: isProductionWeb ? 'https://polygonscan.com' : 'https://mumbai.polygonscan.com',
    rpcTarget: RPCMap[WEB3_AUTH_CHAIN_NAMESPACE][POLY_CHAIN_ID],
  },
  clientId: WEB3_AUTH_CLIENT_ID,
  web3AuthNetwork: isProductionWeb ? 'cyan' : 'testnet',
} as const;
