import { TFilter } from 'Models/App/@types';
import { request } from 'Utils';
import { SearchResponse, JSONType } from 'Typings/Global';
import { Tag } from './@types';
import { parseTag } from './tagParsers';

class TagModel {
  static getTagsList = async (term?: string, filter?: TFilter): Promise<SearchResponse<Tag, JSONType>> => {
    const res = await request<SearchResponse<Tag>>({
      url: '/tags/search',
      params: {
        term,
        filter,
      },
    });
    res.results = res.results.map((r) => ({ hit: parseTag(r.hit) }));
    return res;
  };

  static getTagsSummary = (): Promise<Tag[]> => {
    return request<Tag[]>({
      url: `/Tags/summary`,
      method: 'get',
    });
  };
}

export default TagModel;
