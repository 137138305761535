import { StyleRules } from '@material-ui/core';
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides';
import { THEME_PALETTE } from 'Theme/themeConstants';

const FormControlLabel: Partial<StyleRules<ComponentNameToClassKey['MuiFormControlLabel']>> = {
  root: {
    color: THEME_PALETTE.grey['300'],
  },
};

export default FormControlLabel;
