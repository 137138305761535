/* eslint-disable @typescript-eslint/ban-types */
import { action, Action, actionOn, ActionOn, thunk, Thunk, ThunkOn, thunkOn } from 'easy-peasy';
import { TResultItem } from 'Features/Search/useSearch';
import { omit } from 'lodash';
import AppModel from 'Models/App';
import { TEvent } from 'Models/Event/@types';
import { EVENT_FIELDS } from 'Models/Event/utils';
import ResourceModel, { resourceIncludeFilter } from 'Models/Resource';
import { ResourceResponse, TResource, TResourceResponseResult } from 'Models/Resource/@types';
import { TRootStore } from 'Stores';

type Res = { results: TResultItem[]; total: number };
export interface TSearchPageState {
  searchTerm: string;
  loading: boolean;
  drops: ResourceResponse;
  communities: Res;
  users: Res;
  setSearchTerm: Action<TSearchPageState, string>;
  setDrops: Action<TSearchPageState, ResourceResponse>;
  setCommunities: Action<TSearchPageState, Res>;
  setUsers: Action<TSearchPageState, Res>;
  setLoading: Action<TSearchPageState, boolean>;
  search: Thunk<TSearchPageState, string>;
  onSearchTermChange: ThunkOn<TSearchPageState, {}, TRootStore>;

  onContentFavorite: ActionOn<TSearchPageState, TRootStore>;
  onContentUnFavorite: ActionOn<TSearchPageState, TRootStore>;
  onEventRemind: ActionOn<TSearchPageState, TRootStore>;
  onEventUnRemind: ActionOn<TSearchPageState, TRootStore>;
}

export const RESOURCE_SEARCH_QUERY_CONSTANT = {
  Event: {
    fields: EVENT_FIELDS,
    include: ['tags', 'ticketingInfo', 'collaborators', { relation: 'community', scope: { fields: ['_profileImages', 'id', 'name'] } }],
  },
  Video: resourceIncludeFilter,
  Collect: resourceIncludeFilter,
  Podcast: resourceIncludeFilter,
  Article: resourceIncludeFilter,
};

const SearchPageStore: TSearchPageState = {
  searchTerm: '',
  loading: false,
  drops: { results: [], total: 0, aggregations: {} },
  communities: { results: [], total: 0 },
  users: { results: [], total: 0 },
  setSearchTerm: action((state, payload) => {
    state.searchTerm = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setDrops: action((state, payload) => {
    state.drops = payload;
  }),
  setCommunities: action((state, payload) => {
    state.communities = payload;
  }),
  setUsers: action((state, payload) => {
    state.users = payload;
  }),
  search: thunk(async (actions, searchTerm) => {
    actions.setLoading(true);
    const [resourceResponse, { results: communities, total: totalCommunities }, { results: users, total: totalUsers }] = await Promise.all([
      ResourceModel.getUserResourcesAndEvents(undefined, {
        term: searchTerm,
        filter: { limit: 11, ...RESOURCE_SEARCH_QUERY_CONSTANT, where: { isPublished: true } },
      }),
      AppModel.searchEntities(searchTerm, { limit: 4, doc_type: 'Community' }),
      AppModel.searchEntities(searchTerm, { limit: 4, doc_type: 'User' }),
    ]);
    console.log('setDrops');
    actions.setDrops({
      ...resourceResponse,
      results: resourceResponse.results.map((item) => ({
        ...item,
        hit: {
          ...item.hit,
          ...(['video', 'podcast', 'article', 'collect'].includes(item.hit.doc_type.toLowerCase())
            ? { startTime: (item.hit as TResource).releaseDate }
            : {}),
        },
      })),
    });
    actions.setCommunities({ results: communities.map((c) => c.hit), total: totalCommunities });
    actions.setUsers({ results: users.map((u) => u.hit), total: totalUsers });
    // actions.se(data.results.map(r => r.hit));
    actions.setLoading(false);
  }),
  onSearchTermChange: thunkOn(
    (actions, storeActions) => actions.setSearchTerm,
    async (actions, { payload }) => {
      if (payload) actions.search(payload);
    },
  ),

  onContentFavorite: actionOn(
    (actions, storeActions) => storeActions.AuthStore.contentFavorited,
    (state, { payload: { event, favouriteId } }) => {
      state.drops = {
        ...state.drops,
        results:
          state.drops?.results?.map((e) =>
            e.hit.id === event.id ? ({ ...e, hit: { ...e.hit, ...event, isFavourited: true, favouriteId } } as TResourceResponseResult) : e,
          ) ?? [],
        total: state.drops?.total ?? 0,
        aggregations: state.drops?.aggregations ?? {},
      };
    },
  ),
  onContentUnFavorite: actionOn(
    (actions, storeActions) => storeActions.AuthStore.contentUnFavorited,
    (state, { payload }) => {
      state.drops = {
        ...state.drops,
        results:
          state.drops?.results?.map((e) =>
            e.hit.id === payload ? ({ ...omit(e, ['hit.isFavourited', 'hit.favouriteId']) } as TResourceResponseResult) : e,
          ) ?? [],
        total: state.drops?.total ?? 0,
        aggregations: state.drops?.aggregations ?? {},
      };
    },
  ),
  onEventRemind: actionOn(
    (actions, storeActions) => storeActions.AuthStore.eventReminded,
    (state, { payload: { event, reminderId } }) => {
      state.drops = {
        results:
          state.drops?.results?.map((e) => (e.hit.id === event.id ? { ...e, hit: { ...e.hit, ...event, isReminded: true, reminderId } } : e)) ?? [],
        total: state.drops?.total ?? 0,
        aggregations: state.drops?.aggregations ?? {},
      };
    },
  ),
  onEventUnRemind: actionOn(
    (actions, storeActions) => storeActions.AuthStore.eventUnReminded,
    (state, { payload }) => {
      state.drops = {
        results:
          state.drops?.results?.map((e) =>
            (e.hit as TEvent).reminderId === payload ? ({ ...omit(e, ['hit.isReminded', 'hit.reminderId']) } as TResourceResponseResult) : e,
          ) ?? [],
        total: state.drops?.total ?? 0,
        aggregations: state.drops?.aggregations ?? {},
      };
    },
  ),
};

export default SearchPageStore;
