import * as API from './api';
import * as Utils from './utils';

export * from './@types';

const AuthModel = {
  ...API,
  ...Utils,
};

export default AuthModel;
