import { Box, ButtonProps, FormControlProps, FormHelperTextProps, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import PgButton from 'Components/PgButton';
import PgTypo from 'Components/PgTypo';
import useAuthDialog from 'Features/Auth/useAuthDialog';
import { User } from 'Models/User';
import React, { FC } from 'react';
import { MenuOptions, MenuOptionObject, IFieldProps } from 'react-forms';

export interface IVerifyEmailButtonProps extends IFieldProps {
  fieldProps?: {
    isEmailVerified?: boolean;
    email?: User['email'];
    options?: MenuOptions<MenuOptionObject>;
    defaultButtonProps?: ButtonProps;
    formControlProps?: FormControlProps;
    formHelperTextProps?: FormHelperTextProps;
  };
}

const VerifyEmailButton: FC<IVerifyEmailButtonProps> = (props) => {
  const { fieldProps = {} } = props;
  const { isEmailVerified, email } = fieldProps;
  const classes = useStyles();
  const { openDialog } = useAuthDialog();
  return (
    <Box>
      {isEmailVerified ? (
        <>
          <PgTypo b4 component="p" className={clsx(classes.emailVerifiedText, classes.emailtext)}>{`${email} has been verified`}</PgTypo>
          <PgButton quaternary underlined className={classes.emailBtn} onClick={() => openDialog('edit-email')}>
            Change
          </PgButton>
        </>
      ) : (
        <>
          <PgTypo b4 component="p" color="error" className={classes.emailtext}>{`${email} has not been verified`}</PgTypo>
          <PgButton quaternary underlined className={classes.emailBtn} onClick={() => openDialog('verifyEmailPrompt')}>
            Verify
          </PgButton>
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  emailVerifiedText: {
    color: theme.palette.secondary.main,
  },
  emailtext: {
    display: '-webkit-box',
  },
  emailBtn: {
    margin: '16px 5px 0px -10px',
  },
}));

export default VerifyEmailButton;
