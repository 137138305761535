import { initializeConnector } from '@web3-react/core'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'

import { MAINNET_CHAINS, TESTNET_CHAINS } from '../Chains'
import config from 'config'

const [mainnet, ...optionalChains] = Object.keys({ ...MAINNET_CHAINS, ...TESTNET_CHAINS }).map(Number)

const chainId = parseInt(config.get('DEFAULT_CHAIN_ID') ?? '', 10);

export const [walletConnect, hooks] = initializeConnector<WalletConnectV2>(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: config.get('WALLETCONNECT_PROJECT_ID') ?? '',
        chains: [chainId ?? mainnet],
        optionalChains,
        showQrModal: true,
        qrModalOptions: { themeVariables: { "--wcm-z-index": '2000' } },
      },
    })
)
