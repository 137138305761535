/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { request } from 'Utils';
import { TCollaborationRequest } from './@types';

class CollaborationRequestModel {
  static createCollaborationRequest = (data: Omit<TCollaborationRequest, 'id'|'created'|'status'|'acceptedId' | 'subject'>) => {
    return request<TCollaborationRequest>({
      url: '/CollaborationRequests/request-collaboration',
      data,
      method: 'POST',
    });
  };

  static getCollaborationRequests = (resourceType: TCollaborationRequest['resourceType'], resourceId: string) =>
    request<TCollaborationRequest[]>({
      url: `/CollaborationRequests/collaborations-for-resource`,
      params: { resourceId, resourceType },
    });

  static removeCollaborationRequest = (id: string) =>
    request({
      url: `CollaborationRequests/${id}/remove-collaboration`,
      method: 'DELETE',
    });

  static updateStatus = (id: string, status: TCollaborationRequest['status']) =>
    request({
      url: `/CollaborationRequests/${id}/upsert-status`,
      method: 'PATCH',
      data: {
        newStatus: status,
      },
    });
}
export default CollaborationRequestModel;
