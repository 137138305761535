import { Address } from './@types';

export const parseAddress = (res: Address): Address => {
  const address: Address = {
    ...res,
  };
  return address;
};

export const parseAddressFormData = (address: Address): Address => {
  const formData = {
    ...address,
  };
  return formData;
};
