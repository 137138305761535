import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, TextField, TextFieldProps } from '@material-ui/core';
import { IFieldProps } from 'react-forms';
import clsx from 'clsx';
import Typo from 'Components/Typo';
import { DIVIDER_BORDER } from 'Theme/themeConstants';

export interface ColorFieldProps extends IFieldProps {
  fieldProps?: {
    className?: string;
    label?: string;
    type?: 'hex';
  };
}

const ColorField: FC<ColorFieldProps> = (props) => {
  const { fieldProps, fieldConfig, formikProps } = props;
  const classes = useStyles({});
  const name = fieldConfig?.valueKey || '--';
  const className = fieldProps?.className || '';
  const label = fieldProps?.label || 'Hex value';
  const value = formikProps?.values?.[name] || '';
  const type = fieldProps?.type || 'hex';
  const error = formikProps?.errors?.[name] || '';

  const handleChange: TextFieldProps['onChange'] = (e) => {
    formikProps?.setFieldValue(name, e.target.value, true);
  };

  return (
    <Box className={clsx(classes.root, className)}>
      <div className={classes.color} style={{ backgroundColor: value }} />
      <Box display="flex" flexDirection="column">
        <Typo gutterBottom variant="caption" color="textSecondary">
          {label}
        </Typo>
        <TextField
          variant="outlined"
          name={name}
          value={value}
          error={!!error}
          helperText={error}
          onBlur={formikProps?.handleBlur}
          onChange={handleChange}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
  root: {
    display: 'flex',
    height: 66,
  },
  color: {
    border: DIVIDER_BORDER,
    borderRadius: 3,
    width: 66,
    height: 66,
    marginRight: 20,
  },
}));

export default ColorField;
