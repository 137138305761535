import { DialogProps, AppDialog } from 'mui-wrapper-utils';
import React, { FC, useState } from 'react';

interface ContextProps {
  open: boolean;
  title: string;
  content: React.ReactNode;
  showDialog: (content: React.ReactNode, options?: Partial<IAppDialog>) => void;
  hideDialog: () => void;
  setDialogContent: (content: React.ReactNode, options?: Partial<IAppDialog>) => void;
}

export const AppDialogContext = React.createContext<ContextProps>({
  open: false,
  title: '',
  content: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showDialog: (content: React.ReactNode, options?: Partial<IAppDialog>) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  hideDialog: () => {},
  setDialogContent: (content: React.ReactNode, options?: Partial<IAppDialog>) => {},
});

export interface IAppDialog extends DialogProps {
  content?: React.ReactNode;
}

export const useAppDialog = (initialState: IAppDialog): ContextProps & IAppDialog => {
  const [dialogState, setDialogState] = useState<IAppDialog>(initialState);

  const showDialog = (content: React.ReactNode, options?: Partial<IAppDialog>) => {
    setDialogState({
      ...dialogState,
      open: true,
      content,
      ...options,
    });
  };
  const hideDialog = () => {
    setDialogState((prev) => ({
      ...prev,
      PaperComponent: undefined,
      PaperProps: undefined,
      title: undefined,
      content: undefined,
      headerProps: undefined,
      actionsChildren: undefined,
      hideBackdrop: undefined,
      open: false,
    }));
  };
  const setDialogContent = (dialogContent: React.ReactNode, options?: Partial<IAppDialog>) => {
    setDialogState({
      ...dialogState,
      content: dialogContent,
      ...options,
    });
  };
  return { title: '', content: null, ...dialogState, showDialog, hideDialog, setDialogContent };
};

export const AppDialogProvider: FC = (props) => {
  const {
    content,
    title = '',
    showDialog,
    hideDialog,
    setDialogContent,
    open,
    ...appDialogProps
  } = useAppDialog({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleClose: () => {},
    title: '',
    open: false,
  });
  const { children } = props;
  return (
    <AppDialogContext.Provider
      value={{
        title,
        content,
        showDialog,
        hideDialog,
        setDialogContent,
        open,
      }}
    >
      {children}
      <AppDialog {...appDialogProps} open={open} title={title} handleClose={hideDialog}>
        {content}
      </AppDialog>
    </AppDialogContext.Provider>
  );
};
export default AppDialogProvider;
