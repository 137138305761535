import React, { FC } from 'react';
import { alpha, Badge, Box, ButtonBase, CircularProgress, createStyles, makeStyles, Theme, Tooltip, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import Spacer from 'Components/Spacer';
import NotifItem from './NotifItem';
import { FONT_FAMILY, THEME_PALETTE } from 'Theme/themeConstants';
import { useStoreActions, useStoreState } from 'Stores';
import PgButton from 'Components/PgButton';
import { NotificationTabs } from './types';
import Pagination from 'Components/Pagination';
import useAsyncTask from 'Hooks/useAsyncTask';
import { TNotification } from 'Models/App/@types';
import { ACCOUNTS_ROUTES } from 'Routes/AccountNavigation';
import { APP_HEADER_HEIGHT } from 'Features/AppHeader';
import { useMemo } from 'react';
import CustomDivider from 'Components/CustomDivider';

interface NotificationsListProps {
    currentTab: NotificationTabs;
    onTabChange: (tab: NotificationTabs) => void;
    notifications: TNotification[];
    shouldShowTabs: boolean;
    markAllAsRead: () => Promise<void>;
    sizeVariant?: 'large' | 'small';
    onActionClick?: (notification: TNotification, isAccepted?: boolean) => void;
    page?: number;
    onPageChange?: (page: number) => void;
    isLoading?: boolean;
    isLoadingCount?: boolean;
    count?: number;
    onNavigateAway?: () => void;
    onRead?: (id: string) => void;
    tabsWithNewNotification?: NotificationTabs[];
}

const NotificationsList: FC<NotificationsListProps> = ({
    currentTab,
    notifications,
    isLoading,
    count = 0,
    onTabChange,
    onActionClick,
    sizeVariant = 'small',
    isLoadingCount,
    shouldShowTabs,
    onPageChange,
    markAllAsRead,
    onNavigateAway,
    onRead,
    tabsWithNewNotification,
    page = 0,
}) => {
    const classes = styles();
    const { readNotification } = useStoreActions(({ UserStore: { readNotification } }) => ({
        readNotification,
    }));
    const markAllAsReadTask = useAsyncTask(markAllAsRead);
    const tabs: { title: string; id: NotificationTabs; hasNew?: boolean }[] = useMemo(
        () => [
            {
                title: 'All',
                id: 'all',
                hasNew: tabsWithNewNotification?.includes('all'),
            },
            {
                title: 'Inbox',
                id: 'personal',
                hasNew: tabsWithNewNotification?.includes('personal'),
            },
            {
                title: 'Communities',
                id: 'community',
                hasNew: tabsWithNewNotification?.includes('community'),
            },
        ],
        [tabsWithNewNotification],
    );

    return (
        <>
            <Box
                className={clsx(classes.headerContainer, {
                    [classes.horizontalPadding]: sizeVariant === 'small',
                    [classes.headerStickyLow]: sizeVariant === 'large',
                })}
            >
                <Box className={classes.notifMenuHeader}>
                    <PgTypo className={classes.heading} h7={sizeVariant === 'small'} h4={sizeVariant === 'large'}>
                        Notifications
                    </PgTypo>
                    <Spacer />
                    <Tooltip arrow title={'Mark all as read'} placement="left">
                        <Box>
                            <PgButton disabled={markAllAsReadTask.status === 'PROCESSING'} onClick={markAllAsReadTask.run} className={classes.iconButton}>
                                {markAllAsReadTask.status === 'PROCESSING' ? <CircularProgress size="small" /> : <PgIcon icon="icon-check" />}
                            </PgButton>
                        </Box>
                    </Tooltip>

                    <Spacer width={16} />
                    <PgButton onClick={onNavigateAway} href={ACCOUNTS_ROUTES.SETTINGS.path} className={classes.iconButton}>
                        <PgIcon icon="icon-settings" />
                    </PgButton>
                </Box>
                {shouldShowTabs ? (
                    <Box className={classes.tabsContainer}>
                        {tabs.map(({ title, id, hasNew }) => {
                            const titleElem = (
                                <PgTypo
                                    key={id}
                                    component={ButtonBase}
                                    onClick={() => {
                                        onTabChange(id);
                                    }}
                                    className={clsx(classes.tab, { [classes.tabActive]: currentTab === id })}
                                >
                                    {title}
                                </PgTypo>
                            );
                            return hasNew ? (
                                <StyledBadge invisible={false} variant="dot" key={id}>
                                    {titleElem}
                                </StyledBadge>
                            ) : (
                                titleElem
                            );
                        })}
                    </Box>
                ) : null}
            </Box>
            <Box className={clsx({ [classes.scrollContainer]: sizeVariant === 'small' })}>
                {isLoading ? (
                    <Box display="flex" justifyContent="center" p={1}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {notifications.length === 0 ? (
                            <Box
                                className={clsx(classes.centered, {
                                    [classes.minHeightLarge]: sizeVariant === 'large',
                                    [classes.minHeightSmall]: sizeVariant === 'small',
                                })}
                            >
                                <PgTypo className={classes.noNotifMessage}>You don’t have notifications.</PgTypo>
                            </Box>
                        ) : (
                            notifications.map((item, index) => (
                                <Box key={item.id} px={2}>
                                    <NotifItem
                                        notification={item}
                                        readNotif={async (id: string) => {
                                            await readNotification(id);
                                            onRead?.(id);
                                        }}
                                        onActionClick={(isAccepted) => onActionClick?.(item, isAccepted)}
                                    />
                                    {index !== notifications?.length - 1 || sizeVariant === 'large' ? <CustomDivider color={THEME_PALETTE.common.black} /> : null}
                                </Box>
                            ))
                        )}
                    </>
                )}
            </Box>
            {sizeVariant === 'large' && count > 0 ? (
                <Box display="flex" justifyContent="center" alignItems="center" pt={5}>
                    {isLoadingCount ? <CircularProgress /> : <Pagination page={page + 1} total={count} onChange={(_, page) => onPageChange?.(page - 1)} />}
                </Box>
            ) : null}
        </>
    );
};

export default NotificationsList;

const styles = makeStyles((theme) => ({
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    minHeightLarge: {
        minHeight: 'calc(100vh - 200px)',
    },
    minHeightSmall: {
        minHeight: 200,
    },
    headerContainer: {
        position: 'sticky',
        top: 0,
        background: theme.palette.common.white,
        zIndex: 1,
    },
    headerStickyLow: {
        top: APP_HEADER_HEIGHT,
    },
    heading: {
        fontFamily: FONT_FAMILY.secondary,
        fontWeight: theme.typography.fontWeightBold,
    },
    tabsContainer: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    iconButton: {
        padding: 0,
        minWidth: 'unset',
    },
    scrollContainer: {
        maxHeight: 280,
        overflow: 'auto',
        scrollbarColor: `${theme.palette.primary.main} transparent`,
        scrollbarWidth: 'thin',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 440,
        },
        '&::-webkit-scrollbar': {
            width: 4,
            display: 'block',
            marginRight: 4,
        },

        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },

        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
            borderRadius: 2,
        },

        '&::-webkit-scrollbar-thumb:hover': {
            background: alpha(theme.palette.primary.main, 0.8),
        },
    },
    horizontalPadding: {
        padding: theme.spacing(0, 2.5),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    noNotifMessage: {
        color: THEME_PALETTE.grey.A300,
        margin: 'auto 0',
        textAlign: 'center',
        padding: theme.spacing(4, 0),
    },
    notifMenuHeader: {
        display: 'flex',
        padding: theme.spacing(2.5, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2.5),
        },
    },
    tab: {
        padding: theme.spacing(1, 4),
        display: 'inline-block',
        fontFamily: FONT_FAMILY.secondary,
    },
    tabActive: {
        fontWeight: theme.typography.fontWeightBold,
        position: 'relative',
        '&::after': {
            content: "' '",
            background: theme.palette.common.black,
            bottom: -4,
            left: 0,
            position: 'absolute',
            height: 4,
            width: '100%',
        },
    },
}));

const StyledBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: {
            top: 20,
            right: 20,
            padding: 4,
            background: theme.palette.error.main,
            borderRadius: '50%',
        },
    }),
)(Badge);
