import { Box, BoxProps, Collapse, Switch, SwitchProps } from '@material-ui/core';
import PgTypo from 'Components/PgTypo';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { BORDER_BLACK } from 'Theme/themeConstants';
import helpers from 'Utils/helpers';
import { FormikProps } from 'formik';
import React, { FC } from 'react';
import { BuilderProps, IFieldProps, MLFormContent } from 'react-forms';

export interface WithSwitchConfigProps extends IFieldProps {
  fieldProps?: WithSwitchConfigFieldProps;
}

export interface WithSwitchConfigFieldProps extends Partial<BuilderProps> {
  title?: string;
  switchProps?: SwitchProps;
  rootProps?: BoxProps;
}

const WithSwitchConfig: FC<WithSwitchConfigProps> = (props) => {

  const { fieldProps = {}, formikProps, fieldConfig } = props;
  const { title, switchProps = {}, rootProps = {}, formId = 'with-collapsed-form-config', schema = [], actionConfig = { displayActions: false }, ...formContentProps } = fieldProps;

  const valueKey = fieldConfig?.valueKey ?? '';

  const { isDeviceSm } = useMediaQuery();

  const isOpen = helpers.getFieldValue(formikProps as FormikProps<any>, valueKey) || switchProps.checked;

  return (
    <Box borderBottom={!!isOpen ? BORDER_BLACK : undefined} {...rootProps}>
      <Box display='flex' alignItems='center' justifyContent='space-between' mb={!!isOpen ? 2 : undefined}>
        <PgTypo b1 typoWeight='fontWeightBold'>{title}</PgTypo>
        <Switch checked={!!isOpen} value={isOpen} onChange={(_, c) => formikProps?.setFieldValue(valueKey, c)} {...switchProps} />
      </Box>
      <Collapse in={!!isOpen}>
        {!!isOpen ? (
          <MLFormContent formId={formId} schema={schema} formikProps={formikProps} actionConfig={actionConfig} {...formContentProps} />
        ) : null}
      </Collapse>
    </Box>
  )
}

export default WithSwitchConfig;
