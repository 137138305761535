import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DialogWrapper from 'Components/DialogWrapper';
import React, { FC, useState } from 'react';
import SearchBar from 'Features/Search/SearchBar';
import DialogHeader from 'Dialogs/DialogHeader';
import { Box, IconButton } from '@material-ui/core';
import PgIcon from 'Components/PgIcon';

export interface SearchDialogProps { 
    open:boolean
    onClose:() => void
}

export const SearchDialog: FC<SearchDialogProps> = ({onClose , open}) =>{
    const classes = useStyles(); 
    return (
      <DialogWrapper PaperProps={{ className: classes.root }} open={open} onClose={onClose}>
        <Box p={2} display="flex" alignItems={'center'}>
          <IconButton onClick={onClose} >
            <PgIcon icon="icon-arrow-back" />
          </IconButton>
          <SearchBar onComplete={onClose} variant="dark" />
        </Box>
      </DialogWrapper>
    );
}

const useStyles = makeStyles<Theme>((theme) => {
    return (createStyles({
        root:{
            width : '100%',
            height : '100vh',
            background : theme.palette.background.paper
        }
    }))
})



const useSearchDialog = ( ) => {
    const [show , setShow] = useState(false);
    const toggleShow = () =>setShow(e => !e);
    return {
      toggleShow,
      SearchDialog: show ? <SearchDialog open={show} onClose={toggleShow} /> : null,
    };
}
export default useSearchDialog