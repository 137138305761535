import React, { FC } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

const MicOutlined: FC<SVGCIconProps> = (props) => {
  const { color = 'black', height = 20, width = 13, style } = props;
  return (
    <svg  width={width} height={height} viewBox={`0 0 ${width} ${height}`} style={style} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.33348 0C4.13375 0 2.35051 1.67663 2.35051 3.74487V12.216C2.35051 14.2842 4.13375 15.9608 6.33348 15.9608C8.53321 15.9608 10.3164 14.2842 10.3164 12.216V3.74487C10.3164 1.67664 8.53321 0 6.33348 0ZM9.27102 12.216V3.74487C9.27102 2.2195 7.95584 0.982933 6.33348 0.982933C4.71112 0.982933 3.39594 2.21949 3.39594 3.74487V12.216C3.39594 13.7413 4.71112 14.9779 6.33348 14.9779C7.95584 14.9779 9.27102 13.7413 9.27102 12.216Z"
        fill={color}
      />
      <path
        d="M6.85627 17.9823C9.83393 17.733 12.1667 15.3758 12.1667 12.5069V11.3056C12.1667 11.0342 11.9326 10.8141 11.644 10.8141C11.3553 10.8141 11.1212 11.0342 11.1212 11.3056V12.5069C11.1212 15.0012 8.97639 17.0211 6.33333 17.0211C3.69027 17.0211 1.54543 15.0012 1.54543 12.5069V11.3056C1.54543 11.0342 1.3114 10.8141 1.02271 10.8141C0.734027 10.8141 0.5 11.0342 0.5 11.3056V12.5069C0.5 15.3759 2.83296 17.7332 5.81084 17.9823V19.0171H3.01441C2.72573 19.0171 2.4917 19.2371 2.4917 19.5085C2.4917 19.78 2.72573 20 3.01441 20H9.65269C9.94138 20 10.1754 19.78 10.1754 19.5085C10.1754 19.2371 9.94138 19.0171 9.65269 19.0171H6.85627V17.9823Z"
        fill={color}
      />
    </svg>
  );
};

export default MicOutlined;
