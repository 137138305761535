import { Box, makeStyles, Theme, Tooltip } from '@material-ui/core';
import PgButton from 'Components/PgButton';
import PgTypo from 'Components/PgTypo';
import Tag from 'Components/Tag';
import ReportButton from 'Features/Report/ReportButton';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import DropModel from 'Models/Drop';
import React, { FC, useMemo } from 'react';
import { useStoreState } from 'Stores';
import { THEME_PALETTE } from 'Theme/themeConstants';
import { IEventPageProps } from './@types';
import { appNavigator } from 'Utils/routeUtils';

export interface IEventHeaderProps extends IEventPageProps {
  hideReport?: boolean;
  actionProps?: { label?: string; onClick?: () => void; isDisabled?: boolean };
}

const TagBadge: FC<IEventPageProps> = ({ event }) => {
  const tag = event.tags?.[0];

  if (!tag) return null;
  return (
    <Tag color={tag.background} size="small">
      {tag.name}
    </Tag>
  );
};

const Header: FC<IEventHeaderProps> = ({ event, hideReport, actionProps }) => {
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));

  const { isDeviceSm } = useMediaQuery();

  const isPublished = !!event?.isPublished;
  const tooltip = `This drop is not visible to other users.`;
  const classes = useStyles({ isPublished });

  const canEdit = useMemo(() => DropModel.canEditDrop(event, appUser), [appUser?.id, event.id, event.collaborators]);

  const handleEditClick = () => {
    if (!event?.community || !appUser) return;
    appNavigator.editEvent(event.slug ?? event.id, event.community?.slug ?? event.community.id);
  };

  return (
    <Box className={classes.eventTitle}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <TagBadge event={event} classes={classes} />
        {!!canEdit && (
          <Box display="flex" alignItems="center" justifyContent="space-evenly">
            <Tooltip title={isPublished ? '' : tooltip} placement="left">
              <PgTypo underline c3 className={classes.status}>
                {isPublished ? 'Published' : 'Unpublished'}
              </PgTypo>
            </Tooltip>
            <PgButton secondary onClick={actionProps?.onClick ?? handleEditClick} disabled={actionProps?.isDisabled}>
              <PgTypo b2>{actionProps?.label ?? 'EDIT'}</PgTypo>
            </PgButton>
          </Box>
        )}
      </Box>
      <PgTypo {...(isDeviceSm ? { h6: true } : { h2: true })}>{event.name}</PgTypo>
      <Box display="flex" pt={1.5} justifyContent="space-between" width="100%" alignItems="center" gridColumnGap={32}>
        <PgTypo {...(isDeviceSm ? { h7: true } : { h5: true })}>{event.subtitle}</PgTypo>
        {!hideReport && <ReportButton entity="Event" entityId={event.id} name={event.name} />}
      </Box>
    </Box>
  );
};

export default Header;

const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 0, 1.5),
    marginLeft: -4,
  },
  eventTitle: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  status: {
    color: THEME_PALETTE.grey.A900,
    marginRight: theme.spacing(2),
  },
}));
