import { MiddlewareAPI } from "redux";
import { TRootStore } from "../Stores";
import { ANALYTICS_IDENTIFY_USER, ANALYTICS_LOCATION_CHANGED, ANALYTICS_RESET_USER } from "./analyticActions";



function reduxActionMapping(action: { type: string, payload: any } & Record<string, any>, store: MiddlewareAPI) {
    // ADD LISTENERS TO YOUR REDUX ACTIONS
    const { dispatch } = store;

    const getRootStoreState = () => {
        return store.getState() as TRootStore;
    }


    switch (action.type) {
        // case '@action.App.locationChangeEvent': {
        //     dispatch({ type: ANALYTICS_LOCATION_CHANGED })
        //     break;
        // }
        case '@action.AuthStore.setIsAuthenticated': {
            const state = getRootStoreState();
            if (action.payload)
                dispatch({ type: ANALYTICS_IDENTIFY_USER, data: { userId: state.AuthStore.appUser?.id, traits: { email: state.AuthStore.appUser?.email } } })
            else
                dispatch({ type: ANALYTICS_RESET_USER })
            break
        }
    }
}


export default reduxActionMapping;