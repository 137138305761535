import React, { FC } from 'react';
import { withLink } from 'Components/Utils';
import { TDrop } from 'Models/Drop/@types';
import { TResource } from 'Models/Resource/@types';
import { generatePath, useParams } from 'react-router-dom';
import { APP_ROUTES } from 'Routes';

export interface IDropLinkProps {
  drop: TDrop;
}

const DropLink: FC<IDropLinkProps> = ({ drop, children }) => {
  // const isExternalLink = drop.doc_type !== 'Event';
  const { communityId } = useParams<{ communityId: string }>();

  const link =
    drop.doc_type === 'Event'
      ? generatePath(APP_ROUTES.EVENT_PAGE.path, {
        id: drop.id,
      })
      : generatePath(getResourcePath(drop as TResource), { id: drop.id, communityId });

  if (!link) return <div>{children}</div>;
  return withLink(children, link);
};

export default DropLink;

export const getResourcePath = (drop: TResource): string => {
  const contentType = drop.doc_type.toLowerCase();

  switch (contentType) {
    case 'video':
      return APP_ROUTES.VIDEO_DETAIL_PAGE.path;
      break;

    case 'article':
      return APP_ROUTES.ARTICLE_DETAIL_PAGE.path;
      break;

    case 'collect':
      return APP_ROUTES.MERCH_DETAIL_PAGE.path;
      break;

    case 'podcast':
      return APP_ROUTES.PODCAST_DETAIL_PAGE.path;

    case 'album':
      return APP_ROUTES.ALBUM_DETAIL_PAGE.path;

    case 'soundtrack':
      return APP_ROUTES.SOUNDTRACK_DETAIL_PAGE.path;

    default:
      return '';
      break;
  }
};
