import { LinearProgress } from '@material-ui/core';
import React, { FC, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { APP_ROUTES } from 'Routes';
import Page404 from '../Screens/Page404';
import lazyImport from '../lazy-import';

const Search = lazyImport('../Screens/Search');
const SearchMore = lazyImport('../Screens/SearchMore');

const SearchNavigation: FC = () => {
  return (
    <>
      <Suspense fallback={null}>
        <Switch>
          <Route path={BASE_PATH} exact component={Search} />
          <Route path={SEARCH_ROUTES.MORE.path} exact component={SearchMore} />

          <Route path="/*" exact component={Page404} />
        </Switch>
      </Suspense>
    </>
  );
};

export default SearchNavigation;

export const BASE_PATH = APP_ROUTES.SEARCH.path;

export const SEARCH_ROUTES = {
  MORE: {
    path: `${BASE_PATH}/more`,
  },
};
