/* eslint-disable react/require-default-props */
/* eslint-disable no-underscore-dangle */
import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, BoxProps, ButtonBase } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { User } from 'Models/User/@types';
import Typo from '../../Components/Typo';
import helpers from 'Utils/helpers';
import { getUserName } from 'Models/User/utils';
import UserAvatar from 'Components/UserAvatar';

export interface PeopleSearchResultItemProps extends Omit<BoxProps, 'onClick'> {
  user: User;
  onClick?: (user: User) => void;
  type?: 'community' | 'user';
}

const PeopleSearchResultItem: FC<PeopleSearchResultItemProps> = (props) => {
  const classes = useStyles();
  const { user, type = 'community', onClick, children, ...boxProps } = props;

  const history = useHistory();

  const handleClick = () => {
    if (onClick) {
      onClick(user);
    }
    // if (type === 'community') history.push(`/${user.slug || user.id}`);
    // else history.push(`/user/${user.slug || user.id}`);
    if (type === 'community') history.push(`/${user.slug || user.id}`);
    else history.push(`/user/${user.slug || user.id}`);
  };

  let name = getUserName(user);

  return (
    <Box {...boxProps} onClick={handleClick} component={ButtonBase}>
      <UserAvatar name={name} size={80} url={helpers.getPictureUrl(user?._profileImages?.[0])} />
      <Box pl={1} display="flex" flexDirection="column" alignItems="flex-start">
        {/* <Typo >{user.firstName + ' ' + user.lastName}</Typo> */}
        <Typo align="left" weight="bold" limitLines={2}>
          {name}
        </Typo>
        <Typo variant="overline" className={classes.subtitle} limitLines={1}>
          {user.subtitle}
        </Typo>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  subtitle: {
    // color: theme.palette.grey['200']
  },
}));

export default PeopleSearchResultItem;
