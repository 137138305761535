import { IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useInvitationDialog from 'Dialogs/Invitation/useInvitationDialog';
import useAuthDialog from 'Features/Auth/useAuthDialog';
import { TEventResourse } from 'Models/Resource/@types';
import usersModel, { InvitationTypes } from 'Models/User';
import React, { FC, useMemo } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { getResourceType } from 'Screens/ResourcesPage/ResourceViewModel';
import { ANALYTICS_USER_EVENT } from '../../Analytics/analyticActions';
import EventsModel from '../../Models/Event';
import { useStoreDispatch, useStoreState } from '../../Stores';
import SHARE from 'Assets/icons/share.svg';
// eslint-disable-next-line import/no-cycle

export interface EventShareButtonProps {
  // event: TEvent;
  event: TEventResourse;
  isDisabled?: boolean;
}

const EventShareButton: FC<EventShareButtonProps> = ({ event, isDisabled = false }) => {
  const classes = useStyles();
  const dispatch = useStoreDispatch();
  const { pathname } = useLocation();
  const { path } = useRouteMatch();
  const communityName = useMemo(() => usersModel.getUserName(event.community), [event.community]);
  const eventName = useMemo(() => (EventsModel.isEvent(event) ? event.name : event.title), [event]);
  const shareText = useMemo(
    () => `I’m going to ${eventName} via ${communityName} on Playground, and would love if you joined me! Here’s my invite link to RSVP:`,
    [eventName, communityName]
  );
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
  const { openDialog } = useAuthDialog();
  const { component: InvitationDialog, openInvitationDialog } = useInvitationDialog(
    getResourceType(path)?.slice(0, -1) as InvitationTypes,
    event.id,
    shareText
  );

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!appUser) {
      openDialog();
      return;
    }

    dispatch({ type: ANALYTICS_USER_EVENT, data: { eventName: 'SHARED_BUTTON_CLICKED', context: { event: eventName, source: pathname } } });
    openInvitationDialog();
  };
  return (
    <>
      <IconButton size="small" onClick={handleClick} className={classes.button} disabled={isDisabled}>
        {/* <CustomIcon variant="dark" icon={Share} size={30} /> */}
        {/* <PgIcon icon="icon-share" styleClass={classes.icon} /> */}
        <img src={SHARE} alt='share-icon' className={classes.icon} />
      </IconButton>
      {InvitationDialog}
    </>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    button: {
      padding: 0,
      // padding: '6px 3px',
    },
    icon: { width: theme.spacing(3), filter: theme.palette.type === 'dark' ? 'invert(100%)' : undefined },
  });
});

export default EventShareButton;
