import { action, Action, actionOn, ActionOn, thunk, Thunk } from 'easy-peasy';
import { omit } from 'lodash';
import AppModel from 'Models/App';
import DropModel from 'Models/Drop';
import { TCommunity } from 'Models/Community/@types';
import { TDrop } from 'Models/Drop/@types';
import { resourceIncludeFilter } from 'Models/Resource';
import { TRootStore } from 'Stores';
import { EVENT_FIELDS } from 'Models/Event/utils';

export interface LandingState {
  loading: boolean;
  setLoading: Action<LandingState, boolean>;

  drops: TDrop[];
  irlDrops: TDrop[];
  setDrops: Action<LandingState, TDrop[]>;
  setIrlDrops: Action<LandingState, TDrop[]>;

  communities: TCommunity[];
  setCommunities: Action<LandingState, TCommunity[]>;

  fetchEntities: Thunk<LandingState>;

  onContentFavorite: ActionOn<LandingState, TRootStore>;
  onContentUnFavorite: ActionOn<LandingState, TRootStore>;
  onEventRemind: ActionOn<LandingState, TRootStore>;
  onEventUnRemind: ActionOn<LandingState, TRootStore>;
}

const LandingStore: LandingState = {
  loading: true,
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),

  drops: [],
  irlDrops: [],

  setDrops: action((state, payload) => {
    state.drops = payload;
  }),

  setIrlDrops: action((state, payload) => {
    state.irlDrops = payload;
  }),

  communities: [],
  setCommunities: action((state, payload) => {
    state.communities = payload;
  }),

  fetchEntities: thunk(async (actions) => {
    actions.setLoading(true);
    try {
      const data = await AppModel.fetchDiscoverSummary({ filter: DISCOVER_FILTER });
      actions.setCommunities(data.communities);
      actions.setDrops(data.featuredDrops);
      const offlineDropsData = await DropModel.search(undefined, { filter: IRL_FILTER });
      actions.setIrlDrops(offlineDropsData.results);
    } catch (error) {
      // error handling
    }
    actions.setLoading(false);
  }),

  onContentFavorite: actionOn(
    (actions, storeActions) => storeActions.AuthStore.contentFavorited,
    (state, { payload: { event, favouriteId } }) => {
      state.drops = state.drops.map((e) => (e.id === event.id ? { ...e, ...event, isFavourited: true, favouriteId } : e)) as TDrop[];
    },
  ),
  onContentUnFavorite: actionOn(
    (actions, storeActions) => storeActions.AuthStore.contentUnFavorited,
    (state, { payload }) => {
      state.drops = state.drops.map((e) => (e.id === payload ? omit(e, ['isFavourited', 'favouriteId']) : e)) as TDrop[];
    },
  ),
  onEventRemind: actionOn(
    (actions, storeActions) => storeActions.AuthStore.eventReminded,
    (state, { payload: { event, reminderId } }) => {
      state.drops = state.drops.map((e) => (e.id === event.id ? { ...e, ...event, isReminded: true, reminderId } : e));
    },
  ),
  onEventUnRemind: actionOn(
    (actions, storeActions) => storeActions.AuthStore.eventUnReminded,
    (state, { payload }) => {
      state.drops = state.drops.map((e) => (e.reminderId === payload ? omit(e, ['isReminded', 'reminderId']) : e)) as TDrop[];
    },
  ),
};

export default LandingStore;

const DISCOVER_FILTER = {
  event: {
    limit: 8,
    include: ['community', 'tags', 'collaborationRequestInfo', 'collaborators'],
    order: 'eventDates.startDate+ASC',
    fields: EVENT_FIELDS,
    where: {
      type: 'online',
      featured: true,
      'eventDates.startDate': { gte: new Date() },
    },
  },
  Video: resourceIncludeFilter,
  Collect: resourceIncludeFilter,
  Podcast: resourceIncludeFilter,
  Article: resourceIncludeFilter,
  community: {
    limit: 50,
    where: { featured: true },
    fields: ['id', 'name', 'subtitle', 'slug'],
  },
};

const IRL_FILTER = {
  limit: 8,
  order: 'eventDates.startDate+ASC',
  when: { startDate: new Date() },
  where: { type: 'offline', featured: true },
  Event: { fields: EVENT_FIELDS, include: ['community', 'tags', 'ticketingInfo', 'collaborators'] },
};
