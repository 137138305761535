import TagModel from 'Models/Tags';
import { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'Stores';
import utils from 'Utils';
import UserModel, { User } from '../../Models/User';
import { WalletType } from '../../Models/Web3/@types';
import { usePgWalletProvider } from '../../Web3';
import EthereumRpc from '../../Web3/utils/evm.ethers';

function useAppInit(): { init: () => void; loading: boolean } {
  const { authenticate, setTags, fetchAppConstants, fetchTagsSummary, setAppUser } = useStoreActions(
    ({ AuthStore: { authenticate, setAppUser }, App: { setTags, fetchAppConstants, fetchTagsSummary } }) => ({
      authenticate,
      setTags,
      fetchAppConstants,
      fetchTagsSummary,
      setAppUser,
    }),
  );

  const [loading, setLoading] = useState(true);

  const init = async () => {
    setLoading(true);
    try {
      await Promise.all([login(), fetchTags(), fetchAppConstants(), fetchTagsSummary()]);
      // eslint-disable-next-line no-empty
    } catch (error) {}
    setLoading(false);
  };

  const login = async () => {
    const accessToken = utils.getAccessToken();
    if (!accessToken) return;
    const user = await authenticate({
      token: accessToken,
    });
  };

  const fetchTags = async () => {
    const res = await TagModel.getTagsList();
    setTags(res.results.map((r) => r.hit));
  };

  return { init, loading };
}

export default useAppInit;
