import { Link, LinkProps } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useStoreState } from 'Stores';
import ReportIcon from 'Assets/icons/report.svg';
import useAuthDialog from 'Features/Auth/useAuthDialog';
import { TContentType } from 'Models/Drop/@types';

export type TReportEntity = TContentType | 'user' | 'community';

export interface ReportButtonProps extends LinkProps {
  entity: TReportEntity;
  entityId: string;
  name?: string;
}

const ReportButton: FC<ReportButtonProps> = (props) => {
  const classes = useStyles();
  const { entity, entityId, name = '', ...buttonProps } = props;
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
  const { openDialog } = useAuthDialog();
  const link = useMemo(() => {
    return `/report?entity=${entity}&entityId=${entityId}&name=${name}`;
  }, [entity, entityId, name]);
  const history = useHistory();
  const onClick = () => {
    if (!appUser) {
      openDialog();
      return;
    }
    history.push(link);
  };
  return (
    <Link onClick={onClick} target="_parent" underline="always" {...buttonProps} className={clsx(classes.reportButton, buttonProps.className)}>
      {/* {'Report'} */}
      <img src={ReportIcon} alt={`Report ${name}`} />
    </Link>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    reportButton: {
      color: theme.palette.error.main,
      cursor: 'pointer',
    },
  });
});

export default ReportButton;
