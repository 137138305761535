import { createStyles, makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import React, { FC } from 'react';
import { BORDER_BLACK } from 'Theme/themeConstants';

export interface TabNavProps {
  currentTab: number;
  onTabChange: (val: number) => void;
  hiddenTabIds?: number[];
}

const TABS = [
  { id: 0, label: 'invite link' },
  { id: 1, label: 'invite via Email' },
  { id: 2, label: 'import csv' },
];

const TabNav: FC<TabNavProps> = (props) => {
  const { currentTab, onTabChange, hiddenTabIds } = props;
  const classes = useStyles();
  return (
    <Tabs className={classes.root} variant="scrollable" indicatorColor="primary" value={currentTab} onChange={(e, val) => onTabChange(val)}>
      {TABS.filter((t) => !hiddenTabIds?.find((h) => h === t.id)).map((t) => {
        return <Tab key={t.id} id={`invite-dialog-tab-${t.id}`} value={t.id} label={t.label} className={classes.tab} />;
      })}
    </Tabs>
  );
};

export default TabNav;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { borderBottom: BORDER_BLACK },
    tab: { fontSize: 11 },
  }),
);
