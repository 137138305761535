/* eslint-disable jsx-a11y/alt-text */
import React, { createRef, FC } from 'react';
import { SnackbarProvider, SnackbarKey } from 'notistack';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { THEME_PALETTE } from 'Theme/themeConstants';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import SUCCESS from 'Assets/icons/ToastIcons/toast_icon_success.svg';
import ERROR from 'Assets/icons/ToastIcons/toast_icon_error.svg';
import INFO from 'Assets/icons/ToastIcons/toast_icon_info.svg';

const EnqueToastProvider: FC = ({ children }) => {
  const classes = useStyles();
  const snackbarRef = createRef<SnackbarProvider>();
  const handleClose = (key: SnackbarKey) => {
    snackbarRef.current?.closeSnackbar(key);
  };
  return (
    <SnackbarProvider
      ref={snackbarRef}
      className="toast-snackbar" // Used in auth dialog content
      maxSnack={3}
      action={(key) => (
        <IconButton color="inherit" onClick={() => handleClose(key)}>
          <Close fontSize="small" color="inherit" />
        </IconButton>
      )}
      classes={{
        variantSuccess: classes.success,
        variantInfo: classes.info,
        variantError: classes.error,
        root: classes.root,
      }}
      iconVariant={{
        success: <img src={SUCCESS} alt="success" style={{ marginRight: 15, width: 20 }} />,
        info: <img src={INFO} alt="info" style={{ marginRight: 15, width: 20 }} />,
        error: <img src={ERROR} alt="error" style={{ marginRight: 15, width: 20 }} />,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('sm')]: {
        '& .toast-snackbar': {
          flexWrap: 'unset',
          alignItems: 'flex-start',
          '& > div:last-child': { paddingLeft: 'unset' },
          '& > div:first-child': { alignItems: 'flex-start' },
        },
      },
    },
    success: {
      // background: THEME_PALETTE.primary.main + ' !important',
      background: `${THEME_PALETTE.success.secondary} !important`,
      color: theme.palette.common.black,
      // border: '1px solid white',
    },
    info: {
      background: `${THEME_PALETTE.secondary.main} !important`,
    },
    error: {
      backgroundColor: `${THEME_PALETTE.error.light} !important`,
    },
  }),
);

export default EnqueToastProvider;
