import { Box, Drawer, IconButton, List, ListItem, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuDots from 'Assets/icons/menu-dots.svg';
import replyIcon from 'Assets/icons/reply.svg';
import useAsyncTask from 'Hooks/useAsyncTask';
import useConfirmationDialog from 'Hooks/useConfirmationDialog';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { TMessage } from 'Models/Discussion/@types';
import { getUserName } from 'Models/User/utils';
import React, { FC, useMemo, useState } from 'react';
import { useStoreActions, useStoreState } from 'Stores';
import { BORDER_BLACK } from 'Theme/themeConstants';
import { TDiscussionConfig } from './useDiscussion';
import useUserActions from './useUserActions';

export interface MessageActionsProps {
    message: TMessage;
    isCreator?: boolean;
    replyButtonProps?: {
        disabled: boolean;
        onClick?: () => void;
    };
    onReplyClick?: () => void;
    visible?: boolean;
    handlePinUnpin?: (message: TMessage) => Promise<void>;
    config: TDiscussionConfig;
    onClose?: () => void;
}
export type TMessageMenuItem = {
    label: string;
    onClick: () => void;
    id: string;
    hidden?: boolean;
};
const MessageActions: FC<MessageActionsProps> = ({ replyButtonProps, message, isCreator, visible = false, config, handlePinUnpin, onClose }) => {
    const classes = useStyles({ visible });
    const { blockUser, removeUser, unBlockUser, joinUser } = useUserActions(config);
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
    const { appUser, community, discussions } = useStoreState(
        ({ AuthStore: { appUser }, CommunityStore: { community }, DiscussionStore: { discussions } }) => ({
            appUser,
            community,
            discussions,
        }),
    );
    const { hideMessage, deleteMessage } = useStoreActions(({ DiscussionStore: { hideMessage, deleteMessage } }) => ({
        hideMessage,
        deleteMessage,
    }));

    const toggleMenu = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (e) setMenuAnchorEl(e.currentTarget);
        else {
            setMenuAnchorEl(null);
            onClose?.();
        }
    };
    const isSelf = message.userId === appUser?.id;
    const isBlocked = community?.bannedUserIds?.includes(message.userId);
    const isJoined = useMemo(() => {
        return discussions.find((i) => i.id === config.activeDiscussionId)?.memberIds.includes(message.userId);
    }, [discussions, message.userId, config.activeDiscussionId]);
    const { isDeviceSm } = useMediaQuery();

    const messageMenuItems: TMessageMenuItem[] = [
        {
            label: 'Unpin comment',
            id: 'un-pin',
            onClick: () => handlePinUnpin?.(message),
            hidden: !isCreator || !message.isPinned || !!message.parentMessageId || message.isDeleted,
        },
        {
            label: 'Pin to top',
            id: 'pin',
            onClick: () => handlePinUnpin?.(message),
            hidden: !!message.isPinned || !isCreator || !!message.parentMessageId || message.isDeleted || message.isHidden,
        },
        {
            id: 'reply',
            hidden: !!message.parentMessageId || replyButtonProps?.disabled || !isDeviceSm || !message.user,
            onClick: () => {
                replyButtonProps?.onClick?.();
                toggleMenu();
            },
            label: 'Reply',
        },
        {
            label: 'Delete comment',
            hidden: message.isDeleted || (!isCreator && !isSelf),
            id: 'delete-msg',
            onClick: () => deleteMessageTask.run({}),
        },
        {
            label: 'Hide from public',
            id: 'hide-msg',
            onClick: () => hideUnhideMessage.run({}),
            hidden: !isCreator || message.isHidden || message.isDeleted,
        },
        {
            label: 'Unhide comment',
            id: 'un-hide-msg',
            onClick: () => hideUnhideMessage.run({}),
            hidden: !isCreator || !message.isHidden || message.isDeleted,
        },
        {
            label: 'View member profile',
            id: 'view-profile',
            onClick: () => {
                window.location.href = `/user/${message.user?.slug || message.userId}`;
            },
            hidden: !message.user,
        },

        {
            label: 'Remove from conversation',
            id: 'remove',
            onClick: () => {
                if (message.userId && message.user) removeUser.run(message.user);
            },
            hidden: !isCreator || isSelf || config.subject !== 'Community' || isBlocked || !isJoined || !message.user,
        },
        {
            label: 'Add to conversation',
            onClick: () => {
                if (message.userId && message.user) joinUser.run(message.user);
            },
            hidden: !isCreator || isSelf || isBlocked || isJoined,
            id: 'Add-member',
        },
        isBlocked
            ? {
                label: 'UnBlock member',
                id: 'un-block',
                onClick: () => {
                    if (message.userId && message.user) unBlockUser.run(message.user);
                },
                hidden: !isCreator || isSelf || config.subject !== 'Community',
            }
            : {
                label: 'Block member',
                id: 'block',
                onClick: () => {
                    if (message.userId && message.user) blockUser.run(message.user);
                },
                hidden: !isCreator || isSelf || config.subject !== 'Community' || !message.user,
            },
        {
            label: 'Report',
            id: 'report',
            onClick: () => {
                window.location.href = `/report?entity=user&entityId=${message.userId}&name=${getUserName(message.user)}`;
            },
            hidden: isSelf || !message.user,
        },
    ];
    const handleMenuClick = (item: TMessageMenuItem) => {
        item.onClick();
        toggleMenu();
    };
    const withConfirm = useConfirmationDialog();
    const hideUnhideMessage = useAsyncTask(async () => {
        withConfirm(
            async () => {
                await hideMessage({ discussionId: message.discussionId, message });
            },
            message.isHidden ? UnhideConfirmationDialogTextCopy : HideConfirmationDialogTextCopy,
            {
                successToastMessage: message.isHidden ? 'Message Unhidden' : 'Message Hidden',
            },
        );
    });
    const deleteMessageTask = useAsyncTask(async () => {
        withConfirm(
            async () => {
                await deleteMessage({ discussionId: message.discussionId, message });
            },
            isCreator ? DeleteByAdminConfirmationDialogTextCopy : DeleteConfirmationDialogTextCopy,
            {
                successToastMessage: message.isDeleted ? 'Message restored' : 'Message deleted',
            },
        );
    });

    const open = Boolean(menuAnchorEl);
    return (
        <Box className={classes.msgActions}>
            {!message.parentMessageId && !isDeviceSm && !replyButtonProps?.disabled && message.user ? (
                <IconButton disabled={replyButtonProps?.disabled || false} onClick={replyButtonProps?.onClick} size="small">
                    <img src={replyIcon} style={{ height: 15 }} />
                </IconButton>
            ) : null}
            <IconButton className={classes.msgMenu} onClick={toggleMenu} size="small">
                <img src={MenuDots} alt="menu" className={classes.menuImg} />
            </IconButton>
            {isDeviceSm && open ? (
                <Drawer
                    onClose={() => toggleMenu()}
                    open={open}
                    anchor="bottom"
                    style={{
                        zIndex: 1315,
                    }}
                    PaperProps={{
                        className: classes.drawerPaper,
                    }}
                >
                    <List className={classes.list}>
                        {messageMenuItems.map((item) => {
                            return item.hidden ? null : (
                                <ListItem className={classes.listItem} key={item.id} onClick={() => handleMenuClick(item)} button>
                                    <ListItemText primary={item.label} />
                                </ListItem>
                            );
                        })}
                    </List>
                </Drawer>
            ) : (
                <Menu
                    id="msg-menu"
                    getContentAnchorEl={null}
                    style={{
                        zIndex: 1315,
                        // Had to hardcode it hear , as jss styling was not taking !important.
                        // And the app header has a zIndex of 1301 to be usable when login dialog is in use.
                    }}
                    anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
                    anchorEl={menuAnchorEl}
                    onClose={() => toggleMenu()}
                    open={open}
                >
                    {messageMenuItems.map((item) =>
                        item.hidden ? null : (
                            <MenuItem key={item.id} onClick={() => handleMenuClick(item)}>
                                {item.label}
                            </MenuItem>
                        ),
                    )}
                </Menu>
            )}
        </Box>
    );
};

const useStyles = makeStyles<Theme, { visible?: boolean }>((theme) => {
    return createStyles({
        msgActions: {
            // display: (props) => (props.visible ? 'flex' : 'none'),

            transition: 'linear 3ms',
            //   position: 'absolute',
            //   right: 0,
            //   [theme.breakpoints.up('sm')]: {
            //     top: -4,
            //   },
            //   [theme.breakpoints.down('sm')]: {
            //     bottom: 0,
            //   },
            display: 'flex',
            alignItems: 'center',
        },
        drawerPaper: {
            width: '100%',
        },
        msgMenu: {
            padding: `8px 14px`, // theme.spacing(1,),
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
                padding: theme.spacing(1, 1.5),
            },
        },
        menuImg: {
            width: 4,
            height: 15,
            [theme.breakpoints.down('sm')]: {
                height: 15,
            },
        },
        list: {
            padding: 0,
        },
        listItem: {
            borderBottom: BORDER_BLACK,
        },
    });
});

export default MessageActions;

const UnhideConfirmationDialogTextCopy = {
    agreeText: 'UNHIDE',
    body: 'Unhide this comment from the public.',
    title: 'Unhide comment',
};
const HideConfirmationDialogTextCopy = {
    agreeText: 'HIDE',
    body: 'Hide this comment from the public. You will still be able to see it or take action on it.',
    title: 'Hide comment',
};
const DeleteConfirmationDialogTextCopy = {
    agreeText: 'DELETE',
    body: 'Remove this comment from the conversation. You will no longer be able to see or take action on it.',
    title: 'Delete comment',
};
const DeleteByAdminConfirmationDialogTextCopy = {
    agreeText: 'DELETE',
    body: 'Remove this comment from the conversation. You will no longer be able to see or take action on it.',
    title: 'Delete comment',
};
