import axios from 'axios';
import { TEventRegistration } from './@types';

class EventRegistrationModel {
    static baseUrl = '/eventRegistrations';

    static createNewEventRegistration(data: Partial<TEventRegistration>) {
        return axios.request({
            url: this.baseUrl,
            method: 'POST',
            data
        })
    }
}

export default EventRegistrationModel;
