/* eslint-disable no-useless-catch */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import useToastMessage from 'Hooks/useToastMessage';
import DropModel from 'Models/Drop';
import { OnDisplayEntity, TContentType, TDrop } from 'Models/Drop/@types';
import { TEvent } from 'Models/Event';
import { TResource } from 'Models/Resource/@types';

export function useReplayActions() {
  const withToast = useToastMessage();

  const handleReplayActions = async (drop: TDrop, dropType: TContentType, entities: OnDisplayEntity[]) => {
    try {
      if (!drop) return;
      const dropName = (drop as TEvent)?.name ?? (drop as TResource)?.title;
      await withToast(
        async () => {
          if (drop.isReplayed) {
            const removeReplayData = entities
              .map((r) => {
                const _entity = drop.replayedOn.find((e) => e.onDisplayEntityId === r.onDisplayEntityId);
                if (_entity) return _entity.id;
              })
              .filter((f) => f) as string[];
            await DropModel.removeReplayBulk(removeReplayData);
          } else {
            await DropModel.createReplayBulk(drop.id, dropType, entities);
          }
        },
        {
          successToastMessage: drop.isReplayed ? `Replay removed successfully.` : `${dropName} replayed successfully.`,
        },
      );
    } catch (error) {
      // error handling
      throw error;
    }
  };

  return {
    handleReplayActions,
  };
}

export default useReplayActions;
