export const ticketingHeader = {
  type: 'header',
  valueKey: 'section-header-1',
  fieldProps: {
    title: 'Ticketing Instructions',
    subtitle: 'Share any ticketing details attendees should know.',
  },
};

export const ticketingDescription = (valueKey: string, captionCharactersLeft?: number) => ({
  // type: 'rte-quill',
  // valueKey,
  // fieldProps: {
  //   sizes: [],
  //   toolbarProps: {
  //     toolbarOptions: ['size', 'lists'],
  //     formats: ['bold', 'italic', 'underline', 'link'],
  //   },
  // },

  type: 'text',
  valueKey,
  fieldProps: {
    variant: 'outlined',
    // label: 'Description',
    helperText: captionCharactersLeft
      ? `${captionCharactersLeft > 0 ? captionCharactersLeft : 0} character${captionCharactersLeft !== 1 ? 's' : ''} left`
      : '',
    // FormHelperTextProps: {
    //   className: classes.helperText,
    // },
    multiline: true,
    rows: 4,
    fullWidth: true,
    // placeholder: 'Photograph (C) Artist. Courtesy of Creator.',
  },
});
