import React, { FC } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Redirect, Route, RouteProps } from 'react-router';
import { APP_ROUTES } from 'Routes';
import { useStoreState } from 'Stores';

export type PrivateRouteProps = RouteProps;

const PrivateRoute: FC<PrivateRouteProps> = (props) => {
  const { isAuthenticated } = useStoreState(({ AuthStore: { isAuthenticated } }) => ({ isAuthenticated }));
  if (isAuthenticated) return <Route {...props} />;
  const redirectPath = props.location?.pathname;
  return <Redirect to={`${APP_ROUTES.LOGIN.path}/${redirectPath ? `?redirectTo=${redirectPath}` : ''}`} />;
};

export default PrivateRoute;
