import React, { FC, Suspense, useEffect } from 'react';
import { Route, Switch, useParams, useLocation } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import { useStoreActions, useStoreState } from 'Stores';
// eslint-disable-next-line import/no-cycle
import Loader from 'Components/Loader';
import { ResourceType } from 'Models/Resource/@types';
import { APP_ROUTES } from 'Routes';
import lazyImport from '../../lazy-import';
import Page404 from '../../Screens/Page404';

const ResourceUploadForm = lazyImport('../Screens/Dashboard/UploadsForm');

export const getResourceStoreConfig = (type: ResourceType) => {
  if (type === 'video') {
    return {
      storeName: 'WatchStore',
      fetchDetailMethod: 'fetchVideoDetail',
      loading: 'loadingDetails',
      detailInstanceKey: 'videoDetail',
      setter: 'setVideoDetail',
    };
  }
  if (type === 'podcast') {
    return {
      storeName: 'ListenStore',
      fetchDetailMethod: 'fetchAudioDetail',
      loading: 'loadingDetails',
      detailInstanceKey: 'audioDetail',
      setter: 'setAudioDetail',
    };
  }
  if (type === 'article') {
    return {
      storeName: 'ReadStore',
      fetchDetailMethod: 'fetchArticleDetail',
      loading: 'loadingDetails',
      detailInstanceKey: 'articleDetail',
      setter: 'setArticleDetail',
    };
  }
  if (type === 'collect') {
    return {
      storeName: 'CollectStore',
      fetchDetailMethod: 'fetchCollectDetail',
      loading: 'loadingDetails',
      detailInstanceKey: 'collectDetail',
      setter: 'setCollectDetail',
    };
  }
  if (type === 'album')
    return { storeName: 'AlbumStore', fetchDetailMethod: 'fetchAlbumDetail', loading: 'loadingDetails', detailInstanceKey: 'albumDetail', setter: 'setAlbumDetail' };
  if (type === 'soundtrack')
    return {
      storeName: 'SoundtrackStore',
      fetchDetailMethod: 'fetchSoundtrackDetail',
      loading: 'loadingDetails',
      detailInstanceKey: 'soundtrackDetail',
      setter: 'setSoundtrackDetail',
    };
  return {
    storeName: 'WatchStore',
    fetchDetailMethod: 'fetchVideoDetail',
    loading: 'loadingDetails',
    detailInstanceKey: 'videoDetail',
    setter: 'setVideoDetail',
  };
};

const ResourceNavigation: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();
  const resourceType = getResourceTypeFromPathName(pathname);
  const storeConfig = getResourceStoreConfig(resourceType);
  // TODO Handle it in a better way
  const fetchDetail = useStoreActions((actions: any) => actions[storeConfig.storeName][storeConfig.fetchDetailMethod]);
  const loading = useStoreState((state: any) => state[storeConfig.storeName][storeConfig.loading]);

  useEffect(() => {
    fetchDetail({ id });
  }, [id]);

  return loading ? (
    <Loader fullScreen />
  ) : (
    <>
      <Suspense fallback={null}>
        <Switch>
          <Route path={RESOURCE_ROUTES.RESOURCE_WATCH_EDIT.path} component={ResourceUploadForm} />
          <Route path={RESOURCE_ROUTES.RESOURCE_LISTEN_EDIT.path} component={ResourceUploadForm} />
          <Route path={RESOURCE_ROUTES.RESOURCE_READ_EDIT.path} component={ResourceUploadForm} />
          <Route path={RESOURCE_ROUTES.RESOURCE_COLLECT_EDIT.path} component={ResourceUploadForm} />
          <Route path="/*" exact component={Page404} />
        </Switch>
      </Suspense>
    </>
  );
};

export default ResourceNavigation;

export const getResourceTypeFromPathName = (pathName: string): ResourceType => {
  if (/\/watch\//.test(pathName)) return 'video';
  if (/\/listen\//.test(pathName)) return 'podcast';
  if (/\/read\//.test(pathName)) return 'article';
  if (/\/collect\//.test(pathName)) return 'collect';
  return 'video';
};

export const FORM_BASE_PATH = `${APP_ROUTES.DASHBOARD_HOME.path}/events-and-media`;
export const WATCH_EDIT_PATH = `${FORM_BASE_PATH}/watch/:id/edit`;
export const LISTEN_EDIT_PATH = `${FORM_BASE_PATH}/listen/:id/edit`;
export const READ_EDIT_PATH = `${FORM_BASE_PATH}/read/:id/edit`;
export const COLLECT_EDIT_PATH = `${FORM_BASE_PATH}/collect/:id/edit`;

export const RESOURCE_ROUTES = {
  RESOURCE_WATCH_EDIT: {
    path: WATCH_EDIT_PATH,
  },
  RESOURCE_LISTEN_EDIT: {
    path: LISTEN_EDIT_PATH,
  },
  RESOURCE_READ_EDIT: {
    path: READ_EDIT_PATH,
  },
  RESOURCE_COLLECT_EDIT: {
    path: COLLECT_EDIT_PATH,
  },
};
