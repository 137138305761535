import { Box } from '@material-ui/core';
import Typo from 'Components/Typo';
import { User } from 'Models/User/@types';
import React, { FC, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-cycle
import { SearchMemberListProps } from 'Features/Discussion/ChatMembersContent';
import helpers from 'Utils/helpers';
import { DiscussionDialogBaseProps } from './DiscussionAboutDialog';
import MemberListItem from './MemberListItem';

export interface SearchMembersListProps extends DiscussionDialogBaseProps {
  searchList: SearchMemberListProps[];
  onlineUserIds:string[]
}

const SearchMembersList: FC<SearchMembersListProps> = ({ discussion, config, isAdmin, searchList }) => {
  const [joinedUsers, setJoinedUsers] = useState<SearchMemberListProps[]>([]);
  const [nonJoinedUsers, setNonJoinedUsers] = useState<SearchMemberListProps[]>([]);
  useEffect(() => {
    setJoinedUsers(searchList.filter(({ user }) => discussion.memberIds.includes(user.id)));
    setNonJoinedUsers(searchList.filter(({ user }) => !discussion.memberIds.includes(user.id)));
  }, [searchList]);
  const handleChange = (action: 'remove' | 'add' | 'block' | 'unblock', user: User) => {
    // if (action === 'block') setSearchList(searchList.filter((i) => i.id !== user.id));
    switch (action) {
      case 'remove':
      case 'add': {
        setNonJoinedUsers((e) => {
          const updatedList =  helpers.toggleItemFromList(e.map((x) => x.user), user);
          return updatedList.map((x) => ({
            user: x,
            type: e.filter((i) => i.user.id === x.id)?.[0]?.type || 'offline',
          }))
            
        });
        setJoinedUsers((e) => {
          const updatedList =  helpers.toggleItemFromList(e.map((x) => x.user), user);
          return updatedList.map((x) => ({
            user: x,
            type: e.filter((i) => i.user.id === x.id)?.[0]?.type || 'offline',
          }));
        });
        break;
      }
      case 'block':
        setJoinedUsers((e) => {
          const updatedList =  helpers.toggleItemFromList(e.map((x) => x.user), user);
          return updatedList.map((x) => ({
            user: x,
            type: e.filter((i) => i.user.id === x.id)?.[0]?.type || 'offline',
          }));
        });
        break;
      default:
        break;
    }
  };
  return (
    <Box display="flex" flexDirection="column" pt={2}>
      {(joinedUsers.length || nonJoinedUsers.length)  ? (
        <Box overflow="auto">
          {joinedUsers.length ? (
            <Box my={1}>
              <Typo variant="subtitle2" weight="medium">
                In this channel
              </Typo>
            </Box>
          ) : null}
          {joinedUsers.map(({ user, type }) => {
            return (
              <Box pb={1} mt={1} key={user.id}>
                <MemberListItem member={user} isOnline={type === 'online'} config={config} onUserChange={handleChange} />
              </Box>
            );
          })}
          {nonJoinedUsers.length ? (
            <Box my={1}>
              <Typo variant="subtitle2" weight="medium">
                Not in this channel
              </Typo>
            </Box>
          ) : null}
          {nonJoinedUsers.map(({ user, type }) => {
            return (
              <Box pb={1} mt={1} key={user.id}>
                <MemberListItem member={user} isOnline={type === 'online'} config={config} onUserChange={handleChange} />
              </Box>
            );
          })}
        </Box>
      ) : null}
    </Box>
  );
};


export default SearchMembersList;
