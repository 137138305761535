export interface TTimeZone {
  code: string;
  country: string;
  timezone: string;
  offset: string;
  abbreviation: string;
  name?: string;
}

export const TimeZones = {
  getTimeZoneOptions(): { label: string; key: string }[] {
    return timeZones.map((i) => ({
      label: i.timezone.replace(/\//g, ' '),
      key: i.timezone,
    }));
  },
  getOffset(timeZone: string): string {
    const index = timeZones.findIndex((i) => i.timezone === timeZone);
    return timeZones[index].offset;
  },
  getTZItem(offset: string): TTimeZone | undefined {
    return timeZones.find((x) => x.offset === offset);
  },
};
export const getTimeZoneOptions = (tz = timeZones): TTimeZone[] => tz;
//     .map(i => {
//     return {
//         label: `${i.country}: ${i.timezone}`,
//         key: JSON.stringify(i)
//     }
// })

export const getOffset = (timeZone: string): string => {
  const index = timeZones.findIndex((i) => i.timezone === timeZone);
  return timeZones[index].offset;
};

export const getTimezone = (timeZone: string): TTimeZone => {
  // eslint-disable-next-line no-underscore-dangle
  let _timeZone = timeZone;
  if (timeZone.includes('Calcutta')) _timeZone = 'Asia/Kolkata';
  const index = timeZones.findIndex((i) => i.timezone === _timeZone);
  return timeZones[index];
};

export const DEFAULT_TIMEZONE_REFERENCE = 'UTC';

export const timeZones: TTimeZone[] = [
  {
    abbreviation: 'NUT',
    code: 'NU',
    country: 'Niue',
    timezone: 'Pacific/Niue',
    offset: `${DEFAULT_TIMEZONE_REFERENCE} -11:00`,
    // Better way to do this would be to use just the number of hours or the number of minutes since that would be more reliable on all browsers and we won't have to depend on any external package to get offset.
    // Doing it this way since we were already using the format UTC ±hh:mm and changing it could break things (plus there was probably a good reason we were doing it that way)
  },
  {
    abbreviation: 'HAST',
    code: 'US',
    country: 'United States',
    timezone: 'Pacific/Honolulu',
    offset: `${DEFAULT_TIMEZONE_REFERENCE} -10:00`,
  },
  {
    abbreviation: 'PST',
    code: 'PH',
    country: 'Philippines',
    timezone: 'Asia/Manila',
    offset: `${DEFAULT_TIMEZONE_REFERENCE} +08:00`,
  },
  {
    abbreviation: 'AKST',
    code: 'US',
    country: 'United States',
    timezone: 'America/Adak',
    offset: `${DEFAULT_TIMEZONE_REFERENCE} -09:00`,
  },
  {
    abbreviation: 'AKDT',
    code: 'US',
    country: 'United States',
    timezone: 'America/Anchorage',
    offset: `${DEFAULT_TIMEZONE_REFERENCE} -08:00`,
  },
  {
    abbreviation: 'PST',
    code: 'US',
    country: 'United States',
    timezone: 'America/Los_Angeles',
    offset: `${DEFAULT_TIMEZONE_REFERENCE} -07:00`,
  },
  {
    abbreviation: 'MST',
    code: 'US',
    country: 'United States',
    timezone: 'America/Denver',
    offset: `${DEFAULT_TIMEZONE_REFERENCE} -06:00`,
  },
  {
    abbreviation: 'EST',
    code: 'US',
    country: 'United States',
    timezone: 'America/New_York',
    offset: `${DEFAULT_TIMEZONE_REFERENCE} -04:00`,
  },
  {
    abbreviation: 'BRT',
    code: 'BR',
    country: 'Brazil',
    timezone: 'America/Belem',
    offset: `${DEFAULT_TIMEZONE_REFERENCE} -03:00`,
  },
  {
    abbreviation: 'IST',
    code: 'IN',
    country: 'India',
    timezone: 'Asia/Kolkata',
    offset: `${DEFAULT_TIMEZONE_REFERENCE} +05:30`,
  },
  {
    abbreviation: 'CET',
    code: 'LV',
    country: 'Latvia',
    timezone: 'Europe/Riga',
    offset: `${DEFAULT_TIMEZONE_REFERENCE} +02:00`,
  },
  {
    abbreviation: 'TRT',
    code: 'TR',
    timezone: 'Europe/Istanbul',
    offset: `${DEFAULT_TIMEZONE_REFERENCE} +03:00`,
    country: 'Turkey',
  },
  {
    abbreviation: 'AWST',
    code: 'AU',
    country: 'Australia',
    timezone: 'Australia/Perth',
    offset: `${DEFAULT_TIMEZONE_REFERENCE} +08:00`,
  },
  {
    abbreviation: 'ACST',
    code: 'AU',
    country: 'Australia',
    timezone: 'Australia/Darwin',
    offset: `${DEFAULT_TIMEZONE_REFERENCE} +09:30`,
  },
  {
    abbreviation: 'AEST',
    code: 'AU',
    country: 'Australia',
    timezone: 'Australia/Brisbane',
    offset: `${DEFAULT_TIMEZONE_REFERENCE} +10:00`,
  },
];
