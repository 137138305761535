/* eslint-disable import/no-cycle */
import { Picture } from 'Models/Picture/@types';
import { Publish, TimeStamp, TimeZone } from 'Typings/Global';
import { Tag } from 'Models/Tags/@types';
// eslint-disable-next-line import/no-cycle
import { User, UsersWithProfileImages } from 'Models/User/@types';
import { IFeatureToggle, TContentType, TReplay } from 'Models/Drop/@types';
import { TCollaborationRequest } from 'Models/CollaborationRequest/@types';
import { TCommunity, NFTDetails, IQRManagerConfigProps } from 'Models/Community/@types';
import { Address } from '../Address/@types';
// eslint-disable-next-line import/no-cycle
import { AdmissionType, EventTier, TicketingTierType } from './ticketing/@types';
import { TPurchasedTicket } from 'Models/PurchasedTicket/@types';
import { EGateType } from 'Models';
import { IOwnedRewards, IRewardSetting } from './Reward/@types';

export type EventType = 'online' | 'offline' | 'tbd'; //{ subType: 'online' | 'metaverse' | 'offline' };

export type EventFrequency = 'oneTime' | 'daily' | 'weekdays' | 'weekly' | 'monthly' | 'annually';
// | 'custom';

export type RefundPolicyType =
    | 'no_refunds'
    | 'case_by_case_refund'
    | 'refund_for_up_to_1_day_before_event'
    | 'refund_for_up_to_1_week_before_event'
    | 'refund_for_up_to_1_month_before_event';

export type TNewsletter = 'newsletter' | 'preview_list' | 'creator_waitlist_via_typeform' | 'investors' | 'press' | 'active_clients_convos' | 'users';

export type EventPriceCategory = 0 | 1 | 2 | 3 | 4 | 5;

export type EventCancellationType = 'Postpone' | 'Cancel';
export type EventPostponeType = 'SPECIFIC_DATE' | 'TBD';
export type EventCancelRefundType = 'REQUEST_ONLY' | 'AUTO';

export type EventAnalyticsItem = {
    count: number;
    images: string[];
};

export interface EventAnalytics {
    favouriteAnalytics: EventAnalyticsItem;
    viewAnalytics: EventAnalyticsItem;
    purchasersAnalytics: EventAnalyticsItem;
}

export interface EventCancellation {
    // Datatype for sending to back-end
    cancelOrReschedule: 'cancel' | 'reschedule';
    dateChanged?: boolean;
    dateTBD?: boolean;
    automaticRefund: boolean;
    emailSubject: string;
    emailBody: string;
    newDates?: { startDate?: string; endDate?: string }[];
    timeZone?: TimeZone;
}
export interface EventCancellationTransform {
    // To be used in frontend
    cancellationType: EventCancellationType;
    postponedDateType?: EventPostponeType;
    startTime?: string;
    endTime?: string;
    eventDates?: { startDate: string; endDate: string }[];
    timeZone: TimeZone;
    emailSubject: string;
    emailBody: string;
    refundType: EventCancelRefundType;
    startDate?: string;
    endDate?: string;
}

export interface EventDate {
    startDate: string;
    endDate: string;
}

export interface TEvent extends Publish, TimeStamp, EventCancellation {
    id: string;

    doc_type: TContentType;

    name: string;
    subtitle: string;

    communityId?: string;
    community: TCommunity;

    startTime?: string;
    endTime?: string;
    eventDates: { startDate: string; endDate: string }[];

    canceled?: boolean;
    oldEventDates?: { startDate: string; endDate: string }[];

    description: string;
    /**
     * metaverse is only for event form. Don't use it anywhere else. To check if event is a metaverse event, use subType.
     */
    eventType: EventType | 'metaverse';
    // TODO: Find a way to allow metaverse in event form without adding it to eventType types.
    subType: EventType | 'metaverse';
    tagIds: string[];

    tags?: Tag[];

    usersWithProfileImages: UsersWithProfileImages;

    /**
     * For the eventType online (virtual)
     */
    platformName?: string;
    platformUrl?: string;

    favouriteId?: string;

    location: {
        lat: number;
        lng: number;
    };
    placeId: string;
    address: Address;
    deleted: false;
    type: 'online' | 'offline';
    ticketUrl: string;
    rsvpUrl?: string;
    externalUrl: string;
    priceCategory: string;
    frequency: EventFrequency;
    repeatUntil?: string;
    favouriteCount: number;
    reminderCount: number;
    curated: boolean;
    curatorId: string;
    curator?: User;

    creatorId: string;

    timeZone: TimeZone;
    timeZoneSet: boolean;

    primaryImage: Picture;
    _primaryImage: Picture;
    // primaryImages: Picture;
    galleryImages: Picture[];
    coverImages: Picture[];

    primaryEventCategoryId?: string;
    primaryEventCategory?: Tag;
    secondaryEventCategoryId?: string;
    secondaryEventCategory?: Tag;

    isFavourited?: boolean;
    isPrivate?: boolean;
    isFeatured?: boolean;
    featured?: boolean;
    twitterHandles: string[];
    slug: string;
    tweetParams: {
        isSensitive?: boolean;
    };
    platform: {
        link: string;
        provider: SupportedEventPlatforms;
        [key: string]: any;
    };
    rsvpEmail?: string;
    refundPolicy: RefundPolicyType;
    selectedNewsletter: TNewsletter;

    // Event cancellations
    cancellation?: EventCancellationTransform | any;

    tierType?: TicketingTierType;
    _tiers?: EventTier[];
    enabledTier?: EventTier;
    admissionType?: AdmissionType;
    ticketInstructions?: string;
    promoCodes?: Array<PromoCode>;
    addons?: Addons;
    providerListId?: string;
    newsletterConfig?: {
        action: string;
        providerKey: string;
        providerListId: string;
    }[];

    isReminded?: boolean;
    nextDates: string[];
    purchasedTickets?: Pick<TPurchasedTicket, 'name' | 'orderId' | 'quantity' | 'refunded' | 'eventId' | 'id' | 'transactionId' | 'transaction'>[];
    isUserFeatured: boolean;
    reminderId?: string;
    userId: string;
    user?: User;
    discussionId?: string;

    taxInfo: TTax;
    collaboratingCommunityIds: string[];
    collaborationRequestInfo: TCollaborationRequest[];
    collaborators: TCommunity[];
    chargeTax: string;

    isReplayed: boolean;
    replayId?: string;
    replayersCount: number;
    replayedOn: TReplay[];

    // isPasswordProtected?: boolean;
    password?: string;
    gateType: EGateType;
    nftGates?: NFTDetails[];
    isDropUnlocked?: boolean;

    // isAgeRestricted: boolean;
    ageRestriction: number;

    qrCodesBulleit: IQRCodesBulleit[];
    featureToggle?: Partial<IFeatureToggle>;
    rewardSettings?: IRewardSetting;
    ownedRewards?: IOwnedRewards[];
}

export interface TTax {
    country: string;
    id: string;
    name: string;
    ratePct: number;
}

export interface ISpeakerSponsor {
    name: string;
    connections: IAddOnConnResponse[];
}

export interface Addons {
    additionalImages?: {
        img: Picture;
        source?: string;
        description?: string;
    }[];
    additionalInfo?: { name: string; information: string; images?: Picture[] }[];
    featured?: { name: string; url: string }[]; // There will be other keys as returned from link preview. These are the required ones
    sponsors?: { name: string; url: string }[]; // Same^
    speakers?: IAddOnConnResponse[];
    speakerSponsor?: ISpeakerSponsor[];
    entityOrder?: EAddOnConnType[];
}
export interface PromoCode {
    discountType: 'flat' | 'percent';
    discountValue: number;
    code: string;
    deleted?: boolean;
}

export type Categories = 'music' | 'comedy' | 'wellness' | 'art' | 'activism' | 'education' | 'sports' | 'culture' | 'pride' | 'parties' | 'food';

export interface IDistancedDrops {
    in: TEvent[];
    out: TEvent[];
}

export type SupportedStreamingServices = 'youtube' | 'twitch' | 'vimeo' | 'clubhouse' | 'zoom' | 'global' | 'ronday';
export type SupportedMetaversePlatforms = 'nowhere' | 'spatial' | 'sandbox' | 'ronday';
export type SupportedEventPlatforms = SupportedMetaversePlatforms | SupportedStreamingServices;

export const AddOnConnTypeMap = {
    sponsors: 'Sponsors',
    featured: 'Featuring',
    speakers: 'Speakers',
};

export type TAddOnConnType = 'sponsors' | 'featured' | 'speakers';

export enum EAddOnConnType {
    sponsors = 'sponsors',
    featured = 'featured',
    speakers = 'speakers',
}

export interface IAddOnConnInstance {
    name: string;
    id: string;
    slug: string;
    profileImg: string;
    linkUrl?: string;
}

export interface IAddOnConnRequest {
    connectionType: EAddOnConnType;
    entityType: string;
    entityId: string;
    meta: IAddOnConnInstance;
}

export interface IAddOnConnResponse extends IAddOnConnRequest {
    connectionStatus: TCollaborationRequest['status'];
    id: string;
    addOnType: EAddOnConnType;
    connectionRequestGeneratedMeta?: { email: string; slug: string };
}

export interface IQRCodesBulleit {
    eventId: string;
    hits: number;
    qrId: string;
    isEnabled: boolean;
    qrCode: string;
    config: IQRManagerConfigProps;
    flowTriggerCount: number;
    totalScans: number;
    stats: IQRCodesBulleitStats;
}

export interface IQRCodesBulleitStats {
    errored: number;
    pending: number;
    rejected: number;
    success: number;
    totalScans: number;
}
