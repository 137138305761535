import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { textFieldWMentionHelpers } from './TextFieldWMention/helpers';
import PgTypo, { PgTypoProps } from './PgTypo';

export interface PgTypoWMentionProps extends PgTypoProps {
  renderMentionHTMLStr?: (name: string, meta: string) => string;
}

const PgTypoWMention: FC<PgTypoWMentionProps> = ({ children, renderMentionHTMLStr, ...props }) => {
  const classes = useStyles({});

  const renderMention = (name: string, meta: string) => {
    return `<a href="#">${name}</a>`;
  };

  const __html = typeof children === 'string' ? textFieldWMentionHelpers.getTextNode(children, renderMentionHTMLStr ?? renderMention) : children;

  if (typeof children === 'string') return <PgTypo {...props} dangerouslySetInnerHTML={{ __html: __html as string }}></PgTypo>;

  return <PgTypo {...props}>{__html}</PgTypo>;
};

const useStyles = makeStyles<Theme, any>((theme) => ({}));

export default PgTypoWMention;
