import { Box, TextField, Theme, createStyles, makeStyles } from '@material-ui/core';
import React, { FC, useState } from 'react';
import useImageGenerator from './useImageGenerator';
import MasonryLayout from '../../Components/MasonryLayout';
import PgButton from 'Components/PgButton';
import PgIcon from 'Components/PgIcon';
import Spacer from 'Components/Spacer';
import PgEmptyState from 'Components/PgEmptyState';

export interface ImageGeneratorProps {
  columns?: number;
  gap?: number;
  onImageSelect?: (imgUrl: string) => void;
}

const ImageGenerator: FC<ImageGeneratorProps> = (props) => {

  const { columns = 3, gap = 16, onImageSelect } = props;

  const classes = useStyles();

  const [term, setTerm] = useState('');

  const { setParams, result, isLoading, params } = useImageGenerator();

  const handleSearchClick = () => setParams(p => ({ ...p, query: term, page: 1 }));

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') handleSearchClick();
  }

  const handleImageClick = (url: string) => {
    setParams(p => ({ ...p, query: '', page: 1 }));
    setTerm('');
    onImageSelect?.(url)
  }

  return (
    <Box>
      <Box display='flex' alignItems='center'>
        <TextField
          placeholder='Search for an image...'
          value={term}
          onChange={e => setTerm(e.target.value)}
          variant='outlined'
          fullWidth
          onKeyDown={handleKeyPress}
        />
        <PgButton primary className={classes.searchBtn} onClick={handleSearchClick}>
          <PgIcon icon='icon-search' styleClass={classes.searchIcon} />
        </PgButton>
      </Box>
      <Box pt={2} className={classes.imgWrap}>
        {!!result?.results.length && (
            <MasonryLayout gap={gap} columns={columns}>
              {result.results.map(img => (
                <PgButton quaternary key={img.id} fullWidth onClick={() => handleImageClick(img.urls.full)}>
                  <img src={img.urls.small} className={classes.img} alt={img.alt_description} />
                </PgButton>
              ))}
            </MasonryLayout>
        )}
        {!isLoading && !result?.results.length && <PgEmptyState text={`No image found for ${params.query}`} />}
        {isLoading && <PgEmptyState text={`Searching...`} />}
        {result && result?.total_pages > 1 && (
          <>
            <Spacer height={16} />
            <PgButton primary fullWidth onClick={() => setParams(p => ({ ...p, page: (p.page ?? 1) + 1 }))}>Load more</PgButton>
          </>
        )}
      </Box>
    </Box>
  )
}

export default ImageGenerator;

const useStyles = makeStyles((theme: Theme) => createStyles({
  img: { objectFit: 'contain', width: '100%' },
  imgWrap: {
    overflowY: 'auto',
    height: `calc(100vh - 64px - 110px)`,
    [theme.breakpoints.down('sm')]: { height: `calc(100vh - 110px)` },
  },
  searchBtn: { padding: '5.5px 20px' },
  searchIcon: { color: theme.palette.common.black },
}));
