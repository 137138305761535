import React from 'react';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import helpers from 'Utils/helpers';

const ProdAnalytics: FC = () => {
  const isProduction = helpers.isProduction();
  if (!isProduction) return null;
  return (
    <Helmet>
      {/* 
          Production: Playground Tracking Codes
          https://nostanding-my.sharepoint.com/:w:/g/personal/marickson_nostanding_com_au/Eb-SRz5ZTvxCuxOIhPYhZwMBz0bLU6ieYG4TLNHDxjeBpQ?e=bUEu1J
      */}
      {/* GA4 */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-XXM793LJ5Z"></script>
      <script>{`window.dataLayer = window.dataLayer || []; function gtag(){window.dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-XXM793LJ5Z');`}</script>

      {/* Google Tag Manager */}
      <script>
        {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': 

            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], 
            
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 
            
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); 
            
            })(window,document,'script','dataLayer','GTM-TL86TRZ');
          `}
      </script>

      {/* GSC HTML Tag verification */}
      <meta name="google-site-verification" content="h1yScTCX8gaFhYxLDxfbg1tdHXZNQSOhXeRPFU0Q5eI" />

      {/* https://playgroundapp.atlassian.net/browse/PLG-2869 */}
      <noscript>
        {`
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TL86TRZ" height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `}
      </noscript>

      <meta name="msvalidate.01" content="90B9F586E59829694C7D3D546CE5B85B" />
    </Helmet>
  );
};

export default ProdAnalytics;
