/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { FC, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box, Button, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { useStoreState } from 'Stores';
import CHECK_BOX_DEFAULT from 'Assets/icons/checkbox/checkbox_default.svg';
import CHECK_BOX_SELECTED from 'Assets/icons/checkbox/checkbox_selected.svg';
import helpers from 'Utils/helpers';
import Spacer from 'Components/Spacer';
import { BORDER_BLACK } from 'Theme/themeConstants';
import { getUserName } from 'Models/User/utils';
import { OnDisplayEntity } from 'Models/Drop/@types';

export interface AccountSelectProps {
  isReplayed: boolean;
  value: OnDisplayEntity[];
  onCancel: () => void;
  onSubmit: (data: OnDisplayEntity[]) => void;
}

const AccountSelect: FC<AccountSelectProps> = (props) => {
  const { isReplayed, value, onCancel, onSubmit } = props;
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
  const [selectedEntities, setSelectedEntities] = useState<OnDisplayEntity[]>(isReplayed ? value : []);
  const classes = useStyles({});

  const data: { name: string; id: string; value: string; type: 'Community' | 'user' }[] = [];

  if (!appUser) return null;

  if (isReplayed) {
    if (value?.length) {
      value.map((v) => {
        if (v.onDisplayEntityId === appUser.id) {
          data.push({ name: getUserName(appUser), id: appUser.id, value: appUser.id, type: 'user' });
        } else {
          if (!appUser.userCommunities?.length) return;
          const comm = appUser.userCommunities.find((f) => f.id === v.onDisplayEntityId);
          if (comm) data.push({ name: comm.name, id: comm.id, value: comm.id, type: 'Community' });
        }
      });
    }
  } else {
    data.push({ name: getUserName(appUser), id: appUser.id, value: appUser.id, type: 'user' });
    if (appUser.userCommunities?.length) {
      appUser.userCommunities
        ?.filter((f) => !f.isDeactivated)
        ?.map((m) => {
          data.push({ name: m.name, id: m.id, value: m.id, type: 'Community' });
        });
    }
  }

  const handleClick = (item: OnDisplayEntity) => {
    const updatedData = helpers.toggleItemFromList(selectedEntities, item, undefined, (a, b) => a.onDisplayEntityId === b.onDisplayEntityId);
    setSelectedEntities(updatedData);
  };

  return (
    <Box p={2}>
      <Box p={0.5}>
        <FormGroup>
          {data.map((d) => {
            return (
              <FormControlLabel
                key={d.id}
                onChange={() => handleClick({ onDisplayEntityId: d.value, onDisplayEntityType: d.type })}
                control={
                  <Checkbox
                    checkedIcon={<img src={CHECK_BOX_SELECTED} alt="checkbox-selected" />}
                    icon={<img src={CHECK_BOX_DEFAULT} alt="checkbox-default" />}
                    checked={!!selectedEntities.find((s) => s.onDisplayEntityId === d.value)}
                  />
                }
                label={d.name}
              />
            );
          })}
        </FormGroup>
      </Box>
      <Box mt={2} display="flex" alignItems="center">
        <Button variant="outlined" fullWidth onClick={onCancel} className={classes.actionBtns}>
          {'cancel'.toUpperCase()}
        </Button>
        <Spacer width={20} />
        <Button
          variant="contained"
          fullWidth
          color="secondary"
          onClick={() => onSubmit(selectedEntities)}
          disabled={!selectedEntities.length}
          className={classes.actionBtns}
        >
          {(isReplayed ? 'remove' : 'replay').toUpperCase()}
        </Button>
      </Box>
    </Box>
  );
};

export default AccountSelect;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionBtns: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.body1.fontSize,
      border: BORDER_BLACK,
    },
  }),
);
