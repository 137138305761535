import clsx from 'clsx';
import UrlInput, { UrlInputProps } from 'Components/Inputs/UrlInput';
import { FormikProps } from 'formik';
import { get } from 'lodash';
import React, { FC } from 'react';
import { getFieldError, IFieldProps } from 'react-forms';
import helpers from 'Utils/helpers';

export interface UrlInputFieldProps extends IFieldProps {
  fieldProps?: UrlInputProps;
}

const UrlInputField: FC<UrlInputFieldProps> = (props) => {
  const { fieldProps = {} as UrlInputProps, formikProps = {} as FormikProps<any> } = props;

  const fieldError = getFieldError(fieldProps.name || '', formikProps);
  const updatedProps = {
    ...fieldProps,
    error: !!fieldError,
    helperText: fieldError || fieldProps.helperText || '',
    onChange: formikProps.handleChange,
    onBlur: formikProps.handleBlur,
    value: helpers.getFieldValue(formikProps, fieldProps.name || ''),
  };

  return <UrlInput {...updatedProps} />;
};

export default UrlInputField;
