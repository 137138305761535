import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import PgButton from 'Components/PgButton';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
// eslint-disable-next-line import/no-cycle
import { IPointsDialogContent } from 'Contexts/PointsDialogContext';
import React, { FC } from 'react';
import { COLOR } from 'Theme/themeConstants';

export interface PointsDialogProps extends IPointsDialogContent {
  onClose: () => void;
}

const PointsDialog: FC<PointsDialogProps> = (props) => {
  const classes = useStyles();
  const { open, onClose, title, body, subtitle, actionText = 'Done', onAction = () => {} } = props;
  const handleClose = () => {
    onClose();
  };
  
  return (
    <Dialog maxWidth={false} open={open} onClose={handleClose} PaperProps={{ className: classes.root }}>
      <IconButton className={classes.closeIcon} onClick={handleClose}>
        <PgIcon icon="icon-close" />
      </IconButton>
      <DialogTitle className={classes.zeroPadding} disableTypography>
        <PgTypo align="center" h5>
          {title}
        </PgTypo>
      </DialogTitle>
      <DialogContent className={clsx(classes.zeroPadding, classes.content)}>
        {typeof subtitle === 'string' ? (
          <PgTypo gutterBottom align="center" c2 className={classes.subtitle}>
            {subtitle}
          </PgTypo>
        ) : (
          subtitle
        )}
        {body ? (
          <PgTypo gutterBottom align="center" c2>
            {body}
          </PgTypo>
        ) : null}
      </DialogContent>
      <DialogActions className={classes.zeroPadding}>
        <PgButton onClick={onAction} fullWidth size="large" primary variant="contained">
          {actionText}
        </PgButton>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    closeIcon: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    root: {
      width: 400,
      padding: theme.spacing(5, 3, 4),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    content: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    zeroPadding: {
      padding: 0,
    },
    subtitle: {
      color: COLOR.tertiary.activism,
    },
  });
});

export default PointsDialog;
