/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import { Router, BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import useAppInit from 'Features/Auth/useAppInit';
import Loader from 'Components/Loader';
import RootLayout from './Screens/RootLayout';
import Boot from './Boot';
import Theme from './Theme';
import ProdAnalytics from 'Features/Analytics/prod';
import { LivepeerConfig, createReactClient, studioProvider } from '@livepeer/react';
import config from 'config';

export const history = createBrowserHistory();

const livepeerClient = createReactClient({
  provider: studioProvider({ apiKey: config.get('LIVEPEER_API_KEY') ?? '' }),
});
window.addEventListener('load', () => {
  if (window.location.href.includes('stripeConnect')) {
    if (window.opener) window.opener.postMessage({ name: 'stripeRedirect', url: window.location.href }, '*');
    window.close();
  }
});

const App: React.FC = () => {
  const { init, loading } = useAppInit();

  useEffect(() => {
    Boot().then(() => {
      init();
    });
  }, []);

  return (
    <LivepeerConfig client={livepeerClient}>
      <BrowserRouter
      // history={history}
      >
        <Router history={history}>
          <MuiThemeProvider theme={Theme}>
            <ProdAnalytics />
            {loading ? (
              <Loader fullScreen />
            ) : (
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <RootLayout />
              </MuiPickersUtilsProvider>
            )}
          </MuiThemeProvider>
        </Router>
      </BrowserRouter>
    </LivepeerConfig>
  );
};

export default App;
