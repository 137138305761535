import React, { useEffect } from 'react';
import UserModel, { User } from '../../Models/User';
import { WalletType } from '../../Models/Web3/@types';
import { useStoreActions, useStoreState } from '../../Stores';
import EthereumRpc from '../utils/evm.ethers';

export const useOnUserLoggedIn = () => {
  const { isAuthenticated, appUser } = useStoreState(({ AuthStore: { isAuthenticated, appUser } }) => ({ isAuthenticated, appUser }));
  if (!isAuthenticated) return false;
  if (!appUser?.id) return false;
  return true;
};

export const useUpsertUserPgWallet = (ethereumRpc: EthereumRpc | null) => {
  const isAuthenticated = useOnUserLoggedIn();
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
  const { setAppUser } = useStoreActions(({ AuthStore: { setAppUser } }) => ({ setAppUser }));

  const upsertPgWallet = async (user: User, ethereumRpc: EthereumRpc) => {
    const wallets = user.cryptoWallet || [];
    const pgWallet = wallets.find((w) => w.type === WalletType.PG);
    if (pgWallet) return; // User already has a PG wallet
    try {
      const pgWalletAddress = await ethereumRpc.getAccounts();
      const updatedWallets = [
        ...wallets,
        {
          address: pgWalletAddress,
          type: WalletType.PG,
        },
      ];
      await UserModel.updateUser({
        cryptoWallet: updatedWallets,
        id: user.id,
      });
      setAppUser({
        ...user,
        cryptoWallet: updatedWallets,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    /* 
      If user is logged in via web 3 and doesn't have a PG wallet,
      we save the existing wallet address to the user's profile
    */
    if (!isAuthenticated) return;
    if (!ethereumRpc) return;
    if (!appUser?.id) return;
    upsertPgWallet(appUser, ethereumRpc);
  }, [isAuthenticated, ethereumRpc, appUser?.id]);
};
