import { DATE_FIELD_DATE_FORMAT } from 'Features/ReactFormFields/DateField';
import moment, { Moment } from 'moment-timezone';
import { DEFAULT_TIMEZONE_REFERENCE, timeZones } from './TimeZone';

export const DEFAULT_DATE_FORMAT = 'MMM DD, hh:mm a z';
export const DEFAULT_DATE_RANGE_FORMAT = 'MMM DD, hh:mm a';
const DateTimeUtils = {
  getTimeWithOffset(time: string, offset = 'UTC +00:00', format?: string): string {
    const timeDiff = moment().utcOffset(offset.split(' ')[1]).utcOffset();
    const result = (time ? moment.utc(time, format) : moment()).add(timeDiff, 'm').format(format);
    return result;
  },
  setTimeWithoutOffset(time: string, offset = 'UTC +00:00', format?: string): string {
    const timeDiff = moment().utcOffset(offset.split(' ')[1]).utcOffset();
    const result = moment.utc(time, format).subtract(timeDiff, 'm').format(format);
    return result;
  },
  getOffsetFromTimezone(timezone = 'GMT'): string {
    const offset = moment.tz(timezone).utcOffset();
    const offsetString =
      offset > 0 ? moment.utc('00:00', 'HH:mm').add(offset, 'm').format('HH:mm') : moment.utc('00:00', 'HH:mm').subtract(offset, 'm').format('HH:mm');
    // reverse the sign
    return (offset < 0 ? '-' : '+') + offsetString;
  },
  getTimeZonedDateString(date: string, timeZone = 'America/New_York', format = 'MMM DD, YYYY'): string {
    const momentDate = DateTimeUtils.getTimeZonedDate(date, timeZone);
    return momentDate.format(format);
  },
  getTimeZonedDate(date: string, timeZone = 'America/New_York'): Moment {
    return moment.utc(date).tz(timeZone);
  },

  copyTimeInDate(date: string, time: string, timZone = 'America/New_York', format = DATE_FIELD_DATE_FORMAT): Moment {
    if (moment(date, format).isValid()) {
      const momentDate = moment.utc(date, format).tz(timZone, true).startOf('day').add(DateTimeUtils.getMinutes(time), 'minutes');
      return momentDate;
    }
    const momentDate = DateTimeUtils.getDate(date, timZone);
    // const timeDate = DateTimeUtils.getDate(time, timZone);
    // return momentDate?.clone().hours(timeDate.hours()).minutes(timeDate.minutes()).seconds(timeDate.seconds());
    return momentDate?.clone().startOf('day').add(DateTimeUtils.getMinutes(time), 'minutes');
  },
  getTimezoneAbbreviation(offset: string): string {
    // Expected input +01:00, +01:30 and so on
    return timeZones.find((zone) => zone.offset === `${DEFAULT_TIMEZONE_REFERENCE} ${offset}`)?.abbreviation ?? '';
  },
  getFormattedDate(
    date?: string,
    offset = 0,
    variant: 'timeOnly' | 'reminder' | 'short' | 'full' | 'dateOnly' | 'textDateOnly' | 'dateAtTime' = 'short',
  ): string {
    return '';
  },

  getDate(date: string, timeZone?: string): Moment {
    if (!date) throw Error('Date is not defined.');
    return moment.utc(date).tz(timeZone ?? moment.tz.guess());
  },
  formatDate(date: string, format = DEFAULT_DATE_FORMAT, timeZone?: string): string | undefined {
    if (!date) return '';
    const momentDate = DateTimeUtils.getDate(date, timeZone);
    if (!momentDate) return undefined;
    return momentDate.format(format);
  },
  getMinutes(time: string): number {
    // format: HH:mm
    if (!time) return 0;
    if (typeof time === 'number') return time;
    const [hour, minute] = time.split(':');
    return parseInt(hour, 10) * 60 + parseInt(minute, 10);
  },
};

export default DateTimeUtils;
