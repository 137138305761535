/* eslint-disable react/require-default-props */
import { createStyles, Theme, makeStyles, InputAdornment, Typography, FormHelperTextProps, FormHelperText, Box } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import React, { FC, useEffect, useState } from 'react';
import { FormConfig, getFieldError, IFieldProps } from 'react-forms';
import { GoogleUtils, TGooglePlaceSuggestCategories } from 'rf-google-places';
import { FormikProps } from 'formik';
import { get } from 'lodash';
import helpers from 'Utils/helpers';
import { User } from 'Models/User/@types';
import { DEFAULT_TIMEZONE_REFERENCE, TimeZones } from 'Resources/TimeZone';
import AutoSuggest from 'Components/Inputs/AutoSuggest';
import PgIcon from 'Components/PgIcon';
import { inputStyles } from 'Components/FormFields/inputStyles';
import clsx from 'clsx';
import { PlacesSuggestType } from 'Resources/google';
import { TimeZone } from 'Typings/Global';

export type LocationBasedTimeZoneProps = TextFieldProps & {
  //   onResultClick: (result: any) => void;
  suggestionsTypes?: TGooglePlaceSuggestCategories[];
  value?: string;
  helperTextProps?: FormHelperTextProps;
  setLocationTimeZone?: (city: string, name: string, offset: string, code: string) => void;
  onTimezoneSet?: (timezone: TimeZone) => void;
  disableAutocomlete?: boolean;
  disableClearIcon?: boolean;
  onDisableClick?: () => void;
  rootClassName?: string;
  placeholder?: string;
};

export interface LBTimeZoneProps extends IFieldProps {
  fieldProps?: LocationBasedTimeZoneProps;
  variant?: 'dark' | 'light';
}

export const LocationBasedTimeZone: FC<LBTimeZoneProps> = (props) => {
  const classes = useStyles();
  const inputClass = inputStyles();
  const {
    fieldProps = {} as LocationBasedTimeZoneProps,
    formikProps = {} as FormikProps<User>,
    fieldConfig = {} as FormConfig /* variant = 'light' */,
  } = props;
  const {
    suggestionsTypes = ['(regions)'] as unknown as TGooglePlaceSuggestCategories[],
    name = '',
    helperText,
    helperTextProps,
    setLocationTimeZone,
    onTimezoneSet,
    disableAutocomlete,
    disableClearIcon,
    onDisableClick,
    rootClassName,
    placeholder = 'Search by location',
    ...textFieldProps
  } = fieldProps;
  const error = getFieldError(fieldConfig.valueKey, formikProps || {});
  const value = get(formikProps, `values.${fieldConfig.valueKey}`) ?? undefined;
  const [input, setInput] = useState<string>(value?.offset && value?.city ? `${value?.offset} ${value?.city}` : value?.city ?? value?.name ?? (value || ''));
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    if (input) {
      getSuggestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  const clearInput = () => {
    if (open) setOpen(false);
    formikProps.setFieldValue(fieldConfig.valueKey, undefined);
    setInput('');
  };
  useEffect(() => {
    if (!value) {
      clearInput();
    }
  }, [value]);

  const getSuggestions = async () => {
    let res = (await GoogleUtils.placeSuggest(input, suggestionsTypes)) as PlacesSuggestType[];
    if (res) {
      res = res.filter((item) => !item.types.includes('sublocality') && !item.types.includes('postal_code'));
    }
  };

  const handleResultClick = async (item: any) => {
    const { name, geometry = {} as google.maps.places.PlaceGeometry } = await helpers.cityDetails(item.place_id);
    const timeZone = await helpers.timezoneFromLocation(geometry.location.lat(), geometry.location.lng());
    const minutes = Math.floor(Math.abs(timeZone.rawOffset) / 60);
    const offset = `${DEFAULT_TIMEZONE_REFERENCE} ${timeZone.rawOffset < 0 ? '-' : '+'}${Math.floor(minutes / 60)
      .toString()
      .padStart(2, '0')}:${Math.floor(minutes % 60)
      .toString()
      .padStart(2, '0')}`;
    let timeZoneCode = '';
    // eslint-disable-next-line array-callback-return
    timeZone.timeZoneName.split(' ').map((item, index) => {
      timeZoneCode += item[0];
    });
    // There are some situations where a name can not be abbreviated. eg., Europe/Istanbul has timeZoneName of GMT+03:00. The following handles that situation.
    if (timeZoneCode.length < 3) {
      const temp = TimeZones.getTZItem(offset);
      if (temp?.abbreviation) {
        timeZoneCode = temp.abbreviation;
      }
    }
    const timezoneValue: TimeZone = {
      city: name,
      name: timeZone.timeZoneId,
      offset,
      code: timeZoneCode,
    };
    formikProps.setFieldValue(fieldConfig.valueKey, timezoneValue);
    setInput(`${offset} ${name}`);
    // eslint-disable-next-line no-unused-expressions
    onTimezoneSet?.(timezoneValue);
    // eslint-disable-next-line no-unused-expressions
    setLocationTimeZone?.(name, timeZone.timeZoneId, offset, timeZoneCode);
    setOpen(false);
  };

  const autoSuggestProps = {
    placeholder,
    disableSearchIcon: true,
    autoCompleteClasses: {
      option: clsx(classes?.menuOpton),
    },
    onBlur: () => formikProps.setFieldTouched('timeZone', true),
    onClear: clearInput,
    inputField: input,
    // setInputField: setInput,
    searchAdornment: (
      <InputAdornment position="end">
        <PgIcon icon="icon-search" />
      </InputAdornment>
    ),
    textFieldInputProps: {
      className:clsx(inputClass.input, classes.input, classes.root),
      classes: {
        // formControl: clsx(inputClass.input, classes.input, classes.root),
        ...textFieldProps.classes,
        // formControl: clsx(inputClass.input, classes.input, classes.root),
      },
    },
  };

  return (
    <Box onClick={disableAutocomlete ? onDisableClick : undefined} className={rootClassName}>
      {textFieldProps.label ? (
        <Typography className={clsx(inputClass.label, { [classes.errorLabel]: !!error })} variant="caption">
          {textFieldProps.label}
        </Typography>
      ) : null}

      <AutoSuggest<PlacesSuggestType>
        fetchSuggestions={async (input) => {
          const results = (await GoogleUtils.placeSuggest(input, suggestionsTypes)) as PlacesSuggestType[];
          return results ?? [];
        }}
        autoCompleteProps={{
          getOptionLabel: (option: PlacesSuggestType) => option.description,
          getOptionSelected: (option: PlacesSuggestType, value: PlacesSuggestType) => option.description === value.description,
        }}
        className={classes.autosuggest}
        onResultClick={handleResultClick}
        variant="outlined"
        disableAutocomplete={disableAutocomlete}
        disableClearIcon={disableClearIcon}
        {...autoSuggestProps}
      />
      <div className={classes.helperText}>
        <FormHelperText {...helperTextProps} error={!!error}>
          {error || (typeof helperText !== 'object' ? helperText : (helperText as { name: string })?.name)}
        </FormHelperText>
      </div>
    </Box>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    endIcon: {
      cursor: 'pointer',
    },
    textField: {
      marginTop: theme.spacing(2.25),
    },
    menuOpton: {
      padding: 10,
      width: '100%',
      position: 'relative',
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
    input: {
      fontWeight: 700,
      fontSize: theme.typography.body2.fontSize,
      '& .MuiOutlinedInput-input':{
        padding : 0
      }
    },
    root: {
      width: '100% !important',
      display: 'flex',
      '& input': { height: '100%' },
    },
    helperText: {
      '& p': {
        marginTop: '2px',
      },
    },
    errorLabel: {
      color: theme.palette.error.main,
    },
    autosuggest: {
      '& .MuiInputBase-root': {
        padding: '9px !important',
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
        padding: 0, // Safari issues.
      },
    },
  });
});
