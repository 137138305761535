import { Box, BoxProps, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import PgButton from 'Components/PgButton';
import PgTypo, { PgTypoProps } from 'Components/PgTypo';
import { AppDialogContext } from 'Contexts/AppDialogContext';
import React, { FC, useContext } from 'react';
import PgIcon from './PgIcon';

export interface PgDialogProps {
  heading?: React.ReactNode;
  subheading?: React.ReactNode;
  body?: React.ReactNode;
  actionButtons?: React.ReactNode;
  handleFailure?: () => Promise<void>;
  handleSuccess?: () => Promise<void>;
  positive?: boolean;
  negative?: boolean;
  positiveText?: string;
  negativeText?: string;
  isForm?: boolean;
  headingContainerProps?: BoxProps;
  headingProps?: PgTypoProps;
  subheadingProps?: PgTypoProps;
  prev?: () => void;
}

const PgDialog: FC<PgDialogProps> = (props: PgDialogProps) => {
  const { hideDialog } = useContext(AppDialogContext);
  const {
    heading,
    subheading,
    body,
    actionButtons = false,
    handleFailure,
    handleSuccess,
    positive = true,
    negative = true,
    positiveText = 'Stay',
    negativeText = 'Leave',
    isForm = false,
    headingContainerProps,
    headingProps,
    subheadingProps,
    prev,
  } = props;
  const classes = useStyles({ isForm });

  return (
    <div className={classes.root}>
      <Box {...headingContainerProps}>
        {prev && (
          <PgButton className={classes.prevButton} onClick={prev}>
            <PgIcon styleClass={classes.backArrow} icon="icon-ticker-negative" />
          </PgButton>
        )}
        {heading && (
          <PgTypo h6 align="center" className={clsx(classes.heading, { [classes.headerSpacing]: !!prev })} {...headingProps}>
            {heading}
          </PgTypo>
        )}
      </Box>
      {subheading && (
        <PgTypo c2 align="center" gutterBottom className={classes.subheading} {...subheadingProps}>
          {subheading}
        </PgTypo>
      )}
      {typeof body === 'string' ? (
        <PgTypo b4 align="center" gutterBottom className={classes.body}>
          {body}
        </PgTypo>
      ) : (
        body
      )}
      {!actionButtons && (
        <Box className={classes.buttons}>
          {negative && (
            <PgButton fullWidth secondary onClick={handleFailure || hideDialog}>
              {negativeText}
            </PgButton>
          )}
          {positive && (
            <PgButton fullWidth primary onClick={handleSuccess}>
              {positiveText}
            </PgButton>
          )}
        </Box>
      )}
      {!!actionButtons && actionButtons}
    </div>
  );
};

const useStyles = makeStyles<Theme, { isForm?: boolean }>((theme) => ({
  root: {
    // alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    // paddingTop: theme.spacing(3),
  },
  heading: {
    // marginBottom: theme.spacing(1.5),
  },
  subheading: {
    marginBottom: theme.spacing(1.5),
  },
  body: (props) => ({
    marginTop: props.isForm ? 24 : 0,
    marginBottom: theme.spacing(2.5),
  }),
  buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
  },
  backArrow: {
    transform: 'rotate(90deg)',
  },
  prevButton: {
    padding: 0,
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    minWidth: 'unset',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  headerSpacing: {
    padding: theme.spacing(0, 2),
  },
}));

export default PgDialog;
