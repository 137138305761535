import React, { FC } from 'react';
import { Box, CircularProgress, createStyles, IconButton, makeStyles, Theme } from '@material-ui/core';
import { COLOR, THEME_PALETTE } from 'Theme/themeConstants';
import PgIcon from 'Components/PgIcon';
import clsx from 'clsx';
import { TContentType, TDrop } from 'Models/Drop/@types';
import { useStoreActions, useStoreState } from 'Stores';
import useUserActions from 'Features/User/useUserActions';
import useAsyncTask from 'Hooks/useAsyncTask';
import helpers from 'Utils/helpers';
import { TUserFavorited, User } from 'Models/User';

export interface IFavoriteButton {
  moment: TDrop;
  onDone?: (id: string, dropType: TContentType, favouriteId?: string) => void;
  isDisable?: boolean;
}
const FavoriteButton: FC<IFavoriteButton> = ({ moment, onDone, isDisable = false }) => {
  const classes = useStyles();
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({
    appUser,
  }));
  const { setDropList, contentFavorited, contentUnFavorited } = useStoreActions(
    ({ DropsStore: { setDropList }, AuthStore: { contentFavorited, contentUnFavorited } }) => ({ setDropList, contentFavorited, contentUnFavorited }),
  );
  const { drops } = useStoreState(({ DropsStore: { drops } }) => ({ drops }));
  const { addFavourite, removeFavourite, doAction, isUserEligible } = useUserActions();

  const handleFavourite = async () => {
    const isEligible = await isUserEligible();
    if (!isEligible) return;
    if (moment?.isFavourited) {
      // await removeFavourite(moment?.favouriteId as string);
      await doAction(removeFavourite, [moment?.favouriteId as string]);
      setDropList({ drop: helpers.updateItemList(drops, { ...moment, isFavourited: false, favouriteId: undefined }, 'UPDATE') });
      contentUnFavorited(moment?.id ?? '');
      moment.isFavourited = false;
      onDone?.(moment.id, moment.doc_type ?? 'Event');
      return;
    }
    // const res: TUserFavorited<User> | undefined = await addFavourite(moment?.doc_type as TContentType, moment?.id, appUser?.id as string);
    const res: TUserFavorited<User> | undefined = await doAction(addFavourite, [moment?.doc_type as TContentType, moment?.id, appUser?.id as string]);
    setDropList({ drop: helpers.updateItemList(drops, { ...moment, isFavourited: true, favouriteId: res?.id }, 'UPDATE') });
    contentFavorited({ favouriteId: res?.id ?? '', event: moment });
    moment.isFavourited = true;
    onDone?.(moment.id, moment.doc_type ?? 'Event', res?.id);
  };

  const favouriteRunner = useAsyncTask(handleFavourite);

  return (
    <Box>
      {favouriteRunner.status === 'PROCESSING' ? (
        <CircularProgress size={24} className={classes.loader} />
      ) : (
        <IconButton onClick={favouriteRunner.run} className={classes.button} disabled={isDisable}>
          <PgIcon styleClass={clsx(classes.icon, !moment?.isFavourited ? classes.inactiveIcon : undefined)} icon={'icon-save-fill'} size="large" />
        </IconButton>
      )}
    </Box>
  );
};

export default FavoriteButton;

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    button: {
      padding: 4,
    },
    icon: {
      color: COLOR.tertiary.music,
      fontSize: theme.typography.h4.fontSize,
      WebkitTextStrokeColor: THEME_PALETTE.grey.A700,
      WebkitTextStrokeWidth: '1px',
    },
    inactiveIcon: {
      opacity: 0.8,
      color: THEME_PALETTE.common.white,
    },
    loader: {
      margin: 4,
    },
  });
});
