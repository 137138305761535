import { action, Action, Thunk, thunk } from 'easy-peasy';
import { Tag } from 'Models/Tags/@types';
import { AppConstants } from 'Models/App/@types';
// eslint-disable-next-line import/no-cycle
import { TRootStore } from 'Stores';
import AppModel from 'Models/App';
import { TEventSetting } from 'Models/Setting/@types';
import SettingModel from 'Models/Setting';
import TagModel from 'Models/Tags';
import { ChainConfig } from 'Web3/Chains';

interface RouteProps {
  pathname: string;
  search: string;
}

type TWeb3State = Record<string, boolean | object | string | Function>;

export interface TState {
  sidebarHidden: boolean;
  setSidebarHidden: Action<TState, boolean>;
  eventSetting?: TEventSetting;
  fetchEventSetting: Thunk<TState, void, null, TRootStore>;

  appConstants?: AppConstants;
  fetchAppConstants: Thunk<TState, void, null, TRootStore>;

  // eventInfo?: any;
  // fetchEventInfo: any;

  tags: Tag[];
  setTags: Action<TState, Tag[]>;
  tagsSummaries: Tag[];
  setTagsSummaries: Action<TState, Tag[]>;
  fetchTagsSummary: Thunk<TState, void>;

  accountScreenCloseRedirectUrl?: string;
  setAccountScreenCloseRedirectUrl: Action<TState, string | undefined>;

  prevRoutes: RouteProps[];
  setPrevRoutes: Action<TState, Window['location']>;

  web3State: TWeb3State;
  setWeb3State: Action<TState, TWeb3State>;

  chains: ChainConfig | undefined;
  setChains: Action<TState, ChainConfig>;
}

const State: TState = {

  chains: undefined,
  setChains: action((state, payload) => { state.chains = payload }),

  /**
   * Indicates whether sidebar is hidden by overlay of some sort.
   */
  sidebarHidden: false,
  setSidebarHidden: action((state, payload) => {
    state.sidebarHidden = payload;
  }),

  tags: [],
  tagsSummaries: [],
  setTags: action((state, payload) => {
    state.tags = payload;
  }),
  setTagsSummaries: action((state, summary) => {
    state.tagsSummaries = summary;
  }),
  fetchAppConstants: thunk(async (actions, args, { getState }) => {
    const appConstants = await AppModel.fetchAppConstants();
    const state = getState();
    state.appConstants = appConstants;
    const chains = appConstants.Event.supportedNftChains.reduce((prev, curr) => ({
      ...prev,
      [curr.chainId]: {
        name: curr.title,
        urls: curr.rpc,
        chainId: curr.chainId,
        lookupValue: curr.title,
        nativeCurrency: curr.nativeCurrency,
        currencyAddress: curr.currencyAddress,
        isTestNet: curr.testnet,
        chain: curr.chain,
        currencySymbol: curr?.currencySymbol,
      },
    }), {});
    actions.setChains(chains);
  }),
  fetchTagsSummary: thunk(async (actions) => {
    const data = await TagModel.getTagsSummary();
    actions.setTagsSummaries(data);
    actions.setTags(data);
  }),
  fetchEventSetting: thunk(async (actions, args, { getState }) => {
    const eventSetting = await SettingModel.getEventSetting();
    const state = getState();
    state.eventSetting = eventSetting;
  }),
  setAccountScreenCloseRedirectUrl: action((state, payload) => {
    state.accountScreenCloseRedirectUrl = payload;
  }),
  // fetchEventInfo: thunk(async (actions, args, { getState }) => {
  //   const eventInfo = await AppModel.fetchEventInfo();
  //   const state = getState();
  //   state.eventInfo = eventInfo;
  // }),

  /**
   * For accessing previous page
   */
  prevRoutes: [],
  setPrevRoutes: action((state, payload) => {
    if (state.prevRoutes.length >= 10) state.prevRoutes.pop();
    state.prevRoutes = [{ pathname: payload.pathname, search: payload.search }, ...state.prevRoutes];
  }),

  web3State: {},
  setWeb3State: action((state, payload) => {
    state.web3State = payload;
  }),
};

export default State;
