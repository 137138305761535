import React, { FC, SVGAttributes } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

export interface RondayProps extends SVGCIconProps {
  iconProps?: {
    color?: string;
  };
}

const Ronday: FC<RondayProps> = (props) => {
  const { width = 36, height = 36, iconProps = {} } = props;
  const { color } = iconProps;
  return (
    <svg width={width ?? '44'} height={height ?? '44'} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.24701 32.678C0.637796 34.8857 0 36.6123 0 39.3506C0 41.5152 1.80988 44 4.50557 44H25.3137C32.2985 44 44.0035 36.1743 44 22.3243C43.9982 11.244 34.8612 0.0889787 22.3475 0.000587236C8.04655 -0.0995897 0.132622 12.6386 0.132622 21.7606C0.132622 25.2413 1.27576 29.6864 3.24701 32.678ZM4.41331 22.0139C4.41331 12.1495 12.2774 4.15303 21.9756 4.15303C31.6758 4.15303 39.5393 12.1495 39.5393 22.0139C39.5393 31.6938 31.9687 39.5744 22.5194 39.8672C22.3387 39.873 22.158 39.8769 21.9756 39.8769H5.95813C4.20773 39.8769 3.82919 37.9265 4.79956 36.736C6.3058 34.8896 8.26757 33.6936 8.26757 33.6936C8.26757 33.6936 7.16091 31.6153 7.00321 31.354C5.36051 28.6375 4.41331 25.4377 4.41331 22.0139ZM20.7706 19.3958C23.6753 18.8477 25.6013 16.0074 25.0716 13.0532C24.5384 10.097 21.7528 8.14647 18.8477 8.69449C15.9426 9.24252 14.0192 12.0828 14.5497 15.039C15.0799 17.9932 17.8658 19.9438 20.7706 19.3958ZM20.1812 24.4538C18.2655 22.1556 14.8973 21.8708 12.6589 23.8193C10.4205 25.7658 10.1613 29.2091 12.0787 31.5073C13.9943 33.8035 17.3624 34.0884 19.6006 32.1399C21.8388 30.1933 22.0984 26.7499 20.1812 24.4538ZM24.7594 22.4463C23.7718 25.2924 25.2154 28.4176 27.9851 29.4252C30.7548 30.4309 33.8 28.94 34.7875 26.0938C35.7751 23.2477 34.3314 20.1245 31.5635 19.1168C28.7938 18.1092 25.7487 19.6001 24.7594 22.4463Z"
        fill={color ?? '#2CBE9B'}
      />
    </svg>
  );
};

export default Ronday;
