import React, { FC, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useStoreState } from 'Stores';
import 'index.css';
import DropList from 'Features/Drops';
import { SEARCH_ROUTES } from 'Routes/SearchNavigation';
import DropModel from 'Models/Drop';
import PgTypo from 'Components/PgTypo';
import PgButton from 'Components/PgButton';

export interface DropsProps {}

const Drops: FC<DropsProps> = (props) => {
  const classes = useStyles({});
  const { drops, searchTerm } = useStoreState(({ SearchPageStore: { drops, searchTerm } }) => ({ drops, searchTerm }));
  const title = useMemo(() => {
    let suffix = 'Drops';
    if (drops.total === 1) suffix = 'Drop';
    return `${drops.total} ${suffix}`;
  }, [drops]);

  const loadMoreUrl = `${SEARCH_ROUTES.MORE.path}?type=drop&q=${searchTerm}`;

  if (!drops.total) return null;
  return (
    <Box className="container">
      <PgTypo h6 className={classes.title}>
        {title}
      </PgTypo>
      <DropList drops={DropModel.parseDropsList(drops.results)} isLoading={false} />
      {/* <ProfileEventList resourcesAndEvents={drops.results} /> */}
      <Box display="flex" justifyContent="center">
        {/* <Link to={loadMoreUrl} className={classes.btnLink}>
          {' '} */}
        <PgButton href={loadMoreUrl} secondary className={classes.btn}>
          LOAD ALL RESULTS
        </PgButton>
        {/* </Link> */}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
  title: {
    margin: theme.spacing(1.5, 6, 5),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  btnLink: {
    maxWidth: 320,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 220,
    },
  },
  btn: {
    fontWeight: theme.typography.fontWeightBold,
    margin: theme.spacing(7.5, 'auto', 5),
    width: '100%',
    maxWidth: 320,
    fontSize: 16,
    // '&:hover': {
    //   color: theme.palette.common.white,
    // },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 220,
    },
  },
}));

export default Drops;
