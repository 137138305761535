import React, { FC } from 'react'
import { Box, DialogContent, DialogTitle, IconButton } from '@material-ui/core'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { DiscussionDialogBaseProps } from './DiscussionAboutDialog'
import DialogWrapper from 'Components/DialogWrapper';
import PgTypo from 'Components/PgTypo';
import PgIcon from 'Components/PgIcon';
import MessageCard from './MessageCard';
import useConfirmationDialog from 'Hooks/useConfirmationDialog';
import { TMessage } from 'Models/Discussion/@types';
import useAsyncTask from 'Hooks/useAsyncTask';
import { useStoreActions } from 'Stores';
import DiscussionModel from 'Models/Discussion';
import { REPLY_FETCH_LIMIT } from './useDiscussion';
export interface PinnedMessageDialogProps extends DiscussionDialogBaseProps {
  open: boolean;
  onClose: () => void;
}

const PinnedMessageDialog: FC<PinnedMessageDialogProps> = ({open , onClose , discussion , config , isAdmin }) =>{
    const classes = useStyles();
    const withConfirm = useConfirmationDialog();
    const { pinUnpinMessage, updateDiscussions } = useStoreActions(({ DiscussionStore: { pinUnpinMessage, updateDiscussions } }) => ({
      pinUnpinMessage,
      updateDiscussions,
    }));
    const handlePinUnpinTask = useAsyncTask(async (message: TMessage) => {
      if (discussion?.pinnedMessage?.id === message.id && message.isPinned) {
        withConfirm(async () => {
            await pinUnpinMessage({ discussionId: config.activeDiscussionId, message })
            onClose();
        }, {
          body: 'Are you sure you want to unpin this comment?',
          message: 'Unpin comment',
          agreeText: 'CONFIRM',
        });
      }
    });
    const fetchReplies = async (message: TMessage, loadAll = false) => {
      const replyCount = message.repliesCount || 0;
      const currentReplyCount = message.replies?.length || 0;
      if (currentReplyCount < replyCount && discussion) {
        const newReplies = await DiscussionModel.getMessages(config.subject, config.subjectId, config.activeDiscussionId, {
          where: { parentMessageId: message.id },
          skip: currentReplyCount,
          order: 'created ASC',
          limit: loadAll ? replyCount - currentReplyCount : REPLY_FETCH_LIMIT,
          include: ['user'],
        });
        updateDiscussions({
          discussion: DiscussionModel.updateMessageInDiscussion(discussion, {
            ...message,
            replies: [...(message.replies || []), ...newReplies],
          }),
          action: 'UPDATE',
        });
      }
    };
    return (
      <DialogWrapper scroll='paper' PaperProps={{ className: classes.root }} variant="light" open={open} onClose={onClose}>
        <IconButton className={classes.closeIcon} onClick={onClose}>
          <PgIcon icon="icon-close" />
        </IconButton>
        <DialogTitle className={classes.zeroPadding} disableTypography>
          <PgTypo align="center" h5 typoWeight="fontWeightBold">
            Pinned Messages
          </PgTypo>
        </DialogTitle>
        <DialogContent className={classes.content}>
          {discussion?.pinnedMessage ? (
            <Box mt={1} id={discussion.pinnedMessage?.id}>
              <MessageCard
                sendMessage={async () => {}}
                handlePinUnpin={handlePinUnpinTask.run}
                message={discussion?.pinnedMessage}
                isCreator={isAdmin}
                fetchReplies={fetchReplies}
                isMessageByCreator={discussion?.pinnedMessage?.userId === config.creatorId}
                disableReply={true}
                config={config}
                chatPermissions={discussion.permissions}
              />
            </Box>
          ) : null}
        </DialogContent>
      </DialogWrapper>
    );
}

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
      root: {
        width: 600,
        maxHeight: '60vh',
        minHeight : '300px !important',
        padding: theme.spacing(5, 2, 2),
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          height: '100vh',
          maxHeight:'100vh',
          padding: theme.spacing(5, 0, 0),
        },
      },
      closeIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
      },
      zeroPadding: {
        padding: 0,
      },
    });
})

export default PinnedMessageDialog
