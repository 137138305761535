/* eslint-disable react/require-default-props */
import React from 'react';
import { Theme, TypographyProps, Typography, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { FONT_FAMILY } from 'Theme/themeConstants';

export interface TypoProps extends TypographyProps {
  weight?: 'regular' | 'medium' | 'bold' | 'extended-regular' | 'extended-bold';
  underline?: boolean;
  contrast?: boolean;
  limitLines?: number;
  fontFamily?: keyof typeof FONT_FAMILY;
  whiteSpace?: React.CSSProperties['whiteSpace'];
  upperCase?: boolean;
}
const Typo = React.forwardRef<HTMLElement, TypoProps>((props, ref) => {
  const { children, fontFamily, limitLines, weight, whiteSpace, underline = false, contrast = false, upperCase = false, ...typographyProps } = props;
  const theme = useTheme();
  const classes = useStyles(props);
  const style: TypographyProps['style'] = {
    ...typographyProps.style,
    fontFamily: getFontFamily(fontFamily),
    textDecoration: underline ? 'underline' : undefined,
    fontWeight: getFontWeight(theme, weight),
    whiteSpace,
    color: contrast ? 'white' : typographyProps.style?.color,
    overflow: limitLines ? 'hidden' : undefined,
    textOverflow: limitLines ? 'ellipsis' : undefined,
    lineClamp: limitLines,
    WebkitLineClamp: limitLines,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    boxOrient: 'vertical',
    textTransform: upperCase ? 'uppercase' : typographyProps.style?.textTransform,
  };

  // if (typeof props.children === 'string' && isUrl(props.children)) {
  //     return <Link ref={ref} rel="noopener" href={props.children} target="_blank" {...typographyProps} style={{ ...style, cursor: 'pointer' }}>{props.children}</Link>
  // }

  return (
    <Typography ref={ref} color="textPrimary" {...typographyProps} style={style}>
      {children}
    </Typography>
  );
});

const useStyles = makeStyles<Theme, TypoProps>((theme) => ({
  typography: (props) => ({
    ...props.style,
    fontFamily: getFontFamily(props.fontFamily),
    textDecoration: props.underline ? 'underline' : undefined,
    fontWeight: getFontWeight(theme, props.weight),
    whiteSpace: props.whiteSpace,
    color: props.contrast ? 'white' : undefined,
    overflow: props.limitLines ? 'hidden' : undefined,
    textOverflow: props.limitLines ? 'ellipsis' : undefined,
    lineClamp: props.limitLines,
    WebkitLineClamp: props.limitLines,
    display: '-webkit-box',
    boxOrient: 'vertical',
    textTransform: props.upperCase ? 'uppercase' : undefined,
  }),
}));

const getFontWeight = (theme: Theme, weight: TypoProps['weight']) => {
  if (weight === 'bold' || weight === 'extended-bold') return theme.typography.fontWeightBold;
  if (weight === 'medium') return theme.typography.fontWeightMedium;
  if (weight === 'regular') return theme.typography.fontWeightRegular;
  return theme.typography.fontWeightRegular;
};

const getFontFamily = (value: TypoProps['fontFamily']) => {
  if (value) return FONT_FAMILY[value];
  return undefined;
};

const isUrl = (text: string) =>
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gi.test(text);

export default Typo;
