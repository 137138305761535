 /* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { FC, useMemo, useState, useEffect, useRef } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, BoxProps, FormControl, FormControlProps, FormHelperText, InputLabel, MenuItem, Select, TextField, TextFieldProps } from '@material-ui/core';
import { getFieldError, IFieldProps } from 'react-forms';
import PgIcon from 'Components/PgIcon';
import clsx from 'clsx';
import Spacer from 'Components/Spacer';
import get from 'lodash/get';
import { getTransformedCountryList } from 'Constants/CountryList';
import PgTypo, { PgTypoProps } from 'Components/PgTypo';

const RegExp = {
  noSpaces: /^\S*$/,
  allowedSymbols: /^[a-z0-9-_\s]{1,}$/i,
  notStartOrEndWithSymbol: /(?=^[a-z0-9\s]).{0,}(?=[a-z0-9\s]$)/i,
};

const SlugValidationErrors = (text?: string) => {
  return {
    noSpace: `${text ?? 'Username'} cannot include spaces`,
    noUpperCase: `${text ?? 'Username'} cannot include uppercase letters`,
    allowedSymbols: `${text ?? 'Username'} cannot include symbols other than a dash or underscore`,
    startEndSymbol: `${text ?? 'Username'} cannot start or end with a symbol`,
  };
};

export interface InputWithValidStatusFieldProps extends IFieldProps {
  type?: 'phone' | 'input';
  labelClass?: string;
  fieldProps?: {
    BoxProps?: BoxProps;
    horizontalMargin?: number;
    label?: string;
    subtitle?: string;
    helperText?: string;
    disabled?: boolean;
    isValid?: (text: string) => Promise<boolean>;
    validText?: string;
    invalidText?: string;
    TextFieldCustomisationProps?: Omit<TextFieldProps, 'helperText' | 'disabled' | 'handleChange' | 'label' | 'className' | 'name' | 'value'>;
    FormControlCustomisationProps?: Omit<FormControlProps, 'disabled' | 'className'>;
    statusContainerProps?: BoxProps;
    phoneContainerCustomisationProps?: BoxProps;
    labelClass?: string;
    labelClassName?: string;
    textFieldClass?: string;
    subtitleClass?: string;
    helperTextClass?: string;
    showStatusIcon?: boolean;
    inputPropsUnderlineClass?: string;
    inputStatusClass?: string;
    compareDataToShowStatus?: string;
    startAdornment?: React.ReactNode;
    showSlugErrors?: boolean;
    slugErrorPrefix?: string;
    showSeparateErrors?: boolean; // If we use slug and email in same form
    labelProps?: Partial<PgTypoProps>;
  };
}

const InputWithValidStatusField: FC<InputWithValidStatusFieldProps> = (props) => {
  const classes = useStyles();
  const { type = 'input', formikProps } = props;
  const showStatusIcon = props.fieldProps?.showStatusIcon ?? true;
  const BoxProps = props.fieldProps?.BoxProps;
  const isValid = props.fieldProps?.isValid;
  const label = props.fieldProps?.label;
  const subtitle = props.fieldProps?.subtitle;
  const helperText = props.fieldProps?.helperText;
  const subtitleClass = props.fieldProps?.subtitleClass;
  const helperTextClass = props.fieldProps?.helperTextClass;
  const disabled = !!props.fieldProps?.disabled;
  const validText = props.fieldProps?.validText || 'Valid';
  const invalidText = props.fieldProps?.invalidText || 'Invalid';
  const TextFieldCustomisationProps = props.fieldProps?.TextFieldCustomisationProps ?? {};
  const statusContainerProps = props.fieldProps?.statusContainerProps ?? {};
  const FormControlCustomisationProps = props.fieldProps?.FormControlCustomisationProps ?? {};
  const phoneContainerCustomisationProps = props.fieldProps?.phoneContainerCustomisationProps ?? {};
  const key = props.fieldConfig?.valueKey || '-';
  const value = props.formikProps?.values?.[key];
  const compareDataToShowStatus = props.fieldProps?.compareDataToShowStatus;
  const startAdornment = props.fieldProps?.startAdornment;
  const showSlugErrors = props.fieldProps?.showSlugErrors ?? false;
  const showSeparateErrors = props.fieldProps?.showSeparateErrors ?? false;
  const slugErrorPrefix = props.fieldProps?.slugErrorPrefix;
  const labelProps = props.fieldProps?.labelProps ?? {};
  const [_isValid, setIsValid] = useState(true);
  const cur = useRef<string>();
  const [showStatus, setShowStatus] = useState<boolean>(false);
  const error = !!props.formikProps?.errors?.[key];
  // const errorText = props.formikProps?.errors?.[key] || '';
  // const errorText = getFieldError(key, props.formikProps || {});
  const errorText = showSlugErrors ? props.formikProps?.errors?.[key] : getFieldError(key, props.formikProps || {});
  const errors = [];

  if (value && showSlugErrors) {
    if (!RegExp.noSpaces.test(value)) errors.push(SlugValidationErrors(slugErrorPrefix).noSpace);
    if (value !== (value as string)?.toLowerCase()) errors.push(SlugValidationErrors(slugErrorPrefix).noUpperCase);
    if (!RegExp.allowedSymbols.test(value)) errors.push(SlugValidationErrors(slugErrorPrefix).allowedSymbols);
    if (!RegExp.notStartOrEndWithSymbol.test(value)) errors.push(SlugValidationErrors(slugErrorPrefix).startEndSymbol);
  }
  const doValidation = async (value: any) => {
    if (isValid) {
      try {
        const res = await isValid(value);
        setIsValid(res);
        if (props.formikProps) {
          if (showSeparateErrors) {
            props.formikProps.setFieldValue(`error${key}`, !res);
          } else {
            props.formikProps.setFieldValue(`error`, !res);
          }
        }
      } catch (err) {
        setIsValid(false);
        if (props.formikProps) {
          if (showSeparateErrors) {
            props.formikProps.setFieldValue(`error${key}`, true);
          } else {
            props.formikProps.setFieldValue(`error`, true);
          }
        }
      }
    }
  };
  useEffect(() => {
    if (value && cur.current !== value) {
      cur.current = value;
      if (cur.current === compareDataToShowStatus) {
        setShowStatus(false);
      } else {
        setShowStatus(true);
      }
      doValidation(value);
    }
  }, [value]);

  const Status = useMemo(() => {
    return !value || error ? null : (
      <>
        {!showStatusIcon ? null : _isValid ? (
          value === compareDataToShowStatus ? null : (
            <PgIcon styleClass={classes.valid} icon="icon-check-on" />
          )
        ) : (
          <PgIcon styleClass={clsx(classes.invalidIcon, classes.invalid)} icon="icon-add" />
        )}
        <Spacer width={10} />
        <>
          {_isValid ? (
            showStatus ? (
              <PgTypo b4 className={clsx(classes.validTextClass, _isValid ? classes.valid : classes.invalid)}>
                {validText}
              </PgTypo>
            ) : (
              ''
            )
          ) : (
            <PgTypo b4 className={clsx(classes.invalidTextClass, _isValid ? classes.valid : classes.invalid)}>
              {invalidText}
            </PgTypo>
          )}
        </>
      </>
    );
  }, [_isValid, classes.invalid, classes.invalidIcon, classes.valid, invalidText, validText, error, showStatus, value]);

  // console.dir(Status);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formikProps?.handleChange(event);
    formikProps?.setFieldValue(key, event.currentTarget.value.toLowerCase());
  };

  return (
    <Box position="relative" className={classes.root} {...BoxProps} display="flex" flexDirection="column">
      {label || subtitle ? (
        <Box mb={1}>
          <PgTypo b4 color={(errorText && errorText !== ' ') || (showSlugErrors && errors.length) || !_isValid ? 'error' : undefined} {...labelProps}>{label}</PgTypo>

          {subtitle && (
            <PgTypo b4 color={(errorText && errorText !== ' ') || (showSlugErrors && errors.length) || !_isValid ? 'error' : undefined} className={subtitleClass}>
              {subtitle}
            </PgTypo>
          )}
        </Box>
      ) : null}
      {type === 'input' ? (
        <TextField
          {...TextFieldCustomisationProps}
          name={key}
          onBlur={formikProps?.handleBlur}
          onChange={handleChange}
          value={value}
          variant="outlined"
          fullWidth
          error={!!(errorText || errors.length)}
          InputProps={{ startAdornment }}
          disabled={disabled}
        />
      ) : (
        <Box flex={1} display="flex" alignItems="center" {...phoneContainerCustomisationProps}>
          <TextField
            {...TextFieldCustomisationProps}
            disabled={disabled}
            InputLabelProps={{ classes: { root: classes.labelRoot, shrink: classes.labelShrink } }}
            // label={label}
            // className={classes.textField}
            value={get(value, 'phone') || ''}
            name={`${key}.phone`}
            onChange={props.formikProps?.handleChange}
            inputProps={{ startAdornment }}
          />
          <FormControl className={classes.countryCodeInput} disabled={disabled} {...FormControlCustomisationProps}>
            <InputLabel classes={{ shrink: classes.labelShrink, root: classes.labelRoot }} id="label">
              Country code
            </InputLabel>
            <Select
              name={`${key}.countryCode`}
              value={get(value, 'countryCode') || ''}
              labelId="label"
              id="demo-simple-select"
              onChange={props.formikProps?.handleChange}
            >
              {getTransformedCountryList().map((c) => (
                <MenuItem key={c.countryCodeText} value={c.countryCode}>
                  {c.countryCode}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      <Box
        /** position="absolute" */
        /** bottom={-20} */
        /** left={0} */
        width="100%"
        flex={1}
        {...statusContainerProps}
      >
        {(errorText && errorText !== ' ') || errors.length || (_isValid && helperText) ? (
          <>
            {(errorText && errorText !== ' ') || (helperText && !errors.length) ? (
              <FormHelperText error={!!(errorText && errorText !== ' ')}>
                {errorText ?? helperText}
              </FormHelperText>
            ) : null}
            {showSlugErrors && errors.length
              ? errors?.map((e, i) => <FormHelperText error key={i}>{e}</FormHelperText>)
              : null}
          </>
        ) : (
          <>
            <Box display="flex" alignItems="center">
              {Status}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
  },
  textField: {
    flex: 1,
    backgroundColor: '#F8F8F8', // FIXME: Add to theme and use.
    borderBottom: '0px',
    '& input': {
      position: 'relative',
      // color: theme.palette.common.black,
      // top: '-10px',
      // left: '15px',
    },
  },
  invalidIcon: {
    transform: 'rotate(45deg)',
  },
  invalid: {
    color: `${theme.palette.error.main} !important`,
    borderBottom: 'none',
  },
  valid: {
    color: theme.palette.success.main,
  },
  labelRoot: {
    color: theme.palette.grey.A200,
  },
  labelShrink: {
    color: theme.palette.common.black,
  },
  countryCodeInput: {
    flex: 1,
    marginLeft: 40,
  },
  invalidTextClass: {
    textAlign: 'left',
    color: theme.palette.error.main,
    marginLeft: -theme.spacing(1),
  },
  validTextClass: {
    textAlign: 'left',
    color: theme.palette.success.light,
    marginLeft: -theme.spacing(1),
  },
}));

export default InputWithValidStatusField;
