import { Box, makeStyles } from '@material-ui/core';
import { Formik } from 'formik';
import FormActionButton from 'Forms/FormActionButton';
import { NFTDetails } from 'Models/Community/@types';
import React, { FC } from 'react'
import { ISelectProps, MLFormContent } from 'react-forms';
import { CHAINS } from 'Web3/Chains';
import * as Yup from 'yup'

export interface TokenFormProps {
  onSubmit: (data: Partial<NFTDetails>) => void
  editingToken?: Partial<NFTDetails>
  showFormHeader?: boolean;
  onCancel: () => void
}

const TokenForm: FC<TokenFormProps> = (props) => {
  const {onSubmit, editingToken, showFormHeader = true, onCancel} = props

  const classes = useStyles()

  return (
    <Formik validationSchema={validationSchema} onSubmit={onSubmit} initialValues={editingToken ?? {}}>
      {(formikProps) => {
        return (
          <Box>
            <MLFormContent
              schema={[
                showFormHeader
                  ? [
                      {
                        type: 'header',
                        valueKey: 'field-1-header',
                        fieldProps: {
                          subtitle: 'Gate the access of your community with an NFT*',
                          style: { marginTop: 0 },
                          subtitleStyle: { marginBottom: 8 },
                        },
                      },
                    ]
                  : [],
                {
                  type: 'text',
                  valueKey: 'tokenName',
                  fieldProps: {
                    label: 'What is this token called?',
                    fullWidth: true,
                    style: { marginTop: 20 },
                  },
                },
                {
                  type: 'text',
                  valueKey: 'tokenAddress',
                  fieldProps: {
                    label: 'Contract Address',
                    fullWidth: true,
                    style: { marginTop: 20 },
                  },
                },
                {
                  type: 'select',
                  valueKey: 'chainId',
                  styles: { marginTop: 20 },
                  fieldProps: {
                    options: chainIds,
                    label: 'Chain ID',
                    defaultValue: chainIds[0].value,
                  } as ISelectProps['fieldProps'],
                },
                {
                  type: 'image-upload',
                  valueKey: 'image',
                  fieldProps: {
                    label: 'Add an image for your unlockable',
                    imageUploadCropConfig: {
                      aspectRatio: 1,
                    },
                  },
                },
                {
                  type: 'text',
                  valueKey: 'description',
                  fieldProps: {
                    label: 'Description',
                    className: classes.textBox,
                  },
                },
                {
                  type: 'url-field',
                  valueKey: 'marketplaceUrl',
                  fieldProps: {
                    label: 'Link to marketplace',
                    className: classes.textBox,
                  },
                },
              ]}
              settings={{ verticalSpacing: 20 }}
              formikProps={formikProps}
              formId="token-form"
            />
            <FormActionButton
              BoxProps={{ display: 'flex', flexDirection: 'row-reverse', style: { gap: 20 } }}
              fullWidth
              handleSubmit={formikProps.handleSubmit}
              handleCancel={onCancel}
            />
          </Box>
        );
      }}
    </Formik>
  )
}

export default TokenForm;

const useStyles = makeStyles((theme) => ({
  dialogRoot: { '& .MuiPaper-root': { minWidth: 600, [theme.breakpoints.down('sm')]: { minWidth: 'unset' } } },
  textBox: {
    marginTop: 20,
  },
  closeButton: {
    alignSelf: 'flex-end',
  },
}));

const validationSchema = Yup.object({
  tokenName: Yup.string().required('Token name is required'),
  tokenAddress: Yup.string().required('Contract address is required'),
  chainId: Yup.string()
    .required('Please select chainId')
    .oneOf(
      Object.keys(CHAINS).map((key) => CHAINS[key as unknown as number].lookupValue),
      'Please select a valid value',
    )
    .required('Please select a chain id'),
  image: Yup.object().nullable().required('Please upload an image for your unlockable'),
  marketplaceUrl: Yup.string().url('Accepted url format: http(s)://example.com').required('Please enter the url to get the NFT'),
  description: Yup.string().required('Please add a message to users who want to join your community'),
});

export const chainIds = Object.keys(CHAINS).map((key) => ({
  name: `${CHAINS[key as unknown as number].name} (${key})`,
  value: `${CHAINS[key as unknown as number].lookupValue}`,
  chainId: CHAINS[key as unknown as number].chainId,
}));
