import React, { FC } from 'react'
import { SVGCIconProps } from '../SVGCIcon';

const Menu: FC<SVGCIconProps> = (props) => {
    const { color = 'white', height = 24, width = 24 } = props;
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2 5.03872C2 4.74122 2.23005 4.50005 2.51383 4.50005H21.4862C21.7699 4.50005 22 4.74122 22 5.03872C22 5.33622 21.7699 5.57739 21.4862 5.57739H2.51383C2.23005 5.57739 2 5.33622 2 5.03872Z"
                fill={color}
            />
            <path
                d="M2 12C2 11.7025 2.23005 11.4614 2.51383 11.4614H14.8195C15.1033 11.4614 15.3333 11.7025 15.3333 12C15.3333 12.2975 15.1033 12.5387 14.8195 12.5387H2.51383C2.23005 12.5387 2 12.2975 2 12Z"
                fill={color}
            />
            <path
                d="M2.51383 18.4227C2.23005 18.4227 2 18.6639 2 18.9614C2 19.2589 2.23005 19.5 2.51383 19.5H21.4862C21.7699 19.5 22 19.2589 22 18.9614C22 18.6639 21.7699 18.4227 21.4862 18.4227H2.51383Z"
                fill={color}
            />
        </svg>
    )
}

export default Menu;
