import { Box, createStyles, FormHelperText, FormHelperTextProps, IconButton, makeStyles, TextField, TextFieldProps, Theme } from '@material-ui/core';
import PopoverWrapper from 'Components/PopoverWrapper';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import React, { FC, FocusEventHandler, useState } from 'react';
import { getFieldError, IFieldProps } from 'react-forms';
import DateTimeUtils from 'Resources/DateTimeUtils';
import { get } from 'lodash';
import PgIcon, { TPgIcon } from 'Components/PgIcon';
import PgButton, { PgButtonProps } from 'Components/PgButton';
import PgTypo from 'Components/PgTypo';
import { inputStyles } from 'Components/FormFields/inputStyles';
import clsx from 'clsx';

export interface TimepickerProps extends IFieldProps {
  fieldProps?: {
    popoverLabel?: string;
    placeholder?: string;
    timeInputFormat?: string;
    minuteStep?: number;
    showHour?: boolean;
    showMinute?: boolean;
    use12Hours?: boolean;
    onTimeSet?: (time: string) => void;
    timezone?: string;
    textField?: boolean;
    icon?: TPgIcon;
    buttonProps?: PgButtonProps;
    textFieldProps?: TextFieldProps;
    label?: string;
    helperText?: string;
    helperTextProps?: FormHelperTextProps;
    disabled?: boolean;
    onDisableClick?: () => void;
    rootClassName?: string;
    showIcon?: boolean;
  };
}

const TIME_FORMAT = 'HH:mm';

const Timepicker: FC<TimepickerProps> = (props) => {
  const { fieldConfig, formikProps, fieldProps = {} } = props;
  const {
    popoverLabel = 'Timepicker',
    placeholder = 'Select Time',
    timeInputFormat = 'h:mm a',
    minuteStep = 5,
    showHour,
    showMinute,
    use12Hours = true,
    onTimeSet,
    timezone = 'GMT',
    textField,
    icon = 'icon-time',
    buttonProps,
    textFieldProps,
    label,
    helperText,
    helperTextProps,
    disabled,
    onDisableClick,
    rootClassName,
    showIcon = true,
  } = fieldProps;

  const valueKey = fieldConfig?.valueKey || '';
  const value = get(formikProps?.values, valueKey);

  const inputClass = inputStyles();

  const [inputText, setInputText] = useState<string>();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const classes = useStyles();
  const error = getFieldError(valueKey, formikProps || {});

  return (
    <div onClick={disabled ? onDisableClick : undefined} className={rootClassName}>
      {label ? (
        <PgTypo b4 className={clsx(inputClass.label, { [classes.errorLabel]: !!error })}>
          {label}
        </PgTypo>
      ) : null}
      <div className={classes.root}>
        <TimePicker
          defaultOpenValue={moment(new Date(0)).utc()}
          placeholder={placeholder}
          showSecond={false}
          value={value ? moment(new Date(0)).utc().minutes(DateTimeUtils.getMinutes(value)) : undefined}
          className={clsx(inputClass.input, classes.input)}
          onChange={(v) => {
            if (!v) return;
            // eslint-disable-next-line no-unused-expressions
            formikProps?.setFieldValue(valueKey, v.format(TIME_FORMAT));
            // eslint-disable-next-line no-unused-expressions
            onTimeSet?.(v.format(TIME_FORMAT));
          }}
          onClose={() => formikProps?.setFieldTouched(valueKey, true)}
          format={timeInputFormat}
          use12Hours
          // inputReadOnly
          disabled={!!disabled}
          minuteStep={minuteStep}
          popupClassName={classes.popup}
          inputIcon={showIcon && <PgIcon styleClass={classes.inputIcon} icon="icon-time" />}
          allowEmpty
          clearIcon={<></>}
        />
        <div className={classes.helperText}>
          <FormHelperText {...helperTextProps} error={!!error}>
            {error || helperText}
          </FormHelperText>
        </div>
      </div>
    </div>
  );

  // return (
  //   <>
  //     <Box className={classes.root}>
  //       {label ? (
  //         <PgTypo b4 className={clsx(classes.label, { [classes.errorLabel]: !!error })}>
  //           {label}
  //         </PgTypo>
  //       ) : null}
  //       {textField && (
  //         <Box className={classes.inputContainer}>
  //           <IconButton className={classes.inputButton} onClick={(event) => setAnchorEl(event.currentTarget)}>
  //             <PgIcon icon={icon} />
  //           </IconButton>
  //           <TextField
  //             {...textFieldProps}
  //             variant="filled"
  //             aria-describedby={id}
  //             onClick={(event) => setAnchorEl(event.currentTarget)}
  //             placeholder={placeholder}
  //             value={value ? moment(value, timeInputFormat).format(timeInputFormat) : undefined}
  //             InputProps={{ ...textFieldProps?.InputProps, classes: { input: classes.input } }}
  //             fullWidth
  //           />
  //         </Box>
  //       )}
  //       {!textField && (
  //         <PgButton {...buttonProps} aria-describedby={id} onClick={(event) => setAnchorEl(event.currentTarget)}>
  //           {popoverLabel}
  //         </PgButton>
  //       )}
  //       <PopoverWrapper
  //         anchorEl={anchorEl}
  //         id="time-picker"
  //         onClose={() => setAnchorEl(null)}
  //         anchorOrigin={{
  //           vertical: 'top',
  //           horizontal: 'left',
  //         }}
  //         transformOrigin={{
  //           vertical: 'top',
  //           horizontal: 'left',
  //         }}
  //       >
  //         <Box height="170px" width="168px">
  //           <Box padding="5px 0 5px 0" fontSize={16} fontWeight={800} textAlign="center">
  //             {placeholder}
  //           </Box>
  //           {/* <Box display="flex" justifyContent="center"> */}
  //           <Box className={classes.timepickerContainer}>
  //             <TimePicker
  //               focusOnOpen
  //               defaultOpenValue={moment(new Date(0)).utc()}
  //               showSecond={false}
  //               value={value ? moment(new Date(0)).utc().minutes(DateTimeUtils.getMinutes(value)) : undefined}
  //               className={classes.input}
  //               onChange={(v) => {
  //                 if (!v) return;
  //                 // console.log({ settingTime: v.format(TIME_FORMAT) });
  //                 // eslint-disable-next-line no-unused-expressions
  //                 formikProps?.setFieldValue(valueKey, v.format(TIME_FORMAT));
  //               }}
  //               format={timeInputFormat}
  //               use12Hours={use12Hours}
  //               minuteStep={minuteStep}
  //               popupClassName={classes.popup}
  //               allowEmpty
  //               showHour={showHour}
  //               showMinute={showMinute}
  //               clearIcon={<></>}
  //             />
  //           </Box>
  //         </Box>
  //       </PopoverWrapper>
  //       <div className={classes.helperText}>
  //         <FormHelperText {...helperTextProps} error={!!error}>
  //           {error || helperText}
  //         </FormHelperText>
  //       </div>
  //     </Box>
  //   </>
  // );
};

export default Timepicker;

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    // root: {
    //   // display: 'flex',
    //   // width: '100%',
    //   // position: 'relative',
    //   // '& button': {
    //   //   borderTopLeftRadius: 0,
    //   //   borderBottomLeftRadius: 0,
    //   // },
    //   // '& .rc-time-picker-input': {
    //   //   background: 'transparent',
    //   //   position: 'absolute',
    //   //   fontSize: '14px',
    //   //   border: 'none',
    //   //   top: '8px',
    //   //   fontWeight: theme.typography.fontWeightBold,
    //   //   color: theme.palette.common.black,
    //   //   left: '10px',
    //   //   outline: 'none',
    //   // },
    // },
    // label: {
    //   color: theme.palette.common.black,
    // },
    // helperText: {
    //   '& p': {
    //     marginTop: '2px',
    //   },
    // },
    // input: {
    //   marginBottom: 10,
    // },
    // inputContainer: {
    //   position: 'relative',
    // },
    // inputButton: {
    //   position: 'absolute',
    //   right: 0,
    //   top: 4,
    //   borderRadius: 0,
    //   zIndex: 10,
    // },
    // popup: {
    //   zIndex: theme.zIndex.modal + theme.zIndex.snackbar,
    // },
    // timepickerContainer: {
    //   '& .rc-time-picker-panel-select': {
    //     fontSize: '14px !important',
    //     border: '1px solid #000000 !important',
    //   },
    //   '& .rc-time-picker-panel-input-wrap': {
    //     display: 'none !important',
    //   },
    // },

    // old styles   remove after component fixes
    root: {
      // display: 'flex',
      width: '100%',
      position: 'relative',
      '& button': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
      '& .rc-time-picker-input': {
        background: 'transparent',
        position: 'absolute',
        fontSize: '14px',
        border: 'none',
        top: '8px',
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.common.black,
        left: '10px',
        outline: 'none',
      },
    },
    input: {
      fontWeight: 700,
      fontSize: theme.typography.body2.fontSize,
    },
    inputIcon: {
      position: 'absolute',
      right: 10,
      top: 10,
    },
    popup: {
      // background: 'pink',
      marginTop: '45px',
      zIndex: theme.zIndex.modal + theme.zIndex.snackbar,
      '& .rc-time-picker-panel-input-wrap': {
        display: 'block !important',
      },
    },
    helperText: {
      '& p': {
        marginTop: '2px',
      },
    },
    errorLabel: {
      color: theme.palette.error.main,
    },
  }),
);
