/* eslint-disable no-underscore-dangle */
import React, { FC, useContext, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Card, IconButton, InputAdornment } from '@material-ui/core';
import { IFieldProps } from 'react-forms';
import AutoSuggest from 'Components/Inputs/AutoSuggest';
import ResourceModel from 'Models/Resource';
import { get } from 'lodash';
import PgIcon from 'Components/PgIcon';
import { NameValuePair } from 'Typings/Global';
import DraggableList, { DraggableListProps } from 'Components/DraggableList';
import Typo from 'Components/Typo';
import Spacer from 'Components/Spacer';
import { arrayMoveImmutable } from 'array-move';
import helpers from 'Utils/helpers';
import { AppToastContext } from 'Contexts/AppToastContext';
import MESSAGES from 'Utils/Messages';

export interface ResourceSuggestProps extends IFieldProps {
  fieldProps?: {
    // eslint-disable-next-line camelcase
    doc_type?: string;
    userId: string;
    resolverKey?: string;
    fetchSuggestions?: (input: string) => Promise<NameValuePair[]>;
    resolver: (resource: any) => NameValuePair;
  };
}

const ResourceSuggest: FC<ResourceSuggestProps> = (props) => {
  const { fieldProps, fieldConfig, formikProps } = props;
  const classes = useStyles({});
  const { userId } = fieldProps ?? {};
  const docType = fieldProps?.doc_type || 'Video';
  const [selected, setSelected] = useState<NameValuePair[]>([]);
  const { showToast } = useContext(AppToastContext);
  const docIds: string[] = get(formikProps, `values[${fieldConfig?.valueKey}]`) || [];
  const key = fieldConfig?.valueKey || '';

  const handleFetchSuggestions = async (input: string) => {
    // eslint-disable-next-line no-underscore-dangle
    const _fetchSuggestions = fieldProps?.fetchSuggestions;
    if (_fetchSuggestions) return _fetchSuggestions(input);
    const res = await ResourceModel.search(userId ?? '', input, {
      where: { doc_type: docType, featured: true, isPublished: true },
    });
    if (res)
      return (
        res.results.map((res) => ({
          name: res.hit.title,
          value: res.hit.id,
        })) ?? []
      );
    return [];
  };

  useEffect(() => {
    if (!fieldProps?.resolver || !fieldProps.resolverKey) return;
    const resolverData: any[] = helpers.sortByList(get(formikProps, `values[${fieldProps?.resolverKey}]`) || [], docIds);

    const _selected = resolverData.map<NameValuePair>(fieldProps?.resolver);
    // const resolverData: TResource[] = helpers.sortByList(get(formikProps, `values[${fieldProps?.resolverKey}]`) || [], docIds)
    // const _selected = resolverData.map<NameValuePair>(r => ({ name: r.title, value: r.id }))
    setSelected(_selected);
  }, []);

  const handleDelete = (item: NameValuePair) => () => {
    formikProps?.setFieldValue?.(key, [...docIds.filter((d) => d !== item.value)]);
    setSelected((prev) => [...prev.filter((d) => d.value !== item.value)]);
  };

  const renderListItem: DraggableListProps<NameValuePair>['renderListItem'] = (item, index, draggableProvided) => (
    <Card square elevation={2} className={classes.listItem}>
      <Box p={1} display="flex" alignItems="center">
        <div {...draggableProvided.dragHandleProps}>
          <PgIcon color="secondary" icon="icon-drag" />
        </div>
        <Spacer width={24} />
        <Typo variant="body2" weight="bold">
          {item.name}
        </Typo>
      </Box>
      <Box flex={1} />
      <IconButton onClick={handleDelete(item)}>
        <PgIcon color="secondary" icon="icon-trash" />
      </IconButton>
    </Card>
  );

  const handleResultClick = (resource: NameValuePair) => {
    if (docIds.length >= 4) {
      showToast(MESSAGES.MAX_FOUR, { variant: 'error' });
      return;
    }
    if (docIds.includes(resource.value)) {
      showToast(MESSAGES.LIST_PRESENT, { variant: 'error' });
      return;
    }
    formikProps?.setFieldValue?.(key, [...docIds, resource.value]);
    setSelected((prev) => [...prev, { name: resource.name, value: resource.value }]);
  };

  const handleDragEnd: DraggableListProps['handleDragEnd'] = (res, provided) => {
    const { source, destination } = res;
    if (source && destination) {
      formikProps?.setFieldValue?.(key, arrayMoveImmutable(docIds, source.index, destination.index));
      setSelected((prev) => arrayMoveImmutable(prev, source.index, destination.index));
    }
  };

  return (
    <Box className={get(fieldConfig, 'fieldProps.className') || ''}>
      {selected.length ? (
        <Box mb={3}>
          <DraggableList<NameValuePair> id={`${key}drag-list`} handleDragEnd={handleDragEnd} items={selected} renderListItem={renderListItem} />
        </Box>
      ) : null}
      <AutoSuggest<NameValuePair>
        autoCompleteProps={{
          getOptionSelected: (option) => false,
          getOptionLabel: (option) => option.name,
        }}
        retainInputAfterSelect={false}
        disableSearchIcon
        disableClearIcon
        onResultClick={handleResultClick}
        fetchSuggestions={handleFetchSuggestions}
        className={get(fieldConfig, 'fieldProps.className') || ''}
        variant="outlined"
        label={get(fieldConfig, 'fieldProps.label') || ''}
        textFieldInputProps={{
          classes: { adornedEnd: classes.adornedEnd },
          onBlur: () => formikProps?.setFieldTouched(key),
          placeholder: get(fieldConfig, 'fieldProps.placeholder') || '',
          endAdornment: (
            <InputAdornment position="end">
              <PgIcon color="secondary" icon="icon-add" />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
  listItem: {
    display: 'flex',
    alignItems: 'center',
  },
  adornedEnd: {
    paddingRight: '16px !important',
  },
}));

export default ResourceSuggest;
