import config from 'config';
import useToastMessage from 'Hooks/useToastMessage';
import EventModel, { TEvent } from 'Models/Event';
import { parseEvent } from 'Models/Event/eventParsers';
import { useStoreActions, useStoreDispatch, useStoreState } from 'Stores';
import { JSONType } from 'Typings/Global';
import helpers from 'Utils/helpers';
import MESSAGES from 'Utils/Messages';
import { AxiosResponse } from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { TResource } from 'Models/Resource/@types';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import useUserActions from 'Features/User/useUserActions';
import { ANALYTICS_USER_EVENT } from '../../Analytics/analyticActions';

import RemindersModel from '../../Models/Reminders';
import { get } from 'lodash';

export type TContent = TResource & TEvent;

function useEventActions() {
  const { deleteResources } = useStoreActions(({ EventsStore: { deleteResources } }) => ({ deleteResources }));
  const withToast = useToastMessage();
  const { isAuthenticated, appUser } = useStoreState(({ AuthStore: { isAuthenticated, appUser } }) => ({ isAuthenticated, appUser }));
  const { eventFavorited, eventUnFavorited, eventReminded, eventUnReminded } = useStoreActions(
    ({ AuthStore: { eventReminded, eventUnReminded, contentFavorited: eventFavorited, contentUnFavorited: eventUnFavorited } }) => ({
      eventReminded,
      eventUnReminded,
      eventFavorited,
      eventUnFavorited,
    })
  );
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const classes = useStyles({});

  const { pathname } = useLocation();

  const { addFavourite, removeFavourite } = useUserActions();

  const createReminder = async (event: TEvent, _reminderId?: string) => {
    if (!isAuthenticated || !appUser) return;

    let reminderId = '';
    if (!_reminderId) {
      const { data } = await EventModel.post_Events_id_create_reminder_userId(appUser.id, event.id);
      reminderId = data?.id;
    } else {
      reminderId = _reminderId;
    }
    if (event.rsvpUrl) {
      dispatch({ type: ANALYTICS_USER_EVENT, data: { eventName: 'RSVP_URL_ACCESSED', context: { event: event.name, source: pathname } } });
      // if (!event.isPrivate) {
      //     window.open(
      //         event.rsvpUrl,
      //         'RSVP Url'
      //     );
      // }
    }
    eventReminded({ reminderId, event });
  };

  const removeReminder = async (reminderId: string) => {
    if (!isAuthenticated || !appUser) return;
    await withToast<AxiosResponse<any>>(
      async () => RemindersModel.delete_Reminders_id_remove_reminder(reminderId),
      {
        successToastMessage: 'Reminder removed successfully.',
      }
      //   <Button onClick={() => history.push(`/user/${appUser?.slug || appUser?.id}`)} className={classes.toastAction}>
      //     View playlist
      //   </Button>
    );
    eventUnReminded(reminderId);
  };

  // const createFavoriteAndReminder = async (event: TEvent) => {
  //     await createFavorite(event);
  //     await createReminder(event);
  // }

  const copyEventWebUrl = (event?: TEvent) => {
    if (event)
      withToast(() => helpers.copyToClipboard(`${config.get('BASE_URL')}/events/${event.slug}`), {
        successToastMessage: MESSAGES.COPY_TO_CLIPBOARD,
      });
  };

  const updateEvent = async (data: Partial<TEvent>, id: string, toastMessage?: string) =>
    withToast<TEvent>(async () => EventModel.updateEvent({ ...data, id }), {
      successToastMessage: toastMessage ?? MESSAGES.EVENT_UPDATE_SUCCESS,
    });

  const togglePublishEvent = async (event: TEvent, publish: boolean) => {
    const res = (await withToast(async () => EventModel.togglePublishEvent(event.id, publish), {
      successToastMessage: `Event ${publish ? 'published' : 'unpublished'} successfully`,
    })) as Promise<JSONType>;

    return { ...event, ...parseEvent(res) } as TEvent;
  };

  const deleteEvents = async (ids: string[]) => {
    await withToast(
      async () => {
        try {
          await EventModel.deleteEvents(ids);
          deleteResources({ ids });
        } catch (err) {
          console.error(err);
        }
      },
      {
        successToastMessage: MESSAGES.EVENT_DELETE_SUCCESS,
      }
    );
  };

  const createUpdateEvent = async (data: Partial<TEvent>, toastMessage?: string, isTogglePublish?: boolean, withOutToast = false) => {

    const action = async () => await EventModel.postEvent({ ...data }, isTogglePublish)

    if (withOutToast) return await action();

    return await withToast<TEvent>(async () => await action(), {
      successToastMessage: toastMessage ?? MESSAGES.EVENT_CREATE_SUCCESS,
      errorToastMessage: (err) =>
        get(err, 'response.data.error.responseCode') === 'EVENT_DATE_IN_PAST'
          ? MESSAGES.EVENT_DATE_TIME_IN_PAST_ERR
          : get(err, 'response.data.error.message'),
      showApiErrorMsg: false,
    });
  };

  return {
    updateEvent,
    copyEventWebUrl,
    togglePublishEvent,
    deleteEvents,
    createUpdateEvent,
    addFavourite,
    createReminder,
    removeReminder,
    removeFavourite,
    // createFavoriteAndReminder
  };
}

export default useEventActions;

const useStyles = makeStyles<Theme, any>((theme) =>
  createStyles({
    toastAction: {
      color: 'inherit !important',
      fontSize: theme.typography.body2.fontSize,
    },
  })
);
