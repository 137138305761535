import { Box, CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import PgButton from 'Components/PgButton';
import PgDialog from 'Components/PgDialog';
import PgTypo from 'Components/PgTypo';
import useUserActions from 'Features/User/useUserActions';
import useAsyncTask from 'Hooks/useAsyncTask';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import ResourceModel from 'Models/Resource';
import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'Stores';
import { FONT_FAMILY } from 'Theme/themeConstants';
import { Step, TAuthDialogContentProps } from '../@types';
import { TWalletConnectHookProps } from '../useWalletConnect';

const UnlockGated: FC<
  TAuthDialogContentProps & {
    walletMeta: TWalletConnectHookProps['walletMeta'];
    setWalletMeta: TWalletConnectHookProps['setWalletMeta'];
    setWalletStep: Dispatch<SetStateAction<Step | undefined>>;
  }
> = (props) => {
  const { meta, walletMeta, setWalletMeta, step, setWalletStep } = props;
  const { appUser, community: communityInfo } = useStoreState(({ AuthStore: { appUser }, CommunityStore: { community } }) => ({
    appUser,
    community,
  }));
  const { setAppUser, setCommunity } = useStoreActions(({ AuthStore: { setAppUser }, CommunityStore: { setCommunity } }) => ({
    setAppUser,
    setCommunity,
  }));
  const { isDeviceSm } = useMediaQuery();
  useEffect(() => {
    if (step === 'unlock-gated') {
      setWalletStep(undefined);
      setWalletMeta({ subtitle: undefined, title: undefined });
    }
  }, [step]);
  const classes = useStyles({});
  const { joinCommunityProfile } = useUserActions();
  const handleUnlock = useAsyncTask(async () => {
    const { community, isGatedDrop, drop, onDropUnlocked, runExternal, externalCallBack, resourceType } = meta;

    if (runExternal && externalCallBack) {
      await externalCallBack();
      return;
    }

    if (isGatedDrop && drop.id) {
      const { isAuthorized } = await ResourceModel.unlockGatedDrop(drop.id, resourceType);
      if (!isAuthorized) throw new Error('Authorization failed');
      onDropUnlocked?.();
      return;
    }
    if (!community || !appUser) return;
    await joinCommunityProfile(community, appUser, setAppUser, (community) => {
      // if (communityInfo?.id === community.id) {
      setCommunity({ ...community, isFollowed: true });
      // }
    });
  });
  const component = () => {
    if (handleUnlock.status === 'SUCCESS')
      return (
        <PgTypo c4 className={classes.success}>
          Access unlocked
        </PgTypo>
      );
    if (handleUnlock.status === 'ERROR')
      return (
        <PgTypo c4 className={classes.error}>
          NFT not found
        </PgTypo>
      );
    if (handleUnlock.status === 'PROCESSING')
      return (
        <>
          <CircularProgress size={20} />
          <PgTypo c4>Verifying access</PgTypo>
        </>
      );
    return (
      <PgButton primary fullWidth onClick={handleUnlock.run}>
        Unlock
      </PgButton>
    );
  };
  return (
    <PgDialog
      headingContainerProps={{ display: 'flex', justifyContent: 'center', position: 'relative' }}
      headingProps={{ h3: !isDeviceSm, h7: isDeviceSm, align: 'center', typoWeight: 'fontWeightBold', h6: false }}
      subheadingProps={{ className: classes.subtitle, c2: false, b1: true }}
      heading={walletMeta?.heading ?? meta?.heading ?? (meta?.setStep === 'signup' ? 'Get started' : !appUser && 'Already on Playground? Log in.')}
      subheading={
        walletMeta?.subtitle ??
        meta?.subtitle ??
        (!appUser &&
          `The world is your playground. ${meta?.setStep === 'signup' ? 'Sign up' : 'Log in'} with your email or connect your crypto wallet.`)
      }
      body={walletMeta?.body ?? meta?.body}
      actionButtons={
        <Box mt={2} display="flex" alignItems="center" justifyContent="center" gridGap={10}>
          {component()}
        </Box>
      }
    />
  );
};

export default UnlockGated;

const useStyles = makeStyles<Theme, any>((theme) =>
  createStyles({
    loginBtn: {
      width: 'fit-content',
      margin: '0 auto',
      marginBottom: theme.spacing(4),
      '& .MuiButton-label': {
        fontSize: '1rem',
        fontFamily: FONT_FAMILY.secondary,
      },
    },
    btn: {
      marginBottom: theme.spacing(2),
    },
    subtitle: {
      fontFamily: FONT_FAMILY.secondary,
    },
    success: {
      color: theme.palette.success.main,
    },
    error: {
      color: theme.palette.error.main,
    },
  }),
);
