/* eslint-disable react/require-default-props */
import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, alpha } from '@material-ui/core';
import clsx from 'clsx';
import './style.css';
// import LOADER from 'Assets/images/loaders/Loader.json';
import LottieImage from 'Components/LottieImage';
import LOADER from 'Assets/images/loaders/smily_loader.json';

export interface LoaderProps {
  fullScreen?: boolean;
  overlayed?: boolean;
  minSize?: boolean;
}

export const LoaderComp: FC = () => {
  const classes = useStyles({});
  return (
    <div className={classes.imgBox}>
      {/* <img src={LOADER} alt="loader" className={classes.img} /> */}
      <LottieImage lotteJSON={LOADER} />
    </div>
  )
}

const Loader: FC<LoaderProps> = ({ fullScreen = false, overlayed = false, minSize = false }) => {
  const classes = useStyles({});

  return (
    <Box
      className={clsx({
        [classes.fullScreen]: fullScreen,
        [classes.overlayed]: overlayed,
        [classes.minSize]: minSize,
        [classes.root]: !overlayed && !fullScreen && !minSize,
      })}
      display="flex"
      width="100%"
      justifyContent="center"
    >
      {/* <div className="spinner" /> */}
      <LoaderComp />
    </Box>
  );
};

export const LOADER_MAX_SIZE = 200;

const useStyles = makeStyles<Theme, any>((theme) => ({
  fullScreen: {
    display: 'flex',
    alignItems: 'center',
    height: '80vh',
    // [theme.breakpoints.up('sm')]: {
    //   height: `calc(80vh - ${APP_HEADER_HEIGHT}px)`,
    // },
  },
  minSize: {
    height: '40vh',
    display: 'flex',
    alignItems: 'center',
  },
  overlayed: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.appBar + 1000,
    height: '80vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: alpha('#fff', 0.4),
  },
  root: {
    height: '80vh',
    alignItems: 'center',
  },
  imgBox: {
    // margin: `100px auto`,
    maxWidth: LOADER_MAX_SIZE,
    maxHeight: LOADER_MAX_SIZE,
  },
  img: { width: '100%', height: '100%', aspectRatio: '1 / 1', objectFit: 'cover' },
}));

export default Loader;
