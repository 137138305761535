import { generatePath } from 'react-router';
import { APP_ROUTES } from 'Routes';

const withLink = (href: string, body: string) => `<a href="${href}" style="text-decoration: none;">${body}</a>`;

export function renderHtmlStr(name: string, meta: string) {
  const _meta = JSON.parse(meta);
  if (!_meta.slug && !_meta.id) return withLink('#', name);
  const id = _meta.slug ?? _meta.id;
  const link = window.location.origin + generatePath(APP_ROUTES.USER_DETAIL.path, { id });
  return withLink(link, name);
}
