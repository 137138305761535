import { Box, alpha } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import PgButton from 'Components/PgButton';
import Dark from 'Features/DarkTheme';
import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { APP_HEADER_HEIGHT } from '.';
import useNavigation, { NavLinks } from './useNavigation';
import { FONT_FAMILY } from 'Theme/themeConstants';

export interface NavGroupProps {
    navLinks: NavLinks[];
}

const NavGroup: FC<NavGroupProps> = (props) => {

    const { navLinks } = props;

    const classes = useStyles();
    //   const { NAVLINKS } = useNavigation();
    const { pathname } = useLocation();
    //   const links = NAVLINKS.filter((link) => (link.canRender ? link.canRender() : true));
    const links = navLinks.filter((link) => (link.canRender ? link.canRender() : true));

    return (
        <Dark>
            <Box display="flex" alignItems="center">
                {links.map((nl) => {
                    if (nl.label) {
                        if (nl.external) {
                            return (
                                <PgButton quaternary href={nl.url} key={nl.id} isExternalLink hoverUnderline className={classes.navItem}>
                                    {nl.label}
                                </PgButton>
                            );
                        }
                        if (nl.onClick) {
                            return (
                                <PgButton
                                    quaternary
                                    href={nl.url}
                                    key={nl.id}
                                    underlined={pathname === nl.url}
                                    hoverUnderline
                                    className={classes.navItem}
                                    onClick={nl.onClick}
                                >
                                    {nl.label}
                                </PgButton>
                            );
                        }
                        return (
                            <PgButton quaternary href={nl.url} key={nl.id} underlined={pathname === nl.url} hoverUnderline className={classes.navItem}>
                                {nl.label}
                            </PgButton>
                        );
                    }
                })}
            </Box>
        </Dark>
    );
};

const useStyles = makeStyles<Theme>((theme) => ({
    navItem: {
        marginLeft: theme.spacing(4),
        minWidth: 0,
        // marginTop: theme.spacing(1.5),
        // marginBottom: theme.spacing(1.5),
        // textDecoration: 'none',
        // color: theme.palette.common.white,
        // fontSize: theme.typography.body2.fontSize,
        whiteSpace: 'nowrap',
        fontWeight: theme.typography.fontWeightRegular,
        textTransform: 'uppercase',
    },
    active: {
        color: theme.palette.secondary.main,
        fontSize: theme.typography.body2.fontSize,
    },
    btn: {
        height: APP_HEADER_HEIGHT,
        padding: theme.spacing(0, 4),
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        '&:hover': {
            backgroundColor: alpha(theme.palette.secondary.main, 0.8),
        },
    },
}));

export default NavGroup;
