import { Box, ListItem, Tooltip } from '@material-ui/core';
import { createStyles, makeStyles, Theme, alpha } from '@material-ui/core/styles';
import clsx from 'clsx';
import { ChevronLeft } from '@material-ui/icons';
import ToRight from 'Assets/icons/sidebar/to_right.svg';
import SidebarDiscussionMenu from 'Features/Discussion/SidebarDiscussionMenu';
import React, { FC, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStoreState } from 'Stores';
import { BORDER_WHITE } from 'Theme/themeConstants';
import PgTypo from 'Components/PgTypo';
import ECOSYSTEM from 'Assets/icons/ecosystem.svg';
import { generatePath } from 'react-router';
import { APP_ROUTES } from 'Routes';
import UserAvatar from 'Components/UserAvatar';
import HOME_ICON from 'Assets/icons/home.svg';

export interface JoinedCommunityProps {
  onChange: (open: boolean) => void;
}

const JoinedCommunity: FC<JoinedCommunityProps> = ({ onChange }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { community, open } = useStoreState(({ CommunityStore: { community }, DrawerSidebarStore: { open } }) => ({ community, open }));
  const data = useMemo(() => {
    const ecosystemLink = generatePath(APP_ROUTES.COMMUNITY_ECOSYSTEM.path, { communityId: community?.slug ?? community?.id ?? '#' });
    return {
      name: community?.name,

      image: community?._profileImages?.[0]?.url,
      ecosystemLink,
      communityHomeLink: generatePath(APP_ROUTES.COMMUNITY_DETAIL.path, { communityId: community?.slug ?? community?.id ?? '#' }),
      isEcosystemPage: pathname === ecosystemLink,
      showEcosystem: community?.hasEcosystem ?? false,
    };
  }, [community?.id, pathname]);
  const history = useHistory();
  const handleGoBack = () => {
    history.push('/');
  };
  const onHomeClick = () => {
    history.push(data.communityHomeLink);
  };
  const onEcosystemClick = () => {
    history.push(data.ecosystemLink);
  };

  const withToolTip = useCallback(
    (child: JSX.Element, title: string) => (
      <Tooltip title={title} placement="right" arrow>
        {child}
      </Tooltip>
    ),
    [],
  );
  // const isHomePage = pathname.includes('conversations') === false;
  const isHomePage = pathname === data.communityHomeLink;
  const isDisabled = useMemo(() => community?.isDeactivated || community?.isDeletionRequested, [community]);
  const isBanned = useMemo(() => !!community?.isBanned, [community?.id]);
  if (open)
    return (
      <Box>
        <Box borderBottom={BORDER_WHITE}>
          <ListItem button className={clsx(classes.listItem)}>
            <Box display="flex" alignItems="center" onClick={handleGoBack}>
              <ChevronLeft className={classes.backArrow} />
              <UserAvatar name={data.name ?? ''} size={40} url={data.image} />
              <PgTypo b4Bold style={{ marginLeft: 16 }}>
                {data.name}
              </PgTypo>
            </Box>
          </ListItem>
        </Box>
        <Box>
          <ListItem button className={clsx(classes.listItem, { [classes.itemActive]: isHomePage })} onClick={onHomeClick}>
            <Box display="flex" alignItems="center" onClick={handleGoBack}>
              <img src={HOME_ICON} alt='home-icon' width={24} />
              <PgTypo b4 b4Bold={isHomePage} style={{ marginLeft: 16 }}>
                Home
              </PgTypo>
            </Box>
          </ListItem>
          {isDisabled || isBanned ? null : <SidebarDiscussionMenu />}
          {data.showEcosystem && (
            <ListItem button className={clsx(classes.listItem, { [classes.itemActive]: data.isEcosystemPage })} onClick={onEcosystemClick}>
              <Box display="flex" alignItems="center">
                <img src={ECOSYSTEM} alt="Partners Icon" className={classes.iconSize} />
                <PgTypo b4 b4Bold={data.isEcosystemPage} style={{ marginLeft: 16 }}>
                  Partners
                </PgTypo>
              </Box>
            </ListItem>
          )}
        </Box>
      </Box>
    );
  return (
    <Box>
      <Box borderBottom={BORDER_WHITE}>
        <ListItem button onClick={() => onChange(!open)} className={clsx(classes.listItem, classes.collapsed)}>
          <img src={ToRight} alt="" className={classes.toRight} />
        </ListItem>

        <ListItem button className={clsx(classes.listItem, classes.collapsed)}>
          <Box display="flex" alignItems="center" onClick={handleGoBack}>
            <UserAvatar name={data.name ?? ''} size={40} url={data.image} />
          </Box>
        </ListItem>
      </Box>
      <Box>
        {withToolTip(
          <ListItem button className={clsx(classes.listItem, classes.collapsed, { [classes.itemActive]: isHomePage })} onClick={onHomeClick}>
            <Box display="flex" alignItems="center" justifyContent="space-between" onClick={handleGoBack}>
              <img src={HOME_ICON} alt='home-icon' width={24} />
            </Box>
          </ListItem>,
          'Home',
        )}

        {isDisabled || isBanned ? null : <SidebarDiscussionMenu />}

        {data.showEcosystem &&
          withToolTip(
            <ListItem
              button
              className={clsx(classes.listItem, classes.collapsed, { [classes.itemActive]: data.isEcosystemPage })}
              onClick={onEcosystemClick}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <img src={ECOSYSTEM} alt="Partners Icon" className={classes.iconSize} />
              </Box>
            </ListItem>,
            'Partners',
          )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    listItem: {
      padding: theme.spacing(2),
    },
    collapsed: {
      paddingRight: 0,
      paddingLeft: 0,
      justifyContent: 'center',
    },
    itemActive: {
      backgroundColor: alpha(theme.palette.common.black, 0.04),
    },
    iconSize: { width: 24, height: 24, filter: theme.palette.type === 'dark' ? 'invert(100%)' : undefined },
    toRight: { filter: theme.palette.type === 'dark' ? 'invert(100%)' : undefined },
    backArrow: { color: theme.palette.primary.main },
  });
});

export default JoinedCommunity;
