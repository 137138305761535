import useAppUserActions from 'Features/AppUser/useAppUserActions';
import UserModel, { InvitationTypes } from 'Models/User';
import React, { useEffect, useMemo, useState } from 'react';
import { useStoreActions, useStoreDispatch, useStoreState } from 'Stores';
import InvitationDialog from '.';
import useAsyncTask from 'Hooks/useAsyncTask';
import useToastMessage from 'Hooks/useToastMessage';
import CommunityModel from 'Models/Community';
import MESSAGES from 'Utils/Messages';
import { APP_ROUTES } from 'Routes';
import { ANALYTICS_USER_EVENT } from 'Analytics/analyticActions';

const useInvitationDialog = (invitationTo: InvitationTypes, subjectId: string, socialShareText?: string, isCreatorOfCommunity?: boolean) => {
  const [showDialog, setShowDialog] = useState(false);
  const { appUser, inviteLink, community } = useStoreState(({ AuthStore: { appUser }, InviteStore: { inviteLink }, CommunityStore: { community } }) => ({ inviteLink, appUser, community }));
  const { getInviteLink } = useStoreActions(({ InviteStore: { getInviteLink } }) => ({ getInviteLink }));
  const getInviteLinkTask = useAsyncTask(getInviteLink);
  const baseUrl = window.location.href;
  const urlObj = new URL(baseUrl);
  const redirectUrl = `${urlObj.pathname}`;

  const dispatch = useStoreDispatch()

  const isMyCommunity = useMemo(() => Boolean(appUser && UserModel.isManagesCommunity(appUser, subjectId)), [appUser, subjectId]);

  const withToast = useToastMessage();

  useEffect(() => {
    if (invitationTo === 'community' && (!inviteLink || inviteLink?.linkInstance?.communityId !== subjectId || redirectUrl) && isCreatorOfCommunity) {
      getInviteLinkTask.run({ communityId: subjectId, redirectUrl });
    }

    return () => {};
  }, [redirectUrl]);

  const eventInviteLink = useMemo(() => {
    let link = `${urlObj.origin}${urlObj.pathname}`;
    if (appUser?.referCode) link += `?referrer=${appUser?.referCode}`;
    return link;
  }, [appUser?.id, baseUrl]);
  const { inviteUsers, inviteUsersToCommunity } = useAppUserActions();

  const openInvitationDialog = () => {
    setShowDialog(true);
  };
  const closeDialog = () => {
    setShowDialog(false);
  };

  const sendInvites = async (emails: string[], autoVerify?: boolean) => {
    if (invitationTo === 'community') {
      await inviteUsersToCommunity(emails, subjectId, APP_ROUTES.SIGN_UP.path, autoVerify);
      dispatch({
        type: ANALYTICS_USER_EVENT,
        data: { eventName: 'COMMUNITY_INVITE', context: { community, emails, inviteDate: new Date().toISOString(), communityId: community?.id }, source: window.location.pathname },
      });
    } else await inviteUsers(emails, invitationTo, redirectUrl, subjectId);
  }

  const handleCsvUpload = useAsyncTask<{ data: any; allowAutoApprove?: boolean }>(async ({ data, allowAutoApprove }) => {
    await withToast(async () => await CommunityModel.uploadInviteCsv(subjectId, data, allowAutoApprove), {
      successToastMessage: MESSAGES.INVITE_CSV_UPLOAD_SUCCESS,
    });
  });

  return {
    openInvitationDialog,
    component: showDialog ? (
      <InvitationDialog
        socialShareText={socialShareText}
        open={showDialog}
        onClose={closeDialog}
        inviteLink={invitationTo === 'community' ? inviteLink?.link ?? baseUrl : eventInviteLink}
        sendInvites={sendInvites}
        variant={isCreatorOfCommunity === false && invitationTo === 'community' ? 'share' : 'invite'}
        isInviteLinkLoading={getInviteLinkTask.status === 'PROCESSING'}
        onUploadCsv={(data, allowAutoApprove) => handleCsvUpload.run({ data, allowAutoApprove })}
        inviteTo={invitationTo}
        isMyComm={isMyCommunity}
      />
    ) : null,
  };
};
export default useInvitationDialog;
