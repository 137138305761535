import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Dialog, DialogProps, useTheme } from '@material-ui/core';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import clsx from 'clsx';
import { CreateCSSProperties, CSSProperties } from '@material-ui/core/styles/withStyles';

export interface DialogWrapperProps extends DialogProps {
  variant?: 'light' | 'dark';
  zIndex?: CSSProperties['zIndex'];
}

const DialogWrapper: FC<DialogWrapperProps> = (props) => {
  const { children, variant = 'dark', zIndex, fullScreen, disableEscapeKeyDown, PaperProps, BackdropProps, className, ...dialogProps } = props;
  const classes = useStyles({ variant });
  const { isDeviceSm } = useMediaQuery();
  const isFullScreen = fullScreen ?? isDeviceSm;

  const theme = useTheme();

  return (
    <Dialog
      scroll="body"
      fullScreen={isFullScreen}
      {...dialogProps}
      disableEscapeKeyDown={disableEscapeKeyDown}
      PaperProps={{
        ...PaperProps,
        className: clsx(classes.paper, PaperProps?.className, {
          [classes.paperWidthSm]: !isFullScreen,
        }),
      }}
      BackdropProps={{ ...BackdropProps, className: clsx(classes.backdrop, BackdropProps?.className) }}
      onClose={(_event, reason) => {
        if (dialogProps.onClose) {
          if (reason === 'escapeKeyDown' && !disableEscapeKeyDown) dialogProps.onClose(_event, 'escapeKeyDown');
        }
      }}
      style={{ zIndex: zIndex ?? theme.zIndex.modal + 10 }}
    >
      {children}
    </Dialog>
  );
};

const useStyles = makeStyles<Theme, { variant: 'light' | 'dark' }>((theme) => ({
  paper: {
    background: (props) => (props.variant === 'light' ? undefined : theme.palette.primary.main),
    boxShadow: 'none',
    // width: 420,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      // height: `calc(100vh - ${HEADER_HEIGHT_SMALL}px)`
    },
  },
  paperWidthSm: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '1150px !important',
      minHeight: '450px !important',
      // overflowY: 'hidden !important',
    },
  },
  backdrop: {
    // background: GRADIENTS.primaryFaded,
    opacity: 0.7,
  },
}));

export default DialogWrapper;
