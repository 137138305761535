/* eslint-disable react/require-default-props */
import React, { FC, useState, useCallback, useRef } from 'react';
import { Typography, IconButton, darken } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Datetime, { DatetimepickerProps as DateTimePickerProps } from 'react-datetime';
import PgIcon from '../PgIcon';
import 'react-datetime/css/react-datetime.css';
import { inputStyles } from './inputStyles';
import { isString } from 'lodash';
import { COLOR } from 'Theme/themeConstants';

export interface DateInputProps extends DateTimePickerProps {
  label?: string;
  labelClass?: string;
  placeholder?: string;
  inputProps?: React.HTMLProps<HTMLInputElement>;
  showIcon?: boolean;
}

const DateInput: FC<DateInputProps> = (props) => {
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const classes = useStyles({ showCalendar });
  const inputClasses = inputStyles();
  const ref = useRef<any>(null);
  const { label, placeholder, inputProps, labelClass, showIcon = true, ...rest } = props;
  const toggleCalendar = useCallback(() => setShowCalendar(!showCalendar), [showCalendar]);
  // console.log('Date input ref', ref);
  return (
    <>
      {label ? (
        <>
          {isString(label) ? (
            <Typography className={clsx(labelClass, inputClasses.label)} variant="caption">
              {label}
            </Typography>
          ) : (
            label
          )}
        </>
      ) : null}
      <div className={classes.root}>
        {/* <ClickAwayListener
          onClickAway={() => {
            setShowCalendar(false);
          }}
        > */}
        <Datetime
          ref={ref}
          // open={showCalendar}
          timeFormat={false}
          dateFormat="dddd MMM DD, YYYY"
          className={classes.container}
          inputProps={{
            ...inputProps,
            className: clsx(inputClasses.input, classes.input),
            placeholder,
          }}
          closeOnSelect
          closeOnClickOutside
          renderInput={(inputProps, openCalendar, closeCalendar) => {
            return (
              <div className={classes.inputContainer}>
                <input {...inputProps} />
                {/* <TextField
                  InputProps={{ disableUnderline: true }}
                  fullWidth
                  {...inputProps}
                /> */}
                {showIcon && (<IconButton
                  className={classes.inputButton}
                  onClick={() => {
                    if (inputProps?.disabled) return;
                    openCalendar();
                  }}
                >
                  <PgIcon icon="icon-calendar" />
                </IconButton>)}
              </div>
            );
          }}
          {...rest}
        />
        {/* </ClickAwayListener> */}
        {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */}
      </div>
    </>
  );
};

const useStyles = makeStyles<Theme, { showCalendar: boolean }>((theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      '& button': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
    input: {
      fontWeight: 700,
      fontSize: theme.typography.body2.fontSize,
    },
    container: {
      flex: 1,
      '& .rdtPicker': {
        width: '100%',
        // display:(props) => !props.showCalendar ? 'inherit' : 'none',
      },
      '& .rdtDay.rdtActive': {
        backgroundColor: COLOR.tertiary.music,
        color: theme.palette.common.black,
        '&:hover': { backgroundColor: darken(COLOR.tertiary.music, 0.15), color: 'inherit' },
      },
      '& .rdtDay.rdtToday': {
        '&::before': {
          borderBottom: `7px solid ${COLOR.tertiary.music}`,
        },
      },
    },
    MyCustomButton: {
      '&:hover': {
        backgroundColor: 'inherit !important',
      },
    },
    inputContainer: {
      position: 'relative',
    },
    inputButton: {
      position: 'absolute',
      right: 0,
      top: 4,
      borderRadius: 0,
    },
  }),
);

export default DateInput;
