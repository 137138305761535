import { SnackbarOrigin } from '@material-ui/core';
import { get } from 'lodash';
import { useSnackbar, VariantType } from 'notistack';
import ToastMessage, { ToastMessageProps } from 'Components/ToastMessage';

const SUCCESS_TOAST_MESSAGE = 'Success';
const ERROR_TOAST_MESSAGE = 'Something went wrong';

/**
 * A hook for toast handling
 * @returns a function
 * @example
 * const withToast = useToastMessage(); // import useToastMessage first
 * const submitForm = async (formData: any) =>  await withToast(async () => {
 *      // do your async function and throw the error
 *  },
 *  {
 *      showApiErrorMsg: false, // without this flag, error message from api is displayed in toast
 *      successToastMessage: 'Form submitted successfully',
 *      errorToastMessage: 'Error submitting form',
 *  });
 */
export default () => {
  const { enqueueSnackbar } = useSnackbar();

  const withToast = async <T = any>(action: () => any, toastConfig: WithToastConfig = {}, actionComponent?: JSX.Element): Promise<T> => {
    const { errorToastMessage, anchorOrigin, variant = 'info' } = toastConfig;
    try {
      const res = await action();
      const successMsg = toastConfig.genraToastMessage || toastConfig.successToastMessage || SUCCESS_TOAST_MESSAGE;
      const msg = typeof successMsg === 'string' ? ToastMessage({ title: successMsg }) : ToastMessage(successMsg);
      enqueueSnackbar(msg, {
        variant: toastConfig.successToastMessage ? 'success' : variant,
        anchorOrigin,
        action: actionComponent,
        autoHideDuration: toastConfig.autoHideDuration ?? 8000,
      });
      return res;
    } catch (error: any) {
      const errortext =
        // eslint-disable-next-line no-nested-ternary
        typeof errorToastMessage === 'string'
          ? ToastMessage({ title: errorToastMessage })
          : typeof errorToastMessage === 'object'
          ? ToastMessage(errorToastMessage)
          : ToastMessage({ title: errorToastMessage?.(error) as string });
      const msg =
        toastConfig.showApiErrorMsg === false ? errortext || ERROR_TOAST_MESSAGE : get(error, 'response.data.error.message') || error.message;
      enqueueSnackbar(msg, {
        variant: toastConfig.errorToastVariant ?? 'error',
        anchorOrigin: toastConfig.anchorOrigin,
        action: actionComponent,
        autoHideDuration: toastConfig.autoHideDuration ?? 8000,
      });
      throw error;
    }
  };

  return withToast;
};

export interface WithToastConfig {
  successToastMessage?: string | ToastMessageProps;
  genraToastMessage?: string | ToastMessageProps;
  errorToastMessage?: string | ((error: any) => string) | ToastMessageProps;
  showApiErrorMsg?: boolean;
  variant?: VariantType;
  errorToastVariant?: VariantType;
  anchorOrigin?: SnackbarOrigin;
  autoHideDuration?: number;
}
