import { alpha, darken, StyleRules } from '@material-ui/core';
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides';
import { COLOR } from 'Theme/themeConstants';

const Button: Partial<StyleRules<ComponentNameToClassKey['MuiButton']>> = {
  root: {
    borderRadius: 0,
    padding: '7px 19px',
    border: `1px solid transparent`,
  },
  outlined: {
    padding: '7px 19px',
  },
  outlinedPrimary: {
    border: `1px solid ${COLOR.primary.black}`,
    '&:hover': {
      backgroundColor: darken(COLOR.primary.white, 0.1),
    },
    '&:focus': {
      backgroundColor: darken(COLOR.primary.white, 0.15),
    },
    '&.Mui-disabled': {
      border: `1px solid ${COLOR.primary.black}`,
      color: COLOR.primary.black,
      opacity: 0.4,
    },
  },
  text: {
    padding: '8px 20px',
  },
  textPrimary: {
    '&:hover': {
      backgroundColor: darken(COLOR.primary.white, 0.1),
    },
    '&:focus': {
      backgroundColor: darken(COLOR.primary.white, 0.15),
    },
    '&.Mui-disabled': {
      color: COLOR.primary.black,
      opacity: 0.4,
    },
  },
  containedSecondary: {
    backgroundColor: COLOR.tertiary.music,
    color: COLOR.primary.black,
    '&:hover': {
      backgroundColor: darken(COLOR.tertiary.music, 0.15),
      '@media (hover: none)': {
        backgroundColor: darken(COLOR.tertiary.music, 0.15),
      },
    },
    '&:focus': {
      backgroundColor: darken(COLOR.tertiary.music, 0.15),
      border: `1px solid ${COLOR.primary.black}`,
    },
    '&.Mui-disabled': {
      backgroundColor: alpha(COLOR.tertiary.music, 0.4),
      color: alpha(COLOR.primary.black, 0.4),
    },
  },
  containedPrimary: {
    backgroundColor: COLOR.tertiary.music,
    color: COLOR.primary.black,
    '&:hover': {
      backgroundColor: darken(COLOR.tertiary.music, 0.15),
      '@media (hover: none)': {
        backgroundColor: darken(COLOR.tertiary.music, 0.15),
      },
    },
    '&:focus': {
      backgroundColor: darken(COLOR.tertiary.music, 0.15),
      border: `1px solid ${COLOR.primary.black}`,
    },
    '&.Mui-disabled': {
      backgroundColor: alpha(COLOR.tertiary.music, 0.4),
      color: alpha(COLOR.primary.black, 0.4),
    },
  },
};

export default Button;

export const DarkButton: Partial<StyleRules<ComponentNameToClassKey['MuiButton']>> = {
  root: {
    borderRadius: 0,
    padding: '7px 19px',
    border: `1px solid transparent`,
  },
  outlined: {
    padding: '7px 19px',
  },
  outlinedPrimary: {
    border: `1px solid ${COLOR.primary.white}`,
    '&:hover': {
      backgroundColor: darken(COLOR.primary.black, 0.1),
    },
    '&:focus': {
      backgroundColor: darken(COLOR.primary.black, 0.15),
    },
    '&.Mui-disabled': {
      border: `1px solid ${COLOR.primary.white}`,
      color: COLOR.primary.white,
      opacity: 0.4,
    },
  },
  text: {
    padding: '8px 20px',
  },
  textPrimary: {
    '&:hover': {
      backgroundColor: darken(COLOR.primary.black, 0.1),
    },
    '&:focus': {
      backgroundColor: darken(COLOR.primary.black, 0.15),
    },
    '&.Mui-disabled': {
      color: COLOR.primary.white,
      opacity: 0.4,
    },
  },
  containedSecondary: {
    backgroundColor: COLOR.tertiary.music,
    color: COLOR.primary.black,
    '&:hover': {
      backgroundColor: darken(COLOR.tertiary.music, 0.15),
      '@media (hover: none)': {
        backgroundColor: darken(COLOR.tertiary.music, 0.15),
      },
    },
    '&:focus': {
      backgroundColor: darken(COLOR.tertiary.music, 0.15),
      border: `1px solid ${COLOR.primary.white}`,
    },
    '&.Mui-disabled': {
      backgroundColor: alpha(COLOR.tertiary.music, 0.4),
      color: alpha(COLOR.primary.black, 0.4),
    },
  },
  containedPrimary: {
    backgroundColor: COLOR.tertiary.music,
    color: COLOR.primary.black,
    '&:hover': {
      backgroundColor: darken(COLOR.tertiary.music, 0.15),
      '@media (hover: none)': {
        backgroundColor: darken(COLOR.tertiary.music, 0.15),
      },
    },
    '&:focus': {
      backgroundColor: darken(COLOR.tertiary.music, 0.15),
      border: `1px solid ${COLOR.primary.white}`,
    },
    '&.Mui-disabled': {
      backgroundColor: alpha(COLOR.tertiary.music, 0.4),
      color: alpha(COLOR.primary.black, 0.4),
    },
  },
};
