/* eslint-disable no-unused-expressions */
import React, { FC, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, CircularProgress } from '@material-ui/core';
import useEventTicketing, { INFTAsset, PaymentStatus } from 'Features/Ticketing/useEventTicketing';
import Loader from 'Components/Loaders/Loader';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from 'Routes';
import Typo from '../../Components/Typo';
import Spacer from '../../Components/Spacer';
import { TBill } from 'Models/Transactions/@types';
import { TEvent } from 'Models/Event';
import utils from 'Utils';
import { useStoreActions } from 'Stores';
import { BORDER_BLACK } from 'Theme/themeConstants';
import TextReminder from './TextReminder';
import PgButton from 'Components/PgButton';
import PgTypo from 'Components/PgTypo';
import { NFTComp } from 'Screens/Dashboard/DropFormScreen/Sections/NFTTicketing';
import useShowToast from 'Hooks/useShowToast';

export interface TransactionConfirmationProps {
  bill?: TBill | null;
  event?: TEvent;
  totalTickets?: number;
  transactionId?: string;
  paymentStatus?: PaymentStatus;
  owner?: string;
  email?: string;
  eventDetail?: string;
  onTransactionComplete?: () => void;
  tempAccessToken?: string;
  isNewUser?: boolean;
  onSmsActionClick?: () => void;
}

// General Admission(2x)
// Maria Bermudez
// mbermudez@gmail.com

const TransactionConfirmation: FC<TransactionConfirmationProps> = (props) => {
  const {
    totalTickets = 1,
    paymentStatus: _paymentStatus,
    transactionId,
    eventDetail = '',
    owner = '',
    email = '',
    onTransactionComplete,
    event,
    bill,
    tempAccessToken,
    isNewUser,
    onSmsActionClick,
  } = props;
  const classes = useStyles({});
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus | null>(null);
  const { checkForPaymentStatus } = useEventTicketing();
  const [loading, setLoading] = useState(true);

  const [nftMintStatus, setNftMintStatus] = useState<INFTAsset['status']>('PENDING');

  const { authenticate } = useStoreActions(({ AuthStore: { authenticate } }) => ({ authenticate }));

  const getUserLoggedIn = async (token: string) => {
    utils.setAccessToken(token);
    await authenticate({ token });
  };

  const showToast = useShowToast();

  // const count = useRe
  useEffect(() => {
    if (event?.tierType === 'nft' && transactionId) checkForTransactionCompletion(transactionId, tempAccessToken, isNewUser)
    else if (_paymentStatus) {
      if (_paymentStatus.isNewUser && _paymentStatus.tempAccessToken) getUserLoggedIn(_paymentStatus.tempAccessToken);
      setLoading(false);
      setPaymentStatus(_paymentStatus);
    } else if (transactionId) checkForTransactionCompletion(transactionId, tempAccessToken, isNewUser);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId, _paymentStatus]);

  const checkForTransactionCompletion = async (transactionId: string, tempAccessToken?: string, isNewUser?: boolean) => {
    try {
      if (isNewUser && tempAccessToken) {
        await getUserLoggedIn(tempAccessToken);
      }
      const res = await checkForPaymentStatus(transactionId, tempAccessToken);
      if (!res) throw new Error('Null response');
      if (event?.tierType === 'nft') isNFTMinted(transactionId, tempAccessToken, isNewUser)
      setPaymentStatus(res);
      setLoading(false);
      onTransactionComplete?.();
    } catch (error) {
      setTimeout(() => {
        checkForTransactionCompletion(transactionId, tempAccessToken, isNewUser);
      }, 2000);
    }
  };

  const isNFTMinted = async (transactionId: string, tempAccessToken?: string, isNewUser?: boolean) => {
    try {
      const res = await checkForPaymentStatus(transactionId, tempAccessToken);
      const isPending = res.nftAssets?.find(f => f.status === 'PENDING');
      if (isPending || !res.nftAssets) throw new Error('NFT mint pending');
      setPaymentStatus(res);
      const isError = res.nftAssets?.find(f => f.status === 'ERROR');
      setNftMintStatus(isError ? 'ERROR' : 'CLAIMED');
      if (isError) showToast('Sorry, something went wrong!!', undefined, 'error');
    } catch (error) {
      setTimeout(() => {
        isNFTMinted(transactionId, tempAccessToken, isNewUser);
      }, 5000);
    }
  }

  const orderId =
    paymentStatus?.orderId ??
    (paymentStatus as any)?.purchaseTicketInstance?.orderId ??
    paymentStatus?.id ??
    (paymentStatus as any)?.purchaseTicketInstance?.id;

  const ticketPageUrl = orderId ? `${APP_ROUTES.ACCOUNT.path}/tickets/${orderId}` : '#';
  const ticketName = event?.tierType === 'multi' ? bill?.ticketTierInformation?.name : `General Admission`;

  return loading ? (
    <Loader />
  ) : (
    <Box p={3} className={classes.root}>
      <Box>
        <Typo variant="h6" weight="bold">
          You’re all set!
        </Typo>
        <Spacer height={20} />
        <Typo variant="caption">Your order details are as follows:</Typo>
        <Spacer height={20} />
        <Typo variant="h6">
          Order <strong>#{orderId}</strong>
        </Typo>
        <Spacer height={32} />
        <Typo variant="caption">
          {ticketName} ({paymentStatus?.quantity ?? (paymentStatus as any)?.purchaseTicketInstance?.quantity}x)
        </Typo>
        <br />
        <Typo variant="caption">{owner}</Typo>
        <br />
        <Typo variant="caption" weight="medium">
          {email}
        </Typo>
        <Spacer height={45} />
        {eventDetail.length > 0 && event?.featureToggle?.description && (
          <>
            <Typo weight="bold">Event Details</Typo>
            <Spacer height={20} />
            <Typo variant="caption" whiteSpace="pre-wrap" dangerouslySetInnerHTML={{ __html: eventDetail }} />
          </>
        )}
      </Box>

      {event?.tierType === 'nft' && (
        <Box py={2} borderTop={BORDER_BLACK}>
          {paymentStatus?.ticketTier && nftMintStatus === 'CLAIMED' && (
            <>
              <PgTypo c1>Congratulations! You now own this NFT.</PgTypo>
              <NFTComp
                title={event.name ?? paymentStatus.ticketTier.name}
                subtitle={event.description ?? paymentStatus.ticketTier.ticketInstructions}
                img={paymentStatus.ticketTier.pictureUrl ?? ''}
                containerProps={{ alignItems: 'flex-start', mt: 2 }}
              />
            </>
          )}
          {nftMintStatus === 'PENDING' && (
            <Box maxWidth={420} mx='auto'>
              <PgTypo h6 align='center' style={{ marginBottom: 12 }}>NFT is being minted.</PgTypo>
              <PgTypo align='center' b4>
                {/* Please wait and do not refresh this page. NFT is being minted. The expected time is less than a minute. When finished, you’ll be redirected to the order. */}
                Please wait and do not refresh this page. NFT is being minted. The expected time is less than a minute.
              </PgTypo>
              <Box display='flex' mt={4} alignItems='center' justifyContent='center' gridGap={10}>
                <CircularProgress size={20} />
                <PgTypo b4Bold upperCase>processing</PgTypo>
              </Box>
            </Box>
          )}
        </Box>
      )}

      {/* <TextReminder onSmsActionClick={onSmsActionClick} /> */}
      <Box className={classes.footer}>
        <PgButton primary href={ticketPageUrl}>
          View Registration
        </PgButton>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  footer: {
    height: 83,
    display: 'flex',
    // justifyContent: 'space-between',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing('14px', 0),
    // marginLeft: 'auto',
    // borderTop: `1px solid ${THEME_PALETTE.grey['A200']}`,
    borderTop: BORDER_BLACK,
    '& img': {
      position: 'relative',
      top: 4,
      margin: '0px 20px 0px 4px',
    },
  },
}));

export default TransactionConfirmation;
