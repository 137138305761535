/* eslint-disable import/no-cycle */
import { Box, Tooltip } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import PgIcon from 'Components/PgIcon';
import SidebarDiscussionMenu from 'Features/Discussion/SidebarDiscussionMenu';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle

import { DASHBOARD_ROUTES } from 'Routes/DashboardRoutes/AppNavigation';
// eslint-disable-next-line import/no-cycle
import { isLinkActive } from 'Utils/routeUtils';
import { TSidebarLinkItem } from '.';
import HOME_ICON from 'Assets/icons/home.svg';
import DROPS_ICON from 'Assets/icons/drop.svg';
import MEMBERS_ICON from 'Assets/icons/comm-members.svg';
import INSIGHT_ICON from 'Assets/icons/insights.svg';
import INTEGRATION_ICON from 'Assets/icons/sidebar/integration.svg';
import SETTINGS_ICON from 'Assets/icons/settings.svg';
import EDIT_PROFILE_ICON from 'Assets/icons/edit_profile.svg';

export interface NavsProps { }

export const links: TSidebarLinkItem[] = [
  {
    label: 'Overview',
    id: 'overview',
    link: DASHBOARD_ROUTES.OVERVIEW.path,
    icon: 'icon-home',
    tooltip: 'Overview',
    img: HOME_ICON,

  },
  // {
  //   label: 'Profile',
  //   id: 'profile',
  //   link: DASHBOARD_ROUTES.EDIT_COMMUNITY.path,
  //   icon: 'icon-customize',
  //   tooltip: 'Profile',
  //   img: EDIT_PROFILE_ICON,
  // },
  {
    label: 'Drops',
    id: 'events-and-media',
    link: DASHBOARD_ROUTES.EVENTS_LIST.path,
    icon: 'icon-ticket',
    tooltip: 'Drops', // changed the key in tooltips.json from reviewmedia to content management
    img: DROPS_ICON,
  },
  {
    label: '',
    id: 'conversations',
    // link: EVENT_MEDIA_ROUTES.HOME.path,
    link: '',
    icon: 'icon-add',
    tooltip: '', // changed the key in tooltips.json from reviewmedia to content management
    component: SidebarDiscussionMenu,
  },
  {
    label: 'Members',
    id: 'community-management',
    link: DASHBOARD_ROUTES.COMMUNITY_MANAGEMENT.path,
    icon: 'icon-subscriber',
    tooltip: 'Members',
    img: MEMBERS_ICON,
  },
  {
    label: 'Resolution Center',
    id: 'resolution-center',
    link: DASHBOARD_ROUTES.RESOLUTION_CENTER.path,
    icon: 'icon-question',
    tooltip: 'Resolution Center',
  },
  {
    label: 'Integrations',
    id: 'integrations',
    link: DASHBOARD_ROUTES.INTEGRATIONS.path,
    icon: 'icon-email',
    tooltip: 'Integrations',
    img: INTEGRATION_ICON,
  },
  // {
  //   label: 'Payments',
  //   id: 'payments',
  //   link: '/payments',
  //   icon: 'icon-money',
  //   tooltip: 'Payments',
  // },
  {
    label: 'Insights',
    id: 'insights',
    link: DASHBOARD_ROUTES.INSIGHTS.path,
    icon: 'icon-insights',
    tooltip: 'Insights',
    img: INSIGHT_ICON,
  },
  {
    label: 'Community Settings',
    id: 'settings',
    link: DASHBOARD_ROUTES.COMMUNUNITY_SETTINGS.path,
    icon: 'icon-settings',
    tooltip: 'Community Settings',
    allowForDeactivated: true,
    img: SETTINGS_ICON,
  },
  // { label: 'Profile', id: 'profile', link: '/profile', icon: ICONS.events, tooltip: 'tooltip' },
];

const Navs: FC<NavsProps> = () => {
  const classes = useStyles({});

  return (
    <Box width="100%" mt={3} display="flex" flexDirection="column" alignItems="center">
      {links.map((l) => (
        <NavLink className={classes.navLink} key={l.id} to={l.link} isActive={isLinkActive} activeClassName={classes.active}>
          <Tooltip title={l.tooltip} placement="right">
            <div>
              {' '}
              {/** This div is required for tooltip */}
              <PgIcon icon={l.icon} />
            </div>
          </Tooltip>
        </NavLink>
      ))}
    </Box>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  navLink: {
    width: '100%',
    height: 44,
    margin: '4px 0px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    '& i': {
      color: theme.palette.grey[300],
    },
  },
  active: {
    borderLeft: '3px solid black',
    '& i': {
      color: theme.palette.primary.main,
      // backgroundColor: theme.palette.primary['contrastText'],
    },
  },
}));

export default Navs;
