import { createContext, useEffect } from 'react';
import ViewModel from 'Core/@types';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { useStoreActions } from 'Stores';

class SearchViewModel implements ViewModel<SearchViewModelState> {
  Hook = useSearch;

  CtxProvider = SearchContext.Provider;
}

/**
 * Business logic of the screen: Search
 */
function useSearch(): SearchViewModelState {
  const { search } = useLocation();
  const { setSearchTerm } = useStoreActions(({ SearchPageStore: { setSearchTerm } }) => ({ setSearchTerm }));

  useEffect(() => {
    const query = qs.parse(search, { ignoreQueryPrefix: true });
    if (query.q) {
      setSearchTerm(query.q as string);
    }
  }, [search]);

  const state: SearchViewModelState = {};

  return state;
}

export const SearchContext = createContext<SearchViewModelState>({
  // Provide Default values
});

/**
 * These state will be availabe to the subtree via context api
 */
export interface SearchViewModelState {}

export default SearchViewModel;
