import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { IReactFormProps, ReactForm } from 'react-forms';
import * as Yup from 'yup';
import { THEME_PALETTE } from 'Theme/themeConstants';

const formValidation = Yup.object({
  token: Yup.string().required('Code is required'),
});
export interface OtpFormData {
  email: string;
  token: any;
}
export interface OtpFormProps {
  onSubmit: Function;
  isSubmitting?: boolean;
  initialValues?: object;
}

// attachField('otp', <OtpInput />)

const OtpForm: FC<OtpFormProps> = (props) => {
  const classes = useStyles();
  const config: IReactFormProps['config'] = [
    {
      valueKey: 'token',
      type: 'otp',
    },
  ];
  const actionConfig: IReactFormProps['actionConfig'] = {
    submitButtonText: 'SUBMIT',
    submitButtonLayout: 'fullWidth',
    submitButtonProps: {
      size: 'large',
      style: {
        paddingTop: 8,
        paddingBottom: 8,
      },
    },

    containerClassNames: classes.buttonContainer,
  };
  return (
    <ReactForm
      formId="otp-form"
      config={config}
      actionConfig={actionConfig}
      initialValues={props.initialValues || {}}
      isInProgress={props.isSubmitting}
      onSubmit={props.onSubmit}
      validationSchema={formValidation}
      formSettings={{
        verticalSpacing: 20,
      }}
    />
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    buttonContainer: {
      marginTop: 40,
      '& > button': {
        // backgroundColor: THEME_PALETTE.secondary.main,
      },
    },
  });
});

export default OtpForm;
