import React, { FC } from 'react';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import { NFTDetails } from 'Models/Community/@types';
import DialogWrapper from 'Dialogs';
import PgTypo from 'Components/PgTypo';
import PgIcon from 'Components/PgIcon';
import TokenForm from './TokenForm';

export interface TokenDialogOtherProps {
  title?: string;
  subtitle?: string;
  showFormHeader?: boolean;
}

interface TokenDialogProps extends TokenDialogOtherProps {
  editingToken: NFTDetails | undefined | Record<string, never>;
  onClose: () => void;
  onSubmit: (values: Partial<NFTDetails>) => void | Promise<void>;
}

const TokenDialog: FC<TokenDialogProps> = ({ editingToken, onClose, onSubmit, ...otherProps }) => {
  const {
    title = 'Gate Your community',
    subtitle = 'Token gate your community to restrict access to join and conversations.',
    showFormHeader = true,
  } = otherProps;
  const classes = styles();

  return (
    <DialogWrapper open={!!editingToken} variant="light" onClose={onClose} className={classes.dialogRoot}>
      <Box p={2.5} display="flex" flexDirection="column">
        <IconButton className={classes.closeButton} onClick={onClose}>
          <PgIcon icon="icon-close" />
        </IconButton>
        <PgTypo h6 align="center">
          {title}
        </PgTypo>
        <PgTypo component="p" b4 align="center" dangerouslySetInnerHTML={{ __html: subtitle }} />
        <TokenForm editingToken={editingToken} onSubmit={onSubmit} onCancel={onClose} showFormHeader={showFormHeader} />
      </Box>
    </DialogWrapper>
  );
};

export default TokenDialog;

const styles = makeStyles((theme) => ({
  dialogRoot: { '& .MuiPaper-root': { minWidth: 600, [theme.breakpoints.down('sm')]: { minWidth: 'unset' } } },
  textBox: {
    marginTop: 20,
  },
  closeButton: {
    alignSelf: 'flex-end',
  },
}));
