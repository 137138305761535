import  get from 'lodash/get';
import { Picture } from 'Models/Picture/@types';
import { Role, TUserCommunity, User } from './@types';

export const getAvatarImage = (user?: User): Picture | undefined => {
  if (!user) return undefined;
  return (
    // eslint-disable-next-line no-underscore-dangle
    (user.profileImages ?? user._profileImages)?.find((i) => !!i)
  );
};

export const getUserName = (user?: User): string => {
  if (!user) return 'PG User';
  const isUser = !user.userId && get(user, 'approvalStatus') === undefined;
  if (user.slug && isUser) return user.slug;
  if (user.firstName || user.lastName) {
    if (user.lastName) return `${user.firstName || ''} ${user.lastName || ''}`;
    return `${user.firstName || ''}`;
  }
  return user.username || user.name || 'PG User';
};

export const getCommunity = (user: User, communityId: string): TUserCommunity | undefined => {
  if (!user?.userCommunities || !user.userCommunities.length) return undefined;
  const community = user.userCommunities.find((community) => community?.id === communityId);
  return community;
};

export const isManagesCommunity = (user: User, communityId: string): boolean => {
  return Boolean(getCommunity(user, communityId));
};

export const updateUsersRole = (
  users: User[],

  ids: string[],

  role: Role,

  action: 'add' | 'remove'
): User[] => {
  return users;

  /*  [...(users || [])].map((u) =>
    ids.includes(u.id)
      ? {
          ...u,

          roles:
            // eslint-disable-next-line no-nested-ternary

            action === 'add'
              ? u.roles.includes(role)
                ? u.roles
                : u.roles.concat(role)
              : u.roles.includes(role)
              ? u.roles.filter((r) => r !== role)
              : u.roles,
        }
      : u
  ) as User[]; */
};

export function shouldShowFollowDialog(appUser: User, userToFollow: User, dialogShownUserIds: string[]) {
  if (userToFollow.advanceFollow) {
    // if (dialogShownUserIds.includes(userToFollow.id))
    //     return false;
    if (appUser.socialLinks?.instagramUrl && appUser.socialLinks?.twitterUrl && appUser.socialLinks?.facebookUrl) return false;
    return true;
  }
  return false;
}
