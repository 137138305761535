/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TFilter } from 'Models/App/@types';
import { User } from 'Models/User';
import { request } from 'Utils';
import helpers from 'Utils/helpers';
import { TDiscussion, TDiscussionSubject, TMessage } from './@types';
import config from 'config';

const baseUri = 'discussions/';
class DiscussionModel {
  static createDiscussion = (discussion: Partial<TDiscussion>, communityId: string) =>
    request<TDiscussion>({
      url: `Communities/${communityId}/discussion/new`,
      data: discussion,
      method: 'POST',
    });

  static getMessages = (subject: TDiscussionSubject, subjectId: string, discussionId: string, filter?: TFilter) =>
    request<TMessage[]>({
      url: `${baseUri}${subject}/${subjectId}/${discussionId}/messages`,
      params: {
        filter,
      },
    });

  static markDiscussionRead = (discussionId: string, messageId: string) =>
    request({
      url: `Messages/${messageId}/read`,
      params: {
        discussionId,
      },
    });

  static updateDiscussion = (id: string, subject: TDiscussionSubject, data: Partial<TDiscussion>) => {
    let url = '';
    if (subject === 'Community') url = `/Communities/${id}/discussion/${data.id}/update-discussion`;
    else if (subject === 'RefundRequest') url = `/RefundRequests/${id}/update-discussion`;
    else url = `/Resources/${subject}/${id}/update-discussion`;
    return request({
      url: url,
      method: 'PATCH',
      data,
    });
  };

  static updateMessageInDiscussion = (discussion: TDiscussion, message: TMessage) => {
    const newDiscussion = { ...discussion };
    const isReply = !!message.parentMessageId;
    const isPinned = !!message.isPinned;
    const isPinnedMessageReply = discussion.pinnedMessage?.id === message.parentMessageId;
    if (isReply) {
      if (isPinnedMessageReply && discussion.pinnedMessage) {
        newDiscussion.pinnedMessage = {
          ...discussion.pinnedMessage,
          replies: helpers.updateItemList(discussion.pinnedMessage.replies || [], message, 'UPDATE'),
        };
      }
      newDiscussion.messages = discussion.messages.map((i) =>
        i.id === message.parentMessageId ? { ...i, replies: helpers.updateItemList(i.replies || [], message, 'UPDATE') } : i,
      );
    } else {
      if (isPinned) {
        newDiscussion.pinnedMessage = message;
      }
      newDiscussion.messages = helpers.updateItemList(discussion.messages, message, 'UPDATE');
    }
    return newDiscussion;
  };

  static getSingleDiscussion = (id: string, filter?: TFilter) =>
    request<TDiscussion>({
      url: `${baseUri}${id}`,
      params: {
        filter,
      },
    });

  static postMessage = (discussionId: string, subject: TDiscussionSubject, subjectId: string, message: Partial<TMessage>) =>
    request({
      url: `${baseUri}/${discussionId}/${subject}/${subjectId}/post-message`,
      data: {
        ...message,
      },
      method: 'POST',
    });

  static getPinnedMessage = (subject: TDiscussionSubject, subjectId: string, discussionId: string, filter?: TFilter) =>
    request<TMessage>({
      url: `/Discussions/${subject}/${subjectId}/${discussionId}/pinned`,
      params: {
        messageFilter: filter,
      },
    });

  static pinUnPinMessage = (messageId: string, isPinned: boolean) =>
    request<TMessage>({
      url: `/Discussions/${messageId}/pin`,
      method: 'POST',
      data: {
        add: isPinned,
      },
    });

  static deleteMessage = (discussionId: string, messageId: string) =>
    request({
      url: `/Discussions/${discussionId}/message/${messageId}/delete`,
      method: 'DELETE',
    });

  static hideMessage = (discussionId: string, messageId: string, hide: boolean) =>
    request({
      url: `/Discussions/${discussionId}/message/${messageId}/hide`,
      method: 'patch',
      data: {
        hide,
      },
    });

  static banUser = (subject: TDiscussionSubject, subjectId: string, discussionId: string, userId: string, doBan = true) =>
    request<TMessage>({
      url: `/Discussions/${subject}/${subjectId}/${discussionId}/ban`,
      method: 'POST',
      data: {
        userIdToRemove: userId,
        doBan,
      },
    });

  static leaveJoinDiscussion = (discussionId: string, userId: string, action: 'leave' | 'join') =>
    request({
      url: `Discussions/${discussionId}/${userId}/do-room-action`,
      params: {
        action,
      },
    });

  static getMemberStatus = (discussionId: string) =>
    request<{ online: User[]; offline: User[] }>({
      url: `Discussions/${discussionId}/members-status`,
    });

  static deleteDiscussion = (discussionId: string) => {
    return request({
      url: `Discussions/${discussionId}/delete-discussion`,
      method: 'DELETE',
    });
  };

  static getMentions = (id: string, term = '') => {
    return request<{ users: Partial<User>[] }[]>({
      url: `/Discussions/${id}/get-mentions`,
      method: 'GET',
      params: { mention: term },
    });
  };

  static unlockDiscussion = (discussionId: string, password?: string) => {
    return request<{ isAuthorized: boolean }>({
      url: `/Discussions/${discussionId}/authorize`,
      method: 'POST',
      data: { password },
    });
  };

  static getWebhookUrl = (discussionId: string) => {
    return request<{ url: string }>({
      url: `/Discussions/${discussionId}/webhook-url`,
      method: 'GET',
    });
  };

  static deleteLiveStream = async (streamId: string) => {
    const options = { method: 'DELETE', headers: { Authorization: `Bearer ${config.get('LIVEPEER_API_KEY')}` } };
    await fetch(`https://livepeer.studio/api/stream/${streamId}`, options);
  }

  static getVideoChatAuthToken = async () => {
    const res = await request<string>({
      url: '/Discussions/video-chat-auth-token',
      method: 'GET',
    });
    return res;
  }

}

export default DiscussionModel;
