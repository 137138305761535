/* eslint-disable react/require-default-props */
import { alpha, Avatar, Box, List, ListItem, Tooltip, useTheme } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Add, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import ToRight from 'Assets/icons/sidebar/to_right.svg';
import clsx from 'clsx';
import PgIcon, { TPgIcon } from 'Components/PgIcon';
import Spacer from 'Components/Spacer';
import { initialGenerator } from 'Constants/initialGenerator';
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-cycle
import { APP_HEADER_HEIGHT } from 'Features/AppHeader';
import { useHistory, useLocation } from 'react-router-dom';
import { useStoreState } from 'Stores';
import { THEME_PALETTE } from 'Theme/themeConstants';
import { TUserCommunity } from 'Models/User/@types';
import { APP_ROUTES } from 'Routes';
import { ACCOUNTS_ROUTES } from 'Routes/AccountNavigation';
// eslint-disable-next-line import/no-cycle
import Onboarding, { OnboardState } from 'Components/Onboarding';
import PgButton from 'Components/PgButton';
import PgTypo from 'Components/PgTypo';
import useAuthDialog from 'Features/Auth/useAuthDialog';
import OnboardingPopup, { OnboardingPopupProps } from 'Features/Popover/OnboardingPopup';
import { TooltipRenderProps } from 'react-joyride';
import { DRAWER_SIDEBAR_COLLAPSED_WIDTH, DRAWER_SIDEBAR_EXTENDED_WIDTH } from './DrawerSidebar';
import JOIN_COMM_ICON from 'Assets/icons/sidebar/join-comm-icon-collapsed.svg';
import UserAvatar from 'Components/UserAvatar';
import helpers from 'Utils/helpers';
import { GeneralMenuItem } from 'Dialogs/MobileMenu/LogInMenu';
import onboardingContent from 'Constants/onboardingContent';

interface ICommunityProps {
  community: TUserCommunity;
  onClick?: (community: TUserCommunity) => void;
  classes?: Record<string, string>;
  displayName?: boolean;
}

const Community: FC<ICommunityProps> = ({ community, onClick, classes, displayName = true }) => {
  const { pathname } = useLocation();
  const _classes = styles({});
  const withToolTip = useCallback(
    (child: JSX.Element, title: string) => (
      <Tooltip title={title} placement="right" arrow>
        {child}
      </Tooltip>
    ),
    [],
  );
  return (
    <>
      {withToolTip(
        <ListItem
          key={`${community.slug}_community`}
          className={clsx(
            classes?.listItem,
            { [_classes.itemActive]: pathname === `/${community.slug || community.id}` },
            `community_${community.slug}`,
          )}
          onClick={() => onClick?.(community)}
          button
          disabled={community.isDeletionRequested}
        >
          <Box display="flex" alignItems="center" justifyContent={displayName ? '' : 'center'}>
              <UserAvatar
                // eslint-disable-next-line no-underscore-dangle
                url={helpers.getPictureUrl(community?._profileImages?.[0])}
                size={'32px'}
                name={community?.name ?? ''}
                style={{ marginRight: displayName ? 12 : 0 }}
              />
            {displayName && <PgTypo b4>{community.name}</PgTypo>}
          </Box>
        </ListItem>,
        displayName ? '' : community.name,
      )}
    </>
  );
};

interface ICommunitiesProps {
  communities: TUserCommunity[];
  isSeeMore?: boolean;
  onClick?: (community: TUserCommunity) => void;
  classes?: Record<string, string>;
  handleSeeMoreClick?: () => void;
  isExpandedView?: boolean;
}
export const COMMUNITIES_PREVIEW_COUNT = 3;
export const CREATE_COMMUNITY_MAX_COUNT = 5;
const Communities: FC<ICommunitiesProps> = ({ communities, isSeeMore, onClick, classes, handleSeeMoreClick, isExpandedView = false }) => {
  const withToolTip = useCallback(
    (child: JSX.Element, title: string) => (
      <Tooltip title={title} placement="right" arrow>
        {child}
      </Tooltip>
    ),
    [],
  );

  if (!communities || !communities.length) return <div />;
  if (isSeeMore || communities.length < COMMUNITIES_PREVIEW_COUNT + 1) {
    return (
      <>
        {communities.map((community, i) => (
          <Community key={`${community.id}`} community={community} onClick={onClick} classes={classes} displayName={isExpandedView} />
        ))}
        {isSeeMore &&
          withToolTip(
            <ListItem onClick={handleSeeMoreClick} className={classes?.listItem} button>
              <Avatar style={{ width: 32, height: 32, backgroundColor: 'unset', color: 'unset' }}>
                <KeyboardArrowUp className={classes?.seeMoreArrow} />
              </Avatar>
              {isExpandedView && (
                <>
                  <Spacer width={12} />
                  <PgTypo c3 underline>
                    See less
                  </PgTypo>
                </>
              )}
            </ListItem>,
            isExpandedView ? '' : 'See less',
          )}
      </>
    );
  }
  return (
    <>
      {communities.slice(0, COMMUNITIES_PREVIEW_COUNT).map((community) => (
        <Community key={`${community.id}`} community={community} onClick={onClick} classes={classes} displayName={isExpandedView} />
      ))}

      {withToolTip(
        <ListItem onClick={handleSeeMoreClick} className={classes?.listItem} button>
          <Avatar style={{ width: 32, height: 32, backgroundColor: 'unset', color: 'unset' }}>
            <KeyboardArrowDown className={classes?.seeMoreArrow} />
          </Avatar>
          {isExpandedView && (
            <>
              <Spacer width={10} />
              <PgTypo c3 underline>
                See more
              </PgTypo>
            </>
          )}
        </ListItem>,
        isExpandedView ? '' : 'See more',
      )}
    </>
  );
};

export interface DefaultSidebarProps {
  onClick?: (community: TUserCommunity) => void;
  handleChange: (open: boolean) => void;
}

const DefaultSidebar: FC<DefaultSidebarProps> = (props) => {
  const { onClick, handleChange } = props;

  const { appUser, open, sidebarHidden } = useStoreState(({ App: { sidebarHidden }, AuthStore: { appUser }, DrawerSidebarStore: { open } }) => ({
    appUser,
    open,
    sidebarHidden,
  }));
  const { openDialog } = useAuthDialog();
  // const { sendEmailVerificationLink } = useAuth();
  const userCommunities = appUser?.userCommunities ?? [];
  const userFollowedCommunities = (appUser?.followsCommunities ?? []).filter((f) => !f?.isDeactivated);
  const [followCommunitySeeMore, setFollowCommunitySeeMore] = useState(false);
  const [seeMoreComm, setSeeMoreComm] = useState(false);

  const classes = useStyles({ open });

  const history = useHistory();
  const { pathname } = useLocation();

  const handleLinkClick = (link: string, external = false) => {
    // const sidebarStateQuery = new SidebarQuery({ sidebarOpen: String(open) });
    // if (external) window.location.href = `${link}?${sidebarStateQuery.sidebarQueryString}`;
    // else history.push(link);

    history.push(link);
  };

  const handleSeeMoreClick = () => {
    setSeeMoreComm(!seeMoreComm);
  };

  const hideOnboarding = (skipped: boolean = false) => {
    setOnboardState((e) => ({ ...e, run: false, steps: [] }));
    localStorage.setItem(`${appUser?.id || ''}_onboard`, skipped ? 'closed' : `started`);
  };
  const shouldShowOnboarding = () => {
    /**
     * @author Adnan Husain
     * We show this onboarding helper if and only if the onboarding has not been done or initiated and user has only one community.
     */
    const isHelperHidden = localStorage.getItem(`${appUser?.id || ''}_onboard`);
    const userCommunitiesCount = appUser?.userCommunities?.length || 0;
    if (isHelperHidden || userCommunitiesCount !== 1) return;
    setOnboardState({
      tooltipProps: {
        onClick: () => {
          hideOnboarding();
          window.location.href = `/${appUser?.userCommunities?.[0]?.slug}`;
        },
        onClose: () => hideOnboarding(true),
        content: {
          actionText: 'YAY',
          body: 'Click here to build and grow your community with powerful creator tools.',
          title: 'Your community plays here ⚡️',
        },
      },
      run: true,
      steps: [
        {
          target: `.community_${appUser?.userCommunities?.[0]?.slug}`,
          content: 'some content',
          placement: 'right-start',
          disableBeacon: true,
        },
      ],
    });
  };
  const [onboardState, setOnboardState] = useState<OnboardState & { tooltipProps: OnboardingPopupProps }>({
    run: false,
    steps: [],
    tooltipProps: {
      onClick: () => {},
      content: { title: '', actionText: '', body: '' },
    },
  });
  const hideHelperDialog = (isHyperCommFlow = false) => () => {
    setOnboardState((e) => ({ ...e, run: false, steps: [] }));
    localStorage.setItem(`${appUser?.id || ''}_helperState`, `${new Date().getTime()}`);
    // if (isHyperCommFlow) history.push(APP_ROUTES.CREATE_COMMUNITY.path, { flow: 'hyperCommCreate' });
  };
  const shouldShowCreateHelper = () => {
    if (sidebarHidden) return;
    setTimeout(() => {
      /**
       * @author Adnan Husain
       * We show create community helper if and only if the helper is not hidden by user and user has no communities.
       */
      const isHelperHidden = localStorage.getItem(`${appUser?.id || ''}_helperState`);
      const userHasCommunities = !!appUser?.userCommunities?.length;
      if (isHelperHidden || userHasCommunities) {
        // if we don't need to show helper then we check if onboarding is needed
        shouldShowOnboarding();
        return;
      }
      setOnboardState({
        tooltipProps: {
          disableSkip: true,
          onClick: hideHelperDialog(true),
          onClose: hideHelperDialog(),
          // content: {
          //   title: 'Bring your people together.',
          //   body: 'Create a community for party people, internet icons or your fellow adventurers. Then, access creator tools to help you grow!',
          //   actionText: 'GOT IT',
          // },
          content: onboardingContent.createCommunity,
          showCloseIconBtn: true,
        },
        run: true,
        steps: [
          {
            target: '.create-community',
            content: 'some content',
            placement: 'right-start',
            disableBeacon: true,
          },
        ],
      });
    }, 1500);
  };
  const ToolTip = ({ tooltipProps }: TooltipRenderProps) => (
    <div {...tooltipProps}>
      <OnboardingPopup {...onboardState.tooltipProps} />
    </div>
  );
  // useEffect(() => {
  //   if (appUser?.id && appUser?.emailVerified) {
  //     shouldShowCreateHelper();
  //   }
  // }, [appUser?.id, appUser?.emailVerified, sidebarHidden]);

  const links = {
    orders: { name: 'Orders', link: ACCOUNTS_ROUTES.TICKETS.path },
    favorites: { name: 'Favorites', link: `/${appUser?.slug || appUser?.id || ''}/favorite` },
    settings: { name: 'Account Settings', link: ACCOUNTS_ROUTES.SETTINGS.path },
    invites: { name: 'Invites', link: ACCOUNTS_ROUTES.INVITE_FRIENDS.path },
    addNewCommunity: { name: 'Create New Community', link: APP_ROUTES.CREATE_COMMUNITY.path },
  };

  const handleCreateCommunity = () => {
    if (!appUser?.emailVerified) {
      // sendEmailVerificationLink(appUser?.email as string);
      openDialog('verify-email-first');
      return;
    }
    history.push(links.addNewCommunity.link);
  };
  const handleJoinACommunity = () => {
    history.push(APP_ROUTES.COMMUNITIES.path);
  };

  const withToolTip = useCallback(
    (child: JSX.Element, title: string) => (
      <Tooltip title={title} placement="right" arrow>
        {child}
      </Tooltip>
    ),
    [],
  );

  return (
    <>
      <ListItem className={classes.mainNav}>
        <Onboarding
          callback={() => {}}
          run={onboardState.run}
          scrollOffset={300}
          stepIndex={0}
          steps={onboardState.steps}
          tooltipComponent={ToolTip}
          spotlightPadding={0}
          disableScrollParentFix
          // scrollToFirstStep
          styles={{
            options: {
              zIndex: 10000,
            },
            tooltip: {
              width: 'auto',
            },
            spotlight: {
              height: 56,
            },
          }}
        />
        <List style={{ padding: 0, width: '100%' }}>
          {open ? (
            <ListItem className={classes.listItem}>
              <PgTypo c4>My Playground</PgTypo>
            </ListItem>
          ) : (
            <ListItem button className={classes.listItemSm} onClick={() => handleChange(!open)}>
              <img src={ToRight} alt="" className={classes.toRight} />
            </ListItem>
          )}
          {GeneralMenuItem(appUser).filter(f => f.id !== 2).map(g => (
            <Fragment key={g.id}>
              {withToolTip(
                <ListItem
                  key={g.id}
                  button
                  className={clsx(open ? classes.listItem : classes.listItemSm, { [classes.itemActive]: pathname === links.orders.link })}
                  onClick={() => handleLinkClick(g.link ?? '')}
                >
                  {g.imgSrc
                    ? <img src={g.imgSrc} alt={g.imgAlt} width={30} />
                    : typeof g.icon === 'string'
                    ? <PgIcon icon={g.icon as TPgIcon} styleClass={classes.pgIcon} />
                    : g.icon}
                  {open && (
                    <>
                      <Spacer width={10} />
                      <PgTypo b4>{g.name}</PgTypo>
                    </>
                  )}
                </ListItem>,
                !open && typeof g.name === 'string' ? g.name : '',
              )}
            </Fragment>
          ))}
        </List>
      </ListItem>
      <ListItem className={classes.mainNav}>
        <List style={{ padding: 0, width: '100%' }}>
          {open && (
            <ListItem className={classes.listItem}>
              <PgTypo c4>Communities I Manage</PgTypo>
            </ListItem>
          )}

          <Communities
            communities={userCommunities}
            isSeeMore={seeMoreComm}
            onClick={onClick}
            classes={classes}
            isExpandedView={open}
            handleSeeMoreClick={handleSeeMoreClick}
          />
          {userCommunities?.length >= CREATE_COMMUNITY_MAX_COUNT
            ? null
            : withToolTip(
                <ListItem
                  className={clsx(
                    open ? classes.listItem : classes.listItemSm,
                    {
                      [classes.itemActive]: pathname === links.addNewCommunity.link,
                    },
                    'create-community',
                  )}
                  onClick={handleCreateCommunity}
                  button
                >
                  <PgButton primary fullWidth={open} className={classes.createNewBtn}>
                    {open ? links.addNewCommunity.name : <Add style={{ fontSize: 20 }} />}
                  </PgButton>
                </ListItem>,
                open ? '' : links.addNewCommunity.name,
              )}
        </List>
      </ListItem>

      {!!userFollowedCommunities.length && (
        <ListItem className={classes.mainNav}>
          <List style={{ padding: 0, width: '100%' }}>
            {open && (
              <ListItem className={classes.listItem}>
                <PgTypo c4>Communities I’ve Joined</PgTypo>
              </ListItem>
            )}
            {userFollowedCommunities?.length ? (
              <Communities
                communities={userFollowedCommunities}
                onClick={(community) => handleLinkClick(`/${community.slug || community.id}`)}
                classes={classes}
                isSeeMore={followCommunitySeeMore}
                handleSeeMoreClick={() => setFollowCommunitySeeMore(!followCommunitySeeMore)}
                isExpandedView={open}
              />
            ) : (
              withToolTip(
                <ListItem className={classes.listItem}>
                  <PgButton onClick={handleJoinACommunity} variant="outlined" fullWidth={open} className={classes.createNewBtn}>
                    {open ? 'join a community' : <img src={JOIN_COMM_ICON} alt="join-comm-icon" width={16} height={16} />}
                  </PgButton>
                </ListItem>,
                open ? '' : 'Join a community',
              )
            )}
          </List>
        </ListItem>
      )}
    </>
  );
};

export default DefaultSidebar;

const useStyles = makeStyles<Theme, { open: boolean }>((theme) =>
  createStyles({
    root: {
      '& .MuiDrawer-paper': {
        width: (props) => (props.open ? DRAWER_SIDEBAR_EXTENDED_WIDTH : DRAWER_SIDEBAR_COLLAPSED_WIDTH),
        overflowX: 'hidden',
      },
      transition: '200ms ease-in',
    },
    sidebarHeader: {
      backgroundColor: THEME_PALETTE.common.black,
      height: APP_HEADER_HEIGHT,
      marginTop: -8,
      padding: '0 5px',
    },
    mainNav: {
      padding: theme.spacing(2, 0),
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    listItem: {
      padding: theme.spacing(2),
      // marginLeft: (props) => (props.open ? theme.spacing(1) : 0),
      display: (props) => (props.open ? '' : 'flex'),
      justifyContent: (props) => (props.open ? '' : 'center'),
      // '&:first-child': {
      //     marginLeft: 0,
      // },
    },
    listItemSm: {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    pgIcon: {
      fontSize: 30,
    },
    toRight: {
      // width: '100%',
      filter: theme.palette.type === 'dark' ? 'invert(100%)' : undefined,
    },
    itemActive: {
      backgroundColor: alpha(theme.palette.common.black, 0.04),
    },
    tooltip: {
      // '& .MuiTooltip-popper': {
      // backgroundColor: theme.palette.common.black,
      // fontSize: theme.typography.body2.fontSize,
      // },
    },
    createNewBtn: {
      minWidth: (props) => (props.open ? '' : 40),
      width: (props) => (props.open ? '' : 40),
      height: (props) => (props.open ? '' : 40),
    },
    seeMoreArrow: { color: theme.palette.primary.main },
  }),
);

const styles = makeStyles<Theme, any>((theme) =>
  createStyles({
    itemActive: {
      backgroundColor: alpha(theme.palette.common.black, 0.04),
    },
  }),
);
