/* eslint-disable no-underscore-dangle */
import React, { FC } from 'react';
import { Box, makeStyles, Theme, Link as MuiLink } from '@material-ui/core';
import PgTypo from 'Components/PgTypo';
import { TEvent } from 'Models/Event/@types';
import { Link } from 'react-router-dom';
import { COLOR } from 'Theme/themeConstants';
import { IEventPageProps } from './@types';
import { ACCOUNTS_ROUTES } from 'Routes/AccountNavigation';

const TierComp: FC<{ name: string, price?: number, isSoldOut?: boolean }> = (props) => {
  const { name, price, isSoldOut = false } = props;
  const classes = useStyles();
  return (
    <Box className={classes.tierBox}>
      <Box display={'flex'} alignItems="center" gridGap={16}>
        <PgTypo b2>{name}</PgTypo>
        {isSoldOut && <PgTypo b2 color="error">SOLD OUT</PgTypo>}
      </Box>
      {!!price && <PgTypo c2>${price}</PgTypo>}
    </Box>
  )
}

const Tickets: FC<IEventPageProps> = ({ event }) => {
  const classes = useStyles();
  const idxExt: number | undefined = event?._tiers?.findIndex((item) => item.type === 'externalSite');
  const arr: TEvent['purchasedTickets'] = event?.purchasedTickets;
  let displayPurchasedTickets;
  if (typeof arr !== 'undefined') {
    const tierTicketCount = Array.from(
      arr.reduce((m, { name, quantity }) => m.set(name, (m.get(name) || 0) + quantity), new Map()),
      ([name, quantity]) => ({ name, quantity }),
    );
    displayPurchasedTickets = tierTicketCount.map(
      (val) => `${event.tierType === 'multi' ? val.name : 'General Admission'}: ${val.quantity}x - Tickets Purchased`,
    );
  }

  const tierType = event.tierType;

  if (
    typeof idxExt === 'number' &&
    event?.tierType === 'externalSite' &&
    event?._tiers?.[idxExt]?.type === 'externalSite' &&
    typeof event.purchasedTickets === 'undefined'
  ) return null;

  return (
    <Box width="100%">
      {event.purchasedTickets && (
        <Box>
          {displayPurchasedTickets?.map((val) => (
            <PgTypo b2 key={`ticket-${val}`}>
              {val}
            </PgTypo>
          ))}
          <Link to={ACCOUNTS_ROUTES.TICKETS.path} className={classes.ticketLink}>
            <PgTypo b2>View / Manage Registrations</PgTypo>
          </Link>
        </Box>
      )}

      {typeof event.purchasedTickets === 'undefined'
        && tierType !== 'externalSite'
        && event._tiers?.find(f => f.type === tierType)?._ticketTiers?.map((tier, idx) => {
          const { name, price, remainingTickets } = tier;
          const tierName = tierType === 'multi' ? name : tierType === 'free' ? 'Free' : `$${price}`;
          const tierPrice = tierType === 'multi' ? price : undefined;
          return <TierComp key={`tier-${idx}`} name={tierName} price={tierPrice} isSoldOut={remainingTickets === 0} />
        })}

      {typeof idxExt === 'number' &&
        event?.tierType === 'externalSite' &&
        event?._tiers?.[idxExt]?.type === 'externalSite' &&
        typeof event.purchasedTickets === 'undefined' && (
          <Box>
            <PgTypo className={classes.locationLink}>
              <MuiLink target="_blank" href={event._tiers?.[idxExt]?.ticketUrl ?? '/'}>
                <PgTypo b2 className={classes.externalLink}>
                  {event._tiers?.[idxExt]?.ticketUrl}
                </PgTypo>
              </MuiLink>
            </PgTypo>
          </Box>
        )}

      {event._tiers?.length === 0 && typeof event.purchasedTickets === 'undefined' && <PgTypo>Free</PgTypo>}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  locationLink: {
    color: COLOR.primary.black,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  ticketLink: {
    color: COLOR.secondary.blue,
  },
  tiersContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  tierBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  externalLink: {
    wordBreak: 'break-word',
  },
}));

export default Tickets;
