import { TResource } from 'Models/Resource/@types';
import { parseResource, parseResourceFormData } from 'Models/Resource/resourceParsers';
import AxiosUtils, { request } from 'Resources/AxiosUtils';
import { JSONType } from 'Typings/Global';

class PodcastModel {
  static getAllPodcasts = async (params?: Record<string, any>): Promise<TResource[]> => {
    const res = await request<JSONType[]>({
      url: '/podcasts',
      method: 'GET',
      params,
    });
    return res.map(parseResource);
  };

  static getPodcast = async (id: string, params?: Record<string, any>): Promise<TResource> => {
    const res = await request<JSONType>({
      url: `/resources/${id}/find/Podcast`,
      method: 'GET',
      params,
    });
    return parseResource(res);
  };

  static updatePodcast = async (data: Partial<TResource> & { id: string }): Promise<TResource> => {
    const res = await request({
      url: `/podcasts/${data.id}`,
      method: 'patch',
      data: { ...parseResourceFormData(data) },
    }).catch(AxiosUtils.throwError);
    if (!res) return Promise.reject();
    return { ...data, ...parseResource(res) } as TResource;
  };

  static deletePodcasts = async (ids: string[]): Promise<JSONType> => {
    const res = await request<JSONType>({
      url: '/podcasts/trash',
      method: 'DELETE',
      data: { ids },
    });
    return res;
  };

  static togglePublishPodcast = (id: string, publish: boolean): Promise<JSONType> =>
    request<JSONType>({
      url: `/podcasts/${id}/publish`,
      method: 'post',
      params: { publish },
    });
}

export default PodcastModel;
