import React, { useCallback, Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import LoginForm, { LoginFormData } from 'Forms/Login';
import { useStoreState } from 'Stores';
import PgDialog from 'Components/PgDialog';
import { Box, createStyles } from '@material-ui/core';
import PgButton from 'Components/PgButton';
import useAsyncTask from 'Hooks/useAsyncTask';
import { TAuthDialogContentProps } from '../@types';
import useAuth from '../useAuth';
import { TWalletConnectHookProps } from '../useWalletConnect';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { BORDER_GRAY_A900, FONT_FAMILY, THEME_PALETTE } from 'Theme/themeConstants';
import SocialAuth from 'Components/SocialAuth';
import PgTypo from 'Components/PgTypo';
import Spacer from 'Components/Spacer';

const Login: React.FC<
  TAuthDialogContentProps & {
    handleUserLogIn: TWalletConnectHookProps['handleUserLogIn'];
    walletMeta: TWalletConnectHookProps['walletMeta'];
    setWalletMeta: Dispatch<SetStateAction<TWalletConnectHookProps['walletMeta']>>;
  }
> = (props) => {
  const { onDone, changeStep, handleUserLogIn, walletMeta, meta, setMeta } = props;
  const { isDeviceSm } = useMediaQuery();
  const classes = useStyles({ isDeviceSm });
  // const { pathname } = useLocation();
  // const appClasses = useAppStyles();
  const { isAuthenticated, appUser, communityReferral } = useStoreState(
    ({ AuthStore: { isAuthenticated, appUser }, InviteStore: { communityReferral } }) => ({
      isAuthenticated,
      appUser,
      communityReferral,
    }),
  );

  const { login } = useAuth();

  const handleSubmit = async (data: LoginFormData) => {
    if (walletMeta?.email) {
      await handleUserLogIn.run(data);
      if (data.referringCode) {
        window.location.reload(); // Reloading to get latest community details if invite was accepted
      }
      return;
    }
    await login(data.email, data.password, data.referringCode);
    if (data.referringCode) {
      window.location.reload();
    }
  };

  const handleSubmitRunner = useAsyncTask(handleSubmit);
  const loading = handleSubmitRunner.status === 'PROCESSING';

  useEffect(() => {
    if (appUser && appUser.id && !loading) {
      onDone();
      // history.replace('/empty-page');
      // setTimeout(() => {
      //   history.replace(pathname);
      // },10);
    }
  }, [isAuthenticated, loading]);

  const spacingVariant1 = useMemo(() => isDeviceSm ? "20px" : "32px", [isDeviceSm]);
  const spacingVariant2 = useMemo(() => isDeviceSm ? "0px" : "32px", [isDeviceSm]);


  const switchToResetPassword = useCallback(() => {
    changeStep('reset-password');
  }, [changeStep]);

  const switchToSignup = useCallback(() => {
    changeStep('signup');
  }, [changeStep]);

  const handleClick = () => {
    setMeta?.({ ...meta, setStep: 'login' });
    changeStep('wallet-select');
  }

  return (
    <PgDialog
      heading={walletMeta?.loginHeading ?? 'Already on Playground? Log in.'}
      headingContainerProps={{ display: 'flex', justifyContent: 'center', position: 'relative' }}
      headingProps={{ h3: !isDeviceSm, h7: isDeviceSm, align: 'center', typoWeight: 'fontWeightBold', h6: false }}
      subheadingProps={{ className: classes.subtitle, c2: false, b1: true }}
      subheading={walletMeta?.loginSubheading}
      // prev={
      //   !isDeviceSm
      //     ? () => {
      //         changeStep(walletMeta?.prevStep ?? 'wallet-select');
      //       }
      //     : undefined
      // }
      prev={walletMeta?.prevStep === 'web3-email-check' ? () => changeStep('web3-email-check') : undefined}
      body={
        <Box borderBottom={(walletMeta?.prevStep === 'web3-email-check') || isDeviceSm ? undefined : BORDER_GRAY_A900}>
          <SocialAuth rootProps={{ my: spacingVariant1 }} socialAuthStep='LOG_IN' />
          {/* <Box pt={spacingVariant2} pb={spacingVariant1} borderBottom={isDeviceSm ? undefined : BORDER_GRAY_A900}> */}
          <Box className={classes.emptyContainer} />
          {/* </Box> */}
          <Box mt={isDeviceSm ? "42px" : "54px"}>
            <LoginForm
              isSubmitting={loading}
              initialData={{ referringCode: communityReferral?.referCode, email: walletMeta?.email }}
              disabledEmail={!!walletMeta?.email}
              onSubmit={handleSubmit}
            />
          </Box>
          <PgButton quaternary underlined onClick={switchToResetPassword} style={{ marginBottom: spacingVariant1, marginTop: spacingVariant1 }}>
            Forgot your password? Get a reset link
          </PgButton>
        </Box>
      }
      actionButtons={
        walletMeta?.prevStep === 'web3-email-check' ? undefined : (
          <>
            <Box pt={spacingVariant2} pb={spacingVariant1} borderBottom={isDeviceSm ? undefined : BORDER_GRAY_A900}>
              <PgTypo b1 style={{ marginBottom: "20px" }}>Or continue with</PgTypo>
              <PgButton secondary fullWidth onClick={handleClick}>wallet</PgButton>
            </Box>
            {walletMeta?.email ? (
              <></>
            ) : (
              <Box display="flex" my={spacingVariant2} justifyContent="center">
                <PgButton quaternary underlined onClick={switchToSignup} className={classes.signUp}>
                  Need An Account? Sign Up
                </PgButton>
              </Box>
            )}
          </>
        )
      }
      positive={false}
      negative={false}
      isForm
    />
  );
};

const useStyles = makeStyles<Theme, { isDeviceSm?: boolean }>((theme) => ({
  signUp: {},
  emptyContainer: {
    backgroundColor: THEME_PALETTE.grey.A900,
    height: "1px",
  },
  subtitle: {
    fontFamily: FONT_FAMILY.secondary,
    marginBottom: theme.spacing(4),
  },
}));

export default Login;
