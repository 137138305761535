import React, { FC } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

const Discord: FC<SVGCIconProps> = (props) => {
  const { color = 'black', height = 19, width = 25 } = props;
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.69026 15.2895C8.16555 16.0602 7.60262 16.8042 7.00359 17.5187C2.65128 17.3949 1 14.57 1 14.57C1.06186 10.5675 2.0318 6.63124 3.83667 3.05821C5.42318 1.81705 7.35633 1.09985 9.36846 1.0059L9.95821 2.36821M5.54103 3.58308C7.07146 2.91529 8.69377 2.48188 10.3533 2.29744C11.0649 2.20399 11.7826 2.16653 12.5 2.18538C13.2177 2.17043 13.9355 2.21184 14.6467 2.30923C16.3062 2.49367 17.9285 2.92709 19.459 3.59487M15.0536 2.36231L15.6433 1C17.6574 1.09904 19.5908 1.8225 21.1751 3.07C22.9742 6.64043 23.94 10.5724 24 14.57C24 14.57 22.3487 17.3949 17.9964 17.5187C17.3969 16.7985 16.834 16.0486 16.3097 15.2718M20.1195 13.5615C18.5402 14.5121 16.8205 15.2067 15.0241 15.6197C14.1929 15.7835 13.3472 15.8625 12.5 15.8556C11.6528 15.8625 10.8071 15.7835 9.9759 15.6197C8.17951 15.2067 6.45979 14.5121 4.88051 13.5615M8.87308 8.47795C8.58689 8.4849 8.30531 8.55164 8.04645 8.67389C7.78759 8.79614 7.55717 8.9712 7.37 9.18781C7.18282 9.40442 7.04304 9.65779 6.95962 9.93165C6.87621 10.2055 6.85101 10.4938 6.88564 10.7779C6.90948 11.283 7.12438 11.7602 7.48682 12.1128C7.84926 12.4653 8.33215 12.667 8.83769 12.6769C9.12546 12.6704 9.40874 12.6042 9.66963 12.4826C9.93053 12.361 10.1634 12.1866 10.3535 11.9705C10.5436 11.7544 10.6868 11.5012 10.774 11.2269C10.8613 10.9526 10.8908 10.6632 10.8605 10.3769C10.8267 9.86921 10.6039 9.39258 10.236 9.04106C9.86807 8.68953 9.38181 8.48862 8.87308 8.47795ZM16.1269 8.47795C16.4131 8.4849 16.6947 8.55164 16.9535 8.67389C17.2124 8.79614 17.4428 8.9712 17.63 9.18781C17.8172 9.40442 17.957 9.65779 18.0404 9.93165C18.1238 10.2055 18.149 10.4938 18.1144 10.7779C18.0905 11.283 17.8756 11.7602 17.5132 12.1128C17.1507 12.4653 16.6679 12.667 16.1623 12.6769C15.8745 12.6704 15.5913 12.6042 15.3304 12.4826C15.0695 12.361 14.8366 12.1866 14.6465 11.9705C14.4564 11.7544 14.3132 11.5012 14.226 11.2269C14.1387 10.9526 14.1092 10.6632 14.1395 10.3769C14.1733 9.86921 14.3961 9.39258 14.764 9.04106C15.1319 8.68953 15.6182 8.48862 16.1269 8.47795Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default Discord;
