import { JSONType } from './../../Typings/Global';
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import { action, Action, actionOn, ActionOn, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';
import { CategorizedNotifications, NotificationConfigurations, NotificationTabs } from 'Features/Notifications/types';
import EventQuery from 'Features/Query/EventQuery';
import { omit } from 'lodash';
import { TFilter, TNotification, NotificationPreferences } from 'Models/App/@types';
import CommunityModel from 'Models/Community';
import { TCommunity } from 'Models/Community/@types';
import ResourceModel from 'Models/Resource';
import { ResourceResponse, TResourceResponseResult } from 'Models/Resource/@types';
import RoleUtils from 'Utils/RoleUtils';
import { TRootStore } from '..';
import { TEvent } from '../../Models/Event';
import UserModel, { User } from '../../Models/User';

export interface TUserState {
  userDetails?: User;
  setUserDetails: Action<TUserState, User | TCommunity | undefined>;
  userResources?: ResourceResponse;
  query: EventQuery;

  page: number;
  setPage: Action<TUserState, number>;
  setQuery: Action<TUserState, EventQuery>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onQueryChange: ThunkOn<TUserState, {}, TRootStore>;
  loading: boolean;
  setLoading: Action<TUserState, boolean>;

  fetchSingleUser: Thunk<TUserState, string, any, TRootStore>;
  fetchCommunity: Thunk<TUserState, string, any, TRootStore>;

  userCreatedEvents: TEvent[];
  savedEvents: TEvent[];
  setUserCreatedEvents: Action<TUserState, TEvent[]>;
  setSavedEvents: Action<TUserState, TEvent[]>;
  fetchSavedEvents: Thunk<TUserState, void>;

  onContentFavorite: ActionOn<TUserState, TRootStore>;
  onContentUnFavorite: ActionOn<TUserState, TRootStore>;
  onEventRemind: ActionOn<TUserState, TRootStore>;
  onEventUnRemind: ActionOn<TUserState, TRootStore>;

  fetchUserResourcesAndEvents: Thunk<TUserState, { query: EventQuery; page: number }>;
  setUserResources: Action<TUserState, ResourceResponse | undefined>;

  error: unknown | undefined;
  setError: Action<TUserState, unknown>;

  readNotification: Thunk<TUserState, string, null, TRootStore, Promise<void>>;
  isLoadingNotifications?: boolean;
  setIsLoadingNotifications: Action<TUserState, boolean>;

  notificationsCount: number;
  getNotificationsCount: Thunk<TUserState, JSONType, null, TRootStore, Promise<void>>;
  setNotificationsCount: Action<TUserState, number>;

  notifications: CategorizedNotifications;
  getNotifications: Thunk<TUserState, { type: keyof CategorizedNotifications; filter?: TFilter }, null, TRootStore, Promise<void>>;
  setNotificationsForType: Action<TUserState, { type: keyof CategorizedNotifications; notifications: TNotification[] }>;
  addNotifications: Action<TUserState, { types: (keyof CategorizedNotifications)[]; notifications: TNotification[] }>;
  setNotifications: Action<TUserState, CategorizedNotifications>;

  isLoadingNotificationPreferences: boolean;
  setIsLoadingNotificationPreferences: Action<TUserState, boolean>;

  notificationPreferences: NotificationPreferences;
  getNotificationPreferences: Thunk<TUserState, void>;
  setNotificationPreferences: Action<TUserState, NotificationPreferences>;
  updateNotificationPreferences: Thunk<TUserState, NotificationPreferences>;

  notificationConfigurations: NotificationConfigurations;
  getNotificationConfigurations: Thunk<TUserState, void>;
  setNotificationConfigurations: Action<TUserState, NotificationConfigurations>;
  increaseUnreadNotifCount: Thunk<TUserState, number, void, TRootStore, void>;
  resetUnreadNotifsCount: Thunk<TUserState, void, null, TRootStore, Promise<void>>;
}

const dropShouldFilter = [
  {
    match: {
      featured: true,
    },
  },
  {
    match: {
      isUserFeatured: true,
    },
  },
];

const UserStore: TUserState = {
  // TODO: remove userCreatedEvents & savedEvents
  loading: false,
  userCreatedEvents: [],
  savedEvents: [],
  notificationsCount: 0,
  setUserDetails: action((state, payload) => {
    state.userDetails = payload;
  }),
  page: 0,
  query: new EventQuery({}, {}, ''),
  error: undefined,

  setError: action((state, payload) => {
    state.error = payload;
  }),

  setPage: action((state, payload) => {
    state.page = payload;
  }),
  fetchSingleUser: thunk(async (actions, params, { getStoreState, getStoreActions }) => {
    const { appUser } = getStoreState().AuthStore;
    try {
      const data = await UserModel.getSingleUser(params, {
        filter: {
          include: 'roles',
        },
      });
      actions.setUserDetails(data);
      if (appUser?.id === data.id) {
        getStoreActions().AuthStore.setAppUser({ ...appUser, followerResult: data.followerResult });
      }
      return data;
    } catch (error) {
      actions.setError(error);
    }
  }),
  fetchCommunity: thunk(async (actions, params, { getStoreState, getStoreActions }) => {
    try {
      const data = await CommunityModel.getCommunityById(params);
      actions.setUserDetails(data);
      return data;
    } catch (error) {
      actions.setError(error);
    }
  }),
  setSavedEvents: action((state, payload) => {
    state.savedEvents = [...payload];
  }),
  setUserCreatedEvents: action((state, payload) => {
    state.userCreatedEvents = payload;
  }),
  fetchSavedEvents: thunk(async (actions, _params, { getState }) => {
    const user = getState().userDetails;
    if (!user) return;
    const data = await UserModel.getFavorites<TEvent>(user.id, {
      where: { subjectType: 'Event' },
      include: {
        relation: 'subject',
        scope: {
          include: ['tags', 'user'],
        },
      },
      limit: 50,
    });
    if (!RoleUtils.isRegularUser(user)) {
      const myCreatedEvents = await UserModel.getCreatedEvent(user.id, {
        include: ['user', 'tags'],
        limit: 50,
      });
      actions.setUserCreatedEvents(myCreatedEvents);
    }
    actions.setSavedEvents(data.filter((d) => Boolean(d.subject)).map((d) => d.subject!));
  }),

  onContentFavorite: actionOn(
    (actions, storeActions) => storeActions.AuthStore.contentFavorited,
    (state, { payload: { event, favouriteId } }) => {
      // state.userCreatedEvents = state.userCreatedEvents.map((e) => (e.id === event.id ? { ...e, ...event, isFavourited: true, favouriteId } : e));
      // state.userResources = {
      //   ...state.userResources,
      //   results:
      //     state.userResources?.results?.map((e) =>
      //       e.hit.id === event.id ? { ...e, hit: { ...e.hit, ...event, isFavourited: true, favouriteId } } : e
      //     ) ?? [],
      //   total: state.userResources?.total ?? 0,
      //   aggregations: state.userResources?.aggregations ?? {},
      // };
    }
  ),
  onContentUnFavorite: actionOn(
    (actions, storeActions) => storeActions.AuthStore.contentUnFavorited,
    (state, { payload }) => {
      state.userCreatedEvents = state.userCreatedEvents.map((e) => (e.id === payload ? omit(e, ['isFavourited', 'favouriteId']) : e));
      state.userResources = {
        ...state.userResources,
        results:
          state.userResources?.results?.map((e) =>
            e.hit.id === payload ? ({ ...omit(e, ['hit.isFavourited', 'hit.favouriteId']) } as TResourceResponseResult) : e
          ) ?? [],
        total: state.userResources?.total ?? 0,
        aggregations: state.userResources?.aggregations ?? {},
      };
    }
  ),
  onEventRemind: actionOn(
    (actions, storeActions) => storeActions.AuthStore.eventReminded,
    (state, { payload: { event, reminderId } }) => {
      state.userCreatedEvents = state.userCreatedEvents.map((e) => (e.id === event.id ? { ...e, ...event, isReminded: true, reminderId } : e));
      state.userResources = {
        results:
          state.userResources?.results?.map((e) =>
            e.hit.id === event.id ? { ...e, hit: { ...e.hit, ...event, isReminded: true, reminderId } } : e
          ) ?? [],
        total: state.userResources?.total ?? 0,
        aggregations: state.userResources?.aggregations ?? {},
      };
    }
  ),
  onEventUnRemind: actionOn(
    (actions, storeActions) => storeActions.AuthStore.eventUnReminded,
    (state, { payload }) => {
      state.userCreatedEvents = state.userCreatedEvents.map((e) => (e.reminderId === payload ? omit(e, ['isReminded', 'reminderId']) : e));
      state.userResources = {
        results:
          state.userResources?.results?.map((e) =>
            (e.hit as TEvent).reminderId === payload ? ({ ...omit(e, ['hit.isReminded', 'hit.reminderId']) } as TResourceResponseResult) : e
          ) ?? [],
        total: state.userResources?.total ?? 0,
        aggregations: state.userResources?.aggregations ?? {},
      };
    }
  ),

  fetchUserResourcesAndEvents: thunk(async (actions, { query, page }, { getState }) => {
    const { userDetails, userResources } = getState();
    const isUser = window.location.pathname.includes('/user');
    const whereFilter = isUser ? { where: { isPublished: true } } : { where: { isPublished: true, communityId: [userDetails?.id] } };
    if (page === 0) actions.setLoading(true);
    if (userDetails) {
      const resources = await ResourceModel.getUserResourcesAndEvents(
        isUser ? userDetails.id : '',
        getFilters(page, query, whereFilter, [], {}, {}, [])
      );
      const featuredDrops = await ResourceModel.getUserResourcesAndEvents(
        isUser ? userDetails.id : '',
        getFilters(page, query, whereFilter, dropShouldFilter, {}, {}, dropShouldFilter)
      );
      const unfeaturedDrops = await ResourceModel.getUserResourcesAndEvents(
        isUser ? userDetails.id : '',
        getFilters(page, query, whereFilter, [], { isUserFeatured: false, featured: false }, { isUserFeatured: false, featured: false }, [])
      );
      const drops = [...featuredDrops.results, ...unfeaturedDrops.results];
      actions.setUserResources({
        ...resources,
        // results: page !== 0 ? [...(userResources?.results ?? []), ...resources.results] : resources.results,
        results: page !== 0 ? [...(userResources?.results ?? []), ...drops] : drops,
      });
      actions.setPage(page);
    } else {
      console.error('No user details');
    }
    actions.setLoading(false);
  }),

  setUserResources: action((state, payload) => {
    state.userResources = payload;
  }),

  setQuery: action((state, payload) => {
    state.query = payload;
  }),

  onQueryChange: thunkOn(
    (actions) => actions.setQuery,
    async (actions, { payload }) => {
      await actions.fetchUserResourcesAndEvents({ query: payload, page: 0 });
    }
  ),

  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  notifications: {
    personal: [],
    all: [],
    community: [],
  },
  getNotifications: thunk(async (actions, { type, filter }) => {
    let _filter = filter;
    _filter = { ..._filter, order: ['updated DESC'] };

    actions.setIsLoadingNotifications(true);
    switch (type) {
      case 'community':
        _filter.where = { ..._filter.where, isScopedNotification: true };
        break;
      case 'personal':
        _filter.where = { ..._filter.where, isScopedNotification: false };
        break;
      default:
      case 'all':
        break;
    }
    try {
      const notifications = await UserModel.getNotifications(_filter);
      actions.setNotificationsForType({ type, notifications });
    } catch (err) {
    } finally {
      actions.setIsLoadingNotifications(false);
    }
  }),
  addNotifications: action((state, { types, notifications }) => {
    const newNotifications = state.notifications;
    types.forEach((type) => {
      newNotifications[type] = [...notifications, ...newNotifications[type]];
    });
    state.notifications = newNotifications;
  }),
  setNotificationsForType: action((state, { type, notifications }) => {
    state.notifications = { ...state.notifications, [type]: notifications };
  }),
  setNotifications: action((state, payload) => {
    state.notifications = payload;
  }),
  setIsLoadingNotifications: action((state, payload) => {
    state.isLoadingNotifications = payload;
  }),
  readNotification: thunk(async (actions, notifId, { getState }) => {
    const success = await UserModel.readNotification(notifId);
    const { notifications } = getState();
    if (success) {
      const newNotifications: CategorizedNotifications = { ...notifications };
      (['all', 'personal', 'community'] as NotificationTabs[]).forEach((type) => {
        newNotifications[type] = markSingleNotifAsRead(notifications[type], notifId);
      });
      actions.setNotifications(newNotifications);
    }
  }),
  getNotificationsCount: thunk(async (actions, params) => {
    const count = await UserModel.getNotificationCount(params);
    actions.setNotificationsCount(count);
  }),
  setNotificationsCount: action((state, payload) => {
    state.notificationsCount = payload;
  }),
  setIsLoadingNotificationPreferences: action((state, payload) => {
    state.isLoadingNotificationPreferences = payload;
  }),
  getNotificationPreferences: thunk(async (actions) => {
    actions.setIsLoadingNotificationPreferences(true);
    const settings = await UserModel.getNotificationPreferences();
    actions.setNotificationPreferences(settings);
    actions.setIsLoadingNotificationPreferences(false);
  }),
  updateNotificationPreferences: thunk(async (actions, settings) => {
    actions.setIsLoadingNotificationPreferences(true);
    const updatedSettings = await UserModel.updateNotificationPreferences(settings);
    actions.setNotificationPreferences(updatedSettings);
    actions.setIsLoadingNotificationPreferences(false);
  }),
  getNotificationConfigurations: thunk(async (actions) => {
    const defaultConfigurations = await UserModel.getNotificationConfigurations();
    actions.setNotificationConfigurations(defaultConfigurations);
  }),
  notificationPreferences: {
    conversation_reply_on_comment_channel_web: false,
    conversation_reply_on_comment_channel_email: false,
    conversation_another_user_comment_channel_web: false,
    conversation_another_user_comment_channel_email: false,
    community_new_comment_channel_email: false,
    community_new_comment_channel_web: false,
    community_new_comment_on_drop_channel_email: false,
    community_new_comment_on_drop_channel_web: false,
  },
  notificationConfigurations: {
    conversation_reply_on_comment: {
      email: {
        isConfigurable: false,
        isDefaultEnabled: false,
      },
      web: {
        isConfigurable: false,
        isDefaultEnabled: false,
      },
    },
    conversation_another_user_comment: {
      email: {
        isConfigurable: false,
        isDefaultEnabled: false,
      },
      web: {
        isConfigurable: false,
        isDefaultEnabled: false,
      },
    },
    community_new_comment: {
      email: {
        isConfigurable: false,
        isDefaultEnabled: false,
      },
      web: {
        isConfigurable: false,
        isDefaultEnabled: false,
      },
    },
    community_new_comment_on_drop: {
      email: {
        isConfigurable: false,
        isDefaultEnabled: false,
      },
      web: {
        isConfigurable: false,
        isDefaultEnabled: false,
      },
    },
  },
  isLoadingNotificationPreferences: false,
  setNotificationPreferences: action((state, payload) => {
    state.notificationPreferences = payload;
  }),
  setNotificationConfigurations: action((state, payload) => {
    state.notificationConfigurations = payload;
  }),
  resetUnreadNotifsCount: thunk(async (_, __, { getStoreState, getStoreActions }) => {
    const { appUser } = getStoreState().AuthStore;
    const { setAppUser } = getStoreActions().AuthStore;
    const success = await UserModel.resetUnreadNotifsCount();
    if (success && appUser) {
      setAppUser({ ...appUser, unReadNotificationCount: 0 });
    }
  }),
  increaseUnreadNotifCount: thunk((_, increaseBy, { getStoreState, getStoreActions }) => {
    const { appUser } = getStoreState().AuthStore;
    const { setAppUser } = getStoreActions().AuthStore;
    if (appUser) {
      setAppUser({ ...appUser, unReadNotificationCount: (appUser?.unReadNotificationCount ?? 0) + increaseBy });
    }
  }),
};

export default UserStore;

export const getResourceIncludeFilter = (whereFilter?: TFilter['where'], shouldFilter?: TFilter['should']) => ({
  where: whereFilter,
  should: shouldFilter,
  include: [
    {
      relation: 'community',
      scope: {
        fields: ['firstName', 'lastName', '_profileImages'],
      },
    },
    {
      relation: 'tags',
      scope: {
        fields: ['name', 'background', 'color'],
      },
    },
  ],
});

const getFilters = (
  page: number,
  query: EventQuery,
  whereFilter: { where: TFilter['where'] },
  eventShouldFeaturedFilter: TFilter['should'],
  eventWhereFeaturedFilter: TFilter['where'],
  resourceWhereFilter: TFilter['where'],
  resourceShouldFilter: TFilter['should']
) => ({
  filter: {
    limit: 20,
    skip: page * 20,
    ...omit(query.searchFilter, 'should'),
    should: [...(query.searchFilter.should || []), eventShouldFeaturedFilter || []],
    where: {
      ...query.searchFilter.where,
      ...eventWhereFeaturedFilter,
      ...whereFilter?.where,
    },
    Event: {
      fields: [
        'name',
        'id',
        'description',
        'tierType',
        '_tiers',
        'slug',
        'eventDates',
        '_primaryImage',
        'tagIds',
        'tags',
        'userId',
        'frequency',
        'repeatUntil',
        'subtitle',
        'type',
        'platformName',
        'platformUrl',
        'address',
        'rsvpUrl',
        'featured',
        'platform',
        'isFavourited',
        'isReminded',
        'reminderId',
        'communityId',
        'prices',
        'category',
        'when',
        'canceled',
        'isUserFeatured',
      ] as (keyof TEvent)[],
      include: ['tags', 'community', 'ticketingInfo'],
    },
    Video: getResourceIncludeFilter(resourceWhereFilter, resourceShouldFilter),
    Collect: getResourceIncludeFilter(resourceWhereFilter, resourceShouldFilter),
    Podcast: getResourceIncludeFilter(resourceWhereFilter, resourceShouldFilter),
    Article: getResourceIncludeFilter(resourceWhereFilter, resourceShouldFilter),
  },
});

const markSingleNotifAsRead = (notificationArray: TNotification[], notifIdToMarkAsRead: string) => {
  return notificationArray.map((notif) => ({ ...notif, isRead: notif.id === notifIdToMarkAsRead ? true : notif.isRead }));
};
