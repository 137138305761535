import React, { FC, useCallback, useMemo, useState } from 'react';
import { MLFormContent } from 'react-forms';
import { User } from 'Models/User/@types';
import { useFormConfig } from './config';
import { Form, Formik, FormikProps, useFormikContext } from 'formik';
import { Box, CircularProgress, makeStyles, Slide } from '@material-ui/core';
import PgButton from 'Components/PgButton';
import PgTypo from 'Components/PgTypo';
import PgIcon from 'Components/PgIcon';
import clsx from 'clsx';
import { SHADES } from 'Theme/newThemeConstants';
import Spacer from 'Components/Spacer';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { Step } from 'Features/Auth/@types';
import { PRIVACY_POLICY_LINK, TERMS_OF_SERVICE_LINK } from 'Utils/Constants';
import { BORDER_GRAY_A900, BORDER_WHITE, COLOR, FONT_FAMILY, THEME_PALETTE } from 'Theme/themeConstants';
import { ANALYTICS_USER_EVENT } from 'Analytics/analyticActions';
import { useStoreDispatch } from 'Stores';
import { useLocation } from 'react-router-dom';
import SocialAuth from 'Components/SocialAuth';

export interface SignUpFormData extends User {
    confirmPassword: string;
    referringCode?: string;
}

export interface SignUpFormProps {
    onSubmit: (fields: User) => void | Promise<void>;
    initialData: Partial<SignUpFormData>;
    isSubmitting: boolean;
    changeStep: (step: Step) => void;
    disableEmail?: boolean;
    removeSteps?: SignUpSteps[];
    setMeta?: (meta: any) => void;
    meta?: any;
}
type SignUpSteps = 'email' | 'username' | 'password';
const _signUpStepsOrder: SignUpSteps[] = ['email', 'username' /* , 'password' */];
const SignUpForm: React.FC<SignUpFormProps> = (props) => {
    const classes = useStyles();
    const dispatch = useStoreDispatch();
    const { pathname } = useLocation();
    const { initialData, onSubmit, changeStep, disableEmail, setMeta, removeSteps = [], meta } = props;

    const signUpStepsOrder = removeSteps.length ? _signUpStepsOrder.filter((f) => !removeSteps?.includes(f)) : _signUpStepsOrder;

    const [step, setStep] = useState<SignUpSteps>('email');
    const nextStep = () => {
        const currentStepIndex = signUpStepsOrder.findIndex((_step) => _step === step);
        if (currentStepIndex === -1 || currentStepIndex + 1 >= signUpStepsOrder.length) return;
        let eventName = '';
        switch (currentStepIndex) {
            case 0:
                eventName = 'EMAIL_ADDED';
                break;
            case 2:
                eventName = 'COMPLETE_REGISTRATION';
        }
        if (eventName !== '') {
            dispatch({ type: ANALYTICS_USER_EVENT, data: { eventName, context: { event: eventName, source: pathname } } });
        }
        setStep(signUpStepsOrder[currentStepIndex + 1]);
    };
    const prevStep = () => {
        const currentStepIndex = signUpStepsOrder.findIndex((_step) => _step === step);
        if (currentStepIndex === -1) return;
        if (disableEmail && step === 'email' && meta?.prevStep === 'web3-email-check') {
            changeStep('web3-email-check');
            return;
        }

        setStep(signUpStepsOrder[currentStepIndex + -1]);
    };
    const submitHandler = useCallback(
        async (fields: Partial<SignUpFormData>) => {
            const { confirmPassword, ...fieldsRest } = fields;
            await onSubmit(fieldsRest as SignUpFormData);
            const eventName = 'COMPLETE_REGISTRATION';
            dispatch({ type: ANALYTICS_USER_EVENT, data: { eventName, context: { event: eventName, source: pathname } } });
        },
        [onSubmit],
    );
    const { validationSchema } = useFormConfig(!!disableEmail);

    const stepValidation = (formikProps: FormikProps<SignUpFormData>): boolean => {
        switch (step) {
            case 'username':
                return !(!!(formikProps.values as unknown as { errorslug?: string }).errorslug || !!formikProps.errors.slug) && !!formikProps.values.slug;
            case 'email':
                return !(!!(formikProps.values as unknown as { erroremail?: string }).erroremail || !!formikProps.errors.email) && !!formikProps.values.email;
            case 'password':
                return formikProps.isValid;
        }
    };
    const { isDeviceSm } = useMediaQuery();
    const spacingVariant1 = useMemo(() => isDeviceSm ? "20px" : "32px", [isDeviceSm]);
    const spacingVariant2 = useMemo(() => isDeviceSm ? "0px" : "32px", [isDeviceSm]);

    const handleWalletClick = () => {
        changeStep('wallet-select');
        setMeta?.({ setStep: 'signup' });
    };

    return (
        <Box className={classes.root}>
            <Formik initialValues={initialData} validateOnMount onSubmit={submitHandler} validationSchema={validationSchema}>
                {(formikProps) => (
                    <Form>
                        {signUpStepsOrder.map((s) => {
                            return (
                                <Slide direction="left" mountOnEnter unmountOnExit in={step === s}>
                                    <div>
                                        <FormConsumer
                                            currentStep={s}
                                            prevStep={prevStep}
                                            nextStep={nextStep}
                                            classes={classes}
                                            stepValidation={stepValidation}
                                            disableEmail={disableEmail}
                                            meta={meta}
                                            additionalContent={
                                                s === 'username' ? (
                                                    <PgTypo b6 align="center" className={classes.termsText}>
                                                        By clicking &quot;Sign Up&quot;, you accept Playground’s &nbsp;
                                                        <PgButton quaternary href={TERMS_OF_SERVICE_LINK} isExternalLink className={classes.termsPolicyLink} underlined>
                                                            Terms of Service
                                                        </PgButton>{' '}
                                                        and{' '}
                                                        <PgButton quaternary href={PRIVACY_POLICY_LINK} isExternalLink className={classes.termsPolicyLink} underlined>
                                                            Privacy Policy.
                                                        </PgButton>
                                                    </PgTypo>
                                                ) : null
                                            }
                                        />
                                        {s === 'username' || meta?.prevStep === 'web3-email-check' ? null : (
                                            <>
                                                <Box py={spacingVariant1} borderBottom={isDeviceSm ? undefined : BORDER_GRAY_A900}>
                                                    <PgButton
                                                        quaternary
                                                        underlined
                                                        onClick={() => {
                                                            changeStep('login');
                                                        }}
                                                        className={classes.backBtn}
                                                    >
                                                        Already on Playground? Log in.
                                                    </PgButton>
                                                </Box>
                                                <PgTypo b1 style={{ marginBottom: 20, marginTop: spacingVariant2 }}>Or continue with</PgTypo>
                                                <PgButton secondary fullWidth onClick={handleWalletClick}>
                                                    wallet
                                                </PgButton>
                                            </>
                                        )}
                                    </div>
                                </Slide>
                            );
                        })}
                    </Form>
                )}
            </Formik>
            <Box className={classes.stepsContainer}>
                {Array.from({ length: signUpStepsOrder.length }).map((_, index) => {
                    const isCurrentOrCompletedStep = index <= signUpStepsOrder.findIndex((_step) => step === _step);

                    return (
                        <div
                            key={index}
                            tabIndex={isCurrentOrCompletedStep ? 0 : undefined}
                            onClick={
                                isCurrentOrCompletedStep
                                    ? () => {
                                        setStep(signUpStepsOrder[index]);
                                    }
                                    : undefined
                            }
                            className={clsx(classes.stepIndicator, { [classes.currentOrFinishedStep]: isCurrentOrCompletedStep })}
                        />
                    );
                })}
            </Box>
        </Box>
    );
};

export default SignUpForm;
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '70vh',
        position: 'relative',
        paddingBottom: theme.spacing(3),
    },
    backArrow: {
        transform: 'rotate(90deg)',
    },
    prevButton: {
        padding: 0,
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        minWidth: 'unset',
    },
    stepIndicator: {
        height: 4,
        width: 60,
        backgroundColor: SHADES.greyLight,
    },
    currentOrFinishedStep: {
        backgroundColor: COLOR.tertiary.music,
        cursor: 'pointer',
    },
    stepsContainer: {
        marginTop: theme.spacing(4),
        display: 'flex',
        gap: theme.spacing(1.5),
        justifyContent: 'center',
        // position: 'absolute',
        // bottom: '2vh',
        // left: 0,
        // right: 0,
        [theme.breakpoints.down('sm')]: {
            bottom: theme.spacing(4),
        },
    },
    termsText: {
        color: SHADES.greyDark,
    },
    termsPolicyLink: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        color: SHADES.greyDark,
        fontWeight: theme.typography.fontWeightRegular,
    },
    subtitle: { fontFamily: FONT_FAMILY.secondary },
    backBtn: {},
    emptyContainer: {
        backgroundColor: THEME_PALETTE.grey.A900,
        height: "1px",
    },
}));

const FormConsumer: FC<{
    classes: Record<string, string>;
    prevStep: () => void;
    stepValidation: (formikProps: FormikProps<SignUpFormData>) => boolean;
    nextStep: () => void;
    currentStep: SignUpSteps;
    additionalContent?: React.ReactNode;
    disableEmail?: boolean;
    removeSteps?: SignUpSteps[];
    meta?: any;
}> = ({ classes, prevStep, stepValidation, disableEmail, nextStep, currentStep, additionalContent, removeSteps = [], meta }) => {
    const headerContainerProps = {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
    };
    const { isDeviceSm } = useMediaQuery();
    const formikProps = useFormikContext<SignUpFormData>();
    const _nextStep = () => {
        if ((formikProps.values.password?.length ?? 0) < 8) {
            formikProps.handleSubmit();
            return;
        }
        if (stepValidation(formikProps)) {
            nextStep();
        }
    };
    const marginTopOrBottom = useMemo(() => isDeviceSm ? "20px" : "32px", [isDeviceSm])

    const { inputConfig } = useFormConfig(!!disableEmail, _nextStep);
    const _formConsumerConfig = formConsumerConfig(meta);
    return (
        <>
            <Box {...headerContainerProps}>
                {_formConsumerConfig[currentStep].hasPrevious ? (
                    <PgButton className={classes.prevButton} onClick={prevStep}>
                        <PgIcon styleClass={classes.backArrow} icon="icon-ticker-negative" />
                    </PgButton>
                ) : null}
                <PgTypo h3={!isDeviceSm} h7={isDeviceSm} align="center" typoWeight="fontWeightBold">
                    {_formConsumerConfig[currentStep].title}
                </PgTypo>
            </Box>
            <Spacer height={20} />
            <PgTypo b1={!isDeviceSm} b4={isDeviceSm} align="center" className={classes.subtitle}>
                {_formConsumerConfig[currentStep].subtitle}
            </PgTypo>
            {
                currentStep === "email" ? <>
                    <SocialAuth rootProps={{ mt: marginTopOrBottom, mb: 0 }} socialAuthStep='SIGNUP' />
                    <Box className={classes.emptyContainer} marginY={"32px"} />
                </> : null
            }

            <MLFormContent
                formId="sign-up"
                formikProps={formikProps}
                schema={inputConfig[(currentStep + 'Step') as keyof typeof inputConfig]}
                settings={{ verticalSpacing: 16 }}
            />
            <PgButton
                primary
                fullWidth
                disabled={!stepValidation(formikProps) || formikProps.isSubmitting}
                onClick={_nextStep}
                type={currentStep === 'username' ? 'submit' : 'button'}
            >
                {formikProps.isSubmitting ? <CircularProgress /> : _formConsumerConfig[currentStep].buttonLabel}
            </PgButton>
            {additionalContent && (
                <>
                    <Spacer height={32} />
                    {additionalContent}
                </>
            )}
        </>
    );
};

const formConsumerConfig = (
    meta?: any,
): Record<SignUpSteps, { title: string; subtitle: string; buttonLabel: string; hasPrevious: boolean }> => ({
    username: {
        title: 'Pick a Playground username',
        subtitle: 'AKA your alter ego, superhero name, or digital alias',
        buttonLabel: 'SIGN UP',
        // hasPrevious: !removeSteps?.includes('email') ?? true,
        hasPrevious: true
    },
    email: {
        // title: 'Enter your email',
        // subtitle: 'FYI: Email is a message-delivery system for people who don’t have magical owls.',
        // title: 'Welcome in!',
        title: 'Get started',
        subtitle: 'Enter your email & password',
        buttonLabel: 'NEXT',
        hasPrevious: meta?.prevStep === 'web3-email-check',
    },
    password: {
        title: 'Create a password',
        subtitle: "Make it good. It's your secret code to enter this digital portal.",
        buttonLabel: 'SIGN UP',
        hasPrevious: true,
    },
});
