/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import { TFilter } from 'Models/App/@types';
import { Tag } from 'Models/Tags/@types';
import moment from 'moment';
import qs from 'qs';
import { TWhen } from './EventQuery';

export type TDropQuery = {
  tagIds?: string[];
  locations?: string[];
  timings?: string[];
  community?: string[];
  sort?: string;
  category?: string;
  prices?: string[];
  when?: TWhen;
  status?: string[];
  openAddContent?: boolean;
  page?: number;
  searchTerm?: string;
  locationGeo?: { lat: string; lng: string; name?: string };
  view?: ViewType;
};

export enum DropSort {
  recent = 'recent',
  oldest = 'oldest',
}

export enum LocationFilter {
  virtual = 'online',
  irl = 'offline',
}

export enum TimingFilter {
  upcoming = 'upcoming',
  past = 'past',
}

export enum StatusFilter {
  published = 'published',
  draft = 'draft',
  featured = 'featured',
  unpublished = 'unpublished',
}

export enum EAdditionWhenFilter {
  TODAY = 'today',
  WEEK = 'week',
  MONTH = 'month',
}

export type ViewType = 'map' | 'grid';

// export type TEventSortOption = 'recent' | 'oldest';

const sortMapping: Record<string, string> = {
  [DropSort.oldest]: 'asc',
  [DropSort.recent]: 'desc',
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const locationTypeMapping: Record<string, string> = {
  [LocationFilter.irl]: 'offline',
  [LocationFilter.virtual]: 'online',
};
const timingKeyMapping: Record<string, string> = {
  [TimingFilter.past]: 'lte',
  [TimingFilter.upcoming]: 'gte',
};
class DropQuery {
  private _query: TDropQuery = {};

  private _userId = '';

  private _communityId = '';

  private _pageLocation = '';

  private _isFeatured = false;

  constructor(query: TDropQuery) {
    this._query = query;
  }

  get userId() {
    return this.userId;
  }

  set userId(userId: string) {
    this._userId = userId;
  }

  set isFeatured(isFt: boolean) {
    this._isFeatured = isFt;
  }

  set communityId(id: string) {
    this._communityId = id;
  }

  set pageLocation(location: string) {
    this._pageLocation = location;
  }

  get query() {
    return this._query;
  }

  static fromQuery(query: string) {
    const { page, ...eventQuery } = qs.parse(query, { ignoreQueryPrefix: true }) as Record<string, string>;
    const queryObj: Record<string, any> = { page: page ? Number(page) : undefined };
    Object.keys(eventQuery).forEach((key) => {
      // if (typeof eventQuery[key] === typeof '') {
      //     const value = eventQuery[key].split(',');
      //     if (value) {
      //         queryObj[key] = value;
      //     }
      // }
      switch (key) {
        case 'category':
          queryObj.category = eventQuery[key];
          break;
        case 'when':
          queryObj.when = eventQuery[key];
          break;
        case 'locationGeo':
          queryObj.locationGeo = eventQuery[key];
          break;
        case 'view':
          queryObj.view = eventQuery[key];
          break;
        default:
          queryObj[key] = eventQuery[key].split?.(',');
      }
    });

    return new DropQuery(queryObj);
  }

  getSelectedTags(tagSummaries: Tag[]) {
    return tagSummaries.filter((t) => this._query.tagIds?.includes(t.id));
  }

  getSelectedEventType() {
    return this._query.locations;
  }

  getSelectedDocType() {
    return this._query.category;
  }

  get searchFilter() {
    let should: TFilter['should'] = [];
    if (this._pageLocation === 'drops' || this._pageLocation === 'user-details' || this._pageLocation === 'community-detail')
      should = [
        [
          typeof this?._query?.when === 'string' && this._query.when === EAdditionWhenFilter.TODAY
            ? {
                bool: {
                  should: [
                    {
                      bool: {
                        must: [
                          {
                            range: {
                              releaseDate: {
                                lte: new Date().toISOString(),
                              },
                            },
                          },
                          {
                            range: {
                              releaseEndDate: {
                                gte: new Date().toISOString(),
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      bool: {
                        must: [
                          {
                            range: {
                              releaseDate: {
                                gte: dayjs().startOf('day').toISOString(),
                              },
                            },
                          },
                          {
                            range: {
                              releaseEndDate: {
                                lte: dayjs().endOf('day').toISOString(),
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              }
            // : {
            //     exists: {
            //       field: 'releaseDate',
            //     },
            //   },
            : {},
        ],
      ];
    let where: TFilter['where'] = {};
    if (this._communityId) should.push(shoudFilter(this._communityId, true, !(this._pageLocation === 'event-and-media')));
    if (this._userId) should.push(userShouldFilter(this._userId));

    if (this._pageLocation === 'drops' || this._pageLocation === 'user-details' || this._pageLocation === 'community-detail') {
      // For other drops list, always show the published drops
      where.isPublished = true;
      where.canceled = { ifExistsThen: false };
      if (this._pageLocation === 'drops') where.isSoftPublished = { ifExistsThen: true };
      if (this._communityId || this._userId) {
        where.isUserFeatured = false;
        where.featured = false;
      }
      if (this._isFeatured) where.featured = true;
      // } else if (this._query.status?.[0] && this._query.status.length < 2) {
    } else if (this._query.status?.length) {
      const currStatus = this._query.status;
      if (currStatus.includes(StatusFilter.published) && currStatus.includes(StatusFilter.unpublished)) where.isPublished = undefined;
      else if (currStatus.includes(StatusFilter.published)) where.isPublished = true;
      else if (currStatus.includes(StatusFilter.unpublished)) where.isPublished = false;
      where.featured = currStatus.includes(StatusFilter.featured) ? true : undefined;
    }

    // if (this._userId) where.userId = this._userId;

    if (this.query.tagIds?.length) {
      where.primaryTagId = this.query.tagIds;
    }
    // category
    const categoryFilter: any[] = [];
    if (this._query.category?.length) {
      categoryFilter.push(this._query.category);
    }

    // Event type ----------
    const typeFilter: any[] = [];
    const subTypeFilter: string[] = [];
    if (this._query.locations?.length) {
      this._query.locations.forEach((loc) => {
        if (loc === 'online' || loc === 'metaverse' || loc === 'Metaverse Events') {
          typeFilter.push('online');
          subTypeFilter.push(loc === 'online' ? loc : 'metaverse');
        } else {
          typeFilter.push(loc);
        }
      });
    }

    // price
    const priceFilter: any[] = [];
    if (this._query.prices?.length) {
      // eslint-disable-next-line no-unused-expressions
      this._query.prices?.forEach((price) => {
        priceFilter.push(price);
      });
    }

    const sort = [];

    // when
    let whenFilter: TDropQuery['when'] = {};

    if (this._isFeatured) whenFilter.startDate = dayjs().toISOString();

    if (this._query.when && typeof this._query.when === 'string') {
      if (this._query.when === EAdditionWhenFilter.TODAY) {
        /* Not needed for TODAY since addition of custom filter above */
        // whenFilter = { startDate: moment(moment().format('YYYY-MM-DD')).toISOString() };
      }
      if (this._query.when === EAdditionWhenFilter.WEEK)
        whenFilter = { startDate: moment().toISOString(), endDate: moment().add(1, 'week').toISOString() };
      if (this._query.when === EAdditionWhenFilter.MONTH)
        whenFilter = { startDate: moment().toISOString(), endDate: moment().add(1, 'month').toISOString() };
    } else if (this._query.when?.startDate || this._query.when?.endDate) {
      if (this._query.when?.startDate && this._query.when?.endDate) {
        /**
         * If both present then we know it's a custom date range hence we set them to startof day and end of day
         */
        whenFilter = {
          startDate: dayjs(this._query.when?.startDate).startOf('day').utc().toISOString(),
          endDate: dayjs(this._query.when?.endDate).endOf('day').utc().toISOString(),
        };
        sort.push('oldest');
      } else if (this._query.when?.startDate)
        whenFilter = {
          startDate: dayjs(this._query.when?.startDate).utc().toISOString(),
        };
      else if (this._query.when?.endDate)
        whenFilter = {
          endDate: dayjs(this._query.when?.endDate).utc().toISOString(),
        };
    }
    // status
    const statusFilter: any[] = [];
    if (this._query.status?.length && this._query.status?.length < 2) {
      // eslint-disable-next-line no-unused-expressions
      this._query.status?.forEach((status) => {
        statusFilter.push(status);
      });
    }

    // Time ------------------
    if (this._query.timings?.length) {
      let rangeFilter: any = [];
      this._query.timings.forEach((item) => {
        rangeFilter.push({
          range: { 'eventDates.endDate': { [timingKeyMapping[item]]: new Date().toISOString() } },
        });
      });
      if (this.query.timings?.find((i) => i === 'live')) {
        rangeFilter = [
          {
            bool: {
              must: [
                {
                  range: { 'eventDates.endDate': { lte: new Date().toISOString() } },
                },
                {
                  range: { 'eventDates.endDate': { gte: new Date().toISOString() } },
                },
              ],
            },
          },
        ];
      }
      if (rangeFilter.length) {
        should.push(rangeFilter);
      }
    }

    // geoLocation
    let locationGeoFilter: TDropQuery['locationGeo'];
    if (this._query.locationGeo) {
      locationGeoFilter = {
        lat: this._query.locationGeo.lat,
        lng: this._query.locationGeo.lng,
        name: this._query.locationGeo.name,
      };
    }
    // --------------------------}

    if (this._query.community?.length) {
      const community = Array.isArray(this._query.community) ? this._query.community : (this._query.community as string).split(',');
      // eslint-disable-next-line no-unused-expressions
      community?.forEach((item) => {
        if (item === 'created') {
          where = {
            ...where,
            userId: this._userId.length ? this._userId : undefined,
          };
        } else if (item === 'saved') {
          where = {
            ...where,
            // favouriteUserIds: this._userId
          };
        }
      });
    }
    const { category: updatedCategory, type: mappedTypes, subType: mappedSubType } = categoryToTypeConv(categoryFilter);
    const _filter: TFilter = {
      category: updatedCategory,
      location: locationGeoFilter?.lat || locationGeoFilter?.lng ? { key: 'location_geo', value: [locationGeoFilter?.lat, locationGeoFilter?.lng] } : undefined,
      price: priceFilter,
      should: should.length ? should : undefined,
      // status: statusFilter,
      type: [...mappedTypes, ...typeFilter],
      when: whenFilter,
      where: {
        ...where,
        // subType: subTypeFilter,
      },
      subType: [...mappedSubType, ...subTypeFilter],
    };
    if (this._query.sort === DropSort.oldest) {
      _filter.sort = [
        {
          'eventDates.endDate': {
            order: sortMapping[this._query.sort || DropSort.recent],
          },
        },
      ];
    }
    // else if (!this._query.locationGeo) _filter.customSort = [DropSort.recent];
    return _filter;
  }

  get featuredSearchFilter() {
    let should: TFilter['should'] = [];
    if (this._pageLocation === 'drops' || this._pageLocation === 'user-details' || this._pageLocation === 'community-detail')
      should = [
        [
          {
            bool: {
              must_not: [
                {
                  match: {
                    doc_type: 'Event',
                  },
                },
              ],
            },
          },
          {
            exists: {
              field: 'eventDates.endDate',
            },
          },
        ],
      ];
    if (this._communityId) should.push(shoudFilter(this._communityId));
    if (this._userId) should.push(userShouldFilter(this._userId));
    if (this._pageLocation !== 'drops') should.push(FEATURED_SHOULD_FILTER);

    let where: TFilter['where'] = {};
    if (this._pageLocation === 'drops' || this._pageLocation === 'user-details' || this._pageLocation === 'community-detail') {
      // For other drops list, always show the published drops
      where.isPublished = true;
      where.canceled = { ifExistsThen: false };
      if (this._pageLocation === 'drops') where.isSoftPublished = { ifExistsThen: true };
      // if (this._communityId || this._userId) {
      //   where.isUserFeatured = false;
      //   where.featured = true;
      // }
      // } else if (this._query.status && this._query.status.length < 2) {
    } else if (this._query.status?.length) {
      // Filter by Status for Dashboard admin
      const currStatus = this._query.status;
      if (currStatus.includes(StatusFilter.published) && currStatus.includes(StatusFilter.unpublished)) where.isPublished = undefined;
      else if (currStatus.includes(StatusFilter.published)) where.isPublished = true;
      else if (currStatus.includes(StatusFilter.unpublished)) where.isPublished = false;
      where.featured = currStatus.includes(StatusFilter.featured) ? true : undefined;
    }

    // if (this._userId) where.userId = this._userId;

    if (this.query.tagIds?.length) {
      where.primaryTagId = this.query.tagIds;
    }
    // category
    const categoryFilter: any[] = [];
    if (this._query.category?.length) {
      categoryFilter.push(this._query.category);
    }

    // Event type ----------
    const typeFilter: any[] = [];
    if (this._query.locations?.length) {
      this._query.locations.forEach((loc) => {
        typeFilter.push(loc);
      });
    }

    // price
    const priceFilter: any[] = [];
    if (this._query.prices?.length) {
      // eslint-disable-next-line no-unused-expressions
      this._query.prices?.forEach((price) => {
        priceFilter.push(price);
      });
    }

    const sort = [];

    // when
    let whenFilter: TDropQuery['when'];

    if (this._query.when?.startDate || this._query.when?.endDate) {
      if (this._query.when?.startDate && this._query.when?.endDate) {
        /**
         * If both present then we know it's a custom date range hence we set them to startof day and end of day
         */
        whenFilter = {
          startDate: dayjs(this._query.when?.startDate).startOf('day').utc().toISOString(),
          endDate: dayjs(this._query.when?.endDate).endOf('day').utc().toISOString(),
        };
        sort.push('oldest');
      } else if (this._query.when?.startDate)
        whenFilter = {
          startDate: dayjs(this._query.when?.startDate).utc().toISOString(),
        };
      else if (this._query.when?.endDate)
        whenFilter = {
          endDate: dayjs(this._query.when?.endDate).utc().toISOString(),
        };
    }
    // status
    const statusFilter: any[] = [];
    if (this._query.status?.length && this._query.status?.length < 2) {
      // eslint-disable-next-line no-unused-expressions
      this._query.status?.forEach((status) => {
        statusFilter.push(status);
      });
    }

    // Time ------------------
    if (this._query.timings?.length) {
      let rangeFilter: any = [];
      this._query.timings.forEach((item) => {
        rangeFilter.push({
          range: { 'eventDates.endDate': { [timingKeyMapping[item]]: new Date().toISOString() } },
        });
      });
      if (this.query.timings?.find((i) => i === 'live')) {
        rangeFilter = [
          {
            bool: {
              must: [
                {
                  range: { 'eventDates.endDate': { lte: new Date().toISOString() } },
                },
                {
                  range: { 'eventDates.endDate': { gte: new Date().toISOString() } },
                },
              ],
            },
          },
        ];
      }
      if (rangeFilter.length) {
        should.push(rangeFilter);
      }
    }
    // --------------------------}

    if (this._query.community?.length) {
      const community = Array.isArray(this._query.community) ? this._query.community : (this._query.community as string).split(',');
      // eslint-disable-next-line no-unused-expressions
      community?.forEach((item) => {
        if (item === 'created') {
          where = {
            ...where,
            userId: this._userId.length ? this._userId : undefined,
          };
        } else if (item === 'saved') {
          where = {
            ...where,
            // favouriteUserIds: this._userId
          };
        }
      });
    }
    const { category: updatedCategory, type: mappedTypes, subType } = categoryToTypeConv(categoryFilter);
    const _filter: TFilter = {
      where: {
        ...where,
      },
      price: priceFilter,
      category: updatedCategory,
      when: whenFilter,
      type: [...mappedTypes, ...typeFilter],
      should: should.length ? should : undefined,
      subType,
      // status: statusFilter,
    };
    if (this._query.sort === DropSort.oldest) {
      _filter.sort = [
        {
          'eventDates.endDate': {
            order: sortMapping[this._query.sort || DropSort.recent],
          },
        },
      ];
    }
    // else {
    //   _filter.customSort = [DropSort.recent];
    // }
    return _filter;
  }

  clone(query: TDropQuery) {
    return new DropQuery({
      // priceCategory: query.priceCategory ?? this._query.priceCategory,
      tagIds: query.tagIds ?? this._query.tagIds,
      sort: query.sort ?? this._query.sort,
      locations: query.locations ?? this._query.locations,
      timings: query.timings ?? this._query.timings,
      community: query.community ?? this._query.community,
      prices: query.prices ?? this._query.prices,
      category: query.category === 'All' ? undefined : query.category ?? this._query.category,
      when: query.when ?? this._query.when,
      status: query.status ?? this._query.status,
      page: query.page ?? this._query.page,
      searchTerm: query.searchTerm ?? this._query.searchTerm,
      locationGeo: query.locationGeo ?? this._query.locationGeo,
      view: query.view,
    });
  }

  get queryString() {
    const finalQuery = { ...this.query };
    return qs.stringify(finalQuery, { arrayFormat: 'comma', encodeValuesOnly: true });
  }
}

export default DropQuery;

export const FEATURED_SHOULD_FILTER = [
  {
    match: {
      featured: true,
    },
  },
  {
    match: {
      isUserFeatured: true,
    },
  },
];

export const shoudFilter = (communityId: string, includeCollaboratedDrops: boolean = true, includeReplayedDrops: boolean = true) =>
  [
    {
      bool: {
        must: [
          {
            match: {
              communityId,
            },
          },
        ],
      },
    },
    ...(includeCollaboratedDrops
      ? [
          {
            bool: {
              must: [
                {
                  match: {
                    collaboratingCommunityIds: communityId,
                  },
                },
              ],
            },
          },
        ]
      : []),
    ...(includeReplayedDrops
      ? [
          {
            match: {
              replayedOnCommunityDisplayIds: communityId,
            },
          },
        ]
      : []),
  ].filter((i) => !!i);

export const userShouldFilter = (userId: string) => [
  {
    match: {
      replayedOnUserDisplayIds: userId,
    },
  },
  {
    match: {
      reminderUserIds: userId,
    },
  },
];

// category to type and subtype mapping
const categoryToTypeConv = (category: string[]): { type: string[]; subType: string[]; category: string[] } => {
  const updatedCategory = category.filter((c) => c !== 'offline' && c !== 'online' && c !== 'metaverse');
  const eventTypeCategory = category.filter((c) => c === 'offline' || c === 'online' || c === 'metaverse');
  const updatedType = eventTypeCategory.map((c) => (c === 'metaverse' || c === 'online' ? 'online' : 'offline'));
  return { type: updatedType ?? [], subType: eventTypeCategory ?? [], category: updatedCategory ?? [] };
};
