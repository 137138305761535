import React, { FC } from 'react';
import { Divider, makeStyles, Theme } from '@material-ui/core';

interface CustomDividerProps {
  height?: number | string;
  color?: string;
}

const CustomDivider: FC<CustomDividerProps> = ({ height, color }) => {
  const classes = getClasses({ height, color });
  return <Divider className={classes.root} />;
};

export default CustomDivider;

const getClasses = makeStyles<Theme, CustomDividerProps>(() => ({
  root: {
    height: ({ height }) => height ?? 1,
    backgroundColor: ({ color }) => color || 'rgba(0, 0, 0, 0.12)',
  },
}));
