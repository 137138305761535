import { Box, ButtonBase, Collapse, createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import PgButton from 'Components/PgButton';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import SVGCIcon from 'Components/SVGComponents/SVGCIcon';
import { WALLET_ICONS_MAP } from 'Constants/variables';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import React, { FC, useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import { ACCOUNTS_ROUTES } from 'Routes/AccountNavigation';
import { IWalletURLMeta } from 'Screens/NFTListingScreen/NFTListingScreenViewModel';
import { useStoreState } from 'Stores';
import helpers from 'Utils/helpers';

export const useWalletAddresses = () => {

    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));

    const wallets = useMemo(() => {
        if (!appUser?.cryptoWallet?.length && !appUser?.publicAddress) return [];
        if (!appUser.publicAddress) return appUser.cryptoWallet ?? [];
        return [...(appUser.cryptoWallet ?? []), { address: appUser.publicAddress, type: appUser.recentWalletUsed }];
    }, [appUser]);

    return wallets
}

export interface WalletListProps {
    onListItemClick?: () => void;
}

const WalletList: FC<WalletListProps> = (props) => {
    const { onListItemClick } = props;

    const { isDeviceSm } = useMediaQuery();
    const classes = useStyles();

    const [isExpand, setIsExpand] = useState(false);

    const wallets = useWalletAddresses();

    if (!wallets.length) return null;

    return (
        <Box py={isDeviceSm ? 2.5 : 1} px={isDeviceSm ? 3 : 2} className={classes.root}>
            <Box component={ButtonBase} className={classes.expandCollapsedBtn} onClick={() => setIsExpand((s) => !s)}>
                <Box display="flex" alignItems="center" gridGap={isDeviceSm ? 8 : 16} className={classes.titleWrap}>
                    <SVGCIcon icon="icon-wallet" />
                    <PgTypo variant="body1" className={classes.text}>
                        Wallet
                    </PgTypo>
                </Box>
                <PgIcon icon="icon-arrow-next" size="small" styleClass={clsx(classes.icon, { [classes.iconExpand]: isExpand })} />
            </Box>
            <Collapse in={isExpand}>
                {wallets.map((c) => {
                    const encodedMeta = helpers.b64Encode(JSON.stringify({ ...c } as IWalletURLMeta));
                    const link = generatePath(ACCOUNTS_ROUTES.NFT_LIST.path, { meta: encodedMeta });
                    return (
                        <PgButton href={link} onClick={onListItemClick} key={c.address} className={classes.listItem}>
                            {!!c.type && <img src={WALLET_ICONS_MAP[c.type]} alt={c.type} className={clsx(classes.walletLogo, { [classes.logoColorInvert]: c.type === 'pg' || c.type === 'cocrt' })} />}
                            <PgTypo variant="body1" className={classes.text}>{`${c.address.slice(0, 5)}...${c.address.slice(-4)}`}</PgTypo>
                        </PgButton>
                    );
                })}
            </Collapse>
        </Box>
    );
};

export default WalletList;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: { borderBottom: `1px solid ${theme.palette.primary.main}` },
        expandCollapsedBtn: { width: '100%', display: 'flex !important', justifyContent: 'space-between !important' },
        titleWrap: { '& svg': { width: theme.spacing(3) }, '& path': { fill: theme.palette.primary.main } },
        icon: { transform: 'rotate(90deg)', transition: '300ms ease-in' },
        iconExpand: { transform: 'rotate(-90deg)' },
        listItem: {
            display: 'flex',
            width: '100%',
            padding: 0,
            textTransform: 'none',
            justifyContent: 'unset',
            marginTop: theme.spacing(1),
            '& .MuiButton-label': { gap: theme.spacing(0.5) },
            [theme.breakpoints.down('sm')]: { marginTop: theme.spacing(2) },
        },
        walletLogo: { width: theme.spacing(2), height: theme.spacing(2), margin: theme.spacing(0, 0.5) },
        text: { letterSpacing: '1px', [theme.breakpoints.down('sm')]: { letterSpacing: 'unset' } },
        logoColorInvert: { filter: theme.palette.type === 'dark' ? 'invert(100%)' : undefined },
    }),
);
