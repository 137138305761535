import PointsDialog from 'Features/Points/PointsDialog';
import React, { FC, useState } from 'react';

export interface IPointsDialogContent {
  title: string;
  subtitle?: string | React.ReactNode;
  body?: string;
  actionText?: string;
  onAction?: () => void;
  open: boolean;
}
interface ContextProps extends IPointsDialogContent {
  showDialog: (content: IPointsDialogContent) => void;
  hideDialog: () => void;
}
export const PointsDialogContext = React.createContext<ContextProps>({
  open: false,
  title: '',
  subtitle: '',
  body: '',
  actionText: 'Done',
  onAction: () => {},
  showDialog: () => {},
  hideDialog: () => {},
});

export const usePointsDialog = (initialState: IPointsDialogContent) => {
  const [dialogState, setDialogState] = useState(initialState);

  const showDialog = (content: Omit<IPointsDialogContent, 'open'>) => {
    setDialogState({
      ...dialogState,
      ...content,
      open: true,
    });
  };
  const hideDialog = () => {
    setDialogState({
      ...dialogState,
      open: false,
    });
  };
  return { ...dialogState, showDialog, hideDialog };
};
export const PointsDialogProvider: FC = (props) => {
  const {
    title = '',
    showDialog,
    hideDialog,
    open,
    body,
    ...pointsDialogProps
  } = usePointsDialog({
    title: '',
    open: false,
    body: '',
  });
  const { children } = props;
  return (
    <PointsDialogContext.Provider
      value={{
        title,
        showDialog,
        hideDialog,
        open,
        body,
      }}
    >
      {children}
      <PointsDialog {...pointsDialogProps} body={body} open={open} title={title} onClose={hideDialog} />
    </PointsDialogContext.Provider>
  );
};
export default PointsDialogProvider;
