import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, BoxProps, FormHelperText, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { MentionsInput, Mention, MentionProps, MentionsInputProps } from 'react-mentions';
import clsx from 'clsx';
import { BORDER_BLACK, THEME_PALETTE } from 'Theme/themeConstants';
import UserAvatar from 'Components/UserAvatar';

export interface TextFieldWMentionProps extends Omit<MentionsInputProps, 'children'> {
  BoxProps?: BoxProps;
  renderSuggestion?: MentionProps['renderSuggestion'];
  fetchSuggestions: MentionProps['data'];
  value: string;
  error?: boolean;
  helperText?: string;
}

function TextFieldWMention(props: TextFieldWMentionProps) {
  const { BoxProps, renderSuggestion, fetchSuggestions, className, error = false, helperText, ...restInputProps } = props;
  const classes = useStyles({});

  const renderEntitySuggestion: MentionProps['renderSuggestion'] = (suggestion) => {
    const _meta = JSON.parse(suggestion.id as string);

    return (
      <ListItem button className={classes.listItem}>
        <ListItemIcon>
          <UserAvatar name={suggestion.display ?? ''} size={25} url={_meta?.profileImg} />
        </ListItemIcon>
        <ListItemText className={classes.listItemName} primary={suggestion.display} />
      </ListItem>
    );
  };

  return (
    <Box width="100%" {...BoxProps}>
      <MentionsInput className={clsx('mention-input', classes.input, className)} allowSpaceInQuery {...restInputProps}>
        <Mention
          style={{
            position: 'relative',
            zIndex: 1,
            color: THEME_PALETTE.secondary.main,
            background: 'white',
          }}
          trigger="@"
          markup={`<mention>name:__display__;__id__</mention>`}
          data={fetchSuggestions}
          renderSuggestion={renderSuggestion ?? renderEntitySuggestion}
          appendSpaceOnAdd
        />
      </MentionsInput>
      {helperText ? <FormHelperText error={error}>{helperText}</FormHelperText> : null}
    </Box>
  );
}

const useStyles = makeStyles<Theme, any>((theme) => ({
  listItem: {
    padding: theme.spacing(1, 2),
    gap: 12,
    '& .MuiListItemIcon-root': { minWidth: 'unset' },
  },
  listItemName: {
    '& .MuiListItemText-primary': {
      ...theme.typography.caption,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  input: {
    '& .mention-input__suggestions': {
      left: 'unset !important',
      top: 'unset !important',
      minWidth: '100% !important',
      bottom: '100%',
      boxShadow: `0px 0px 36px rgba(0, 0, 0, 0.12), 0px 4px 4px rgba(0, 0, 0, 0.08), 0px 4px 10px rgba(0, 0, 0, 0.12)`,
      '&__item': {
        borderBottom: BORDER_BLACK,
        '&:last-child': {
          borderBottom: 'unset',
        },
      },
    },
  },
}));

export default TextFieldWMention;
