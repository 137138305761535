import { debounce, omit } from 'lodash';
import DiscussionModel from 'Models/Discussion';
import UserModel, { User } from 'Models/User';
import { SuggestionDataItem } from 'react-mentions';
import helpers from 'Utils/helpers';

const _regex = /<mention>name:([^;]+?);([^<]+?)<\/mention>/gi;
export const striptedData = (name: string, meta: string) => `<mention>name:${name};${meta}</mention>`;

export const textFieldWMentionHelpers = {
  getSearchText(text: string) {
    if (!/@/gi.test(text)) return '';
    const mentions = text.split('@');
    if (!mentions.length) return '';
    return mentions[mentions.length - 1];
  },
  getTextNode(text: string, renderMention: (name: string, meta: string) => string) {
    const _res = text.matchAll(_regex);
    let _htmlStr = text;

    for (let r of _res) {
      _htmlStr = _htmlStr.replace(r[0], renderMention(r[1], r[2]));
    }
    return _htmlStr;
  },
  stripMetaData(originlText: string, keys: string[]) {
    const _res = originlText.matchAll(_regex);
    let _htmlStr = originlText;

    for (let r of _res) {
      _htmlStr = _htmlStr.replace(r[0], striptedData(r[1], JSON.stringify(omit(JSON.parse(r[2]), keys))));
    }

    return _htmlStr;
  },
  fetchAutocomplete: debounce((discussionId: string, query: string, callback: (data: SuggestionDataItem[]) => void) => {
    if (!query) return;
    DiscussionModel.getMentions(discussionId, query)
      .then((res) => res.find((f) => f.users)?.users?.filter((f) => f) ?? [])

      // Transform the data to what react-mentions expects
      .then((res) =>
        res.map((d) => {
          const user = d as User;
          const imgObj = UserModel.getAvatarImage(user);
          const profileImg = helpers.getPictureUrl(imgObj);
          const meta = { id: user.id ?? (user as any)._id, slug: user.slug, profileImg };
          return { display: UserModel.getUserName(user), id: JSON.stringify(meta) };
        }),
      )
      .then(callback);
  }, 500),
};
