import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { BackdropProps, PaperProps, Popover, PopoverProps, useTheme } from '@material-ui/core';
import clsx from 'clsx';

export interface PopoverWrapperProps extends Pick<PopoverProps, 'anchorOrigin' | 'transformOrigin'> {
  id: string;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  maxHeight?: number;
  paperProps?: Partial<PaperProps> | undefined;
  backdropProps?: Partial<BackdropProps>;
}

const PopoverWrapper: FC<PopoverWrapperProps> = (props) => {
  const { anchorEl, id, onClose, anchorOrigin, transformOrigin, maxHeight, children, paperProps = {}, backdropProps ={}} = props;
  const classes = useStyles({});
  const open = Boolean(anchorEl);
  
  const handleClose = () => {
    onClose();
  };

  const theme = useTheme();
  return (
    <Popover
      elevation={3}
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
        ...anchorOrigin,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
        ...transformOrigin,
      }}
      BackdropProps={{
        ...backdropProps,
      }}
      PaperProps={{
        ...paperProps,
        square: true,
        className: clsx(classes.paper, paperProps.className),
        style: {
          maxHeight,
          ...(paperProps.style || {}),
        },
      }}
    >
      {children}
    </Popover>
  );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
  paper: {
    backgroundColor: theme.palette.common.white,
    overflow: 'auto',
  },
}));

export default PopoverWrapper;
