import { Box, CircularProgress } from '@material-ui/core';
import React, { CSSProperties, useContext, useState } from 'react';
import PgButton from 'Components/PgButton';
import { AppDialogContext } from '../Contexts/AppDialogContext';
import useToastMessage from './useToastMessage';
import Typo from '../Components/Typo';
import useAsyncTask from './useAsyncTask';

const DEFAULT_MESSAGE = 'Confirm action?';

/**
 * A hook for confirmation dialogs
 * @returns a function
 *
 * @example
 * const withConfirmation = useConfirmationDialog();
 * const handleDelete = async (id: string) => await withConfirmation(async () => {
 * // do your async task
 * // throw error, if there is an error
 * });
 *
 */
export default () => {
  const { showDialog, hideDialog } = useContext(AppDialogContext);

  // const getContentComponent = (message: string = DEFAULT_MESSAGE) => {
  //     return (
  //         <Typography>{message}</Typography>
  //     )
  // }

  const getBody = (body: string | JSX.Element) => {
    return typeof body === 'string' ? (
      <Typo gutterBottom align="center" variant="body2">
        {body}
      </Typo>
    ) : (
      body
    );
  };

  const withConfirmationDialog = (action: () => any, config: WithConfirmationDialogConfig = {}, toastConfig?: WithConfirmationDialogToastConfig) => {
    const { message, body, hideActions, styleOverride = {} } = config;
    showDialog(body ? getBody(body) : <div />, {
      headerProps: {
        isCloseButton: !hideActions?.includes('close-icon'),
        headerContent: (
          <Typo align="center" variant="h5" weight="bold">
            {message || DEFAULT_MESSAGE}
          </Typo>
        ),
      },
      isActionCloseButton: false,
      onClose () {},
      PaperProps: {
        style: {
          width: 400,
          padding: 16,
          ...styleOverride,
        },
      },
      style: {
        zIndex: 1310,
      },
      title: message || DEFAULT_MESSAGE,
      actionsChildren: <ActionButton agree={action} hideDialog={hideDialog} config={config} toastConfig={toastConfig} />,
    });
  };

  return withConfirmationDialog;
};

const ActionButton: React.FC<ActionButtonProps> = (props) => {
  const { agree, config, toastConfig, hideDialog } = props;

  const withToast = useToastMessage();
  const [loading, setLoading] = useState(false);

  const accept = async () => {
    setLoading(true);
    try {
      if (toastConfig) {
        await withToast(agree, toastConfig);
      } else {
        await agree();
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    setLoading(false);
    hideDialog();
  };

  // const acceptRunner = useAsyncTask(accept);

  // const loading = acceptRunner.status === 'PROCESSING';

  return (
    <Box display="flex" width="100%" gridGap={16}>
      {config.hideActions?.includes('cancel') ? null : (
        <PgButton onClick={hideDialog} secondary fullWidth variant="outlined">
          {config.cancelText || 'CANCEL'}
        </PgButton>
      )}
      {config.hideActions?.includes('agree') ? null : (
        <>
          {accept ? (
            <PgButton onClick={accept} primary fullWidth variant="contained">
              {loading ? <CircularProgress /> : config.agreeText || 'YES'}
            </PgButton>
          ) : null}
        </>
      )}
    </Box>
  );
};

export interface WithConfirmationDialogConfig {
  message?: string;
  body?: JSX.Element | string;
  agreeText?: string;
  cancelText?: string;
  hideActions?: Array<'agree' | 'cancel' | 'close-icon'>;
  styleOverride?: CSSProperties;
}

export interface WithConfirmationDialogToastConfig {
  successToastMessage?: string;
  errorToastMessage?: string;
}

type ActionButtonProps = {
  agree: () => void | Promise<void>;
  config: WithConfirmationDialogConfig;
  toastConfig?: WithConfirmationDialogToastConfig;
  hideDialog: () => void;
};
