import React, { useState } from 'react';
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { PaymentRequest, PaymentRequestOptions } from '@stripe/stripe-js';

// {
//     country: 'US',
//         currency: 'usd',
//             total: {
//         label: 'Demo total',
//             amount: 1099,
//             },
//     requestPayerName: true,
//         requestPayerEmail: true,
//         }

function useNativePay() {
    const stripe = useStripe();
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);
    // useEffect(() => {
    //     const pr = stripe?.paymentRequest({
    //         country: 'US',
    //         currency: 'usd',
    //         total: {
    //             label: 'Demo total',
    //             amount: 1099,
    //         },
    //         requestPayerName: true,
    //         requestPayerEmail: true,
    //     });

    //     if (!pr) return;
    //     // Check the availability of the Payment Request API.
    //     pr.canMakePayment().then(result => {
    //         if (result) {
    //             setPaymentRequest(pr);
    //         }
    //     });

    //     pr.on('paymentmethod', async (ev) => {
    //         if (!stripe) return;
    //         // Confirm the PaymentIntent without handling potential next actions (yet).
    //         const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
    //             clientSecret,
    //             { payment_method: ev.paymentMethod.id },
    //             { handleActions: false }
    //         );

    //         if (confirmError) {
    //             // Report to the browser that the payment failed, prompting it to
    //             // re-show the payment interface, or show an error message and close
    //             // the payment interface.
    //             ev.complete('fail');
    //         } else {
    //             // Report to the browser that the confirmation was successful, prompting
    //             // it to close the browser payment method collection interface.
    //             ev.complete('success');
    //             // Check if the PaymentIntent requires any actions and if so let Stripe.js
    //             // handle the flow. If using an API version older than "2019-02-11"
    //             // instead check for: `paymentIntent.status === "requires_source_action"`.
    //             if (paymentIntent?.status === "requires_action") {
    //                 // Let Stripe.js handle the rest of the payment flow.
    //                 const { error } = await stripe.confirmCardPayment(clientSecret);
    //                 if (error) {
    //                     // The payment failed -- ask your customer for a new payment method.
    //                 } else {
    //                     // The payment has succeeded.
    //                 }
    //             } else {
    //                 // The payment has succeeded.
    //             }
    //         }
    //     });


    //     return () => {
    //         pr.off('paymentmethod');
    //     };
    // }, [stripe]);


    const createPaymentRequestButton = (paymentRequestOption: PaymentRequestOptions, secret: string) => {
        const pr = stripe?.paymentRequest(paymentRequestOption);
        if (!pr) return;
        // Check the availability of the Payment Request API.
        pr.canMakePayment().then(result => {
            if (result) {
                setPaymentRequest(pr);
            }
        }).catch(err => console.log("error", err));

        pr.on('paymentmethod', async (ev) => {
            if (!stripe) return;
            // Confirm the PaymentIntent without handling potential next actions (yet).
            const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
                secret,
                { payment_method: ev.paymentMethod.id },
                { handleActions: false }
            );

            if (confirmError) {
                // Report to the browser that the payment failed, prompting it to
                // re-show the payment interface, or show an error message and close
                // the payment interface.
                ev.complete('fail');
            } else {
                // Report to the browser that the confirmation was successful, prompting
                // it to close the browser payment method collection interface.
                ev.complete('success');
                // Check if the PaymentIntent requires any actions and if so let Stripe.js
                // handle the flow. If using an API version older than "2019-02-11"
                // instead check for: `paymentIntent.status === "requires_source_action"`.
                if (paymentIntent?.status === "requires_action") {
                    // Let Stripe.js handle the rest of the payment flow.
                    const { error } = await stripe.confirmCardPayment(secret);
                    if (error) {
                        // The payment failed -- ask your customer for a new payment method.
                    } else {
                        // The payment has succeeded.
                    }
                } else {
                    // The payment has succeeded.
                }
            }
        });
    }


    const disconnectListener = () => {
        paymentRequest?.off('paymentmethod');
    }



    return {
        createPaymentRequestButton,
        disconnectListener,
        Component: paymentRequest ? <PaymentRequestButtonElement options={{ paymentRequest }} /> : null
    }
}



export default useNativePay;