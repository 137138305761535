/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import moment from 'moment';
import { FormConfig } from 'react-forms';
import { useStoreState } from 'Stores';
import * as Yup from 'yup';

export const TICKETING_TERMS_ERROR = 'Please accept the terms and conditions in order to checkout.'

export const useEventTicketFormConfig = (ageRestriction?: number) => {
  const classes = useStyles();
  const { isDeviceSm } = useMediaQuery();
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));

  const firstName = {
    type: 'text',
    valueKey: 'firstName',
    fieldProps: {
      // className: classes.firstCol,
      fullWidth: true,
      label: 'First Name',
      // variant: "filled",
      placeholder: 'First name',
      className: classes.textField,
    },
  };
  const lastName = {
    type: 'text',
    valueKey: 'lastName',
    styles: isDeviceSm
      ? {
          marginTop: 20,
        }
      : undefined,
    fieldProps: {
      fullWidth: true,
      label: 'Last Name',
      // variant: "filled",
      placeholder: 'Last name',
      className: classes.textField,
    },
  };
  const formFieldConvertor = (firstField: any, secondField: any) => {
    if (isDeviceSm) return [firstField, secondField];
    return [[firstField, secondField]];
  };

  const inputConfig: Array<Array<FormConfig> | FormConfig> = [
    // [
    //   {
    //     type: 'section-header',
    //     valueKey: 'section-header-5',
    //     fieldProps: {
    //       title: 'First name',
    //       style: {
    //         position: 'relative',
    //         top: '20px',
    //         marginTop: '-20px',
    //       },
    //       titleWeight: 'medium',
    //     },
    //   },
    //   {
    //     type: 'section-header',
    //     valueKey: 'section-header-5',
    //     fieldProps: {
    //       title: 'Last name',
    //       style: {
    //         position: 'relative',
    //         top: '20px',
    //         marginTop: '-20px',
    //       },
    //       titleWeight: 'medium',
    //     },
    //   },
    // ],
    ...formFieldConvertor(firstName, lastName),
    ...(appUser
      ? []
      : [
          {
            type: 'text',
            valueKey: 'email',
            styles: { marginTop: 20 },
            fieldProps: {
              // className: classes.firstCol,
              fullWidth: true,
              label: 'Email Address',
              // variant: "filled",
              placeholder: 'Email address',
              className: classes.textField,
            },
          },
        ]),

    ...(ageRestriction ? [{ type: 'dob-field', valueKey: 'dob', fieldProps: { label: 'What’s your birth date?' } }] : []),
  ];
  const validationSchema = Yup.object({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),

    email: appUser ? Yup.string() : Yup.string().email('Invalid Email').required('Email required'),
    // countryCode: Yup.object().shape({
    //     countryCode: Yup.string().required('Country Code is required'),
    // }),
    // phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is is required'),
    dob: ageRestriction
      ? Yup.string()
          .required('Date of birth required')
          .test('dob-age-test', `You must be over ${ageRestriction} to attend.`, (val) => {
            if (!val) return false;
            const diff = moment(val).diff(moment(), 'year');
            return ageRestriction <= Math.abs(diff);
          })
      : Yup.string(),

    termsAccepted: Yup.bool().oneOf([true], TICKETING_TERMS_ERROR).required()
  });

  const repeatEventValidationSchema = Yup.object({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    eventDate: Yup.string().required('Select a date'),
  });

  return {
    inputConfig,
    validationSchema,
    repeatEventValidationSchema,
  };
};

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    // buttonContainer: {
    //   marginTop: 40,
    // },
    // adornmentIcon: {
    //   cursor: 'pointer',
    // },
    // firstCol: {
    //   marginRight: 40,
    // },
    // textField: {
    //   backgroundColor: `${theme.palette.grey.A400}!important`,
    //   '& > div.MuiInput-underline:before': {
    //     borderBottom: '0px !important',
    //   },
    //   '& > div': {
    //     width: '100%',
    //     borderBottom: '0px !important',
    //     // position: 'absolute',
    //     // top: '24px',
    //   },
    //   '& > div > input': {
    //     padding: '5px 4px 5px 8px',
    //     color: TICKETING_FORM_COLOR,
    //   },
    //   '& > div:hover': {
    //     border: 'none !important',
    //     backgroundColor: `${theme.palette.grey.A400}!important`,
    //   },
    //   height: '32px',
    //   '& label + .MuiInput-formControl': {
    //     marginTop: '0px !important',
    //   },
    // },
  }),
);
