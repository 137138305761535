import { Box, IconButton, Tab, Tabs, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CollapsibleContent from 'Components/CollapsibleContent';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import { capitalize, debounce } from 'lodash';
import { TDiscussion } from 'Models/Discussion/@types';
import UserModel from 'Models/User';
import { User } from 'Models/User/@types';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useStoreState } from 'Stores';
import MemberList from './MemberList';
import MemberListItem from './MemberListItem';
import SearchMembersList from './SearchMembersList';
import { TDiscussionConfig, TStatus } from './useDiscussion';
import { BORDER_BLACK } from 'Theme/themeConstants';
import { ChatLoader, DiscussionLayoutProps } from './DiscussionLayout';
import { useMediaQuery } from 'Hooks/useMediaQuery';

export interface ChatMembersContentProps extends Pick<DiscussionLayoutProps, 'allowMessaging' | 'memberDrawerDisabled'> {
    config: TDiscussionConfig;
    showChat?: boolean;
}

export interface SearchMemberListProps {
    user: User;
    type: TStatus;
}

const TABS = [
    { id: 0, label: 'Players' },
    { id: 1, label: 'Chat' },
];

const ChatMembersContent: FC<ChatMembersContentProps> = ({ config, showChat = false }) => {
    const classes = useStyles();
    const { discussions, appUser, community } = useStoreState(
        ({ DiscussionStore: { discussions }, AuthStore: { appUser }, CommunityStore: { community } }) => ({
            discussions,
            appUser,
            community,
        }),
    );
    const discussion = useMemo(() => {
        return discussions.find((i) => i.id === config.activeDiscussionId) || ({} as TDiscussion);
    }, [discussions, config.activeDiscussionId]);
    const [searchList, setSearchList] = useState<SearchMemberListProps[]>([]);

    const { onlineUsers = [], offlineUsers = [] } = useMemo(() => {
        return {
            onlineUsers: community?.onlineUsers || [],
            offlineUsers: community?.offlineUsers || [],
        };
    }, [community]);
    const [search, setSearch] = useState('');
    const debouncedSearch = debounce(async (term: string) => {
        const data = await UserModel.searchUsersForCommunity(config.subjectId, term, {
            limit: 40,
            // where: { id: discussion.memberIds || [] },
        });

        const userList = data.results.map((r) => ({
            user: r.hit,
            type: onlineUsers.find((user) => user.id === r.hit.id) ? 'online' : ('offline' as TStatus),
        }));
        setSearchList(userList);
    }, 300);

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearch(value);
        if (!value) {
            setSearchList([]);
            return;
        }
        debouncedSearch(value);
    }, []);

    const getMemberList = (members: User[], type: 'offline' | 'online') => {
        return (
            <CollapsibleContent
                title={
                    <Box display={'flex'} alignItems="center">
                        <PgTypo b4Bold>{`${capitalize(type)}`}&nbsp;</PgTypo>
                        <PgTypo b4>{`(${members.length})`}</PgTypo>
                    </Box>
                }
            >
                <Box display={'flex'} flexDirection="column">
                    {members
                        .filter((m) => m)
                        .map((item) => (
                            <Box my={1} key={item.id}>
                                <MemberListItem member={item} isOnline={type === 'online'} config={config} />
                            </Box>
                        ))}
                </Box>
            </CollapsibleContent>
        );
    };


    const [tabIndex, setTabIndex] = useState(showChat ? 1 : 0);
    const handleTabChange = (tab: number) => {
        setTabIndex(tab);
    };
    const tabNav = (
        <Box width='100%'>
            <PgTypo h6 className={classes.title}>{TABS[tabIndex].label}</PgTypo>
            <Tabs value={tabIndex} indicatorColor="primary" onChange={(e, val) => handleTabChange(val)} variant='fullWidth' style={{ width: '100%' }}>
                {TABS.map((t) => (
                    <Tab className={classes.tab} value={t.id} label={t.label} key={t.id} style={{ minWidth: 'unset', width: "100%" }} />
                ))}
            </Tabs>
        </Box>
    );

    return (
        <Box className={classes.root}>
            <Box display={'flex'} justifyContent="space-between" alignItems={'center'} borderBottom={showChat ? BORDER_BLACK : undefined}>
                {showChat ? tabNav : <PgTypo h6 className={classes.memberTitle}>Members</PgTypo>}
            </Box>

            {tabIndex === 0 && (
                <Box height='100%' overflow='auto' px={2.5}>
                    <Box my={1.5}>
                        <TextField
                            InputProps={{
                                className: classes.searchField,
                                endAdornment: search ? (
                                    <IconButton
                                        className={classes.clearIcon}
                                        size="small"
                                        onClick={() => onChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)}
                                    >
                                        <PgIcon size="small" icon="icon-close" />
                                    </IconButton>
                                ) : (
                                    <PgIcon icon="icon-search" size="small" />
                                ),
                            }}
                            fullWidth
                            placeholder="Search"
                            value={search}
                            onChange={onChange}
                        />
                    </Box>
                    {discussion.id && search ? (
                        <SearchMembersList
                            onlineUserIds={(onlineUsers || []).map((i) => i.id)}
                            isAdmin={appUser?.id === config.creatorId}
                            config={config}
                            discussion={discussion}
                            searchList={searchList}
                        />
                    ) : null}
                    {search ? null : (
                        <Box height='100%' overflow='auto'>
                            {getMemberList(onlineUsers || [], 'online')}
                            {getMemberList(offlineUsers || [], 'offline')}
                        </Box>
                    )}
                </Box>
            )}

            {tabIndex === 1 && <ChatLoader allowMessaging memberDrawerDisabled rootProps={{ px: 1 }} />}

        </Box>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
        },
        searchField: {
            paddingBottom: 4,
        },
        clearIcon: {
            padding: 0,
        },
        title: {
            padding: theme.spacing(2.5, 2.5, 0),
            [theme.breakpoints.up('sm')]: { display: 'none' },
        },
        memberTitle: {
            padding: theme.spacing(0, 2.5),
            [theme.breakpoints.down('sm')]: { padding: theme.spacing(2.5, 2.5, 0), },
        }
    });
});

export default ChatMembersContent;
