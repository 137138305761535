import { COLORS, SHADES } from './newThemeConstants';

export const gradient = (color1 = '#26282C', color2 = '#FFFFFF', direction = 'to right'): string => {
    return `linear-gradient(${direction}, ${color1}, ${color2})`;
};

export const FONT_FAMILY = {
    primary: 'Nue Plak',
    secondary: 'Extended',
    tertiary: 'Roboto-Mono',
    quaternary: 'Neue Plak Condensed Black',
};

export const FONT_WEIGHTS = {
    fontWeightBold: 700,
    fontWeightMedium: 600,
    fontWeightRegular: 400,
};

export const TEXT_COLOR = {
    primary: '#000000',
    secondary: '#ffffff',
    title: '#262626',
};

export const COLOR = {
    primary: {
        white: '#ffffff',
        black: '#000000',
    },

    secondary: {
        // blue: '#675DF7',
        blue: '#4E45FF',
        neon: '#CEFA6C',
        red: '#B71840',
        greenYellow: '#B4FF00',
    },
    tertiary: {
        activism: '#FF9100',
        art: '#FF0279',
        party: '#FF0400',
        music: '#CDFF00',
        culture: '#FF70FF',
        food: '#D500AC',
        comedy: '#FFF701',
        wellness: '#13FDC8',
        startup: '#00C3FF',
        film: '#13EDFD',
        activeGreen: '#3e6c3f',
    },
};

export const DARK_THEME_PALETTE = {
    primary: {
        main: COLORS.white,
        light: SHADES.dark,
        contrastText: COLORS.black,
    },
    secondary: {
        // main: COLOR.primary.white,
        main: COLOR.secondary.blue,
    },
    text: {
        primary: TEXT_COLOR.secondary,
        secondary: TEXT_COLOR.primary,
    },
    background: {
        default: COLOR.primary.black,
        paper: COLOR.primary.black,
    },
};

export const TICKETING_FORM_COLOR = '#6B6570';
export const TICKETING_FORM_PLACEHOLDER_COLOR = '#bdbabf';

export const THEME_PALETTE = {
    grey: {
        100: '#F8F8F8',
        200: '#D9D9D9',
        300: '#949494',
        400: '#707070',
        500: '#4D4D4D',
        600: '#474747',
        700: '#363535',
        800: '#333333',
        900: '#262626',
        A100: '#EBEBEB',
        A200: '#F2F2F2',
        A300: '#B6BFBF',
        A400: '#C4C4C4',
        A500: '#E5E7EB',
        A600: '#374151',
        A700: '#1A090D',
        A800: '#1D1D1D',
        A900: '#6B6570',
        B100: '#E0E0E0',
        B200: '#F8F8F8',
        B300: '#5C5C5C',
    },
    text: {
        primary: TEXT_COLOR.primary,
        secondary: TEXT_COLOR.secondary,
        disabled: '#6B6570',
    },

    background: {
        default: '#ebeff2',
        paper: COLOR.primary.white,
    },
    common: {
        black: COLOR.primary.black,
        white: COLOR.primary.white,
    },
    action: {
        active: COLOR.primary.black,
    },
    error: {
        // main: '#B71840',
        main: '#FF0400',
        light: '#FF0400',
        contrastText: COLOR.primary.white,
    },
    others: {
        purple: '#B15496',
        violet: '#675DF7',
        startup: '#00C3FF',
        orange: '#FF9100',
    },
    primary: {
        light: '#1A090D',
        main: COLOR.primary.black,
        contrastText: COLOR.primary.white,
    },
    secondary: {
        main: COLOR.secondary.blue,
        contrastText: COLOR.primary.white,
    },
    success: {
        main: '#4caf50',
        secondary: '#2FE600',
        light: '#2FE600',
    },
    warning: {
        main: '#ff9800',
    },
    info: {
        main: '#2196f3',
    },
};

export const DIVIDER_BORDER = `1px solid ${THEME_PALETTE.grey[200]}`;
export const DIVIDER_BORDER_BLACK = `1px solid ${THEME_PALETTE.common.black}`;

export const DIVIDER_BORDER_DOTTED = `1px dashed ${THEME_PALETTE.grey[200]}`;

export const DIVIDER_BORDER_DARK = `1px solid ${THEME_PALETTE.grey[100]}`;
// export const DIVIDER_BORDER_DARK = `1px solid ${THEME_PALETTE.grey[300]}`
export const DIVIDER_BORDER_LIGHT = `1px solid ${THEME_PALETTE.grey[100]}`;

export const DIVIDER_BORDER_CONTRAST = `1px solid ${THEME_PALETTE.primary.contrastText}`;

export const DIVIDER_BORDER_SOFT = `1px solid ${THEME_PALETTE.grey.A100}`;

export const BORDER_BLACK = `1px solid ${THEME_PALETTE.common.black}`;
export const BORDER_WHITE = `1px solid ${THEME_PALETTE.common.white}`;
export const BORDER_GRAY_A900 = `1px solid ${THEME_PALETTE.grey.A900}`;

export const GRID_VIEW_GUTTER_SPACING_UNIT = 5;

// export const GRADIENTS = {};
export const GRADIENTS = [`transparent linear-gradient(106deg, #B15496 0%, #D03960 100%) 0% 0% no-repeat padding-box`];

export const QUILL_LINK_COLOR = '#06c';
