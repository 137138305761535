import { IconButton, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import PgIcon from 'Components/PgIcon';
import moment from 'moment';
import React, { useState } from 'react';
import AddToCalendars from 'react-add-to-calendar';
import calender from '../../Assets/icons/calendar2.svg';
import CustomIcon from '../SvgIcon';
import './style.css';
import REMINDER from 'Assets/icons/reminder.svg';

export type TCalendarEvent = {
  title: string;
  open?: string;
  close?: string;
  description?: string;
  location?: string;
};

interface AddToCalendarProps extends TCalendarEvent {
  isDisabled?: boolean;
}

// const icon = { 'calendar-plus-o': 'left' };
const AddToCalendar: React.FC<AddToCalendarProps> = (props) => {
  const { title, description, open, close, location = 'Virtual', isDisabled = false } = props;
  const [ref, setRef] = useState<any>();
  const start = moment(open).toISOString();
  const end = moment(close).toISOString();
  const classes = useStyles();
  const handleClick = () => {
    ref?.toggleCalendarDropdown?.();
  };
  return (
    <div className={classes.root}>
      <IconButton size="small" className={classes.button} disableRipple onClick={handleClick} disabled={isDisabled}>
        {/* <CustomIcon variant='dark' icon={calender} size={30} /> */}
        {/* <PgIcon icon="icon-calendar" styleClass={classes.icon} /> */}
        <img src={REMINDER} alt='reminder-icon' width={24} />
        <AddToCalendars
          buttonLabel=""
          buttonTemplate={{}}
          ref={(ref) => {
            setRef(ref);
          }}
          buttonIconClass=""
          listItems={[{ apple: 'Apple Calendar' }, { google: 'Google' }, { outlook: 'Outlook' }, { yahoo: 'Yahoo' }]}
          event={{
            title,
            startTime: open ? start : undefined,
            endTime: close ? end : undefined,
            description,
            location,
          }}
          useFontAwesomeIcons={true}
          displayItemIcons={true}
        />
      </IconButton>
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      '& div > div > div': {
        backgroundColor: 'yellow',
        height: '50px',
      },
      '& .react-add-to-calendar': {
        fontFamily: `${theme.typography.fontFamily} !important`,
      },
      '& .react-add-to-calendar__button': {
        color: `${theme.palette.primary.contrastText} !important`,
        fontWeight: 'bold',
        fontSize: '13px',
      },
      '& .react-add-to-calendar__dropdown': { bottom: 'unset', top: 22 },
    },
    icon: {
      //   color: theme.palette.primary.main,
      //   fontSize: theme.typography.h4.fontSize,
    },
    button: {
      padding: 0,
      // padding: '6px 3px'
    },
  });
});

export default AddToCalendar;
