import Axios, { CancelTokenSource } from 'axios';
import { action, Action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';
import CommunityUserQuery from 'Features/Query/CommunityUserQuery';
import EventModel, { IQRCodesBulleit } from 'Models/Event';
import { PromoCode, TEvent } from 'Models/Event/@types';
import { Guest } from 'Models/User/@types';
// eslint-disable-next-line import/no-cycle
import { TRootStore } from 'Stores';
import { TicketTier } from 'Models/Event/ticketing/@types';
import { ChartData } from '../../Models/Insights/@types';
import { TFilter } from 'Models/App/@types';
import CommunityModel from 'Models/Community';
import { parseBulleitQR } from 'Models/Event/eventParsers';

export interface GuestParams {
  from?: number;
  limit?: number;
  searchText?: string;
}
export interface EventDetailState {
  event: TEvent | undefined;
  setEvent: Action<EventDetailState, TEvent | undefined>;
  availableTickets: TicketTier[];
  setAvailableTickets: Action<EventDetailState, TicketTier[]>;
  fetchEventDetail: Thunk<EventDetailState, { id: string, filters?: TFilter }, null, TRootStore>;
  loading: boolean;
  setLoading: Action<EventDetailState, boolean>;

  loadingGuests: boolean;
  setLoadingGuests: Action<EventDetailState, boolean>;

  totalGuests: number;
  setTotalGuests: Action<EventDetailState, number>;

  guests: Guest[];
  setGuests: Action<EventDetailState, Guest[]>;
  fetchEventGuests: Thunk<EventDetailState, CommunityUserQuery, null, TRootStore>;

  analytics?: ChartData;
  setAnalytics: Action<EventDetailState, ChartData>;
  fetchAnalytics: Thunk<
    EventDetailState,
    { startDate: any; endDate: any; granularity: string; insights: string[] },
    void,
    TRootStore
  >;

  query: CommunityUserQuery; // data from CommunityUserQuery object are used to get params for guests endpoint. Keeping it this way because query is easier to manipulate
  setQuery: Action<EventDetailState, CommunityUserQuery>;
  onQueryChange: ThunkOn<EventDetailState, Record<string, unknown>, TRootStore>;

  setEventPromoCodes: Thunk<EventDetailState, void, void, TRootStore>;
}

let cancelToken: CancelTokenSource | undefined;

const EventDetailStore: EventDetailState = {
  event: undefined,
  availableTickets: [],
  setAvailableTickets: action((state, payload) => {
    state.availableTickets = payload;
  }),
  loading: false,
  loadingGuests: false,
  totalGuests: 0,
  query: new CommunityUserQuery({}, { page: 1 }),
  guests: [],
  setEvent: action((state, payload) => {
    state.event = payload;
  }),
  setAnalytics: action((state, payload) => {
    // console.log('Setting analytics', payload);
    state.analytics = payload;
  }),
  setTotalGuests: action((state, payload) => {
    state.totalGuests = payload;
  }),
  setGuests: action((state, payload) => {
    state.guests = payload;
  }),
  setQuery: action((state, payload) => {
    state.query = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setLoadingGuests: action((state, payload) => {
    state.loadingGuests = payload;
  }),
  fetchEventDetail: thunk(async (actions, { id, filters = {} }) => {
    try {
      actions.setLoading(true);
      const res = await EventModel.getSingleEvent(id, { filter: { ...filters, include: ['collaborators', ...(filters.include as Array<string> ?? [])] } });
      if (res.tierType === 'free' || res.tierType === 'single' || res.tierType === 'multi' || res.tierType === 'nft') {
        const tickets = await EventModel.getAvailableTickets(res.id);
        actions.setAvailableTickets(tickets);
      }
      let qrCodesBulleit: IQRCodesBulleit[] = [];
      if (res.qrCodesBulleit.length && res.id) qrCodesBulleit = await CommunityModel.qrManager(res.id).catch(console.error) as IQRCodesBulleit[];
      actions.setEvent({ ...res, qrCodesBulleit: parseBulleitQR(qrCodesBulleit) });
      return { ...res, qrCodesBulleit: parseBulleitQR(qrCodesBulleit) };
    } catch (error) {
      console.error(error);
    } finally {
      actions.setLoading(false);
    }
  }),
  fetchAnalytics: thunk(async (actions, payl, { getState }) => {
    const { event } = getState();
    const { startDate, endDate, granularity, insights } = payl;
    if (!event) return;
    const res = await EventModel.getEventAnalytics(event.communityId || '', event.id, startDate, endDate, granularity, insights, ['revenue', 'ticket', 'favorite']);
    actions.setAnalytics(res);
  }),
  fetchEventGuests: thunk(async (actions, query, { getState }) => {
    const { event } = getState();
    if (!event) return;

    actions.setLoadingGuests(true);

    if (cancelToken) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
    cancelToken = Axios.CancelToken.source();
    const params: GuestParams = {};
    params.from = query.paginationFilter.skip;
    params.limit = query.paginationFilter.limit;
    params.searchText = query.searchTerm;
    const { data, metadata } = await EventModel.getGuests(event.id, params);
    actions.setTotalGuests(metadata?.total ?? 0);
    actions.setGuests(data ?? []);
    actions.setLoadingGuests(false);
  }),
  setEventPromoCodes: thunk(async (actions, _, { getState }) => {
    const { event } = getState();
    if (!event) return;
    const res = await EventModel.getPromoCodes(event.id);
    // console.log('Promo codes', res as PromoCode[]);
    actions.setEvent({ ...event, promoCodes: res as PromoCode[] });
  }),
  onQueryChange: thunkOn(
    (actions) => actions.setQuery,
    async (actions, { payload }) => {
      actions.fetchEventGuests(payload);
    }
  ),
};

export default EventDetailStore;
