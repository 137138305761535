import { action, Action, thunk, Thunk, ThunkOn, thunkOn } from 'easy-peasy';
import { TSearchResult } from 'Models';
import CommunityModel from 'Models/Community';
import { TCommunity } from 'Models/Community/@types';
import communitiesQueryUtil, { CommunitiesQuery } from './communitiesQueryUtil';


export interface CommunitiesState {
    communities: TCommunity[]
    setCommunities: Action<CommunitiesState, TCommunity[]>
    fetchCommunities: Thunk<CommunitiesState, CommunitiesQuery>

    loading: boolean
    setLoading: Action<CommunitiesState, boolean>

    totalCount: number
    setTotalCount: Action<CommunitiesState, number>

    query: CommunitiesQuery
    setQuery: Action<CommunitiesState, CommunitiesQuery>

    onQueryChange: ThunkOn<CommunitiesState, CommunitiesQuery>
}

const CommunitiesStore: CommunitiesState = {
    communities: [],
    setCommunities: action((state, payload) => {
        state.communities = payload;
    }),
    fetchCommunities: thunk(async (actions, payload) => {
        actions.setLoading(true);
        const _query = communitiesQueryUtil.getSearchQuery(payload);
        const { results, total } = await CommunityModel.search<TSearchResult<TCommunity>>(payload.q, _query)
        actions.setCommunities(results.map(r => r.hit));
        actions.setTotalCount(total);
        actions.setLoading(false);
    }),

    loading: false,
    setLoading: action((state, payload) => {
        state.loading = payload;
    }),

    query: {
        page: 1,
        q: '',
        approvalStatus: 'approved',
    },
    setQuery: action((state, payload) => {
        state.query = payload;
    }),

    totalCount: 0,
    setTotalCount: action((state, payload) => {
        state.totalCount = payload;
    }),

    onQueryChange: thunkOn(
        (actions) => actions.setQuery,

        async (actions, { payload }) => {
            actions.fetchCommunities(payload);
        }
    )
};

export default CommunitiesStore;

