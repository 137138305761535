import { Box, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import DialogWrapper from 'Dialogs';
import { TDiscussion } from 'Models/Discussion/@types';
import React, { FC } from 'react';
import { useStoreState } from 'Stores';
import { TDiscussionConfig } from '../useDiscussion';
import AboutSection from './AboutSection';

export interface DiscussionDialogBaseProps {
  discussion: TDiscussion;
  config: TDiscussionConfig;
  isAdmin?: boolean;
  isAuthenticated?: boolean;
}

export interface DiscussionAboutDialogProps extends DiscussionDialogBaseProps {
  onClose: () => void;
  open: boolean;
}

interface ITabConfig {
  id: number;
  name: string;
}
const tabConfig: ITabConfig[] = [
  {
    name: 'About',
    id: 0,
  },
  {
    name: 'Members',
    id: 1,
  },
];
const DiscussionAboutDialog: FC<DiscussionAboutDialogProps> = ({ discussion, onClose, open, config, isAuthenticated }) => {
  const classes = useStyles({});
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
  const isAdmin = appUser?.id === config.creatorId;
  return (
    <DialogWrapper className={classes.root} variant="light" open={open} onClose={onClose}>
      <IconButton className={classes.closeIcon} onClick={onClose}>
        <PgIcon icon="icon-close" />
      </IconButton>
      <Box display="flex">
        <PgTypo h6>{`#`}</PgTypo>
        <PgTypo align="center" variant="h6" className={classes.title}>{`${discussion.title}`}</PgTypo>
      </Box>
      <AboutSection closeDialog={onClose} isAdmin={isAdmin} isAuthenticated={isAuthenticated} config={config} discussion={discussion} />
    </DialogWrapper>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    closeIcon: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    root: {
      zIndex: `${theme.zIndex.modal + 5} !important` as CSSProperties['zIndex'],
      '& .MuiPaper-root': {
        width: 600,
        padding: theme.spacing(5, 3, 3),
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          height: '100%',
        },
      },
    },
    title: {
      marginBottom: theme.spacing(3),
      marginLeft: '5px',
      marginRight: '10px',
      textAlign: 'justify',
      wordBreak: 'break-all',
    },
  });
});

export default DiscussionAboutDialog;
