import React from 'react';
import { Link } from 'react-router-dom';

export const withLink = (children: React.ReactNode, link: string, isExternal?: boolean) => {
  return isExternal ? (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  ) : (
    <Link to={link}>{children}</Link>
  );
};

export default {
  withLink,
};
