import React from 'react';
import { ReactForm, IFormActionProps } from 'react-forms';
import * as Yup from 'yup';
import { makeStyles, Theme } from '@material-ui/core';
import { isDropPasswordValid } from 'Models/Event/api';
import ToastMessage, { ToastMessageProps } from 'Components/ToastMessage';
import { useSnackbar } from 'notistack';
import usePasswordField from 'Components/usePasswordField';
import { TContentType } from 'Models/Drop/@types';
import MESSAGES from 'Utils/Messages';

// TODO: ASHISH 7. ADD AN AFTERSUBMIT METHOD THAT TAKES PASSWORD AS A PARAMETER
type Props = {
  afterSubmit: (password: string) => void;
  id: string;
  onPasswordSubmit?: (password: string) => void;
  dropType?: TContentType;
};

export default function PasswordForm({ afterSubmit, id, onPasswordSubmit, dropType }: Props) {
  const classes = useStyles();

  const passwordField = usePasswordField('password', 'Secret code', false, {
    placeholder: 'Secret code',
    // className: classes.eachField,
  });
  const myConfig = [passwordField];

  const myInitialValues: IFormActionProps = {
    submitButtonText: 'UNLOCK',
    containerClassNames: classes.loginButton,
    submitButtonLayout: 'fullWidth',
    submitButtonProps: { color: 'secondary' },
  };

  const handleLoginSubmitButton = async (secreatCode: any) => {
    const { password } = secreatCode;
    if (onPasswordSubmit) {
      onPasswordSubmit(password);
      return;
    }
    if (dropType !== 'Event') {
      afterSubmit(password);
      return;
    }
    const res = await isDropPasswordValid(id, password, dropType);
    if (res) {
      afterSubmit(password);
    } else {
      enqueueSnackbar(ToastMessage({ title: MESSAGES.DROP_PASSWORD_INVALID }), {
        variant: 'error',
        autoHideDuration: 8000,
      });
    }
    // TODO: ASHISH 8. VALIDATE THE PASSWORD USING THE VALIDATION API.
    // IF IT RETURNS SUCCESS THEN CALL THE AFTERSUBMIT METHOD WITH THE VALIDATED PASSWORD
  };

  const formValidation = Yup.object({
    password: Yup.string().required('Secret code cannot be empty'),
  });

  const { enqueueSnackbar } = useSnackbar();

  return (
    <div className={classes.passwordFieldContainer}>
      <ReactForm
        config={myConfig}
        formId="required-password"
        onSubmit={handleLoginSubmitButton}
        actionConfig={myInitialValues}
        validationSchema={formValidation}
      />
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  loginButton: {
    marginTop: '20px',
  },
  passwordFieldContainer: {
    marginTop: '20px',
  },
}));
