import React from 'react';
import MultiImagePicker from 'Components/Inputs/MultiImagePicker';
import DateField from 'Features/ReactFormFields/DateField';
import TimeField from 'Features/ReactFormFields/TimeField';
import ToggleButtons from 'Features/ReactFormFields/ToggleButtons';
import EventLocationSuggest from 'Features/ReactFormFields/EventLocationSuggest';
import StreamingLink from 'Features/ReactFormFields/StreamingInfo';
import { attachField, setDefaultProps } from 'react-forms';
import 'rf-quill';
import FormSectionHeader from 'Forms/FormSectionHeader';
import LinkPreview from 'Features/ReactFormFields/LinkPreview';
import ImagePicker from 'Features/ReactFormFields/ImagePicker';
import AccordionArray from 'Components/FormFields/AccordionArray';
import MultiImagePickerWithDialog from 'Components/Inputs/MultiImagePickerWithDialog';
import Interests from 'Components/Interests';
import { GoogleLocationSuggest } from 'Forms/UserProfile/CityField';
import ResourceSuggest from './ResourceSuggest';
import ColorField from './ColorField';
import ProviderSelection from './NewsLetterProvider';
import ColorPickerField from './ColorPickerField';
import TicketAccordion from './TicketAccordion';
import Payout from './Payout';
import PromoButton from './PromoButton';
import PromoCode from './PromoCode';
import RCTimePicker from './RCTimePicker';
import { LocationBasedTimeZone } from './LocationBasedTimeZone';
import InputWithValidStatusField from './InputWithValidStatusField';
import EmptyField from './EmptyField';
import LinkButton from './LinkButton';
import PhoneInput from './PhoneInput';
import OtpInput from './OtpInput';
import AddPhoneButton from './AddPhoneButton';
import SingleDatepicker from './Datepicker/SingleDatepicker';
import DateRangepicker from './Datepicker/DateRangepicker';
import Timepicker from './Datepicker/Timepicker';
import VerifyEmailButton from './VerifyEmailButton';
import StripeCardDetail from './StripeCardDetail';
import TokenList from './TokenList';
import UrlInputField from './UrlInputField';
import PrivacyTextInfo from 'Components/PrivacyTextInfo';
import DOBField from 'Components/FormFields/DOBField';
import WithCollapsedConfig from './WithCollapsedConfig';
import SocialLinksField from './SocialLinksField';
import WithSwitchConfig from './withSwitchConfig';

// const setSelectDefaults = () => {
//   setDefaultProps('select', {
//     native: true,
//     inputLabelProps: {
//       disableAnimation: true,
//       shrink: false,
//       style: {
//         marginTop: -22,
//         fontSize: 12,
//       },
//     },
//     fullWidth: true,
//     formControlProps: {
//       fullWidth: true,
//     },
//     style: {
//       backgroundColor: THEME_PALETTE.grey.A200,
//       padding: '0 14px',
//       borderRadius: 4,
//       borderBottom: 'none',
//       top: 4,
//     },
//   });
// };

const setTextDefaults = () => {
  setDefaultProps('text', {
    variant: 'outlined',
    fullWidth: true,
    inputProps: {
      autoComplete: 'new-password',
    },
  });
  setDefaultProps('location-timezone', {
    variant: 'outlined',
    fullWidth: true,
  });
  setDefaultProps('url-field', {
    variant: 'outlined',
    fullWidth: true,
  });
  setDefaultProps('select', {
    variant: 'outlined',
    formControlProps: {
      fullWidth: true,
    },
    inputLabelProps: { variant: 'outlined' },
    native: true,
  });
};

const setRTEDefaults = () => {
  setDefaultProps('rte-quill', {
    fieldProps: {
      sizes: [],
      toolbarProps: {
        toolbarOptions: ['size', 'lists'],
        formats: ['bold', 'italic', 'underline', 'link'],
      },
    },
  });
};
const setDefaults = () => {
  // setSelectDefaults();
  setTextDefaults();
  // setRTEDefaults();
};

export default (): void => {
  // @ts-ignore
  attachField(['header', 'section-header'], <FormSectionHeader />);
  attachField('time-field', <TimeField />);
  attachField('resource-suggest', <ResourceSuggest />);
  attachField('color-field', <ColorField />);
  attachField('color-picker-field', <ColorPickerField />);
  attachField('date-field', <DateField />);
  attachField('multi-image', <MultiImagePicker />);
  attachField(['image-upload', 'image-upload-2'], <ImagePicker />);
  attachField('toggle-buttons', <ToggleButtons />);
  attachField('google-autocomplete', <EventLocationSuggest />);
  attachField('empty', <EmptyField />);
  attachField('streaming-link', <StreamingLink />);

  attachField('link-preview', <LinkPreview />);
  attachField('promo-button', <PromoButton />);
  attachField('newsletter-provider', <ProviderSelection />);
  attachField('ticket-accordion', <TicketAccordion />);
  attachField('payout-section', <Payout />);
  attachField('discount-code', <PromoCode />);
  attachField('discount-code-disabled', <PromoCode />, { fieldProps: { disabled: true } });

  attachField('accordion-array', <AccordionArray />);
  attachField('multi-image-dialog', <MultiImagePickerWithDialog />);
  attachField('rc-timePicker', <RCTimePicker />);
  attachField('location-timezone', <LocationBasedTimeZone />);
  attachField('user-interests', <Interests />);
  attachField('input-w-status', <InputWithValidStatusField />);
  attachField('link-button', <LinkButton />);

  attachField('phone-input', <PhoneInput />);
  attachField('otp', <OtpInput />);

  attachField('phone-w-status', <InputWithValidStatusField type="phone" />);

  // @ts-ignore
  attachField('city', <GoogleLocationSuggest variant="light" />);
  // @ts-ignore
  attachField('city-dark', <GoogleLocationSuggest variant="dark" />);

  attachField('add-phone-button', <AddPhoneButton />);
  attachField('verify-email-button', <VerifyEmailButton />);

  attachField('single-datepicker', <SingleDatepicker />);
  attachField('date-rangepicker', <DateRangepicker />);
  attachField('timepicker', <Timepicker />);
  attachField('stripe-card', <StripeCardDetail />);
  attachField('token-list', <TokenList />);

  attachField('url-field', <UrlInputField />);

  attachField('privacy-info', <PrivacyTextInfo />);

  attachField('dob-field', <DOBField />);

  attachField('with-collapsed', <WithCollapsedConfig />);
  attachField('with-switch', <WithSwitchConfig />);

  attachField('social-links-field', <SocialLinksField />);

  setDefaults();
};
