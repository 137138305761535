import { action, Action, ActionOn, actionOn, thunk, Thunk } from 'easy-peasy';
import DropQuery from 'Features/Query/DropsQuery';
import { omit } from 'lodash';
import { TFilter } from 'Models/App/@types';
import DropModel from 'Models/Drop';
import { TDrop } from 'Models/Drop/@types';
import { EVENT_FIELDS } from 'Models/Event/utils';
import { resourceIncludeFilter } from 'Models/Resource';
import { TRootStore } from 'Stores';
import helpers from 'Utils/helpers';

export interface DropsPageState {
  drops: TDrop[];
  featuredDrops: TDrop[];
  setDropList: Action<DropsPageState, { drop: TDrop[] }>;
  total: number;
  aggregations?: Record<string, unknown>;
  fetchDrops: Thunk<DropsPageState, { query: DropQuery; limit?: number; skip?: number }>;
  fetchFeaturedDrop: Thunk<DropsPageState, { query: DropQuery }>;
  updateDropList: Action<DropsPageState, { drop: TDrop; action: 'ADD' | 'UPDATE' | 'PUT' | 'DELETE' }>;
  displayDrops: TDrop[];
  setDisplayDrops: Action<DropsPageState, TDrop[]>;
  fetchDisplayDrops: Thunk<DropsPageState, { query: DropQuery, page: number, limit?: number, skip?: number }>;
  setTotal: Action<DropsPageState, number>;
  onContentFavorite: ActionOn<DropsPageState, TRootStore>;
  onContentUnFavorite: ActionOn<DropsPageState, TRootStore>;
}

export const DROPS_SEARCH_PAGE_LIMIT = 20;
// const unfeaturedShouldFilter = [
//   [
//     {
//       bool: {
//         must_not: [
//           {
//             match: {
//               featured: true,
//             },
//           },
//         ],
//       },
//     },
//     {
//       range: {
//         releaseDate: {
//           lt: new Date().toISOString(),
//         },
//       },
//     },
//   ],
// ];
const unfeaturedShouldFilter = [
  [
    {
      bool: {
        must_not: [
          {
            match: {
              featured: true,
            },
          },
        ],
      },
    },
  ],
  // [
  //   {
  //     bool: {
  //       should: [
  //         {
  //           range: {
  //             releaseDate: {
  //               lt: new Date().toISOString(),
  //             },
  //           },
  //         },
  //       ],
  //     },
  //   },
  // ],
];

const DropsPageStore: DropsPageState = {
  total: 0,
  drops: [],
  featuredDrops: [],
  displayDrops: [],
  setDisplayDrops: action((state, payload) => {
    state.displayDrops = payload;
  }),
  setTotal: action((state, payload) => {
    state.total = payload;
  }),
  fetchDisplayDrops: thunk(async (actions, { query, page = 0 }, { getState }) => {
    const state = getState();
    const totalSkip = DROPS_SEARCH_PAGE_LIMIT * page;
    const featuredPages = parseInt(`${state.featuredDrops.length / DROPS_SEARCH_PAGE_LIMIT}`);
    const remainderFeaturedComms = state.featuredDrops.length % DROPS_SEARCH_PAGE_LIMIT;

    if (featuredPages < page + 1) {
      const unfeaturedSkip = totalSkip ? totalSkip - state.featuredDrops.length : totalSkip;
      const unfeaturedLimit = featuredPages === page ? DROPS_SEARCH_PAGE_LIMIT - remainderFeaturedComms : DROPS_SEARCH_PAGE_LIMIT;
      const res = await actions.fetchDrops({ query: query, limit: unfeaturedLimit, skip: unfeaturedSkip });
      actions.setDisplayDrops([...state.featuredDrops.slice(totalSkip), ...(res ?? [] as TDrop[])]);
    }
    else actions.setDisplayDrops(state.featuredDrops.slice(totalSkip, totalSkip + DROPS_SEARCH_PAGE_LIMIT));
  }),
  setDropList: action((state, payload) => {
    state.drops = [...payload.drop];
  }),
  aggregations: {},
  updateDropList: action((state, payload) => {
    state.drops = helpers.updateItemList(state.drops, payload.drop, payload.action);
  }),
  fetchFeaturedDrop: thunk(async (actions, { query }, { getState }) => {
    const state = getState();
    query.isFeatured = true;
    const res = await DropModel.search(undefined, getFilter(query, 0, 50));
    state.featuredDrops = res.results;
    query.isFeatured = false;
    const unfeaturedRes = await DropModel.search(undefined, getFilter(query, undefined, 1, 0, unfeaturedShouldFilter));
    actions.setTotal(res.total + unfeaturedRes.total)
  }),
  fetchDrops: thunk(async (actions, { query, limit, skip }, { getState }) => {
    const state = getState();
    state.drops = [];
    query.isFeatured = false;
    const searchResponse = await DropModel.search(undefined, getFilter(query, undefined, limit, skip, unfeaturedShouldFilter));
    state.drops = searchResponse.results;
    return searchResponse.results;
  }),

  onContentFavorite: actionOn(
    (actions, storeActions) => storeActions.AuthStore.contentFavorited,
    (state, { payload: { event, favouriteId } }) => {
      state.displayDrops = state.displayDrops.map((e) => (e.id === event.id ? { ...e, ...event, isFavourited: true, favouriteId } : e)) as TDrop[];
    },
  ),
  onContentUnFavorite: actionOn(
    (actions, storeActions) => storeActions.AuthStore.contentUnFavorited,
    (state, { payload }) => {
      state.displayDrops = state.displayDrops.map((e) => (e.id === payload ? omit(e, ['isFavourited', 'favouriteId']) : e)) as TDrop[];
    },
  ),
};

export default DropsPageStore;

const getFilter = (query: DropQuery, page = 0, limit?: number, skip?: number, shoudFilter: TFilter['should'] = []) => ({
  term: query.query.searchTerm,
  filter: {
    limit: limit ?? DROPS_SEARCH_PAGE_LIMIT,
    // eslint-disable-next-line no-nested-ternary
    skip: skip ?? page * DROPS_SEARCH_PAGE_LIMIT,
    should: [...(query.searchFilter.should ?? []), ...shoudFilter],
    ...omit(query.searchFilter, 'should'),
    Event: {
      fields: EVENT_FIELDS,
      include: [
        'tags',
        'ticketingInfo',
        {
          relation: 'community',
          scope: {
            fields: ['_profileImages', 'id', 'name', 'slug'],
          },
        },
        'includePurchasedTickets',
        // 'includePurchasersMeta',
        'collaborators',
      ],
    },
    Video: resourceIncludeFilter,
    Collect: resourceIncludeFilter,
    Podcast: resourceIncludeFilter,
    Article: resourceIncludeFilter,
    Album: resourceIncludeFilter,
    Soundtrack: resourceIncludeFilter,
  },
});
