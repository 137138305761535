import React, { useRef, useEffect } from 'react';
import { ReactForm } from 'react-forms';
import { FormikProps } from 'formik';
import { useFormConfig } from './config';

export interface ResetLinkFormData {
  email: string;
}

export interface ResetLinkFormProps {
  onSubmit: (email: string) => any;
  initialData: Partial<ResetLinkFormData>;
  isSubmitting: boolean;
  disableAction?: boolean;
}

const ResetLinkForm: React.FC<ResetLinkFormProps> = (props) => {
  const formRef = useRef<FormikProps<Partial<ResetLinkFormData>>>(null);
  const { actionConfig, inputConfig, validationSchema } = useFormConfig(!!props.disableAction);
  const { initialData, onSubmit, isSubmitting } = props;

  // useEffect(() => {
  //   if (!isSubmitting) {
  //     formRef.current?.resetForm();
  //   }
  // }, [isSubmitting]);

  return (
    <ReactForm
      initialValues={initialData}
      formId="ResetLink"
      config={inputConfig}
      actionConfig={actionConfig}
      innerRef={formRef}
      onSubmit={(e: ResetLinkFormData) => {
        onSubmit(e.email);
      }}
      validationSchema={validationSchema}
      formSettings={{
        verticalSpacing: 20,
      }}
      isInProgress={isSubmitting}
    />
  );
};

export default ResetLinkForm;
