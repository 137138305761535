import React, { FC } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

export interface BellProps extends SVGCIconProps {
  iconProps?: {
    color?: string;
    outlineColor?: string;
  };
}

const Bell: FC<BellProps> = (props) => {
  const { color, outlineColor } = props.iconProps ?? {};
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.95813 14.0417C4.00756 14.1525 4.03518 14.2751 4.03556 14.4041C4.03699 14.8999 3.63555 15.3033 3.13798 15.3062C2.82381 15.308 2.56958 15.5624 2.56958 15.8759C2.56958 16.1906 2.82562 16.4457 3.14146 16.4457H16.8588C17.1747 16.4457 17.4307 16.1906 17.4307 15.8759C17.4307 15.5624 17.1765 15.308 16.8623 15.3062C16.3647 15.3033 15.9633 14.8999 15.9647 14.4041C15.9651 14.2757 15.9925 14.1536 16.0415 14.0432V8.83699C16.0415 5.51245 13.3365 2.81738 9.9998 2.81738C6.66308 2.81738 3.95813 5.51245 3.95813 8.83699L3.95813 14.0417Z"
        fill={color ?? '#4E45FF'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.52843 2.36978C7.5043 2.29919 7.4975 2.22165 7.51212 2.14312C7.73605 0.940851 8.74779 0 9.99992 0C11.2521 0 12.2638 0.940851 12.4877 2.14312C12.5024 2.22181 12.4955 2.29952 12.4713 2.37022C15.086 3.36287 16.9437 5.88434 16.9437 8.8381V14.4078H16.8669C16.8927 14.408 16.9183 14.4088 16.9437 14.4102C17.2616 14.4283 17.5526 14.5468 17.7852 14.7343C18.1193 15.0036 18.3329 15.4155 18.3329 15.8771C18.3329 16.6885 17.6727 17.3463 16.8583 17.3463L12.5343 17.3463C12.5343 18.7861 11.4246 20 9.99959 20C8.57461 20 7.46487 18.7862 7.46487 17.3464L3.14091 17.3463C2.32648 17.3463 1.66626 16.6885 1.66626 15.8771C1.66626 15.4157 1.87966 15.004 2.21349 14.7347C2.44599 14.5471 2.7369 14.4285 3.05481 14.4102C3.08045 14.4088 3.10627 14.408 3.13224 14.4078H3.05481L3.05481 8.8381C3.05481 5.8839 4.91303 3.36212 7.52843 2.36978ZM8.45354 2.09103C8.67532 1.38448 9.2976 0.899482 9.99992 0.899482C10.7023 0.899482 11.3247 1.38463 11.5464 2.09135C11.0489 1.97857 10.531 1.91901 9.99926 1.91901C9.46797 1.91901 8.95062 1.97845 8.45354 2.09103ZM8.36765 17.3464L11.6315 17.3463C11.6315 18.3409 10.8758 19.1005 9.99959 19.1005C9.1234 19.1005 8.36765 18.3409 8.36765 17.3464ZM3.95759 14.0428C4.00702 14.1536 4.03464 14.2762 4.03501 14.4052C4.03645 14.901 3.63501 15.3044 3.13744 15.3073C2.82327 15.3091 2.56904 15.5635 2.56904 15.8771C2.56904 16.1917 2.82508 16.4468 3.14091 16.4468L16.8583 16.4468C17.1741 16.4468 17.4301 16.1917 17.4301 15.8771C17.4301 15.5635 17.1759 15.3091 16.8617 15.3073C16.3642 15.3044 15.9627 14.901 15.9642 14.4052C15.9645 14.2768 15.9919 14.1547 16.0409 14.0443V8.8381C16.0409 5.51356 13.336 2.81849 9.99926 2.81849C6.66254 2.81849 3.95759 5.51356 3.95759 8.8381L3.95759 14.0428Z"
        fill={outlineColor ?? 'white'}
      />
    </svg>
  );
};

export default Bell;
