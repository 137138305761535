import { action, Action, thunk, Thunk } from 'easy-peasy';
import PurchasedTicketModel from 'Models/PurchasedTicket';
import { TPurchasedTicket } from 'Models/PurchasedTicket/@types';
import { TRootStore } from 'Stores';

export interface TTicketDetailState {
  detail: TPurchasedTicket | undefined;
  setDetail: Action<TTicketDetailState, TPurchasedTicket | undefined>;
  fetchTicketDetail: Thunk<TTicketDetailState, string, null, TRootStore>;

  loading: boolean;
  setLoading: Action<TTicketDetailState, boolean>;
}

const TicketDetailStore: TTicketDetailState = {
  detail: undefined,
  loading: false,
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setDetail: action((state, payload) => {
    state.detail = payload;
  }),
  fetchTicketDetail: thunk(async (actions, ticketId) => {
    actions.setLoading(true);
    const { data } = await PurchasedTicketModel.getPurchasedTicketDetail(ticketId);
    actions.setDetail(data);
    actions.setLoading(false);
  }),
};

export default TicketDetailStore;
