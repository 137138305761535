import { Picture } from './@types';

// eslint-disable-next-line import/prefer-default-export
export const parsePicture = (response: any): Picture => {
  const pic: Picture = {
    ...response,
    thumbnail: response.thumbnailUrl,
    imagePath: response.filePath,
  };
  return pic;
};
