import React, { FC } from 'react';
import { Box, ButtonBase, makeStyles } from '@material-ui/core';
import { TNotification } from 'Models/App/@types';
import helpers from 'Utils/helpers';
import ActionButtons from './ActionButtons';
import clsx from 'clsx';
import PgButton from 'Components/PgButton';
import moment from 'moment';
import PgTypo from 'Components/PgTypo';
import { SHADES } from 'Theme/newThemeConstants';
import UserAvatar from 'Components/UserAvatar';

interface NotifItemProps {
  notification: TNotification;
  readNotif: (notifId: string) => Promise<void>;
  onActionClick?: (isAccepted?: boolean) => void;
}

const NotifItem: FC<NotifItemProps> = ({ notification, readNotif, onActionClick }) => {
  const { text, actionButtons, variableMapping, created, isActionPerformed, actionState } = notification;
  const classes = styles();

  return (
    <Box
      component={!notification.isRead ? PgButton : undefined}
      className={clsx(classes.root, { [classes.newNotif]: !notification.isRead })}
      onClick={!notification.isRead ? () => readNotif(notification.id) : undefined}
    >
      <Box className={classes.imgTextContainer}>
        <a href={variableMapping?.profileImageLink}>
          {/* <img src={helpers.getPictureUrl(variableMapping?.profileImage, 80, 80) || helpers.getPlaceholderProfileImage()} className={classes.img} /> */}
          <UserAvatar
            size={40}
            name={variableMapping?.user ?? variableMapping?.communityName ?? variableMapping?.resourceName ?? ''}
            url={helpers.getPictureUrl(variableMapping?.profileImage, 80, 80)}
          />
        </a>
        <PgTypo b4 className={classes.mainText}>
          <PgTypo component="span" dangerouslySetInnerHTML={{ __html: text }} />
          <PgTypo b4 className={classes.timeDiff}>
            {moment.duration(moment().diff(moment(created))).humanize()} ago
          </PgTypo>
        </PgTypo>
      </Box>
      <Box className={classes.buttonsContainer}>
        {isActionPerformed ? (
          actionState === 'ACCEPTED' ? (
            <PgButton secondary disabled style={{ fontSize: 12 }}>
              Accepted
            </PgButton>
          ) : null
        ) : (
          <ActionButtons
            actions={actionButtons}
            notiftriggeredId={notification.notificationTriggerId}
            variableMapping={variableMapping}
            onActionClick={onActionClick}
          />
        )}
      </Box>
    </Box>
  );
};

export default NotifItem;

const styles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0),
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: { flexWrap: 'wrap' },
    '& span': { gap: 12 }
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  imgTextContainer: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
  },
  mainText: {
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'none',
    '& span': { display: 'inline' },
    '& *': { wordBreak: 'break-word' },
  },
  timeDiff: {
    paddingLeft: theme.spacing(1.5),
    color: SHADES.greyDark,
    whiteSpace: 'nowrap',
    display: 'inline',
  },
  img: {
    width: 40,
    height: 40,
    borderRadius: '50%',
  },
  newNotif: {
    paddingLeft: theme.spacing(4.5),
    position: 'relative',
    textAlign: 'left',
    '&::after': {
      width: 8,
      height: 8,
      content: "' '",
      borderRadius: 4,
      backgroundColor: theme.palette.error.main,
      position: 'absolute',
      top: '50%',
      left: 16,
      transform: 'translateY(-50%)',
    },
    [theme.breakpoints.down('sm')]: {
      '& > span': {
        flexWrap: 'inherit',
        gap: 'inherit',
        justifyContent: 'unset',
      },
    },
  },
}));
