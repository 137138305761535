import { generatePath, match as Match } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies

// eslint-disable-next-line import/no-cycle
import { history } from 'App';
// eslint-disable-next-line import/no-cycle
import { EVENT_ROUTES } from 'Routes/DashboardRoutes/EventNavigation';
import { DASHBOARD_ROUTES } from 'Routes/DashboardRoutes/AppNavigation';
// eslint-disable-next-line import/no-cycle
import { EVENT_MEDIA_ROUTES } from 'Routes/DashboardRoutes/EventsAndMediaNavigation';
import { ResourceType } from 'Models/Resource/@types';
// eslint-disable-next-line import/no-cycle
import { RESOURCE_ROUTES } from 'Routes/DashboardRoutes/ResourceNavigation';
import { CollectType } from 'Models/Collect/@types';
import { APP_ROUTES } from 'Routes';
import { TUserCommunity } from 'Models/User/@types';

export const isLinkActive = (match: Match | null): boolean => !!match;

export const appNavigator = {
  searchEvents(query: string,communityId:string, urlOnly = false): string | undefined {
    const url = generatePath(EVENT_MEDIA_ROUTES.EVENTS_LIST.path,{communityId}) + (query ? `?${query}` : '');
    if (urlOnly) return url;
    history.push(url);
    return undefined;
  },
  searchResources(type: ResourceType,communityId : string, query: string, urlOnly = false): string | undefined {
    let baseUrl = '';
    if (type === 'article') baseUrl = EVENT_MEDIA_ROUTES.READ.path;
    else if (type === 'collect') baseUrl = EVENT_MEDIA_ROUTES.COLLECT.path;
    else if (type === 'podcast') baseUrl = EVENT_MEDIA_ROUTES.LISTEN.path;
    else if (type === 'video') baseUrl = EVENT_MEDIA_ROUTES.WATCH.path;
    const url = generatePath(baseUrl , {communityId}) + (query ? `?${query}` : '');
    if (urlOnly) return url;
    history.push(url);
    return undefined;
  },
  resourceDetail(
    type: ResourceType,
    resourceId: string,
    communityId : string,
    urlOnly = false,
    options: { collectType: CollectType } = { collectType: 'merchandise' }
  ): string | undefined {
    let baseUrl = '';
    if (type === 'article') baseUrl = RESOURCE_ROUTES.RESOURCE_READ_EDIT.path;
    else if (type === 'collect') baseUrl = `${RESOURCE_ROUTES.RESOURCE_COLLECT_EDIT.path}?type=${options.collectType}`;
    else if (type === 'podcast') baseUrl = RESOURCE_ROUTES.RESOURCE_LISTEN_EDIT.path;
    else if (type === 'video') baseUrl = RESOURCE_ROUTES.RESOURCE_WATCH_EDIT.path;
    const url = generatePath(baseUrl, { id: resourceId ,communityId});
    if (urlOnly) return url;
    history.push(url);
    return undefined;
  },
  eventDetail(eventId: string,  communityId : string,query: string, urlOnly = false): string | undefined {
    const url = generatePath(EVENT_ROUTES.EVENT_HOME.path, { id: eventId ,communityId}) + (query ? `?${query}` : '');
    if (urlOnly) return url;
    history.push(url);
    return undefined;
  },
  searchCommunityUsers(query: string, communityId : string,urlOnly = false): string | undefined {
    const url = generatePath(DASHBOARD_ROUTES.COMMUNITY_MANAGEMENT.path,{communityId}) + (query ? `?${query}` : '');
    if (urlOnly) return url;
    history.push(url);
    return undefined;
  },
  editEvent(eventId: string, communityId : string, urlOnly = false, query?: string): string | undefined {
    const url = generatePath(EVENT_MEDIA_ROUTES.EDIT_DROP_FORM.path + (query ? `?${query}` : ''), { dropId: eventId, communityId, dropType: 'event' });
    if (urlOnly) return url;
    history.push(url);
    return undefined;
  },
  duplicateEvent(eventId: string,communityId:string, urlOnly = false): string | undefined {
    // const url = generatePath(EVENT_ROUTES.DUPLICATE_EVENT.path, {
    //   id: eventId,
    //   communityId
    // });
    const url = generatePath(EVENT_MEDIA_ROUTES.DUPLICATE_DROP_FORM.path, { communityId, dropType: 'event', dropId: eventId });
    if (urlOnly) return url;
    history.push(url);
    return undefined;
  },
  communityProfile: (community: TUserCommunity, urlOnly = false): string | undefined => {
    const url = generatePath(APP_ROUTES.COMMUNITY_DETAIL.path, {
      communityId: community.slug ?? community.id,
    });
    if (urlOnly) return url;
    history.push(url);
    return undefined;
  },
};
