import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import { LocationOnOutlined } from '@material-ui/icons';
import PgTypo from 'Components/PgTypo';
import Tag from 'Components/Tag';
import CollaboratorsGroup from 'Features/Collaboration/CollaboratorsGroup';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import DropModel from 'Models/Drop';
import { TDrop } from 'Models/Drop/@types';
import EventModel, { TEvent } from 'Models/Event';
import { TResource } from 'Models/Resource/@types';
import DateTimeUtils, { DEFAULT_DATE_FORMAT } from 'Resources/DateTimeUtils';
import { BORDER_BLACK } from 'Theme/themeConstants';
import DropUtils from 'Utils/DropUtils';
import helpers from 'Utils/helpers';
import React, { FC, useMemo } from 'react';

export interface RSVPDropCardProps {
  drop: TDrop;
}

const RSVPDropCard: FC<RSVPDropCardProps> = (props) => {
  const { drop } = props;

  const { isDeviceSm } = useMediaQuery();

  const tag = useMemo(() => {
    if (drop.doc_type === 'Event') return DropUtils.getEventPrimaryTag(drop);
    return DropUtils.getResourcePrimaryTag(drop);
  }, [drop.doc_type]);

  const data = useMemo(() => {

    const collaborators = DropModel.getCollaborators(drop);

    if (drop.doc_type === 'Event') {
      const event = drop as TEvent;
      const date = event?.featureToggle?.when ? EventModel.getEventDateWithLocation(event, true) : '';
      return {
        imageUrl: helpers.getPictureUrl(event.primaryImage ?? event._primaryImage) || helpers.getPlaceholderImageUrl(),
        name: event?.name,
        collaborators,
        date,
        city: event?.address?.city || event?.address?.state,
        type: event?.eventType,
      };
    }

    const resource = drop as TResource;
    const date = resource.releaseDate && resource?.featureToggle?.when ? DateTimeUtils.formatDate(resource.releaseDate, DEFAULT_DATE_FORMAT) : '';
    return {
      name: resource.title,
      imageUrl: helpers.getPictureUrl(resource._resourceImages?.[0]) ?? helpers.getPlaceholderImageUrl(),
      collaborators,
      date,
    };
  }, [drop.id]);

  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      m={isDeviceSm ? 1.5 : 0}
      p={isDeviceSm ? 2 : 2.5}
      display="flex"
      flexDirection={isDeviceSm ? 'row' : 'column'}
      gridGap={isDeviceSm ? 16 : 24}
    >
      <Box position="relative">
        <img src={data.imageUrl} alt={data.name} className={classes.img} />

        {!!drop.doc_type && (
          <Box className={classes.dropTypeTag}>
            <PgTypo b1 color="textSecondary">
              {DropUtils.isResourceTypeMatched(drop.doc_type, 'collect') ? 'Merch' : drop.doc_type}
            </PgTypo>
          </Box>
        )}

        {!isDeviceSm && (
          <Box position="absolute" bottom={-30} right={12}>
            <CollaboratorsGroup collaborators={data.collaborators} />
          </Box>
        )}
      </Box>
      <Box>
        {tag ? (
          <Tag size="small" color={tag.background}>
            {tag.name}
          </Tag>
        ) : null}

        <PgTypo c5>{data.date}</PgTypo>
        <PgTypo c2>{data.name}</PgTypo>

        {data.city && data.type === 'offline' ? (
          <Box my={1} display="flex" alignItems="center">
            <LocationOnOutlined />
            <PgTypo b5>{data.city}</PgTypo>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default RSVPDropCard;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2.5),
      gap: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        margin: theme.spacing(1.5),
        padding: theme.spacing(2),
        gap: theme.spacing(2),
        border: BORDER_BLACK,
      },
    },
    img: { width: '100%', objectFit: 'cover', aspectRatio: '1 / 1', verticalAlign: 'bottom', [theme.breakpoints.down('sm')]: { width: 100 } },
    dropTypeTag: { position: 'absolute', backgroundColor: `rgb(0 0 0 / 50%)`, padding: `5px 7px 3px`, left: 10, top: 4 },
  }),
);
