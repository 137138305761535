import React, { FC } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

export interface UploadFileProps extends SVGCIconProps {
  iconProps?: Record<string, unknown>;
}

const UploadFile: FC<UploadFileProps> = (props) => {
  const { color, height = 23, width = 24 } = props;
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 23L24 23V18.2698C24 17.9582 23.7479 17.7057 23.4368 17.7057C23.1258 17.7057 22.8736 17.9582 22.8736 18.2698V21.8717L1.12635 21.8717L1.12635 18.2698C1.12635 17.9582 0.874211 17.7057 0.563177 17.7057C0.252143 17.7057 0 17.9582 0 18.2698L0 23Z"
        fill={color ?? 'black'}
      />
      <path
        d="M18.6365 7.21212C18.4166 7.43244 18.06 7.43244 17.84 7.21212L12.5632 1.92613V14.6245C12.5632 14.9361 12.311 15.1887 12 15.1887C11.689 15.1887 11.4368 14.9361 11.4368 14.6245V1.92613L6.15996 7.21212C5.94003 7.43244 5.58344 7.43244 5.36351 7.21212C5.14357 6.99181 5.14357 6.63461 5.36351 6.41429L11.6018 0.165234C11.7074 0.0594349 11.8506 -1.90735e-06 12 -1.90735e-06C12.1494 -1.90735e-06 12.2926 0.0594349 12.3982 0.165234L18.6365 6.4143C18.8564 6.63461 18.8564 6.99181 18.6365 7.21212Z"
        fill={color ?? 'black'}
      />
    </svg>
  );
};

export default UploadFile;
