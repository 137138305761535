import useToastMessage from 'Hooks/useToastMessage';
import { NotificationPreferenceData } from 'Forms/NotificationPreferenceForm';
import { createContext, Provider, useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'Stores';
import ViewModel from 'Core/@types';
import useAsyncTask from 'Hooks/useAsyncTask';
import { NotificationConfigurations } from 'Features/Notifications/types';
import { NotificationPreferences } from 'Models/App/@types';
import Messages from 'Utils/Messages';
class AccountNotificationViewModel implements ViewModel<AccountNotificationsViewModelState> {
  Hook = useAccountNotification;

  CtxProvider = AccountNotificationsContext.Provider;
}

function useAccountNotification(): AccountNotificationsViewModelState {
  const { isLoadingNotificationPreferences, notificationConfigurations, notificationPreferences } = useStoreState(
    ({ UserStore: { isLoadingNotificationPreferences, notificationConfigurations, notificationPreferences } }) => ({
      isLoadingNotificationPreferences,
      notificationConfigurations,
      notificationPreferences,
    })
  );

  const { getNotificationConfigurations, getNotificationPreferences, updateNotificationPreferences } = useStoreActions(
    ({ UserStore: { getNotificationConfigurations, getNotificationPreferences, updateNotificationPreferences } }) => ({
      getNotificationConfigurations,
      getNotificationPreferences,
      updateNotificationPreferences,
    })
  );

  const getNotificationConfigurationsTask = useAsyncTask(getNotificationConfigurations);
  const getNotificationPreferencesTask = useAsyncTask(getNotificationPreferences);
  const updateNotificationPreferencesTask = useAsyncTask(updateNotificationPreferences);

  const withToast = useToastMessage();

  const handleSubmit = async (formData: Partial<NotificationPreferences>) => {
    await withToast(async () => updateNotificationPreferencesTask.run(formData), {
      successToastMessage: {
        title: Messages.NOTIFICATION_PREFERENCE_SUCCESS_TITLE,
      },
      errorToastMessage: 'Something went wrong,Please try again',
    });

    // updateNotificationPreferencesTask.run(formData);
  };

  const state: AccountNotificationsViewModelState = {
    handleSubmit,
    isLoading: getNotificationPreferencesTask.status === 'PROCESSING' || getNotificationConfigurationsTask.status === 'PROCESSING',
    isSubmitting: updateNotificationPreferencesTask.status === 'PROCESSING',
    notificationConfigurations,
    notificationPreferences,
  };

  useEffect(() => {
    getNotificationPreferencesTask.run();
    getNotificationConfigurationsTask.run();
  }, []);

  return state;
}

export const AccountNotificationsContext = createContext<AccountNotificationsViewModelState>({
  handleSubmit: async (_: Partial<NotificationPreferences>) => {},
  isLoading: false,
  isSubmitting: false,
});

export interface AccountNotificationsViewModelState {
  handleSubmit?: (form: Partial<NotificationPreferences>) => Promise<void>;
  isLoading?: boolean;
  isSubmitting?: boolean;
  notificationConfigurations?: NotificationConfigurations;
  notificationPreferences?: NotificationPreferences;
}

export default AccountNotificationViewModel;
