import { Theme, alpha, createStyles, makeStyles } from "@material-ui/core";
import { COLOR, THEME_PALETTE } from "Theme/themeConstants";

const useStyles = makeStyles((theme: Theme) => createStyles({
  textField: { marginTop: theme.spacing(3) },
  // toggleBtContainer: { display: 'flex', gap: theme.spacing(1, 2.5), '& > button': { margin: 0 } },
  toggleBtContainer: { display: 'flex', '& > button': { margin: 0 } },
  defaultBtn: {
    color: theme.palette.common.black,
    borderColor: theme.palette.common.black,
    '&:hover, &:focus': { borderColor: theme.palette.common.black }
  },
  selectedBtn: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    '&:hover, &:focus': { backgroundColor: theme.palette.common.black },
    '&.Mui-disabled': { backgroundColor: alpha(theme.palette.common.black, 0.4) },
  },
  ticketTypeBtnWrap: { [theme.breakpoints.down('sm')]: { flexDirection: 'column !important' } },
  locationOptions: { '&[data-focus="true"]': { backgroundColor: theme.palette.primary.main + ' !important', color: theme.palette.common.white } },
  locationInput: { paddingRight: '10px !important' },
  privacyOptionTitle: { whiteSpace: 'nowrap', display: '-webkit-inline-box' },
  privacyOptionSubtitle: { display: 'inline' },
  externalLink: { textDecoration: 'underline', color: theme.palette.secondary.main },

  // when
  whenFields: {
    width: '100%',
    '& .rc-time-picker, input, .MuiInputBase-root, fieldset': {
      // backgroundColor: 'unset',
      color: theme.palette.common.black,
      // border: 'none', '&:focus': { border: 'none' },
    },
    // '& .rc-time-picker-input': { left: '0 !important', padding: theme.spacing(0.4, 0) },
    // '& input, div.MuiInputBase-root': { padding: '8px 0px !important' },
    '& .rc-time-picker-input': { left: '0 !important', padding: theme.spacing(1) },
    '& input, div.MuiInputBase-root': { padding: '8px !important' },
    '& input': { ...theme.typography.caption, fontWeight: `${theme.typography.fontWeightRegular} !important` },
    '& input.MuiInputBase-input': { padding: '0 !important' },
    '&:has(input:disabled) *': { cursor: 'not-allowed', '& input': { pointerEvents: 'none' } },
  },
  endDateNTimeField: {
    // '& input, .Mui-error': { textAlign: 'right' },
    // '& .rc-time-picker': { width: 140, marginLeft: 'auto' },
  },
  dateNTimeFields: { '& input': { cursor: 'pointer' } },
  // whenClearBtn: { display: 'block', margin: '8px 0 12px auto' },
  whenClearBtn: {
    display: 'block',
    [theme.breakpoints.down('sm')]: { margin: '8px 0 12px auto' },
    [theme.breakpoints.up('sm')]: { height: 44 },
  },
  arrowIcon: { transform: 'rotate(90deg)' },

  // header
  statusUnPublish: { color: COLOR.tertiary.activism },
  publishBtn: { maxWidth: 200, [theme.breakpoints.down('sm')]: { maxWidth: 'unset' } },

  saveBtnFixed: {
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      bottom: theme.spacing(1),
      left: '50%',
      transform: 'translateX(-50%)',
      background: 'white',
      zIndex: 15,
    },
  },


  withSubtitleLabel: { marginTop: theme.spacing(5), '& label': { top: -14 } },

  quill: {
    border: `1px solid ${alpha(theme.palette.common.black, 0.5)}`,
    borderRadius: theme.spacing(0.5),
    '& .quill': { backgroundColor: 'unset' },
    '& .ql-toolbar': { backgroundColor: 'unset', borderBottom: `1px solid ${THEME_PALETTE.grey['A300']} !important` },
    '& .ql-container': { fontFamily: 'inherit' },
    '& .ql-editor.ql-blank::before': { fontStyle: 'normal' },
  },
}));

export default useStyles;
