import Segment from '@redux-beacon/segment';
import { createMiddleware } from 'redux-beacon';
import { ANALYTICS_IDENTIFY_USER, ANALYTICS_LOCATION_CHANGED, ANALYTICS_RESET_USER, ANALYTICS_SET_GROUP, ANALYTICS_USER_EVENT } from './analyticActions';
import { analyticsRemoveUser, analyticsSetGroup, analyticsSetUser, eventTracker, pageViewTracker } from './trackers';

const segment = Segment();

// Match the event definition to a Redux action:
const eventsMap = {
  [ANALYTICS_LOCATION_CHANGED]: pageViewTracker,
  [ANALYTICS_USER_EVENT]: eventTracker,
  [ANALYTICS_IDENTIFY_USER]: analyticsSetUser,
  [ANALYTICS_RESET_USER]: analyticsRemoveUser,
  [ANALYTICS_SET_GROUP]: analyticsSetGroup,
};

export type AnalyticsEvent = keyof typeof eventsMap;

// Create the middleware
export default createMiddleware(eventsMap, segment);
