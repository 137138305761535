import { Grid, GridProps } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { TCommunity } from 'Models/Community/@types';
import React, { FC } from 'react';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import { GRID_VIEW_GUTTER_SPACING_UNIT } from '../../Theme/themeConstants';
import CommunityGridCard, { ICommunityGridCard } from './CommunityGridCard';
import CommunitySkeletonCard from './CommunitySkeletonCard';

export interface CommunityGridViewProps extends Omit<ICommunityGridCard, 'community'> {
  isLoading: boolean;
  communities: TCommunity[];
  spacing?: GridProps['spacing'];
}

const CommunityGridView: FC<CommunityGridViewProps> = (props) => {
  const classes = useStyles();
  const { isLoading = true, communities, spacing, ...communityCardRestProps } = props;
  const { isDeviceSm, isDeviceLg, isDeviceXl, isDeviceMd } = useMediaQuery();
  let numOfSkeleton;
  if (isDeviceSm) numOfSkeleton = [1, 2, 3, 4];
  if (isDeviceMd) numOfSkeleton = [1, 2, 3, 4];
  if (isDeviceLg) numOfSkeleton = [1, 2, 3, 4];
  if (isDeviceXl) numOfSkeleton = [1, 2, 3, 4, 5, 6];
  return (
    <Grid container spacing={isDeviceSm ? 0 : spacing ?? GRID_VIEW_GUTTER_SPACING_UNIT}>
      {communities.map((val, i) => {
        return (
          <Grid className={classes.gridItem} item key={val.id} xl={2} lg={3} md={3} sm={4} xs={6}>
            <CommunityGridCard community={val} {...communityCardRestProps} />
          </Grid>
        );
      })}
      {isLoading
        ? numOfSkeleton?.map((i) => {
            return (
              <Grid className={classes.gridItem} item key={i} xl={2} lg={3} md={3} sm={4} xs={6}>
                <CommunitySkeletonCard />
              </Grid>
            );
          })
        : null}
    </Grid>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  gridItem: {
    margin: theme.spacing(2, 0),
  },
}));

export default CommunityGridView;
