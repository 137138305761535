import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import { VariableMapping, TNotifAction, TNotification } from 'Models/App/@types';
import PgButton from 'Components/PgButton';
import useAsyncTask from 'Hooks/useAsyncTask';
import utils from 'Utils';
import useToastMessage from 'Hooks/useToastMessage';
import MESSAGES, { getTemplateString } from 'Utils/Messages';
import useConfirmationDialog from 'Hooks/useConfirmationDialog';
import { NOTIFICATION_TRIGGERED_IDS } from 'Constants/variables';
import { EAddOnConnType } from 'Models/Event';
import axios from 'axios';
import helpers from 'Utils/helpers';
import AuthModel from 'Models/Auth';

export const roleMap = {
  [EAddOnConnType.featured]: 'featuring',
  [EAddOnConnType.speakers]: 'speaker',
  [EAddOnConnType.sponsors]: 'sponsor',
};

interface ActionButtonsProps {
  actions: TNotifAction[];
  notiftriggeredId: TNotification['notificationTriggerId'];
  variableMapping?: VariableMapping;
  onActionClick?: (isAccepted?: boolean) => void;
}

const ActionButtons: FC<ActionButtonsProps> = ({ actions, notiftriggeredId, variableMapping, onActionClick }) => {
  const classes = styles();
  const withToast = useToastMessage();
  const withConfirmationDialog = useConfirmationDialog();
  const action = async ({ action, index }: { action: TNotifAction; index: number }) => {
    const actionUrl = new URL(action.actionCall);
    actionUrl.searchParams.append('access_token', utils.getAccessToken());
    const actionLink = actionUrl.toString();

    if (notiftriggeredId === NOTIFICATION_TRIGGERED_IDS.ECOSYSTEM_INVITE)
      await withToast(
        async () => {
          await axios(actionLink);
          onActionClick?.(action.textTemplate.toLowerCase() === 'accept');
        },
        {
          successToastMessage: MESSAGES[action.textTemplate.toLowerCase() === 'accept' ? 'ECOSYSTEM_INVITE_ACCEPT' : 'ECOSYSTEM_INVITE_DECLINE'],
          errorToastMessage: err => helpers.getErrorData<{ statusCode: number }>(err)?.statusCode === 404 ? MESSAGES.ECOSYSTEM_INVITE_EXPIRED : AuthModel.getAuthErrorMessages(err),
          showApiErrorMsg: false,
        },
      );
    else if (notiftriggeredId === NOTIFICATION_TRIGGERED_IDS.DROP_CONNECTION_INVITE)
      withConfirmationDialog(
        async () => {
          await axios(actionLink);
          onActionClick?.(action.textTemplate.toLowerCase() === 'accept');
        },
        action.textTemplate.toLowerCase() === 'accept' ? confirmationConfig.accept(variableMapping?.addOnType) : confirmationConfig.decline,
        {
          successToastMessage:
            action.textTemplate.toLowerCase() === 'accept'
              ? getTemplateString('SPEAKER_NOTIFICATION_ACCEPT', {
                  dropName: variableMapping?.resourceName ?? '',
                  role: roleMap[(variableMapping?.addOnType ?? '') as EAddOnConnType] ?? variableMapping?.addOnType,
                })
              : MESSAGES.SPEAKER_NOTIFICATION_DECLINE,
        },
      );
    else {
      await axios(actionLink);
      onActionClick?.(action.textTemplate.toLowerCase() === 'accept');
    }
  };
  const actionTask = useAsyncTask(action);
  return (
    <>
      {actions.map((action, index) => {
        const { textTemplate, actionCall, actionType } = action;
        return (
          <PgButton
            key={textTemplate}
            onClick={
              actionType === 'internal'
                ? () => {
                    actionTask.run({ action, index });
                  }
                : () => onActionClick?.()
            }
            primary={index === 0}
            href={actionType === 'redirect' ? actionCall : undefined}
            isExternalLink={actionType === 'redirect'}
            className={classes.button}
            openInNewTab={false}
          >
            {textTemplate}
          </PgButton>
        );
      })}
    </>
  );
};

export default ActionButtons;

const styles = makeStyles((theme) => ({
  button: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    fontSize: 12,
  },
}));

const roleParsedText = (addonType?: EAddOnConnType) => {
  if (addonType === EAddOnConnType.featured) return 'featured';
  const preText = 'displayed as a ';
  if (`${addonType}s` === EAddOnConnType.speakers || addonType === EAddOnConnType.speakers) return preText + 'speaker';
  return preText + 'sponsor';
};

const confirmationConfig = {
  accept: (addonType?: EAddOnConnType) => ({
    message: 'Accept invitation',
    body: `You will be ${roleParsedText(addonType)} on this drop, but will not have administrative access or control over it.`,
    agreeText: 'accept',
    cancelText: 'cancel',
  }),

  decline: {
    message: 'Ignore invitation',
    body: 'Are you sure you want to decline the invitation to collaborate on this drop?',
    agreeText: 'decline',
    cancelText: 'cancel',
  },
};
