/* eslint-disable react/require-default-props */
import React, { FC, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import Spacer from './Spacer';
import PgTypo, { PgTypoProps } from './PgTypo';

export interface IconTextProps {
  icon: JSX.Element;
  text: string;
  textClasses?: string | string[];
  iconPosition?: 'left' | 'right';
  size?: 'small' | 'medium';
  textVariant?: 'light' | 'secondary' | 'dark' | 'error'| 'default';
  fontWeight?: PgTypoProps['typoWeight'];
  containerClasses?: string;
}

const IconText: FC<IconTextProps> = ({
  icon,
  text,
  textClasses,
  size = 'medium',
  iconPosition = 'left',
  textVariant = 'dark',
  fontWeight,
  containerClasses,
}) => {
  const classes = useStyles({});

  // eslint-disable-next-line consistent-return
  const textColor = useMemo<PgTypoProps['typoColor']>(() => {
    if (textVariant === 'dark') return 'primary';
    if (textVariant === 'light') return 'contrast';
    if (textVariant === 'error') return 'error';
    if (textVariant === 'secondary') return 'secondary';
    if (textVariant === 'default') return 'default';
  }, [textVariant]);

  const typoProps: PgTypoProps = {
    className: clsx(classes.text, textClasses),
    typoWeight : fontWeight,
    typoColor : textColor,
    b6 : size === 'small',
    b4 : size !== 'small'
  };

  return (
    <Box display="flex" alignItems="center" flexDirection={iconPosition === 'left' ? 'row' : 'row-reverse'} className={containerClasses}>
      {icon}
      <Spacer width={size === 'small' ? 4 : 12} />
      <PgTypo {...typoProps}>
        {text}
      </PgTypo>
    </Box>
  );
};

const useStyles = makeStyles<Theme, any>(() => ({
  text: {
    position: 'relative',
    top: 1,
    lineHeight: '100%',
  },
}));

export default IconText;
