import React, { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './icon.css';
import './new-icon.css';
import { StoreProvider } from 'easy-peasy';
import App from './App';
import store, { useStoreState } from './Stores';
import * as serviceWorker from './serviceWorker';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { PgWalletProvider } from './Web3';
import { ThirdwebSDKProvider } from '@thirdweb-dev/react';
import { ethers } from 'ethers';
import config from 'config';
import { useWeb3React, Web3ReactProvider } from '@web3-react/core';
import { hooks as walletConnectHooks, walletConnect } from 'Web3/Connector/WalletConnect';
import { hooks as metamaskHooks, metaMask } from 'Web3/Connector/Metamask';
import { hooks as coinbaseHooks, coinbaseWallet } from 'Web3/Connector/Coinbase';
import { hooks as networkHooks, network } from 'Web3/Connector/Network';
import { hooks as phantomHooks, phantom } from 'Web3/Connector/PhantomWallet';
import { ExtendedChainInformation } from 'Web3/Chains';
import { connectWalletEagerly } from 'Constants/initialGenerator';
import { WalletProvider as SuiWalletProvider } from '@suiet/wallet-kit';

const thirdWebApiKey = config.get('THIRD_WEB_API_KEY');

const Index: FC = () => {
  const { appUser, chains } = useStoreState(({ AuthStore: { appUser }, App: { chains } }) => ({ appUser, chains }));

  const { provider, isActive, chainId } = useWeb3React();

  useEffect(() => {
    if (!appUser) return;
    connectWalletEagerly(appUser);
  }, [appUser]);

  const chain = chains?.[chainId ?? 0] as ExtendedChainInformation

  return (
    <PgWalletProvider>
      <ThirdwebSDKProvider
        clientId={thirdWebApiKey}
        signer={
          isActive && provider
            ? new ethers.providers.Web3Provider(async (method: string, params?: Array<any>) => {
                return provider.send(method, params || []);
              }).getSigner()
            : undefined
        }
        // activeChain={chainId}
        activeChain={chain ? {
          // === Required information for connecting to the network === \\
          chainId: chain?.chainId, // Chain ID of the network
          // Array of RPC URLs to use
          rpc: chain?.urls,
          // === Information for adding the network to your wallet (how it will appear for first time users) === \\
          // Information about the chain's native currency (i.e. the currency that is used to pay for gas)
          nativeCurrency: chain.nativeCurrency,
          shortName: chain.name, // Display value shown in the wallet UI
          slug: chain.name, // Display value shown in the wallet UI
          testnet: !!chain.isTestNet, // Boolean indicating whether the chain is a testnet or mainnet
          chain: chain.chain ?? '', // Name of the network
          name: chain.name, // Name of the network
        } : chainId}
      >
        <App />
      </ThirdwebSDKProvider>
    </PgWalletProvider>
  );
};

ReactDOM.render(
  <StoreProvider store={store}>
    <Web3ReactProvider
      connectors={[
        [metaMask, metamaskHooks],
        [coinbaseWallet, coinbaseHooks],
        [walletConnect, walletConnectHooks],
        [phantom, phantomHooks],
        [network, networkHooks],
      ]}
    >
      <SuiWalletProvider autoConnect={false}>
        <Index />
      </SuiWalletProvider>
    </Web3ReactProvider>
  </StoreProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
