import { request } from 'Resources/AxiosUtils';
import { INFT, ISuiSignMessageOutput, TNonce, TRequestWalletConnect, TUserInfo, WalletType } from './@types';
import { JSONType } from 'Typings/Global';

class Web3Model {
  static requestWalletConnect(publicAddress: string, email: string) {
    return request<TRequestWalletConnect>({
      url: `/users/web3/request-address-linking`,
      method: 'GET',
      params: { publicAddress, email },
    });
  }

  static linkWallet(
    publicAddress: string,
    email: string,
    signature?: string,
    provider: 'metamask' | 'solana' | 'sui' = 'metamask',
    walletType: WalletType = WalletType.METAMASK,
    signatureObject?: ISuiSignMessageOutput
  ) {
    return request<boolean>({
      url: `/users/web3/do-address-linking`,
      method: 'POST',
      data: { publicAddress, signature, email, provider, walletType, signatureObject },
    });
  }

  static getNonce(publicAddress: string) {
    return request<TNonce>({
      url: `/users/web3/${publicAddress}`,
      method: 'GET',
    });
  }

  static verifySignature(
    publicAddress: string,
    signature?: string,
    userInfo?: TUserInfo,
    provider: 'metamask' | 'solana' | 'sui' = 'metamask',
    walletType: WalletType = WalletType.METAMASK,
    signatureObject?: ISuiSignMessageOutput
  ) {
    return request<{ userId: string; id: string }>({
      url: `/users/web3/login`,
      method: 'POST',
      data: { publicAddress, signature, userInfo, provider, walletType, signatureObject },
    });
  }

  static getWalletNFT(publicAddress: string, chain: string | number) {
    return request<{ result: INFT[] }>({
      url: '/users/web3/get-wallet-nfts',
      params: { publicAddress, chain },
    });
  }

  static async usdToCryptoCurrency(price: number, cryptoCurrency: string) {
    const res = await request<{ price: number }>({
      url: `https://api.binance.com/api/v3/ticker/price?symbol=${cryptoCurrency.toUpperCase()}USDT`,
      withCredentials: false,
    });
    return (price / res.price);
  }

  static async getSuiNfts(publicAddress: string) {
    const res = await request<INFT[]>({
      url: `/users/web3/get-sui-nfts`,
      method: 'GET',
      params: { publicAddress },
    });
    return res;
  }
}

export default Web3Model;
