/* eslint-disable no-unused-expressions */
import React, { FC, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box, BoxProps } from '@material-ui/core';
import PgTypo from 'Components/PgTypo';

export interface TimerProps extends BoxProps {
  timeInSeconds: number;
  showTimer: boolean;
  startTimer: boolean;
  onTimerComplete?: () => void;
}

const Timer: FC<TimerProps> = (props) => {
  const { timeInSeconds, showTimer, startTimer, onTimerComplete, ...boxProps } = props;
  const classes = useStyles({});

  const [timer, setTimer] = useState(timeInSeconds);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>();
  const [canClear, setCanClear] = useState(true);

  useEffect(() => {
    if (startTimer) {
      setTimer(timeInSeconds);
      setIntervalId(undefined);
      HandleStart();
    }
  }, [startTimer]);

  const HandleStart = () => {
    const timeId = setInterval(() => {
      setTimer((t) => t - 1);
    }, 1000);
    setIntervalId(timeId);
    setCanClear(true);
  };

  if (timer < 1 && intervalId && canClear) {
    clearInterval(intervalId);
    onTimerComplete?.();
    setCanClear(!canClear);
  }

  return !showTimer || timer < 1 ? null : (
    <Box {...boxProps}>
      <PgTypo variant="caption" color="error" align="center">
        Try again in {timer} seconds.
      </PgTypo>
    </Box>
  );
};

export default Timer;

const useStyles = makeStyles<Theme, any>((theme) => createStyles({}));
