import { Role, User } from 'Models/User/@types';

const hasRole = (roleName: Role, user?: User): boolean => {
  if (!user) return false;
  return !!user.roles?.find((role) => (typeof role === 'string' ? role === roleName : role.name === roleName));
};
const RoleUtils = {
  isCurator: (user?: User): boolean => hasRole('CURATOR', user),
  isCommunity: (user?: User): boolean => hasRole('COMMUNITY', user),
  isRegularUser: (user?: User): boolean =>
    !RoleUtils.isCurator(user) && !RoleUtils.isCommunity(user) && !RoleUtils.isAdmin(user) && !RoleUtils.isSuperAdmin(user),
  isAdmin: (user?: User): boolean => hasRole('ADMIN', user),
  isSuperAdmin: (user?: User): boolean => hasRole('SUPERADMIN', user),
};

export default RoleUtils;
