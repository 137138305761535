import helpers from './helpers';

const MESSAGES = {
  EMAIL_REQUIRED: 'Email is required.',

  EMAIL_SUBSCRIBED: 'Thanks for subscribing to the Playground newsletter!',

  FOLLOWED_SUCCESSFULLY: 'Followed successfully',

  UNFOLLOWED_SUCCESSFULLY: 'Unfollowed successfully',

  COMMUNITY_JOINED: 'You have joined {community} successfully.',

  COMMUNITY_LEFT: 'You have left {community}',

  COMMUNITY_JOIN_FAILED: 'Failed to join {community}',

  INVITE_SUCCESS: 'Invite(s) sent successfully',

  INVITE_ERROR: 'Some invites could not be sent',

  RSVP_JOINED: 'A reminder has been set, and this drop has been added to your playlist.',

  RSVP_REMOVED: 'Reminder removed successfully',

  TICKETS_SUCCESS: 'Registration successful!',

  UNPUBLISHED_REMINDER_ERROR: 'Cannot set reminder for an unpublished entity.',

  FAVOURITE_ADDED: 'Content has been added to your favorites.',

  FAVOURITE_REMOVED: 'Content has been removed from your favorites.',

  COPY_TO_CLIPBOARD: 'Link copied to clipboard.',

  COMMUNITY_PROFILE_UPDATED: 'Community profile updated successfully!',
  COMMUNITY_CREATED: 'Community created successfully!',

  COMMUNITY_LOGIN_ERROR: 'Please login as a Community user',

  MAX_FOUR: 'Maximum 4 can be selected',

  LIST_PRESENT: 'Already in list',

  FIELDS_REQUIRED: 'Some fields are required',

  EVENT_NEWSLETTER_UPDATED: 'Event news letter config updated successfully',

  SOMETHING_WRONG: 'Something went wrong',

  API_INTEGRATION_SUCCESS: 'API integrated successfully',

  API_INTEGRATION_ERROR: 'Provider not connected or API Key invalid',

  AUTH_ERROR: 'Authorization failed',

  SAVE_FORM_ERROR: 'Please save the form first',

  EVENT_UPDATE_SUCCESS: 'Event updated successfully',

  EVENT_DELETE_SUCCESS: 'Event deleted successfully',

  EVENT_CREATE_SUCCESS: 'Event created successfully',

  RESOURCE_CREATE_SUCCESS: 'Resource created successfully',

  RESOURCE_DELETE_SUCCESS: 'Resource deleted successfully',

  CONTENT_TABS_UPDATE_SUCCESS: 'Content Tabs updated successfully',

  CONTENT_BLOCK_CREATE_SUCCESS: 'Content block created successfully',

  CONTENT_BLOCK_UPDATE_SUCCESS: 'Content block updated successfully',

  CONTENT_BLOCK_REMOVED: 'Content block removed',

  PROFILE_UPDATE_SUCCESS: 'Profile updated successfully',

  COMMUNITY_REACTIVATION_SUCCESS: 'Community re-activation request sent successfully.',

  COMMUNITY_DEACTIVATION_SUCCESS: 'Community deactivation request sent successfully.',

  COMMUNITY_DELETION_SUCCESS: 'Community deletion request sent successfully.',

  REFUND_APPROVED: 'Approved refund request',

  REFUND_APPROVED_ERROR: 'Failed to approve refund request',

  REFUND_DENIED: 'Denied refund request',

  REFUND_DENIED_ERROR: 'Failed to deny refund request',

  SETTINGS_SAVED_SUCCESS: 'Settings saved successfully',

  PASSWORD_CHANGE_SUCCESS: 'Password changed successfully',

  CONTACT_SUCCESS: 'Your message has been sent successfully',

  GENERAL_ERROR: 'Something went wrong',

  TICKET_TIER_SOLD_OUT: 'This tier is sold out',

  INVITE_SENT_SUCCESS_TITLE: 'Ding! Your invite was delivered.',
  INVITE_SENT_SUCCESS_SUBTITLE: 'Earn points when your friends sign up. More invites? More points.',

  INVITE_SENT_ERROR_TITLE: 'An email address you entered needs attention.',
  INVITE_SENT_ERROR_SUBTITLE: 'Please check to make sure the email addresses you included are correct.',

  EVENT_DATE_TIME_IN_PAST_ERR: 'Event date/time cannot be in the past',

  UNPUBLISH_DROP_COLLAB_ERR: 'Publish your drop before inviting other collaborators',

  COLLBORATOR_INVITED: 'Invitation to collaborate has been sent to {name}',

  PROFILE_LOADING_ERROR: 'Unable to load profiles, try again later',

  GENERAL_DELETE_SUCCESS: '{name} deleted successfully.',

  PUBLIC_ADDR_COPIED: 'Wallet address copied',

  // Notifications
  NOTIFICATION_PREFERENCE_SUCCESS_TITLE: 'Account Notification Settings updated successfully',
  /**
   * Auth Module
   */

  LOGIN_SUCCESS: 'You have signed in successfully.',
  // LOGIN_ERROR_TITLE: 'Login credentials invalid',
  // LOGIN_ERROR_MESSAGE: 'Please re-enter your login credentials and try again.',
  LOGIN_ACCOUNT_DOES_NOT_EXIST_TITLE: "That email didn't work",
  LOGIN_ACCOUNT_DOES_NOT_EXIST_MESSAGE: 'We couldn’t find an account with that email. Want to sign up instead?',
  LOGIN_ERROR_TITLE: 'Hmm, those credentials didn’t work.',
  LOGIN_ERROR_MESSAGE: 'Double-check your spelling and try again. Not working? Reset your password',

  SIGNUP_SUCCESS_TITLE: 'Almost there!',
  SIGNUP_SUCCESS_MESSAGE: 'We have sent a verification link to {email}',

  FORGOT_PASSWORD_SUCCESS_TITLE: 'Reset link sent',
  FORGOT_PASSWORD_SUCCESS_MESSAGE: 'A link to reset your password has been sent to {email}.',

  CHANGE_PASSWORD_ERROR_INVALID_TOKEN: 'Invalid Token',
  CHANGE_PASSWORD_SUCCESS_MESSAGE: 'Your password has been updated.',

  LOGOUT_SUCCESS: 'Logged out successfully.',
  EMAIL_VERIFIED_SUCCESS: 'Email verified successfully',
  VERIFICATION_LINK_SENT: 'Verification link sent to {email}',

  PHONE_NUMBER_REQUIRED: 'Phone number required',
  PHONE_NUMBER_INVALID: 'Phone number not valid',
  PHONE_COUNTRY_CODE_REQUIRED: 'Country code required',
  PHONE_VERIFICATION_CODE_SENT: `Verification code sent to {phone}`,
  PHONE_VERIFICATION_SUCCESS: 'Your phone number has been verified successfully.',

  PHONE_REMOVED_TITLE: 'Your phone number has been removed.',
  PHONE_REMOVED_MESSAGE: 'You will no longer receive SMS reminders.',

  EMAIL_EXIST_LOGIN: 'It seems you already have an account. Please log in with same email first',

  /**
   * User follow
   */

  USER_FOLLOW_SUCCESS: '{name} was added to your followings.',
  USER_FOLLOW_ERROR: 'Something went wrong when trying to follow, try again later',
  USER_UNFOLLOW_SUCCESS: '{name} was unfollowed',
  USER_FOLLOWINGS_LOADING_ERROR: 'Unable to load following, try again later',
  USER_FOLLOWERS_LOADING_ERROR: 'Unable to load followers, try again later',

  /**
   * Ecosystem
   */
  ECOSYSTEM_INVITE_SEND: 'Invitation sent to {name}.',
  ECOSYSTEM_REMOVE_COMMUNITY: '{name} removed successfully',
  ECOSYSTEM_INVITE_CANCELLED: 'Invitation to {name} has been canceled',
  ECOSYSTEM_INVITE_ACCEPT: 'Invitation has been accepted.',
  ECOSYSTEM_INVITE_DECLINE: 'Invitation has been declined.',
  ECOSYSTEM_ALREADY_MEMBER: 'This community is already in your partners',
  ECOSYSTEM_ALREADY_INVITED: 'This community has already been invited.',
  ECOSYSTEM_INVITE_EXPIRED: 'We’re sorry, this invite is no longer available.',

  /**
   * speacker sponsors
   */
  SPEAKER_INVITE_SEND: 'Invitation has been sent to {name}',
  SPEAKER_INVITE_RESEND: 'Invitation resend successfully',
  SPEAKER_INVITE_REORDERED: 'Reordered successfully',

  /**
   * Password protected conversation
   */
  CONVERSATION_UNLOCK_SUCCESS: '{name} unlocked successfully!',
  CONVERSATION_PASS_UNLOCK_ERR: 'That’s not the right password. Double-check your spelling and try again',
  CONVERSATION_TOKEN_UNLOCK_ERR: 'NFT not found or not belongs to required contract',

  /**
   * speaker sponsors notification
   */
  SPEAKER_NOTIFICATION_ACCEPT: 'Successfully added as a {role} on {dropName} drop',
  SPEAKER_NOTIFICATION_DECLINE: 'Successfully ignored',
  SPEAKER_UPDATED: 'Updated successfully',

  /**
   * community count display
   */
  COMMUNITY_MEMBER_COUNT_DISPLAY: 'Members are now visible to all users.',
  COMMUNITY_MEMBER_COUNT_HIDDEN: 'Members are now hidden to all users.',

  /**
   * Invite csv upload
   */
  FILE_SIZE_EXCEED: 'Oops! Choose an file less than 10MB!',
  ONLY_CSV_ALLOWED: 'Only csv files are allowed',
  INVITE_CSV_UPLOAD_SUCCESS: 'Ding! Your invite was delivered.',
  INVITE_CSV_COL_NOT_FOUND: 'Could not find the ‘email’ column.',

  /**
   * Bulleit flow: code - toast mapping
   */
  NFT_PROCESS_ADDED_TO_QUEUE: 'Wallet is being generated and NFT minted.',
  NFT_REDEEM_REJECTED: 'Wallet couldn’t be generated. Please try again.',
  NFT_MINTED: 'Success! We’ve created a wallet for you and now you’re the owner of BULLEIT #23',

  /**
   * QR Manager Flows
   */
  EVENT_QR_CREATE: 'QR Code created successfully.',
  EVENT_QR_UPDATE: 'QR Code saved successfully.',
  EVENT_QR_ENABLED: 'QR Code enabled successfully.',
  EVENT_QR_DISABLED: 'QR Code disabled successfully.',
  EVENT_QR_DELETED: 'QR successfully deleted',


  DROP_PASSWORD_INVALID: 'That’s not the right code. Double-check your spelling and try again',

  SUI_WALLET_NOT_CONNECTED: 'Oops! This event requires a Sui wallet for ticket purchases. Please set up your Sui wallet to buy tickets.',
};

export const getTemplateString = (template: keyof typeof MESSAGES, data: Record<string, string>): string => {
  return helpers.templateReplace(MESSAGES[template], data);
};
export default MESSAGES;
