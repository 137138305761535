import React, { useEffect, useState } from 'react';
import { useStoreState } from 'Stores';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { APP_ROUTES } from 'Routes';
import PgDialog from 'Components/PgDialog';
import PgTypo from 'Components/PgTypo';
import PgButton from 'Components/PgButton';
import MESSAGES from 'Utils/Messages';
import useAuth from '../useAuth';
import Timer from '../AuthDialogContent/Timer';
import { TIMEOUT } from 'Constants/variables';

export interface TEmailSentDialogProps {}

const EmailSentDialog: React.FC<TEmailSentDialogProps> = () => {
  const classes = useStyles({});
  const { requestEmailVerification } = useAuth();

  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({
    appUser,
  }));

  const [showTimer, setshowTimer] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    setStartTimer(true);
  }, []);

  const sendEmailHandler = async () => {
    if (!appUser) return;
    setshowTimer(true);
    setStartTimer(false);
    if (!canResend) return;
    requestEmailVerification(appUser.email).then(() => {
      setStartTimer(true);
      setCanResend(false);
      // setshowTimer(false);
    });
  };

  return (
    <PgDialog
      heading={MESSAGES.SIGNUP_SUCCESS_TITLE}
      body={
        <PgTypo b4 align="center">
          We have sent you a link to verify your account.&nbsp; Please check your email.
        </PgTypo>
      }
      actionButtons={
        <>
          <Box display="flex" justifyContent="center">
            <PgTypo b4 align="center">
              Didn't get the email?
              <PgButton quaternary underlined onClick={sendEmailHandler} className={classes.actionBtn}>
                Resend Email
              </PgButton>
              or
              <PgButton quaternary underlined href={APP_ROUTES.CONTACT.path} isExternalLink className={classes.actionBtn}>
                Contact Us
              </PgButton>
            </PgTypo>
          </Box>
          <Timer timeInSeconds={TIMEOUT.SEND_TOKEN} showTimer={showTimer} startTimer={startTimer} onTimerComplete={() => setCanResend(true)} pt={2} />
        </>
      }
    />
  );
};

const useStyles = makeStyles<Theme, any>((theme: Theme) =>
  createStyles({
    actionBtn: {
      paddingLeft: 1,
      paddingRight: 1,
    },
  })
);

export default EmailSentDialog;
