/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-expressions */
import React, { FC, useEffect, useMemo, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Paper, IconButton, Box } from '@material-ui/core';
import GoogleLocationSuggest, { GoogleLocationSuggestProps } from 'Components/Inputs/GoogleLocationSuggest';
import { Address } from 'Models/Address/@types';
import { getFieldError, IFieldProps } from 'react-forms';
import GoogleUtils from 'Resources/google';
import MapsView from 'Components/MapsView';
import { TPosition } from 'Models/App/@types';
import PgIcon from 'Components/PgIcon';
import clsx from 'clsx';
import PgTypo from 'Components/PgTypo';
import { isEmpty } from 'lodash';

export interface EventLocationSuggestProps extends IFieldProps {
  fieldProps?: {
    suggestionTypes?: GoogleLocationSuggestProps['suggestionsTypes'];
    disableSearchIcon?: boolean;
    label?: string;
    hasMapDisabled?: boolean;
    className?: string;
    mapHeight?: number;
    suggestionProps?: Omit<GoogleLocationSuggestProps, 'suggestionsTypes'>;
  };
}

const getReadableAddress = (address: any) => {
  if (!address) return { main_text: '', secondary_text: '' };
  if (address.structured_formatting) return address.structured_formatting;
  if (address.fullAddress) {
    const [mainText, ...secondaryTexts] = address.fullAddress.split(',');
    return {
      main_text: mainText,
      secondary_text: secondaryTexts.join(',').trim(),
    };
  }
  return { main_text: '', secondary_text: '' };
};
const EventLocationSuggest: FC<EventLocationSuggestProps> = (props) => {
  const classes = useStyles();
  const { formikProps, fieldConfig, fieldProps = {} } = props;
  const { mapHeight = 200 } = fieldProps;
  const locationValue: TPosition = useMemo(() => formikProps?.values.location, [formikProps?.values.location]); // TODO get location key from parent component
  const readableAddress = getReadableAddress(formikProps?.values.address);
  const [isEditMode, setIsEditMode] = useState(!locationValue);
  const suggestionTypes = fieldProps?.suggestionTypes || [];
  const label = fieldProps?.label || '';
  const hasMapDisabled = fieldProps?.hasMapDisabled || false;
  const disableSearchIcon = fieldProps?.disableSearchIcon || false;
  const className = fieldProps?.className || '';
  const error = getFieldError(fieldConfig?.valueKey || '', formikProps as any);
  const handleResultClick = async (result: any) => {
    const res = await GoogleUtils.placeDetails(result.place_id);
    const placeDetails = GoogleUtils.transformAddress(res);
    formikProps?.setFieldValue('address', {
      address1: placeDetails?.address1,
      city: placeDetails?.city,
      country: placeDetails?.country,
      fullAddress: placeDetails?.full_address,
      locality: placeDetails?.locality,
      placeId: placeDetails?.placeid,
      state: placeDetails?.state,
      zipCode: placeDetails?.zipcode,
      structured_formatting: result?.structured_formatting,
    } as Address);
    formikProps?.setFieldValue('placeId', placeDetails?.placeid);
    formikProps?.setFieldValue('location', {
      lat: res.geometry?.location?.lat?.(),
      lng: res.geometry?.location?.lng?.(),
    });
    setIsEditMode(false);
  };

  const handleRemoveMap = () => {
    // formikProps?.setFieldValue('placeId', null);
    // formikProps?.setFieldValue('address', null);
    // formikProps?.setFieldValue('location', null);
    formikProps?.setValues({ ...formikProps.values, placeId: null, location: null, address: null });
    // setIsEditMode(true);
  };

  useEffect(() => {
    if (!formikProps?.values.location || isEmpty(formikProps?.values.location)) {
      setIsEditMode(true);
    } else setIsEditMode(false);
  }, [formikProps?.values.location]);

  if (hasMapDisabled)
    return (
      <>
        <GoogleLocationSuggest
          label={label}
          disableSearchIcon={disableSearchIcon}
          onResultClick={handleResultClick}
          suggestionsTypes={suggestionTypes ?? []}
          className={clsx(classes.root, className)}
          {...fieldProps?.suggestionProps}
        />
      </>
    );

  return (
    <>
      {/* {
                label ?
                    <Typography variant="caption">{label}</Typography>
                    :
                    null
            } */}
      {isEditMode && (
        <>
          {!!label && <PgTypo b4 style={{ marginBottom: 8 }}>{label}</PgTypo>}
          <GoogleLocationSuggest
            disableSearchIcon={disableSearchIcon}
            onResultClick={handleResultClick}
            suggestionsTypes={suggestionTypes ?? []}
            className={clsx(classes.root, className)}
            error={typeof error === 'string' ? error : undefined}
            {...fieldProps?.suggestionProps}
          />
        </>
      )}
      {!isEditMode && (
        <Paper>
          <MapsView
            points={{ lat: locationValue?.lat, lng: locationValue?.lng }}
            containerElement={
              <div
                style={{
                  height: mapHeight,
                  width: '100%',
                  overflow: 'hidden',
                }}
              />
            }
            mapElement={<div style={{ height: '100%' }} />}
          />
          <Box className={classes.mapDetail} display="flex">
            <div>
              <PgTypo b4Bold>{readableAddress.main_text}</PgTypo>
              <PgTypo b4>{readableAddress.secondary_text}</PgTypo>
            </div>
            <div>
              <IconButton onClick={() => setIsEditMode(true)}>
                <PgIcon styleClass={classes.mapDetailIcon} icon="icon-edit" color="secondary" />
              </IconButton>

              <IconButton onClick={() => handleRemoveMap()}>
                <PgIcon styleClass={classes.mapRemoveIcon} icon="icon-close" color="secondary" />
              </IconButton>
            </div>
          </Box>
        </Paper>
      )}
    </>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  const {
    palette: { grey },
  } = theme;
  return createStyles({
    root: {
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
        padding: 0, // Safari issues.
      },
    },
    mapDetail: {
      padding: '10px 20px',
      justifyContent: 'space-between',
    },
    mapDetailIcon: {
      fontWeight: 'bold',
    },
    mapRemoveIcon: {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '50%',
      padding: 3,
      fontWeight: 'bold',
      fontSize: theme.typography.subtitle1.fontSize,
    },
  });
});

export default EventLocationSuggest;
