export const availableTickets = (valueKey: string) => {
  return {
    type: 'text',
    valueKey,
    fieldProps: {
      label: 'Tickets Available*',
      classes: {
        // root: classes?.selectBold,
      },
    },
    styles: {
      marginTop: 21,
    },
  };
};

export const ticketsQuantity = (valueKey: string) => ({
  type: 'text',
  valueKey,
  fieldProps: {
    label: 'Max Quantity*',
    styles: {
      color: 'red',
    },
    classes: {
      // root: classes?.selectBold,
    },
  },
  styles: {
    fontWeight: 'bold',
    marginTop: 21,
  },
});
