import Axios, { CancelTokenSource } from 'axios';
import { action, Action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';
import ResourceQuery from 'Features/Query/ResourceQuery';
import { TFilter } from 'Models/AppModels';
import ResourceModel from 'Models/Resource';
import { TResource } from 'Models/Resource/@types';
import { parseResource } from 'Models/Resource/resourceParsers';
import VideoModel from 'Models/Video';
// eslint-disable-next-line import/no-cycle
import { TRootStore } from 'Stores';

export interface WatchState {
  videoDetail?: TResource;
  setVideoDetail: Action<WatchState, TResource | undefined>;
  fetchVideoDetail: Thunk<WatchState, { id: string, filters?: TFilter }, null, TRootStore>;
  loadingDetails: boolean;
  setLoadingDetails: Action<WatchState, boolean>;

  watchList: TResource[];
  fetchWatchList: Thunk<WatchState, ResourceQuery, null, TRootStore>;
  setWatchList: Action<WatchState, { watchList: TResource[] }>;

  loading: boolean;
  setLoading: Action<WatchState, boolean>;

  total: number;
  setTotal: Action<WatchState, number>;

  query: ResourceQuery;
  setQuery: Action<WatchState, ResourceQuery>;
  onQueryChange: ThunkOn<WatchState, Record<string, unknown>, TRootStore>;
}

let cancelToken: CancelTokenSource | undefined;

const WatchStore: WatchState = {
  loadingDetails: false,
  watchList: [],
  total: 0,
  loading: false,
  query: new ResourceQuery({}, { page: 1 }),
  setVideoDetail: action((state, payload) => {
    state.videoDetail = payload;
  }),
  setLoadingDetails: action((state, payload) => {
    state.loadingDetails = payload;
  }),
  setWatchList: action((state, { watchList }) => {
    state.watchList = watchList;
  }),
  setTotal: action((state, payload) => {
    state.total = payload;
  }),
  setQuery: action((state, payload) => {
    state.query = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  fetchWatchList: thunk(async (actions, query, { getStoreState }) => {
    actions.setLoading(true);
    const { community } = getStoreState().CommunityStore;
    if (!community) return;

    query.communityId = community?.id ?? '';
    query.docType = 'Video';

    if (cancelToken) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
    cancelToken = Axios.CancelToken.source();
    const res = await ResourceModel.search(
      community?.id ?? '',
      '',
      {
        Video: {
          include: 'tags',
        },
        ...query.searchFilter,
      },
      { cancelToken: cancelToken.token }
    );

    if (res) {
      const watchList: TResource[] = [...res.results.map((res) => parseResource(res.hit))];

      actions.setTotal(res.total);
      actions.setWatchList({ watchList });
    }
    actions.setLoading(false);
  }),
  fetchVideoDetail: thunk(async (actions, { id, filters = {} }) => {
    try {
      actions.setLoadingDetails(true);
      const res = await VideoModel.getVideo(id, filters);
      actions.setVideoDetail(res);
    } catch (error) {
      console.log(error);
    } finally {
      actions.setLoadingDetails(false);
    }
  }),
  onQueryChange: thunkOn(
    (actions) => actions.setQuery,
    async (actions, { payload }) => {
      actions.fetchWatchList(payload);
    }
  ),
};

export default WatchStore;
