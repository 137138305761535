/* eslint-disable import/prefer-default-export */
import { TextFieldProps } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormConfig, IFormActionProps } from 'react-forms';

export const useFormConfig = () => {
  const classes = useStyles();

  const actionConfig: IFormActionProps = {
    submitButtonText: 'continue'.toUpperCase(),
    displayActions: true,
    submitButtonLayout: 'fullWidth',
    submitButtonProps: {
      size: 'large',
      color: 'secondary',
    },
    containerClassNames: classes.buttonContainer,
  };
  const schema: Array<Array<FormConfig> | FormConfig> = [
    {
      type: 'text',
      valueKey: 'email',
      fieldProps: {
        className: classes.textField,
        label: 'Email address',
        fullWidth: true,
        variant: 'outlined',
        placeholder: 'Email address',
      } as TextFieldProps,
    },
  ];
  return { actionConfig, schema };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {},
    textField: {
      margin: '16px 0px',
    },
  })
);
