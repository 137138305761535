import { request } from 'Resources/AxiosUtils';
import { CommunityRequest, CommunityUpdateData, TCreatorFilter } from './@types';
import { SearchResponse } from '../../Typings/Global';
import { TCommunity } from '../Community/@types';

class CreatorRequestsModel {
  static fetchCommunityRequests = async (filter?: TCreatorFilter): Promise<CommunityRequest[]> => {
    const url = `/CreatorRequests`;
    const response = await request<CommunityRequest[]>({
      url,
      params: { filter: { ...filter, include: ['user', 'community'] } },
      method: 'GET',
    });
    return response;
  };

  static searchCommunityRequests = async (term = '', filter?: TCreatorFilter): Promise<SearchResponse<CommunityRequest>> => {
    const url = `/CreatorRequests/search`;
    const response = await request<SearchResponse<CommunityRequest>>({
      url,
      params: {
        term,
        filter: {
          ...filter,
          "CreatorRequest": {
            "include": [
              "community",
              {
                "relation": "user",
                "scope": {
                  "fields": [
                    "id",
                    "slug",
                    "firstName",
                    "_profileImages"
                  ]
                }
              }
            ]
          }
        }
      },
      method: 'GET',
    });
    return response;
  };

  static fetchSingleCommunityRequest = async (filter?: TCreatorFilter): Promise<CommunityRequest> => {
    return request<CommunityRequest>({
      url: `CreatorRequests/findOne`,
      method: 'get',
      params: filter,
    });
  };

  static fetchCommunitySearchResults = async (term: string): Promise<SearchResponse<TCommunity>> => {
    return request<SearchResponse<TCommunity>>({
      url: `Communities/search`,
      method: 'get',
      params: { term, filter: { limit: 5 } },
    });
  };

  static updateCommunityData = async (id: string | undefined, data: CommunityUpdateData): Promise<CommunityUpdateData> => {
    return request<CommunityUpdateData>({
      url: `Communities/${id}/update-data`,
      method: 'post',
      data: { data },
    });
  };

  static featureCommunityData = async (id: string | undefined): Promise<any> => {
    return request<any>({
      url: `Communities/${id}/feature-community`,
      method: 'patch',
    });
  };

  static creatorUpsertStatus = async (id: string, newStatus: string): Promise<any> => {
    return request<any>({
      url: `CreatorRequests/${id}/upsert-status`,
      method: 'patch',
      data: { id, newStatus },
    });
  };

  static creatorRequestCount = async (where?: Record<string, any>): Promise<Record<string, any>> => {
    // const where = { $or: [{ status: 'PENDING' }, { status: 'REJECTED' }] };
    return request<Record<string, any>>({
      url: `CreatorRequests/count`,
      method: 'get',
      params: { where },
    });
  };
}

export default CreatorRequestsModel;
