import React, { useContext } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import PgButton from 'Components/PgButton';
import PgTypo from 'Components/PgTypo';
import PgIcon from 'Components/PgIcon';
import { COLORS } from 'Theme/newThemeConstants';
import PgDialog from 'Components/PgDialog';
import { AppDialogContext } from 'Contexts/AppDialogContext';
import { useHistory } from 'react-router-dom';

export interface TEmailVerifiedDialogProps {}

const EmailVerifiedDialog: React.FC<TEmailVerifiedDialogProps> = () => {
  const classes = useStyles();
  const { hideDialog } = useContext(AppDialogContext);
  const history = useHistory();

  return (
    <PgDialog
      heading="You're all set!"
      body={
        <Box display="flex" flexDirection="column">
          <PgIcon icon="icon-check-on" styleClass={classes.icon} />
          <PgTypo b4 align="center">
            Start exploring events or customize your profile to get recommendations tailored to your interests.
          </PgTypo>
        </Box>
      }
      actionButtons={
        <>
          <Box className={classes.btn}>
            <PgButton
              primary
              fullWidth
              onClick={() => {
                hideDialog();
                history.push('/drops');
              }}
            >
              Explore Drops
            </PgButton>
          </Box>
          <Box className={classes.btn}>
            <PgButton
              secondary
              fullWidth
              onClick={() => {
                hideDialog();
                history.push('/account/edit-profile');
              }}
            >
              Customize your profile
            </PgButton>
          </Box>
        </>
      }
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  btn: {
    margin: theme.spacing(1.25),
  },
  icon: {
    color: COLORS.positive,
    fontSize: 80,
  },
}));

export default EmailVerifiedDialog;
