import { StyleRules } from '@material-ui/core';
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides';
import { THEME_PALETTE } from 'Theme/themeConstants';

const Select: Partial<StyleRules<ComponentNameToClassKey['MuiSelect']>> = {
  root: {
    height: 44,
    boxSizing: 'border-box',
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  outlined: {
    backgroundColor: THEME_PALETTE.grey.A200,
    '&.MuiOutlinedInput-input': {
      padding: '8px 20px 8px 8px',
    },
  },
};

export default Select;

export const DarkSelect: Partial<StyleRules<ComponentNameToClassKey['MuiSelect']>> = {
  root: {
    height: 44,
    boxSizing: 'border-box',
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  outlined: {
    padding: '8px 20px 8px 8px',
  },
};
