import { Box, makeStyles, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Spacer from 'Components/Spacer';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import React, { FC } from 'react';

const CommunitySkeletonCard: FC = () => {
  const classes = useStyles();
  const { isDeviceSm } = useMediaQuery();
  return (
    <Box style={{ aspectRatio: '1/1' }}>
      <Skeleton className={classes.skeletonLayout} variant="circle" width="70%" height="70%" />
      <Spacer height={10} />
      <Skeleton className={classes.skeletonLayout} variant="rect" width="60%" height={10} />
      <Spacer height={10} />
      <Skeleton className={classes.skeletonLayout} variant="rect" width="45%" height={8} />
    </Box>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  skeletonLayout: {
    margin: '0 auto',
  },
  gridItem: {
    margin: theme.spacing(2, 0),
  },
}));

export default CommunitySkeletonCard;
