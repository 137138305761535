import { ANALYTICS_USER_EVENT } from 'Analytics/analyticActions';
import ToastMessage, { ToastMessageProps } from 'Components/ToastMessage';
import config from 'config';
import useToastMessage from 'Hooks/useToastMessage';
import AuthModel from 'Models/Auth';
import UserModel from 'Models/User';
import { TPhone, User } from 'Models/User/@types';
import { useSnackbar, VariantType } from 'notistack';
import { useStoreActions, useStoreDispatch, useStoreState } from 'Stores';
import MESSAGES, { getTemplateString } from 'Utils/Messages';
import { usePgWalletProvider } from '../../Web3';

export interface TEmailVerificationParams {
  verificationToken?: string;
  verificationEmail?: string;
}
export const useAuth = () => {
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({
    appUser,
  }));
  const {
    authenticate,
    login: userLogin,
    signup: userSignup,
    logout: userLogout,
    setAppUser,
    updateUser,
  } = useStoreActions(({ AuthStore: { authenticate, login, signup, logout, setAppUser, updateUser } }) => ({
    authenticate,
    login,
    signup,
    logout,
    setAppUser,
    updateUser,
  }));
  const withToast = useToastMessage();
  const { enqueueSnackbar } = useSnackbar();
  const showToast = (msg: ToastMessageProps | string, variant: VariantType = 'error') =>
    enqueueSnackbar(ToastMessage(typeof msg === 'string' ? { title: msg } : msg), { variant, autoHideDuration: 8000 });

  const dispatch = useStoreDispatch();
  const { logout: web3LogOut } = usePgWalletProvider();

  const login = async (email: string, password: string, referringCode?: string) => {
    try {
      const userData = await userLogin({ email, password, referringCode });
      dispatch({
        type: ANALYTICS_USER_EVENT,
        data: { eventName: 'USER_LOG_IN', context: { user: userData, type: 'email' }, source: window.location.pathname },
      });
      showToast(MESSAGES.LOGIN_SUCCESS, 'success');
    } catch (err) {
      const emailExists = await UserModel.checkIfEmailExists(email);
      showToast({
        title: !emailExists ? MESSAGES.LOGIN_ACCOUNT_DOES_NOT_EXIST_TITLE : MESSAGES.LOGIN_ERROR_TITLE,
        subtitle: !emailExists ? MESSAGES.LOGIN_ACCOUNT_DOES_NOT_EXIST_MESSAGE : MESSAGES.LOGIN_ERROR_MESSAGE,
      });
    }
  };

  const signUp = async (user: User) => {
    await withToast(async () => userSignup(user), {
      successToastMessage: {
        title: MESSAGES.SIGNUP_SUCCESS_TITLE,
        subtitle: getTemplateString('SIGNUP_SUCCESS_MESSAGE', { email: user.email }),
      },
    });
  };

  const logout = async (showToast = true) => {
    if (showToast) {
      await withToast(async () => userLogout(), {
        successToastMessage: MESSAGES.LOGOUT_SUCCESS,
        showApiErrorMsg: false,
      });
    } else {
      await userLogout();
    }
    await web3LogOut();
  };

  const requestEmailVerification = async (email: string, redirectUrl?: string) => {
    return withToast(
      async () => {
        return AuthModel.requestEmailVerificationLink(email, redirectUrl);
      },
      {
        successToastMessage: getTemplateString('VERIFICATION_LINK_SENT', { email }), // `Verification link sent to ${email}`,
        showApiErrorMsg: false,
        errorToastMessage: AuthModel.getAuthErrorMessages,
      },
    );
  };

  // const verifyEmail = async (token: string): Promise<User> => {
  //   return withToast<User>(
  //     async () => {
  //       const { accessToken, ...rest } = await AuthModel.verifyEmailToken(token);
  //       if (!accessToken?.id) return undefined;
  //       authenticate({
  //         token: accessToken.id,
  //         user: rest,
  //       });
  //       return rest;
  //     },
  //     {
  //       successToastMessage: MESSAGES.EMAIL_VERIFIED_SUCCESS,
  //       showApiErrorMsg: false,
  //       errorToastMessage: AuthModel.getAuthErrorMessages,
  //     }
  //   );
  // };

  const forgotPassword = async (email: string) => {
    await withToast(async () => AuthModel.forgotPassword(email, config.get('BASE_URL')), {
      successToastMessage: {
        title: MESSAGES.FORGOT_PASSWORD_SUCCESS_TITLE,
        subtitle: getTemplateString('FORGOT_PASSWORD_SUCCESS_MESSAGE', { email }),
      },
    });
  };

  const changePassword = async (userId: string, password: string) => {
    if (!password) return;

    await withToast(async () => AuthModel.updatePassword(userId, password), {
      successToastMessage: MESSAGES.CHANGE_PASSWORD_SUCCESS_MESSAGE,
    });
  };

  const requestPhoneOTP = async (phoneData: TPhone) => {
    if (!appUser) return;
    await withToast(
      async () => {
        await UserModel.updateUser({ phone: phoneData, phoneVerified: false, id: appUser?.id });
        await AuthModel.requestPhoneOTP(phoneData);
      },
      {
        successToastMessage: getTemplateString('PHONE_VERIFICATION_CODE_SENT', { phone: `${phoneData.countryCode} ${phoneData.phone}` }),
        showApiErrorMsg: false,
        errorToastMessage: AuthModel.getAuthErrorMessages,
        errorToastVariant: 'info',
      },
    );
    setAppUser({ ...appUser, phone: phoneData, phoneVerified: false });
  };

  const verifyPhone = async (phone: TPhone, token: string) => {
    await withToast(
      async () => {
        const { accessToken, ...rest } = await AuthModel.updatePhone(phone, token);
        if (!accessToken?.id) return undefined;
        authenticate({
          token: accessToken.id,
          user: rest,
        });
        updateUser({ id: rest.id, receiveRsvpOn: { ...rest.receiveRsvpOn, sms: true } } as Partial<User> & { id: string });
        setAppUser({ ...rest, receiveRsvpOn: { ...rest.receiveRsvpOn, sms: true } } as User);
        return rest;
      },
      {
        successToastMessage: MESSAGES.PHONE_VERIFICATION_SUCCESS,
        variant: 'info',
        showApiErrorMsg: false,
        errorToastMessage: AuthModel.getAuthErrorMessages,
      },
    );
  };

  const setDontAskAgain = async () => {
    if (appUser) {
      const data = await UserModel.updateAskAgain(appUser.id);
      return data.dontAskPhoneRsvp;
    }
  };

  const removePhoneNumber = async () => {
    if (!appUser) return;
    await withToast(
      async () => {
        await UserModel.updateUser({
          id: appUser?.id || '',
          phone: { countryCode: undefined, countryCodeText: undefined, phone: undefined },
          receiveRsvpOn: { ...appUser.receiveRsvpOn, sms: false },
          phoneVerified: false,
        } as Partial<User> & { id: string });
      },
      {
        successToastMessage: { title: MESSAGES.PHONE_REMOVED_TITLE, subtitle: MESSAGES.PHONE_REMOVED_MESSAGE },
      },
    );
    setAppUser({ ...appUser, phone: { countryCode: undefined, countryCodeText: undefined, phone: undefined }, phoneVerified: false });
  };

  return {
    login,
    signUp,
    logout,
    requestEmailVerification,
    // verifyEmail,
    forgotPassword,
    changePassword,
    requestPhoneOTP,
    verifyPhone,
    setDontAskAgain,
    removePhoneNumber,
  };
};

export default useAuth;
