/* eslint-disable import/no-cycle */
import SoundtrackStore from './Soundtrack'
import AlbumStore from './Album'
import NFTListingStore from './NFTListing'
import DropsPageStore from './DropsPage'
import UserDropsStore from './UserDrops';
import { createStore, createTypedHooks, State } from 'easy-peasy';
import isEqual from 'lodash/isEqual';
import LandingStore from './Landing';
import CommunitiesStore from './Communities';
import CommunityRequestsStore from './CommunityRequests';
import EventOrderStore from './EventOrder';
import App from './App';
import CollectStore from './Collect';
import ReadStore from './Read';
import ListenStore from './Listen';
import WatchStore from './Watch';
import EventDetailStore from './EventDetail';
import CommunityStore from './Community';
import EventsStore from './Events';
import AuthStore from './Auth';
import InviteStore from './Invite';
import DrawerSidebarStore from './DrawerSidebar';
import DropsStore from './Drops';
import TicketDetailStore from './TicketDetail';
import TicketsStore from './Tickets';
import SearchMoreStore from './SearchMore';
import SearchPageStore from './SearchPage';
import FavoritesStore from './Favorites/index';
import UserStore from './User';
import IssueDetailsStore from './IssueDetails';
import CommunityDropsStore from './CommunityDrops';
import UserFavouriteEventsStore from './UserFavouriteEvents';
import DiscussionStore from './Discussion';
import Analytics from 'Analytics';
import analyticsMapMiddleware from 'Analytics/analyticsMapMiddleware';

export type TRootStore = typeof RootStore;

const RootStore = {
    SoundtrackStore,
    AlbumStore,
    NFTListingStore,
    DropsPageStore,
  UserDropsStore,
  LandingStore,
  IssueDetailsStore,
  CommunitiesStore,
  CommunityRequestsStore,
  DiscussionStore,
  DrawerSidebarStore,
  EventOrderStore,
  CollectStore,
  ReadStore,
  ListenStore,
  WatchStore,
  EventDetailStore,
  CommunityStore,
  EventsStore,
  AuthStore,
  App,
  InviteStore,
  DropsStore,
  /** TODO revisit */

  TicketDetailStore,
  TicketsStore,
  SearchMoreStore,
  SearchPageStore,
  FavoritesStore,
  UserStore,
  CommunityDropsStore,
  UserFavouriteEventsStore,
};

const typedHooks = createTypedHooks<TRootStore>();

export const { useStoreActions } = typedHooks;
export const { useStoreDispatch } = typedHooks;
// export const useStoreState = typedHooks.useStoreState;
export const useStoreState = <Result>(a: (state: State<TRootStore>) => Result, b?: any) => typedHooks.useStoreState(a, b || isEqual);

export default createStore(RootStore, {
  // Put your dependency injections here
  middleware: [Analytics, analyticsMapMiddleware],
});
