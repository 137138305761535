import { StyleRules } from '@material-ui/core';
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides';
import { COLOR } from 'Theme/themeConstants';

const InputLabel: Partial<StyleRules<ComponentNameToClassKey['MuiInputLabel']>> = {
  outlined: {
    '&.MuiInputLabel-shrink': {
      fontSize: 14,
      transform: 'translate(2px, -22px) scale(1)',
      lineHeight: 1.2,
      letterSpacing: 0.01,
      // color: THEME_PALETTE.primary.light,
      fontWeight: 400,
    },
  },
  shrink: {
    transform: 'translate(0, -21px) scale(0.75)',
    lineHeight: 1.2,
    letterSpacing: 0.01,
    color: COLOR.primary.black,
  },
};

export default InputLabel;

export const DarkInputLabel: Partial<StyleRules<ComponentNameToClassKey['MuiInputLabel']>> = {
  outlined: {
    '&[data-shrink="true"]': {
      fontSize: 14,
      transform: 'translate(2px, -22px) scale(1)',
      lineHeight: 1.2,
      letterSpacing: 0.01,
      // color: THEME_PALETTE.primary.light,
      fontWeight: 400,
    },
  },
  shrink: {
    transform: 'translate(0, -21px) scale(0.75)',
    lineHeight: 1.2,
    letterSpacing: 0.01,
    color: COLOR.primary.white,
  },
};
