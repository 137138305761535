
import * as API from './api';
import * as utils from './utils';
export * from './@types';

const RemindersModel = {
    ...API,
    ...utils
}
export default RemindersModel
    