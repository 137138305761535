import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PgTypo from 'Components/PgTypo';
import { NFTDetails } from 'Models/Community/@types'
import React, { FC } from 'react'
import helpers from 'Utils/helpers';

export interface ListingProps {
  tokens: NFTDetails[];
  isActionDisabled?: boolean;
  onEdit: (data: NFTDetails, index: number) => void;
  onRemove: (tokenAddress: string) => void
}

const Listing: FC<ListingProps> = (props) => {
  const { tokens, isActionDisabled = false, onEdit, onRemove } = props;

  const classes = useStyles()

  if (!tokens.length) return null;

  return (
    <Box className={classes.tokensInfoContainer}>
      <Box className={classes.tokensHeading}>
        <PgTypo b4Bold>TOKEN</PgTypo>
        <PgTypo b4Bold>ID</PgTypo>
      </Box>
      {tokens.map((tokenItem, index) => {
        const { image, tokenAddress: token, chainId: id } = tokenItem;
        return (
          <Box className={classes.tokenInfo} key={token}>
            <img src={helpers.getPictureUrl(image, 72)} className={classes.thumbnail} />
            <PgTypo b4 className={classes.tokenString}>
              {token}
            </PgTypo>
            <PgTypo b4 className={classes.id}>
              {id}
            </PgTypo>
            <Box display="flex" justifyContent="space-between">
              <PgTypo
                c3
                className={clsx(classes.actionButton, { [classes.disabled]: isActionDisabled })}
                role="button"
                tabIndex={0}
                onClick={() => {
                  if (!isActionDisabled) onEdit(tokenItem, index);
                }}
              >
                Edit
              </PgTypo>
              <PgTypo
                c3
                className={clsx(classes.actionButton, classes.remove, { [classes.disabled]: isActionDisabled })}
                role="button"
                tabIndex={0}
                onClick={() => {
                  if (!isActionDisabled) onRemove(token);
                }}
              >
                Remove
              </PgTypo>
            </Box>
          </Box>
        );
      })}
    </Box>
  )
}

export default Listing;

const useStyles = makeStyles((theme) => ({
  tokensInfoContainer: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  tokensHeading: {
    display: 'grid',
    gridTemplateColumns: '1fr 0.97fr',
    gap: 40,
    paddingBottom: theme.spacing(1.5),
  },
  tokenInfo: {
    display: 'grid',
    gridTemplateColumns: '[image]36px 16px [tokenString]1fr 40px [id]0.80fr [buttons]107px',
    paddingBottom: theme.spacing(1.5),
    alignItems: 'center',
    fontSize: 14,
    '&:not(:first-child)': {
      paddingTop: theme.spacing(1.5),
    },
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.common.black}`,
    },
  },
  tokenString: {
    wordBreak: 'break-all',
    gridColumn: 'tokenString',
  },
  id: {
    gridColumn: 'id',
  },
  actionButton: {
    cursor: 'pointer',
    textDecoration: 'underline',
    textUnderlineOffset: '3px',
    gridColumn: 'buttons',
  },
  thumbnail: {
    width: 36,
    gridColumn: 'image',
  },
  remove: { color: theme.palette.error.main },
  disabled: {
    opacity: 0.8,
  },
}));
