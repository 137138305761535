import React, { FC, useState } from 'react';
import { Player } from '@livepeer/react';
import BasicActionDialog from 'Dialogs/BasicActionDialog';
import { TResource } from 'Models/Resource/@types';
import { EGateType } from 'Models';
import utils from 'Utils';
import helpers from 'Utils/helpers';

export interface VideoPlayerDialogProps {
  playbackId: string;
  accessKey: string;
  title?: string;
  onClose?: () => void;
  posterImg?: string;
}

const VideoPlayerDialog: FC<VideoPlayerDialogProps> = ({ playbackId, onClose, title, accessKey, posterImg }) => {
  return (
    <BasicActionDialog
      open
      onClose={onClose ?? (() => {})}
      title={title}
      additionalComponent={<Player autoPlay playbackId={playbackId} priority accessKey={accessKey} poster={posterImg} />}
      additionalComponentContainerProps={{ pt: 0.5 }}
      dialogMaxWidth={680}
    />
  );
};

export const useLivepeerVideoPlayer = () => {
  const [open, setOpen] = useState(false);
  const [params, setParams] = useState<VideoPlayerDialogProps>({ playbackId: '', accessKey: '' });
  const openVideoPlayer = (resource: TResource, password = '') => {
    const { playbackId, gateType, title, _resourceImages } = resource;
    if (!playbackId) return;
    setParams({
      playbackId,
      accessKey: gateType === EGateType.PASSWORD_PROTECTED ? password : utils.getAccessToken(),
      title,
      posterImg: helpers.getPictureUrl(_resourceImages?.[0]),
    });
    setOpen(true);
  };
  const closeVideoPlayer = () => {
    setOpen(false);
    setParams({ playbackId: '', accessKey: '' });
  };
  return {
    openVideoPlayer,
    player: open ? <VideoPlayerDialog {...params} onClose={closeVideoPlayer} /> : null,
  };
};

export default VideoPlayerDialog;
