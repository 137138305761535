/* eslint-disable no-unused-expressions */
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, BoxProps, Button, ButtonProps, IconButton } from '@material-ui/core';
import { IFieldProps, MLFormContent, FormConfig, BuilderSettingsProps } from 'react-forms';
import { get } from 'lodash';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import ConfigurableAccordion, { ConfigurableAccordionProps, ConfigurableAccordionRow } from 'Components/ConfigurableAccordion';
import { JSONType } from 'Typings/Global';
import PgIcon from 'Components/PgIcon';
import { FormikProps } from 'formik';
import { DIVIDER_BORDER } from 'Theme/themeConstants';
import useConfirmationDialog from 'Hooks/useConfirmationDialog';

export interface AccordionArrayFieldProps {
  schema: Array<FormConfig | Array<FormConfig>>;
  /**
   *  Exclude the topmost key for valueKeys in this schema.
   * wrong: [
   *   {
   *     "type": "accordion-array",
   *     "valueKey": "arrayOfValues",
   *     "formikProps": {
   *       "schema": [
   *         {
   *           "type": "text",
   *           "valueKey": "arrayOfValues.name"
   *         }
   *       ]
   *     }
   *   }
   * ]
   * correct:
   * [
   *  {
   *    "type": "accordion-array",
   *    "valueKey": "arrayOfValues",
   *    "formikProps": {
   *      "schema": [
   *        {
   *          "type": "text",
   *          "valueKey": "name"
   *        }
   *      ]
   *    }
   *  }
   *]
   * */
  name?: string;
  getTitle?: (value: JSONType, index: number) => ConfigurableAccordionRow['title'];
  generateId?: (value: JSONType, index: number) => string;
  addRowButtonLabel?: React.ReactNode;
  addRowButtonContainerProps?: Omit<BoxProps, 'children'>;
  addRowButtonProps?: Omit<ButtonProps, 'children' | 'onClick'>;
  draggable?: boolean;
  formSettings: BuilderSettingsProps;
  AccordionCustomisationProps?: Omit<ConfigurableAccordionProps, 'onDragEnd' | 'rows' | 'id' | 'selectedStep' | 'onChange'>;
  containerCustomisationProps?: Omit<BoxProps, 'children'>;
  formContainerCustomisationProps?: Omit<BoxProps, 'children'>;
  removeItemPromptMessage?: string;
  shouldExpandAccordion: boolean;
  hideAddButton?: boolean;
  onAccordionStepChange?: (step: number) => void;
  onAccordionSectionRemoved?: (value: any) => void;
  onAccordionSectionReordered?: (value: any) => void;
}

interface AccordionArrayProps extends IFieldProps {
  fieldProps?: AccordionArrayFieldProps;
}

const AccordionArray: FC<AccordionArrayProps> = ({
  fieldProps = {} as AccordionArrayFieldProps,
  fieldConfig = {} as FormConfig,
  formikProps = {} as FormikProps<unknown>,
}) => {
  const {
    name = '',
    getTitle,
    generateId,
    schema = [],
    addRowButtonLabel,
    addRowButtonContainerProps,
    addRowButtonProps,
    draggable,
    AccordionCustomisationProps,
    formSettings,
    containerCustomisationProps,
    formContainerCustomisationProps,
    removeItemPromptMessage = 'Are you sure you want to remove this item?',
    shouldExpandAccordion,
    hideAddButton = false,
    onAccordionStepChange,
    onAccordionSectionRemoved,
    onAccordionSectionReordered,
  } = fieldProps;

  const setCurrentStep = (step: number) => {
    _setCurrentStep(step);
    onAccordionStepChange?.(step);
  };

  const handleAccordionChange = (event: React.ChangeEvent<Record<string, unknown>>, index: number) => {
    const newStep = index + 1;
    setCurrentStep(newStep === currentStep ? 0 : newStep);
  };

  const values = get(formikProps, `values.${name}`) ?? [{}];

  const addRow = () => {
    formikProps?.setFieldValue(name, [...values, {}]);
    setCurrentStep(values.length + 1);
  };
  useEffect(() => {
    if (!values.length) addRow();
  }, [values]);
  const [currentStep, _setCurrentStep] = useState<number>(!shouldExpandAccordion ? 1000 : values?.length ?? 1);
  const withConfirmationDialog = useConfirmationDialog();

  const removeRow = (event: React.MouseEvent, index: number) => {
    const newValues = [...values];
    newValues.splice(index, 1);
    formikProps.setFieldValue(name, newValues);
    onAccordionSectionRemoved?.(newValues);
  };

  const contentSchemata = useMemo(() => getSchemataForLength(values.length, name, schema), [values.length, schema, name]);

  const accordionRows: ConfigurableAccordionRow[] = values.map((value: JSONType, index: number) => {
    return {
      title: getTitle ? getTitle?.(value, index) : `Title`,
      id: generateId?.(value, index) ?? index.toString(),
      disableDrag: currentStep === index + 1,
      icon: (
        <Box borderLeft={DIVIDER_BORDER} display="flex" alignItems="center" height="100%">
          {currentStep !== index + 1 ? ( // only show this action if current step is not the same as this step
            <IconButton onClick={() => setCurrentStep(index + 1)}>
              <PgIcon color="secondary" icon="icon-edit" />
            </IconButton>
          ) : null}
          <IconButton
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              withConfirmationDialog(() => removeRow(event, index), {
                message: removeItemPromptMessage,
                agreeText: 'YES',
                cancelText: 'NO',
                body: '',
              });
            }}
          >
            <PgIcon color="secondary" icon="icon-trash" />
          </IconButton>
        </Box>
      ),
      content: (
        <>
          <Box {...formContainerCustomisationProps}>
            <MLFormContent
              formId="configurable-accordion-form-component"
              schema={contentSchemata[index]}
              formikProps={formikProps}
              settings={formSettings}
            />
          </Box>
          {index === values.length - 1 && !hideAddButton ? (
            <Box {...addRowButtonContainerProps}>
              <Button {...addRowButtonProps} onClick={addRow}>
                {addRowButtonLabel ?? 'Add item'}
              </Button>
            </Box>
          ) : null}
        </>
      ),
    };
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDragEnd = (result: DropResult, _provided: ResponderProvided) => {
    const { source, destination } = result;
    if (!destination) {
      // onReorder(stateList)
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;
    const valuesArray = [...values];
    const itemToinsert = valuesArray[source.index];
    valuesArray.splice(source.index, 1);
    valuesArray.splice(destination.index, 0, itemToinsert);
    formikProps?.setFieldValue(
      // eslint-disable-next-line no-underscore-dangle
      fieldConfig?.valueKey ?? '',
      valuesArray,
    );
    onAccordionSectionReordered?.(valuesArray);
  };

  // selectedStep is 1 indexed.
  return (
    <Box {...containerCustomisationProps}>
      <ConfigurableAccordion
        id={name}
        rows={accordionRows}
        selectedStep={currentStep}
        onChange={handleAccordionChange}
        onDragEnd={handleDragEnd}
        draggable={draggable}
        {
          ...AccordionCustomisationProps /* Won't override since nothing overlaps */
        }
      />
    </Box>
  );
};

export default AccordionArray;

const getSchemataForLength = (
  numberOfItems: number,
  name: string,
  schema: Array<FormConfig | Array<FormConfig>> | FormConfig,
): Array<Array<FormConfig | Array<FormConfig>>> => {
  let contentSchemata = Array.from({ length: numberOfItems }).map((_, index) => createSchemaForIndex(schema, index, name));
  contentSchemata = contentSchemata.map((contentSchema) => {
    if (!Array.isArray(contentSchema)) {
      return [contentSchema]; // precautionary measure
    }
    return contentSchema;
  });
  return contentSchemata as Array<Array<FormConfig | Array<FormConfig>>>;
};

export const createSchemaForIndex = (
  schema: Array<FormConfig | Array<FormConfig>> | FormConfig,
  index: number,
  parentValueKey: string,
): Array<FormConfig | Array<FormConfig>> | FormConfig => {
  if (Array.isArray(schema)) {
    const newSchema: Array<FormConfig | Array<FormConfig>> = schema.map((schemaItem: FormConfig | Array<FormConfig>) => {
      if (Array.isArray(schemaItem)) {
        return createSchemaForIndex(schemaItem, index, parentValueKey) as Array<FormConfig>;
      }
      return {
        ...schemaItem,
        valueKey: `${parentValueKey}[${index}].${schemaItem.valueKey}`,
      };
    });
    return newSchema as Array<FormConfig | Array<FormConfig>>;
  }
  return {
    ...schema,
    valueKey: `${parentValueKey}[${index}].${schema.valueKey}`,
  };
};
