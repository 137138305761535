import { Box, makeStyles } from '@material-ui/core';
import PgDialog from 'Components/PgDialog';
import CheckEmailExistForm from 'Forms/CheckEmailExist';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import React, { FC } from 'react';
import { FONT_FAMILY } from 'Theme/themeConstants';
import { TAuthDialogContentProps } from '../@types';
import useWalletConnect, { TWalletConnectHookProps } from '../useWalletConnect';

const CheckEmailExist: FC<
  TAuthDialogContentProps & { handleEmailExist: TWalletConnectHookProps['handleEmailExist']; walletMeta: TWalletConnectHookProps['walletMeta'] }
> = (props) => {
  const { handleEmailExist, walletMeta, changeStep } = props;
  const { isDeviceSm } = useMediaQuery();
  const classes = useStyles();
  return (
    <PgDialog
      heading={'Finish setting up your account'}
      headingContainerProps={{ display: 'flex', justifyContent: 'center', position: 'relative' }}
      headingProps={{ h3: !isDeviceSm, h7: isDeviceSm, align: 'center', typoWeight: 'fontWeightBold', h6: false }}
      subheadingProps={{ className: classes.subtitle, c2: false, b1: true }}
      subheading="After completing this step, you can choose whether to log in using your email address or wallet."
      body={
        <>
          <Box mt={3}>
            <CheckEmailExistForm initialData={{}} isSubmitting={handleEmailExist.status === 'PROCESSING'} onSubmit={handleEmailExist.run} />
          </Box>
        </>
      }
      positive={false}
      negative={false}
    />
  );
};

export default CheckEmailExist;

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontFamily: FONT_FAMILY.secondary,
    marginBottom: theme.spacing(4),
  },
}));
