import React, { FC } from 'react';
import ConnectPhantom from './ConnectPhantom';

interface TestComponentsProps {}

const TestComponents: FC<TestComponentsProps> = (props) => {
  return (
    <>
      <ConnectPhantom />
    </>
  );
};

export default TestComponents;
