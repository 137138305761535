import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import useAsyncTask from 'Hooks/useAsyncTask';
import useConfirmationDialog from 'Hooks/useConfirmationDialog';
import useToastMessage from 'Hooks/useToastMessage';
import { EGateType } from 'Models';
import { TCommunity } from 'Models/Community/@types';
import DiscussionModel from 'Models/Discussion';
import { TDiscussion } from 'Models/Discussion/@types';
import React, { FC, useState } from 'react';
import { generatePath, useHistory } from 'react-router';
import { APP_ROUTES } from 'Routes';
import { useStoreActions, useStoreState } from 'Stores';
import { DiscussionDialogBaseProps } from '.';
import ChatActiveFlag from '../ChatActiveFlag';
import About from './sections/About';
import NavTabs from './sections/NavTabs';
import Privacy from './sections/Privacy';
import Permissions from './sections/Permissions';
export interface AboutSectionProps extends DiscussionDialogBaseProps {
  closeDialog: () => void;
}
const AboutSection: FC<AboutSectionProps> = ({ discussion, isAdmin, config, closeDialog, isAuthenticated }) => {
  const { appUser, community } = useStoreState(({ AuthStore: { appUser }, CommunityStore: { community } }) => ({ appUser, community }));
  const { updateDiscussions, setCommunity } = useStoreActions(({ DiscussionStore: { updateDiscussions }, CommunityStore: { setCommunity } }) => ({
    updateDiscussions,
    setCommunity,
  }));
  const withConfirm = useConfirmationDialog();
  const withToast = useToastMessage();
  const leaveDiscussion = () => {
    if (!community || !appUser) return;

    withConfirm(
      async () => {
        await DiscussionModel.leaveJoinDiscussion(discussion.id, appUser.id, 'leave');
        setCommunity({
          ...community,
          discussions: (community.discussions || []).map((i) =>
            i.id === discussion.id ? { ...i, memberIds: i.memberIds.filter((i) => i !== appUser.id) } : i,
          ),
        });
        closeDialog();
      },
      {
        message: 'Leave conversation',
        body: 'You will still be able to see this conversation, but will no longer receive notifications for it. You can rejoin later on.',
        agreeText: 'LEAVE',
      },
      {
        successToastMessage: `You left #${discussion.title}`,
      },
    );
  };
  const onSave = useAsyncTask(async (formData: Partial<TDiscussion>) => {
    withToast(
      async () => {
        let gateType = formData.gateType ?? discussion.gateType ?? EGateType.OPEN;
        if (formData.gateType === EGateType.NFT_GATED && (!formData.nftGates?.length || !formData.nftGates))
          gateType = EGateType.OPEN;
        if (gateType !== EGateType.PASSWORD_PROTECTED && formData.password) formData.password = '';
        await DiscussionModel.updateDiscussion(config.subjectId, config.subject, { id: discussion.id, ...formData, gateType });
        updateDiscussions({ discussion: { ...discussion, ...formData, gateType }, action: 'PUT' });
        if (community) {
          setCommunity({
            ...community,
            discussions: (community.discussions || []).map((i) => (i.id === discussion.id ? { ...i, ...formData, gateType } : i)),
          });
        }
      },
      {
        successToastMessage: `#${formData.title} updated successfully`,
      },
    );
  });

  const history = useHistory();
  const isLastDiscussion = !!(community && community?.discussions?.length < 2);
  const handleDeleteClick = async () => {
    await withConfirm(
      async () => {
        await DiscussionModel.deleteDiscussion(discussion.id);
        const _comm = { ...community, discussions: community?.discussions.filter((d) => d.id !== discussion.id) ?? [] };
        setCommunity(_comm as TCommunity);
        history.push(generatePath(APP_ROUTES.COMMUNITY_DETAIL.path, { communityId: community?.slug ?? community?.id ?? '#' }));
      },
      {
        message: isLastDiscussion ? 'You need at least one conversation.' : `Delete #${discussion.title}`,
        body: isLastDiscussion
          ? 'Your community needs at least one place to chat. Create a new conversation before deleting this one.'
          : `This will remove #${discussion.title} and all its replies. You won't be able to undo this action.`,
        agreeText: isLastDiscussion ? undefined : 'delete',
        cancelText: isLastDiscussion ? 'okay' : 'cancel',
        hideActions: isLastDiscussion ? ['agree'] : undefined,
      },
      {
        successToastMessage: `${discussion.title} deleted`,
      },
    );
  };

  const [currTab, setCurrTab] = useState(0);

  return (
    <Box>
      {!!isAdmin && <NavTabs currentTab={currTab} onTabChange={setCurrTab} />}
      <Box mt={2}>
        {currTab === 0 && (
          <>
            {isAdmin ? <ChatActiveFlag config={config} discussion={discussion} /> : null}
            <About
              config={config}
              discussion={discussion}
              onDeleteClick={handleDeleteClick}
              onLeaveClick={leaveDiscussion}
              onSave={onSave.run}
              isAdmin={isAdmin}
              isAuthenticated={isAuthenticated}
            />
          </>
        )}

        {currTab === 1 && <Privacy discussion={discussion} onPrivacyChange={onSave.run} />}

        {currTab === 2 && <Permissions discussion={discussion} onPermissionChange={onSave.run} />}
      </Box>
    </Box>
  );
};

export default AboutSection;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteConverstion: {
      color: theme.palette.error.main,
    },
  }),
);
