import { Box, TextField, TextFieldProps } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { get } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { getFieldError, IFieldProps } from 'react-forms';
import clsx from 'clsx';
import { getTransformedCountryList, TOption } from 'Constants/CountryList';
import { FormikProps } from 'formik';
import Typo from 'Components/Typo';

export interface IPhoneFieldProps {
  countryCode: string;
  countryCodeText: string;
  phone: string;
}
export interface IPhoneInputProps extends IFieldProps {
  fieldProps?: {
    countryCodeProps?: TextFieldProps;
    phoneProps?: TextFieldProps;
  };
}

const list: TOption[] = getTransformedCountryList();

const PhoneInput: FC<IPhoneInputProps> = (props) => {
  const { fieldProps = {}, formikProps = {} as FormikProps<any & IPhoneFieldProps>, fieldConfig } = props;
  const { countryCodeProps, phoneProps } = fieldProps;
  const classes = useStyles();
  const [state, setState] = useState<TOption | null>(null);
  const valueKey = fieldConfig?.valueKey ?? '';
  const value: IPhoneFieldProps = get(formikProps?.values, valueKey) ?? {};
  const countryCodeLabel = countryCodeProps?.label;
  useEffect(() => {
    if (value) {
      const item = list.find((i) => i.countryCodeText === value.countryCodeText);
      setState(item || null);
    }
  }, [value]);
  const error = getFieldError(valueKey, formikProps);

  const onItemSelect = (event: React.ChangeEvent<{}>, selectedOption: TOption | null) => {
    event.preventDefault();
    if (!selectedOption) return;
    formikProps.setFieldValue(valueKey, { ...value, countryCode: selectedOption.countryCode, countryCodeText: selectedOption.countryCodeText });
  };
  const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formikProps.setFieldValue(valueKey, { ...value, phone: event.target.value });
  };
  return (
    <Box display="flex">
      <Box flex={1} px={0.5}>
        <Autocomplete
          onChange={onItemSelect}
          fullWidth
          value={state}
          options={[...list] as TOption[]}
          classes={{
            option: classes.option,
            popper: classes.popper,
            clearIndicator: classes.clear,
            inputRoot: classes.inputRoot,
            popupIndicator: classes.arrow,
            popupIndicatorOpen: classes.arrow,
          }}
          autoHighlight
          disableClearable={false}
          getOptionLabel={(option) => option.name}
          renderOption={(option) => (
            <>
              <Typo variant="caption">{option.name}</Typo>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={countryCodeLabel}
              variant="outlined"
              fullWidth
              InputProps={{
                ...params.InputProps,
                autoComplete: 'new-password',
                classes: {
                  root: clsx(error ? '' : classes.root),
                  focused: error ? '' : classes.underline,
                },
                placeholder: countryCodeProps?.placeholder,
              }}
              helperText={error?.countryCode}
              error={Boolean(error?.countryCode)}
              InputLabelProps={{
                classes: {
                  // root: error ? '' : classes.underline,
                  focused: error ? '' : classes.underline,
                },
              }}
              placeholder={countryCodeProps?.placeholder}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                ...params.inputProps,

                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              {...countryCodeProps}
            />
          )}
        />
      </Box>
      <Box flex={1} px={0.5}>
        <TextField
          value={value?.phone ?? ''}
          variant="outlined"
          onChange={onPhoneChange}
          helperText={error?.phone}
          error={Boolean(error?.phone)}
          {...phoneProps}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    popper: {
      width: '335px !important',
      // marginLeft: 174,
      /* zIndex: theme.zIndex.modal + 10,
      width: '336px !important',
      marginLeft: 174,
      [theme.breakpoints.down('md')]: {
        marginLeft: 27.5,
        width: 'calc(100vw - 64px) !important',
      }, */
    },
    clear: {
      display: 'none',
    },
    arrow: {
      // color: '#fff',
    },
    inputRoot: {
      paddingRight: `24px !important`,
      minWidth: 145,
    },
  });
});

export default PhoneInput;
