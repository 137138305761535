import { createStyles, IconButton, InputAdornment, ListItemText, TextField, Theme } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import Clear from '@material-ui/icons/Clear';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import React, { FC, useEffect, useState } from 'react';
import { FormConfig, getFieldError, IFieldProps } from 'react-forms';
import { GoogleUtils, TGooglePlaceSuggestCategories } from 'rf-google-places';
import { FormikProps } from 'formik';
import { get } from 'lodash';
import { User } from 'Models/User';
import helpers from '../../Utils/helpers';
import { useStoreState } from '../../Stores';

export type GoogleLocationSuggestProps = TextFieldProps & {
  onResultClick: (result: any) => void;
  suggestionsTypes: TGooglePlaceSuggestCategories[];
  value?: any;
};

export interface GoogleLocationProps extends IFieldProps {
  fieldProps: GoogleLocationSuggestProps;
  variant?: 'dark' | 'light';
}

export const GoogleLocationSuggest: FC<GoogleLocationProps> = (props) => {
  const classes = useStyles();
  // console.log(props);
  const { fieldProps, formikProps = {} as FormikProps<User>, fieldConfig = {} as FormConfig /* variant = 'light' */ } = props;
  const [input, setInput] = useState('');
  const [result, setResult] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const helperText = getFieldError(fieldConfig.valueKey, props.formikProps || {});
  const error = !!helperText;
  const { isAuthenticated } = useStoreState(({ AuthStore: { isAuthenticated } }) => ({ isAuthenticated }));

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!open) setOpen(true);
    setInput(e.target.value);
    // anchorEl || setAnchorEl(e.currentTarget);
  };
  const { onResultClick, suggestionsTypes, ...textFieldProps } = fieldProps;
  const clearInput = () => {
    if (open) setOpen(false);
    formikProps.setFieldValue(fieldConfig.valueKey, null);
    formikProps.setFieldValue('city', null);
    setInput('');
    setResult([]);
  };
  const getSuggestions = async () => {
    const res = (await GoogleUtils.placeSuggest(input, suggestionsTypes)) as any[];
    if (res) setResult(res);
  };

  useEffect(() => {
    if (input) {
      getSuggestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  const value = get(formikProps, `values.${fieldConfig.valueKey}`) ?? {};
  useEffect(() => {
    setInput(value?.name || '');
  }, [value]);
  const handleResultClick = async (item: any) => {
    const res = await helpers.cityDetails(item.place_id);
    formikProps.setFieldValue(fieldConfig.valueKey, res);
    formikProps.setFieldValue('city', res.name);
    setInput(res.name || res.formatted_address || '');
    setOpen(false);
  };
  const onInputChange = (event: React.ChangeEvent<{}>, values: string, reason: 'input' | 'reset' | 'clear') => {
    if (event) {
      event.preventDefault();
      if (reason === 'clear') {
        clearInput();
      }
    }
  };
  return (
    <>
      <Autocomplete
        // onChange={(e, val) => handleResultClick(val)}
        getOptionLabel={(option) => option.name || option.description}
        classes={{ popper: classes.popper }}
        filterOptions={(x) => x}
        options={result}
        onInputChange={onInputChange}
        includeInputInList
        fullWidth
        autoComplete
        freeSolo
        open={open && Boolean(input.length)}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        forcePopupIcon={false}
        disableClearable={false}
        aria-expanded
        id="city-field"
        // value={value || null}
        placeholder="Search on google"
        getOptionSelected={(option) => option.name || option.description}
        renderInput={(params) => (
          <TextField
            {...params}
            label="City"
            InputLabelProps={{
              title: 'City',
              classes: {
                root: !isAuthenticated ? '' : classes.label,
              },
            }}
            inputProps={{
              ...params.inputProps,
              onChange: handleInputChange,
              value: input || '',
              autoComplete: 'nope',
              'aria-label': 'City',
            }}
            error={error}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={clearInput}>
                    <Clear fontSize="small" className={classes.endIcon} />
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                root: error ? '' : !isAuthenticated ? classes.root : '',
                //  underline: classes.underline,
                focused: error ? '' : !isAuthenticated ? classes.underline : '',
              },
            }}
            placeholder="Search on google"
            variant="standard"
            {...textFieldProps}
          />
        )}
        renderOption={(item) => <ListItemText onClick={() => handleResultClick(item)}>{item.description}</ListItemText>}
      />
    </>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    endIcon: {
      cursor: 'pointer',
    },
    label: {
      color: '#000',
    },
    root: {
      borderBottom: '1px solid #fff',
      color: '#fff',
    },
    underline: {
      color: '#fff !important',
      // borderBottom: '1px solid #fff',
    },
    popper: {
      zIndex: theme.zIndex.modal + 10,
    },
  });
});
