import React, { FC, useEffect, useState } from 'react';
import { Box, BoxProps, Button, ButtonProps } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typo, { TypoProps } from './Typo';
import PgTypo, { PgTypoProps } from './PgTypo';
import PgButton from './PgButton';
import Autolinker from 'autolinker';
import clsx from 'clsx';

export interface ReadMoreLessProps extends BoxProps {
  maxLength: number;
  text: string;
  typoProps?: PgTypoProps;
  buttonProps?: ButtonProps;
}

const ReadMoreLess: FC<ReadMoreLessProps> = ({ text: propText = '', buttonProps = {}, typoProps = {}, maxLength, ...boxProps }) => {
  const classes = useStyles();
  const [showMore, setShowMore] = useState(false);
  const [text, setText] = useState('');
  const toggleShowMore = () => {
    setShowMore((e) => !e);
  };
  useEffect(() => {
    if (propText.length <= maxLength) setText(propText);
    else if (showMore) setText(propText);
    else setText(propText.substring(0, maxLength));
  }, [showMore, propText, maxLength]);
  const hasMore = propText.length > maxLength;
  return (
    <Box component="span" display="inline-flex" {...boxProps}>
      <PgTypo {...typoProps} className={clsx(classes.text, typoProps.className)}>
        <span dangerouslySetInnerHTML={{ __html: Autolinker.link(text, { newWindow: true }) }} />
        {hasMore ? (
          <PgButton quaternary tcolor="#000" className={classes.button} onClick={toggleShowMore} {...buttonProps}>
            <PgTypo b4Bold>{showMore ? 'Read less' : 'Read more'}</PgTypo>
          </PgButton>
        ) : null}
      </PgTypo>
    </Box>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    text: {
      wordBreak: 'break-all',
      '& a': { color: theme.palette.secondary.main, textDecoration: 'underline' }, '& p': { wordBreak: 'break-all' }
    },
    button: {
      marginLeft: '5px',
      padding: 0,
      height: 'auto',
      '&:focus': {
        backgroundColor: 'transparent !important',
      },
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
  });
});

export default ReadMoreLess;
