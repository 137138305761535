import React, { useState, type FC, useContext, useMemo } from 'react';
import { Box, ButtonBase, Collapse, Portal, Theme, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import PgIcon from 'Components/PgIcon';
import clsx from 'clsx';
import { BORDER_BLACK, COLOR, FONT_FAMILY, FONT_WEIGHTS } from 'Theme/themeConstants';
import PgTypo from 'Components/PgTypo';
import PgButton from 'Components/PgButton';
import BasicActionDialog from 'Dialogs/BasicActionDialog';
import UserModel, { TCryptoWallet } from 'Models/User';
import DropUtils from 'Utils/DropUtils';
import IconText from 'Components/IconText';
import SVGCIcon from 'Components/SVGComponents/SVGCIcon';
import { TDrop, TDropType } from 'Models/Drop/@types';
import { IRewardSetting } from 'Models/Event/Reward/@types';
import EventModel from 'Models/Event';
import { useStoreState } from 'Stores';
import { generatePath } from 'react-router';
import { ACCOUNTS_ROUTES } from 'Routes/AccountNavigation';
import { APP_ROUTES } from 'Routes';
import SubmitButton from 'Components/SubmitButton';
import useAsyncTask from 'Hooks/useAsyncTask';
import { EventContext } from './EventViewModel';
import useAuthDialog from 'Features/Auth/useAuthDialog';
import { useWalletAddresses } from 'Features/AppHeader/WalletList';
import { WalletType } from 'Models/Web3/@types';
import helpers from 'Utils/helpers';

interface ClaimRewardProps {
    event: TDrop;
    dropType: TDropType;
}

const ClaimReward: FC<ClaimRewardProps> = ({ event, dropType }) => {
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
    const [isOpen, setIsOpen] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [isClaimSuccessful, setIsClaimSuccessful] = useState(false);

    const { openDialog: openAuthDialoag } = useAuthDialog();

    const { refreshStealth } = useContext(EventContext);
    const wallets = useWalletAddresses();

    // const rewardLink = appUser?.publicAddress && appUser.recentWalletUsed ? generatePath(ACCOUNTS_ROUTES.NFT_DETAILS.path, { address: appUser.publicAddress, type: appUser.recentWalletUsed }) : undefined;

    const rewardLink = useMemo(() => {
        const wallet = wallets.find(w => w.address && w.type !== WalletType.COCRT && w.type !== WalletType.SUI)
        const pgWallet = wallets.find(w => w.type === WalletType.PG);
        if (!wallet && !pgWallet) return APP_ROUTES.REWARDS.path;
        const route = generatePath(
            ACCOUNTS_ROUTES.NFT_LIST.path,
            { meta: helpers.b64Encode(JSON.stringify({ ...(pgWallet ?? wallet), chain: 43114 })) }
        )
        return route;
    }, [wallets]);
    const classes = useStyles({ isOpen: !!isOpen });

    const onClaim = async () => {
        if (!event?.ownedRewards?.[0]?.id) return;
        try {
            const res = await EventModel.claimReward(event?.ownedRewards?.[0]?.id);
            setIsClaimSuccessful(true);
            setOpenDialog(true);
            refreshStealth();
        } catch (error) {
            setOpenDialog(true);
            setIsClaimSuccessful(false);
        }
    };

    const onClose = () => setOpenDialog(false);

    const handleSuiWalletCreate = async () => {
        // const { loginURL } = await UserModel.getGoogleZKLoginLink();
        // window.open(loginURL, '_self');
        openAuthDialoag('wallet-select', {
            appUser
        });
    };

    const { hasClaimed, isSoldOut, rewardTitle, subtitle, title, soldOrClaimedHelperText } = useMemo(() => {
        const { claimedCount = 0, amount = 0 } = event.rewardSettings ?? ({} as IRewardSetting);
        const hasClaimed = !!event?.ownedRewards?.[0]?.claimedAt;
        return {
            title: isClaimSuccessful ? 'Claimed!' : 'Couldn’t claim reward',
            subtitle: isClaimSuccessful
                ? 'You will be able to see your rewards inside your wallet.'
                : 'In order to claim the reward you need to be connected to a wallet.',

            hasClaimed,
            isSoldOut: claimedCount === amount,
            rewardTitle: `This ticket includes a reward. (${claimedCount ?? 0}/${amount ?? 0})`,
            soldOrClaimedHelperText: hasClaimed ? 'You’ve claimed a reward!' : 'No more rewards to claim.',
        };
    }, [event.rewardSettings, isClaimSuccessful]);


    if (!event.rewardSettings) return null;

    return (
        <>
            <Box width={'100%'}>
                <Box component={ButtonBase} className={classes.titleWrap} onClick={() => setIsOpen(!isOpen)}>
                    <PgTypo b1>{rewardTitle}</PgTypo>
                    <PgIcon icon="icon-arrow-next" size="small" styleClass={clsx(classes.aboutIcon, { [classes.aboutIconExpand]: !!isOpen })} />
                </Box>
                <Collapse in={!!isOpen}>
                    <Box mt={0.5} mb={1.5}>
                        {hasClaimed || isSoldOut ? (
                            <PgTypo b4 className={clsx({ [classes.successText]: hasClaimed, [classes.soldOutText]: !hasClaimed && isSoldOut })}>
                                {soldOrClaimedHelperText}
                            </PgTypo>
                        ) : null}
                    </Box>
                    {!!isOpen ? (
                        <ClaimRewardInfo
                            onClick={onClaim}
                            canClaim={!!event?.ownedRewards?.[0]?.canClaim?.[0]}
                            rewardSettings={event.rewardSettings}
                            isSoldOut={isSoldOut}
                            isClaimSuccessful={isClaimSuccessful || hasClaimed}
                            dropType={dropType}
                            rewardLink={rewardLink}
                        />
                    ) : null}
                </Collapse>
            </Box>
            <Portal>
                <BasicActionDialog
                    open={openDialog}
                    onClose={onClose}
                    additionalComponent={
                        <AdditionalComponent
                            onSuiClick={handleSuiWalletCreate}
                            onClick={onClaim}
                            isClaimSuccessful={true}
                            canClaim={!!event?.ownedRewards?.[0]?.canClaim?.[0]}
                            rewardSettings={event.rewardSettings}
                            dropType={dropType}
                            rewardLink={rewardLink}
                        />
                    }
                    title={title}
                    subtitle={subtitle}
                    dialogMaxWidth={600}
                    dialogProps={{ fullScreen: false }}
                    dialogPaperClass={classes.dialog}
                />
            </Portal>
        </>
    );
};

export default ClaimReward;

interface AdditionalProps extends ClaimRewardInfoProps {
    onSuiClick: () => Promise<void>;
}

const AdditionalComponent: FC<AdditionalProps> = (props) => {
    const { isClaimSuccessful, onSuiClick } = props;

    if (isClaimSuccessful) return <ClaimRewardInfo {...props} isDialog />;

    return (
        <Box display={'flex'} flexDirection={'column'} gridGap={'16px'}>
            <PgButton onClick={onSuiClick} size="large" variant="outlined" color="primary">
                connect an existing wallet
            </PgButton>
            <PgButton onClick={onSuiClick} size="large" variant="outlined" color="primary">
                create a wallet
            </PgButton>
        </Box>
    );
};

interface ClaimRewardInfoProps {
    onClick: () => Promise<void>;
    isClaimSuccessful?: boolean;
    canClaim?: boolean;
    isSoldOut?: boolean;
    rewardSettings: IRewardSetting;
    dropType: TDropType;
    rewardLink?: string;
    isDialog?: boolean;
}

export const ClaimRewardInfo: FC<ClaimRewardInfoProps> = (props) => {
    const classes = useStyles({});
    const { name, description, image } = props.rewardSettings;

    return (
        <Box display={'flex'} paddingRight={'22px'} gridGap={'24px'} flexDirection={'row'} border={1} borderColor={COLOR.primary.black}>
            <Box width={80}>
                <img height={80} className={classes.img} alt="reward_image" src={image?.url} />
            </Box>
            <Box flex={1} display={'flex'} padding={'4px'} flexDirection={'column'} justifyContent={'center'} gridGap={'8px'}>
                <PgTypo c3 limitLines={1}>
                    {name}
                </PgTypo>
                <PgTypo b6 limitLines={2}>
                    {description}
                </PgTypo>
            </Box>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <ClaimRewardActionButton {...props} />
            </Box>
        </Box>
    );
};

interface ClaimRewardActionButtonProps extends ClaimRewardInfoProps { }

const ClaimRewardActionButton: FC<ClaimRewardActionButtonProps> = (props) => {
    const { isClaimSuccessful, canClaim = false, onClick, isSoldOut, dropType, rewardLink, isDialog = false } = props;

    const classes = useStyles({});

    const onClaimClick = useAsyncTask(onClick);

    if (isClaimSuccessful) {
        return (
            <PgButton className={classes.button} href={rewardLink} size="large" variant="outlined" color="primary">
                {!isDialog ? 'View' : 'See'} Reward
            </PgButton>
        );
    }

    if (canClaim) {
        return (
            <SubmitButton
                color="primary"
                fullWidth
                onClick={() => onClaimClick.run({})}
                className={classes.button}
                disabled={onClaimClick.status === 'PROCESSING'}
                variant="contained"
                loading={onClaimClick.status === 'PROCESSING'}
            >
                claim
            </SubmitButton>
        );
    }
    return (
        <Tooltip title={getTooltipTitle(dropType)} placement="right" arrow>
            <span>
                <PgButton disabled secondary size="large" variant="outlined" className={classes.disableButton} color="secondary">
                    {isSoldOut ? (
                        'sold out'
                    ) : (
                        <IconText icon={<SVGCIcon icon="icon-lock" opacity={0.4} />} text="locked" textClasses={classes.text} />
                    )}
                </PgButton>
            </span>
        </Tooltip>
    );
};

const getTooltipTitle = (dropType: TDropType) => {
    switch (dropType) {
        case 'video':
            return 'Watch the video to unlock the reward. Please note: It could take a few minutes to claim.';
        case 'podcast':
            return 'Listen to the podcast to unlock the reward. Please note: It could take a few minutes to claim.';
        case 'album':
            return 'Listen to the album to unlock the reward. Please note: It could take a few minutes to claim.';
        case 'soundtrack':
            return 'Listen to the soundtrack to unlock the reward. Please note: It could take a few minutes to claim.';
        default:
            return 'Ticket owners only can unlock the reward.Please note: It could take a few minutes to claim.';
    }
};

const useStyles = makeStyles<Theme, { isOpen?: boolean }>((theme) =>
    createStyles({
        titleWrap: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: (props) => theme.spacing(props?.isOpen ? 2 : 0),
            cursor: 'pointer',
        },
        aboutIcon: { transform: 'rotate(0deg)', transition: '300ms ease-in', cursor: 'pointer' },
        img: { width: '100%', height: '100%', aspectRatio: '1 / 1', objectFit: 'cover', borderRight: BORDER_BLACK },
        aboutIconExpand: { transform: 'rotate(90deg)' },
        button: {
            height: '32px',
            padding: '8px 20px',
            whiteSpace: 'nowrap',
            width: 112,
            fontSize: 12
        },
        disableButton: {
            height: '32px',
            padding: '16px 20px 16px 12px',
        },
        text: {
            fontWeight: FONT_WEIGHTS.fontWeightBold,
            fontFamily: FONT_FAMILY.primary,
            opacity: 0.4,
            fontSize: '14px',
            lineHeight: '22px',
        },
        successText: { color: theme.palette.success.light },
        soldOutText: { color: theme.palette.error.main },
        dialog: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
                marginRight: 0,
            },
        },
    }),
);
