/* eslint-disable react/require-default-props */
import React, { FC, createRef, useEffect, useState } from 'react';
import 'cropperjs/dist/cropper.css';
// import Cropper, { ReactCropperElement } from 'react-cropper';
import 'react-image-crop/dist/ReactCrop.css';
import BasicActionDialog from 'Dialogs/BasicActionDialog';
import { Box, Slider, Theme, createStyles, makeStyles } from '@material-ui/core';
import { debounce } from 'lodash';
import { COLOR } from 'Theme/themeConstants';
import { FiberManualRecordRounded } from '@material-ui/icons';
import PgTypo from './PgTypo';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import Cropper, { Area } from 'react-easy-crop';
import useAsyncTask from 'Hooks/useAsyncTask';

export interface ImageCropperProps {
  base64: string;
  onComplete: (base64Original: string, cropCoordinates: Cropper.Data) => void;
  cropConfig?: Omit<Cropper.Options, 'checkOrientation' | 'onInitialized' | 'src' | 'viewMode' | 'center'>;
  onClose: () => void;
  open: boolean;
  showVariableSizes?: boolean;
  cropperOnly?: boolean;
  onCropComplete?: (croppedAreaPixels: Area, rotate: number) => void;
}

const ImageCropper: FC<ImageCropperProps> = (props) => {
  const { base64, cropConfig, open, onClose, onComplete, showVariableSizes = false, cropperOnly = false, onCropComplete } = props;

  // const [cropper, setCropper] = useState<null | Cropper>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>()

  const getCropData = useAsyncTask<void>(async () => {
    // if (cropper) {
      // onComplete(base64, cropper.getData());
      // onComplete(cropper.getCroppedCanvas().toDataURL(), {} as Cropper.Data)
    // }
    if (!croppedAreaPixels) return;
    const croppedImg = await getCroppedImg(base64, croppedAreaPixels, rotate);
    onComplete(croppedImg ?? '', {} as Cropper.Data)
  });

  // return (
  //   <Dialog open={open} onClose={onClose} scroll="paper">
  //     <DialogTitle>Crop Image</DialogTitle>
  //     <DialogContent dividers>
  //       <Cropper
  //         {...cropConfig}
  //         center
  //         src={base64}
  //         viewMode={1}
  //         checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
  //         onInitialized={(instance: Cropper) => {
  //           setCropper(instance);
  //         }}
  //       />
  //     </DialogContent>
  //     <DialogActions>
  //       <Button onClick={onClose} color="primary">
  //         Cancel
  //       </Button>
  //       <Button onClick={getCropData} color="primary">
  //         Done
  //       </Button>
  //     </DialogActions>
  //   </Dialog>
  // );

  const classes = useStyle();
  const { isDeviceSm } = useMediaQuery();


  const renderOutlines = () => {
    if (!showVariableSizes) return;
    const cropperDiv = document.querySelector('.reactEasyCrop_CropArea')
    if (!cropperDiv) {
      debounce(renderOutlines, 700)();
      return;
    }
    const mobileRange = document.createElement('div');
    mobileRange.className = classes.mobileRange;
    const mobileRangeChild = document.createElement('div');
    mobileRangeChild.className = classes.mobileRangeChild;
    mobileRange.appendChild(mobileRangeChild);
    const desktopRange = document.createElement('div');
    desktopRange.className = classes.desktopRange;
    mobileRangeChild.appendChild(desktopRange);
    cropperDiv.appendChild(mobileRange);
  }

  const variableSizeProps = showVariableSizes ? {
    aspectRatio: 2.07,
  } : {};

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [rotate, setRotate] = useState(0)
  const handleCropComplete = async (croppedArea: Area, croppedAreaPixels: Area) => {
    // const croppedImg = await getCroppedImg(base64, croppedAreaPixels, rotate);
    // onComplete(croppedImg ?? '', {} as Cropper.Data)
    setCroppedAreaPixels(croppedAreaPixels)
    if (cropperOnly) onCropComplete?.(croppedAreaPixels, rotate);
  }

  useEffect(() => {
    setCrop({ x: 0, y: 0 })
    setZoom(1)
    setRotate(0)
    setCroppedAreaPixels(undefined)
  }, [base64])

  const handleCropperLoad = () => {
    setCrop({ x: 0, y:0 });
    setZoom(1);
    setRotate(0);
    setCroppedAreaPixels(undefined);
  }

  const cropperComp = (
    <div className={showVariableSizes ? classes.cropperRoot : undefined}>
      {/* {showVariableSizes && (
        <Box display='flex' justifyContent='center' alignItems='center' gridGap={'8px 32px'} flexWrap='wrap' pt={1} pb={4}>
          {Array.from({ length: 2 }).map((_, i) => (
            <Box display='flex' alignItems='center' gridGap={12}>
              <FiberManualRecordRounded fontSize="small" htmlColor={COLOR.tertiary[i == 0 ? 'party' : 'music']} />
              <PgTypo b2>{i === 0 ? 'Mobile' : 'Desktop'} view safe zone.</PgTypo>
            </Box>
          ))}
        </Box>
      )} */}
      {/* <Cropper
        {...cropConfig}
        width='100%'
        height='100%'
        center
        src={base64}
        viewMode={3}
        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
        onInitialized={(instance: Cropper) => {
          if (showVariableSizes) renderOutlines();
          setCropper(instance);
        }}
        style={{ height: '100%', maxHeight: 400, width: '100%' }}
        cropBoxResizable={false}
        cropBoxMovable={false}
        dragMode='move'
        autoCropArea={1}
        zoomOnTouch={false}
        zoomOnWheel={false}
        toggleDragModeOnDblclick={false}
        {...variableSizeProps as Partial<Cropper.Options>}
      /> */}
      <Box height={400} position='relative' bgcolor='#7f7f7f' onLoad={handleCropperLoad}>
        <Cropper
          image={base64}
          crop={crop}
          zoom={zoom}
          rotation={rotate}
          aspect={variableSizeProps.aspectRatio ?? cropConfig?.aspectRatio}
          onCropChange={setCrop}
          onCropComplete={handleCropComplete}
          onZoomChange={setZoom}
          zoomWithScroll={false}
        />
      </Box>
      <Box mt={2} display='flex' alignItems='flex-start' flexDirection={isDeviceSm ? 'column' : 'row'} gridGap={16}>
        <Box width='100%'>
          <PgTypo b2 align='left'>Zoom</PgTypo>
          <Slider
            min={1}
            max={2}
            step={0.1}
            value={zoom}
            valueLabelDisplay="auto"
            onChange={(_, v) => setZoom(v as number)}
            // onChange={(_, v) => cropper?.zoomTo((v as number) - 1)}
          />
        </Box>
        <Box width='100%'>
          <PgTypo b2 align='left'>Straighten</PgTypo>
          <Slider
            min={-180}
            max={180}
            step={1}
            value={rotate}
            track={false}
            valueLabelDisplay="auto"
            onChange={(_, v) => setRotate(v as number)}
            // onChange={(_, v) => cropper?.rotateTo(v as number)}
          />
        </Box>
      </Box>
    </div>
  )

  if (cropperOnly) return cropperComp;

  return (
    <BasicActionDialog
      open={open}
      onClose={onClose}
      title="Crop Image"
      subtitle="Zoom image to resize"
      additionalComponent={cropperComp}
      onDone={getCropData.run}
      onCancel={onClose}
      dialogMaxWidth={650}
      doneBtnprops={{ id: 'testId', disabled: getCropData.status === 'PROCESSING' }}
    />
  );
};

export default ImageCropper;


const useStyle = makeStyles((theme: Theme) => createStyles({
  cropperRoot: {
    '& .cropper-move': { zIndex: 1 },
    '& .cropper-view-box': { outline: 'none' },
  },
  mobileRange: {
    border: `1px solid ${theme.palette.error.main}`,
    position: 'absolute',
    top: 0,
    width: '100%',
    aspectRatio: '2.07 / 1',
  },
  mobileRangeChild: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  desktopRange: {
    height: '100%',
    aspectRatio: '1.09 / 1',
    border: `1px solid ${COLOR.tertiary.music}`,
  },
}))

const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })


export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation)

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: Area,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc) as any;
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width as number,
    image.height as number,
    rotation
  )

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  // draw rotated image
  ctx.drawImage(image, 0, 0)

  const croppedCanvas = document.createElement('canvas')

  const croppedCtx = croppedCanvas.getContext('2d')

  if (!croppedCtx) {
    return null
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width
  croppedCanvas.height = pixelCrop.height

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  )

  // As Base64 string
  return croppedCanvas.toDataURL('image/jpeg');

  // As a blob
  // return new Promise((resolve, reject) => {
  //   croppedCanvas.toBlob((file) => {
  //     resolve(URL.createObjectURL(file as Blob))
  //   }, 'image/jpeg')
  // })
}
