import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormConfig, IFormActionProps } from 'react-forms';
import * as Yup from 'yup';
import UserModel from 'Models/User';
import { debounce } from 'lodash';
import { TextFieldProps } from '@material-ui/core';

const checkEmailExistHandler = async (email: string) => {
  const doesEmailExist = await UserModel.checkIfEmailExists(email);

  return doesEmailExist;
};

const debouncedEmailCheck = debounce(checkEmailExistHandler, 500);

// eslint-disable-next-line import/prefer-default-export
export const useFormConfig = (disableAction: boolean) => {
  const classes = useStyles();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid Email')
      .required('Email is required')
      .test('emailExists', 'Email not found', function (e: any) {
        const { path, createError } = this;
        return new Promise(async (resolve, reject) => {
          if (!!e && typeof e !== 'string') reject(createError({ path, message: 'Email not found' }));
          const isExist = await debouncedEmailCheck(e?.toLowerCase());
          if (!isExist && isExist !== undefined) {
            // if (!(await debouncedEmailCheck(e.toLowerCase()))) {
            reject(createError({ path, message: 'Email not found' }));
          }
          resolve(true);
        });
      }),
  });
  const actionConfig: IFormActionProps = {
    submitButtonText: 'SEND',
    submitButtonLayout: 'fullWidth',
    submitButtonProps: {
      size: 'large',
      disabled: disableAction,
    },
    containerClassNames: classes.buttonContainer,
  };
  const inputConfig: Array<Array<FormConfig> | FormConfig> = [
    {
      type: 'text',
      valueKey: 'email',
      fieldProps: {
        placeholder: 'Email',
        fullWidth: true,
        variant: 'outlined',
        autoFocus: true,
      } as TextFieldProps,
    },
  ];
  return { actionConfig, inputConfig, validationSchema };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      '& button': {
        // backgroundColor: theme.palette.secondary.main,s
      },
    },
    eachField: {
      paddingBottom: 20,
      '& label': {
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.common.black,
      },
    },
  }),
);
