import { TFilter, TParams } from 'Models/App/@types';
import AxiosUtils, { request } from 'Utils';
import { JSONType, SearchResponse } from 'Typings/Global';
import axios, { AxiosRequestConfig } from 'axios';
import { Guest, User } from 'Models/User/@types';
import { parseToGuest } from 'Models/User/userParser';
import { ChartData } from 'Models/Insights/@types';

import { parseEventFormData, parseEvent, addOnParser, addOnFormParser } from './eventParsers';
import { Addons, EAddOnConnType, EventCancellation, IAddOnConnRequest, IAddOnConnResponse, TEvent } from './@types';
import { TRefundIssue } from 'Models/RefundRequest/@types';
import { TPurchasedTicket } from 'Models/PurchasedTicket/@types';
import { DEFAULT_INSIGHT_FILTER_ITEMS } from 'Models/Insights';
import { TContentType } from 'Models/Drop/@types';
import { IMintingPriceEstimation, IRewardContractEstimation, MintingStatus } from './Reward/@types';

export const getPurchasedTicketDetail = async (id: string, params?: JSONType): Promise<TPurchasedTicket> =>
    request<TPurchasedTicket>({
        url: `/purchasedTickets/${id}/details`,
        method: 'GET',
        params,
    });

export function get_Events_id_purchase_tickets<T = any>(
    id: string,
    quantity: number,
    userDetails: Record<string, any>,
    ticketTierId?: string,
    promoCode?: string,
    password?: string,
) {
    return axios.request<T>({
        url: `/Events/${id}/purchaseTickets`,
        method: 'POST',
        data: {
            quantity,
            ticketTierId,
            userDetails,
            promoCode,
            password,
        },
    });
}

export function get_Event_id_bill<T = any>(id: string, quantity: number, ticketTierId: string, promoCode?: string) {
    return axios.request<T>({
        url: `/Events/${id}/bill`,
        method: 'get',
        params: {
            quantity,
            ticketTierId,
            promoCode,
        },
    });
}

export function get_Events_id_stripe_account_id<T = any>(id: string) {
    return axios.request<T>({
        url: `/Events/${id}/stripe-account-id`,
        method: 'get',
    });
}

export function post_Events_id_create_reminder_userId<T = any>(userId: any, id: string) {
    return axios.request<T>({
        url: `/Events/${id}/create-reminder/${userId}`,
        method: 'post',
    });
}

export const getEventAnalytics = async (
    appUserID: string,
    eventId: string,
    startDate: any,
    endDate: any,
    granularity: string,
    insights: string[],
    insightFields: string[] = DEFAULT_INSIGHT_FILTER_ITEMS,
): Promise<ChartData> => {
    const response = await request({
        // url: `events/${eventId}/analytics`,
        url: `/users/community-insights`,
        method: 'get',
        params: {
            communityId: appUserID,
            startDate,
            endDate,
            granularity,
            eventId,
            insights,
        },
    });
    return response as ChartData;
};

export const postEvent = async (data: Partial<TEvent>, togglePublish?: boolean): Promise<TEvent> => {
    if (data.id) {
        if (togglePublish) {
            return togglePublishEvent(data.id, !data.isPublished);
        }
        return updateEvent({ ...data, id: data.id }).catch(AxiosUtils.throwError);
    }
    const { promoCodes, ...eventData } = parseEventFormData(data);
    const res = await request({
        url: '/events/new',
        method: 'POST',
        data: { event: { ...eventData, addons: addOnFormParser(eventData?.addons) }, promoCodes },
    }).catch(AxiosUtils.throwError);
    if (togglePublish) await togglePublishEvent(res.event.id, true);
    const parsedEvent = parseEvent(res.event);
    return { ...data, ...parsedEvent, addons: addOnParser(parsedEvent.addons ?? data.addons) } as TEvent;
};

export const updateEvent = async (data: Partial<TEvent> & { id: string }): Promise<TEvent> => {
    const { promoCodes, ...eventData } = parseEventFormData(data);
    const res = await request({
        url: `/events/${data.id}/update`,
        method: 'patch',
        data: { event: { ...eventData, addons: addOnFormParser(eventData?.addons) }, promoCodes },
    }).catch(AxiosUtils.throwError);
    const parsedEvent = parseEvent(res);
    return { ...data, ...parsedEvent, addons: addOnParser(parsedEvent.addons ?? data.addons) } as TEvent;
};

export const getEvents = async (params?: TParams, axiosConfig?: AxiosRequestConfig): Promise<JSONType> => {
    const res = await request<JSONType>({
        ...axiosConfig,
        url: '/Events',
        method: 'GET',
        params,
    });
    return res;
};

export const deleteEvents = async (ids: string[]): Promise<JSONType> => {
    const res = await request<JSONType>({
        url: '/Events/trash',
        method: 'DELETE',
        data: { ids },
    });
    return res;
};

export const getSingleEvent = async (id: string, params?: TParams): Promise<TEvent> => {
    const res = await request<JSONType>({
        url: `/Events/${id}`,
        params,
    }).catch(AxiosUtils.throwError);
    const event = parseEvent(res);
    return { ...event, addons: addOnParser(event.addons) };
};

export const togglePublishEvent = async (id: string, publish: boolean): Promise<TEvent> => {
    const res = await request<TEvent>({
        url: `/events/${id}/publish`,
        method: 'post',
        params: { publish },
    }).catch(AxiosUtils.throwError);
    const event = parseEvent(res);
    return { ...event, addons: addOnParser(event.addons) };
};

export const searchEvents = (
    term: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filter?: Record<string, any>,
    axiosConfig?: AxiosRequestConfig,
): Promise<SearchResponse<TEvent>> =>
    request<SearchResponse<TEvent>>({
        ...axiosConfig,
        url: '/events/search',
        params: { term, filter },
    });

export const addToFavourites = (eventIds: string[]): Promise<JSONType> =>
    request({
        url: '/events/addToFavourites',
        method: 'post',
        params: { eventIds },
    });

export const removeFromFavourites = (eventIds: string[]): Promise<JSONType> =>
    request({
        url: '/events/removeFromFavourites',
        method: 'delete',
        params: { eventIds },
    });

export const updateEventsFlag = (key: string, events: TEvent[] = [], eventIds: string[] = [], flag = true): TEvent[] =>
    [...(events || [])].map((e) => (eventIds.includes(e.id) ? { ...e, [key]: flag } : e));

export const cancelOrReschedule = (id: string, eventCancellationData: EventCancellation): Promise<JSONType> =>
    request({
        url: `/events/${id}/cancel-or-reschedule`,
        method: 'PATCH',
        data: eventCancellationData,
    });

export const getPromoCodes = (id: string): Promise<JSONType> => {
    return request({
        url: `/events/${id}/promoCodes`,
        params: {
            deleted: false,
        },
    });
};

export const refundUserTicket = (id: string): Promise<JSONType> =>
    request({
        url: `purchasedTickets/${id}/refund`,
        method: 'POST',
    });

export const getGuests = async (
    eventId: string,
    params?: { from?: number; limit?: number; searchTerms?: string },
): Promise<{ data: Guest[]; metadata: { total: number; page: number } }> => {
    const res = await request({
        url: `/events/${eventId}/guests`,
        method: 'GET',
        params,
    });
    return { metadata: res.metadata, data: res.data?.map(parseToGuest) };
};

export const approveRefundRequest = (data: { id: string; acceptNote?: string }): Promise<JSONType> =>
    request<JSONType>({
        url: `/refundRequests/accept`,
        method: 'POST',
        data,
    });
export const getRefundRequest = (id: string, filter?: TFilter): Promise<TRefundIssue> =>
    request<TRefundIssue>({
        url: `/RefundRequests/${id}`,
        method: 'GET',
        params: {
            filter,
        },
    });

export const rejectRefundRequest = (data: { id: string; cancelNote?: string }): Promise<JSONType> =>
    request<JSONType>({
        url: `/refundRequests/cancel`,
        method: 'DELETE',
        data,
    });

export const getMyEvents = (filter?: TFilter): Promise<TEvent[]> => {
    return request<TEvent[]>({
        url: `/Events/my-events`,
        method: 'get',
        params: {
            filter,
        },
    });
};
export const isDropPasswordValid = (id?: string, password?: string, dropType: TContentType = 'Event'): Promise<TEvent> =>
    request<TEvent>({
        url: `/${dropType}s/${id}/validate-password`,
        method: 'POST',
        params: { id, password },
    });

export const getEventRsvpGuests = async (id: string, term: string, filter?: TFilter) => {
    try {
        const { metadata, data } = await request<{ metadata: { total: number }; data: { user: User }[] }>({
            url: `/Events/${id}/search-rsvp-guests`,
            params: {
                searchText: term,
                from: filter?.skip,
                limit: filter?.limit,
            },
            method: 'GET',
        });
        return {
            total: metadata?.total || 0,
            users: data.filter((i) => !!i.user)?.map((i) => i.user),
        };
    } catch (err) {
        return { total: 0, users: [] };
    }
};

export const addAddOnConn = async (eventId: string, connReqData: IAddOnConnRequest) => {
    const res = await request<JSONType>({
        url: `/Events/${eventId}/update-addons-connections`,
        method: 'POST',
        data: { ...connReqData },
    });
    return {
        ...res,
        addOns: addOnParser({
            ...res.addOns,
            entityOrder: res?.addons?.entityOrder ?? [EAddOnConnType.featured, EAddOnConnType.speakers, EAddOnConnType.sponsors],
        }),
    } as { addedConnection: IAddOnConnResponse; addOns: Addons };
};

export const resendAddonConnReq = async (eventId: string, connRequestId: string) => {
    const res = await request({
        url: `/Events/${eventId}/connection-resend/${connRequestId}`,
        method: 'POST',
    });
    return res;
};

export const getMintingPriceEstimation = async (params: IMintingPriceEstimation): Promise<IRewardContractEstimation> => {
    const res = await request<IRewardContractEstimation>({
        url: `/Resources/get-rewards-contract-estimation`,
        method: 'GET',
        params,
    });
    return res;
};

export const getMintStatus = async (uniqueId: string) => {
    const res = await request<MintingStatus>({
        url: '/Resources/check-mint-status',
        method: 'GET',
        params: {
            uniqueId,
        },
    });
    return res;
};

export const claimReward = async (id: string) => {
    const res = await request({
        url: `Rewards/${id}/claim`,
        method: 'POST',
    });
    return res;
};
