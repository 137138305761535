import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, makeStyles, Menu, useTheme } from '@material-ui/core';
import { useStoreActions, useStoreState } from 'Stores';
import PgButton from 'Components/PgButton';
import { APP_ROUTES } from 'Routes';
import { CategorizedNotifications, NotificationTabs } from './types';
import NotificationsList from './NotificationsList';
import { TNotification } from 'Models/App/@types';
import UserModel from 'Models/User';
import useNotificationsSocket from './useNotificationsSocket';
import useAsyncTask from 'Hooks/useAsyncTask';
import { NOTIFICATION_TRIGGERED_IDS } from 'Constants/variables';

interface NotificationsMenuProps {
  anchorEl?: HTMLElement | null;
  handleClose: () => void;
}

const NotificationsMenu: FC<NotificationsMenuProps> = ({ anchorEl, handleClose }) => {
  const classes = styles();
  const theme = useTheme();
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
  const { increaseUnreadNotifCount } = useStoreActions(({ UserStore: { increaseUnreadNotifCount } }) => ({ increaseUnreadNotifCount }));
  // const [currentTab, setCurrentTab] = useState<NotificationTabs>(appUser?.userCommunities?.length ? 'personal' : 'all');
  const [currentTab, setCurrentTab] = useState<NotificationTabs>('all');
  const [notifications, setNotifications] = useState<CategorizedNotifications>({
    all: [],
    personal: [],
    community: [],
  });
  const [tabsWithNewNotification, setTabsWithNewNotification] = useState<NotificationTabs[]>([]);
  const onTabChange = (newTab: NotificationTabs) => {
    setCurrentTab(newTab);
    setTabsWithNewNotification(tabsWithNewNotification.filter((tab) => tab !== newTab));
  };

  const onNotificationReceive = (data: TNotification) => {
    increaseUnreadNotifCount(1);
    setNotifications((notifications) => {
      const typesToAddNotifTo: NotificationTabs[] = appUser?.userCommunities?.length
        ? ['all', data.isScopedNotification ? 'community' : 'personal']
        : ['all'];
      let newNotifications = { ...notifications };
      typesToAddNotifTo.forEach((type) => {
        newNotifications[type] = [data, ...newNotifications[type]];
      });
      setTabsWithNewNotification((curr) => [...curr, ...typesToAddNotifTo]);
      return newNotifications;
    });
  };
  useNotificationsSocket(onNotificationReceive);

  const getNotificationsTask = useAsyncTask(async () => {
    const newNotifications = await UserModel.getNotifications({
      order: ['updated DESC'],
      limit: 20,
      ...(currentTab !== 'all' ? { where: { isScopedNotification: currentTab === 'community' } } : {}),
    });
    setNotifications({ ...notifications, [currentTab]: newNotifications });
  });

  const notificationCheck = async (currTab: NotificationTabs) => {
    const newNotifications = await UserModel.getNotifications({
      order: ['updated DESC'],
      limit: 20,
      ...{ where: { isScopedNotification: currTab === 'community' } },
    });
    const isNewPresent = newNotifications.find((n) => !n.isRead);
    setTabsWithNewNotification((t) => [...t, ...(isNewPresent ? [currTab] : []), 'all']);
  };

  useEffect(() => {
    if (appUser && appUser.unReadNotificationCount) {
      notificationCheck('community');
      notificationCheck('personal');
    }
  }, [appUser?.id]);

  useEffect(() => {
    if (notifications[currentTab].length < 20) {
      getNotificationsTask.run({});
    }
  }, [currentTab]);

  const notificationsToShow = useMemo(
    () => notifications[currentTab],
    [currentTab, notifications.all, notifications.community, notifications.personal],
  );

  const markAllAsRead = async () => {
    const { count } = await UserModel.markAllNotifsAsRead();
    if (count) {
      const newNotifications = {
        all: notifications.all.map((item) => ({ ...item, isRead: true })),
        personal: notifications.personal.map((item) => ({ ...item, isRead: true })),
        community: notifications.community.map((item) => ({ ...item, isRead: true })),
      };
      setTabsWithNewNotification([]);
      setNotifications(newNotifications);
    }
  };
  const onReadNotif = useCallback(
    (id: string) => {
      setTabsWithNewNotification((curr) => {
        return curr.filter((tabName) => tabName !== currentTab);
      });
      setNotifications((notif) => ({
        all: notif.all.map((item) => ({ ...item, isRead: id === item.id ? true : item.isRead })),
        personal: notif.personal.map((item) => ({ ...item, isRead: id === item.id ? true : item.isRead })),
        community: notif.community.map((item) => ({ ...item, isRead: id === item.id ? true : item.isRead })),
      }));
    },
    [
      setTabsWithNewNotification,
      setNotifications,
      currentTab,
      tabsWithNewNotification,
      notifications.all,
      notifications.personal,
      notifications.community,
    ],
  );

  const handleActionClick = (itemNotif: TNotification, isAccepted?: boolean) => {
    if (isAccepted === undefined) {
      handleClose();
      return;
    }
    if (itemNotif.notificationTriggerId === NOTIFICATION_TRIGGERED_IDS.ECOSYSTEM_INVITE) {
      setNotifications((n) => ({
        ...n,
        community: n?.community?.map((m) =>
          m.scopedCommunityId === itemNotif.scopedCommunityId &&
          m.variableMapping?.invitingCommunityId === itemNotif.variableMapping?.invitingCommunityId
            ? { ...m, isActionPerformed: true, actionState: isAccepted ? 'ACCEPTED' : 'REJECTED', actionButtons: [] }
            : m,
        ),
        all: n.all.map((m) =>
          m.scopedCommunityId === itemNotif.scopedCommunityId &&
          m.variableMapping?.invitingCommunityId === itemNotif.variableMapping?.invitingCommunityId
            ? { ...m, isActionPerformed: true, actionState: isAccepted ? 'ACCEPTED' : 'REJECTED', actionButtons: [] }
            : m,
        ),
      }));
    }
    if (itemNotif.notificationTriggerId === NOTIFICATION_TRIGGERED_IDS.DROP_CONNECTION_INVITE) {
      setNotifications((n) => ({
        personal: n.personal?.map((m) =>
          m.scopedCommunityId === itemNotif.scopedCommunityId &&
          m.variableMapping?.invitingCommunityId === itemNotif.variableMapping?.invitingCommunityId &&
          m.variableMapping?.addOnType === itemNotif.variableMapping?.addOnType
            ? { ...m, isActionPerformed: true, actionState: isAccepted ? 'ACCEPTED' : 'REJECTED', actionButtons: [] }
            : m,
        ),
        community: n?.community?.map((m) =>
          m.scopedCommunityId === itemNotif.scopedCommunityId &&
          m.variableMapping?.invitingCommunityId === itemNotif.variableMapping?.invitingCommunityId &&
          m.variableMapping?.addOnType === itemNotif.variableMapping?.addOnType
            ? { ...m, isActionPerformed: true, actionState: isAccepted ? 'ACCEPTED' : 'REJECTED', actionButtons: [] }
            : m,
        ),
        all: n.all.map((m) =>
          m.scopedCommunityId === itemNotif.scopedCommunityId &&
          m.variableMapping?.invitingCommunityId === itemNotif.variableMapping?.invitingCommunityId &&
          m.variableMapping?.addOnType === itemNotif.variableMapping?.addOnType
            ? { ...m, isActionPerformed: true, actionState: isAccepted ? 'ACCEPTED' : 'REJECTED', actionButtons: [] }
            : m,
        ),
      }));
    }
    handleClose();
  };
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      open={!!anchorEl}
      onClose={handleClose}
      classes={{ paper: classes.menu, list: classes.list }}
      style={{ zIndex: theme.zIndex.modal + 2 }}
    >
      <NotificationsList
        markAllAsRead={markAllAsRead}
        shouldShowTabs={!!appUser?.userCommunities?.length}
        notifications={notificationsToShow}
        currentTab={currentTab}
        onTabChange={onTabChange}
        onActionClick={handleActionClick}
        onRead={onReadNotif}
        isLoading={getNotificationsTask.status === 'PROCESSING'}
        sizeVariant="small"
        onNavigateAway={handleClose}
        tabsWithNewNotification={tabsWithNewNotification}
      />
      <Box p={2.5}>
        <PgButton onClick={handleClose} primary href={APP_ROUTES.NOTIFICATIONS.path} fullWidth className={classes.allNotifsButton}>
          SEE ALL NOTIFICATIONS
        </PgButton>
      </Box>
    </Menu>
  );
};

export default NotificationsMenu;

const styles = makeStyles((theme) => ({
  list: {
    padding: 0,
    overflow: 'auto',
  },
  menu: {
    minWidth: 616,
    minHeight: 300,
    marginTop: 40,
    maxHeight: 572,
    maxWidth: 616,
    padding: theme.spacing(0),
    [theme.breakpoints.down('md')]: {
      minWidth: 'unset',
      width: '100%',
      maxWidth: 'calc(100vw - 20px)',
      minHeight: 'unset',
      left: '10px !important',
      right: '10px !important',
      maxHeight: 'unset',
    },
  },
  allNotifsButton: {
    marginTop: 'auto',
    marginBottom: 0,
  },
}));
