import METAMASK from 'Assets/Web3_wallets/metamask.svg';
import COINBASE from 'Assets/Web3_wallets/coinbase.svg';
import WALLET_CONNECT from 'Assets/Web3_wallets/walletconnect.svg';
import PHANTOM from 'Assets/Web3_wallets/phantom.svg';
import COCRT from 'Assets/Web3_wallets/cocrt.svg';
import SUI from 'Assets/Web3_wallets/sui.svg';
import { WalletType } from 'Models/Web3/@types';
import PG_LOGO from 'Assets/Web3_wallets/pg.png';
import { Picture } from 'Models/Picture/@types';

export const ACCORDION_SUMMARY_HEIGHT = 70;
export const SCREEN_CONTAINER_WIDTH_LG = 1100;
export const NO_PRINT_CLASS = 'no-print';
export const ZERO_LEFT_MARGIN_CLASS = 'no-left-margin';
export const ADD_EVENT_CREATOR_FORM = 'https://forms.gle/S8EDC6AsZDJC3QCm6';
export const INVALID_URL_MESSAGE = 'Invalid URL.';
export const INVALID_URL_PREFIX_MESSAGE = 'Invalid URL. Your link must start with https://';
export const VALID_URL_PREFIX_REGEX = new RegExp('^(https)://|$', 'i');
export const TIMEOUT = {
    FORGET_PASSWORD: 60,
    SEND_TOKEN: 30,
};
export const FORM_FIELD_MISSING_ERR_MSG = 'One or more mandatory fields are missing';
export const USERNAME_REGEX = /^[a-z0-9]+(([a-z0-9_-])*([a-z0-9])+)?$/;
export const SLUG_MAX_LENGTH = 30;

export const COMMUNITY_TAB_NAVIGATION_ID = 'ommunity-tab-navigation-id';
export const UNVERIFIED_EMAIL_BANNER_ID = 'email-not-verified-alert-banner-id';
export const PROFILE_BANNER_ASPECT_RATIO = '216/65';
export const PROFILE_BANNER_ASPECT_RATIO_N = 216 / 65;

export const WALLET_ICONS_MAP = {
    [WalletType.METAMASK]: METAMASK,
    [WalletType.COINBASE]: COINBASE,
    [WalletType.WALLETCONNECT]: WALLET_CONNECT,
    [WalletType.PHANTOM]: PHANTOM,
    [WalletType.SUI]: SUI,
    cocrt: COCRT,
    pg: PG_LOGO,
};

export const NOTIFICATION_TRIGGERED_IDS = {
    ECOSYSTEM_INVITE: 'community_ecosystem_invite',
    DROP_CONNECTION_INVITE: 'connection_request_invite',
};

export const DEFAULT_IMAGE = {
    fileId: '651d5e1d88c257da33ddfd41',
    name: 'b72be7c0-62b3-11ee-be28-47efe8eaf846_nWRcVbJOW.png',
    size: 1521599,
    versionInfo: { id: '651d5e1d88c257da33ddfd41', name: 'Version 1' },
    filePath: '/pg-images/default/b72be7c0-62b3-11ee-be28-47efe8eaf846_nWRcVbJOW.png',
    url: 'https://ik.imagekit.io/mithya/pg-images/default/b72be7c0-62b3-11ee-be28-47efe8eaf846_nWRcVbJOW.png',
    fileType: 'image',
    height: 962,
    width: 964,
    thumbnailUrl: 'https://ik.imagekit.io/mithya/tr:n-ik_ml_thumbnail/pg-images/default/b72be7c0-62b3-11ee-be28-47efe8eaf846_nWRcVbJOW.png',
    AITags: null,
    id: '651d5e1e4b82fd0f9fec3dff',
    isPortrait: false,
} as Picture;

export const PLAYGROUND_COMM_ID = '61eea5f17fee0bb79665dd4b';
export const COMMUNITY_FORM_BG_IMG = 'https://ik.imagekit.io/mithya/pg-images/default/6c1c8dc0-a16a-11ee-bb60-fbce46c374a7_4gKBYU1Pm.jpg';
export const ALL_MEMBER_DISPLAY_COMM_IDS = [PLAYGROUND_COMM_ID, '64112df9fcc62e318f0d64fc', '647ef3f69b4a952276e60e50'];
