import PgDialog from 'Components/PgDialog';
import React from 'react';
import { useStoreActions, useStoreState } from 'Stores';
import useAuth from '../useAuth';
import { TAuthDialogContentProps } from '../@types';

const DontAskAgain: React.FC<TAuthDialogContentProps> = ({ onDone, changeStep }) => {
  const { setDontAskAgain } = useAuth();
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({
    appUser,
  }));
  const { setAppUser } = useStoreActions(({ AuthStore: { setAppUser } }) => ({
    setAppUser,
  }));

  const handleSuccess = async () => {
    const data = await setDontAskAgain();
    if (!appUser) return;
    setAppUser({
      ...appUser,
      dontAskPhoneRsvp: data,
    });
    onDone();
  };

  return (
    <div>
      <PgDialog
        heading="Are you sure?"
        body="You will not receive notifications for your future events."
        negative={false}
        positiveText="Don't Ask Again"
        handleSuccess={handleSuccess}
      />
    </div>
  );
};

export default DontAskAgain;
