import { parseEvent } from 'Models/Event/eventParsers';
import { request } from 'Utils';
import { parseResource } from 'Models/Resource/resourceParsers';
import { TDrop } from 'Models/Drop/@types';
import { AppConstants, IDiscoverSummaryResponse, Summary, TParams, TFilter, TResult, TESSearchItem, IUnsplashPrams, IUnsplashResponse, IUnsplashImage } from './@types';
import { UploadProgress } from '../../Typings/Global';
import QueryString from 'qs';
import config from 'config';

class AppModel {
  static fetchAppConstants = async (): Promise<AppConstants> => {
    return request<AppConstants>({ url: 'AppModels/constants' });
  };

  static fetchSummary = (): Promise<Summary> => request<Summary>({ url: 'AppModels/summary' });

  static fetchDiscoverSummary = async (params?: TParams): Promise<IDiscoverSummaryResponse> => {
    const res = await request({
      url: '/Events/discover',
      method: 'GET',
      params,
    });
    return {
      events: res.events.map(parseEvent),
      communities: res.communities,
      featuredDrops: res.featuredDrops.results.map((result: TESSearchItem<TDrop>) =>
        result.hit.doc_type === 'Event' ? parseEvent(result.hit) : parseResource(result.hit)
      ),
    };
  };

  static postSingleAsset = async (data: any, setProgress?: (progress: number) => any): Promise<any[]> => {
    const formData = new FormData();
    formData.append('', data);
    const url = 'Assets/upload';
    // eslint-disable-next-line no-useless-catch
    try {
      const result = await request<any[]>({
        url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'POST',
        onUploadProgress: (progressEvent: Partial<UploadProgress>) => {
          const progress = ((progressEvent.loaded ?? 0) / (progressEvent.total ?? 1)) * 100;
          setProgress && setProgress(progress);
        },
      });
      return result;
    } catch (err) {
      throw err;
    }
  };

  // TODO: return type on variable
  static searchEntities(term?: string, filter?: TFilter): Promise<TResult> {
    return request<TResult>({
      url: `/AppModels/searchEntities`,
      method: 'get',
      params: {
        term,
        filter,
      },
    });
  }

  static reportEntity = async (entity: { entityId: string; entityType: string; formData: Record<string, any> }) =>
    request({
      url: '/AppModels/report-entity',
      method: 'POST',
      data: {
        ...entity,
      },
    });

  static getUnsplashImages = async (params: IUnsplashPrams) => {
    const res = await request<IUnsplashResponse>({
      url: `https://api.unsplash.com/search/photos?${QueryString.stringify(params)}`,
      method: 'GET',
      headers: { Authorization: `Client-ID ${config.get('UNSPLASH_ACCESS_KEY') ?? ''}` },
      withCredentials: false,
    });
    return res;
  }

  static getRandomImage = async (term = '') => {
    const res = await request<IUnsplashImage>({
      url: `https://api.unsplash.com/photos/random?${term}`,
      method: 'GET',
      headers: { Authorization: `Client-ID ${config.get('UNSPLASH_ACCESS_KEY') ?? ''}` },
      withCredentials: false,
    })
    return res;
  }

  static getTotalMemberCount = async () => {
    const res = await request<{ totalMemberCount: number }>({
      url: '/AppModels/total-member-count',
      method: 'GET',
    });
    return res;
  }

}

export default AppModel;
