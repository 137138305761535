/* eslint-disable import/no-cycle */
import { Box, LinearProgress } from '@material-ui/core';
import lazyImport from 'lazy-import';
import React, { FC, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { APP_ROUTES } from 'Routes';
import Page404 from 'Screens/Page404';

const PasswordScreen = lazyImport('../Screens/PasswordScreen');
const UserSettings = lazyImport('../Screens/UserSettings');
const EditProfile = lazyImport('../Screens/EditProfileScreen');
const Tickets = lazyImport('../Screens/Tickets');
const TicketDetail = lazyImport('../Screens/TicketDetail');
const InviteFriendsScreen = lazyImport('../Screens/InviteFriendsScreen');
const IssueDetails = lazyImport('../Screens/IssueDetails');
const NFTListingScreen = lazyImport('../Screens/NFTListingScreen');
const NFTDetailsScreen = lazyImport('../Screens/NFTDetailsScreen');

const AccountNavigation: FC = () => {
    return (
        <Box display="flex">
            <Box flex={1} width={'100%'}>
                <Suspense fallback={null}>
                    <Switch>
                        <Route path={BASE_PATH} exact render={() => <Redirect to={ACCOUNTS_ROUTES.TICKETS.path} />} />
                        <Route path={ACCOUNTS_ROUTES.PASSWORD.path} component={PasswordScreen} />
                        <Route path={ACCOUNTS_ROUTES.TICKETS.path} exact component={Tickets} />
                        <Route path={ACCOUNTS_ROUTES.ISSUE_DETAILS_PATH.path} component={IssueDetails} />
                        <Route path={ACCOUNTS_ROUTES.TICKET_DETAIL.path} component={TicketDetail} />
                        <Route path={ACCOUNTS_ROUTES.SETTINGS.path} component={UserSettings} />
                        <Route path={ACCOUNTS_ROUTES.EDIT_PROFILE.path} component={EditProfile} />
                        <Route path={ACCOUNTS_ROUTES.INVITE_FRIENDS.path} component={InviteFriendsScreen} />
                        <Route path={ACCOUNTS_ROUTES.NFT_DETAILS.path} exact component={NFTDetailsScreen} />
                        <Route path={ACCOUNTS_ROUTES.NFT_LIST.path} component={NFTListingScreen} />
                        <Route path="/*" exact component={Page404} />
                    </Switch>
                </Suspense>
            </Box>
        </Box>
    );
};

export default AccountNavigation;

export const BASE_PATH = APP_ROUTES.ACCOUNT.path;
export const ACCOUNTS_ROUTES = {
    TICKETS: {
        path: `${BASE_PATH}/tickets`,
    },
    TICKET_DETAIL: {
        path: `${BASE_PATH}/tickets/:id`,
    },
    SETTINGS: {
        path: `${BASE_PATH}/settings`,
    },
    EDIT_PROFILE: {
        path: `${BASE_PATH}/edit-profile`,
    },
    INVITE_FRIENDS: {
        path: `${BASE_PATH}/invite-friends`,
    },
    PASSWORD: {
        path: `${BASE_PATH}/password`,
    },
    ISSUE_DETAILS_PATH: {
        path: `${BASE_PATH}/tickets/:ticketId/issue/:issueId`,
    },
    ISSUE_DETAILS_CONVERSATION_PATH: {
        path: `${BASE_PATH}/tickets/:ticketId/issue/:issueId/conversations`,
    },
    NFT_LIST: {
        path: `${BASE_PATH}/wallet/:meta`,
    },
    NFT_DETAILS: {
        path: `${BASE_PATH}/wallet/:meta/details`,
    },
};
