import { DASHBOARD_ROUTES, SUPERADMIN_ROUTES } from './AppNavigation';
import { EVENT_MEDIA_ROUTES } from './EventsAndMediaNavigation';
import { EVENT_ROUTES } from './EventNavigation';

export default {
  ...DASHBOARD_ROUTES,
  ...EVENT_ROUTES,
  ...EVENT_MEDIA_ROUTES,
  ...SUPERADMIN_ROUTES,
};
