export const SHADES = {
  dark: '#1A090D',
  greyDark: '#6B6570',
  grey: '#6B6570',
  greyLight: '#E0E0E0',
  light: '#F8F8F8',
  white: '#FFFFFF',
};

export const COLORS = {
  blurple: '#4E45FF',
  black: '#000000',
  white: '#FFFFFF',
  neutral: '#FF9100',
  positive: '#2FE600',
  negative: '#FF0400',
};

export const EFFECTS = {
  shadow: `0px 0px 36px rgba(0, 0, 0, 0.12), 0px 4px 4px rgba(0, 0, 0, 0.08), 0px 4px 10px rgba(0, 0, 0, 0.12)`,
  shadows: [
    `0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)`,
    `0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)`,
    `0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)`,
    `0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)`,
  ],
  gradient: (color1?: string, color2?: string): string => {
    return `linear-gradient(to right, #${color1 || `26282C`}, #${color2 || 'FFFFFF'})`;
  },
};
