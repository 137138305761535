import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// eslint-disable-next-line import/no-cycle
import { SVGCIconProps } from '../SVGCIcon';

export interface TwitchIconProps extends SVGCIconProps {
  iconProps?: Record<string, unknown>;
}

export const TwitchIcon: FC<TwitchIconProps> = (props) => {
  const { color = '#9147FE', width = 37, height = 38 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 37 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.099.333L.667 6.818v25.939H9.58v4.866h4.866l4.861-4.866h7.294l9.727-9.724V.333H3.098zm3.24 3.24h26.748v17.835l-5.675 5.676h-8.915l-4.86 4.86v-4.86H6.34V3.574zm8.916 16.216h3.242v-9.727h-3.242v9.727zm8.915 0h3.242v-9.727H24.17v9.727z"
        fill={color}
      />
    </svg>
  );
};

export const Twitch: FC<TwitchIconProps> = (props) => {
  const classes = useStyles();
  return (
    <i className={classes.root}>
      <TwitchIcon {...props} />
    </i>
  );
};

export default Twitch;

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      position: 'relative',
      display: 'inline-block',
    },
    playIcon: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-70%)',
    },
  })
);
