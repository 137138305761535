import React, { useContext } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import PgIcon from 'Components/PgIcon';
import { COLORS } from 'Theme/newThemeConstants';
import PgDialog from 'Components/PgDialog';
import PgButton from 'Components/PgButton';
import PgTypo from 'Components/PgTypo';
import { AppDialogContext } from 'Contexts/AppDialogContext';

export interface TPhoneVerifiedDialogProps {}

const PhoneVerifiedDialog: React.FC<TPhoneVerifiedDialogProps> = () => {
  const { hideDialog } = useContext(AppDialogContext);
  const classes = useStyles();
  return (
    <PgDialog
      heading="You're all set"
      body={
        <Box display="flex" flexDirection="column">
          <PgIcon icon="icon-check-on" styleClass={classes.icon} />
          <PgTypo b4 align="center">
            We will send notifications to your registered email /phone number.
          </PgTypo>
        </Box>
      }
      actionButtons={
        <PgButton primary onClick={hideDialog}>
          Keep Enjoying
        </PgButton>
      }
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  btn: {
    margin: theme.spacing(1.5),
  },
  icon: {
    color: COLORS.positive,
    fontSize: 80,
  },
}));

export default PhoneVerifiedDialog;
