import { JSONType } from 'Typings/Global';
import { parseAddress } from 'Models/Address/addressParser';
import { parseUser } from 'Models/User/userParser';
import get from 'lodash/get';
import DateTimeUtils from 'Resources/DateTimeUtils';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import { getTimezone } from 'Resources/TimeZone';
import { Addons, EAddOnConnType, EventCancellation, EventCancellationTransform, IQRCodesBulleit, TEvent } from './@types';
import { AdmissionType, EventTier, TicketTier } from './ticketing/@types';
import sanitize from 'sanitize-html';
import { defaultSanitizeProps } from 'Utils/Constants';
import { EGateType } from 'Models';
import { IFeatureToggle } from 'Models/Drop/@types';
import { isEmpty, omit, pick } from 'lodash';

const getAdmissionType = (tier: EventTier): AdmissionType | undefined => {
    if (!tier) return undefined;
    if (tier.type === 'externalSite') return 'UseAnotherSite';
    // if (tier.type === 'nft') return 'NFT';
    if (tier.type === 'nft') return 'UsePlayground';
    return tier.type === 'single' || tier.type === 'multi' ? 'UsePlayground' : 'Free';
};

export const defaultFeatureToggle: IFeatureToggle = {
    addOns: true,
    ageRestriction: true,
    collaborator: true,
    description: true,
    notifications: true,
    privacy: true,
    speakers: true,
    ticketing: true,
    when: true,
    where: true,
    reward: true,
}

export const parseEvent = (obj: JSONType): TEvent => {
    const resObj = get(obj, 'event') ?? obj;
    const {
        type,
        userId,
        user,
        _primaryImage,
        _galleryImages,
        address,
        tagIds = [],
        tags = [],
        isFavourited,
        featured,
        isPrivate,
        timeZone,
        tweetParams = {},
        _coverImages,
        startTime,
        endTime,
        subType,
        addons,
        ageRestriction,
        qrCodesBulleit,
        featureToggle,
        rewardSettings,
        ...rest
    } = resObj;
    // eslint-disable-next-line no-underscore-dangle
    const _enabledTier = find(rest._tiers, { type: rest.tierType }) as EventTier;
    const enabledTier = _enabledTier?.type === 'free' ? {
        ..._enabledTier,
        _ticketTiers: _enabledTier._ticketTiers.map(t => t.isInfinite ? { ...t, ticketsAvailable: undefined } : t),
    } : _enabledTier?.type === 'nft' ? {
        ..._enabledTier,
        type: 'single',
        _ticketTiers: _enabledTier._ticketTiers.map(t => ({ ...t, initialNFTTicketingComplete: t.isClaimPhaseSet, isNFTTicketing: !!t.blockchain })),
    } : _enabledTier;

    const constructedEvent = {
        ...rest,
        addons: !addons
            ? undefined
            : { ...addons, entityOrder: addons.entityOrder ?? [EAddOnConnType.featured, EAddOnConnType.speakers, EAddOnConnType.sponsors] },
        eventType: subType === 'metaverse' ? subType : type,
        curatorId: userId,
        userId,
        primaryImage: _primaryImage,
        galleryImages: _galleryImages,
        coverImages: _coverImages?.[0],
        curator: user ? parseUser(user) : undefined,

        primaryEventCategoryId: tagIds[0],
        primaryEventCategory: tags[0],

        secondaryEventCategory: tags[1],
        secondaryEventCategoryId: tagIds[1],
        address: parseAddress(address),
        isFavourited: !!isFavourited,
        isFeatured: !!featured,
        isPrivate: !!isPrivate,
        tweetParams: {
            isSensitive: !!tweetParams.possibly_sensitive,
        },
        timeZone,
        startTime: get(rest, 'eventDates[0].startDate'),
        endTime: get(rest, 'eventDates[0].endDate'),
        enabledTier,
        admissionType: getAdmissionType({ type: rest.tierType } as EventTier),
        communityId: rest.communityId ?? '',
        tagIds,
        tags,
        chargeTax: rest.taxInfo ? 'yes' : 'no',
        taxInfo: rest.taxInfo ?? {},
        ageRestriction,
        qrCodesBulleit: parseBulleitQR(qrCodesBulleit),
        featureToggle: featureToggle ?? defaultFeatureToggle,
        rewardSettings,
    } as TEvent;
    return constructedEvent;
};

export const parseEventFormData = (
    event: Partial<TEvent>,
    // values?: any
): JSONType => {
    const formData: JSONType = {};
    const {
        id,
        address,
        curatorId,
        name,
        eventDates,
        description,
        location,
        placeId,
        eventType,
        ticketUrl,
        priceCategory,
        frequency,
        repeatUntil,
        // timeZone,
        primaryImage,
        // galleryImages,
        primaryEventCategoryId,
        secondaryEventCategoryId,
        isFeatured,
        // platformName,
        // platformUrl,
        platform,
        subtitle,
        rsvpUrl,
        tweetParams,
        twitterHandles,
        isPrivate,
        timeZone,
        rsvpEmail,
        refundPolicy,
        selectedNewsletter,
        startTime,
        endTime,
        enabledTier,
        promoCodes,
        addons,
        coverImages,
        communityId,
        isUserFeatured,
        chargeTax,
        taxInfo,
        collaboratingCommunityIds,
        // isPasswordProtected,
        password,
        nftGates,
        gateType,
        ageRestriction,
        featureToggle,
        rewardSettings,
    } = event;
    if (id) formData.id = id;
    // if (eventDates)
    //   formData.eventDates = frequency === 'custom' ? eventDates : [eventDates[0]];
    if (address) formData.address = parseAddress(address);
    if (primaryEventCategoryId) {
        formData.tagIds = [primaryEventCategoryId];
        if (secondaryEventCategoryId) formData.tagIds.push(secondaryEventCategoryId);
    }
    if (timeZone) {
        const timeZoneData = getTimezone(timeZone.name);
        if (typeof timeZone !== 'string') {
            formData.timeZone = timeZone;
        } else if (timeZoneData) {
            formData.timeZone = {
                code: timeZoneData.abbreviation,
                name: timeZoneData.timezone,
                offset: timeZoneData.offset,
            };
        }
    }

    let startDate: string | undefined = undefined, endDate: string | undefined = undefined;

    const initialDates = eventDates && eventDates[0];

    if (initialDates && initialDates.startDate && startTime) {
        startDate = DateTimeUtils.copyTimeInDate(initialDates.startDate, startTime, timeZone?.name).toISOString();
    }

    if (initialDates && initialDates.endDate && endTime) {
        endDate = DateTimeUtils.copyTimeInDate(initialDates.endDate, endTime, timeZone?.name).toISOString();
    }

    const { _tiers = [] } = event;
    if (enabledTier) {
        // eslint-disable-next-line no-underscore-dangle
        enabledTier._ticketTiers = enabledTier._ticketTiers.map((val: TicketTier, index: number) => {
            const ticketValue = val as unknown as TicketTier & { rowTitle: string };
            // eslint-disable-next-line
            const { rowTitle, ...ticket } = ticketValue;

            if (!ticket.name)
                // eslint-disable-next-line no-underscore-dangle
                ticket.name = `Tier ${index + 1} ticket`;

            ticket.isClaimPhaseSet = ticket.initialNFTTicketingComplete;
            delete ticket.initialNFTTicketingComplete;

            ticket.isInfinite = !ticket.ticketsAvailable && enabledTier.type === 'free'
            ticket.maxTicketsPerOrder = ticket.isInfinite && !ticket.ticketsAvailable ? 1 : ticket.maxTicketsPerOrder

            if (ticket.blockchain) {
                ticket.pictureUrl = primaryImage?.url;
                ticket.name = name ?? '';
            }

            return ticket;
        });
        formData.tierType = enabledTier.type;
        if (enabledTier.type === 'nft') {
            formData.refundPolicy = 'no_refunds';
            formData.promoCodes = [];
        } else {
            formData.refundPolicy = refundPolicy;
            formData.promoCodes = promoCodes;
        }
        const tierIndex = findIndex(_tiers, { type: enabledTier.type });
        if (tierIndex !== -1) {
            const { id, _ticketTiers } = (_tiers ?? [])[tierIndex];
            (_tiers ?? [])[tierIndex] = { ...enabledTier, id, _ticketTiers: enabledTier._ticketTiers.map((t, i) => ({ ...t, id: _ticketTiers[i].id })) };
        } else {
            const { id: _, _ticketTiers, ...restTier } = enabledTier;
            _tiers.push({
                ...restTier,
                _ticketTiers: _ticketTiers.map(t => omit(t, ['id', 'created', 'updated'])),
            } as EventTier);
        }
        // eslint-disable-next-line no-underscore-dangle
        if (_tiers.length) formData._tiers = _tiers;
    }

    return {
        ...formData,
        eventDates: eventDates?.length ? !startDate || !endDate || !startTime || !endTime ? [] : [{ startDate, endDate }] : undefined,
        // promoCodes,
        name,
        description,
        location,
        placeId,
        type: eventType === 'metaverse' ? 'online' : eventType,
        subType: eventType,
        ticketUrl,
        priceCategory,
        frequency,
        repeatUntil,
        userId: curatorId,
        _primaryImage: primaryImage,
        // galleryImages && galleryImages.length ? galleryImages[0] : undefined,
        // _galleryImages: galleryImages,
        featured: isFeatured,
        platform,
        subtitle,
        rsvpUrl,
        tweetParams: {
            possibly_sensitive: !!tweetParams?.isSensitive,
        },
        twitterHandles,
        isPrivate,
        // refundPolicy,
        selectedNewsletter,
        rsvpEmail,
        addons,
        _coverImages: [coverImages],
        communityId,
        isUserFeatured,
        taxInfo: !chargeTax ? undefined : chargeTax === 'yes' ? taxInfo : null,
        collaboratingCommunityIds,
        // isPasswordProtected,
        password: !gateType ? undefined : gateType === EGateType.PASSWORD_PROTECTED ? password : '',
        nftGates,
        gateType: !nftGates?.length && gateType === EGateType.NFT_GATED ? EGateType.OPEN : gateType,
        ageRestriction: featureToggle?.ageRestriction ? ageRestriction ? parseInt(ageRestriction as unknown as string) : 13 : undefined,
        featureToggle,
        rewardSettings
    };
};

export const parseEventCancellationForm = (formData: EventCancellationTransform): EventCancellation => {
    const {
        cancellationType,
        postponedDateType,
        endDate,
        startDate,
        startTime,
        endTime,
        eventDates = [{ startDate, endDate: endDate }],
        emailSubject = 'Cancellation/postponement of event',
        emailBody,
        refundType,
        timeZone,
    } = formData;
    if (cancellationType === 'Cancel') {
        return {
            cancelOrReschedule: 'cancel',
            automaticRefund: true,
            emailSubject,
            emailBody,
        };
    }
    const cancelOrReschedule: 'cancel' | 'reschedule' = 'reschedule';
    // const dateChanged: boolean = postponedDateType === 'SPECIFIC_DATE';
    // const dateTBD: boolean = postponedDateType === 'TBD';
    // const automaticRefund: boolean = refundType === 'AUTO';
    const newDates = eventDates?.map((eventDate) => {
        const startDate = DateTimeUtils.copyTimeInDate(
            eventDate.startDate ?? '',
            startTime ?? '', // If we assume proper validation, there will always be a start time when there are eventDates.
            timeZone?.name,
        );
        let endDate = DateTimeUtils.copyTimeInDate(eventDate.endDate ?? eventDate.startDate ?? '', endTime ?? '', timeZone?.name);
        if (startDate.isAfter(endDate)) {
            endDate = endDate.add(1, 'day');
        }
        return {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
        };
    });

    let newTimeZone = timeZone;

    if (timeZone) {
        const timeZoneData = getTimezone(timeZone.name);
        if (typeof timeZone === 'string' && timeZoneData) {
            newTimeZone = {
                code: timeZoneData.abbreviation,
                name: timeZoneData.timezone,
                offset: timeZoneData.offset,
            };
        }
    }
    return {
        cancelOrReschedule,
        // dateChanged: true,
        // dateTBD,
        automaticRefund: false,
        emailSubject: emailSubject ?? ' ',
        emailBody: emailBody ? sanitize(emailBody, defaultSanitizeProps) : ' ',
        newDates,
        timeZone: newTimeZone,
    };
};

export const addOnParser = (data?: JSONType): Addons | undefined => {
    if (!data) return;
    const existingSponsorConn = data?.speakerSponsor?.find((f: { name: EAddOnConnType }) => f.name === EAddOnConnType.sponsors)?.connections ?? [];
    const existingFeaturedConn = data?.speakerSponsor?.find((f: { name: EAddOnConnType }) => f.name === EAddOnConnType.featured)?.connections ?? [];
    const existingSpeakersConn = data?.speakerSponsor?.find((f: { name: EAddOnConnType }) => f.name === EAddOnConnType.speakers)?.connections ?? [];

    const sponsorConn = data.sponsors?.filter((f: { connectionStatus: string }) => f.connectionStatus) ?? [];
    const featuredConn = data.featured?.filter((f: { connectionStatus: string }) => f.connectionStatus) ?? [];
    const speakerConn = data.speakers?.filter((f: { connectionStatus: string }) => f.connectionStatus) ?? [];

    const sponsorWithOutConn = data.sponsors?.filter((f: { connectionStatus: string }) => !f.connectionStatus) ?? [];
    const featuredWithOutConn = data.featured?.filter((f: { connectionStatus: string }) => !f.connectionStatus) ?? [];

    const sponsor =
        sponsorConn.length || existingSponsorConn.length
            ? [{ name: sponsorConn?.[0]?.addOnType, connections: [...existingSponsorConn, ...sponsorConn]?.filter((f) => f) }]
            : [];
    const featured =
        featuredConn.length || existingFeaturedConn.length
            ? [{ name: featuredConn?.[0]?.addOnType, connections: [...existingFeaturedConn, ...featuredConn]?.filter((f) => f) }]
            : [];
    const speaker =
        speakerConn.length || existingSpeakersConn.length
            ? [{ name: speakerConn?.[0]?.addOnType, connections: [...existingSpeakersConn, ...speakerConn]?.filter((f) => f) }]
            : [];

    const _speakerSponsors = [...sponsor, ...speaker, ...featured];

    let entityOrder = data?.entityOrder ?? [];
    const entitiesPresent = _speakerSponsors.map((m) => m.name);
    const leftOutEntities = entitiesPresent.filter((e) => !entityOrder?.includes(e));
    if (leftOutEntities.length) entityOrder = [...entityOrder, ...leftOutEntities];
    const speakerSponsor = entityOrder?.map((m: any) => _speakerSponsors.find((f) => f.name === m))?.filter((f: any) => f);

    return { ...data, speakerSponsor, featured: featuredWithOutConn, sponsors: sponsorWithOutConn, entityOrder };
};

export const addOnFormParser = (data: Addons) => {
    if (!data) return;
    const { speakerSponsor = [], speakers = [], sponsors = [], featured = [], ...rest } = data;
    const sponsorConn = speakerSponsor?.find((f) => f.name === EAddOnConnType.sponsors)?.connections ?? [];
    const featuredConn = speakerSponsor?.find((f) => f.name === EAddOnConnType.featured)?.connections ?? [];
    const speakerConn = speakerSponsor?.find((f) => f.name === EAddOnConnType.speakers)?.connections ?? [];
    const entityOrder = speakerSponsor.map((m) => m.name);
    return { ...rest, speakers: speakerConn, sponsors: [...sponsors, ...sponsorConn], featured: [...featured, ...featuredConn], entityOrder };
};

export const parseBulleitQR = (data: any[]): IQRCodesBulleit[] => data && data.length ? data.map((q: { config: { maxUsage: string; }; }) => ({ ...q, config: { ...q.config, maxUsage: parseInt(q?.config?.maxUsage ?? '0') } }) as IQRCodesBulleit) : [];
