/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-useless-concat */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import { ButtonBase } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import React, { useEffect, useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import '../../node_modules/slick-carousel/slick/slick-theme.css';
import '../../node_modules/slick-carousel/slick/slick.css';
import { THEME_PALETTE } from 'Theme/themeConstants';
import clsx from 'clsx';

export interface CustomCarouselProps<T> {
  items: T[];
  // variant?: 'default' | 'upgradeScreen'

  startIndex?: number;
  showArrows?: boolean;
  carouselSettings?: Settings;
  dots?: boolean;
  dotsPosition?: 'left' | 'center' | 'right';
  renderItem: (item: T, index: number) => JSX.Element;
  renderLabel?: (item: T, index: number) => JSX.Element;
  arrowProps?: { additionalClassName?: string; alt?: boolean };
}

function CustomCarousel<T = unknown>(props: CustomCarouselProps<T>) {
  const { items, showArrows, carouselSettings, dots, dotsPosition = 'center', renderItem, renderLabel, arrowProps, startIndex } = props;
  const classes = useStyles({ dotsPosition });
  const ref = useRef<Slider | null>(null);
  const { isDeviceSm } = useMediaQuery();
  const settings: Settings = {
    // variableWidth: isDeviceSm,
    dots,
    autoplaySpeed: 5000,
    autoplay: false,
    infinite: true,
    arrows: showArrows,
    nextArrow: <CarouselNextButton {...arrowProps} />,
    prevArrow: <CarouselPrevButton {...arrowProps} />,
    pauseOnHover: true,
    speed: 500,
    swipe: true,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    ...carouselSettings,
  };

  useEffect(() => {
    if (startIndex) ref.current?.slickGoTo(startIndex);
  }, [ref.current]);

  const nextSlide = () => {
    if (!isDeviceSm || !ref.current) return;
    ref.current.slickNext();
  };

  return (
    <Slider ref={ref} className={classes.root} {...settings}>
      {items.map((it, i) => {
        return (
          <div key={i} onClick={nextSlide} className={classes.carouselItem}>
            {renderItem(it, i)}
            {renderLabel ? renderLabel(it, i) : null}
          </div>
        );
      })}
    </Slider>
  );
}

export const CarouselPrevButton = (props: { onClick?: () => {}; className?: string; additionalClassName?: string; alt?: boolean }) => {
  return (
    <ButtonBase onClick={props.onClick} className={clsx(props.className, props.additionalClassName)} style={{ left: 20 }}>
      <ArrowBack color={props.alt ? 'action' : 'primary'} />
    </ButtonBase>
  );
};

export const CarouselNextButton = (props: { onClick?: () => {}; className?: string; additionalClassName?: string; alt?: boolean }) => {
  return (
    <ButtonBase onClick={props.onClick} className={clsx(props.className, props.additionalClassName)} style={{ right: 20 }}>
      <ArrowForward color={props.alt ? 'action' : 'primary'} />
    </ButtonBase>
  );
};

const useStyles = makeStyles<Theme, { dotsPosition: 'left' | 'center' | 'right' }>((theme) => ({
  root: {
    height: '100%',
    // display: 'flex',
    // alignItems: 'flex-start',

    '& .slick-track': {
      marginLeft: 0,
      marginRight: 0,
    },
    '& .slick-arrow': {
      zIndex: 1,
      // color: theme.palette.common.white,
      fontSize: 20,
      '&::after, &::before': {
        display: 'none',
      },
      '& svg': {
        color: theme.palette.common.white,
      },
    },
    '& .slick-prev': {
      position: 'absolute',
      // top: '100%',
      // left: 'calc(100% - 94px) !important',
      width: 34,
      height: 34,
      backgroundColor: 'transparent',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    '& .slick-next': {
      position: 'absolute',
      // top: '100%',
      width: 34,
      height: 34,
      backgroundColor: 'transparent',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    '& .slick-disabled > .MuiSvgIcon-colorPrimary': {
      color: THEME_PALETTE.grey[600],
    },
    '& .slick-dots': {
      display: 'flex !important',
      bottom: 0,
      justifyContent: (props) =>
        props.dotsPosition === 'center' ? 'center' : props.dotsPosition === 'left' ? 'flex-start' : 'flex-end' + ' !important',
    },
    '& .slick-dots li': {
      width: '5px !important',
      height: '20x !important',
      margin: '0 5px !important',
    },
    '& .slick-dots ': {
      '&::before': {
        width: '5px !important',
        height: '5px !important',
        margin: '0 !important',
        color: '#8B8888',
        opacity: 1,
      },
    },
    '& .slick-active > button': {
      '&::before': {
        color: '#FF6250 !important',
        opacity: '1 !important',
      },
    },
  },
  img: {
    // border: DIVIDER_BORDER_LIGHT,
    // height: IMAGE_CONTAINER_SIZE,
    // width: IMAGE_CONTAINER_SIZE,
    [theme.breakpoints.down('sm')]: {
      // marginTop: 0,
      height: 125,
      width: 125,
    },
    // '&:first-child': {
    //     height: 200,
    //     width: 200,
    // }
  },
  fallback: {
    height: 200,
    width: 200,
    [theme.breakpoints.down('sm')]: {
      // marginTop: 0,
      height: 125,
      width: 125,
    },
  },
  outline: {
    '&:focus': {
      outline: 'none !important',
    },
  },
  arrowButton: {
    position: 'absolute',
    top: window.innerWidth * 0.3,
    width: 40,
    height: 40,
    borderRadius: 20,
    display: 'flex',
    zIndex: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    cursor: 'pointer',
    transition: '200ms linear',
    '& i': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      opacity: 0.8,
    },
  },
  carouselItem: {
    position: 'relative',
  },
}));

export default CustomCarousel;
