import { createStyles, makeStyles, Theme, alpha } from '@material-ui/core/styles';

// FIXME: Make a default export
// eslint-disable-next-line import/prefer-default-export
export const inputStyles = makeStyles<Theme>((theme) =>
  createStyles({
    label: {
      // color: theme.palette.text.secondary,
    },
    input: {
      font: 'inherit',
      color: 'currentColor',
      width: '100%',
      border: 0,
      height: 44,
      margin: 0,
      display: 'block',
      padding: '18.5px 14px',
      minWidth: 0,
      background: theme.palette.grey.A200,
      boxSizing: 'border-box',
      borderRadius: 4,
      outline: 'none',
      '&:focus': {
        border: `2px solid ${alpha(theme.palette.secondary.main, 0.5)}`,
        outline: 'none',
      },
    },
    inputIcon: {
      position: 'relative',
      left: -30,
      top: 8,
    },
  })
);
