/* eslint-disable no-underscore-dangle */
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import config from 'config';
import React, { FC, createContext, useState } from 'react';

// const promise = loadStripe("pk_test_51JAuSISDqeqZK3gxCkngDRfYuSi6OhwgdtE07cmXN9zrea6ydI7630q1RHLREM2Wb6yI9l5T7KREDvztX9QEQHVx00VO1Dhy1V", {
//     stripeAccount: 'acct_1JAunMKI7d9mkELg'
// });

export const StripeContext = createContext({
  setStripeAccount: () => {},
  setStripeAccountByAccountId: (id: string) => {},
});

const STRIPE_KEY = config.get('STRIPE_PUB_KEY');

const StripeContextProvider: FC = (props) => {
  const [promise, setPromise] = useState<Promise<Stripe | null> | null>(null);

  const setStripeAccountByAccountId = (id: string) => {
    const _promise = loadStripe(STRIPE_KEY as string, {
      stripeAccount: id,
    });
    setPromise(_promise);
  };

  const setStripeAccount = () => {
    const _promise = loadStripe(STRIPE_KEY as string);
    setPromise(_promise);
  };

  return (
    <StripeContext.Provider value={{ setStripeAccount, setStripeAccountByAccountId }}>
      <Elements stripe={promise}>{props.children}</Elements>
    </StripeContext.Provider>
  );
};

export default StripeContextProvider;
