import { Box, Theme, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import PgTypo from 'Components/PgTypo';
import CommunityModel from 'Models/Community';
import { TCommunity } from 'Models/Community/@types';
import { TContentType, TDrop } from 'Models/Drop/@types';
import EventModel, { TEvent } from 'Models/Event';
import { TResource } from 'Models/Resource/@types';
import { Tag } from 'Models/Tags/@types';
import DateTimeUtils, { DEFAULT_DATE_FORMAT } from 'Resources/DateTimeUtils';
import DropUtils from 'Utils/DropUtils';
import helpers from 'Utils/helpers';
import React, { FC, useMemo, useState } from 'react';
import DropLink from './DropLink';
import LiveTag from './LiveTag';
import DropActionButton, { DROP_CARD_BTN_ID } from './DropActionButton';
import CollaboratorsGroup from 'Features/Collaboration/CollaboratorsGroup';
import FavoriteButton from './FavoriteButton';
import PgIcon from 'Components/PgIcon';
import { THEME_PALETTE } from 'Theme/themeConstants';
import { useMediaQuery } from 'Hooks/useMediaQuery';

export interface DropCardGridProps {
    drop: TDrop;
    variant?: 'list' | 'grid';
    onDropHover?: (id?: string) => void;
}

interface DropCardData {
    name: string;
    imageUrl: string;
    date?: string;
    creatorAvatar?: string;
    subtitle: string;
    tag?: Tag;
    type?: string;
    platformName?: string;
    platformUrl?: string;
    city?: string;
    docType: TContentType;
    communityUrl: string;
    featured: boolean;
    collaborators: TCommunity[];
    isLive?: boolean;
    isCanceled?: boolean;
    isPast?: boolean;
}

const getEventData = (event: TEvent): DropCardData => {
    // const startDate = EventModel.getEventStartDate(event);
    const collaborators = [...(event.collaborators || [])];
    if (event.community) collaborators.unshift(event.community);
    const date = event?.featureToggle?.when ? EventModel.getEventDateWithLocation(event, true) : '';
    const isLive = helpers.isEventLive(event);
    return {
        name: event?.name,
        imageUrl: helpers.getPictureUrl(event.primaryImage ?? event._primaryImage) || helpers.getPlaceholderImageUrl(),
        date,
        tag: DropUtils.getEventPrimaryTag(event),
        creatorAvatar: helpers.getPictureUrl(CommunityModel.getAvatarImage(event.community as TCommunity), 200),
        subtitle: event.subtitle,
        type: event?.type,
        platformName: event?.platformName,
        platformUrl: event?.platformUrl,
        city: event?.address?.city,
        docType: 'Event',
        communityUrl: event?.community?.slug || (event?.communityId as string),
        featured: event.isFeatured ?? event.featured ?? event.isUserFeatured ?? false,
        collaborators,
        isLive,
        isCanceled: event.canceled,
        isPast: DropUtils.isPastRelease(event, 'Event'),
    };
};

const getResourceData = (resource: TResource): DropCardData => {
    const collaborators = [...(resource.collaborators || [])];
    if (resource.community) collaborators.unshift(resource.community);
    const date = resource.releaseDate || '';
    const isPast = DropUtils.isPastRelease(resource, resource.doc_type);
    return {
        name: resource.title,
        imageUrl: helpers.getPictureUrl(resource._resourceImages?.[0]) ?? helpers.getPlaceholderImageUrl(),
        tag: DropUtils.getResourcePrimaryTag(resource),
        date: date && !isPast && resource?.featureToggle?.when ? DateTimeUtils.formatDate(date, DEFAULT_DATE_FORMAT) : '',
        creatorAvatar: helpers.getPictureUrl(CommunityModel.getAvatarImage(resource.community), 200),
        subtitle: resource.description ?? '',
        docType: resource.doc_type ?? 'collect',
        communityUrl: resource.community?.slug || (resource.communityId as string),
        featured: resource.featured ?? resource.featured ?? resource.isUserFeatured ?? false,
        collaborators,
    };
};

const DropCardGrid: FC<DropCardGridProps> = (props) => {
    const { drop, onDropHover } = props;

    const { isDeviceSm } = useMediaQuery();

    const tag = drop.doc_type === 'Event' ? DropUtils.getEventPrimaryTag(drop) : DropUtils.getResourcePrimaryTag(drop);

    const data: DropCardData = useMemo(() => {
        if (DropUtils.isEventMoment(drop.doc_type as TContentType)) return getEventData(drop as TEvent);
        return getResourceData(drop as TResource);
    }, [drop]);

    const [isMouseOver, setIsMouseOver] = useState(false);

    const handleMouseOver = () => {
        if (isDeviceSm) return;
        onDropHover?.(drop.id);
        setIsMouseOver(true);
    };
    const handleMouseOut = () => {
        if (isDeviceSm) return;
        onDropHover?.();
        setIsMouseOver(false);
    };

    const classes = useStyles({ isMouseOver: isDeviceSm ? true : isMouseOver, tag, isReminded: drop.isReminded });

    const handleClick = () => {
        setIsMouseOver(false)
    }

    return (
        <Box onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut} className={classes.root} onClick={handleClick}>
            <DropLink drop={drop}>
                <img src={data.imageUrl} alt={data.name} className={classes.img} style={{ filter: data.isPast && !isMouseOver ? 'grayscale(100%)' : undefined }} />

                {!!tag && (
                    <Box display="flex" alignItems="center" justifyContent='center' width={70} height={72} position="absolute" top={0} left={20} bgcolor={tag.background} px={0.75}>
                        <PgTypo className={classes.tag} style={{ color: tag.color }}>
                            {tag.name}
                        </PgTypo>
                    </Box>
                )}
            </DropLink>

            <Box position="absolute" top={12} right={12} display="flex" alignItems="center" gridGap={8}>
                {data.featured && <PgIcon icon="icon-star-fill" styleClass={classes.featured} />}
                <FavoriteButton moment={drop} />
            </Box>

            <Box p={2.5} position="relative" className={classes.content}>
                <Box position="absolute" top={-30} right={12}>
                    <CollaboratorsGroup collaborators={data.collaborators || []} />
                </Box>

                <DropLink drop={drop}>
                    <Box minHeight={120} mb={2.5}>
                        <Box mb={2.5} display="flex" gridGap={16}>
                            <PgTypo c5 className={classes.dropType} upperCase>
                                {data.docType?.toLowerCase() === 'collect' ? 'merch' : data.docType}
                            </PgTypo>
                            {data.isLive && <LiveTag typoProps={{ b5: true }} className={classes.liveTag} />}
                        </Box>

                        {/* <Tooltip title={data.name ?? ''} arrow> */}
                        <PgTypo c2 limitLines={2} className={classes.dropName}>
                            {data.name}
                        </PgTypo>
                        {/* </Tooltip> */}

                        <PgTypo b6 limitLines={1} className={classes.dropDate}>
                            {data.date}
                        </PgTypo>
                    </Box>
                </DropLink>
            </Box>

            <DropActionButton drop={drop} resourceType={drop.doc_type ?? 'Event'} />
        </Box>
    );
};

export default DropCardGrid;

const useStyles = makeStyles<Theme, { isMouseOver: boolean; tag?: Tag; isReminded?: boolean }>((theme) =>
    createStyles({
        root: ({ isMouseOver, tag }) => ({
            height: '100%',
            backgroundColor: isMouseOver && tag ? tag?.background : theme.palette.common.white,
            position: 'relative',
            transition: '300ms ease-in',
            [`& #${DROP_CARD_BTN_ID}`]: {
                backgroundColor: theme.palette.common.black,
                borderColor: theme.palette.common.black,
                color: theme.palette.common.white,
                position: 'absolute',
                bottom: 0,
                opacity: isMouseOver ? 1 : 0,
                transition: 'inherit',
            },
        }),
        img: { width: '100%', objectFit: 'cover', aspectRatio: '1 / 1', verticalAlign: 'bottom' },
        tag: { fontSize: 9, textTransform: 'uppercase', fontWeight: theme.typography.fontWeightBold },
        dropType: {
            fontWeight: theme.typography.fontWeightBold,
            color: ({ isMouseOver, tag }) => (isMouseOver && tag ? tag?.color : theme.palette.text.disabled),
        },
        liveTag: { paddingBottom: 0 },
        dropName: {
            color: ({ isMouseOver, tag }) => (isMouseOver && tag ? tag?.color : theme.palette.common.black),
            marginBottom: theme.spacing(1),
        },
        dropDate: { color: ({ isMouseOver, tag }) => (isMouseOver && tag ? tag?.color : theme.palette.common.black) },
        content: ({ isMouseOver, isReminded, tag }) => ({
            '& button, a': {
                borderColor: isMouseOver ? tag?.color ?? theme.palette.common.black : theme.palette.common.black,
                color: () => {
                    if (isReminded) return theme.palette.common.black;
                    if (isMouseOver) return tag?.color ?? theme.palette.common.black;
                    return theme.palette.common.black;
                },
                '&:hover': {
                    backgroundColor: theme.palette.common.black,
                    color: theme.palette.common.white,
                },
            },
        }),
        featured: {
            fontSize: 28,
            color: THEME_PALETTE.others.orange,
            WebkitTextStrokeColor: theme.palette.grey.A700,
            WebkitTextStrokeWidth: '1px',
        },
    }),
);
