import React, { FC } from 'react';
import { SVGCIconProps } from '../SVGCIcon';

const AddUser: FC<SVGCIconProps> = (props) => {

    const { color = 'black', height = 24, width = 24 } = props;

    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.7499 17.7069C23.7499 20.2545 21.6854 22.3198 19.1387 22.3198C16.592 22.3198 14.5275 20.2545 14.5275 17.7069C14.5275 15.1593 16.592 13.094 19.1387 13.094C21.6854 13.094 23.7499 15.1593 23.7499 17.7069ZM18.9071 18.0809V19.5665H19.5144V18.0809H21V17.4737H19.5144V15.9881H18.9071V17.4737H17.4215V18.0809H18.9071Z"
                fill={color}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.276 16.662C14.1982 16.9978 14.1571 17.3475 14.1571 17.7069C14.1571 18.1673 14.2246 18.612 14.3502 19.0315C13.0798 19.7814 11.6237 20.1836 10.1303 20.1827C8.13027 20.1845 6.19707 19.4627 4.68762 18.1506C5.2675 17.2313 6.07077 16.4739 7.02251 15.9489C7.97425 15.424 9.04343 15.1487 10.1303 15.1487C11.2172 15.1487 12.2864 15.424 13.2381 15.9489C13.608 16.1529 13.9554 16.392 14.276 16.662Z"
                fill={color}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.9873 19.8074C12.7869 20.4023 11.4346 20.7366 10.0042 20.7366C5.04333 20.7366 1.02176 16.715 1.02176 11.7542C1.02176 6.79333 5.04333 2.77176 10.0042 2.77176C14.9651 2.77176 18.9866 6.79333 18.9866 11.7542C18.9866 11.978 18.9784 12.1998 18.9624 12.4195C19.2237 12.424 19.4804 12.4475 19.7312 12.4885C19.7492 12.2461 19.7584 12.0012 19.7584 11.7542C19.7584 6.3671 15.3913 2 10.0042 2C4.6171 2 0.25 6.3671 0.25 11.7542C0.25 17.1413 4.6171 21.5084 10.0042 21.5084C11.5623 21.5084 13.0352 21.143 14.3417 20.4933C14.2076 20.2747 14.089 20.0455 13.9873 19.8074Z"
                fill={color}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.6568 9.04102C13.6568 11.0649 12.0161 12.7056 9.99223 12.7056C7.96833 12.7056 6.32764 11.0649 6.32764 9.04102C6.32764 7.01712 7.96833 5.37643 9.99223 5.37643C12.0161 5.37643 13.6568 7.01712 13.6568 9.04102ZM10.6899 8.17063L9.99224 6.08093L9.29455 8.17063L7.09308 8.18756L8.86103 9.49828L8.20059 11.5981L9.99224 10.3179L11.7839 11.5981L11.1201 9.49828L12.8914 8.18756L10.6899 8.17063Z"
                fill={color}
            />
        </svg>
    );
};

export default AddUser;
