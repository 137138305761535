/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
import React, { FC, useMemo, useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { LocationOnOutlined } from '@material-ui/icons';
import round from 'lodash/round';
import { TBill } from 'Models/Transactions/@types';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import EventsModel, { TEvent } from '../../Models/Event';
import helpers from '../../Utils/helpers';
import usersModel from '../../Models/User';
import Typo from '../../Components/Typo';
import Spacer from '../../Components/Spacer';
import { THEME_PALETTE } from '../../Theme/themeConstants';
import EventModel from '../../Models/Event';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import DropUtils from 'Utils/DropUtils';
import Tag from 'Components/Tag';
import CollaboratorsGroup from 'Features/Collaboration/CollaboratorsGroup';

export interface TicketingEventCardProps {
  variant?: 'paid' | 'free';
  event: TEvent;
  totalTickets?: number;
  bill: TBill | null;
}

const TicketingEventCard: FC<TicketingEventCardProps> = (props) => {
  const { variant = 'free', event, totalTickets = 1, bill } = props;
  const { isDeviceSm } = useMediaQuery();
  const classes = useStyles({ variant, isDeviceSm });

  const data = useMemo(() => {
    const date = event?.featureToggle?.when ? EventModel.getEventDateWithLocation(event, true) : '';
    return {
      showFetauredTag: false,
      name: event?.name,
      imageUrl: helpers.getPictureUrl(event.primaryImage ?? event._primaryImage) || helpers.getPlaceholderImageUrl(),
      tag: EventsModel.getPrimaryTag(event),
      date,
      creatorAvatar: usersModel.getAvatarImage(event.community),
      isFeatured: event.featured,
      subtitle: event.subtitle || '',
      type: event?.eventType,
      platformName: event?.platformName,
      platformUrl: event?.platformUrl,
      city: event?.address?.city || event?.address?.state,
      doc_type: event?.doc_type || 'Event',
      collaborators: EventModel.getColloborators(event),
      taxName: event?.taxInfo?.name ?? '',
      tierType: event?.tierType ?? '',
    };
  }, [event]);

  const getOrderSummary = useCallback(() => {
    const admissionFee = bill?.ticketTierInformation?.price || 0;
    const applicationFee = bill?.applicationFee || 0;
    const discount = bill?.promoCode ? { code: bill.promoCode, amount: bill.discountAmount } : undefined;
    const tax = bill?.taxCharges ?? undefined;

    const items = [
      {
        label: data.tierType === 'multi' ? `${bill?.ticketTierInformation?.name} (${totalTickets}x)` : `General Admission (${totalTickets}x)`,
        price: `$${round(admissionFee * totalTickets, 2)}`,
      },
      { label: `Processing Fees`, price: `$${round(applicationFee, 2)}` },
    ];
    if (discount) items.splice(1, 0, { label: `Discount (${discount.code})`, price: `-$${round(discount.amount, 2)}` });
    if (tax) items.splice(discount ? 2 : 1, 0, { label: data.taxName, price: `$${tax}` });

    const total = `$${round(bill?.total || 0, 2)}`;

    return (
      <Box
        pt={3}
        ml={isDeviceSm ? "0" :"20px"}
        display="flex"
        flex={1}
        flexDirection="column"
        borderTop={`1px solid ${THEME_PALETTE.grey.A300}`}
        width={isDeviceSm ? '100%' : '252px'}
        height="400px"
      >
        <Typo weight="bold">Order Summary</Typo>
        {items.map((item) => (
          <Box my={0.5} display="flex" key={item.label} justifyContent="space-between">
            <Typo>{item.label}</Typo>
            <Typo weight="bold">{item.price}</Typo>
          </Box>
        ))}
        <Box
          mt={2}
          display="flex"
          py="34px"
          borderTop={`1px solid ${THEME_PALETTE.grey.A300}`}
          justifyContent="space-between"
          width={isDeviceSm ? '100%' : '252px'}
        >
          <Typo weight="regular">Total</Typo>
          <Typo weight="bold">{total}</Typo>
        </Box>
      </Box>
    );
  }, [bill, totalTickets]);

  const TicketCard: FC = () => {
    return (
      <Box className={classes.ticketCardRoot}>
        <Box display="flex" p={2.5} className={classes.imageContainer}>
          {data.doc_type && !isDeviceSm? (
            <Box className={classes.eventTag}>
              <PgTypo b1 color="textSecondary">
                {DropUtils.isResourceTypeMatched(data.doc_type, 'collect') ? 'Merch' : data.doc_type}
              </PgTypo>
            </Box>
          ) : null}
          <img src={data.imageUrl} className={classes.img} alt="event-card" />
        </Box>
        <Box px={2} py={3} position="relative">
          {!isDeviceSm && (
            <Box position="absolute" top={-40} right={30}>
              <CollaboratorsGroup collaborators={data.collaborators} />
            </Box>
          )}
          {data.tag ? (
            <Tag size="small" color={data.tag.background}>
              {data.tag.name}
            </Tag>
          ) : null}
          <PgTypo c5>{data.date}</PgTypo>
          <PgTypo c2>{data.name}</PgTypo>
          <PgTypo b6>{data.subtitle}</PgTypo>
          {data.city && data.type === 'offline' ? (
            <Box my={3} display="flex">
              <LocationOnOutlined />
              <PgTypo b5>{data.city}</PgTypo>
            </Box>
          ) : null}
        </Box>
      </Box>
    );
  };

  return (
    <Box className={classes.root} border={`1px solid ${THEME_PALETTE.primary.main}`}>
      <TicketCard />
      {variant === 'free' && !isDeviceSm ? (
        <>
          <Box flex={1} />
          <Box my={3} px={2} display="flex" alignItems="center">
            <PgIcon icon="icon-ticket" />
            <Spacer width={10} />
            <PgTypo b5>FREE</PgTypo>
          </Box>
        </>
      ) : null}
      {variant === 'paid' ? getOrderSummary() : null}
    </Box>
  );
};

const CARD_WIDTH = 290;

const useStyles = makeStyles<Theme, { variant: TicketingEventCardProps['variant']; isDeviceSm: boolean }>((theme) => ({
  root: {
    width: CARD_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      minHeight: 0,
      width: '100%',
      border: 'none',
      padding: theme.spacing(2.5),
    },
  },
  ticketCardRoot: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      border: `1px solid ${theme.palette.common.black}`,
    },
  },
  imageContainer: {
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
  },
  img: {
    width: CARD_WIDTH - theme.spacing(2.5),
    aspectRatio: '1/1',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: 100,
      height: 100,
    },
  },
  avatar: {
    height: 60,
    width: 60,
    position: 'absolute',
    top: -38,
    right: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  testBox: {
    backgroundColor: theme.palette.primary.contrastText,
    // paddingBottom: 0,
  },
  eventTag: {
    position: 'absolute',
    backgroundColor: `rgb(0 0 0 / 50%)`,
    padding: `5px 7px 3px`,
    top: 20,
    left: 20,
  },
}));

export default TicketingEventCard;
