import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import PgTypo from './PgTypo';

export interface ToastMessageProps {
  title: string;
  subtitle?: string;
}

const ToastMessage: FC<ToastMessageProps> = (props) => {
  const { title, subtitle } = props;
  return (
    <span>
      <PgTypo c3 style={{ wordBreak: 'break-word' }}>{title}</PgTypo>
      {subtitle && <PgTypo b6 style={{ wordBreak: 'break-word' }}>{subtitle}</PgTypo>}
    </span>
  );
};

export default ToastMessage;

const useStyles = makeStyles<Theme, any>((theme) => createStyles({}));
