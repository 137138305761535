import { IconButton, InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Clear from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete, { AutocompleteProps as _AutocompleteProps } from '@material-ui/lab/Autocomplete';
import PgIcon from 'Components/PgIcon';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import AxiosUtils from 'Utils';
import helpers from 'Utils/helpers';

type AutocompleteProps<T> = _AutocompleteProps<T, undefined, undefined, undefined>;

export type AutoSuggestProps<T> = TextFieldProps & {
  onResultClick?: (result: T) => void;
  autoCompleteProps: {
    getOptionLabel?: AutocompleteProps<T>['getOptionLabel'];
    getOptionSelected: AutocompleteProps<T>['getOptionSelected'];
    renderOption?: AutocompleteProps<T>['renderOption'];
  };
  label?: string;
  disableClearIcon?: boolean;
  disableSearchIcon?: boolean;
  fetchSuggestions: (inpt: string) => Promise<T[]>;
  retainInputAfterSelect?: boolean;
  textFieldInputProps?: TextFieldProps['InputProps'];
  autoCompleteClasses?: Record<string, string>;
  onClear?: () => void;
  searchAdornment?: JSX.Element;
  inputField?: string;
  setInputField?: (value: string) => void; // Optionally allow moving state up.
  disableAutocomplete?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AutoSuggest = <T extends any>(props: AutoSuggestProps<T>): React.ReactElement => {
  const {
    label,
    disableClearIcon = false,
    disableSearchIcon = false,
    onResultClick,
    autoCompleteProps,
    fetchSuggestions,
    retainInputAfterSelect = true,
    autoCompleteClasses,
    textFieldInputProps,
    onClear,
    searchAdornment,
    inputField,
    setInputField,
    disableAutocomplete,
    ...textFieldProps
  } = props;

  const [result, setResult] = useState<T[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [input, setInput] = useState(inputField ?? '');
  const updateInput = (value: string) => {
    if (setInputField) setInputField(value);
    else {
      setInput(value);
    }
  };

  useEffect(() => {
    updateInput(inputField ?? '');
  }, [inputField]);

  const clearInput = () => {
    if (open) setOpen(false);
    updateInput('');
    setResult([]);
    onClear?.();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!open) setOpen(true);
    setInput(e.target.value);
    // anchorEl || setAnchorEl(e.currentTarget);
  };

  const getSuggestions = useCallback(
    (() => {
      let timeout: NodeJS.Timeout;
      return async (term: string) => {
        if (!term) {
          setResult([]);
          updateInput('');
        }
        updateInput(term);
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(async () => {
          const res = await fetchSuggestions(term).catch(AxiosUtils.throwError);
          setResult(res);
        }, 300);
      };
    })(),
    []
  );

  useEffect(() => {
    if (input) {
      getSuggestions(input);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleResultClick = (item: any) => {
    // Will set the input with the select option
    updateInput(retainInputAfterSelect ? autoCompleteProps.getOptionLabel?.(item) || '' : '');
    if (onResultClick) onResultClick(item);

    setOpen(false);
  };

  const classes = useStyles({ isSafari: helpers.isSafari() });
  const endAdornment = useMemo(() => {
    if (textFieldInputProps?.endAdornment) {
      return textFieldInputProps?.endAdornment;
    }
    return input.length && !disableClearIcon ? (
      <InputAdornment position="end">
        <IconButton onClick={clearInput}>
          <Clear fontSize="small" className={classes.endIcon} />
        </IconButton>
      </InputAdornment>
    ) : (
      searchAdornment ?? null
    );
  }, [textFieldInputProps?.endAdornment, input]);

  return (
    <Autocomplete<T, false, false, true>
      classes={{ popper: classes.popper, ...autoCompleteClasses }}
      filterOptions={(x) => x}
      options={result}
      includeInputInList
      fullWidth
      autoComplete
      open={open && input.length > 0}
      forcePopupIcon={false}
      multiple={false}
      freeSolo
      onChange={(e, v) => {
        handleResultClick(v);
      }}
      onClick={handleResultClick}
      disabled={Boolean(disableAutocomplete)}
      {...autoCompleteProps}
      renderInput={(params) => (
        <TextField
          label={label}
          className={classes.root}
          {...params}
          inputProps={{
            ...params.inputProps,
            onChange: handleInputChange,
            value: setInputField ? inputField : input ?? '',
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            ...params.InputProps,
            ...textFieldInputProps,
            endAdornment,
            startAdornment:
              params.InputProps.startAdornment ?? disableSearchIcon ? null : (
                <InputAdornment position="start" className={classes.adornment}>
                  <IconButton disableRipple disableFocusRipple disableTouchRipple>
                    <PgIcon icon="icon-search" />
                  </IconButton>
                </InputAdornment>
              ),
          }}
          variant="filled"
          {...textFieldProps}
        />
      )}
      renderOption={autoCompleteProps.renderOption}
      getOptionLabel={autoCompleteProps.getOptionLabel}
    />
  );
};

const useStyles = makeStyles<Theme, { isSafari: boolean }>((theme) =>
  createStyles({
    root: {
      '& > div': {
        padding: '2px 8px !important',
      },
      '& input': {
        paddingTop: (props) => (props.isSafari ? '0px !important' : undefined),
        paddingBottom: (props) => (props.isSafari ? '0px !important' : undefined),
      },
    },
    popper: {
      zIndex: theme.zIndex.modal + 10,
    },
    endIcon: {
      cursor: 'pointer',
    },
    adornment: {
      marginTop: '0px !important',
    },
  })
);

export default AutoSuggest;
