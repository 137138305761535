import { Box, IconButton, TextFieldProps } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import FormTextInput from 'Components/Inputs/FormTextInput';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import DialogWrapper from 'Dialogs';
import { Formik, FormikProps } from 'formik';
import useAsyncTask from 'Hooks/useAsyncTask';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { get } from 'lodash';
import { TDiscussion } from 'Models/Discussion/@types';
import React, { FC, useMemo } from 'react';
import { attachField, IFieldProps, IReactFormProps, MLFormBuilder } from 'react-forms';
import helpers from 'Utils/helpers';
import * as Yup from 'yup';
import GenerateStream from './GenerateStream';

const Text: FC<IFieldProps<Partial<TDiscussion>>> = (props) => {
  if (props.formikProps?.values.discussionType !== 'live-stream') return null;
  return (
    <PgTypo b4>The stream will appear also in your community profile to let members know that the streaming has started.</PgTypo>
  )
}

attachField('form-text', <FormTextInput />);
attachField('generate-stream', <GenerateStream />);
attachField('live-stream-text', <Text />);

export interface CreateDiscussionDialogProps {
  onClose: () => void;
  open: boolean;
  onSubmit: (data: Partial<TDiscussion>) => Promise<void>;
}
export const DESCRIPTION_MAX_COUNT = 250;
export const TITLE_MAX_COUNT = 80;

const getHelperText = (fieldName: string, formikProps: FormikProps<Partial<TDiscussion>>, allowedCount: number) => {
  const value: string = get(formikProps, `values.${fieldName}`) || '';
  const remainingCount = allowedCount - (value?.trim?.()?.length || 0);
  const helperText = `${remainingCount} characters remaining`;
  return helperText;
};

const OPTIONS = [
  { name: 'Text (Send messages)', value: 'text' },
  { name: 'Voice (Talk, video and screen share)', value: 'video' },
  { name: 'Live Streaming Broadcast ', value: 'live-stream' },
].filter(f => f) as { name: string, value: TDiscussion['discussionType'] }[]

export const getDiscussionFormConfig = (formikProps: FormikProps<Partial<TDiscussion>>): IReactFormProps['config'] => {
  return [
    {
      type: 'select',
      valueKey: 'discussionType',
      fieldProps: { options: OPTIONS, variant: 'outlined', label: 'Conversation type', disabled: !!formikProps.values.id },
    },
    { type: 'live-stream-text', valueKey: '-' },
    {
      type: 'text',
      valueKey: 'title',
      styles: { marginTop: 32 },
      fieldProps: {
        label: 'Name*',
        placeholder: 'e.g. General',
        fullWidth: true,
        variant: 'outlined',
        helperText: getHelperText('title', formikProps, TITLE_MAX_COUNT),
      } as TextFieldProps,
    },
    {
      type: 'text',
      valueKey: 'description',
      styles: { marginTop: 24 },
      fieldProps: {
        label: 'Description (optional)',
        fullWidth: true,
        variant: 'outlined',
        helperText: getHelperText('description', formikProps, DESCRIPTION_MAX_COUNT),
      },
    },
    { type: 'generate-stream', valueKey: '-' }
  ];
};
const initialValues = {
  title: '',
  description: '',
};
interface CreateDiscussion {
  title: string;
  description: string;
}

const validationSchema = Yup.object({
  title: Yup.string().trim().required('This is required').max(80, 'Maximum 80 characters are allowed'),
  description: Yup.string().trim().max(250, 'Maximum 250 characters are allowed'),
});
const CreateDiscussionDialog: FC<CreateDiscussionDialogProps> = ({ open, onClose, onSubmit }) => {
  const classes = useStyles();
  const handleSubmit = useAsyncTask(onSubmit);
  const { isDeviceSm } = useMediaQuery();
  const actionConfig: IReactFormProps['actionConfig'] = useMemo(() => {
    return {
      submitButtonText: 'CREATE',
      submitButtonLayout: isDeviceSm ? 'fullWidth' : 'right',
      submitButtonProps: {
        variant: 'contained',
        color: 'secondary',
      },
    };
  }, [isDeviceSm]);
  return (
    <DialogWrapper className={classes.root} variant="light" open={open} onClose={onClose}>
      <IconButton className={classes.closeIcon} onClick={onClose}>
        <PgIcon icon="icon-close" />
      </IconButton>
      <PgTypo align="center" variant="h6" className={classes.title}>
        Create a conversation
      </PgTypo>
      {/* <ReactForm
          formId="create-convo"
          isInProgress={handleSubmit.status === 'PROCESSING'}
          config={formConfig}
          actionConfig={actionConfig}
          onSubmit={handleSubmit.run}
          initialValues={initialValues}
          validationSchema={validationSchema}
        /> */}
      <Formik<Partial<CreateDiscussion>>
        onSubmit={handleSubmit.run}
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
      >
        {(formikProps: FormikProps<Partial<CreateDiscussion>>) => {
          const formConfig = getDiscussionFormConfig(formikProps);
          return (
            <MLFormBuilder
              schema={formConfig}
              formId="create-conversation"
              actionConfig={actionConfig}
              formikProps={formikProps}
              isInProgress={handleSubmit.status === 'PROCESSING'}
            />
          );
        }}
      </Formik>
    </DialogWrapper>
  );
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    closeIcon: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    root: {
      zIndex: `${theme.zIndex.modal + 50} !important` as CSSProperties['zIndex'],
      "& [role='dialog']": {
        width: 600,
        padding: theme.spacing(5, 2, 2),
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          height: '100%',
        },
      },
    },
    title: {
      marginBottom: theme.spacing(5),
    },
  });
});
export default CreateDiscussionDialog;
