import { Box, Checkbox, Chip, CircularProgress, createStyles, FormControlLabel, makeStyles, TextField, Theme } from '@material-ui/core';
import clsx from 'clsx';
import PgButton from 'Components/PgButton';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import useAsyncTask from 'Hooks/useAsyncTask';
import React, { FC, useState } from 'react';
import helpers from 'Utils/helpers';
import { InvitationDialogProps } from '..';
import CHECK_BOX_DEFAULT from 'Assets/icons/checkbox/checkbox_default.svg';
import CHECK_BOX_SELECTED from 'Assets/icons/checkbox/checkbox_selected.svg';

export interface EmailInviteProps extends Pick<InvitationDialogProps, 'isMyComm' | 'sendInvites' | 'variant'> { }

const EmailInvite: FC<EmailInviteProps> = (props) => {
    const { sendInvites, variant, isMyComm = false } = props;
    const classes = useStyles();
    const [value, setValue] = useState('');
    const [error, setError] = useState('');
    const [emails, setEmails] = useState<string[]>([]);
    //   const [allowAutoApprove, setAllowAutoApprove] = useState(false);
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(e.target.value);
    };
    const addEmail = (email: string) => {
        if (emails.indexOf(email) === -1) {
            setEmails((e) => [...e, email]);
        }
    };
    const handleSubmit = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            if (!value) return;
            if (helpers.validateEmail(value)) {
                setValue('');
                addEmail(value);
                setError('');
            } else {
                setError('Invalid email');
            }
        }
    };
    const onRemove = (index: number) => {
        setEmails(emails.filter((_, i) => i !== index));
    };
    const handleInviteClick = useAsyncTask(async () => {
        const newEmails = [...emails];
        if (value) {
            if (helpers.validateEmail(value)) {
                newEmails.push(value);
                setError('');
            } else {
                setError('Invalid email');
                return;
            }
        }
        const inviteCount = newEmails.length;
        if (!inviteCount) return;
        // await sendInvites(newEmails, allowAutoApprove);
        await sendInvites(newEmails, true);
        setValue('');
        setEmails([]);
        // setAllowAutoApprove(false);
    });
    // const emailInviteText = variant === 'invite' ? 'Invite via email or share your unique invite link.' : 'Share via email',
    const emailInviteText = variant === 'invite' ? 'Invite via email' : 'Share via email',
        emailActionBtnText = variant === 'invite' ? 'SEND INVITE' : 'SHARE';
    return (
        <div>
            <PgTypo variant="body1">{emailInviteText}</PgTypo>
            <Box className={classes.inputContainers}>
                <Box>
                    <TextField
                        fullWidth
                        value={value}
                        variant="outlined"
                        InputLabelProps={{
                            variant: 'standard',
                        }}
                        InputProps={{
                            className: clsx(classes.textField, classes.inviteTextField),
                            startAdornment: emails.map((item, index) => (
                                <Box mx={0.5} my={1} key={item}>
                                    <Chip
                                        tabIndex={-1}
                                        label={<PgTypo variant="body1">{item}</PgTypo>}
                                        variant="default"
                                        className={classes.chip}
                                        onDelete={() => onRemove(index)}
                                        deleteIcon={
                                            <div>
                                                <PgIcon icon="icon-close" size="small" />
                                            </div>
                                        }
                                    />
                                </Box>
                            )),
                        }}
                        inputProps={{
                            className: classes.inputField,
                        }}
                        error={!!error}
                        helperText={error}
                        onChange={handleChange}
                        onKeyPress={handleSubmit}
                    />
                </Box>
                {/* {!isMyComm ? null : (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={allowAutoApprove}
                                onChange={(_, c) => setAllowAutoApprove(c)}
                                checkedIcon={<img src={CHECK_BOX_SELECTED} alt="checkbox-selected" className={classes.checkBoxIcon} />}
                                icon={<img src={CHECK_BOX_DEFAULT} alt="checkbox-default" className={clsx(classes.checkBoxIcon, classes.uncheckedIcon)} />}
                            />
                        }
                        label={
                            <PgTypo variant="overline" upperCase color="secondary" typoWeight="fontWeightBold">
                                let users join community without verification process
                            </PgTypo>
                        }
                        className={classes.labelRoot}
                    />
                )} */}
                <Box className={classes.actionDiv}>
                    <PgButton onClick={handleInviteClick.run} disabled={!value && !emails.length} fullWidth primary>
                        {handleInviteClick.status === 'PROCESSING' ? <CircularProgress /> : emailActionBtnText}
                    </PgButton>
                </Box>
            </Box>
        </div>
    );
};

export default EmailInvite;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            // background: '#F8F8F8',
            // border: 'none',
            display: 'flex !important',
            flexWrap: 'wrap',
        },
        chip: {
            borderRadius: 4,
            background: theme.palette.common.white,
            color: theme.palette.common.black,
        },
        inviteTextField: {
            padding: `4px 14px`,
        },
        inputField: {
            width: 0,
            flex: '1 1 0%',
            height: 32,
            margin: theme.spacing(1, 0),
            padding: 0,
        },
        inputContainers: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            marginTop: theme.spacing(2),
        },
        actionDiv: {
            width: '100%',
            marginTop: theme.spacing(1),
        },
        rowheight: {
            height: 56,
        },
        checkBoxIcon: { width: 20, height: 20 },
        uncheckedIcon: { filter: theme.palette.type === 'dark' ? 'invert(100%)' : undefined },
        labelRoot: { margin: theme.spacing(2, 0), marginLeft: theme.spacing(-1), [theme.breakpoints.down('sm')]: { alignItems: 'flex-end' } },
    }),
);
