import Axios, { CancelTokenSource } from 'axios';

import { action, Action, thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';

import EventQuery from 'Features/Query/EventQuery';

import EventModel from 'Models/Event';

import { TEvent } from 'Models/Event/@types';

import { parseEvent } from 'Models/Event/eventParsers';
import ResourceModel from 'Models/Resource';
import { TEventResourse } from 'Models/Resource/@types';

// eslint-disable-next-line import/no-cycle
import { TRootStore } from 'Stores';

import { JSONType } from 'Typings/Global';
import RoleUtils from 'Utils/RoleUtils';

export interface EventsState {
  eventList: TEvent[];
  fetchEventList: Thunk<EventsState, EventQuery, null, TRootStore, Promise<TEvent[]>>;
  setEventList: Action<EventsState, { eventList: TEvent[] }>;

  loading: boolean;
  setLoading: Action<EventsState, boolean>;

  total: number;
  setTotal: Action<EventsState, number>;

  searchTerm: string;
  setSearchTerm: Action<EventsState, string>;

  searchQuery: EventQuery;
  setQuery: Action<EventsState, EventQuery>;
  onQueryChange: ThunkOn<EventsState, Record<string, unknown>, TRootStore>;

  drops: TEventResourse[];
  setDropList: Action<EventsState, { drop: TEventResourse[] }>;

  fetchEventResourceList: Thunk<EventsState, { query: EventQuery }, any, TRootStore>;
  deleteResources: Action<EventsState, { ids: string[] }>;
}

let cancelToken: CancelTokenSource | undefined;

const EventsStore: EventsState = {
  searchTerm: '',
  setSearchTerm: action((state, payload) => {
    state.searchTerm = payload;
  }),
  drops: [],
  setDropList: action((state, payload) => {
    state.drops = [...payload.drop];
  }),
  fetchEventResourceList: thunk(async (actions, { query }, { getState, getStoreState }) => {
    actions.setLoading(true);
    const state = getState();
    const { community } = getStoreState().CommunityStore;
    const { appUser } = getStoreState().AuthStore;
    const communityId = community?.id;
    const isSuperAdmin = RoleUtils.isSuperAdmin(appUser);
    const res = await ResourceModel.getUserResourcesAndEvents(undefined, {
      term: query.query.search ?? undefined,
      filter: {
        ...query.searchFilter,
        where: {
          ...query.searchFilter.where,
          // Set communityId if not superadmin
          communityId: isSuperAdmin ? undefined : communityId,
        },
        Event: {
          fields: [
            'name',
            'id',
            'description',
            'tierType',
            '_tiers',
            'slug',
            'eventDates',
            '_primaryImage',
            'tagIds',
            'tags',
            'userId',
            'communityId',
            'frequency',
            'repeatUntil',
            'subtitle',
            'type',
            'platformName',
            'platformUrl',
            'address',
            'rsvpUrl',
            'featured',
            'platform',
            'isFavourited',
            'isReminded',
            'isPublished',
            'reminderId',
            'prices',
            'category',
            'when',
            'favouriteCount',
            'reminderCount',
            'dateChanged',
            'canceled',
            'timeZone',
            'ticketingInfo',
          ] as (keyof TEvent)[],
          include: [
            'tags',
            'ticketingInfo',
            { relation: 'community', scope: { fields: ['_profileImages', 'id', 'name', 'timeZone'] } },
            'includePurchasedTickets',
            // 'includePurchasersMeta',
          ],
        },
        Video: ResourceModel.resourceIncludeFilter,
        Collect: ResourceModel.resourceIncludeFilter,
        Podcast: ResourceModel.resourceIncludeFilter,
        Article: ResourceModel.resourceIncludeFilter,
      },
    });
    const { results, total } = res;

    state.total = total;
    state.drops = [...results.map((i) => i.hit)];
    actions.setLoading(false);
  }),

  eventList: [],

  total: 0,

  loading: false,

  searchQuery: new EventQuery({}, { page: 1 }),

  setEventList: action((state, { eventList }) => {
    state.eventList = eventList;
  }),

  setTotal: action((state, payload) => {
    state.total = payload;
  }),

  setQuery: action((state, payload) => {
    state.searchQuery = payload;
  }),

  setLoading: action((state, payload) => {
    state.loading = payload;
  }),

  deleteResources: action((state, { ids }) => {
    state.drops = state.drops.filter((drop) => !ids.includes(drop.id));
  }),
  fetchEventList: thunk(async (actions, query, { getStoreState }) => {
    actions.setLoading(true);

    const { community } = getStoreState().CommunityStore;

    if (!community) return [];

    query.communityId = community?.id ?? '';

    if (cancelToken) {
      cancelToken.cancel('Operation canceled due to new request.');
    }

    cancelToken = Axios.CancelToken.source();

    const res = await EventModel.searchEvents(
      '',

      {
        Event: {
          include: 'tags',
        },

        ...query.searchFilter,
      },

      { cancelToken: cancelToken.token }
    );

    const events: TEvent[] = [...res.results.map((res: { hit: JSONType }) => parseEvent(res.hit))];

    actions.setTotal(res.total);

    actions.setEventList({ eventList: events });

    actions.setLoading(false);

    return events;
  }),

  onQueryChange: thunkOn(
    (actions) => actions.setQuery,

    async (actions, { payload }) => {
      actions.fetchEventResourceList({ query: payload });
    }
  ),
};

export default EventsStore;
