import { Box, BoxProps, FormControl, FormControlProps, FormHelperText, InputLabel, InputLabelProps, Select, SelectProps } from '@material-ui/core';
import React, { FC } from 'react';

export interface IDOB {
  day: number;
  month: number;
  year: number;
}

export interface DOBSelectorProps {
  helperText?: string;
  error?: string;
  onChange?: (date: IDOB, ChangeEvent: React.ChangeEvent<any>) => void;
  value?: IDOB;
  selectFieldProps?: SelectProps;
  selectFieldLabelProps?: InputLabelProps;
  formControlProps?: FormControlProps;
  containerProps?: BoxProps;
  name?: string;
}

const CURRENT_YEAR = new Date().getFullYear();
const CURRENT_MONTH = new Date().getMonth() + 1;
const CURRENT_DAY = new Date().getDate();

const defaultSelectProps: SelectProps = { fullWidth: true, native: true, variant: 'outlined' };
const defaultFormControlProps: FormControlProps = { fullWidth: true };
const defaultLabelProps: InputLabelProps = { variant: 'outlined' };

const DOBSelector: FC<DOBSelectorProps> = (props) => {
  const {
    error,
    helperText,
    value = { day: CURRENT_DAY, month: CURRENT_MONTH, year: CURRENT_YEAR },
    onChange,
    selectFieldProps = {},
    selectFieldLabelProps = {},
    formControlProps = {},
    containerProps = {},
    name = 'dob-field',
  } = props;

  const onDateChange = (key: keyof IDOB, e: React.ChangeEvent<any>) => {
    onChange?.({ ...value, [key]: parseInt(e.target.value) }, e);
  };

  const selectProps = { ...defaultSelectProps, ...selectFieldProps };
  const _formControlProps = { ...defaultFormControlProps, ...formControlProps };
  const labelProps = { ...defaultLabelProps, ...selectFieldLabelProps };

  return (
    <>
      <Box display="flex" gridGap={16} {...containerProps}>
        <FormControl error={!!error} {..._formControlProps}>
          <InputLabel error={!!error} id="custom-dob-selector-month" {...labelProps}>
            Month
          </InputLabel>
          <Select
            name={`${name}-month`}
            labelId="custom-dob-selector-month"
            value={value.month}
            onChange={(e) => onDateChange('month', e)}
            {...selectProps}
          >
            {MONTHS.map((m) => (
              <option key={m.value} value={m.value}>
                {m.name}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl error={!!error} {..._formControlProps}>
          <InputLabel error={!!error} id="custom-dob-selector-days" {...labelProps}>
            Day
          </InputLabel>
          <Select name={`${name}-day`} labelId="custom-dob-selector-days" value={value.day} onChange={(e) => onDateChange('day', e)} {...selectProps}>
            {Array.from({ length: 31 }).map((_, i) => {
              const item = i + 1;
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </Select>
        </FormControl>

        <FormControl error={!!error} {..._formControlProps}>
          <InputLabel error={!!error} id="custom-dob-selector-year" {...labelProps}>
            Year
          </InputLabel>
          <Select
            name={`${name}-year`}
            labelId="custom-dob-selector-year"
            value={value.year}
            onChange={(e) => onDateChange('year', e)}
            {...selectProps}
          >
            {Array.from({ length: 120 }).map((_, i) => {
              const item = CURRENT_YEAR - i;
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </Select>
        </FormControl>
      </Box>

      {!helperText && !error ? null : <FormHelperText error={!!error}>{error || helperText}</FormHelperText>}
    </>
  );
};

export default DOBSelector;

const MONTHS = [
  { name: 'Jan', value: 0 },
  { name: 'Feb', value: 1 },
  { name: 'Mar', value: 2 },
  { name: 'Apr', value: 3 },
  { name: 'May', value: 4 },
  { name: 'Jun', value: 5 },
  { name: 'Jul', value: 6 },
  { name: 'Aug', value: 7 },
  { name: 'Sep', value: 8 },
  { name: 'Oct', value: 9 },
  { name: 'Nov', value: 10 },
  { name: 'Dec', value: 11 },
];
