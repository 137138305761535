/* eslint-disable no-underscore-dangle */
import { TFilter } from 'Models/App/@types';
import qs from 'qs';
import PaginationQuery, { TPaginationQuery } from './PaginationQuery';

export type TResourceQuery = {
  tagIds?: string[];
  sort?: string;
  availability?: string[];
  collectType?: string[];
};

export enum ResourceSort {
  recent = 'recent',
  oldest = 'oldest',
}

// export type TEventSortOption = 'recent' | 'oldest';

const sortMapping: Record<string, string> = {
  [ResourceSort.oldest]: 'asc',
  [ResourceSort.recent]: 'desc',
};

export type ResourceDocType = 'Video' | 'Podcast' | 'Article' | 'Collect';

class ResourceQuery extends PaginationQuery {
  private _query: TResourceQuery = {};

  private _userId = '';

  private _communityId = '';

  set communityId(id: string) {
    this._communityId = id;
  }

  private _docType: ResourceDocType = 'Video';

  constructor(query: TResourceQuery, paginationQuery: TPaginationQuery) {
    super(paginationQuery);
    this._query = query;
  }

  set userId(userId: string) {
    this._userId = userId;
  }

  set docType(docType: ResourceDocType) {
    this._docType = docType;
  }

  get query() {
    return this._query;
  }

  static fromQuery(query: string) {
    const { page, ...eventQuery } = qs.parse(query, {
      ignoreQueryPrefix: true,
    }) as Record<string, string>;
    const paginationQuery: TPaginationQuery = { page: page ? Number(page) : 1 };
    return new ResourceQuery(eventQuery, paginationQuery);
  }

  get searchFilter() {
    const _filter: TFilter = {
      ...this.paginationFilter,
      sort: [
        {
          created: {
            order: sortMapping[this._query.sort || ResourceSort.recent],
          },
        },
      ],
      where: {
        doc_type: this._docType,
        communityId: this._communityId,
        tagIds: this._query.tagIds,
        availability: this._query.availability,
        collectType: this._query.collectType,
      },
    };

    return _filter;
  }

  clone(query: TResourceQuery, paginationQuery: TPaginationQuery) {
    return new ResourceQuery(
      {
        // priceCategory: query.priceCategory ?? this._query.priceCategory,
        tagIds: query.tagIds ?? this._query.tagIds,
        sort: query.sort ?? this._query.sort,
        availability: query.availability ?? this._query.availability,
        collectType: query.collectType ?? this._query.collectType,
      },
      { page: paginationQuery.page ?? this.paginationQuery.page }
    );
  }

  get queryString() {
    const finalQuery = { ...this.query, ...this.paginationQuery };
    return qs.stringify(finalQuery);
  }
}

export default ResourceQuery;
