import React, { FC, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import NotificationPreferenceForm from 'Forms/NotificationPreferenceForm';
import { Box, Checkbox, FormControlLabel, makeStyles, Theme, Divider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import AccountNotificationsViewModel, { AccountNotificationsViewModelState } from './AccountNotificationsViewModel';
import withViewModel from 'Core/withViewModel';
import { NotificationPreferences } from 'Models/App/@types';
import FormSectionHeader from 'Forms/FormSectionHeader';
import { COLOR, THEME_PALETTE } from 'Theme/themeConstants';
import PgIcon from 'Components/PgIcon';
import { MUICheckBox } from 'react-forms';
import PgTypo from 'Components/PgTypo';
import PgButton from 'Components/PgButton';
import { useStoreActions, useStoreState } from 'Stores';
import useAsyncTask from 'Hooks/useAsyncTask';
import useAuthDialog from 'Features/Auth/useAuthDialog';
import { User } from 'Models/User';
import useToastMessage from 'Hooks/useToastMessage';
import MESSAGES from 'Utils/Messages';
import helpers from 'Utils/helpers';

export interface AccountNotificationsProps extends AccountNotificationsViewModelState {}

const AccountNotificationSkeleton = () => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection={'row'} width="100%" p={2} className={classes.skeletonContainer}>
      <Skeleton className={classes.skeletonLayout} variant="rect" width="70%" height={40} />
      <Skeleton className={classes.skeletonLayout} variant="rect" width="10%" height={40} />
      <Skeleton className={classes.skeletonLayout} variant="rect" width="10%" height={40} />
    </Box>
  );
};

const AccountNotifications: FC<AccountNotificationsProps> = ({
  isLoading,
  isSubmitting,
  handleSubmit,
  notificationConfigurations,
  // In the next phases we will need to use notificationConfigurations to check which notifications are editable and which ones are not. That isn't needed as of now. Hence notificationConfigurations is unused.
  notificationPreferences,
}) => {
  const [formData, setFormData] = useState(notificationPreferences);
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
  const { updateUser, setAppUser } = useStoreActions(({ AuthStore: { updateUser, setAppUser } }) => ({ updateUser, setAppUser }));
  const { openDialog } = useAuthDialog();
  // const [isAllSelected, setIsAllSelected] = useState({
  //   email: !!(formData?.conversation_another_user_comment_channel_email && formData.conversation_reply_on_comment_channel_email),
  //   web: !!(formData?.conversation_another_user_comment_channel_web && formData.conversation_reply_on_comment_channel_web),
  // });

  // const [isConsentChecked, setConsentChecked] = useState(true);
  useEffect(() => {
    setFormData(notificationPreferences);

    return () => {};
  }, [notificationPreferences]);

  const handleSelectAllWeb = (val: boolean) => {
    const data: NotificationPreferences = {
      conversation_another_user_comment_channel_web: val,
      conversation_reply_on_comment_channel_web: val,
      community_new_comment_channel_web: val,
      community_new_comment_on_drop_channel_web: val,
      community_ecosystem_invite_accepted_channel_web: val,
      community_ecosystem_invite_channel_web: val,
      connection_request_invite_channel_web: val,
      mentioned_in_comment_channel_web: val,
      mentioned_in_drop_comment_channel_web: val,
      replied_on_drop_mentioned_comment_channel_web: val,
      replied_on_mentioned_comment_channel_web: val,
      replied_to_drop_mentioned_comment_channel_web: val,
      replied_to_mentioned_comment_channel_web: val,
    };
    handleSubmit?.({ ...formData, ...data });
    setFormData((prev) => ({ ...prev, ...data }));
  };

  // const handleSelectAllEmail = (val: boolean) => {
  //   setIsAllSelected((prev) => ({
  //     ...prev,
  //     email: val,
  //   }));
  //   setFormData((prev) => ({
  //     ...prev,
  //     conversation_another_user_comment_channel_email: val,
  //     conversation_reply_on_comment_channel_email: val,
  //   }));
  // };

  // const submit = () => {
  //   if (handleSubmit) handleSubmit(formData || {});
  // };

  const withToast = useToastMessage();

  const handleSmsToggle = useAsyncTask(async (isCheked: boolean) => {
    if (!appUser) return;
    if (!appUser.phone?.phone || !appUser.phoneVerified) {
      openDialog('verifyPhone', { phone: appUser.phone, hideDontAskOption: true });
      return;
    }
    await withToast(
      async () => {
        try {
          await updateUser({ receiveRsvpOn: { ...appUser.receiveRsvpOn, sms: isCheked }, id: appUser.id } as Partial<User> & { id: string });
          setAppUser({ ...appUser, receiveRsvpOn: { ...appUser.receiveRsvpOn, sms: isCheked } } as User);
        } catch (error) {
          console.error(error);
        }
      },
      { successToastMessage: MESSAGES.NOTIFICATION_PREFERENCE_SUCCESS_TITLE },
    );
  });

  if (isLoading) {
    return <AccountNotificationSkeleton />;
  }
  return (
    <Box width="100%" p={2}>
      <Box display="flex" flexDirection="row" flexBasis={0}>
        <Box display="flex" flexBasis={'70%'}>
          <FormSectionHeader
            fieldProps={{
              title: 'Apply to all notifications',
              style: {
                marginTop: 10,
                color: THEME_PALETTE.grey.A800,
              },
              subtitleStyle: {
                marginTop: 0,
              },
            }}
          />
        </Box>
        <Box display="flex" flexBasis={'30%'} justifyContent="flex-end">
          {/* <FormControlLabel
            control={
              <Checkbox
                disabled
                checked={formData.conversation_another_user_comment_channel_email &&
formData.conversation_reply_on_comment_channel_email}
                onChange={(event) => handleSelectAllEmail(event.target.checked)}
                icon={<PgIcon icon="icon-checkbox-off" color="primary" />}
                checkedIcon={<CustomNotificationCheckbox />}
              />
            }
            label={
              <PgTypo c5 color="primary">
                EMAIL
              </PgTypo>
            }
          /> */}

          <FormControlLabel
            control={
              <Checkbox
                checked={!!(appUser?.receiveRsvpOn?.sms && appUser.phone?.phone && appUser.phoneVerified)}
                onChange={(event) => handleSmsToggle.run(event.target.checked)}
                icon={<PgIcon icon="icon-checkbox-off" />}
                checkedIcon={<CustomNotificationCheckbox />}
                disabled={handleSmsToggle.status === 'PROCESSING'}
              />
            }
            label={
              <PgTypo c5 color="primary">
                SMS
              </PgTypo>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData?.conversation_another_user_comment_channel_web && formData?.conversation_reply_on_comment_channel_web}
                onChange={(event) => handleSelectAllWeb(event.target.checked)}
                icon={<PgIcon icon="icon-checkbox-off" />}
                checkedIcon={<CustomNotificationCheckbox />}
                disabled={isSubmitting}
              />
            }
            label={
              <PgTypo c5 color="primary">
                WEB
              </PgTypo>
            }
          />
        </Box>
      </Box>
      <Divider color={THEME_PALETTE.grey.A800} style={{ marginTop: 10, marginBottom: 12 }} />
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={isConsentChecked}
            onChange={(event) => setConsentChecked(event.target.checked)}
            icon={<PgIcon icon="icon-checkbox-off" />}
            checkedIcon={<CustomNotificationCheckbox />}
          />
        }
        label={
          <PgTypo b2 typoColor="default">
            I consent to be notified by email and or SMS if required for events coming up, or invitations.
          </PgTypo>
        }
      /> */}
    </Box>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  skeletonContainer: {
    gap: '5px',
  },
  skeletonLayout: {
    margin: '0 auto',
  },
  gridItem: {
    margin: theme.spacing(2, 0),
  },
}));

export default withViewModel(AccountNotifications, new AccountNotificationsViewModel());

export const CustomNotificationCheckbox = () => {
  return (
    <Box
      width={22}
      height={22}
      border={`1px solid ${COLOR.tertiary.music}`}
      bgcolor={COLOR.tertiary.music}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <PgIcon icon="icon-checkbox-on" />
    </Box>
  );
};
