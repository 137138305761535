import axios from "axios"
import { TPurchasedTicket } from "./@types"


class PurchasedTicketModel {
    static baseUrl = '/purchasedTickets'
    static getPurchasedTicketDetail(id: string) {
        return axios.request<TPurchasedTicket>({
            url: `${this.baseUrl}/${id}/details`,
            method: 'get',
        })
    }
}

export default PurchasedTicketModel