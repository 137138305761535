/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
import { alpha, Box, ButtonBase, List, ListItem, Tooltip } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ChevronLeft } from '@material-ui/icons';
import ToRight from 'Assets/icons/sidebar/to_right.svg';
import clsx from 'clsx';
import Onboarding, { OnboardState } from 'Components/Onboarding';
// import PgButton from 'Components/PgButton';
import PgIcon from 'Components/PgIcon';
import PgTypo from 'Components/PgTypo';
import Spacer from 'Components/Spacer';
// import { DialogContext } from 'Dialogs/DialogContext';
import SidebarDiscussionMenu from 'Features/Discussion/SidebarDiscussionMenu';
import OnboardingPopup from 'Features/Popover/OnboardingPopup';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CallBackProps, TooltipRenderProps } from 'react-joyride';
import { generatePath, Link, useHistory, useLocation } from 'react-router-dom';
import { useStoreState } from 'Stores';
import { BORDER_WHITE } from 'Theme/themeConstants';
import { links } from './Navs';
import AddDropButton from 'Features/Drops/AddDropButton';
import UserAvatar from 'Components/UserAvatar';
import { commOnboardStepsCls, communityOnboardSteps, hideOnboard, isOnboardHidden } from 'Constants/onboardingContent';
import DashboardRoutes from 'Routes/DashboardRoutes';

export interface ManagedCommunityProps {
    onBack?: () => void;
    onChange: (open: boolean) => void;
}

const ManagedCommunity: FC<ManagedCommunityProps> = (props) => {
    const { onBack, onChange } = props;

    const { open, community, appUser } = useStoreState(({ DrawerSidebarStore: { open }, CommunityStore: { community }, AuthStore: { appUser } }) => ({
        open,
        community,
        appUser,
    }));

    const { pathname } = useLocation();
    const history = useHistory();

    const [onboardState, setOnboardState] = useState<OnboardState>({
        run: false,
        steps: [],
    });
    const handleJoyrideCallback = (data: CallBackProps) => {
        // const { status, type } = data;
        // const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
        // if (finishedStatuses.includes(status)) {
        //   setOnboardState(e => ({ ...e,run: false }));
        // }
    };
    const [step, setStep] = useState(0);

    const hideOnboarding = () => {
        setOnboardState({ run: false, steps: [] });
        hideOnboard('manage-comm-sidebar');
        // localStorage.setItem(`${appUser?.id || ''}_onboard`, 'closed');
    };

    const handleEditCommClick = () => {
        hideOnboarding();
        history.push(generatePath(DashboardRoutes.EDIT_COMMUNITY.path, { communityId: community?.slug ?? community?.id ?? '#' }));
    }

    const onboardSteps = useMemo(() => communityOnboardSteps(handleEditCommClick), [communityOnboardSteps, handleEditCommClick]);

    const showOnboardingDialog = () => {
        const communityCount = appUser?.userCommunities?.length || 0;
        // if (isOnboardHidden(appUser?.id ?? '') || communityCount !== 1) return;
        if (!isOnboardHidden('manage-comm-sidebar') && communityCount)
            setTimeout(() => {
                setOnboardState((e) => ({
                    run: true,
                    steps: onboardSteps.map((i, ind) => ({
                        target: i.target,
                        content: '',
                        placement: ind > 1 ? 'right-end' : 'right-start',
                        disableBeacon: ind === 0,
                    })),
                }));
            }, 1500);
    };

    const onNext = () => {
        const currStep = step + 1;
        if (currStep === onboardSteps.length) {
            hideOnboarding();
        } else {
            setStep(currStep);
        }
    };

    const ToolTip = ({ index, tooltipProps }: TooltipRenderProps) => (
        <div {...tooltipProps}>
            <OnboardingPopup onClick={onNext} onClose={hideOnboarding} disableSkip={(index + 1) === onboardSteps.length} content={{ ...onboardSteps[index] }} />
        </div>
    );

    useEffect(() => {
        if (community?.id && appUser?.id) {
            showOnboardingDialog();
        }
    }, [appUser?.id, community?.id]);

    // const { addContent } = useContext(DialogContext);

    const classes = useStyles({ open });

    const handleLinkClick = (link: string) => {
        history.push(link);
    };

    const data = useMemo(
        () => ({
            name: community?.name || '',
            // eslint-disable-next-line no-underscore-dangle
            image: community?._profileImages?.[0]?.url,
            id: `/${community?.slug || community?.id}`,
        }),
        [community],
    );

    const withToolTip = useCallback(
        (child: JSX.Element, title: string, key?: any) => (
            <Tooltip key={key} title={title} placement="right" arrow>
                {child}
            </Tooltip>
        ),
        [],
    );
    const isDisabled = useMemo(() => community?.isDeactivated || community?.isDeletionRequested, [community]);

    const filteredLinks = useMemo(() => {
        if (isDisabled) return links.filter((i) => i.allowForDeactivated);
        return links;
    }, [community, isDisabled]);

    return (
        <ListItem style={{ padding: 0 }}>
            <Onboarding
                callback={handleJoyrideCallback}
                run={onboardState.run}
                scrollOffset={300}
                stepIndex={step}
                steps={onboardState.steps}
                tooltipComponent={ToolTip}
                spotlightPadding={0}
                disableScrollParentFix
                styles={{ options: { zIndex: 10000 }, tooltip: { width: 'auto' }, spotlight: { height: 56 } }}
            />
            {open ? (
                <List style={{ padding: 0, width: '100%' }}>
                    {/* <Box width={'100%'} px={2} style={{ borderBottom: `1px solid #000` }}> */}
                    <div className={commOnboardStepsCls.manageComm}>
                        <ListItem button className={classes.listItem} onClick={onBack}>
                            <Box display="flex" alignItems="center">
                                <ChevronLeft className={classes.leftIcon} />
                                <PgTypo c4>Communities I Manage</PgTypo>
                            </Box>
                        </ListItem>
                        <ListItem
                            component={Link}
                            to={data.id}
                            className={clsx(classes.listItem, {
                                [classes.itemActive]: pathname === data.id,
                            })}
                            style={{ borderBottom: BORDER_WHITE }}
                        >
                            <Box display="flex" alignItems="center">
                                <UserAvatar size={40} name={data.name} url={data.image} />
                                <PgTypo c3 style={{ marginLeft: 16 }}>
                                    {data.name}
                                </PgTypo>
                            </Box>
                        </ListItem>
                    </div>
                    {!isDisabled && (
                        <div className={commOnboardStepsCls.commChat}>
                            <SidebarDiscussionMenu />
                        </div>
                    )}
                    {/* </Box> */}
                    {/* <Box width={'100%'} px={2}> */}
                    <ListItem className={classes.listItem}>
                        <PgTypo c2>Tools</PgTypo>
                    </ListItem>
                    <ListItem>
                        {/* <PgButton className="add-drop" primary disabled={isDisabled} onClick={addContent} fullWidth>
              ADD DROP
            </PgButton> */}
                        <AddDropButton />
                    </ListItem>
                    {filteredLinks.map((l) => {
                        if (l.component) return null;
                        return (
                            <ListItem
                                className={clsx(classes.listItem, l.id, {
                                    [classes.itemActive]: pathname === l.link,
                                    [commOnboardStepsCls.editComm]: l.id === 'profile',
                                    [commOnboardStepsCls.commIntegration]: l.id === 'integrations',
                                })}
                                button
                                key={l.id}
                                onClick={() => handleLinkClick(generatePath(l.link, { communityId: community?.slug || community?.id }))}
                            >
                                {l.img
                                    ? <img
                                        src={l.img}
                                        alt={l.label}
                                        className={clsx(classes.icon, { [classes.integrationIcon]: l.id === 'integrations' })}
                                        width={24}
                                    />
                                    : <PgIcon icon={l.icon} styleClass={classes.icon} />}
                                <Spacer width={10} />
                                <PgTypo b4={pathname !== l.link} c4={pathname === l.link}>
                                    {l.label}
                                </PgTypo>
                            </ListItem>
                        );
                    })}
                    {/* </Box> */}
                </List>
            ) : (
                <Box width="100%" display="flex" flexDirection="column" alignItems="center">
                    <img src={ToRight} alt="to-right" className={classes.toRight} onClick={() => onChange(!open)} />

                    {withToolTip(
                        <Box
                            width="100%"
                            component={ButtonBase}
                            className={clsx(classes.avatarContainer, {
                                [classes.itemActive]: pathname === data.id,
                            })}
                            onClick={() => handleLinkClick(data.id)}
                        >
                            <UserAvatar size={40} name={data.name} url={data.image} />
                        </Box>,
                        data.name || '',
                    )}

                    <Box width="100%" borderBottom={BORDER_WHITE} />
                    {!isDisabled && withToolTip(<SidebarDiscussionMenu />, 'Conversations')}

                    {withToolTip(
                        <Box py={2}>
                            {/* <PgButton primary disabled={isDisabled} onClick={addContent} className={classes.btn}>
                <Add htmlColor={THEME_PALETTE.common.black} style={{ fontSize: 25 }} />
              </PgButton> */}
                            <AddDropButton />
                        </Box>,
                        'Add Drop',
                    )}
                    <Box width="100%" borderBottom={BORDER_WHITE} />
                    {filteredLinks.map((l) => {
                        if (l.component) return null;
                        return withToolTip(
                            <Box
                                width="100%"
                                display="flex"
                                justifyContent="center"
                                className={clsx(classes.iconContainer, {
                                    [classes.itemActive]: pathname === l.link,
                                })}
                                onClick={() => handleLinkClick(generatePath(l.link, { communityId: community?.slug || community?.id }))}
                            >
                                {l.img
                                    ? <img
                                        src={l.img}
                                        alt={l.label}
                                        className={clsx(classes.icon, { [classes.integrationIcon]: l.id === 'integrations' })}
                                        width={24}
                                    />
                                    : <PgIcon icon={l.icon} styleClass={classes.icon} />}
                            </Box>,
                            l.tooltip,
                            l.id,
                        );
                    })}
                </Box>
            )}
        </ListItem>
    );
};

export default ManagedCommunity;

const useStyles = makeStyles<Theme, { open: boolean }>((theme) =>
    createStyles({
        avatar: {
            marginTop: (props) => (props.open ? 0 : 16),
            marginBottom: (props) => (props.open ? 0 : 16),
            cursor: 'pointer',
            width: 40,
            height: 40,
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 40 / 3,
        },
        btn: {
            marginTop: (props) => (props.open ? 0 : 16),
            marginBottom: (props) => (props.open ? 0 : 16),
            fontSize: (props) => (props.open ? 16 : 24),
            minWidth: (props) => (props.open ? '' : 40),
            width: (props) => (props.open ? '' : 40),
            height: (props) => (props.open ? '' : 40),
            // '& > .MuiButton-label': {
            //   marginBottom: (props) => (props.open ? '' : 8),
            // },
        },
        iconContainer: {
            '&:hover': {
                backgroundColor: alpha(theme.palette.common.black, 0.04),
            },
        },
        icon: {
            // marginTop: 32,
            marginTop: (props) => (props.open ? 0 : 16),
            marginBottom: (props) => (props.open ? 0 : 16),
            cursor: 'pointer',
            '& path': { fill: theme.palette.primary.main },
        },
        listItem: {
            padding: theme.spacing(2),
        },
        toRight: {
            marginTop: theme.spacing(4),
            cursor: 'pointer',
            filter: theme.palette.type === 'dark' ? 'invert(100%)' : undefined,
        },
        itemActive: {
            backgroundColor: alpha(theme.palette.common.black, 0.04),
        },
        leftIcon: { color: theme.palette.primary.main },
        integrationIcon: { filter: theme.palette.type === 'dark' ? 'invert(100%)' : undefined },
        avatarContainer: { marginTop: theme.spacing(3), padding: theme.spacing(2, 0) },
    }),
);

// export const OnboardingSteps: { title: string; body: string; actionText: string; target: string }[] = [
//   {
//     target: '.add-drop',
//     actionText: 'HOT 🔥',
//     body: `Event? Merch sale? Book launch? NFT release? Drop it on your profile`,
//     title: `Hype what's happening`,
//   },
//   {
//     target: '.conversations',
//     actionText: 'TTYL',
//     title: `Get people talking 💬`,
//     body: `Create hype, share updates, or discuss the latest arthouse film with your community.`,
//   },
//   {
//     title: `Your apps + Playground = 🚀`,
//     body: 'Bring your favorite tools together in a few clicks.',
//     actionText: 'AWESOME 😎',
//     target: '.integrations',
//   },
//   {
//     title: `Click here to learn the secrets of the universe 🔮`,
//     actionText: 'OOH',
//     body: `JK. But you'll learn loads about your community.`,
//     target: '.insights',
//   },
// ];
export const MobileOnboardingSteps: { title: string; body: string; actionText: string; target: string }[] = [
    {
        target: '.add-drop',
        actionText: 'HOT 🔥',
        body: `Event? Merch sale? Book launch? NFT release? Drop it on your profile`,
        title: `Hype what's happening`,
    },
    // {
    //   target: '.conversations',
    //   actionText: 'TTYL',
    //   title: `Get people talking 💬`,
    //   body: `Create hype, share updates, or discuss the latest arthouse film with your community.`,
    // },
    {
        title: `Your apps + Playground = 🚀`,
        body: 'Bring your favorite tools together in a few clicks.',
        actionText: 'AWESOME 😎',
        target: '.integrations',
    },
    {
        title: `Click here to learn the secrets of the universe 🔮`,
        actionText: 'OOH',
        body: `JK. But you'll learn loads about your community.`,
        target: '.insights',
    },
];
