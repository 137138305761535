import React, { FC } from 'react';
import { ReactForm } from 'react-forms';
import { useFormConfig } from './config';

export interface ResetPasswordFormData {
  password: string;
  repeatPassword: string;
}

export interface IProps {
  onSubmit: (data: ResetPasswordFormData) => void;
  initialData?: Partial<ResetPasswordFormData>;
  isSubmitting?: boolean;
  onBack?: (email: string) => void;
}

export const ResetPasswordForm: FC<IProps> = (props) => {
  const { actionConfig, validationSchema, inputConfig } = useFormConfig(props);
  const { onSubmit, isSubmitting } = props;
  return (
    <ReactForm
      formId="reset-password"
      config={inputConfig}
      actionConfig={actionConfig}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      formSettings={{
        verticalSpacing: 20,
      }}
      isInProgress={isSubmitting}
    />
  );
};

export default ResetPasswordForm;
