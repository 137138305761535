/* eslint-disable no-underscore-dangle */
import qs from 'qs';
import PaginationQuery, { TPaginationQuery } from './PaginationQuery';
export enum LocationOption {
  all = 'all',
  unknown = 'Unknown',
}
export type TCommunityUserQuery = {
  sort?: string;
  searchTerm?: string;
  userType?: string[];
  location?: string[];
};

export enum CommunityUserSort {
  recent = 'recent',
  oldest = 'oldest',
  nameAsc = 'nameAsc',
  nameDesc = 'nameDesc',
}

export enum CommunityUserType {
  attendees = 'attendees',
  subscribers = 'subscribers',
  bannedFromCommunity = 'bannedFromCommunity',
  invitees = 'invited',
  expiredInvites = 'expiredInvites',
  unregisteredInvitees = 'unregistered_invitees',
  unregisteredExpiredInvitees = 'unregistered_expired_invitees'
}

const userTypeMapping: Record<string, string> = {
  [CommunityUserType.attendees]: 'attendees',
  [CommunityUserType.subscribers]: 'subscribers',
  [CommunityUserType.bannedFromCommunity]: 'bannedFromCommunity',
  [CommunityUserType.invitees]: 'invitees',
  [CommunityUserType.expiredInvites]: 'expiredInvites',
  [CommunityUserType.unregisteredInvitees]: 'unregistered_invitees',
  [CommunityUserType.unregisteredExpiredInvitees]: 'unregistered_expired_invitees',
};

const sortMapping: Record<string, string> = {
  [CommunityUserSort.oldest]: 'oldest',
  [CommunityUserSort.recent]: 'mostRecent',
  [CommunityUserSort.nameAsc]: 'az',
  [CommunityUserSort.nameDesc]: 'za',
};

class CommunityUserQuery extends PaginationQuery {
  totalLocations: number = 0;
  private _query: TCommunityUserQuery = {};

  constructor(query: TCommunityUserQuery, paginationQuery: TPaginationQuery) {
    super(paginationQuery);
    // eslint-disable-next-line no-underscore-dangle
    this._query = query;
  }

  get query() {
    // eslint-disable-next-line no-underscore-dangle
    return this._query;
  }

  static fromQuery(query: string, totalLocations?: number) {
    const { page, ...eventQuery } = qs.parse(query, {
      ignoreQueryPrefix: true,
    }) as Record<string, string>;
    const paginationQuery: TPaginationQuery = { page: page ? Number(page) : 1 };
    const newCommunityUserQuery = new CommunityUserQuery(eventQuery, paginationQuery);
    newCommunityUserQuery.totalLocations = totalLocations || 0;
    return newCommunityUserQuery;
  }

  get searchTerm() {
    return this._query.searchTerm || '';
  }

  get searchFilter() {
    // UserType filter ------
    let userFilter: string[] | undefined;
    // eslint-disable-next-line no-underscore-dangle
    if (this._query.userType?.length) {
      // eslint-disable-next-line no-underscore-dangle
      userFilter = this._query.userType.map((t) => userTypeMapping[t]);
    }
    // ----------------------

    let city: string[] | undefined;
    // if (this.totalLocations + 1 === this._query.location?.length) {
    //   city = undefined;
    //   // eslint-disable-next-line no-underscore-dangle
    // } else if (this._query.location?.length) {
    //   // eslint-disable-next-line no-underscore-dangle
    //   city = [...this._query.location.filter((loc) => loc !== LocationOption.all)];
    // }
    if (this._query.location?.length) {
      // eslint-disable-next-line no-underscore-dangle
      city = [...this._query.location.filter((loc) => loc !== LocationOption.all)];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _filter: Record<string, any> = {
      ...this.paginationFilter,
      sort: [sortMapping[this._query.sort || CommunityUserSort.nameAsc]],
      userFilter,
      city,
    };

    return _filter;
  }

  clone(query: TCommunityUserQuery, paginationQuery: TPaginationQuery) {
    const newCommunityUserQuery = new CommunityUserQuery(
      {
        searchTerm: query.searchTerm ?? this._query.searchTerm,
        sort: query.sort ?? this._query.sort,
        location: query.location ?? this._query.location,
        userType: query.userType ?? this.query.userType,
      },
      { page: paginationQuery.page ?? this.paginationQuery.page }
    );
    newCommunityUserQuery.totalLocations = this.totalLocations;
    return newCommunityUserQuery;
  }

  get queryString() {
    const finalQuery = { ...this.query, ...this.paginationQuery };
    return qs.stringify(finalQuery);
  }
}

export default CommunityUserQuery;
