import PgButton from 'Components/PgButton';
import PgDialog from 'Components/PgDialog';
import React, { FC } from 'react';
import { TAuthDialogContentProps } from '../@types';

const VerifyEmailFirst: FC<TAuthDialogContentProps> = (props) => {
  const { onDone, meta } = props;
  return (
    <PgDialog
      heading="Verify your account first."
      body={meta?.subtitle ?? 'You must verify your account via email before you can create a community.'}
      actionButtons={
        <>
          <PgButton secondary fullWidth onClick={onDone}>
            Got it
          </PgButton>
        </>
      }
      positive={false}
      negative={false}
    />
  );
};

export default VerifyEmailFirst;
