import ViewModel from 'Core/@types';
import useAsyncTask from 'Hooks/useAsyncTask';
import EventModel from 'Models/Event';
import { TEvent } from 'Models/Event/@types';
import { TicketTier } from 'Models/Event/ticketing/@types';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useStoreState } from 'Stores';

const EVENT_PARAMS_FILTER = {
  filter: {
    include: [
      'tags',
      'tiers',
      { relation: 'community' },
      'includePurchasedTickets',
      'includePurchasersMeta',
      'collaborators',
      'collaborationRequestInfo',
    ],
  },
};
const useEvent = (): EventViewModelState => {
  const [eventDetail, setEventDetail] = useState<TEvent>();
  const [ticketsInfo, setTicketsInfo] = useState<TicketTier[]>();
  const { id } = useParams<{ id: string }>();

  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));

  const getEventDetail = useAsyncTask<void>(async () => {
    const eventDetail = await EventModel.getSingleEvent(id, EVENT_PARAMS_FILTER);
    setEventDetail(eventDetail);
    const ticketsInfo = await EventModel.getAvailableTickets(eventDetail?.id as string);
    setTicketsInfo(ticketsInfo);
  });
  const isMyDrop = useMemo(
    () => (appUser?.id === eventDetail?.userId && !!eventDetail?.userId) || (appUser?.id === eventDetail?.curatorId && !!eventDetail?.curatorId),
    [appUser?.id, eventDetail?.curatorId, eventDetail?.userId]
  );

  useEffect(() => {
    if (id) {
      refreshStealth();
    }
  }, [appUser?.id, id]);

  const refreshStealth = () => {
    getEventDetail.run();
  };

  return { eventDetail, ticketsInfo, setEventDetail, refreshStealth, isMyDrop, isLoading: getEventDetail.status === 'PROCESSING' };
};

export const EventContext = createContext<EventViewModelState>({
  eventDetail: {} as TEvent,
  ticketsInfo: [] as TicketTier[],
  setEventDetail: () => { },
  refreshStealth: () => { },
  isMyDrop: false,
  isLoading: true,
});

export interface EventViewModelState {
  eventDetail?: TEvent;
  ticketsInfo?: TicketTier[];
  setEventDetail: (eventDetail: TEvent) => void;
  refreshStealth: () => void;
  isMyDrop: boolean;
  isLoading: boolean;
}

class EventViewModel implements ViewModel<EventViewModelState> {
  Hook = useEvent;

  CtxProvider = EventContext.Provider;
}

export default EventViewModel;
