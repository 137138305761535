import { StyleRules } from '@material-ui/core';
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides';

const InputBase: Partial<StyleRules<ComponentNameToClassKey['MuiInputBase']>> = {
  input: {
    // fontWeight: 700,
    boxSizing: 'border-box',
  },
};

export default InputBase;
