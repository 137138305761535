/* eslint-disable react/require-default-props */
/* eslint-disable no-underscore-dangle */
import { alpha, Box, ButtonBase, Container, Grid, Link, makeStyles, Portal, Theme, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import AddToCalendar from 'Components/AddToCalendar';
import CustomDivider from 'Components/CustomDivider';
import Footer from 'Components/Footer/Footer';
import PgButton from 'Components/PgButton';
import PgTypo from 'Components/PgTypo';
import ReadMoreLess from 'Components/ReadMoreLess';
import UserAvatar from 'Components/UserAvatar';
import { REFUND_POLICY_LINK } from 'Constants/links';
import DropActionButton from 'Features/Drops/DropActionButton';
import LiveTag from 'Features/Drops/LiveTag';
import useAttendeeListDialog from 'Features/Event/AttendeeList/useAttendeeListDialog';
import EventShareButton from 'Features/Event/EventShareButton';
import ReplayButton from 'Features/Replay/ReplayButton';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import { filter } from 'lodash';
import EventModel, { AddOnConnTypeMap, EAddOnConnType, IAddOnConnInstance, IAddOnConnResponse, ISpeakerSponsor } from 'Models/Event';
import { Addons, TEvent } from 'Models/Event/@types';
import moment from 'moment';
import React, { FC, useContext, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import DateTimeUtils from 'Resources/DateTimeUtils';
import { APP_ROUTES } from 'Routes';
import { useStoreState } from 'Stores';
import useAppStyles from 'Theme/Styles';
import { COLOR } from 'Theme/themeConstants';
import DropUtils from 'Utils/DropUtils';
import { IEventPageProps } from './@types';
import Carousel from './Carousel';
import CreatorInfo from './Creator';
import EventCollaborationRequest from './EventCollaborationRequest';
import EventLocation from './EventLocation';
import EventPageSkeleton from './EventPageSkeleton';
import { EventContext } from './EventViewModel';
import Header, { IEventHeaderProps } from './Header';
import StatusBar from './StatusBar';
import Tickets from './Tickets';
import AdditionalInfo from './AdditionalInfo';
import helpers from 'Utils/helpers';
import useLayoutQuery from 'Hooks/useLayoutQuery';
import ClaimReward from './ClaimReward';

export interface EventPageProps {
  event: TEvent;
  classes: Record<string, string>;
}

export const withToolTip = (child: React.ReactElement<any, any>, text: string) => (
  <Tooltip title={text} placement="top">
    {child}
  </Tooltip>
);

/**
 * This is a special handling for some events where we have to display custom data string
 */
const CustomDateTextMap: Record<string, string> = {
  /**
   * Meditation & Mindfulness Power Hour with Liza Kindred & Friends!
   * https://playgroundapp.com/events/eff-this-meditation-with-liza-kindred-friends
   */
  '6377ca0e0561998dabfcddc5': `Nov 30th - 8:30am - 9:30am EST<br/>Dec 1st - 8:30am - 9:30am EST<br/>Dec 2nd - 8:30am - 9:30am EST<br/>Dec 3rd - 8:30am - 9:30am EST`,

  /**
   * nrvld.co Daily Run Club
   * https://playgroundapp.com/events/nrvldco-run-meet-up
   */
  '637beff53b1351ee7e966a6b': `Nov 30th - 8:00am - 9:30am EST<br/>Dec 1st - 8:00am - 9:30am EST<br/>Dec 2nd - 8:00am - 9:30am EST<br/>Dec 3rd - 8:00am - 9:30am EST`,

  /**
   * [This is for testing on staging]
   * Tuesday Night Roller Disco - Adult Skate - 8 P.M. to 10 P.M.
   */
  '61dceee8c35b5a352b235895': `Nov 30th - 8:30am - 9:30am EST<br/>Dec 1st - 8:30am - 9:30am EST<br/>Dec 2nd - 8:30am - 9:30am EST<br/>Dec 3rd - 8:30am - 9:30am EST`,
};

const getEventDateTime = (date: TEvent['eventDates'][0]) => {
  const startDate = DateTimeUtils.formatDate(date.startDate, 'dddd[,] MMMM DD[,]');
  const endDate = DateTimeUtils.formatDate(date.endDate, 'dddd[,] MMMM DD[,]');
  const startTime = DateTimeUtils.formatDate(date.startDate, 'hh:mm A');
  const endTime = DateTimeUtils.formatDate(date.endDate, 'hh:mm A z');
  // return `${startDate} at ${startTime} - ${endTime}`;
  if (DropUtils.isSameDate([date]))
    return (
      <>
        {startDate}
        <br />
        {startTime} - {endTime}
      </>
    );
  else
    return (
      <>
        {startDate} {startTime} -<br />
        {endDate} {endTime}
      </>
    );
};
const EventDateTime: FC<IEventPageProps> = ({ classes, event }) => {
  // if (!event?.featureToggle?.when) return <PgTypo b2>TBD</PgTypo>;
  if (!event?.featureToggle?.when) return null;
  const isLive = helpers.isEventLive(event);
  const hasCustomDate = !!CustomDateTextMap[event.id];
  if (!event?.eventDates || !event?.eventDates?.length) return <div />;
  return (
    <Box>
      {isLive && !event.canceled && <LiveTag className={classes?.liveTag} />}
      {hasCustomDate ? (
        <PgTypo b2 dangerouslySetInnerHTML={{ __html: CustomDateTextMap[event.id] }} />
      ) : (
        <React.Fragment>
          {event.eventDates.map((date) => (
            <PgTypo key={date.startDate + date.endDate} b2>
              {event.dateChanged && <strong>{'Rescheduled'.toUpperCase()}: </strong>}
              {hasCustomDate ? CustomDateTextMap[event.id] : getEventDateTime(date)}
              {event.canceled && <strong className={classes?.canceled}> - CANCELED</strong>}
            </PgTypo>
          ))}
        </React.Fragment>
      )}
    </Box>
  );
};

export const DESCRIPTION_READ_MORE_LIMIT = 500;

const EventDescription: FC<EventPageProps> = ({ event, classes }) => {
  const [isReadMore, setReadMore] = useState(false);
  const appClasses = useAppStyles();
  return (
    <Box>
      <ReadMoreLess
        maxLength={DESCRIPTION_READ_MORE_LIMIT}
        text={event.description}
        typoProps={{ b2: true, className: clsx(appClasses.richText, classes.eventDescription) }}
      />
    </Box>
  );
};

const Attendees: FC<IEventPageProps> = ({ event, classes }) => {
  const userImages = event.usersWithProfileImages?.userImages;
  const count = event.usersWithProfileImages?.count - 1;
  const { Component, openDialog } = useAttendeeListDialog();
  if (!userImages || !userImages.length) return null;
  return (
    <>
      <Box component={ButtonBase} display="flex" onClick={openDialog}>
        {userImages.map(
          (url, i) =>
            i < 5 && (
              <Box marginRight={0.5} key={url}>
                <UserAvatar url={url} name={url} size={25} />
              </Box>
            ),
        )}
        {count > 5 ? <PgTypo c2>+ {count - 5} Going</PgTypo> : <></>}
      </Box>
      {Component}
    </>
  );
};

const PeopleList: FC<{ list: Addons['featured']; classes: IEventPageProps['classes'] }> = ({ list, classes }) => {
  if (!list || !list.length) return null;
  return (
    <Box>
      {list.map((item) => {
        return (
          <Box mt={1}>
            <Link href={item.url} underline="none" className={clsx({ [classes?.addOnsLink ?? '']: item.url })}>
              <PgTypo b2>{item.name}</PgTypo>
            </Link>
          </Box>
        );
      })}
    </Box>
  );
};

const EventActions: FC<{
  event: TEvent;
  classes: IEventPageProps['classes'];
  setEventDetail: (event: TEvent) => void;
  refreshStealth: () => void;
  isMyDrop: boolean;
  disableActions?: boolean;
}> = ({ event, classes, refreshStealth, isMyDrop, disableActions = false }) => {
  const startDate = EventModel.getEventStartDate(event);
  const endDate = EventModel.getEventEndTime(event, startDate || '') || '';
  return (
    <Box display="flex" flex={1}>
      <DropActionButton drop={event} resourceType="Event" type="primary" onActionComplete={refreshStealth} isDisabled={disableActions} />
      <Box display="flex" alignItems="center" ml={2}>
        {/* <FavoriteButton moment={event} onDone={handleDone} /> */}
        {isMyDrop
          ? null
          : withToolTip(
            <Box>
              <ReplayButton drop={event} dropType={'Event'} onDone={refreshStealth} isDisabled={disableActions} />
            </Box>,
            'Replay',
          )}
        {withToolTip(
          <Box className={classes?.actionButton}>
            <AddToCalendar
              title={`${event.name} ${event.subtitle ?? ''}`}
              description={event.description}
              open={startDate}
              close={endDate}
              location={event.eventType === 'offline' ? event.address.city ?? event.address.fullAddress : undefined}
              isDisabled={disableActions}
            />
          </Box>,
          'Add to Calendar',
        )}
        {withToolTip(
          <Box>
            {/* <PgIcon icon="icon-share" /> */}
            <EventShareButton event={{ ...event, doc_type: 'Event' }} isDisabled={disableActions} />
          </Box>,
          'Share',
        )}
      </Box>
    </Box>
  );
};

const DetailRow: FC<{ heading?: string; classes?: IEventPageProps['classes']; divider?: boolean; value: React.ReactNode }> = ({
  heading,
  classes,
  divider = true,
  value,
}) => {
  const valueElement = (value as any)?.type((value as any).props);
  const { isDeviceSm } = useMediaQuery();
  if (!valueElement) return null;

  return (
    <Box>
      <Box padding={2.5} display="flex" flexDirection={isDeviceSm ? 'column' : 'row'}>
        {heading && (
          <Box width={isDeviceSm ? '100%' : 200} pr={isDeviceSm ? undefined : 4} pb={isDeviceSm ? 1.5 : undefined}>
            <PgTypo c2 style={{ textTransform: 'uppercase' }}>
              {heading}
            </PgTypo>
          </Box>
        )}

        <Box display="flex" flex={1}>
          {valueElement}
        </Box>
      </Box>
      {divider && <CustomDivider color={COLOR.primary.black} />}
    </Box>
  );
};

export const DropDetailsRow = DetailRow;

const Refund: FC<{ event: TEvent }> = ({ event }) => {
  const refundType = useMemo(() => {
    if (!event?.refundPolicy) return null;
    switch (event.refundPolicy) {
      case 'no_refunds':
        return 'This event is non-refundable.';
      case 'case_by_case_refund':
        return 'Refunds are granted on case-by-case basis.';
      case 'refund_for_up_to_1_day_before_event':
        return "A refund may be issued up to 1 day before this event, according to the creator's refund policy.";
      case 'refund_for_up_to_1_month_before_event':
        return "A refund may be issued up to 30 days before this event, according to the creator's refund policy.";
      case 'refund_for_up_to_1_week_before_event':
        return "A refund may be issued up to 7 days before this event, according to the creator's refund policy.";
      default:
        return null;
    }
  }, [event?.refundPolicy]);

  if (!event.refundPolicy || event.tierType === 'free' || event.tierType === 'externalSite') return null;

  return (
    <PgTypo variant="body1">
      {refundType} See our{' '}
      <PgButton quaternary isExternalLink href={REFUND_POLICY_LINK}>
        Refund Policy.
      </PgButton>
    </PgTypo>
  );
};

const Speakers: FC<{ speakers: Array<IAddOnConnInstance & { addonType: string }> }> = (props) => {
  const { speakers } = props;
  const generateLink = (type: string, slug: string) => {
    if (type === 'external') return slug;
    if (type === 'user') return generatePath(APP_ROUTES.USER_DETAIL.path, { id: slug });
    else return generatePath(APP_ROUTES.COMMUNITY_DETAIL.path, { communityId: slug });
  };
  return (
    <Box display={'flex'} alignItems="center" flexWrap={'wrap'} gridGap={10}>
      {speakers.map((s) => {
        return (
          <Tooltip title={s.name}>
            <div>
              <PgButton quaternary isExternalLink={s.addonType === 'external'} href={generateLink(s.addonType, s.slug ?? s.linkUrl)}>
                <UserAvatar name={s.name} size={'48px'} url={s.profileImg} key={s.id} />
              </PgButton>
            </div>
          </Tooltip>
        );
      })}
    </Box>
  );
};

export const AgeRestrictionMap: Record<number, { title: string; subtitle: string }> = {
  13: { title: '13+ Event: ', subtitle: 'Guests must be 13 or older to attend.' },
  16: { title: '16+ Event: ', subtitle: 'Guests must be 16 or older to attend.' },
  18: { title: '18+ Event: ', subtitle: 'Guests must be 18 or older to attend.' },
  21: { title: '21+ Event: ', subtitle: 'Guests must be 21 or older to attend.' },
};

const AgeRestriction: FC<{ title: string; subtitle: string; classes?: Record<string, string> }> = ({ title, subtitle, classes }) => {
  return (
    <PgTypo b2>
      <span className={classes?.ageResTitle}>{title}</span>
      {subtitle}
    </PgTypo>
  );
};

const getSpeakerMeta = (speakers?: ISpeakerSponsor) => {
  return speakers?.connections?.filter((f) => f.connectionStatus === 'ACCEPTED')?.map((m) => ({ ...m.meta, addonType: m.entityType }));
};

export interface DetailProps {
  eventDetail: TEvent;
  setEventDetail: (eventDetail: TEvent) => void;
  refreshStealth: () => void;
  isMyDrop: boolean;
  disableActions?: boolean;
  hideChatRedirect?: boolean;
  headerActionProps?: IEventHeaderProps['actionProps'];
}

const Details: FC<DetailProps> = (props) => {
  const {
    eventDetail: event,
    setEventDetail,
    refreshStealth,
    isMyDrop,
    disableActions = false,
    hideChatRedirect = false,
    headerActionProps,
  } = props;
  const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));
  const classes = useStyles({ isReplayed: !!event?.isReplayed });

  const history = useHistory();
  const onConversationClick = () => {
    if (!event) return;
    history.push(generatePath(APP_ROUTES.EVENT_PAGE_CONVERSATIONS.path, { id: event.slug }));
  };
  const addons: Addons = useMemo(() => {
    const _addons: Addons = {};
    if (event?.addons)
      Object.keys(event?.addons).map((key) => {
        if (Array.isArray(event.addons?.[key as keyof Addons])) {
          _addons[key as keyof Addons] = filter<any>(
            event.addons?.[key as keyof Addons],
            (value: any) => !!value && Object.keys(value).length,
          );
        } else {
          _addons[key as keyof Addons] = event.addons?.[key as keyof Addons] as any;
        }
      });
    return _addons;
  }, [event?.addons]);

  const { isDeviceSm } = useMediaQuery();
  const { ref, height } = useLayoutQuery();
  const dropActionType = useMemo(() => DropUtils.getDropActionType('Event', event), []);

  if (!event) return <EventPageSkeleton />;
  return (
    <Box>
      <StatusBar event={event} />
      <EventCollaborationRequest />
      <Header event={event} hideReport={disableActions} actionProps={headerActionProps} />
      <Box>
        <Container maxWidth={false} className={classes.pageContainer}>
          <Grid container>
            <Grid item xs={12} sm={12} md={5} lg={5} className={classes.imageContainer}>
              <div ref={ref}>
                <Carousel
                  primaryImage={event.primaryImage}
                  drop={event}
                  setDropDetail={(e) => setEventDetail(e as TEvent)}
                  Addons={event.addons}
                  disableAction={disableActions}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Box height={isDeviceSm ? 'auto' : height} className={classes.detailParentContainer}>
                <DetailRow
                  heading=""
                  value={
                    <EventActions
                      event={event}
                      classes={classes}
                      setEventDetail={setEventDetail}
                      refreshStealth={refreshStealth}
                      isMyDrop={isMyDrop}
                      disableActions={disableActions}
                    />
                  }
                />
                <DetailRow heading="Who" value={<Attendees event={event} classes={classes} />} />
                {event?.description && event?.featureToggle?.description && (
                  <DetailRow heading="What" value={<EventDescription classes={classes} event={event} />} />
                )}
                <DetailRow heading="When" value={<EventDateTime classes={classes} event={event} />} />
                <DetailRow heading="Where" value={<EventLocation event={event} onCountDownEnds={refreshStealth} />} />
                {dropActionType === 'RSVP' ? null : (
                  <DetailRow heading="Tickets" value={<Tickets classes={classes} event={event} />} />
                )}
                {!!event.rewardSettings ? (
                  <DetailRow heading="Reward" value={<ClaimReward event={event} dropType="event" />} />
                ) : null}

                {!!event.ageRestriction && event?.featureToggle?.ageRestriction && !!AgeRestrictionMap[event.ageRestriction] && (
                  <DetailRow
                    heading="Age restriction"
                    value={<AgeRestriction {...AgeRestrictionMap[event.ageRestriction]} classes={classes} />}
                  />
                )}
                {addons?.additionalInfo?.length && event.featureToggle?.addOns
                  ? addons.additionalInfo.map((a, i) => {
                    // if (a.name)
                    return (
                      <DetailRow
                        key={a.name + i}
                        heading={a.name ?? ' '}
                        value={<AdditionalInfo val={a.information} imgs={a.images} />}
                      />
                    );
                    // return null;
                  })
                  : null}
                {event.featureToggle?.speakers ? (
                  <>
                    {addons?.featured?.length ? (
                      <DetailRow heading="Featuring" value={<PeopleList list={event.addons?.featured} classes={classes} />} />
                    ) : null}
                    {addons?.sponsors?.length ? (
                      <DetailRow heading="Sponsors" value={<PeopleList list={event.addons?.sponsors} classes={classes} />} />
                    ) : null}
                  </>
                ) : null}
                {event.featureToggle?.speakers
                  ? event.addons?.speakerSponsor?.map((speaker) => {
                    const connections = getSpeakerMeta(speaker);
                    if (!connections?.length) return <></>;
                    return (
                      <DetailRow
                        heading={AddOnConnTypeMap[speaker.name as EAddOnConnType]}
                        value={<Speakers speakers={connections} />}
                      />
                    );
                  })
                  : null}
                <DetailRow
                  heading="Hosts"
                  value={<CreatorInfo event={event} setEventDetail={setEventDetail} isMyDrop={isMyDrop} />}
                />
                <DetailRow heading="Refunds" divider={false} value={<Refund event={event} />} />
              </Box>
            </Grid>
          </Grid>
        </Container>
        {appUser?.id && !hideChatRedirect ? (
          <Box className={classes.discussionSection}>
            <Box flex={6}>
              <PgTypo h6>{`Chat about and make plans for ${event.name}`}</PgTypo>
            </Box>
            <PgButton className={classes.convoBtn} onClick={onConversationClick} size="large" variant="outlined" color="primary">
              open conversation
            </PgButton>
          </Box>
        ) : null}
      </Box>
      <Footer />
    </Box>
  );
};

const useStyles = makeStyles<Theme, { isReplayed: boolean }>((theme) => ({
  pageContainer: {
    borderTop: `1px solid ${theme.palette.common.black}`,
    borderBottom: `1px solid ${theme.palette.common.black}`,
    paddingLeft: 0,
    paddingRight: 0,
  },
  imageContainer: {
    [theme.breakpoints.up('sm')]: {
      borderRight: `1px solid ${theme.palette.common.black}`,
    },
    [theme.breakpoints.down('sm')]: {
      borderLeft: 0,
    },
  },
  detailParentContainer: {
    // '& button, a': {
    //   '&:focus': {
    //     backgroundColor: theme.palette.secondary.main,
    //   },
    // },
    [theme.breakpoints.up('sm')]: { overflowY: 'auto' },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderBottom: `1px solid $${theme.palette.common.black}`,
    },
  },
  readMore: {
    textTransform: 'initial',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    '&:focus': {
      backgroundColor: 'unset !important',
    },
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, 2),
  },
  replayBtn: {
    backgroundColor: (props) => (props.isReplayed ? theme.palette.secondary.main : theme.palette.common.white),
    padding: theme.spacing(1),
    '&:hover svg': {
      color: theme.palette.common.black,
    },
  },
  replayIcon: {
    color: (props) => (props.isReplayed ? theme.palette.common.white : theme.palette.common.black),
  },
  discussionSection: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(8, 4),
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: theme.spacing(3),
    },
  },
  convoBtn: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
  },
  eventDescription: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  addOnsLink: {
    '& *': {
      color: theme.palette.secondary.main,
      textDecoration: 'underline',
    },
  },
  liveTag: {
    width: 70,
  },
  addImgs: { width: 140 },
  addIngWrap: { position: 'relative', '&:hover > div': { display: 'grid' } },
  addImgsBg: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: alpha(theme.palette.common.black, 0.7),
    display: 'none',
    placeItems: 'center',
    aspectRatio: '1.5 / 1',
  },
  ageResTitle: { fontWeight: theme.typography.fontWeightBold },
}));

export default Details;
