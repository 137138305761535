import config from 'config';
import { io, Socket } from 'socket.io-client';
// eslint-disable-next-line import/no-cycle
import usePointsNotification from '../Features/Points/usePointsNotification';
import { useStoreActions, useStoreState } from 'Stores';
import utils from 'Utils';
import helpers from 'Utils/helpers';
import { useSnackbar, VariantType } from 'notistack';
import ToastMessage from '../Components/ToastMessage';
import MESSAGES from 'Utils/Messages';
import { TUserCommunity, User } from 'Models/User';
export const socket = io(config.get('SOCKET_URL') as string, { autoConnect: false, withCredentials: true });

export const SOCKET_EVENT_KEYS = {
  JOIN: 'join',
  SCORE: 'score',
  JOIN_COMMUNITY: 'joinCommunity',
  JOIN_EVENT: 'joinEvent',
  JOIN_RESOURCE: 'joinResource',
  NEW_MESSAGE: 'newMessage',
  MESSAGE_DELETED: 'messageDeleted',
  MESSAGE_HIDDEN: 'messageHidden',
  MESSAGE_UNHIDDEN: 'messageUnhidden',
  JOIN_REFUND_REQUEST: 'joinRefundRequest',
  NOTIFICATION: 'notification',
  MAKE_USER_PRESENCE: 'makeUserPresence',
  USER_ONLINE: 'userOnline',
  USER_OFFLINE: 'userOffline',
};
const useSocketListener = () => {
  const { JOIN, SCORE, MAKE_USER_PRESENCE } = SOCKET_EVENT_KEYS;
  const { notify } = usePointsNotification();
  const { setSocketConnected, setSocketConnectionId, getCommunityDetails, setAppUser } = useStoreActions(({
    AuthStore: { setSocketConnected, setSocketConnectionId, setAppUser },
    CommunityStore: { getCommunityDetails },
  }) => ({
    setSocketConnected,
    setSocketConnectionId,
    getCommunityDetails,
    setAppUser,
  }));
  const { socketConnected, appUser } = useStoreState(({ AuthStore: { socketConnected, appUser } }) => ({ socketConnected, appUser }));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let markPresenceIntervalId: NodeJS.Timeout;
  const disconnectSocket = () => {
    if (socket.connected) {
      socket.removeAllListeners();
      socket.disconnect();
      setSocketConnected(false);
      setSocketConnectionId('');
    }
    if (markPresenceIntervalId) clearInterval(markPresenceIntervalId);
  };

  const markPresence = () => {
    if (markPresenceIntervalId) clearInterval(markPresenceIntervalId);
    socket.emit(SOCKET_EVENT_KEYS.MAKE_USER_PRESENCE);
    markPresenceIntervalId = setInterval(() => {
      socket.emit(SOCKET_EVENT_KEYS.MAKE_USER_PRESENCE);
    }, 60000);
  };

  const authenticateSocket = (socket: Socket) => {
    socket.emit(
      JOIN,
      {
        accessToken: utils.getAccessToken(),
      },
      () => {
        console.log(`Socket authenticated successfully`)
        markPresence();
        setSocketConnected(true);
      },
    );
  };

  const socketInit = () => {
    disconnectSocket();
    try {
      console.log('connection requested', socket.connected);
      socket.on('connect', () => {
        setSocketConnectionId(socket.id);
        console.log('connection successfull', socket.connected);
      });
      socket.connect();

      socket.on(SCORE, notify);

      // ----------------------
      const onShowSnackbarEvent = (data?: { message: string; variant?: VariantType; persist?: boolean; timeout?: number; code?: string, data?: any }) => {
        if (!data?.message) {
          console.warn(`socket event showSnackbar has no message`, data);
          return;
        }

        if (data?.data?.communityId && data.code === 'NEW_COMM_FOLLOW') {
          getCommunityDetails({ id: data?.data?.communityId }).then((res: TUserCommunity) => {
            if (res.isFollowed) setAppUser({ ...appUser, followsCommunities: [...(appUser?.followsCommunities ?? []), res] } as User);
          });
        }

        const snackId = enqueueSnackbar(ToastMessage({ title: MESSAGES[(data.code ?? '') as keyof typeof MESSAGES] ?? data.message }), {
          variant: data.variant || 'info',
          autoHideDuration: data.timeout || 5000,
        });
        // const event = new Event(snackId + '_ack');
        // window.addEventListener(snackId + '_ack', () => closeSnackbar(snackId), { once: true });
        // let intervalId: NodeJS.Timeout;
        // intervalId = setInterval(async () => {
        //   if (document.hidden) return;
        //   clearInterval(intervalId);
        //   await new Promise((resolve) => setTimeout(resolve, 5000));
        //   window.dispatchEvent(event);
        // }, 1000);
      };
      socket.on('showSnackbar', onShowSnackbarEvent);

      socket.on('update_cookie', (data?: { op: 'set' | 'clear'; key: string; value?: string; domain?: string }) => {
        if (!data) return;
        if (data.op === 'set' && data.key && data.value) {
          utils.setCookie(data.key, data.value, data.domain);
        } else if (data.op === 'clear' && data.key) {
          utils.clearCookie(data.key, data.domain);
        }
      });

      socket.on('redirect_page', (data?: { url: string }) => {
        if (!data || !data.url) return;
        window.location.href = data.url;
      });

      if (!helpers.isProduction()) {
        (window as any).gSocket = socket;
      }
      // socket.onAny((ev,data)=>{
      //   if (helpers.isProduction())
      //     return;
      //   console.log('socket_event_revieved', ev, data);
      // })
    } catch (err) {
      console.log('connect', err);
      // todo: handle error
    }
  };
  return {
    socket,
    socketInit,
    SOCKET_EVENT_KEYS,
    socketConnected,
    disconnectSocket,
    authenticateSocket,
  };
};

export default useSocketListener;
