import { Box, IconButton, Theme, createStyles, makeStyles } from '@material-ui/core';
import DialogWrapper from 'Components/DialogWrapper';
import PgIcon from 'Components/PgIcon';
import { BORDER_BLACK } from 'Theme/themeConstants';
import React, { FC } from 'react';
import RSVPDropCard from './Sections/RSVPDropCard';
import { TDrop } from 'Models/Drop/@types';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import RSVPForm, { RSVPFormProps } from './Sections/RSVPForm';
import PgTypo from 'Components/PgTypo';
import { capitalize } from 'lodash';

export interface RSVPDialogProps extends Omit<RSVPFormProps, 'dropType'> {
  open: boolean;
  onClose?: () => void;
  drop: TDrop;
}

const RSVPDialog: FC<RSVPDialogProps> = (props) => {
  const { open, onClose, drop, ...rsvpFormProps } = props;

  const { isDeviceSm } = useMediaQuery();
  const classes = useStyles();

  const title = (
    <PgTypo h6 style={{ padding: isDeviceSm ? 16 : `24px 24px 0` }}>
      {drop.doc_type === 'Event' ? 'RSVP for Event' : `Remind me for ${drop.doc_type === 'collect' ? 'Merch' : capitalize(drop.doc_type)}`}
    </PgTypo>
  );

  return (
    <DialogWrapper open={open} variant="light" PaperProps={{ className: classes.root }}>
      <IconButton className={classes.closeIcon} onClick={onClose}>
        <PgIcon icon="icon-close" size="small" />
      </IconButton>
      <Box className={classes.main}>
        {isDeviceSm && title}
        <Box minWidth={isDeviceSm ? '100%' : 290} width={isDeviceSm ? '100%' : 290} borderRight={isDeviceSm ? undefined : BORDER_BLACK}>
          <RSVPDropCard drop={drop} />
        </Box>
        <Box width="100%">
          {!isDeviceSm && title}
          <RSVPForm {...rsvpFormProps} dropType={drop.doc_type} />
        </Box>
      </Box>
    </DialogWrapper>
  );
};

export default RSVPDialog;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { width: 900, minHeight: 'unset !important' },
    closeIcon: { position: 'absolute', top: theme.spacing(1.5), right: theme.spacing(1.5) },
    main: { display: 'flex', [theme.breakpoints.down('sm')]: { flexDirection: 'column' } },
  }),
);
