import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box, BoxProps } from '@material-ui/core';
import { THEME_PALETTE } from 'Theme/themeConstants';
import PgTypo from './PgTypo';
import PgButton from './PgButton';
import PgIcon, { TPgIcon } from './PgIcon';
import AddDropButton from 'Features/Drops/AddDropButton';

export interface PgEmptyStateProps extends BoxProps {
  text: string;
  type?: 'Default' | 'Icon' | 'Title';
  icon?: TPgIcon;
  title?: string;
  onButtonClick?: () => void;
  buttonName?: string;
  showAddDropBtn?: boolean;
}

const PgEmptyState: FC<PgEmptyStateProps> = (props) => {
  const { text, type = 'Default', icon, title, onButtonClick, buttonName, showAddDropBtn = false, ...boxProps } = props;
  const classes = useStyles({});

  return (
    <>
      <Box
        width="100%"
        height={400}
        left={8}
        top={8}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding="60px 32px"
        {...boxProps}
      >
        {type === 'Icon' && icon && (
          <Box className={classes.iconContainer}>
            <PgIcon style={{ fontSize: 64, color: THEME_PALETTE.grey.A300 }} icon={icon} />
          </Box>
        )}
        {type === 'Title' && title && (
          <Box className={classes.titleContainer}>
            <PgTypo h6 align="center">
              {title}
            </PgTypo>
          </Box>
        )}
        <Box className={classes.textContainer}>
          {/* <PgTypo h6 align="center" className={classes.text}>
            {text}
          </PgTypo> */}
          <PgTypo h6 align="center" className={classes.text} dangerouslySetInnerHTML={{ __html: text }} />
        </Box>
        {showAddDropBtn && <AddDropButton className={classes.btn} btnname={buttonName} />}
        {buttonName && onButtonClick && (
          <PgButton fullWidth primary className={classes.btn} onClick={onButtonClick}>
            {buttonName}
          </PgButton>
        )}
      </Box>
    </>
  );
};

const useStyles = makeStyles<Theme, any>((theme) =>
  createStyles({
    text: {
      [theme.breakpoints.up('md')]: {
        width: 520,
      },
      color: THEME_PALETTE.grey.A300,
    },
    textContainer: {
      [theme.breakpoints.up('md')]: {
        width: 520,
      },
      // height: 85,
    },
    btn: {
      marginTop: 28,
      maxWidth: 320,
      // [theme.breakpoints.down('sm')]: {
      //   maxWidth: 220,
      // },
    },
    iconContainer: {
      width: 60,
      height: 64,
      marginBottom: 28,
    },
    titleContainer: {
      marginBottom: 28,
    },
  })
);

export default PgEmptyState;
