import { thunk, Thunk } from 'easy-peasy';
import { TDrop } from 'Models/Drop/@types';
import FavoritesModel from 'Models/Favorites';

export interface TFavoritesState {
  favorites: TDrop[];
  total: number;
  hasMore?: boolean;
  aggregations?: Record<string, unknown>;
  fetchFavorites: Thunk<TFavoritesState, { appendResult?: boolean; limit: number; skip: number; slug: string }>;
  favoritesCount: number;
}

const FavoritesStore: TFavoritesState = {
  total: 0,
  favorites: [],
  hasMore: true,
  aggregations: {},
  favoritesCount: 0,
  fetchFavorites: thunk(async (actions, { appendResult = true, limit, skip, slug }, { getState }) => {
    const state = getState();
    if (appendResult === false) state.favorites = [];
    const favoritesCountNumber = await FavoritesModel.favoritesCount(slug);
    const favoritesResponse = await FavoritesModel.favorites(slug, 
      {
        where: {
          or: [
            { subjectType: 'Event' },
            { subjectType: 'Video' },
            { subjectType: 'Collect' },
            { subjectType: 'Podcast' },
            { subjectType: 'Article' },
          ],
        },
        include: { relation: 'subject', scope: { include: ['tags', 'community', 'ticketingInfo', 'collaborators'] } },
        order: 'updated DESC',
        limit,
        skip,

    });
    state.favorites = appendResult ? [...state.favorites, ...favoritesResponse.results] : favoritesResponse.results;
    state.favoritesCount = favoritesCountNumber;
    if (appendResult === false) {
      state.total = favoritesResponse.total;
      state.aggregations = favoritesResponse.aggregations;
      state.hasMore = true;
    } else {
      state.hasMore = state.total > state.favorites.length || Boolean(favoritesResponse.results.length);
    }
  }),
};

export default FavoritesStore;
