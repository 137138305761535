import DOBSelector, { DOBSelectorProps, IDOB } from 'Components/DOBSelector';
import PgTypo, { PgTypoProps } from 'Components/PgTypo';
import Spacer from 'Components/Spacer';
import { FormikProps } from 'formik';
import moment from 'moment';
import React, { FC, useMemo } from 'react';
import { getFieldError, IFieldProps } from 'react-forms';
import helpers from 'Utils/helpers';

export interface DOBFieldProps extends IFieldProps {
  fieldProps?: DOBSelectorProps & {
    label?: string;
    labelProps?: PgTypoProps;
  };
}

const DOBField: FC<DOBFieldProps> = (props) => {
  const { fieldConfig, fieldProps = {}, formikProps = {} as FormikProps<any> } = props;

  const valueKey = fieldConfig?.valueKey || '';
  const _value = helpers.getFieldValue(formikProps, valueKey);

  const onChange = (date: IDOB, event: React.ChangeEvent<any>) => {
    const dateString = moment().set('year', date.year).set('month', date.month).set('date', date.day).toISOString();
    formikProps.handleChange({
      ...event,
      target: { ...event.target, value: dateString, name: valueKey },
      currentTarget: { ...event.currentTarget, value: dateString, name: valueKey },
    });
  };

  const value = useMemo<IDOB>(() => {
    const dateMoment = moment(_value).isValid() ? moment(_value) : moment();
    return { day: dateMoment.date(), month: dateMoment.month(), year: dateMoment.year() };
  }, [_value]);

  const fieldError = getFieldError(valueKey, formikProps);
  const updatedProps: DOBSelectorProps = {
    ...fieldProps,
    error: fieldError,
    helperText: fieldProps.helperText,
    onChange,
    selectFieldProps: { onBlur: formikProps.handleBlur, ...fieldProps.selectFieldProps },
    value,
    name: valueKey,
  };

  return (
    <>
      {!fieldProps.label ? null : (
        <>
          <PgTypo variant="body1" {...fieldProps.labelProps}>
            {fieldProps.label}
          </PgTypo>
          <Spacer height={30} />
        </>
      )}
      <DOBSelector {...updatedProps} />
    </>
  );
};

export default DOBField;
