import { createTheme, alpha } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import shadows from '@material-ui/core/styles/shadows';
import { DARK_THEME_PALETTE, THEME_PALETTE } from 'Theme/themeConstants';
import typography from './typography';
import MuiButton, { DarkButton } from './overrides/Button';
import MuiSwitch from './overrides/Switch';
import MuiOutlinedInput, { OutlinedInputDark } from './overrides/FormFields/OutlinedInput';
import MuiFormControlLabel from './overrides/FormFields/FormControlLabel';
import MuiFormHelperText from './overrides/FormFields/FormHelperText';
import MuiSelect, { DarkSelect } from './overrides/FormFields/Select';
import MuiInputBase from './overrides/FormFields/InputBase';
import MuiInputLabel, { DarkInputLabel } from './overrides/FormFields/InputLabel';
import { ComponentsProps } from '@material-ui/core/styles/props';

const { primary, secondary, background, text } = DARK_THEME_PALETTE;

const { others: otherColors, ...palette } = THEME_PALETTE;
const breakpoints = createBreakpoints({});

shadows[1] = '0px 1px 1px #00000029';
shadows[2] = `0px 1px 2px ${alpha('#000000', 0.15)}`;
shadows[3] = `0px 2px 3px ${alpha('#000000', 0.2)}`;
shadows[4] = '0px 3px 6px #00000029';
shadows[5] = '-3px 0px 6px #00000029';
shadows[6] = '0px 0px 36px rgba(0, 0, 0, 0.12), 0px 4px 4px rgba(0, 0, 0, 0.08), 0px 4px 10px rgba(0, 0, 0, 0.12)';
shadows[7] = `0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)`;
shadows[8] = `0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)`;
shadows[9] = `0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)`;
shadows[10] = `0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)`;

const overrides = {
  MuiIconButton: {
    sizeSmall: {
      padding: 7,
    },
  },
  MuiSvgIcon: {
    fontSizeSmall: {
      fontSize: '1rem',
    },
  },
  MuiMenu: {
    paper: {
      borderRadius: 0,
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: 42,
    },
  },
  MuiListItemText: {
    root: {
      fontSize: 14,
    },
  },
  MuiTooltip: {
    tooltip: {
      backgroundColor: '#000',
      borderRadius: 0,
      fontSize: 14,
      fontWeight: 400,
    },
    arrow: {
      color: '#000',
    },
  },
  MuiOutlinedInput,
  MuiFormControlLabel,
  MuiFormHelperText,
  MuiSelect,
  MuiInputBase,
  MuiInputLabel,
  MuiButton,
  MuiSwitch,
  MuiToolbar: {
    gutters: {
      [breakpoints.up('md')]: {
        paddingLeft: 45,
        paddingRight: 45,
      },
    },
  },
};

const props: ComponentsProps = {
  MuiIconButton: {
    size: 'small',
  },
  MuiTooltip: {
    arrow: true,
  },
  MuiButton: {
    color: 'primary',
  },
  MuiFab: {
    size: 'small',
  },
  MuiOutlinedInput: {
    notched: false,
  },
  MuiInputLabel: {
    shrink: true,
    variant: 'outlined',
    disableAnimation: true,
  },
  MuiLink: {
    target: '_blank',
    underline: 'none',
  },
};

export default createTheme({
  palette,
  shadows,
  props,
  typography,
  overrides,
});

export const darkTheme = createTheme({
  palette: {
    type: 'dark',
    text,
    background,
    primary,
    secondary,
  },
  props: {
    ...props,
    MuiTypography: { color: 'textPrimary' },
    MuiFormHelperText: { variant: 'outlined' },
  },
  typography,
  overrides: {
    ...overrides,
    MuiButton: DarkButton,
    // MuiIconButton: {
    //   sizeSmall: {
    //     padding: 7,
    //   },
    // },
    // MuiSvgIcon: {
    //   fontSizeSmall: {
    //     fontSize: '1rem',
    //   },
    // },
    // MuiMenu: {
    //   paper: {
    //     borderRadius: 0,
    //   },
    // },
    // MuiListItemIcon: {
    //   root: {
    //     minWidth: 42,
    //   },
    // },
    // MuiListItemText: {
    //   root: {
    //     fontSize: 14,
    //   },
    // },
    // MuiFormHelperText: {
    //   root: {
    //     fontSize: 14,
    //     '&.Mui-error': {
    //       color: THEME_PALETTE.error.light,
    //     },
    //     marginLeft: 0,
    //     color: DARK_THEME_PALETTE.text.primary,
    //     marginTop: 4,
    //   },
    // },
    // MuiInputLabel,
    // MuiFormControlLabel: {
    //   root: {
    //     color: THEME_PALETTE.grey['300'],
    //   },
    // },
    // // MuiFormLabel: {
    // //   root: {
    // //     '&.Mui-focused': {
    // //       color: DARK_THEME_PALETTE.secondary,
    // //     },
    // //   },
    // // },
    // MuiSelect: {
    //   root: {
    //     height: 44,
    //     boxSizing: 'border-box',
    //   },
    //   select: {
    //     '&:focus': {
    //       backgroundColor: 'transparent',
    //     },
    //   },
    //   outlined: {
    //     '&.MuiOutlinedInput-input': {
    //       padding: '8px 20px 8px 8px',
    //     },
    //   },
    // },
    // MuiInputBase: {
    //   input: {
    //     // fontWeight: 700,
    //     boxSizing: 'border-box',
    //   },
    // },
    // MuiInput: {
    //   underline: {
    //     '&:before': {
    //       borderBottom: `1px solid ${DARK_THEME_PALETTE.secondary.main}`,
    //     },
    //     '&:after': {
    //       borderBottom: `1px solid ${DARK_THEME_PALETTE.secondary.main}`,
    //     },
    //     '&&:hover::before': {
    //       borderBottom: `1px solid ${DARK_THEME_PALETTE.secondary.main}`,
    //     },
    //   },
    //   input: {
    //     marginBottom: 8,
    //   },
    // },
    MuiSelect: DarkSelect,
    MuiOutlinedInput: OutlinedInputDark,
    MuiInputLabel: DarkInputLabel,
    MuiFormLabel: { root: { color: THEME_PALETTE.common.white } },
  },
});
