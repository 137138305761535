import { APP_ROUTES } from 'Routes';
import { useStoreState } from 'Stores';

export interface NavLinks {
    url: string;
    label: string;
    id: string;
    external?: boolean;
    canRender?: () => boolean;
    onClick?: () => void;
}

function useNavigation() {
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({ appUser }));

    const NAVLINKS = [
        // appUser ? { url: APP_ROUTES.COMMUNITIES.path, label: 'Communities', id: 'communities', external: false } : undefined,
        // appUser ? { url: APP_ROUTES.DROPS.path, label: 'Drops', id: 'drops' } : undefined,
        // appUser ? { url: APP_ROUTES.CREATOR.path, label: 'For Creators', id: 'creators' } : undefined,
        // appUser ? { url: APP_ROUTES.BRANDS.path, label: 'For Brands', id: 'brands' } : undefined,
        // appUser ? { url: APP_ROUTES.ABOUT_US.path, label: 'About Us', id: 'about-us' } : undefined,
    ].filter((f) => f) as NavLinks[];

    const RIGHT_NAV_LINKS = [appUser ? undefined : { url: APP_ROUTES.PRICING.path, label: 'PRICING', id: 'pricing', external: false }].filter(
        (f) => f,
    ) as NavLinks[];

    return {
        NAVLINKS,
        RIGHT_NAV_LINKS,
    };
}

export default useNavigation;
