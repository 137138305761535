import { Box, Drawer, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { BORDER_BLACK } from 'Theme/themeConstants';
import ChatMembersContent, { ChatMembersContentProps } from './ChatMembersContent';
import { TDiscussionConfig } from './useDiscussion';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import PgIcon from 'Components/PgIcon';

export interface ActiveMemberDrawerProps extends Pick<ChatMembersContentProps, 'allowMessaging' | 'memberDrawerDisabled' | 'showChat'> {
    config: TDiscussionConfig;
    mobileDrawerProps?: { open?: boolean, onClose?: () => void };
}

export const MEMBER_DRAWER_WIDTH = 280;
const ActiveMemberDrawer: FC<ActiveMemberDrawerProps> = (props) => {

    const { config, allowMessaging = true, memberDrawerDisabled = false, showChat, mobileDrawerProps = {} } = props;
    const { open, onClose } = mobileDrawerProps;

    const classes = useStyles();

    const { isDeviceSm } = useMediaQuery();

    if (isDeviceSm) return (
        <Drawer PaperProps={{ className: classes.root }} anchor="right" onClose={onClose} open={open}>
            <Box position='relative' height='100%'>
                <ChatMembersContent config={config} allowMessaging={allowMessaging} memberDrawerDisabled={memberDrawerDisabled} showChat={showChat} />
                <IconButton onClick={onClose} className={classes.closeBtn}>
                    <PgIcon icon='icon-close' size='small' />
                </IconButton>
            </Box>
        </Drawer>
    )

    return (
        <Box className={classes.root} pt={2.5}>
            <ChatMembersContent config={config} allowMessaging={allowMessaging} memberDrawerDisabled={memberDrawerDisabled} showChat={showChat} />
        </Box>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        root: {
            //   position: 'fixed',
            //   top: APP_HEADER_HEIGHT,
            //   bottom: 0,
            //   right: 0,
            //   width: MEMBER_DRAWER_WIDTH,
            //   background: theme.palette.background.paper,
            //   borderLeft: BORDER_BLACK,
            //   overflow: 'auto',
            height: '100%',
            width: 300,
            minWidth: 300,
            [theme.breakpoints.up('sm')]: { borderLeft: BORDER_BLACK },
            [theme.breakpoints.down('sm')]: { width: '100%', minWidth: 'unset' },
        },
        closeBtn: {
            position: 'absolute',
            top: theme.spacing(1.5),
            right: theme.spacing(1.5),
        },
    });
});

export default ActiveMemberDrawer;
