import React, { FC } from 'react'
import { SVGCIconProps } from '../SVGCIcon'

const Settings: FC<SVGCIconProps> = (props) => {
  const { color = 'black', height = 24, width = 24 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.8134 9.97978L20.8846 10.2906H21.2034H23.4295V13.706H21.2034H20.8841L20.8133 14.0172C20.5888 15.0042 20.198 15.9458 19.6578 16.8017L19.4872 17.072L19.7132 17.298L21.2891 18.8739L18.874 21.2891L17.2981 19.7132L17.072 19.4871L16.8017 19.6578C15.9458 20.198 15.0042 20.5888 14.0173 20.8133L13.706 20.8841V21.2033V23.4295H10.2906V21.2033V20.884L9.97913 20.8133C8.98885 20.5885 8.04378 20.1976 7.18399 19.6573L6.91363 19.4874L6.68806 19.7134L5.11605 21.2883L2.71044 18.8744L4.28683 17.298L4.51286 17.072L4.34224 16.8017C3.802 15.9458 3.41125 15.0042 3.18668 14.0172L3.11586 13.706H2.79665H0.570532V10.2906H2.79665H3.11603L3.18673 9.9791C3.41152 8.98882 3.80236 8.04375 4.34266 7.18395L4.51256 6.9136L4.28657 6.68802L2.71118 5.11552L5.11634 2.71036L6.69906 4.2873L6.92479 4.51221L7.19447 4.34247C8.05126 3.80317 8.99294 3.41237 9.97981 3.18653L10.2906 3.11541V2.79661V0.570486H13.706V2.79661V3.11582L14.0173 3.18664C15.0042 3.4112 15.9458 3.80196 16.8017 4.3422L17.072 4.51282L17.2981 4.28679L18.8744 2.71039L21.2891 5.11679L19.7127 6.69903L19.4878 6.92476L19.6575 7.19443C20.1968 8.05123 20.5876 8.9929 20.8134 9.97978Z"
        stroke={color}
        stroke-width="0.8"
        stroke-miterlimit="10"
      />
      <path
        d="M16.2093 11.9983C16.2093 14.324 14.324 16.2093 11.9983 16.2093C9.67259 16.2093 7.78724 14.324 7.78724 11.9983C7.78724 9.67256 9.67259 7.78721 11.9983 7.78721C14.324 7.78721 16.2093 9.67256 16.2093 11.9983Z"
        stroke={color}
        stroke-width="0.8"
        stroke-miterlimit="10"
      />
    </svg>
  )
}

export default Settings
