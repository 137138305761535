import { TextField, TextFieldProps } from '@material-ui/core';
import { URL_PROTOCOL_CHECK_REG_EXP } from 'Constants/RegExp';
import React, { FC, useEffect, useState } from 'react';

export type UrlInputProps = TextFieldProps;

const UrlInput: FC<UrlInputProps> = (props) => {
  const { onChange, value, ...restProps } = props;
  const [val, setVal] = useState(value ?? '');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let _val = event.target.value;
    setVal(_val);
    const valueWithProtocol = URL_PROTOCOL_CHECK_REG_EXP.test(_val) || !_val ? _val : 'https://' + _val;
    onChange?.({
      ...event,
      target: { ...event.target, value: valueWithProtocol, name: event.target.name },
      currentTarget: { ...event.currentTarget, value: valueWithProtocol, name: event.currentTarget.name },
    });
  };
  return <TextField value={!!val ? val : value} onChange={handleChange} {...restProps} />;
};

export default UrlInput;
