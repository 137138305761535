import React, { FC, useState } from 'react'
import { Box, Collapse, IconButton } from '@material-ui/core'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { isString } from 'lodash';
import PgTypo from './PgTypo';

export interface CollapsibleContentProps { 
    title:string | React.ReactNode
    defaultClosed?:boolean
}

const CollapsibleContent: FC<CollapsibleContentProps> = (props) =>{
    const classes = useStyles(); 
    const {title , defaultClosed = false , children} = props;
    const [open , setOpen] = useState(!defaultClosed);
     const onToggle = () => {
       setOpen((e) => !e);
     }; 
    return (
      <Box>
        <div className={clsx(classes.titleContainer, 'conversations')} onClick={onToggle}>
          {isString(title) ? <PgTypo b4Bold>{title}</PgTypo> : title}
          <IconButton size="small" className={clsx(classes.collapseIcon)} style={{ transform: open ? 'rotateX(180deg)' : 'rotateX(0deg)' }}>
            <ExpandMoreIcon />
          </IconButton>
        </div>
        <Collapse in={open}>
            {children}
        </Collapse>
      </Box>
    );
}

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
      titleContainer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'space-between',
        width: '100%',
        '& p': {
          lineHeight: '15px',
          flex: 1,
        },
      },
      collapseIcon: {
        transition: '300ms ease-in-out',
      },
    });
})

export default CollapsibleContent