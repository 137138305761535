import { Box, BoxProps, createStyles, makeStyles, Theme } from '@material-ui/core';
import PgTypo, { PgTypoProps } from 'Components/PgTypo';
import React, { FC } from 'react';
import LIVE_IMG from 'Assets/icons/streaming.svg';
import clsx from 'clsx';

export interface LiveTagProps {
  className?: string;
  typoProps?: PgTypoProps;
}

const LiveTag: FC<LiveTagProps> = ({ className, typoProps }) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.liveTag, className)}>
      <PgTypo b1 color="textSecondary" className={classes.text} {...typoProps}>
        Now
      </PgTypo>
      <img src={LIVE_IMG} alt="live icon" />
    </Box>
  );
};

export default LiveTag;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    liveTag: {
      display: 'flex',
      alignItems: 'center',
      gap: 6,
      padding: `0px 6px 3px`,
      backgroundColor: theme.palette.error.main,
    },
    text: { wordBreak: 'unset' }
  })
);
