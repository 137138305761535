import { action, Action, thunk, Thunk } from 'easy-peasy';
import RefundRequestModel from 'Models/RefundRequest';
import { TRefundIssue } from 'Models/RefundRequest/@types';

export interface TIssueDetailsState {
  issue: TRefundIssue | undefined;
  setIssue: Action<TIssueDetailsState, TRefundIssue>;
  getIssue: Thunk<TIssueDetailsState, { id: string }>;
}

const IssueDetailsStore: TIssueDetailsState = {
  issue: undefined,

  setIssue: action((state, payload) => {
    state.issue = payload;
  }),

  getIssue: thunk(async (actions, { id }) => {
    const { data } = await RefundRequestModel.getRefundRequest(id, {
      include: ['transaction', 'event'],
    });
    actions.setIssue(data);
  }),
};

export default IssueDetailsStore;
