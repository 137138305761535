import { Box } from '@material-ui/core';
import { makeStyles, Theme, lighten } from '@material-ui/core/styles';
import Menu, { MenuProps } from 'Components/Menu';
import React, { FC, useCallback, useState } from 'react';
import { useStoreState } from 'Stores';
// eslint-disable-next-line import/no-cycle
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { history } from 'App';
import logoutIcon from 'Assets/icons/logout.svg';
import clsx from 'clsx';
import PgButton from 'Components/PgButton';
import PgTypo from 'Components/PgTypo';
import { Step } from 'Features/Auth/@types';
import useAuth from 'Features/Auth/useAuth';
import useAuthDialog from 'Features/Auth/useAuthDialog';
import UserModel from 'Models/User';
import { THEME_PALETTE } from 'Theme/themeConstants';
import helpers from 'Utils/helpers';
import UserAvatar from 'Components/UserAvatar';
import { BottomItem } from 'Dialogs/MobileMenu/LogInMenu';
import { MobileMenuItem } from 'Dialogs/MobileMenu/LogOutMenu';
import { APP_HEADER_HEIGHT } from '.';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UserMenuProps { }

interface ILoggedOutMenuProps {
    // eslint-disable-next-line react/require-default-props
    classes?: Record<string, string>;
}

const LoggedOutMenu: FC<ILoggedOutMenuProps> = ({ classes }) => {
    const { openDialog } = useAuthDialog();
    const handleLogin = () => {
        openDialog();
    };
    return (
        <PgButton primary className={classes?.button} onClick={handleLogin}>
            Login
        </PgButton>
    );
};

const UserMenu: FC<UserMenuProps> = (props) => {
    const classes = useStyles({ width: 50 });
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const { openDialog } = useAuthDialog();
    const { appUser } = useStoreState(({ AuthStore: { appUser } }) => ({
        appUser,
    }));
    const { logout } = useAuth();
    const name = UserModel.getUserName(appUser);
    const avatarImage = UserModel.getAvatarImage(appUser);
    const closeMenu = () => {
        setAnchorEl(null);
    };
    const handleLogin = useCallback(
        (step?: Step) => () => {
            closeMenu();
            setTimeout(() => {
                openDialog(step, { setStep: step });
            }); // Fixing an issue with switching between "Login" and "Sign up".
        },
        [setAnchorEl, openDialog],
    );

    const handleLogout = useCallback(async () => {
        setAnchorEl(null);
        await logout();
        history.push('/');
    }, [setAnchorEl, logout, history]);

    const handleOptionClick = useCallback((link: string, isExternal = false) => {
        setAnchorEl(null);
        if (isExternal) window.open(link, '_blank');
        else history.push(link);
    }, [setAnchorEl])

    const options: MenuProps['options'] = [
        ...BottomItem(appUser, () => setAnchorEl(null)).map(b => {
            if (React.isValidElement(b)) return b;
            const item = b as MobileMenuItem;
            return [{ icon: item.icon, label: item.name, onClick: () => handleOptionClick(item.link ?? '', item.isExternal) }, 'divider'] as MenuProps['options'];
        }),
        { icon: <img src={logoutIcon} className={classes.icon} />, label: 'Logout', onClick: handleLogout },
    ].flat();
    if (!appUser) {
        return (
            <Box pl={2.5}>
                <PgButton quaternary disableFocusRipple className={clsx(classes?.button, classes?.signUpBtn)} onClick={handleLogin('signup')}>
                    <PgTypo b4 fontFamily='quaternary' upperCase typoWeight='fontWeightBold'>
                        Sign up
                    </PgTypo>
                </PgButton>
                <PgButton quaternary disableFocusRipple className={clsx(classes?.button, classes?.signUpBtn)} onClick={handleLogin()}>
                    <PgTypo b4 fontFamily='quaternary' upperCase typoWeight='fontWeightBold'>
                        Log in
                    </PgTypo>
                </PgButton>
            </Box>
        );
    }
    return (
        <Box display="flex" flexDirection="row" alignItems="center">
            <PgButton
                quaternary
                style={{ minWidth: 0, marginRight: 32 }}
                className={classes.buttonText}
                // classes={{ label: classes.buttonText }}
                onClick={(e) => setAnchorEl(e.currentTarget)}
            >
                <UserAvatar name={name} url={helpers.getPictureUrl(avatarImage, 100)} size={40} />
                <PgTypo b2 className={classes.username} limitLines={1}>
                    {name}
                </PgTypo>
                {anchorEl ? <ExpandLess /> : <ExpandMore />}
            </PgButton>
            <Menu handleClose={closeMenu} id="user-menu" options={options} anchorEl={anchorEl} borderColor={THEME_PALETTE.common.black} />
        </Box>
    );
};

const useStyles = makeStyles<Theme, { width: number }>((theme) => ({
    btn: {
        height: APP_HEADER_HEIGHT,
        padding: 0,
    },
    buttonText: {
        color: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
    },
    avatarText: {
        fontSize: (props) => props.width / 3,
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    button: {
        height: '100%',
        padding: theme.spacing(0, 2.5),
    },
    username: {
        marginLeft: 8,
        marginRight: 8,
    },
    signUpBtn: { color: theme.palette.common.white },
    loginBtn: {
        backgroundColor: '#CDFF00',
        '&:hover': {
            backgroundColor: '#CDFF00',
            color: lighten('#000', 0.3),
        },
        '&:focus': {
            backgroundColor: '#CDFF00',
            color: lighten('#000', 0.4),
        },
    },
    icon: {
        height: 24,
        width: 24,
    },
}));

export default UserMenu;
