import React, { FC, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useStoreState } from 'Stores';
import clsx from 'clsx';
import 'index.css';
import { useMediaQuery } from 'Hooks/useMediaQuery';
import CommunityGridView from 'Features/Community/CommunityGridView';
import { TCommunity } from 'Models/Community/@types';
import { SEARCH_ROUTES } from 'Routes/SearchNavigation';
import PgTypo from 'Components/PgTypo';
import PgButton from 'Components/PgButton';

export interface CommunitiesProps {}

const Communities: FC<CommunitiesProps> = (props) => {
  const { isDeviceSm } = useMediaQuery();
  const classes = useStyles({});
  const { communities, searchTerm } = useStoreState(({ SearchPageStore: { communities, searchTerm } }) => ({ communities, searchTerm }));

  const title = useMemo(() => {
    let suffix = 'Communities';
    if (communities.total === 1) suffix = 'Community';
    return `${communities.total} ${suffix}`;
  }, [communities]);

  const loadMoreUrl = `${SEARCH_ROUTES.MORE.path}?type=community&q=${searchTerm}`;

  if (!communities.total) return null;
  return (
    <Box className={clsx(classes.root, { 'padding-container': !isDeviceSm })}>
      <PgTypo h6 className={classes.title}>
        {title}
      </PgTypo>
      <Box px={isDeviceSm ? 0 : 2.5}>
        <CommunityGridView communities={communities.results as unknown as TCommunity[]} isLoading={false} />
      </Box>
      <Box display="flex" justifyContent="center">
        {/* <Link to={loadMoreUrl} className={classes.btnLink}>
          {' '} */}
        <PgButton href={loadMoreUrl} secondary className={classes.btn}>
          LOAD ALL RESULTS
        </PgButton>
        {/* </Link> */}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
  root: {
    padding: theme.spacing(8, 0),
    [theme.breakpoints.down('sm')]: {
      // paddingLeft: theme.spacing(3),
      // paddingRight: theme.spacing(3),
      padding: theme.spacing(2),
    },
  },
  title: {
    margin: theme.spacing(1.5, 6),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
    },
  },
  btnLink: {
    maxWidth: 320,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 220,
    },
  },
  btn: {
    fontWeight: theme.typography.fontWeightBold,
    margin: theme.spacing(7.5, 'auto', 5),
    width: '100%',
    maxWidth: 320,
    fontSize: 16,
    // '&:hover': {
    //   color: theme.palette.common.white,
    // },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 220,
    },
  },
}));

export default Communities;
