import { Box, IconButton, Select, TextField, Theme, createStyles, makeStyles } from '@material-ui/core';
import PgButton from 'Components/PgButton';
import PgIcon from 'Components/PgIcon';
import UserAvatar from 'Components/UserAvatar';
import BasicActionDialog from 'Dialogs/BasicActionDialog';
import CommunityModel from 'Models/Community';
import { TDropType } from 'Models/Drop/@types';
import { useStoreState } from 'Stores';
import helpers from 'Utils/helpers';
import { Formik, FormikHelpers } from 'formik';
import React, { FC } from 'react';
import ADD_DROP_ICON from 'Assets/icons/add-drop.svg';
import { generatePath, useHistory } from 'react-router';
import { EVENT_ROUTES } from 'Routes/DashboardRoutes/EventNavigation';
import { EVENT_MEDIA_ROUTES } from 'Routes/DashboardRoutes/EventsAndMediaNavigation';
import { useMediaQuery } from 'Hooks/useMediaQuery';

export interface AddContentDialogProps {
  open: boolean;
  onClose: () => void;
}

const OPTIONS: { name: string, value: TDropType }[] = [
  { name: 'Event', value: 'event' },
  { name: 'Video', value: 'video' },
  { name: 'Article', value: 'article' },
  { name: 'Podcast', value: 'podcast' },
  { name: 'Merch', value: 'collect' },
]

interface FormData {
  dropName: string;
  dropType: TDropType;
}

const AddContentDialog: FC<AddContentDialogProps> = (props) => {

  const { open, onClose } = props;

  const { community } = useStoreState(({ CommunityStore: { community } }) => ({ community }));

  const { isDeviceSm } = useMediaQuery();

  const classes = useStyles();

  const history = useHistory();

  const handleSubmit = (values: Partial<FormData>, formikHelpers: FormikHelpers<Partial<FormData>>) => {
    const { dropName, dropType } = values;
    history.push(generatePath(EVENT_MEDIA_ROUTES.NEW_DROP_FORM.path, { communityId: community?.slug || community?.id || '#', dropType }), { dropName });
    onClose();
  }

  if (!community) return null;

  const dialogComp = (
    <Box px={1.5} pb={1} mb={2}>
      <Box display='flex' alignItems='flex-start' justifyContent='space-between' mb={2}>
        <UserAvatar size={40} name={CommunityModel.getName(community)} url={helpers.getPictureUrl(community._profileImages?.[0])} />
        <IconButton onClick={onClose} className={classes.closeBtn}>
          <PgIcon icon='icon-close' size='small' />
        </IconButton>
      </Box>
      <Formik<Partial<FormData>> initialValues={{ dropType: 'event' }} onSubmit={handleSubmit}>
        {formikProps => (
          <form onSubmit={formikProps.handleSubmit}>
            <TextField
              name='dropName'
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
              variant='outlined'
              fullWidth
              minRows={4}
              multiline
              placeholder='Post something to the community (Optional)'
            />
            <Box display='flex' flexDirection={isDeviceSm ? 'column' : 'row'} gridGap={isDeviceSm ? 20 : 16} mt={2.5}>
              <Select
                name='dropType'
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                variant='outlined'
                fullWidth
                native
                className={classes.selectField}
                startAdornment={<img src={ADD_DROP_ICON} alt='add-drop-icon' width={32} />}
              >
                {OPTIONS.map(o => (
                  <option key={o.value} value={o.value}>
                    {o.name}
                  </option>
                ))}
              </Select>
              <PgButton primary type='submit' fullWidth={isDeviceSm} className={classes.submitBtn}>continue</PgButton>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );

  return (
    <BasicActionDialog
      open={open}
      onClose={onClose}
      additionalComponent={dialogComp}
      showCloseBtn={false}
      dialogMaxWidth={isDeviceSm ? 'unset' as unknown as number : 600}
      dialogProps={{ fullScreen: false }}
    />
  )
}

export default AddContentDialog;

const useStyles = makeStyles((theme: Theme) => createStyles({
  closeBtn: { margin: '-7px -7px 0 0' },
  selectField: { '& select': { paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) } },
  submitBtn: { [theme.breakpoints.up('sm')]: { minWidth: 140 } },
}))
