/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import { TFilter } from 'Models/App/@types';
import { TTagSummary } from 'Models/Tags/@types';
import utc from 'dayjs/plugin/utc';
import qs from 'qs';
import PaginationQuery, { TPaginationQuery } from './PaginationQuery';

dayjs.extend(utc);

export type TWhen = {
  startDate?: string;
  endDate?: string;
};
export type TEventQuery = {
  tagIds?: string[];
  locations?: string[];
  timings?: string[];
  status?: string[];
  // priceCategory?: number
  sort?: string;
  openAddContent?: boolean;
  community?: string[];
  category?: string;
  search?: string;
  prices?: string[];
  when?: TWhen;
  locationGeo?: { lat: string; lng: string; name?: string };
};

export enum EventSort {
  recent = 'recent',
  oldest = 'oldest',
}

export enum EventLocationFilter {
  virtual = 'virtual',
  irl = 'irl',
}

export enum EventTimingFilter {
  upcoming = 'upcoming',
  past = 'past',
}

export enum EventStatusFilter {
  published = 'published',
  draft = 'draft',
}

// export type TEventSortOption = 'recent' | 'oldest';

export const sortMapping: Record<string, string> = {
  [EventSort.oldest]: 'asc',
  [EventSort.recent]: 'desc',
};
// const locationTypeMapping: Record<string, string> = {
//   [EventLocationFilter.irl]: 'offline',
//   [EventLocationFilter.virtual]: 'online',
// };
const timingKeyMapping: Record<string, string> = {
  [EventTimingFilter.past]: 'lte',
  [EventTimingFilter.upcoming]: 'gte',
};

class EventQuery extends PaginationQuery {
  private _query: TEventQuery = {};

  private _userId = '';

  private _communityId = '';

  constructor(query: TEventQuery, paginationQuery: TPaginationQuery, queryTerm = '') {
    super(paginationQuery);
    this._query = query;
  }

  set userId(userId: string) {
    this._userId = userId;
  }

  set communityId(id: string) {
    this._communityId = id;
  }

  get query() {
    return this._query;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get userId() {
    return this.userId;
  }

  getSelectedTags(tagSummaries: TTagSummary[]) {
    return tagSummaries.filter((t) => this._query.tagIds?.includes(t.id));
  }

  getSelectedEventType() {
    return this._query.locations;
  }

  getSelectedDocType() {
    return this._query.category;
  }

  static fromQuery(query: string) {
    const { page, ...eventQuery } = qs.parse(query, {
      ignoreQueryPrefix: true,
    }) as Record<string, string>;
    const paginationQuery: TPaginationQuery = { page: page ? Number(page) : 1 };
    return new EventQuery(eventQuery, paginationQuery);
  }

  get searchFilter() {
    const should: TFilter['should'] = [];
    let where: TFilter['where'] = {};
    if (this._communityId) where.communityId = [this._communityId];
    if (this.query.tagIds?.length) {
      where.primaryTagId = this.query.tagIds;
    }

    // category
    const categoryFilter: string[] = [];
    if (this._query.category?.length) {
      categoryFilter.push(this._query.category);
    }

    // Publish status ------
    let isPublished: boolean | undefined;
    if (this._query.status?.length === 1) isPublished = this._query.status[0] === EventStatusFilter.published;
    else isPublished = undefined;
    // ---------------------

    // Event type ----------
    const typeFilter: string[] = [];
    const subTypeFilter: string[] = [];
    if (this._query.locations?.length && (categoryFilter.includes('Event') || !categoryFilter.length)) {
      this._query.locations.forEach((loc) => {
        // should?.push([])
        if (loc === 'online' || loc === 'metaverse') {
          typeFilter.push('online');
          subTypeFilter.push(loc);
        } else {
          typeFilter.push(loc);
        }
      });
    }
    const sort = [];
    if (this._query.sort?.length) {
      sort.push(this._query.sort);
    }
    // when
    let whenFilter: TEventQuery['when'];
    if (this._query.when?.startDate || this._query.when?.endDate) {
      if (this._query.when?.startDate && this._query.when?.endDate) {
        /**
         * If both present then we know it's a custom date range hence we set them to startof day and end of day
         */
        whenFilter = {
          startDate: dayjs(this._query.when?.startDate).startOf('day').utc().toISOString(),
          endDate: dayjs(this._query.when?.endDate).endOf('day').utc().toISOString(),
        };
        sort.push('oldest');
      } else if (this._query.when?.startDate)
        whenFilter = {
          startDate: dayjs(this._query.when?.startDate).utc().toISOString(),
        };
      else if (this._query.when?.endDate)
        whenFilter = {
          endDate: dayjs(this._query.when?.endDate).utc().toISOString(),
        };
    }

    // Time ------------------
    if (this._query.timings?.length) {
      let rangeFilter: Record<string, unknown>[] = [];
      this._query.timings.forEach((item) => {
        rangeFilter.push({
          range: { 'eventDates.endDate': { [timingKeyMapping[item]]: new Date().toISOString() } },
        });
      });
      if (this.query.timings?.find((i) => i === 'live')) {
        rangeFilter = [
          {
            bool: {
              must: [
                {
                  range: { 'eventDates.endDate': { lte: new Date().toISOString() } },
                },
                {
                  range: { 'eventDates.endDate': { gte: new Date().toISOString() } },
                },
              ],
            },
          },
        ];
      }
      if (rangeFilter.length) {
        should.push(rangeFilter);
      }
    }

    // geoLocation
    let locationGeoFilter: TEventQuery['locationGeo'];
    if (this._query.locationGeo) {
      locationGeoFilter = {
        lat: this._query.locationGeo.lat,
        lng: this._query.locationGeo.lng,
        name: this._query.locationGeo.name,
      };
    }
    // --------------------------}
    if (this._query.community?.length) {
      const community = Array.isArray(this._query.community) ? this._query.community : (this._query.community as string).split(',');
      // eslint-disable-next-line no-unused-expressions
      community?.forEach((item) => {
        if (item === 'created') {
          where = {
            ...where,
            userId: this._userId.length ? this._userId : undefined,
          };
        } else if (item === 'saved') {
          where = {
            ...where,
            // favouriteUserIds: this._userId
          };
        }
      });
    }

    const _filter: TFilter = {
      ...this.paginationFilter,
      should: should.length ? should : undefined,
      where: {
        ...where,
        isPublished,
        subType: subTypeFilter,
      },
      category: categoryFilter,
      when: whenFilter,
      type: typeFilter,
      customSort: sort,
    };

    return _filter;
  }

  clone(query: TEventQuery, paginationQuery: TPaginationQuery) {
    return new EventQuery(
      {
        // priceCategory: query.priceCategory ?? this._query.priceCategory,
        tagIds: query.tagIds ?? this._query.tagIds,
        sort: query.sort === 'All' ? undefined : query.sort ?? this._query.sort,
        locations: query.locations ?? this._query.locations,
        status: query.status ?? this._query.status,
        timings: query.timings ?? this._query.timings,
        openAddContent: query.openAddContent ?? this._query.openAddContent,
        community: query.community ?? this._query.community,
        prices: query.prices ?? this._query.prices,
        category: query.category === 'All' ? undefined : query.category ?? this._query.category,
        when: query.when ?? this._query.when,
        search: query.search ?? this._query.search,
      },
      { page: paginationQuery.page ?? this.paginationQuery.page }
    );
  }

  get queryString() {
    const finalQuery = { ...this.query, ...this.paginationQuery };
    return qs.stringify(finalQuery);
  }
}

export default EventQuery;
