import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ChatContext } from 'Contexts/ChatContext';
import ActiveMemberDrawer from 'Features/Discussion/ActiveMemberDrawer';
import DiscussionLayout from 'Features/Discussion/DiscussionLayout';
import { TDiscussionConfig } from 'Features/Discussion/useDiscussion';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { DiscussionHeader } from 'Screens/CommunityDetail/Pages/Discussion';
import { useStoreState } from 'Stores';

export interface DiscussionProps { }

const Discussion: FC<DiscussionProps> = (props) => {
    const classes = useStyles();

    const { community, appUser } = useStoreState(({ AuthStore: { appUser }, CommunityStore: { community } }) => ({ community, appUser }));
    const { search } = useLocation();
    const activeDiscussionId = useMemo(() => {
        const searchParams = new URLSearchParams(search);
        return searchParams.get('discussionId') || '';
    }, [search]);
    const { memberIds = [], title } = (community?.discussions || []).find((i) => i.id === activeDiscussionId) || {};
    const [showInfoDialog, setShowInfoDialog] = useState(false);
    const toggleShowInfoDialog = () => setShowInfoDialog((e) => !e);

    const chatConfig = useMemo<TDiscussionConfig>(() => ({
        discussionIds: community?.discussionIds ? community?.discussionIds : [],
        activeDiscussionId,
        // discussionId: community?.discussionId || '',
        creatorId: appUser?.id || '',
        subject: 'Community',
        subjectId: community?.id || '',
    }), [community?.discussionIds, activeDiscussionId, appUser?.id, community?.id]);

    const { initChat } = useContext(ChatContext);

    useEffect(() => {
        if (activeDiscussionId) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (chatConfig) initChat(chatConfig)
        }
    }, [activeDiscussionId]);

    const allowMessaging = useMemo(() => community?.userId === appUser?.id, [community?.userId, appUser?.id])

    if (community?.id && !appUser?.id) return <Redirect to={`/${community.slug}`} />;
    if (!community?.id) return <div />;
    return (
        <div id="conversation-block" className={classes.root}>
            <DiscussionLayout
                getDiscussionHeader={(config, discussion, isAuthenticated) => (
                    <DiscussionHeader
                        toggleShowInfoDialog={toggleShowInfoDialog}
                        showInfoDialog={showInfoDialog}
                        config={config}
                        discussion={{ ...discussion, memberIds }}
                        isJoined
                        onJoin={() => { }}
                        isAdmin
                        community={community}
                        isAuthenticated={isAuthenticated}
                        allowMessaging={allowMessaging}
                    />
                )}
                config={chatConfig}
                allowMessaging={allowMessaging}
                getDiscussionSideComp={(config, discussion) => <ActiveMemberDrawer config={config} allowMessaging={allowMessaging} showChat={discussion?.discussionType && discussion?.discussionType !== 'text'} />}
            />
        </div>
    );
};

const useStyles = makeStyles<Theme>((theme) => {
    return createStyles({
        root: {
            width: '100%',
            //   padding: theme.spacing(4, 4, 0),
            boxSizing: 'border-box',
            height: 'calc(100vh - 50px)',
            [theme.breakpoints.down('sm')]: {
                // padding: theme.spacing(2, 3, 0),
            },
        },
        text: {
            color: `${theme.palette.text.disabled} !important`,
        },
        infoButton: {
            padding: theme.spacing(0),
            marginLeft: theme.spacing(1),
            '&:hover': {
                backgroundColor: 'none',
            },
        },
    });
});

export default Discussion;
