/* eslint-disable camelcase */
import { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import AppModel from 'Models/App';
import { TSearchResult } from 'Models';

export interface TResultItem {
  doc_type: string;
  [key: string]: any;
}
export interface TResult extends TSearchResult<TResultItem> {}

function useSearch(doc_types?: string[], searchLimit?: number) {
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<TResultItem[]>([]);

  const debouncedSearch = debounce(async (term: string) => {
    setLoading(true);

    const data = await AppModel.searchEntities(term, {
      limit: searchLimit ?? 5,
      where: { isPublished: true, doc_type: doc_types },
      isSoftPublished: { ifExistsThen: true },
    });

    setResult(data.results.map((r) => r.hit));
    setLoading(false);
  }, 300);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchTerm(value);
    debouncedSearch(value);
  }, []);

  const clearInput = useCallback(() => {
    setSearchTerm('');
    setResult([]);
  }, []);

  return {
    searchTerm,
    onChange,
    clearInput,
    result,
    loading,
  };
}

export default useSearch;
