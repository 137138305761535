import helpers from 'Utils/helpers';
import { request } from 'Utils';
import { UploadProgress } from 'Typings/Global';

import { parsePicture } from './pictureParser';
import { Picture } from './@types';

const getFileSize = (base64 : string) => {
  if(!base64 || base64 === '') return '';
}

class PictureModel {
  static uploadSequential = async (files: any[], onEachUpload: any) => {
    helpers.sequentialPromises(files, (fileItem: any) => PictureModel.upload(fileItem), onEachUpload);
  };

  static upload = async (
    pictureObject: { base64: string; name: string } & Record<string, unknown>,
    fileName?: string,
    folderName?: string,
    setProgress?: (progress: number) => any
  ) => {
    const { base64, name,  ...cropData } = pictureObject;
    const res = await request<Picture>({
      url: 'pictures/upload',
      data: {
        ...cropData,
        base64img: base64,
        filename: fileName || name,
        folder: folderName || 'default',
      },
      method: 'POST',
      onUploadProgress: (progressEvent: Partial<UploadProgress>) => {
        const progress = ((progressEvent.loaded ?? 0) / (progressEvent.total ?? 1)) * 100;
        setProgress && setProgress(progress);
      },
    }).catch((error) => {
      throw error;
    });
    return parsePicture(res);
  };
}

export default PictureModel;
