import axios from 'axios';
import type { AxiosError, AxiosRequestConfig } from 'axios';

export const request = async <T = Record<string, any>>(config: AxiosRequestConfig, _log = true): Promise<T> => {
  if (!axios.defaults.baseURL) {
    return Promise.reject(new Error('Error: Base Url is not provided'));
  }
  const resp = await axios.request<T>(config);
  return resp.data;
};

const AxiosUtils = {
  setBaseAPI_URL: (url: string): void => {
    axios.defaults.baseURL = url;
  },

  setHeader: (type = 'Content-Type', value = 'application/json'): void => {
    axios.defaults.headers.post[type] = value;
  },

  setAuthHeader: (accessToken?: string): void => {
    axios.defaults.headers.common.Authorization = accessToken ?? '';
  },

  throwError: (error: AxiosError): void => {
    throw error;
  },
};

export default AxiosUtils;
