import * as Yup from 'yup';
import { SLUG_VALIDATION_REG_EXP } from './RegExp';
import { SLUG_MAX_LENGTH } from './variables';

export const getSlugValidation = (msgs?: string[]) =>
  Yup.string()
    .max(SLUG_MAX_LENGTH, msgs?.[0] ?? 'Username exceeds character limit')
    .min(3, msgs?.[1] ?? 'Username should be at least 3 characters long')
    .matches(SLUG_VALIDATION_REG_EXP, msgs?.[2] ?? ' ')
    .required(msgs?.[3] ?? 'Username required');
