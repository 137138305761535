/* eslint-disable react/destructuring-assignment */
import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Tooltip, TooltipProps } from '@material-ui/core';
import { getFieldError, IFieldProps } from 'react-forms';
import PgTypo from 'Components/PgTypo';
import { FONT_WEIGHTS } from '../Theme/themeConstants';

export type TFieldProps = {
  title?: string;
  subtitle?: string;
  hint?: string;
  titleWeight?: 'bold' | 'regular' | 'medium' | undefined;
  style?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  subtitleStyle?: React.CSSProperties;
  error?: string;
  errorPosition?: 'right' | 'bottom';
  tooltipProps?: TooltipProps;
};
export interface FormSectionHeaderProps extends IFieldProps {
  fieldProps: TFieldProps | undefined;
}

const withToolTip = (child: JSX.Element, tooltipProps?: TFieldProps['tooltipProps']) =>
  !tooltipProps || !tooltipProps?.title ? child : (
    <Tooltip placement='right' {...tooltipProps}>{child}</Tooltip>
  )

const FormSectionHeader: FC<FormSectionHeaderProps> = (props) => {
  const { fieldProps = {} } = props;
  const { titleStyle, tooltipProps } = fieldProps;
  const classes = useStyles({});
  const title = fieldProps?.title;
  const subtitle = fieldProps?.subtitle;
  const subtitleStyle = fieldProps?.subtitleStyle;
  const hint = fieldProps?.hint;
  const titleWeight = fieldProps?.titleWeight || 'bold';
  let fontWeight = FONT_WEIGHTS.fontWeightBold;
  if (titleWeight === 'medium') fontWeight = FONT_WEIGHTS.fontWeightMedium;
  else if (titleWeight === 'regular') fontWeight = FONT_WEIGHTS.fontWeightRegular;
  const style = fieldProps?.style;
  const valueKey = props?.fieldConfig?.valueKey || '';
  // const error = fieldProps?.error;
  const error = getFieldError(valueKey, props?.formikProps || {});
  const errorPosition = fieldProps?.errorPosition || 'right';
  if (!title && !subtitle) return null;
  return (
    <Box className={classes.root} style={style}>
      {title ? (
        <Box display="flex" alignItems="center" flexWrap="wrap" justifyContent="space-between">
          {withToolTip(
            <PgTypo c1 style={{ ...titleStyle, fontWeight }}>{title}</PgTypo>,
            tooltipProps,
          )}
          {typeof error === 'string' && errorPosition === 'right' ? (
            <PgTypo b4 color="error">
              {error}
            </PgTypo>
          ) : null}
        </Box>
      ) : null}
      {subtitle ? (
        <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
          <PgTypo b4 dangerouslySetInnerHTML={{ __html: subtitle }} className={classes.subtitle} style={subtitleStyle ?? undefined} />
          {hint || (typeof error === 'string' && errorPosition === 'bottom') ? (
            <PgTypo b4 color={error ? 'error' : 'secondary'}>
              {error || hint}
            </PgTypo>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};

const useStyles = makeStyles<Theme, any>((theme) => ({
  root: {
    marginTop: 20,
    // marginBottom: 20,
  },
  subtitle: {
    marginTop: 4,
    // marginBottom: 20
  },
}));

export default FormSectionHeader;
